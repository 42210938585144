import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	NativeSelect,
	useMediaQuery,
	Input,
	InputLabel,
	TextField
} from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RequestStatuses } from '../../const/requests';
import '../../styles/requests/requests-search-modal.css';
import SearchSelect from '../shared/SearchSelect';

interface RequestsSearchModalProps {
	isOpen: boolean;
	close(): void;
	selectedSite: any;
	handleSiteChange: (newSite: any) => void;
	selectedLocation: any;
	handleLocationChange: (newLocation: any) => void;
	selectedSubLocation: any;
	handleSubLocationChange: (newSubLocation: any) => void;
	searchRequests(): void;
	setSelectedStatus: (selectedItem: string) => void;
	selectedStatus: string;
	requestNumber: number;
	setRequestNumber: (requestNumber: number) => void;
}

const RequestsSearchModal = (props: RequestsSearchModalProps) => {
	const [t, i18n] = useTranslation();
	return (
		<Dialog
			open={props.isOpen}
			onClose={props.close}
			aria-labelledby={t("Requests Search")}
			fullScreen={useMediaQuery(useTheme().breakpoints.down('xs'))}
		>
			<DialogTitle>{t("Search Requests")}</DialogTitle>
			<DialogContent
				style={{ height: '580px', width: useMediaQuery(useTheme().breakpoints.down('xs')) ? '300px' : '600px' }}
			>
				<Grid container spacing={5} style={{ marginTop: 15 }}>
					<SearchSelect
						label={t("Site")}
						collectionName="Sites"
						nameProperty="SiteName"
						selectedOption={props.selectedSite}
						setSelectedOption={props.handleSiteChange}
						isDisabled={false}
					/>
					<SearchSelect
						label={t("Location")}
						collectionName="Locations"
						nameProperty="LocationName"
						selectedOption={props.selectedLocation}
						setSelectedOption={props.handleLocationChange}
						isDisabled={!props.selectedSite}
						dependsOn={props.selectedSite}
						dependsOnField="SiteID"
					/>
					<SearchSelect
						label={t("SubLocation")}
						collectionName="SubLocations"
						nameProperty="SubLocationName"
						selectedOption={props.selectedSubLocation}
						setSelectedOption={props.handleSubLocationChange}
						isDisabled={!props.selectedLocation}
						dependsOn={props.selectedLocation}
						dependsOnField="LocationID"
					/>
					<Grid item xs={6}>
						<InputLabel htmlFor="requestStatus">{t("Request Status")}</InputLabel>
						<NativeSelect
							style={{ width: '100%' }}
							value={props.selectedStatus}
							onChange={e => props.setSelectedStatus(e.target.value)}
							input={<Input name="RequestStatus" id="requestStatus" />}
							name={t("Status")}
							defaultValue={undefined}
						>
							<option value="None">{t("None")}</option>
							<option value={RequestStatuses.Approved}>{t("Approved")}</option>
							<option value={RequestStatuses.Pending}>{t("Pending")}</option>
							<option value={RequestStatuses.Declined}>{t("Declined")}</option>
							<option value={RequestStatuses.ApprovedOnHold}>{t("Approved - on hold")}</option>
							<option value={RequestStatuses.RequiresAuthorisation}>{t("Requires Authorisation")}</option>
							<option value={RequestStatuses.Authorised}>{t("Authorised")}</option>
							<option value={RequestStatuses.WorkConfirmed}>{t("Work Confirmed")}</option>
							<option value={RequestStatuses.WorkRejected}>{t("Work Rejected")}</option>
							<option value={RequestStatuses.FormNotSubmitted}>{t("Form Not Submitted")}</option>
							<option value={RequestStatuses.WorkRejected}>{t("Form Submitted")}</option>
						</NativeSelect>
					</Grid>
					<Grid item xs={6}>
						<InputLabel htmlFor="requestNumber">{t("Request Number")}</InputLabel>
						<TextField
							name="RequestNumber"
							id="requestNumber"
							variant="standard"
							type="number"
							onChange={e => props.setRequestNumber(parseInt(e.target.value))}
							value={props.requestNumber || undefined}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions classes={{ root: 'dialog-action-buttons' }}>
				<Button onClick={props.close} color="primary">
					{t("Cancel")}
				</Button>
				<Button onClick={props.searchRequests} color="primary">
					{t("Search")}
				</Button>
			</DialogActions>
		</Dialog>
	)
};

export default RequestsSearchModal;
