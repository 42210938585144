import { History } from 'history';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { fire } from '../..';
import Api from '../../apiConfiguration.json';
import ApproveRequestButton from '../../components/requests/ApproveRequestButton';
import DeclineRequestButton from '../../components/requests/DeclineRequestButton';
import RequestMainDetails from '../../components/requests/RequestMainDetails';
import RequestPhotos from '../../components/requests/RequestPhotos';
import BackButton from '../../components/shared/BackButton';
import SideDrawer from '../../components/shared/SideDrawer';
import LoadingSpinner from '../../components/Spinners/LoadingSpinner';
import Request from '../../definitions/request';
import useRequestDetails from '../../hooks/Requests/useRequestDetails';
import '../../styles/requests/request-details.css';
import ApiKeyObj from '../../utils/ApiObjectKey';
import { getBaseURL } from '../../utils/getBaseURL';
import { SendErrorData } from '../../utils/WindowError';

interface RequestDetailsProps {
	history: History;
	request: Request;
	canApproveRequest: boolean;
	getRequest: any;
}

const RequestDetails = ({ request, history, canApproveRequest, getRequest }: RequestDetailsProps) => (
	<div className="request-details-container">
		<div className="request-details">
			<RequestMainDetails request={request} />
			<RequestPhotos photos={request.Photos} />
			{(canApproveRequest && request.RequestStatus != "Approved" && request.RequestStatus != "Declined") && <ApproveRequestButton request={request} history={history} />}
			{(canApproveRequest && request.RequestStatus != "Approved" && request.RequestStatus != "Declined") && <DeclineRequestButton request={request} history={history} getRequest={getRequest} />}
		</div>
	</div>
);

interface RequestDetailsViewProps {
	history: History;
	UserSettings: Store.UserSettings;
}

const RequestDetailsView = (props: RequestDetailsViewProps) => {
	let canApproveRequest = false;
	if (
		props.UserSettings.CanApproveRequests
	) {
		canApproveRequest = true;
	}
	const { isLoading, request, getRequest } = useRequestDetails();
	const [t, i18n] = useTranslation();

	if (isLoading) {
		return (
			<>
				<SideDrawer
					history={props.history}
					title={t('Requests Details')}
					rightMenuButton={<BackButton callbackMethod={() => window.history.back()} />}
					colour="primary"
					handleLogout={() => fire.auth.signOut().then(() => location.reload())}
					User={props.UserSettings}
					versionApp={Api.VERSION}
					versionDb={Api.INDEXEDDB_VERSION}
					SendErrorData={SendErrorData}
					getBaseURL={getBaseURL}
					ApiKeyObj={ApiKeyObj}
				/>
				<LoadingSpinner height="90vh" text={t("Loading Requests Details...")} />
			</>
		);
	}

	return (
		<>
			<SideDrawer
				history={props.history}
				title={t('Requests Details')}
				rightMenuButton={<BackButton callbackMethod={() => window.history.back()} />}
				colour="primary"
				handleLogout={() => fire.auth.signOut().then(() => location.reload())}
				User={props.UserSettings}
				versionApp={Api.VERSION}
				versionDb={Api.INDEXEDDB_VERSION}
				SendErrorData={SendErrorData}
				getBaseURL={getBaseURL}
				ApiKeyObj={ApiKeyObj}
			/>
			<RequestDetails request={request as Request} history={props.history} canApproveRequest={canApproveRequest} getRequest={getRequest} />
		</>
	);
};

const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings as Store.UserSettings,
});

export default connect(mapStateToProps)(RequestDetailsView);
