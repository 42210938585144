// react
import * as React from 'react';

// Material UI
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Components
import OuterCard from '../shared/OuterCard';
import CardHeader from '../shared/CardHeader';
import { unixToDateString, unixToTimeString } from '../../utils/Times';
import { withTranslation } from 'react-i18next';

import JobNoteTypes from '../../utils/JobNoteTypes'
import { isNullOrUndefined } from 'util';

//card icon
import NOTE from '../../assets/images/icons/NOTE.svg'

interface Notes {
	JobNoteTypeID?: number;
	Id: string;
	CreatedBy: string;
	DateAdded: number;
	DateAddedString: string;
	Note: string;
	AddedByContractor: boolean | null;
	TimeStamp: number | null;
	statusColor: string;
	Args?: string[];
}

interface NotesCardProps {
	notes: Notes[];
	noNotes: string;
	color?: string;
	title: string;
	t: any;
	expand: boolean;
	handleExpand: (value: boolean) => void;
	handleNoteDialogVisibility: () => void;
}

class NotesCard extends React.Component<NotesCardProps> {

	constructor(props) {
		super(props);
	}

	//Translate note string if needed and replace placeholder values with sent variables.
	parseNoteString = (note: string, jobNoteTypeID: number, args: string[]) => {
		const _noteType = JobNoteTypes[jobNoteTypeID];
		if (!_noteType)
			return "";

		note = this.props.t(JobNoteTypes[jobNoteTypeID][1]);

		if (note.substring(note.length - 8, note.length) == "_JobNote")
			note = JobNoteTypes[jobNoteTypeID][0];

		var newString = note;
		for (var i = 0; i < newString.length; i++) {
			if (newString[i] == "{" && newString[i + 2] == "}") {
				var replacement = args[newString.charAt(i + 1)];
				newString = newString.substring(0, i) + replacement + newString.substring(i + 3, newString.length);
			}
		}

		return newString;
	}

	render() {
		return (
			<OuterCard styles="notes-card border-radius" padding="0px">
				<CardHeader title={this.props.title} textColor="white" backgroundColor="#f50057" expand={this.props.expand} handleExpand={this.props.handleExpand} showExpand={true} titleIcon={NOTE}
					showActionButton={true} actionButtonText={this.props.t("Add Note")} actionButtonIcon="note_add" actionButtonClickHandler={this.props.handleNoteDialogVisibility} />
				{this.props.notes.length < 1 ? <div className='no-elements-card-text'>
					{this.props.expand && <p>{this.props.noNotes}</p>}
				</div> : <>{this.props.expand ? <Table>
					<TableHead>
						<TableRow style={{ padding: '0', paddingBottom: '5px' }}>
							<TableCell className="note-cell-1-header" padding="none">
								{this.props.t("Date Added")}
							</TableCell>
							<TableCell className="note-cell-2-header" padding="none">
								{this.props.t("Note")}
							</TableCell>
							<TableCell className="note-cell-3-header" padding="none">
								{this.props.t("Created By")}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{this.props.notes.map(row => {
							if (!row.Note) {
								if (!isNullOrUndefined(row.Args) && !isNullOrUndefined(row.JobNoteTypeID))
									row.Note = this.parseNoteString(row.Note, row.JobNoteTypeID, row.Args);
							}
							return (
								<TableRow key={row.Id} className={row.AddedByContractor ? 'pink-row' : ''}>
									<TableCell className="note-cell-1" padding="none">
										<em>{unixToTimeString(row.DateAdded)}</em>
										<br />
										{unixToDateString(row.DateAdded, true)}
									</TableCell>
									<TableCell className="note-cell-2" padding="none">
										{row.Note}
									</TableCell>
									<TableCell className="note-cell-3" padding="none">
										{row.CreatedBy}
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table> : <></>}</>}
			</OuterCard>
		);
	}
}

export default withTranslation()((NotesCard));
