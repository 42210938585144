// React
import * as React from 'react';

// Material UI
import {
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
	InputLabel,
	TextField,
	Button,
	useMediaQuery, Chip, Avatar,
} from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import AutoCompleteDropDown from '../inputs/AutoCompleteDropdown';
import { useTranslation } from 'react-i18next';

const ResourceSearchModal = props => {
	const {t, i18n} = useTranslation();
	return (
	<Dialog
		open={props.searchModalOpen}
		onClose={() => props.handleSearchModal()}
		aria-labelledby={t("Resource Search")}
		fullScreen={useMediaQuery(useTheme().breakpoints.down('sm'))}
	>
		<DialogContent id="content" classes={{ root: 'dialog-content-root' }}>
			<Grid container spacing={5} direction="row" justify="center" alignItems="center">
				<Grid item xs={12} sm={5}>
					<AutoCompleteDropDown
						labelName={t("Team")}
						selectedName={props.selectedTeam || ''}
						listToFilter={props.selectedTrade === '' ? props.teamList : []}
						selectInputCallback={props.handleTeamChange}
						clearChildDropdowns={props.handleTeamChange}
						showListUnderNumber={200}
					/>
				</Grid>

				<Grid container justify="center" xs={12} sm={2}>
					<Chip label={t("Or")} variant="outlined" color="primary" />
				</Grid>

				<Grid item xs={12} sm={5}>
					<AutoCompleteDropDown
						labelName={t("Trade")}
						selectedName={props.selectedTrade || ''}
						listToFilter={props.selectedTeam === '' ? props.tradeList : []}
						selectInputCallback={props.handleTradeChange}
						clearChildDropdowns={props.handleTradeChange}
						showListUnderNumber={200}
					/>
				</Grid>
			</Grid>
		</DialogContent>

		<DialogActions classes={{ root: 'action-buttons-list' }}>
			<Button onClick={props.handleSearchModal} color="primary">
				{t("Cancel")}
			</Button>

			<Button onClick={props.searchList} color="primary">
				{t("Search")}
			</Button>
		</DialogActions>
	</Dialog>
)};

export default ResourceSearchModal;
