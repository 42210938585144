import { useEffect, useRef, useState } from 'react';
import { fire } from '../../index';
import { LocalstorageGetItem, LocalstorageRemoveItem, LocalstorageSetItem } from '../../utils/LocalStorage';
import useDialog from '../useDialog';

const DOCUMENTS_PER_PAGE = 25;
const LOCAL_STORAGE_KEY = 'StorageAreasFilterOptions';

const useStorageAreas = () => {
	const mountedRef = useRef(true);
	const [isLoading, setIsLoading] = useState(false);
	const [isEndOfList, setIsEndOfList] = useState(false);
	const [lastLoadedDocument, setLastLoadedDocument] = useState<firebase.default.firestore.DocumentData | null>(null);
	const [storageAreas, setStorageAreas] = useState<StorageArea[]>([]);
	const [isFiltered, setIsFiltered] = useState(false);
	const [selectedSite, setSelectedSite] = useState(null as any);
	const [selectedLocation, setSelectedLocation] = useState(null as any);
	const [selectedSubLocation, setSelectedSubLocation] = useState(null as any);
	const {
		isDialogOpen: isSearchDialogOpen,
		openDialog: openSearchDialog,
		closeDialog: closeSearchDialog,
	} = useDialog();

	const handleSiteChange = site => {
		setSelectedSite(site);
		setSelectedLocation(null);
		setSelectedSubLocation(null);
	};
	const handleLocationChange = location => {
		setSelectedLocation(location);
		setSelectedSubLocation(null);
	};
	const handleSubLocationChange = subLocation => {
		setSelectedSubLocation(subLocation);
	};

	useEffect(() => {
		let filters;
		const storedFilters = JSON.parse(LocalstorageGetItem(LOCAL_STORAGE_KEY) || 'null');
		if (storedFilters) {
			setSelectedSite(storedFilters.selectedSite);
			setSelectedLocation(storedFilters.selectedLocation);
			setSelectedSubLocation(storedFilters.selectedSubLocation);
			filters = {
				search: storedFilters.search,
				SiteFBID: storedFilters.selectedSite && storedFilters.selectedSite.value,
				LocationFBID: storedFilters.selectedLocation && storedFilters.selectedLocation.value,
				SubLocationFBID: storedFilters.selectedSubLocation && storedFilters.selectedSubLocation.value,
			};
			setIsFiltered(true);
		}
		filters ? getStorageAreas(false, filters) : getStorageAreas();
		return () => {
			mountedRef.current = false;
		};
	}, []);

	const getStorageAreas = (isReset?: boolean, filters?: any, isFilteredAndPaginated?: boolean) => {
		const isResetOrFiltered = isReset || (filters !== undefined && !isFilteredAndPaginated);
		const scrollY = isResetOrFiltered ? 0 : window.scrollY;
		setIsLoading(true);
		fire
			.getStorageAreasCollectionPaginated(DOCUMENTS_PER_PAGE, isResetOrFiltered ? null : lastLoadedDocument, filters)
			.get()
			.then(queryResult => mountedRef.current && _handleQueryResult(queryResult, isResetOrFiltered))
			.finally(() => {
				mountedRef.current && setIsLoading(false);
				window.scrollTo(0, scrollY);
			});
	};

	const _handleQueryResult = (queryResult: firebase.default.firestore.QuerySnapshot, isResetOrFiltered?: boolean) => {
		if (queryResult.empty) {
			setIsEndOfList(true);
			if (isResetOrFiltered) setStorageAreas([]);
		} else {
			if (isResetOrFiltered) setIsEndOfList(false);
			const rows = queryResult.docs.map(storageRow => {
				const row = storageRow.data() as StorageArea;
				row.ID = storageRow.id;
				return row;
			});
			const data = isResetOrFiltered ? rows : storageAreas.concat(rows);
			setStorageAreas(data.sort((a, b) => a.StorageName.toLowerCase().localeCompare(b.StorageName.toLowerCase())));
			setLastLoadedDocument(queryResult.docs[queryResult.docs.length - 1]);
		}
	};

	const searchStorageAreas = () => {
		if (selectedSite || selectedLocation || selectedSubLocation) {
			setIsFiltered(true);
			const filters = {
				SiteFBID: selectedSite && selectedSite.value,
				LocationFBID: selectedLocation && selectedLocation.value,
				SubLocationFBID: selectedSubLocation && selectedSubLocation.value,
			};
			LocalstorageSetItem({
				Key: LOCAL_STORAGE_KEY,
				Value: { selectedSite, selectedLocation, selectedSubLocation },
			});
			getStorageAreas(false, filters);
		} else {
			if (isFiltered) {
				clearSearch();
			}
		}
		closeSearchDialog();
	};

	const clearSearch = () => {
		setIsFiltered(false);
		setSelectedSite(null);
		setSelectedLocation(null);
		setSelectedSubLocation(null);
		LocalstorageRemoveItem(LOCAL_STORAGE_KEY);
		getStorageAreas(true);
	};

	const loadMoreStorageAreas = () => {
		if (isFiltered) {
			const filters = {
				SiteFBID: selectedSite && selectedSite.value,
				LocationFBID: selectedLocation && selectedLocation.value,
				SubLocationFBID: selectedSubLocation && selectedSubLocation.value,
			};
			getStorageAreas(false, filters, true);
		} else {
			getStorageAreas();
		}
	};

	return {
		isLoading,
		isFiltered,
		isEndOfList,
		storageAreas,
		loadMoreStorageAreas,
		isSearchDialogOpen,
		openSearchDialog,
		closeSearchDialog,
		selectedSite,
		handleSiteChange,
		selectedLocation,
		handleLocationChange,
		selectedSubLocation,
		handleSubLocationChange,
		searchStorageAreas,
		clearSearch,
		_handleQueryResult,
	};
};

export default useStorageAreas;
