interface GeoLocation {
	Latitude: number | null;
	Longitude: number | null;
}

export function getLocation(): Promise<GeoLocation> {
	return new Promise<GeoLocation>(resolve => {
		navigator.geolocation.getCurrentPosition(
			location => {
				const locationObj: GeoLocation = {
					Latitude: location.coords.latitude,
					Longitude: location.coords.longitude,
				};
				resolve(locationObj);
			},
			() => {
				const locationObj: GeoLocation = {
					Latitude: null,
					Longitude: null,
				};

				resolve(locationObj);
			},
		);
	});
}
