// React
import * as React from 'react';
import { useState, useRef, useEffect } from 'react';

// Search Pagination for Sites
import { AsyncPaginate } from 'react-select-async-paginate';

import { isNullOrUndefined } from 'util';

// Material UI
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
	InputLabel,
	TextField,
	useMediaQuery,
	Icon,
	IconButton
} from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import { generateFirebaseId } from '../../utils/Guids';
import { useTranslation } from 'react-i18next';

function SiteSearchModal(props) {
	const [enableTextSearchSiteType, setTextSearchSiteType] = useState(false);
	const [SiteTypeMenuOpen, setSiteTypeMenuOpen] = useState(false);
	const [enableTextSearchRegion, setTextSearchRegion] = useState(false);
	const [RegionMenuOpen, setRegionMenuOpen] = useState(false);
	const [enableTextSearchContract, setTextSearchContract] = useState(false);
	const [ContractMenuOpen, setContractMenuOpen] = useState(false);
	const [showKeyboard, setShowKeyboard] = useState(false);
	const [t, i18n] = useTranslation();

	const sitetypeRef = useRef<HTMLInputElement>();
	const regionRef = useRef<HTMLInputElement>();
	const contractRef = useRef<HTMLInputElement>();

	useEffect(() => {
		updatePredicate();
	}, [window.innerWidth]);

	const updatePredicate = () => {
		setShowKeyboard(window.innerWidth < 600);
	}

	const openSiteTypeMenu = () => {
		setSiteTypeMenuOpen(true);
		if(!isNullOrUndefined(sitetypeRef.current) && !isNullOrUndefined(sitetypeRef.current.select)) {
			sitetypeRef.current.focus();
		}
	}

	const closeSiteTypeMenu = () => setSiteTypeMenuOpen(false);

	const openRegionMenu = () => {
		setRegionMenuOpen(true);
		if(!isNullOrUndefined(regionRef.current) && !isNullOrUndefined(regionRef.current.select)) {
			regionRef.current.focus();
		}
	}

	const closeRegionMenu = () => setRegionMenuOpen(false);

	const openContractMenu = () => {
		setContractMenuOpen(true);
		if(!isNullOrUndefined(contractRef.current) && !isNullOrUndefined(contractRef.current.select)) {
			contractRef.current.focus();
		}
	}

	const closeContractMenu = () => setContractMenuOpen(false);

	return (
		<Dialog
			open={props.searchModalOpen}
			onClose={() => props.handleSearchModal()}
			aria-labelledby={t("Site Search")}
			fullScreen={useMediaQuery(useTheme().breakpoints.down('xs'))}
		>
			<DialogContent id="content"  style={{ height: '580px' }}>
				<Grid container spacing={5}>
					<Grid item xs={12}>
						<InputLabel htmlFor="free-text-input">{t("Search")}</InputLabel>

						<TextField
							margin="dense"
							name= {generateFirebaseId()}
							id="free-text-input"
							type="text"
							fullWidth={true}
							value={props.searchInput || ''}
							onChange={e => props.handleSearchInput(e.target.value)}
						/>
					</Grid>

					<div style={{display: 'flex', columnGap: 10 ,width: '100%', margin: '10px 20px'}}>
						<div style={{flex: '1'}}>
							<InputLabel htmlFor="siteType-helper" style={{ marginBottom: 5 }}>{t("Site Type")}</InputLabel>
							<AsyncPaginate
								id="sitetype-helper"
								value={props.siteTypeFilter}
								selectRef={sitetypeRef}
								onFocus={openSiteTypeMenu}
								onBlur={closeSiteTypeMenu}
								menuIsOpen={SiteTypeMenuOpen}
								onChange={(e) => { props.handleSiteTypeFilter(e); setSiteTypeMenuOpen(false); }}
								blurInputOnSelect={true}
								loadOptions={props.loadSiteTypes}
								loadingMessage={() => t("Loading Site Types...")}
								cacheUniqs={[props.siteTypeFilter, props.siteTypeValue]}
								isDisabled={false}
								noOptionsMessage={() => t("No Site Types Found")}
								isSearchable={ showKeyboard ? enableTextSearchSiteType : true }
							/>
						</div>
						<div style={{flex: '0', marginTop: 28, justifySelf: 'flex-end'}}>
							{!isNullOrUndefined(props.siteTypeFilter) && props.siteTypeFilter !== '' && (
								<div>
									<IconButton onClick={() => props.clearSelectedSiteType()} style={{ cursor: 'pointer' }}>
										<Icon color="error">
											delete_forever
										</Icon>
									</IconButton>
								</div>
							)}
						</div>
					</div>

					<div style={{display: 'flex', columnGap: 10 ,width: '100%', margin: '10px 20px'}}>
						<div style={{flex: '1'}}>
							<InputLabel htmlFor="region-helper" style={{ marginBottom: 5 }}>{t("Region")}</InputLabel>
							<AsyncPaginate
								id="region-helper"
								value={props.regionFilter}
								selectRef={regionRef}
								onFocus={openRegionMenu}
								onBlur={closeRegionMenu}
								menuIsOpen={RegionMenuOpen}
								onChange={(e) => { props.handleRegionFilter(e); setRegionMenuOpen(false); }}
								blurInputOnSelect={true}
								loadOptions={props.loadRegions}
								loadingMessage={() => t("Loading Regions...")}
								cacheUniqs={[props.regionFilter, props.regionValue]}
								isDisabled={false}
								noOptionsMessage={() => t("No Regions Found")}
								isSearchable={ showKeyboard ? enableTextSearchRegion : true }
							/>
						</div>
						<div style={{flex: '0', marginTop: 28, justifySelf: 'flex-end'}}>
							{!isNullOrUndefined(props.regionFilter) && props.regionFilter !== '' && (
								<div>
									<IconButton onClick={() => props.clearSelectedRegion()} style={{ cursor: 'pointer' }}>
										<Icon color="error">
											delete_forever
										</Icon>
									</IconButton>
								</div>
							)}
						</div>
					</div>

					<div style={{display: 'flex', columnGap: 10 ,width: '100%', margin: '10px 20px'}}>
						<div style={{flex: '1'}}>
							<InputLabel htmlFor="contract-helper" style={{ marginBottom: 5 }}>{t("Contract")}</InputLabel>
							<AsyncPaginate
								id="contract-helper"
								value={props.contractFilter}
								selectRef={contractRef}
								onFocus={openContractMenu}
								onBlur={closeContractMenu}
								menuIsOpen={ContractMenuOpen}
								onChange={(e) => { props.handleContractFilter(e); setContractMenuOpen(false); }}
								blurInputOnSelect={true}
								loadOptions={props.loadContracts}
								loadingMessage={() => t("Loading Contracts...")}
								cacheUniqs={[props.contractFilter, props.contractValue]}
								isDisabled={false}
								noOptionsMessage={() => t("No Contracts Found")}
								isSearchable={ showKeyboard ? enableTextSearchContract : true }
							/>
						</div>
						<div style={{flex: '0', marginTop: 28, justifySelf: 'flex-end'}}>
							{!isNullOrUndefined(props.contractFilter) && props.contractFilter !== '' && (
								<div>
									<IconButton onClick={() => props.clearSelectedContract()} style={{ cursor: 'pointer' }}>
										<Icon color="error">
											delete_forever
										</Icon>
									</IconButton>
								</div>
							)}
						</div>
					</div>
					
				</Grid>
			</DialogContent>

			<DialogActions classes={{ root: 'action-buttons-list' }}>
				<Button onClick={props.handleSearchModal} color="primary">
					{t("Cancel")}
				</Button>
				<Button onClick={props.searchSites} color="primary">
					{t("Search")}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default SiteSearchModal;
