import React from 'react';

import { TableCell, TableHead, TableRow } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export const DocumentsCardHeader = () => {
    const { t } = useTranslation();
    return (
      <TableHead>
      <TableRow style={{ padding: '0', paddingBottom: '5px' }}>
        <TableCell className="cell3" padding="none">
          {t("File Name")}	
        </TableCell>
        <TableCell className="cell5" padding="none" align="center">
        {t("Details")}	
        </TableCell>
      </TableRow>
    </TableHead>
    )
  }