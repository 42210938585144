// React
import * as React from 'react';

// Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

const ResourceSearchModal = props => {
	const [t, i18n] = useTranslation();
	return (
	<Dialog
		open={props.searchModalOpen}
		onClose={() => props.handleSearchModal()}
		aria-labelledby="Resource Search"
		fullWidth={true}
		maxWidth={'sm'}
		classes={{ paper: 'search-popup-resource' }}
	>
		<DialogContent id="content" classes={{ root: 'dialog-content-root' }}>
			<div id="filters-row">
				<div style={{ width: '100%' }}>
					<InputLabel htmlFor="free-text-input">{t("Search Term")}</InputLabel>
					<TextField
						autoFocus={true}
						margin="dense"
						id="free-text-input"
						type="text"
						fullWidth={true}
						value={props.searchInput || ''}
						onChange={e => props.handleSearchInput(e.target.value)}
					/>
				</div>
			</div>
		</DialogContent>
		<DialogActions classes={{ root: 'action-buttons-list' }}>
			<Button onClick={props.handleSearchModal} color="primary">
				{t("Cancel")}
			</Button>
			<Button onClick={props.searchList} color="primary">
				{t("Search")}
			</Button>
		</DialogActions>
	</Dialog>
)};

export default ResourceSearchModal;
