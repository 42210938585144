// prettier-ignore
import i18n from "i18next";
// prettier-ignore
import { initReactI18next } from "react-i18next";
// prettier-ignore
import allLanguages from "./allLanguages.json";

const resources = allLanguages;

// prettier-ignore
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "English", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;