// React
import * as React from 'react';

// Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon/Icon';
import { useTranslation } from 'react-i18next';

interface AddNoteDialogPopupProps {
	open: boolean;
	handleNoteDialogVisibility: () => void;
	handleAddNew: () => void;
	Title: string;
	newNoteChange: (e) => void;
}

const AddNoteDialogPopup = (props: AddNoteDialogPopupProps) => {
	const {t, i18n} = useTranslation();
	return (
	<Dialog open={props.open} fullScreen={true} maxWidth="lg">
		<DialogTitle id="form-dialog-title">{props.Title}</DialogTitle>
		<DialogContent>
			<TextField
				autoFocus={true}
				margin="none"
				id="add-note"
				label={t("Note")}
				type="text"
				fullWidth={true}
				multiline={true}
				onChange={e => props.newNoteChange(e)}
			/>
		</DialogContent>
		<DialogActions>
			<Button
				onClick={props.handleNoteDialogVisibility}
				color="primary"
				style={{ backgroundColor: 'var(--light-grey)', color: 'white' }}
			>
				<Icon style={{ fontSize: 20 }}>undo</Icon> &nbsp;
				<p style={{ marginTop: 2 }}>{t("Back")}</p>
			</Button>
			<Button
				onClick={props.handleAddNew}
				color="primary"
				style={{ backgroundColor: 'var(--light-green)', color: 'white' }}
			>
				<Icon style={{ fontSize: 20 }}>note_add</Icon>&nbsp;
				<p style={{ marginTop: 2 }}>{t("Add Note")}</p>
			</Button>
		</DialogActions>
	</Dialog>
)};

export default AddNoteDialogPopup;
