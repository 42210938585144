// Trackplan Manager DB
import TrackplanDatabase from './TrackplanDb';
// Utils
import { fire, idb } from '../../../index';
import { generateGuid } from '../../Guids';
import { fileToArrayBuffer } from '../../Converters';
import { isNullOrUndefined } from 'util';
import firebase from 'firebase';
import * as mime from 'react-native-mime-types';
import GetMimeType from '../../MimeTypes';
import { BusinessTypes, getBusinessTypeByCollection, sortFBPhotosByCreatedDate, sortIDBPhotosByCreatedDate } from '../../shared';

export const MIME_TYPE_DOCUMENT = 'document';
export const MIME_TYPE_IMAGE = 'image';
export const JOB_DOCUMENT_SOURCE_FROM_APP = 'FromApp';
export const JOB_DOCUMENT_SOURCE_OTHER = 'Other';

export default class IndexDb {
	db: TrackplanDatabase;
	private readonly iOS: boolean;

	constructor() {
		this.db = new TrackplanDatabase();
		this.iOS =
			!!navigator.platform &&
			(/iPad|iPhone|iPod/.test(navigator.platform) ||
				(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1));
	}

	async savePhotoToLocal(
		photo: ArrayBuffer,
		documentId: string,
		isComplete: string,
		documentFBID: string,
		documentName?: string,
		localGuid?: string,
		formId?: string,
		PhotoFBID?: string,
		uploadedBy?: string,
		source?: string,
		dateCreated?: number,
		jobTaskNumber?: string,
		tags?: indexDb.DocumentTag[],
		functionCallback?: any,
		businessType?: string
	) {
		//if a guid is already present use that, else generate new one
		const guid = localGuid === undefined ? generateGuid() : localGuid;
		if (await this.documentExistsByGuid(guid)) return guid;

		const documentNameFormatted = documentName || `photo-${new Date().getTime()}.jpeg`;
		const mimeType =
			(mime.lookup(documentNameFormatted) || GetMimeType(documentNameFormatted) || '').split('/')[0] === MIME_TYPE_IMAGE
				? MIME_TYPE_IMAGE
				: MIME_TYPE_DOCUMENT;
		const nameRegexp = /(photo-)\d+\.\w{0,4}/g;

		const doesDocumentExist = await this.documentExistsByDocumentFBID(documentFBID);
		if (doesDocumentExist) return guid;
		if (await this.documentExistsByGuid(guid)) return guid;

		//commits to indexdb
		await this.db.transaction('rw!', this.db.documents, async () => {
			await this.db.documents.add({
				documentId,
				document: photo,
				documentName: documentNameFormatted,
				guid,
				mimeType,
				isComplete,
				formId,
				photoId: PhotoFBID,
				uploadedBy,
				source:
					source || (nameRegexp.test(documentNameFormatted) ? JOB_DOCUMENT_SOURCE_FROM_APP : JOB_DOCUMENT_SOURCE_OTHER),
				dateCreated,
				jobTaskNumber,
				tags,
				documentFBID: documentFBID,
				businessType
			});
		});

		if (functionCallback != null)
			functionCallback();

		return guid;
	}

	async setPhoto(
		photo: any,
		documentId: string,
		fullSync: boolean,
		Collection: string,
		isComplete: string,
		documentRef?: firebase.firestore.DocumentReference,
		LocalGuid?: string,
	) {
		const guid = LocalGuid === undefined ? generateGuid() : LocalGuid;
		if (this.iOS && typeof photo !== 'string') {
			photo = await this.fileToBase64(photo);
		}

		return this.db.transaction('rw', this.db.jobphotos, async () => {
			this.db.jobphotos
				.add({
					guid,
					file: photo,
					documentId,
					isComplete,
				})
				.then(() => {
					if (fullSync) {
						fire
							.uploadImageExistingJob(photo, documentId, guid, true)
							.catch(() => alert('There was an error creating the photo. Please try again.'));
					} else {
						fire.updateDocument(documentRef as firebase.firestore.DocumentReference, {
							LocalGuid: guid,
						});
					}
				});
		});
	}

	async fileToBase64(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			if (file instanceof Blob) {
				reader.readAsDataURL(file);
				reader.addEventListener('load', () => {
					resolve(reader.result as any);
				});
				reader.addEventListener('error', error => {
					reject(error);
				});
			} else {
				reject();
			}
		});
	}

	async setPhotoForCreateJob(photo: ArrayBuffer, previousGuid: string | null) {
		const guid = previousGuid === null ? generateGuid() : previousGuid;
		return this.db.transaction('rw', this.db.createjobPhotos, async () => {
			await this.db.createjobPhotos.add({
				guid,
				file: photo,
			});
			return guid;
		});
	}

	getPhotosForCreateJob(guid: string) {
		return this.db.transaction('r', this.db.createjobPhotos, async () => {
			return await this.db.createjobPhotos
				.where('guid')
				.equals(guid)
				.toArray();
		});
	}

	removePhotosCreateJobPhotos(guid: string) {
		return this.db.transaction('rw!', this.db.createjobPhotos, async () => {
			return await this.db.createjobPhotos
				.where('guid')
				.equals(guid)
				.delete();
		});
	}

	removePhotosForRequest() {
		return this.db.transaction('rw!', this.db.createjobPhotos, async () => {
			return await this.db.createjobPhotos.clear();
		});
	}

	getPhotos(documentId: string) {
		return this.db.transaction('r!', this.db.documents, async () => {
			let photosFromDocuments = await this.db.documents
				.where({ documentId: documentId, mimeType: MIME_TYPE_IMAGE })
				.toArray()
				.then(documents => {
					let photosFromDocuments: indexDb.Photos[] = [];
					let sortedPhotos: indexDb.Photos[] = [];

					documents.forEach(document => {
						let photo = this.parseDocumentToPhoto(document);
						if (!isNullOrUndefined(photo)) {
							photosFromDocuments.push(photo);
						}
					});

					if (photosFromDocuments && photosFromDocuments.length) {
						sortedPhotos = sortIDBPhotosByCreatedDate(photosFromDocuments);
					}

					return photosFromDocuments;
				});

			return photosFromDocuments;
		});
	}

	getPhotosCard(documentId: string) {
		return this.db.transaction('r!', this.db.documents, async () => {
			let photosFromDocuments = await this.db.documents
				.where({ documentId: documentId, mimeType: MIME_TYPE_IMAGE })
				.toArray()
				.then(documents => {
					let photosFromDocuments: indexDb.Photos[] = [];
					let sortedPhotos: indexDb.Photos[] = [];
					documents.forEach(document => {
						let photo = this.parseDocumentToPhoto(document);
						if (!isNullOrUndefined(photo)) {
							photosFromDocuments.push(photo);
						}
					});

					if (photosFromDocuments && photosFromDocuments.length) {
						sortedPhotos = sortIDBPhotosByCreatedDate(photosFromDocuments);
					}

					return sortedPhotos;
				});

			return photosFromDocuments;
		});
	}

	getDocuments(documentId: string) {
		return this.db.transaction('r!', this.db.documents, async () => {
			return await this.db.documents.where({ documentId: documentId, mimeType: MIME_TYPE_DOCUMENT }).toArray();
		});
	}

	async removeAttachmentsForJob(documentId: string) {
		this.removePhotosForJob(documentId);
		this.removeDocumentsForJob(documentId);
	}

	async removePhotosForJob(documentId: string) {
		return this.db.transaction('rw!', this.db.jobphotos, async () => {
			return await this.db.jobphotos
				.where('documentId')
				.equals(documentId)
				.delete();
		});
	}

	async removeDocumentsForJob(documentId: string) {
		return this.db.transaction('rw!', this.db.documents, async () => {
			return await this.db.documents
				.where('documentId')
				.equals(documentId)
				.delete();
		});
	}

	async hasPhotosAttached(documentId: string) {
		return this.db.transaction('r', this.db.jobphotos, async () => {
			return this.db.jobphotos
				.where('documentId')
				.equals(documentId)
				.toArray()
				.then(arr => arr.length > 0);
		});
	}

	removePhotoByGuid(guid: string) {
		return this.db.transaction('rw!', this.db.documents, async () => {
			return await this.db.documents
				.where('guid')
				.equals(guid)
				.delete();
		});
	}

	removeDocumentByGuid(guid: string) {
		return this.db.transaction('rw!', this.db.documents, async () => {
			return await this.db.documents
				.where('guid')
				.equals(guid)
				.delete();
		});
	}

	async syncPhotosFromFirebase(documentId: string, Collection: string, isComplete: string, reloadFunction: any) {
		await this.getPhotos(documentId).then(photos => {
			if (!isNullOrUndefined(photos)) {
				photos.forEach(photo => {
					if (photo.file.byteLength === new ArrayBuffer(0).byteLength) {
						this.removePhotoByGuid(photo.guid);
					}
				});
			}
		});

		await fire
			.getPhotos(documentId, Collection)
			.get()
			.then(photos => {
				this.processSyncPhotos(photos, documentId, isComplete, reloadFunction)
			})
			.catch(err => console.error(err));

		await fire
			.getDocuments(documentId, Collection)
			.get()
			.then(photos => {
				this.processSyncPhotos(photos, documentId, isComplete, reloadFunction)
			})
			.catch(err => console.error(err));

		if (reloadFunction != null)
			reloadFunction();
	}

	async syncDocumentsFromFirebase(documentId: string, Collection: string, isComplete: string, reloadFunction: (() => void) | null) {
		await fire
			.getDocuments(documentId, Collection)
			.get()
			.then(photos => this.processSyncPhotos(photos, documentId, isComplete, reloadFunction))
			.catch(err => console.error(err));

		if (reloadFunction != null)
			reloadFunction();
	}

	processSyncPhotos(
		photos: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>,
		documentId: string,
		isComplete: string,
		functionCallback: (() => void) | null
	) {

		photos.forEach(async document => {
			const photoObj = document.data() as Job.JobPhoto;
			const guid = isNullOrUndefined(photoObj.Guid) ? generateGuid() : photoObj.Guid;

			const exists = (await this.photoExistsByGuid(guid)) || (await this.documentExistsByGuid(guid));

			if (exists) { // id document exists, update tags
				await this.db.transaction('rw!', this.db.documents, async () => {
					const indexDbDocument = await this.db.documents.get({ guid });

					if (indexDbDocument !== undefined) {
						indexDbDocument.tags = photoObj.DocumentTags ? photoObj.DocumentTags.map(tag => ({ tagName: tag.TagName, tagFBID: tag.TagFBID } as indexDb.DocumentTag)) : [];
						await this.db.documents.put(indexDbDocument);
					}
					return;
				});
			} else { // if document does not exist, create new document
				if (!isNullOrUndefined(photoObj.FirebaseStoragePath)) {
					this.downloadPhotoFromFirebase(
						photoObj.FirebaseStoragePath,
						documentId,
						photoObj.Filename || '',
						guid,
						document.ref,
						isComplete,
						document.id,
						photoObj.UploadedBy,
						photoObj.Source,
						photoObj.DateCreated,
						photoObj.JobTaskNumber,
						photoObj.DocumentTags,
						functionCallback
					);
				} else if (!isNullOrUndefined(photoObj.AzureImage)) {
					this.downloadPhotoFromFirebase(
						photoObj.AzureImage,
						documentId,
						photoObj.Filename || '',
						guid,
						document.ref,
						isComplete,
						document.id,
						photoObj.UploadedBy,
						photoObj.Source,
						photoObj.DateCreated,
						photoObj.JobTaskNumber,
						photoObj.DocumentTags,
						functionCallback
					);
				}
			};
			if (functionCallback != null)
				functionCallback();
		});
	}

	downloadPhotoFromFirebase(
		firebaseUrl: string,
		documentId: string,
		documentFilename: string,
		guid: string,
		docRef: firebase.firestore.DocumentReference,
		isComplete: string,
		documentFBID: string,
		uploadedBy?: string,
		source?: string,
		dateCreated?: number,
		jobTaskNumber?: string,
		tags?: DocumentTag[],
		functionCallback?: (() => void) | null
	) {
		fetch(firebaseUrl)
			.then(response => response.arrayBuffer())
			.then(arrayBuffer => {
				this.savePhotoToLocal(
					arrayBuffer,
					documentId,
					isComplete,
					documentFBID,
					documentFilename,
					guid,
					undefined,
					undefined,
					uploadedBy,
					source,
					dateCreated,
					jobTaskNumber,
					tags ? tags.map(tag => ({ tagName: tag.TagName, tagFBID: tag.TagFBID } as indexDb.DocumentTag)) : undefined,
					functionCallback
				);
			})
			.then(() => docRef.update({ Guid: guid }))
			.catch(err => {
				this.savePhotoToLocal(new ArrayBuffer(0), documentId, isComplete, documentFBID, documentFilename, guid);
				console.error(err);
			});
	}

	addPhotoTags(guid: string, tag: indexDb.DocumentTag) {
		return this.db.transaction('rw!', this.db.documents, async () => {
			const indexDbDocument = await this.db.documents.get({ guid });

			if (indexDbDocument !== undefined) {
				if (indexDbDocument.tags === undefined) {
					indexDbDocument.tags = [tag];
				} else {
					indexDbDocument.tags.push(tag);
				}
				this.db.documents.put(indexDbDocument);
			}
		});
	}

	async photoExists(guid: string) {
		const document = await this.db.jobphotos.get({ guid });
		return document !== undefined;
	}

	updatePhotosToComplete(DocumentID: string) {
		this.db.transaction('rw!', this.db.documents, async () => {
			const photoArray = await this.db.documents
				.where('documentId')
				.equals(DocumentID)
				.toArray();

			photoArray.forEach(photo => {
				photo.isComplete = 'true';
				this.db.documents.put(photo);
			});
		});
	}

	updateDocumentsToComplete(DocumentID: string) {
		this.db.transaction('rw!', this.db.documents, async () => {
			const documentArray = await this.db.documents
				.where('documentId')
				.equals(DocumentID)
				.toArray();

			documentArray.forEach(document => {
				document.isComplete = 'true';
				this.db.documents.put(document);
			});
		});
	}

	updateFormPhotosToComplete(formId: string) {
		this.db.transaction('rw!', this.db.documents, async () => {
			const photoArray = await this.db.documents
				.where('formId')
				.equals(formId)
				.toArray();

			photoArray.forEach(photo => {
				photo.isComplete = 'true';
				this.db.documents.put(photo);
			});
		});
	}

	updateFormDocumentsToComplete(formId: string) {
		this.db.transaction('rw!', this.db.documents, async () => {
			const documentArray = await this.db.documents
				.where('formId')
				.equals(formId)
				.toArray();

			documentArray.forEach(photo => {
				photo.isComplete = 'true';
				this.db.documents.put(photo);
			});
		});
	}

	removeCompletedPhotosFromTable() {
		return this.db.transaction('rw!', this.db.jobphotos, async () => {
			return await this.db.jobphotos
				.where('isComplete')
				.equals('true')
				.delete();
		});
	}

	removeCompletedDocumentsFromTable() {
		return this.db.transaction('rw!', this.db.documents, async () => {
			return await this.db.documents
				.where('isComplete')
				.equals('true')
				.delete();
		});
	}

	photoExistsByDocumentId(documentId: string) {
		const photo = this.db.transaction('rw!', this.db.jobphotos, () => {
			return this.db.jobphotos
				.where('documentId')
				.equals(documentId)
				.first();
		});
		// alert('docid for Photo exist: ' + documentId);
		// alert(photo);
		return photo !== undefined;
	}

	signatureExistsByDocumentId(documentId: string) {
		const signature = this.db.transaction('rw!', this.db.jobphotos, async () => {
			return await this.db.jobphotos
				.where('documentId')
				.equals(documentId)
				.toArray();
		});

		// alert('Docid for Sig exist: ' + documentId);
		signature.then(array => {
			// alert(array.length);
			return array.length > 0;
		});
	}

	getPhotoByGuid(guid: string) {
		return this.db.transaction('r!', this.db.documents, async () => {
			return await this.db.documents
				.where('guid')
				.equals(guid)
				.first();
		});
	}

	removeLocalQueueByPhotoId(photoId: string) {
		return this.db.transaction('rw!', this.db.localQueue, async () => {
			return await this.db.localQueue
				.where('photoId')
				.equals(photoId)
				.delete();
		});
	}

	async removePhotobyGuid(guid: string) {
		const Photo = await this.getPhotoByGuid(guid);
		this.db.transaction('rw!', this.db.documents, async () => {
			return await this.db.documents
				.where('guid')
				.equals(guid)
				.delete();
		});

		return !isNullOrUndefined(Photo) ? Photo.photoId : '';
	}

	removelocalQueueByPhotoId(photoId: string) {
		return this.db.transaction('rw!', this.db.localQueue, async () => {
			return await this.db.localQueue
				.where('photoId')
				.equals(photoId)
				.delete();
		});
	}

	async deletePhotosByDocId(docId: string) {
		return this.db.transaction('rw!', this.db.documents, async () => {
			return await this.db.documents
				.where('documentId')
				.equals(docId)
				.delete();
		});
	}

	deleteDocumentsByDocId(docId: string) {
		return this.db.transaction('rw!', this.db.documents, async () => {
			return await this.db.documents
				.where('documentId')
				.equals(docId)
				.delete();
		});
	}

	async saveDocumentToLocal(
		document: File,
		documentId: string,
		isComplete: string,
		documentFBID: string,
		localGuid?: string,
		formId?: string,
		uploadedBy?: string,
		source?: string,
		dateCreated?: number,
		tags?: indexDb.DocumentTag[],
		businessType?: string
	) {
		//if a guid is already present use that, else generate new one
		const guid = localGuid === undefined ? generateGuid() : localGuid;
		const documentName = document.name;

		const arrayBuffer = await fileToArrayBuffer(document);
		const mimeType =
			(mime.lookup(documentName) || GetMimeType(documentName) || '').split('/')[0] === MIME_TYPE_IMAGE ? MIME_TYPE_IMAGE : MIME_TYPE_DOCUMENT;
		const nameRegexp = /(photo-)\d+\.\w{0,4}/g;

		//commits to indexdb
		await this.db.transaction('rw!', this.db.documents, async () => {
			await this.db.documents.add({
				documentId,
				document: arrayBuffer,
				documentName,
				guid,
				mimeType,
				isComplete,
				formId,
				uploadedBy,
				source: source || (nameRegexp.test(documentName) ? JOB_DOCUMENT_SOURCE_FROM_APP : JOB_DOCUMENT_SOURCE_OTHER),
				dateCreated,
				tags,
				businessType
			});
		});

		return { File: arrayBuffer, Guid: guid };
	}

	getPhotoByDocId(documentId: string) {
		return this.db.transaction('r!', this.db.documents, async () => {
			return await this.db.documents
				.where('documentId')
				.equals(documentId)
				.first();
		});
	}

	getDocumentByGuid(guid: string) {
		return this.db.transaction('r!', this.db.documents, async () => {
			return await this.db.documents
				.where('guid')
				.equals(guid)
				.first();
		});
	}

	getDocumentByDocId(documentId: string) {
		return this.db.transaction('r!', this.db.documents, async () => {
			return await this.db.documents
				.where('documentId')
				.equals(documentId)
				.first();
		});
	}

	async photoExistsByDocId(documentId: string) {
		const document = await this.db.documents.get({ documentId });
		return document !== undefined;
	}

	async photoExistsByGuid(guid: string) {
		const document = await this.db.documents.get({ guid });
		return document !== undefined;
	}

	async documentExistsByGuid(guid: string) {
		const document = await this.db.documents.get({ guid });
		return document !== undefined;
	}

	async documentExistsByDocumentFBID(documentFBID: string) {
		const document = await this.db.documents.get({ documentFBID });
		return document !== undefined;
	}

	async downloadQuestionAnswerPhotoFromFirebase(
		firebaseUrl: string,
		documentId: string,
		guid: string,
		PhotoFBID: string,
		PhotoFileName: string,
		formComplete: string,
		formId: string,
		formBusinessType?: string
	) {
		await fetch(firebaseUrl)
			.then(response => response.arrayBuffer())
			.then(async arrayBuffer => {
				let businessType;
				if (formBusinessType === "Asset") {
					businessType = BusinessTypes.Asset
				} else if (formBusinessType === "Site") {
					businessType = BusinessTypes.Site
				} else if (formBusinessType === "Location") {
					businessType = BusinessTypes.Locations
				} else if (formBusinessType === "Sublocation") {
					businessType = BusinessTypes.SubLocations
				} else {
					businessType = BusinessTypes.Job
				}
				await this.savePhotoToLocal(
					arrayBuffer,
					documentId,
					formComplete,
					PhotoFBID,
					PhotoFileName,
					guid,
					formId,
					PhotoFBID,
					undefined,
					undefined,
					undefined,
					undefined,
					undefined,
					undefined,
					businessType
				);
			})
			.catch(async err => {
				await this.savePhotoToLocal(
					new ArrayBuffer(0),
					documentId,
					formComplete,
					PhotoFBID,
					PhotoFileName,
					guid,
					formId,
					PhotoFBID,
				);
				console.log(err);
			});
	}

	async photoExistsByGuid2(guid: string) {
		const document = await this.db.transaction('rw!', this.db.documents, async () => {
			return await this.db.documents
				.where('guid')
				.equals(guid)
				.first();
		});

		return document !== undefined;
	}

	parseDocumentToPhoto(document: indexDb.Documents): indexDb.Photos | null {
		if (document.mimeType === MIME_TYPE_IMAGE) {
			return {
				id: document.id,
				documentId: document.documentId,
				file: document.document,
				fileName: document.documentName,
				guid: document.guid,
				photoId: undefined,
				isComplete: document.isComplete,
				formId: document.formId,
				uploadedBy: document.uploadedBy,
				source: document.source,
				dateCreated: document.dateCreated,
				jobTaskNumber: document.jobTaskNumber,
			};
		} else {
			return null;
		}
	}

	syncPhotosOrDocsFromFireBase = (
		path: string,
		docRefId: string,
		businessType: string,
		showRecentDocs?: boolean
	): Promise<any> => {
		return new Promise((resolve, reject) => {
			const docsCount: number = 3;
			let docQuery: any = fire.baseQuery
				.collection(path);
			if (showRecentDocs) {
				docQuery = docQuery.orderBy("DateCreated", "desc")
					.limit(docsCount);
			}
			docQuery
				.get()
				.then(async documents => {					
					if (documents.docs.length) {
						for (let doc of documents.docs) {
							const docObj = doc.data();
							const guid = docObj.Guid ? docObj.Guid : generateGuid();
							const exists = await this.documentExistsByGuid(guid);
							const docUrl = docObj.FirebaseUrl || docObj.FirebaseStoragePath ||
								docObj.AzureImage || docObj.AzureUrl;
							const fileName = docObj.FileName || docObj.Filename;
							if (!exists) {
								if (docUrl) {
									const blob = await fetch(docUrl)
										.then(response => {
											return response.blob();
										});
									const file = new File([blob], `${fileName}`);
									const arrayBuffer = await fileToArrayBuffer(file);
									const mimeType =
										(mime.lookup(fileName) ||
											GetMimeType(`${fileName}`) || '')
											.split('/')[0] === MIME_TYPE_IMAGE ?
											MIME_TYPE_IMAGE : MIME_TYPE_DOCUMENT;
									const nameRegexp = /(Contractor-Photo-)\d+\.\w{0,4}/g;
									await this.db.transaction('rw!', this.db.documents, async () => {
										await this.db.documents.add({
											documentId: docRefId,
											document: arrayBuffer,
											documentName: fileName || "",
											guid,
											mimeType,
											isComplete: "false",
											formId: "",
											uploadedBy: docObj.UploadedBy,
											source: docObj.Source || (nameRegexp.test(fileName || "") ? JOB_DOCUMENT_SOURCE_FROM_APP : JOB_DOCUMENT_SOURCE_OTHER),
											dateCreated: docObj.DateCreated,
											jobTaskNumber: docObj.JobTaskNumber,
											tags: docObj.DocumentTags ?
												docObj.DocumentTags
													.map(tag => ({ tagName: tag.TagName, tagFBID: tag.TagFBID } as indexDb.DocumentTag)) : undefined,
											documentFBID: undefined,
											businessType
										});
									});
								}
							}
						}
					}
					resolve(documents);
				}).catch(err => reject(err))
		})
	}

	async checkAndRemoveInactiveDocs(
		documentId: string,
		fbDocuments: any[]
	) {
		this.db.transaction('rw!', this.db.documents, async () => {
			const itemIdbDocs = await this.db.documents
				.where('documentId')
				.equals(documentId)
				.toArray();

			if (itemIdbDocs && itemIdbDocs.length) {
				const removedDocs = itemIdbDocs
					.filter(_idbDoc => fbDocuments.every(_fbDoc => _fbDoc.Guid !== _idbDoc.guid));
				if (removedDocs && removedDocs.length) {
					for (let rmDoc of removedDocs) {
						await this.db.documents
							.where('guid')
							.equals(rmDoc.guid)
							.delete()
					}
				}

			}
		});
	}

	async removeInactiveDocumentsFromIDB(
		businessType: string,
		itemIds: any[],
		completedItemIds?: any[]
	) {
		return this.db.transaction('rw!', this.db.documents, async () => {
			const allIdbDocuments = await this.db.documents
				.toArray();

			const currentDocs = allIdbDocuments && allIdbDocuments
				.filter(_doc => _doc.businessType === businessType);

			const inactiveDocs = currentDocs &&
				currentDocs.filter(item =>
					itemIds.every(_id => _id !== item.documentId)
					|| ((completedItemIds && completedItemIds.length) &&
						completedItemIds.some(_cid => _cid === item.documentId)));

			if (inactiveDocs && inactiveDocs.length) {
				const duplicatesRemoved = inactiveDocs.filter((value, index, self) =>
					index === self.findIndex((t) => (
						t.documentId === value.documentId && t.documentId === value.documentId
					))
				)

				for (let taskDoc of duplicatesRemoved) {
					await this.db.documents
						.where('documentId')
						.equals(taskDoc.documentId)
						.delete()
				}
			}

		});
	}

	/** Sync Documents from Firebase updated functions  start */

	syncDoumentsAndPhotosFromFb = (
		documentId: string,
		collection: string,
		isComplete: string,
		reloadFunction: any,
		setPhotosCallBack?: (fbPhotos: any[]) => void,
		showPhotosPagination?: boolean
	): Promise<any> => {
		return new Promise(async (resolve, reject) => {
			await this.getPhotos(documentId).then(photos => {
				if (photos && photos.length) {
					photos.forEach(photo => {
						if (photo.file.byteLength === new ArrayBuffer(0).byteLength) {
							this.removePhotoByGuid(photo.guid);
						}
					});
				}
			});

			await fire
				.getDocuments(documentId, collection)
				.get()
				.then(async photos => {
					let allPhotos: any[] = [];
					let allDocuments: any[] = [];
					let fireBaseDocuments: any[] = [];
					let photosAndDocsToShow: any[] = [];

					photos.docs.forEach(_document => {
						const currentData = _document.data();
						const FileName = currentData.Filename || currentData.FileName;
						if (FileName) {
							const docType = (mime.lookup()
								|| GetMimeType(FileName) || '')
								.split('/')[0] === MIME_TYPE_IMAGE ?
								MIME_TYPE_IMAGE : MIME_TYPE_DOCUMENT;

							const docObj = {
								...currentData,
								id: _document.id,
								ref: _document.ref,
								docType,
								Filename: FileName
							};

							fireBaseDocuments.push(docObj);

							if (docType === MIME_TYPE_IMAGE) {
								allPhotos.push(docObj)
							} else {
								allDocuments.push(docObj)
							}
						}
					});

					if (allPhotos.length) {
						const sortedPhotos = sortFBPhotosByCreatedDate(allPhotos);
						if (setPhotosCallBack) {
							setPhotosCallBack(sortedPhotos);
						}

						if (allPhotos.length > 5) {
							const firstFivePhotos = sortedPhotos.slice(0, 5);
							photosAndDocsToShow = [...firstFivePhotos, ...allDocuments];
						} else {
							photosAndDocsToShow = [...sortedPhotos, ...allDocuments];
						};
					} else {
						photosAndDocsToShow = fireBaseDocuments;
					}

					await idb.checkAndRemoveInactiveDocs(documentId, fireBaseDocuments);
					if (showPhotosPagination) {
						await this.processSyncedPhotosFromFb(
							photosAndDocsToShow,
							documentId,
							isComplete,
							reloadFunction,
							collection
						);
					} else {
						await this.processSyncedPhotosFromFb(
							fireBaseDocuments,
							documentId,
							isComplete,
							reloadFunction,
							collection
						);
					}

					resolve("success");
				})
				.catch(err => reject(err));
		})
	}

	async processSyncedPhotosFromFb(
		photos: any[],
		documentId: string,
		isComplete: string,
		functionCallback: (() => void) | null,
		collection: string
	) {
		for (let photoObj of photos) {
			let guid;
			if (photoObj.Guid) {
				guid = photoObj.Guid
			} else {
				const generatedGuid = generateGuid();
				await fire.updateDocumentGuid(
					documentId,
					collection,
					photoObj.id,
					{ Guid: generatedGuid }
				);
				guid = generatedGuid;
			}

			const exists = (await this.photoExistsByGuid(guid)) || (await this.documentExistsByGuid(guid));

			if (exists) {
				// id document exists, update tags
				await this.db.transaction('rw!', this.db.documents, async () => {
					const indexDbDocument = await this.db.documents.get({ guid });
					if (indexDbDocument !== undefined) {
						indexDbDocument.tags = photoObj.DocumentTags ? photoObj.DocumentTags.map(tag => ({ tagName: tag.TagName, tagFBID: tag.TagFBID } as indexDb.DocumentTag)) : [];
						await this.db.documents.put(indexDbDocument);
					}
				});
			} else {
				const imageUrl = photoObj.FirebaseStoragePath ||
					photoObj.FirebaseUrl ||
					photoObj.AzureImage ||
					photoObj.AzureUrl;

				if (imageUrl) {
					const imageArrayBuffer = await this.downloadPhotoByUrl(imageUrl);
					const businessType = getBusinessTypeByCollection(collection);

					const documentNameFormatted = photoObj.Filename
						|| `photo-${new Date().getTime()}.jpeg`;

					const mimeType =
						(mime.lookup(documentNameFormatted) || GetMimeType(documentNameFormatted) || '').split('/')[0] === MIME_TYPE_IMAGE
							? MIME_TYPE_IMAGE
							: MIME_TYPE_DOCUMENT;

					const nameRegexp = /(photo-)\d+\.\w{0,4}/g;

					const photoToAddObj: indexDb.Documents = {
						document: imageArrayBuffer,
						documentId: documentId,
						documentName: documentNameFormatted,
						isComplete: isComplete,
						mimeType,
						guid,
						formId: undefined,
						photoId: undefined,
						uploadedBy: photoObj.UploadedBy,
						dateCreated: photoObj.DateCreated,
						documentFBID: photoObj.id,
						jobTaskNumber: photoObj.JobTaskNumber,
						tags: photoObj.DocumentTags ?
							photoObj.DocumentTags
								.map(tag => ({ tagName: tag.TagName, tagFBID: tag.TagFBID } as indexDb.DocumentTag))
							: undefined,
						source: photoObj.Source || (nameRegexp.test(documentNameFormatted)
							? JOB_DOCUMENT_SOURCE_FROM_APP : JOB_DOCUMENT_SOURCE_OTHER),
						businessType
					}

					const addedItem = await this.saveImageToIndexDb(photoToAddObj);
				}
			}
		}
		if (functionCallback) {
			functionCallback();
		}
	}

	downloadPhotoByUrl = (imageUrl): Promise<any> => {
		return new Promise((resolve, reject) => {
			fetch(imageUrl)
				.then(response => {
					resolve(response.arrayBuffer());
				}).catch(err => reject(err))
		})
	}

	saveImageToIndexDb = (photoToSave: indexDb.Documents): Promise<any> => {
		return new Promise(async (resolve, reject) => {
			const guid = photoToSave.guid ? photoToSave.guid : generateGuid();
			if (await this.documentExistsByGuid(guid)) return guid;

			await this.db.transaction('rw!', this.db.documents, async () => {
				await this.db.documents.add(photoToSave).then(res => {
					resolve(res)
				})
			}).catch(err => {
				reject(err)
			})

		})
	}


	loadMorePhotosFromFB = (
		documentId: string,
		collection: string,
		isComplete: string,
		reloadFunction: any,
	): Promise<boolean> => {
		return new Promise(async (resolve, reject) => {
			await fire
				.getDocuments(documentId, collection)
				.get()
				.then(async photos => {
					const idbPhotos = await idb.getPhotos(documentId);
					const noOfPhotosToFetch = 5;
					const loadPhotosFrom = idbPhotos.length;
					const loadPhotosTo = idbPhotos.length + noOfPhotosToFetch;
					let allPhotos: any[] = [];
					let photosAndDocsToShow: any[] = [];
					let hasMorePhotos: boolean = true;
					photos.docs.forEach(_document => {
						const currentData = _document.data();
						const docType = (mime.lookup(currentData.Filename)
							|| GetMimeType(currentData.Filename) || '')
							.split('/')[0] === MIME_TYPE_IMAGE ?
							MIME_TYPE_IMAGE : MIME_TYPE_DOCUMENT;

						const docObj = {
							...currentData,
							id: _document.id,
							ref: _document.ref,
							docType
						};

						if (docType === MIME_TYPE_IMAGE) {
							allPhotos.push(docObj)
						}
					});

					if (allPhotos.length) {
						if (loadPhotosTo >= allPhotos.length) {
							hasMorePhotos = false
						}
						const sortedPhotos = sortFBPhotosByCreatedDate(allPhotos);
						const allRemaningPhotos = sortedPhotos.slice(loadPhotosFrom, loadPhotosTo);
						photosAndDocsToShow = allRemaningPhotos;
					}

					await this.processSyncedPhotosFromFb(
						photosAndDocsToShow,
						documentId,
						isComplete,
						reloadFunction,
						collection
					);
					resolve(hasMorePhotos);
				})
				.catch(err => reject(err));
		})
	}
	/** Sync Documents from Firebase updated functions  end */
}
