import { combineReducers } from 'redux';
import AssetReducer from './AssetReducer';
import JobsReducer from './JobReducer';
import UserReducer from './UserReducer';

const rootReducer = combineReducers({
	User: UserReducer,
	JobsInfo: JobsReducer,
	AssetsInfo: AssetReducer
});

export default rootReducer;
