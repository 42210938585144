/* eslint-disable prettier/prettier */
// React
import * as React from 'react';
import { Link } from 'react-router-dom';

// Material UI
import { isNullOrUndefined } from 'util';
import { Grid } from '@material-ui/core';

import {
	Icon,
	IconButton
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import OuterCard from '../shared/OuterCard';
import CardHeader from '../shared/CardHeader';
import AddAssetToJobButton from '../job/AddAssetToJobButton';


//card icon
import ASSET from '../../assets/images/icons/ASSET.svg'

const AssetJobList = props => {
	const { t, i18n } = useTranslation();

	const [expand, setExpand] = React.useState(false)

	const handleExpand = (value) => {
		setExpand(value)
	}


	return (
		<>
			<OuterCard styles="border-radius" padding="0px" marginBottom="15px">
				<CardHeader
					title={t("Assets")}
					titleIcon={ASSET}
					textColor="white"
					backgroundColor="#b821f3"
					expand={expand}
					handleExpand={handleExpand}
					showExpand={true}
				/>
				<>
					{expand && (
						<div className='margin-top'>
							<AddAssetToJobButton
								documentID={props.documentID}
								disabled={false}
							/>
						</div>
					)}

					{props.assets.map((assets: any, i: number) => {
						return (
							<>
								{expand && (<div
									key={i}
									style={{
										border: `2.5px solid var(--light-grey)`,
										marginBottom: '10px',
										padding: '10px',
									}}
									className="card-shadow-site site-card"
								>
									<Grid container>
										<Grid container xs={4} justify="flex-start" alignItems="flex-end">
											<div>
												<p style={{ overflowWrap: 'break-word' }} className="content-site">
													<strong>{assets.AssetCode}</strong>
												</p>
												<p className="label-asset" style={{ color: '#f12525' }}>{t("Asset Code")}</p>
												<br />
											</div>
										</Grid>

										<Grid container xs={4} justify="flex-start" alignItems="flex-end">
											<div>
												<p className="content-site">{assets.AssetClass}</p>
												<p className="label-asset" style={{ color: '#f12525' }}>{t("Asset Class")}</p>
												<br />
											</div>
										</Grid>

										<Grid container xs={4} justify="flex-end" alignItems="flex-end">
											<div>
												<IconButton
													onClick={() => {
														props.history.push(`/asset-nmdetails/${assets.AssetFBID}` + '/' + props.TaskId);
													}}
												>
													<Icon>outbound</Icon>
												</IconButton>
											</div>
										</Grid>

										<Grid container xs={12} justify="flex-start" alignItems="flex-end">
											<div>
												<p className="content-site" >{assets.AssetName}</p>
												<p className="label-asset" style={{ color: '#f12525' }}>{t("Asset Name")}</p>
											</div>
										</Grid>
									</Grid>
								</div>)}
							</>
						)
					})}
				</>
			</OuterCard>
		</>
	);
};

export default AssetJobList;
