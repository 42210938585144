import React, { useCallback, useEffect, useState } from 'react';
import {
    Scheduler,
    AgendaView,
    TimelineView,
    DayView,
    WeekView,
    MonthView,
} from '@progress/kendo-react-scheduler';
import '@progress/kendo-theme-material/dist/all.css';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';
import { History } from "history";
import { IntlProvider, LocalizationProvider, load, loadMessages } from '@progress/kendo-react-intl';
import dayjs from 'dayjs';
import '../../styles/job/job-list.css';
import JobSchedulerItem from './JobSchedulerItem';
import { getCalendarLocalizationMonths } from '../../utils/CalendarLocalizationImports';
import { SchedulerDateChangeEvent, SchedulerViewChangeEvent } from '@progress/kendo-react-scheduler/dist/npm/Scheduler';
import { CalendarViews, LocalStrorageVariables } from '../../const/common';
import { LocalstorageGetItem, LocalstorageSetItem } from '../../utils/LocalStorage';

const JobsCalendarView: React.FC<IProps> = ({
    userSettings,
    calendarData,
    history,
    translate
}) => {
    const today = dayjs().toDate();
    const [activeView, setActiveView] = useState<string>(CalendarViews.Day);
    const [activeDate, setActiveDate] = useState<Date>(today);

    useEffect(() => {
        const initilizeData = (async () => {
            const calendarMessages = {
                "scheduler": {
                    "today": translate('Today'),
                    "allDay": translate('All day'),
                    "showWorkDay": translate('Show Business Hours'),
                    "showFullDay": translate('Show Full Day'),
                    "dayViewTitle": translate('Day'),
                    "weekViewTitle": translate('Week'),
                    "monthViewTitle": translate('Month'),
                    "dateTitle": translate('Date'),
                    "noEvents": translate('No events'),
                    "timeTitle": translate("Time"),
                    "eventTitle": translate("Event"),
                    "allEvents": translate("All events"),
                    "timelineViewTitle": translate("Timeline"),
                    "agendaViewTitle": translate("Agenda")
                }
            };
            const months = getCalendarLocalizationMonths(userSettings.Culture);
            loadMessages(calendarMessages, userSettings.Language || "English");
            load(likelySubtags, currencyData, weekData, "", "", months || "", "", "");
            const selectedView = await LocalstorageGetItem(LocalStrorageVariables.CalendarView);
            setActiveView(selectedView ? JSON.parse(selectedView) : CalendarViews.Day);
            const selectedDate = await LocalstorageGetItem(LocalStrorageVariables.CalendarDate);
            setActiveDate(selectedDate ? new Date(selectedDate) : today);
        });

        initilizeData();
    }, [translate, userSettings]);

    const schedulerItem = (props) => {
        return (
            <JobSchedulerItem scheduleProps={props} history={history} />
        )
    }

    const onViewChange = useCallback((args: SchedulerViewChangeEvent) => {
        setActiveView(args.value);
        LocalstorageSetItem({ Key: LocalStrorageVariables.CalendarView, Value: args.value });
    }, []);

    const onDateChange = useCallback((args: SchedulerDateChangeEvent) => {
        setActiveDate(args.value);
        LocalstorageSetItem({
            Key: LocalStrorageVariables.CalendarDate,
            Value: `${args.value}`
        });
    }, []);

    return (
        <div>
            <LocalizationProvider language={userSettings.Language || "English"}>
                <IntlProvider locale={(userSettings.Culture === "zz" ? "en" : userSettings.Culture) || "en"}>
                    <Scheduler
                        data={calendarData}
                        item={schedulerItem}
                        onViewChange={onViewChange}
                        view={activeView}
                        onDateChange={onDateChange}
                        date={activeDate}
                    >
                        <AgendaView />
                        <TimelineView />
                        <DayView />
                        <WeekView className='jobCalendarWeekView' />
                        <MonthView />
                    </Scheduler>
                </IntlProvider>
            </LocalizationProvider>
        </div>
    )
}

export default JobsCalendarView;

interface IProps {
    userSettings: Store.UserSettings;
    calendarData: any[];
    history: History;
    translate: any;
}