// React
import * as React from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { match } from 'react-router';

// Config File
import Api from '../../apiConfiguration.json';

// Firebase
import { fire } from '../../index';

// Material UI
import { Button, Fab, Grid, Icon, IconButton } from '@material-ui/core';

// Components
import LoadingSpinner from '../../components/Spinners/LoadingSpinner';
import SideDrawer from '../../components/shared/SideDrawer';
import BackButton from '../../components/shared/BackButton';
import ResourceDetailsModal from '../../components/resource/ResourceDetailsModal';
import ResourceSearchModal from '../../components/resource/ResourceSearchModal';
import ResourceCard from '../../components/resource/ResourceCard';

// Utils
import { isNullOrUndefined } from 'util';
import { searchFreeTextInput } from '../../utils/resource/ResourceSearch';
import { LocalstorageRemoveItem, LocalstorageGetItem, LocalstorageSetItem } from '../../utils/LocalStorage';
import { windowError, SendErrorData } from '../../utils/WindowError';
import { getBaseURL } from '../../utils/getBaseURL';
import ApiKeyObj from '../../utils/ApiObjectKey';

// CSS
import '../../styles/resource/resource-list.css';
import { withTranslation } from 'react-i18next';

interface ResourceListScreenProps {
	history: History;
	match: match;
	UserSettings: Store.UserSettings;
	t: any;
}

interface ResourceListScreenState {
	resourceArray: Resource[];
	SelectedResource: Resource | null;
	lastLoadedResource: any;
	loading: boolean;
	loadingMore: boolean;
	LoadingPageGrid: boolean;
	endOfList: boolean;
	documentsPerPage: number;
	DocumentID: string;
	resourceDetailsModalOpen: boolean;
	teamList: any[];
	selectedTeam: string;
	tradeList: any[];
	selectedTrade: string;
	searchModalOpen: boolean;
	TradeString: string;
	TeamsString: string;
	yAxisScroll: number;
	selectedResourceArray: Resource[];
	contractorsToJob: string[];
}

class ResourceListScreen extends React.Component<ResourceListScreenProps, ResourceListScreenState> {
	private unsubscribe: any = null;

	constructor(props) {
		super(props);
		this.state = {
			resourceArray: [],
			SelectedResource: null,
			lastLoadedResource: null,
			loading: false,
			loadingMore: false,
			LoadingPageGrid: true,
			endOfList: false,
			documentsPerPage: 10,
			DocumentID: '',
			resourceDetailsModalOpen: false,
			teamList: [],
			selectedTeam: '',
			tradeList: [],
			selectedTrade: '',
			searchModalOpen: false,
			TradeString: '',
			TeamsString: '',
			yAxisScroll: 0,
			selectedResourceArray: [],
			contractorsToJob: []
		};
	}

	componentWillMount() {
		windowError(
			this.props.UserSettings.email,
			this.props.UserSettings.UserUID,
			this.props.UserSettings.ServerName,
			'ResourceList',
		);

		this.setState({ loading: true });
	}

	componentDidMount() {
		const { id } = this.props.match.params as any;
		this.setState({ DocumentID: id });

		fire.getContractorsForJob(id).then(data => {
			var job = data.data();
			var contractorArray: string[] = [];

			if(!isNullOrUndefined(job)){
				if(job.AssignedToResource != null && job.AssignedToResource != undefined){
					contractorArray.push(job.AssignedToResource.ResourceObjFBID);
				}
				if(job.AssignedToResourceMultiple != null){
				job.AssignedToResourceMultiple.forEach(contractor => {
					console.log(contractor);
					contractorArray.push(contractor.ResourceObjFBID);
				})
			}
			}	
			console.log(contractorArray);
			this.setState({contractorsToJob: contractorArray})
		});

		const LocalItem = LocalstorageGetItem('ResourceFilterOptions') || '{}';
		const JsonObj = JSON.parse(LocalItem);
		this.setState(
			{
				selectedTeam: JsonObj.selectedTeam || '',
				selectedTrade: JsonObj.selectedTrade || '',
			},
			() => {
				this.getResources();
				this.getTeams();
				this.getTrades();
			},
		);
	}

	getResources() {
		const resourceFilterOptions = {
			selectedTeam: this.state.selectedTeam,
			selectedTrade: this.state.selectedTrade,
		};

		this.setState({ yAxisScroll: window.scrollY, loading: true }, () => 
		{
			this.unsubscribe = fire
				.getResourceCollectionPaginated(this.state.documentsPerPage, this.props.UserSettings.CanViewResourcesInTheirTeams, this.state.lastLoadedResource, resourceFilterOptions)
				.get()
				.then(query => {
					this.handleResourceSnapshot(query);
				})
		});
	}

	handleResourceSnapshot = (query: any) => {
		if (query.empty) {
			this.setState({ loading: false, loadingMore: false, endOfList: true });
			window.scrollTo(0, this.state.yAxisScroll);
			if (isNullOrUndefined(this.state.lastLoadedResource)) this.setState({ resourceArray: [] });
			return;
		}

		let resourceArray = isNullOrUndefined(this.state.lastLoadedResource) ? [] : this.state.resourceArray;
		resourceArray.push(
			...query.docs.map(resourceRow => {
				const row = resourceRow.data() as Resource;
				row.ID = resourceRow.id;
				return row;
			}),
		);

		resourceArray.sort((a, b) => a.Name.toLowerCase().localeCompare(b.Name.toLowerCase()));
		resourceArray = resourceArray.filter(resource => !this.state.contractorsToJob.includes(resource.ID))

		this.setState({
			resourceArray,
			lastLoadedResource: query.docs[query.docs.length - 1],
			loading: false,
			loadingMore: false,
		}, () => {
			window.scrollTo(0, this.state.yAxisScroll);
		});
	};

	getTeams() {
		fire.getTeamCollection(this.props.UserSettings.CanViewResourcesInTheirTeams).onSnapshot(query => {
			let teamList = query.docs.map(row => {
				const name = row.data().Name;
				return { name: name, value: name };
			});

			this.setState({ teamList });
		});
	}

	getTrades() {
		fire.getTradesCollection().onSnapshot(query => {
			let tradeList = query.docs.map(row => {
				const name = row.data().Name;
				return { name: name, value: name };
			});

			this.setState({ tradeList });
		});
	}

	handleChooseResource(ResourceID: string, ResourceName: string, DocumentID: string, history: History, translate: any) {
		if (confirm(translate("Are you sure you wish to assign") + " " + ResourceName + " " + translate("to this job?"))) {
			fire.assignResourceToJob(DocumentID, ResourceName, ResourceID);

			const JobObj = {
				ResourceObjFBID: ResourceID,
				JobAction: 'AssignResourceToJob',
			};

			fire.postToJobQueue(DocumentID, JobObj);
			history.replace('/job-details/' + DocumentID);
		}
	}

	backButton = () => {
		if(this.state.DocumentID != 'list'){
			this.props.history.replace('/job-details/' + this.state.DocumentID);
		}

		else {
			window.history.back();
		}
	};

	handleLogout() {
		LocalstorageRemoveItem('NewJobDetails');
		LocalstorageRemoveItem('JobFilterOptions');
		LocalstorageRemoveItem('AssignedUserFilterOptions');
		LocalstorageRemoveItem('ResourceFilterOptions');
		LocalstorageRemoveItem('FormLibraryFilterOptions');
		fire.auth.signOut().then(() => location.reload());
	}

	handleCloseChooseResourceDetailModal = (
		SelectedResource: Resource | null,
		TradeString: string,
		TeamsString: string,
	) => {
		this.setState({
			resourceDetailsModalOpen: !this.state.resourceDetailsModalOpen,
			SelectedResource,
			TradeString,
			TeamsString,
		});
	};

	handleSearchModal = () => {
		this.setState({
			searchModalOpen: !this.state.searchModalOpen,
		});
	};

	handleTeamChange = (object: { name: string; value: string } | null) => {
		if (isNullOrUndefined(object)) this.setState({ selectedTeam: '' });
		else this.setState({ selectedTeam: object.name });
	};

	handleTradeChange = (object: { name: string; value: string } | null) => {
		if (isNullOrUndefined(object)) this.setState({ selectedTrade: '' });
		else this.setState({ selectedTrade: object.name });
	};

	handleClearSearch = () => {
		this.setState(
			{
				selectedTeam: '',
				selectedTrade: '',
				loading: true,
				lastLoadedResource: null,
			},
			() => {
				const SearchObject = { selectedTeam: '', selectedTrade: '' };
				LocalstorageSetItem({ Key: 'ResourceFilterOptions', Value: SearchObject });
				this.getResources();
			},
		);
	};

	areFiltersActive = () => {
		return this.state.selectedTeam !== '' || this.state.selectedTrade != '';
	};

	searchResources() {
		this.setState(
			{
				searchModalOpen: false,
				loading: true,
				lastLoadedResource: null,
			},
			() => {
				const SearchObject = {
					selectedTeam: this.state.selectedTeam,
					selectedTrade: this.state.selectedTrade,
				};
				LocalstorageSetItem({ Key: 'ResourceFilterOptions', Value: SearchObject });
				this.getResources();
			},
		);
	}

	render() {
		const title = this.state.DocumentID === 'list' ? this.props.t("Resource List") : this.props.t("Choose Resource");

		if (this.state.loading) {
			return <LoadingSpinner text={this.props.t("Loading resources...")} />;
		}

		if (!isNullOrUndefined(this.state.resourceArray) && this.state.resourceArray.length < 1) {
			return (
				<div>
					<SideDrawer
						history={this.props.history}
						title={title}
						colour="primary"
						handleLogout={this.handleLogout}
						User={this.props.UserSettings}
						versionApp={Api.VERSION}
						versionDb={Api.INDEXEDDB_VERSION}
						SendErrorData={SendErrorData}
						getBaseURL={getBaseURL}
						ApiKeyObj={ApiKeyObj}
						rightMenuButton= {<BackButton callbackMethod={this.backButton}/>}
					/>
					<Grid container={true} direction="column" justify="center" alignItems="center">
						<div className="main-resource" style={{ marginTop: '10px' }}>
							<div className="no-jobtasks-card">
								<h1 className="hot-pink">{this.props.t("There are no resources.")}</h1>
							</div>

							<Fab
								className={this.areFiltersActive() ? 'not-hidden' : 'hidden'}
								id="clear-search-fab"
								color="inherit"
								aria-label="Add"
								style={{ backgroundColor: 'var(--light-red)' }}
								onClick={() => this.handleClearSearch()}
							>
								<Icon style={{ color: 'white' }}>clear</Icon>
							</Fab>

							<Fab
								id="search-fab"
								color="inherit"
								aria-label="Add"
								style={{ backgroundColor: 'var(--light-blue)' }}
								onClick={() => this.handleSearchModal()}
							>
								<Icon style={{ color: 'white' }}>search</Icon>
							</Fab>
							
							<ResourceSearchModal
							searchModalOpen={this.state.searchModalOpen}
							handleSearchModal={this.handleSearchModal}
							teamList={this.state.teamList}
							selectedTeam={this.state.selectedTeam}
							handleTeamChange={this.handleTeamChange}
							tradeList={this.state.tradeList}
							selectedTrade={this.state.selectedTrade}
							handleTradeChange={this.handleTradeChange}
							searchList={() => this.searchResources()}
							canOnlyViewResourcesInTheirTeam={this.props.UserSettings.CanViewResourcesInTheirTeams}
						/>

						{this.state.SelectedResource !== null ? (
							<ResourceDetailsModal
								resourceDetailsModalOpen={this.state.resourceDetailsModalOpen}
								SelectedResource={this.state.SelectedResource}
								TradeString={this.state.TradeString}
								TeamsString={this.state.TeamsString}
								handleCloseChooseResourceDetailModal={() => this.handleCloseChooseResourceDetailModal(null, '', '')}
							/>
						) : null}
						</div>
					</Grid>
				</div>
			);
		}

		return (
			<div>
				<SideDrawer
					history={this.props.history}
					title={title}
					colour="primary"
					handleLogout={this.handleLogout}
					User={this.props.UserSettings}
					versionApp={Api.VERSION}
					versionDb={Api.INDEXEDDB_VERSION}
					SendErrorData={SendErrorData}
					getBaseURL={getBaseURL}
					ApiKeyObj={ApiKeyObj}
					rightMenuButton= {<div>
						{this.state.DocumentID != 'list' ? <BackButton callbackMethod={this.backButton} /> : ''}
					</div> }
				/>
				<Grid container={true} direction="column" justify="center" alignItems="center">
					<div className="main-resource" style={{ marginTop: '10px' }}>
						<ResourceCard
							resources={this.state.resourceArray}
							DocumentID={this.state.DocumentID}
							handleChooseResource={this.handleChooseResource}
							modalOpen={this.state.resourceDetailsModalOpen}
							handleCloseChooseResourceDetailModal={this.handleCloseChooseResourceDetailModal}
							history={this.props.history}
						/>

						{this.state.endOfList ? (
							<Button variant="outlined" size="large" fullWidth disabled>
								{this.props.t("All Resources Loaded")}
							</Button>
						) : (
							<Button variant="outlined" color="primary" size="large" fullWidth onClick={() => this.getResources()}>
								{this.props.t("Load More Resources")}
							</Button>
						)}

						<Fab
							className={this.areFiltersActive() ? 'not-hidden' : 'hidden'}
							id="clear-search-fab"
							color="inherit"
							aria-label="Add"
							style={{ backgroundColor: 'var(--light-red)' }}
							onClick={() => this.handleClearSearch()}
						>
							<Icon style={{ color: 'white' }}>clear</Icon>
						</Fab>

						<Fab
							id="search-fab"
							color="inherit"
							aria-label="Add"
							style={{ backgroundColor: 'var(--light-blue)' }}
							onClick={() => this.handleSearchModal()}
						>
							<Icon style={{ color: 'white' }}>search</Icon>
						</Fab>

						<ResourceSearchModal
							searchModalOpen={this.state.searchModalOpen}
							handleSearchModal={this.handleSearchModal}
							teamList={this.state.teamList}
							selectedTeam={this.state.selectedTeam}
							handleTeamChange={this.handleTeamChange}
							tradeList={this.state.tradeList}
							selectedTrade={this.state.selectedTrade}
							handleTradeChange={this.handleTradeChange}
							searchList={() => this.searchResources()}
							canOnlyViewResourcesInTheirTeam={this.props.UserSettings.CanViewResourcesInTheirTeams}
						/>

						{this.state.SelectedResource !== null ? (
							<ResourceDetailsModal
								resourceDetailsModalOpen={this.state.resourceDetailsModalOpen}
								SelectedResource={this.state.SelectedResource}
								TradeString={this.state.TradeString}
								TeamsString={this.state.TeamsString}
								handleCloseChooseResourceDetailModal={() => this.handleCloseChooseResourceDetailModal(null, '', '')}
							/>
						) : null}
					</div>
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
});

export default withTranslation()(connect(mapStateToProps)(ResourceListScreen));
