import { CURRENT_ASSETS, CURRENT_ASSETS_SCROLLY, CLEAR_ASSETS_STATE } from '../actions/AssetActions';

const initialState = {
    totalAssetsLoaded: 0,
    yAxisPos: 0
};

const AssetReducer = (state = initialState, action) => {

    switch (action.type) {
        case CURRENT_ASSETS:
            state = {
                ...state, totalAssetsLoaded: action.payload
            }
            return state;
        case CURRENT_ASSETS_SCROLLY:
            state = {
                ...state, yAxisPos: action.payload
            };
            return state;
        case CLEAR_ASSETS_STATE:
            state = initialState;
            return state;
        default:
            return state;
    }
};

export default AssetReducer;