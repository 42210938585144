const cardColour = status => {
	switch (status) {
		case 'Completed':
		case 'Submitted':
		case 'Approved':
		case 'Authorised':
		case 'Complete':
			return '#2E7D32';

		case 'User Confirmed Complete':
		case 'Contractor Confirmed Complete':
		case 'Resource Confirmed Complete':
		case 'Form Submitted':
			return '#00b309';

		case 'Pending':
			return '#9E9E9E';

		case 'Instructed':
			return '#FFC107';

		case 'More Work Required':
		case 'Re-Opened':
		case 'Form Not Submitted':
			return '#db8200';

		case 'Accepted':
		case 'Accepted Task':
		case 'Work Confirmed':
			return '#66BB6A';

		case 'Arrived':
		case 'Started':
			return '#FFC107';

		case 'Started Travel':
		case 'Travel Start':
			return '#2196F3';

		case 'Left Site':
			return '#F57F17';

		case 'Left Task':
			return '#F57F17';

		case 'Cancelled':
		case 'UnderQuery':
		case 'Declined':
		case 'Work Rejected':
			return '#c62828';

		case 'On Hold':
		case 'Rejected By User':
		case 'Requires Auth.':
		case 'Requires Authorisation':
			return '#d55858';

		default:
			return 'transparent';
	}
};

export default cardColour;
