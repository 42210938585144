import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../styles/storage-areas/storage-area-details.css';

interface StorageAreaCardProps {
	storageArea: StorageArea;
}

const StorageAreaCard = ({ storageArea }: StorageAreaCardProps) => {
	const [t, i18n] = useTranslation();
	const storageAreaFieldsToDisplay = [
		{ content: storageArea.StorageName, label: t('Storage Name'), hasLineBreak: true },
		{ content: storageArea.StorageCode, label: t('Storage Code'), hasLineBreak: true },
		{ content: storageArea.AssetName, label: t('Asset Name'), hasLineBreak: true },
		{ content: storageArea.AssetCode, label: t('Asset Code'), hasLineBreak: true },
		{ content: storageArea.Site, label: t('Site Name'), hasLineBreak: true },
		{ content: storageArea.SiteCode, label: t('Site Code'), hasLineBreak: true },
		{ content: storageArea.Location, label: t('Location Name'), hasLineBreak: true },
		{ content: storageArea.SubLocation, label: t('SubLocation Name'), hasLineBreak: true },
		{ content: storageArea.DefaultStockLine, label: t('Default Stock Line'), hasLineBreak: false },
	];
	return (
		<div className="storage-area-card">
			<Grid container>
				{storageAreaFieldsToDisplay.map((field, index) => (
					<Grid key={index} container item xs={6} justify="flex-start" alignItems="flex-end">
						<div>
							<p className={`content-storage-area ${index === 0 && 'first'}`}>{field.content}</p>
							<p className="label-storage-area">{field.label}</p>
							{field.hasLineBreak && <br />}
						</div>
					</Grid>
				))}
			</Grid>
		</div>
	);
};

export default StorageAreaCard;
