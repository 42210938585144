// React
import * as React from 'react';

// Firebase
import { fire } from '../../index';

// Material UI
import { FormControl, Select } from '@material-ui/core';

// Components
import QuestionLayout from './QuestionLayout';

// Utils
import { orderAlphabetically } from '../../utils/forms/FormHelpers';
import { useTranslation } from 'react-i18next';

interface DropdownProps {
	data: Forms.QuestionAnswer;
	formComplete: boolean;
}
interface DropdownState {
	answers: Forms.MultiSelectAnswer[];
	selectedAnswer: string | null | undefined;
	formComplete: boolean;
}

export default class DropdownQuestion extends React.Component<DropdownProps, DropdownState> {
	constructor(props: Readonly<DropdownProps>) {
		super(props);
		this.state = {
			answers: this.orderAlphabeticallyArray(this.props.data.MultiSelectAnswers),
			selectedAnswer: this.props.data.Answer,
			formComplete: this.props.formComplete,
		};
	}

	UNSAFE_componentWillReceiveProps(newProps: DropdownProps) {
		if (
			newProps.data.MultiSelectAnswers !== this.props.data.MultiSelectAnswers &&
			newProps.data.MultiSelectAnswers !== null
		) {
			this.setState({
				answers: this.orderAlphabeticallyArray(newProps.data.MultiSelectAnswers),
				formComplete: newProps.formComplete,
				// selectedAnswer: newProps.data.Answer,
			});
		}
		if (newProps.data.Answer !== this.props.data.Answer) {
			this.setState({ selectedAnswer: newProps.data.Answer, formComplete: newProps.formComplete });
		}
	}

	orderAlphabeticallyArray(list: Forms.MultiSelectAnswer[]) {
		return list.sort((a, b) => orderAlphabetically(a.Value, b.Value));
	}

	onClick = index => {
		const selectedAnswer: string = index.value.toString();
		this.setState({ selectedAnswer });

		this.props.data.questionRef.update({ Answer: selectedAnswer });
		fire.answerQuestion({ ...this.props.data, Answer: selectedAnswer });

		fire.UpdateFormAfterAnswer(this.props.data.FormFBID);
	};

	render() {
		return (
			<QuestionLayout>
				{this.props.data.QuestionTitle}
				<DropdownAnswer
					dropdownAnswers={this.state.answers}
					handleChange={this.onClick}
					selectedAnswer={this.state.selectedAnswer}
					formComplete={this.state.formComplete}
					questionID={this.props.data.QuestionID}
				/>
			</QuestionLayout>
		);
	}
}

const DropdownAnswer = props => {
	const [t, i18n] = useTranslation();
	if (props.dropdownAnswers === undefined || props.dropdownAnswers === null) return null;
	return (
		<FormControl classes={{ root: 'width-100' }}>
			<select
				data-questionid={props.questionID}
				disabled={props.formComplete}
				className="select-root"
				value={props.selectedAnswer}
				onChange={selection => props.handleChange(selection.target.options[selection.target.selectedIndex])}
			>
				<option key="0" value="0">
					{t("Please Select...")}
				</option>
				{props.dropdownAnswers.map(answer => (
					<option key={answer.Id} value={answer.Id}>
						{answer.Value}
					</option>
				))}
			</select>
		</FormControl>
	);
};
