import { useTranslation } from "react-i18next";
import React, { Fragment, useState } from 'react';

import useDocumentsCard from "../../hooks/DocumentsCard/useDocumentsCard";
import {Box, Chip, CircularProgress, Dialog, DialogContent, Icon, IconButton, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import DocumentDetails from "./DocumentDetails";
import OuterCard from "./OuterCard";
import { isIos, isIOS13 } from "../../utils/IsIos";
import { DocumentsCardHeader } from "./DocumentsCardHeader";
import CardHeader from '../shared/CardHeader';

import '../../styles/documents/document-card.css';
import DocumentTags from "./DocumentTags";

//card icon
import DOCUMENT from '../../assets/images/icons/DOCUMENT.svg'

type DocumentsCardProps = {
  associatedFBID: string;
  fromView: string;
};

const DocumentsCard = ({associatedFBID, fromView}: DocumentsCardProps) => {
  const {
		openDocumentDialog, 
		saveDocument, 
		handleDownloadDocument, 
		documents, 
		downloading, 
		activeRow, 
		detailsModalOpen, 
		toggleDetailsModal, 
		tagModalOpen,
		toggleTagModal,
		reloadDocuments: realoadDocuments,
		selectedRow
	} = useDocumentsCard(associatedFBID, fromView);
  const { t } = useTranslation();

  
	const [expand, setExpand] = useState(false);

	const handleExpand = (value: boolean) => {
		setExpand(value)
	}
		return (<>
			<OuterCard id="documents-card" padding="0px" marginBottom="15px" styles='border-radius'>
					<CardHeader title={t("Documents")} textColor="white" backgroundColor="#2196f3"
						actionButtonID="attach-document-button" actionButtonIcon="upload_file" 
						actionButtonText={t("Add")} actionButtonClickHandler={openDocumentDialog}
						showActionButton={true}
						expand={expand}
						handleExpand={handleExpand}
						showExpand={true}
						titleIcon={DOCUMENT} />
				{documents.length < 1 ? (
                        <div className='no-elements-card-text'>
                            {expand && <p>{t("No Documents")}</p>}
                        </div>
                    ) : <>{expand ? <Table>
					<DocumentsCardHeader></DocumentsCardHeader>
					<TableBody>
						{documents.map((row, i) => {
							console.log(row);
							return (
								<Fragment key={i}>
									<TableRow>
										<TableCell className="cell3" padding="none">
											{row.documentName}
											{isIos() && !isIOS13() ? null : (
													downloading && activeRow === i ? (
														<CircularProgress color="secondary" size={25} style={{ marginLeft: '12px' }} />
													) : (
														<IconButton onClick={() => handleDownloadDocument(row, i)} style={{ marginLeft: '12px' }}>
															<Icon style={{ color: 'black' }}>cloud_download</Icon>
														</IconButton>
													)
											)}
										</TableCell>
										<TableCell className="cell5" padding="none" align="center">
											<IconButton
												onClick={() => {
													toggleDetailsModal(row);
												}} >
												<Icon style={{ color: 'black' }}>info</Icon>
											</IconButton>
										</TableCell>
									</TableRow>
									<TableRow key={i + "-tags"}>
										<TableCell colSpan={2} className="document-tag-list" padding="none">
										{ row.tags && row.tags.length > 0 ?
											(
												<>
													{row.tags.map((tag, j) => {
														return (
															<Chip key={i + "-tags-" + j} className="document-tag" label={tag.tagName}/>
														);
													})}
												</>
											) : (
												<Chip className="document-tag document-tag-placeholder" label={t("Tags")} variant="outlined"/>
											)
										}
										<Box display="flex" justifyContent="center" alignItems="center">
											<IconButton onClick={() => toggleTagModal(row)}>
												<Icon>add</Icon>
											</IconButton>	
										</Box>
										</TableCell>
									</TableRow>
								</Fragment>
							);
						})}
					</TableBody>
				</Table> : <></>}</>}
				{/* Tag Dialog */}
				<Dialog open={tagModalOpen} onClose={() => toggleTagModal(null)} fullWidth={false} maxWidth={false}>
					<Icon style={{ position: 'absolute', cursor: 'pointer', top: 5, right: 5 }} onClick={() => toggleTagModal(null)}>
						close
					</Icon>
					<br />
					
					<DialogContent>
						<DocumentTags 
							selectedDocumentGuid={selectedRow.SelectedDocumentGuid} 
							associatedFBID={associatedFBID} 
							collection={fromView} 
							documentTags={selectedRow.DocumentTags}
							realoadDocuments={realoadDocuments}/>
					</DialogContent>
				</Dialog>
				{/* Document Details Dialog */}
				<Dialog open={detailsModalOpen} onClose={() => toggleDetailsModal(null)} fullWidth={false} maxWidth={false}>
					<Icon style={{ position: 'absolute', cursor: 'pointer', top: 5, right: 5 }} onClick={() => toggleDetailsModal(null)}>
						close
					</Icon>
					<br />
					
					<DialogContent>
						<DocumentDetails
							Filename={selectedRow.FileName}
							UploadedBy={selectedRow.UploadedBy}
							Source={selectedRow.Source}
							DateCreated={selectedRow.DateCreated}
						/>
					</DialogContent>
				</Dialog>
			</OuterCard>
      <input
          id="document-upload"
          type="file"
          onChange={e => saveDocument(e.target.files)}
          style={{ display: 'none' }}
        />
      </>
		);
}

export default DocumentsCard
