import * as React from 'react';
import { History } from 'history';
import { match } from 'react-router';
import { connect } from 'react-redux';

// Fire
import firebase from 'firebase';
import { fire } from '../../index';

// Shared Components
import SideDrawer from '../../components/shared/SideDrawer';
import BackButton from '../../components/shared/BackButton';

// Material UI
import {  Grid, TextField } from '@material-ui/core';
import {  Button } from '@material-ui/core';

import { isNullOrUndefined } from 'util';
import { generateFirebaseId } from '../../utils/Guids';
import Dexie from 'dexie';
import { IsChrome } from '../../utils/IsChrome';
import imgLogo from '../../assets/images/big-logo.png';
import { installOfflineWatcher } from '../../utils/Network';
import Api from '../../apiConfiguration.json';

// Actions
import { loginUser, loginUserError, loginUserSuccess } from '../../actions/UserActions';
import { SendErrorData } from '../../utils/WindowError';
import { getBaseURL } from '../../utils/getBaseURL';
import ApiKeyObj from '../../utils/ApiObjectKey';
import { withTranslation } from 'react-i18next';

interface PasswordResetProps {
	loginUser: () => void;
	loginUserSuccess: (user: firebase.User) => void;
	loginUserError: (error: firebase.auth.Error | string) => void;
    history: History;
    location: Location;
    match: match;
    User: Store.UserState;
    UserSettings: Store.UserSettings;
	t: any;
}

interface PasswordResetState {
    loading: boolean;
    userSettings: any;
    indexedDBVersion: number;
    password: string;
    LoginErrorMessage: string;
	offline: boolean;
	Email: string;
	loginVerified: boolean;
	clientFBID: string;
	serverName;
}

class PasswordReset extends React.Component<PasswordResetProps, PasswordResetState> {
    constructor(props) {
        super(props);
        this.state = {
            loading: false, 
            userSettings: null,
            indexedDBVersion: 0,
            password: '',
            LoginErrorMessage: '',
			offline: false,
			Email: '',
			loginVerified: false,
			clientFBID: '',
			serverName: ''
        }
    }

   async componentDidMount() {
        const { UserSettings } = this.props.User;
	    if(!isNullOrUndefined(UserSettings)){
			await this.setState({clientFBID: UserSettings.ClientUID, serverName: UserSettings.ServerName, Email: UserSettings.email});
		}

		installOfflineWatcher(offline => {
			this.setState({ offline });
		});

        await new Dexie('TrackplanManagerDB').open().then(async db => {
			this.setState({ indexedDBVersion: db.verno });
        });
    }

    backButton = () => {
		window.history.back();
	};

 
    handleReturnKey = e => {
		if (e.key === 'Enter') {
			this.setPassword();
		}
	};

    async setPassword() {
		if (this.state.password.length < 6) {
			this.setState({ LoginErrorMessage: this.props.t("Password must be six characters minimum.") });
			return;
		};

		var user = fire.auth.currentUser;
		var newPassword = this.state.password;	

		if (!isNullOrUndefined(user)){
			 user.updatePassword(newPassword).then(() => {
				if (!isNullOrUndefined(user)){
					const JobQueueObject = {
						JobAction: 'ResourceUpdatedPassword',
						Email: user.email,
						Password: this.state.password,
						ServerName: this.state.serverName,
						ClientUID: this.state.clientFBID,
						UID: user.uid
					};
				  fire.postToJobQueueLogin(generateFirebaseId(), JobQueueObject);
				  this.props.history.replace('/settings');
				  
				}
				
			}).catch(error => {
					console.log(error);
					return 
				});
		}
	}

	handleSubmit = () => {
		if (this.state.Email.length === 0) {
			this.setState({ LoginErrorMessage: this.props.t("Email not defined.") });
			return;
		}
		if (!this.state.Email.includes('@') || !this.state.Email.includes('.')) {
			this.setState({ LoginErrorMessage: this.props.t("Not a valid email.")});
			return;
		}
		if (this.state.password.length === 0) {
			this.setState({ LoginErrorMessage: this.props.t("Password not defined.")});
			return;
		}
		if (this.state.password.length === 0 && this.state.Email.length === 0) {
			this.setState({ LoginErrorMessage: this.props.t("Email & Password not defined.")});
			return;
		}
		this.loginFirebase();
	};

	loginFirebase(): void {
		this.props.loginUser();
		fire.auth
			.signInWithEmailAndPassword(this.state.Email, this.state.password)
			.then(async user => {
				const fbUser = user.user as firebase.User;
                const doesUserExist = await fire.doesUserExist(fbUser.uid);
				if (!doesUserExist) {
					this.setState({
						LoginErrorMessage: this.props.t("User not found. Please check login details and try again."),
					});
					fire.auth.signOut();
				} else {
					this.props.loginUserSuccess(fbUser);
					this.setState({loginVerified: true, password: '', LoginErrorMessage: ''});
				}
			})
			.catch((err: firebase.auth.Error) => this.setState({LoginErrorMessage: this.props.t("User not found. Please check login details and try again.")}));
	}

    render() {
        const { UserSettings } = this.props.User;
		if (!this.state.loginVerified) {
			return (
				<div className="outer-container">
					<SideDrawer
						history={this.props.history}
						title={this.props.t("Password Reset")}
                        User={this.props.UserSettings} 
						rightMenuButton={<BackButton callbackMethod={this.backButton} />}
                        colour="primary"
                        versionApp={Api.VERSION}
						versionDb={Api.INDEXEDDB_VERSION}
						SendErrorData={SendErrorData}
						getBaseURL={getBaseURL}
                        ApiKeyObj={ApiKeyObj}
					/>
					<div className="inputContainer">
						<img src={imgLogo} alt="Logo" width="300" id="logo" />
						<h1 className="title-text">{this.props.t("Please verify your login details to proceed.")}</h1>
						<TextField
							autoComplete={IsChrome()}
							id="email"
							label={this.props.t("Email")}
							className="text-inputs"
							value={this.state.Email}
							onChange={evt => this.setState({ Email: evt.target.value })}
							margin="normal"
							disabled={this.state.offline}
						/>
						<TextField
							autoComplete={IsChrome()}
							id="password"
							label={this.props.t("Password")}
							className="text-inputs"
							value={this.state.password}
							onChange={evt => this.setState({ password: evt.target.value })}
							margin="normal"
							type="password"
							disabled={this.state.offline}
							onKeyDown={e => this.handleReturnKey(e)}
						/>
						<p style={{ color: 'red' }}>{this.state.LoginErrorMessage}</p>
						<Button
							disabled={this.state.offline}
							variant="contained"
							id="loginBtn"
							className="login-buttons"
							onClick={() => this.handleSubmit()}
						>
							{this.props.t("Login")}
						</Button>

						{this.state.offline ? (
							<p style={{ marginTop: '10px', textAlign: 'center', color: 'red' }}>
								{this.props.t("Cannot login while offline. Please try again with an internet connection.")}
							</p>
						) : null}
					</div>
				</div>
			);
		} 
            else return (
				<Grid container={true} direction="column" justify="center" alignItems="stretch">
                     <SideDrawer
						history={this.props.history}
						title={this.props.t("Password Reset")}
						rightMenuButton={<BackButton callbackMethod={this.backButton} />}
                        colour="primary"
                        versionApp={Api.VERSION}
						versionDb={Api.INDEXEDDB_VERSION}
						SendErrorData={SendErrorData}
						getBaseURL={getBaseURL}
                        ApiKeyObj={ApiKeyObj}
                        User={this.props.UserSettings} 
					/>
                <div className="outer-container">
					<div className="inputContainer">
						<img src={imgLogo} alt="Logo" width="300" id="logo" />
						<h1 className="title-text">{this.props.t("Please Enter Your New Password")}</h1>
						<TextField
							autoComplete={IsChrome()}
							id="password"
							label={this.props.t("Password")}
							className="text-inputs"
							value={this.state.password}
							onChange={evt => this.setState({ password: evt.target.value })}
							margin="normal"
							type="password"
							disabled={this.state.offline}
						/>

						<p style={{ color: 'red' }}>{this.state.LoginErrorMessage}</p>
						<Button
							disabled={this.state.offline}
							variant="contained"
							id="loginBtn"
							className="login-buttons"
							onClick={() => this.setPassword()}
						>
							{this.props.t("Set Password")}
						</Button>
					</div>
				</div>   
				</Grid>  
            )
    }
}


const mapStateToProps = (state: Store.Store) => ({
    UserSettings: state.User.UserSettings,
    User: state.User
});

const mapDispatchToProps = dispatch => ({
	loginUser: () => dispatch(loginUser()),
	loginUserSuccess: user => dispatch(loginUserSuccess(user)),
	loginUserError: error => dispatch(loginUserError(error)),
});


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PasswordReset));  