// React
import * as React from 'react';
import { History } from 'history';

// dexie import for IndexedDB
import Dexie from 'dexie';

// sideDrawer logo
import imgLogo from '../../assets/images/logo.png';
import dimoLogo from '../../assets/images/dimo-logo-small.png';

// Material UI
import AppBar from '@material-ui/core/AppBar';
import {
	Divider,
	Icon,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	PropTypes,
	SwipeableDrawer,
	Toolbar,
} from '@material-ui/core';
import '../../styles/side-drawer/side-drawer.css';

// Utils
import { isNullOrUndefined } from 'util';
import { useTranslation, withTranslation } from 'react-i18next';

interface UserSettings {
	ClientUID: string;
	CanAccessAllJobs: boolean;
	CanAccessAllSites: boolean;
	AssetsEnabled?: boolean | null;
	CanViewResources?: boolean | null;
	CanViewFormLibrary?: boolean | null;
	CanCompleteJob?: boolean | null;
	Name: string;
	UserUID: string;
	email: string;
	ServerName: string | undefined;
	AskForCustomerSignature?: boolean;
	CanCreateRequest?: boolean;
	EnableArriveOnSite?: boolean;
	EnableStartTravel?: boolean;
	EnableTimesheets?: boolean;
	EnableStock?: boolean;
	RequireAccept?: boolean;
	StockEnabled?: boolean;
	RequirePhotoBeforeCompleting?: boolean;
	TakeCoordsWhenMoving?: boolean;
	Culture?: string | undefined;
	CurrentJobTask?: string | null | undefined;
	CurrentJobTaskStatus?: string | null | undefined;
	JobTaskLeaveLabel?: string | null | undefined;
	CanSelfDeliver?: boolean;
	MessageToken?: string | null;
	EnableFirebaseNotifications?: boolean;
	CanViewRequests?: boolean | null;
	Language?: string | undefined;
}

interface SideDrawerProps {
	history: History;
	title: string;
	rightMenuButton?: JSX.Element;
	User: UserSettings;
	title2?: string;
	colour: PropTypes.Color;
	handleLogout?: () => void;
	versionApp: string;
	versionDb: string;
	SendErrorData: (
		Email: string,
		UID: string,
		ServerName: string | undefined,
		Message: string,
		Source: string,
		AppScreeen: string,
	) => Promise<void>;
	getBaseURL: any;
	ApiKeyObj: any;
	t: any;
}

interface SideDrawerState {
	DrawerOpen: boolean;
	indexedDBVersion: number;
	logoImage: string;
}

class SideDrawer extends React.Component<SideDrawerProps, SideDrawerState> {
	constructor(props) {
		super(props);
		this.state = {
			DrawerOpen: false,
			indexedDBVersion: 0,
			logoImage: imgLogo
		};
	}

	updateDrawerOpen() {
		this.setState({ DrawerOpen: !this.state.DrawerOpen });
	}

	async componentDidMount() {
		await new Dexie('TrackplanManagerDB').open().then(async db => {
			this.setState({ indexedDBVersion: db.verno });
		}).catch(err => {
			window.location.reload();
		})

		this.checkIfDimo();
	}

	checkIfDimo() {
		var url = window.location.href;
		if (url.includes("appges.trackplanfm.com")) {
			this.setState({ logoImage: dimoLogo });
		}
	}

	render() {
		const UserSettings = this.props.User !== null ? this.props.User : null;
		const titleTwoExists = !isNullOrUndefined(this.props.title2);
		return (
			<div className='drawer-div-container'>
				<AppBar className='appbar'>
					<Toolbar>
						<Icon onClick={() => this.updateDrawerOpen()} className='menu-icon'>menu</Icon>
						<h1 className='appbar-title'>
							{this.props.title}
							<br />
							{titleTwoExists ? `${this.props.title2}` : ''}
						</h1>
						{this.props.rightMenuButton !== null ? this.props.rightMenuButton : null}
					</Toolbar>
				</AppBar>

				<SwipeableDrawer
					variant="temporary"
					open={this.state.DrawerOpen}
					onClose={() => this.updateDrawerOpen()}
					onOpen={() => this.setState({ DrawerOpen: !this.state.DrawerOpen })}
				>
					<List>
						<ListItem>
							<img src={this.state.logoImage} alt="Logo" width="175" id="logo" />
						</ListItem>

						<div>
							<JobList UserSettings={UserSettings} history={this.props.history} />
							<JobsCalendar history={this.props.history} />
							{(isNullOrUndefined(this.props.User.CanViewResources) || this.props.User.CanViewResources) && <ResourceList UserSettings={UserSettings} history={this.props.history} />}
							{(isNullOrUndefined(this.props.User.AssetsEnabled) || this.props.User.AssetsEnabled) && <AssetList UserSettings={UserSettings} history={this.props.history} formTemplateFBID={''} jobID={''} />}
							<SiteList UserSettings={UserSettings} history={this.props.history} />
							{(isNullOrUndefined(this.props.User.CanViewFormLibrary) || this.props.User.CanViewFormLibrary) && <FormLibrary UserSettings={UserSettings} history={this.props.history} />}
							<NewJob UserSettings={UserSettings} history={this.props.history} />
							{(isNullOrUndefined(this.props.User.StockEnabled) || this.props.User.StockEnabled) && <StorageAreas UserSettings={UserSettings} history={this.props.history} />}
							{(!isNullOrUndefined(this.props.User.CanViewRequests) && this.props.User.CanViewRequests) && <Requests UserSettings={UserSettings} history={this.props.history} />}
						</div>
					</List>

					<List className="details-list">
						<Divider />

						<SettingsScreen UserSettings={UserSettings} history={this.props.history} />
					</List>
				</SwipeableDrawer>
			</div>
		);
	}
}

export default withTranslation()(SideDrawer);

const JobList = props => {
	const [t, i18n] = useTranslation();
	return (
		<ListItem button={true} onClick={() => props.history.replace({ pathname: '/job-list', state: { clearScrollState: true } })}>
			<ListItemIcon>
				<Icon>list</Icon>
			</ListItemIcon>
			<ListItemText primary={t("Job List")} />
		</ListItem>
	)
};

const ResourceList = props => {
	const [t, i18n] = useTranslation();
	return (
		<ListItem button={true} onClick={() => props.history.replace('/resource-list/list')}>
			<ListItemIcon>
				<Icon>people</Icon>
			</ListItemIcon>
			<ListItemText primary={t("Resource List")} />
		</ListItem>
	)
};

const AssetList = props => {
	const [t, i18n] = useTranslation();
	return (
		<ListItem button={true} onClick={() => props.history.replace('/asset-list/list')}>
			<ListItemIcon>
				<Icon>important_devices</Icon>
			</ListItemIcon>
			<ListItemText primary={t("Asset List")} />
		</ListItem>
	)
};

const SiteList = props => {
	const [t, i18n] = useTranslation();
	return (
		<ListItem button={true} onClick={() => props.history.replace('/sites-list')}>
			<ListItemIcon>
				<Icon>explore</Icon>
			</ListItemIcon>
			<ListItemText primary={t("Site List")} />
		</ListItem>
	)
};

const NewJob = props => {
	const [t, i18n] = useTranslation();
	return (
		<ListItem button={true} onClick={() => props.history.replace('/new-job')}>
			<ListItemIcon>
				<Icon>add_circle</Icon>
			</ListItemIcon>
			<ListItemText primary={t("New Job")} />
		</ListItem>
	)
};

const FormLibrary = props => {
	const [t, i18n] = useTranslation();
	return (
		<ListItem button={true} onClick={() => props.history.replace('/form-library')}>
			<ListItemIcon>
				<Icon>description</Icon>
			</ListItemIcon>
			<ListItemText primary={t("Form Library")} />
		</ListItem>
	)
};

const SettingsScreen = props => {
	const [t, i18n] = useTranslation();
	return (
		<ListItem button={true} onClick={() => props.history.push('/settings')}>
			<ListItemIcon>
				<Icon>settings</Icon>
			</ListItemIcon>
			<ListItemText primary={t("Settings")} />
		</ListItem>
	)
};

const StorageAreas = props => {
	const [t, i18n] = useTranslation();
	return (
		<ListItem button={true} onClick={() => props.history.replace('/storage-areas-list')}>
			<ListItemIcon>
				<Icon>store</Icon>
			</ListItemIcon>
			<ListItemText primary={t("Storage Areas")} />
		</ListItem>
	)
};

const Requests = props => {
	const [t, i18n] = useTranslation();
	return (
		<ListItem button={true} onClick={() => props.history.replace('/requests-list')}>
			<ListItemIcon>
				<Icon>new_releases</Icon>
			</ListItemIcon>
			<ListItemText primary={t("Requests")} />
		</ListItem>
	)
};

const JobsCalendar = props => {
	const [t, i18n] = useTranslation();
	return (
		<ListItem button={true} onClick={() => props.history.replace({ pathname: '/jobs-calendar' })}>
			<ListItemIcon>
				<Icon>calendar_today</Icon>
			</ListItemIcon>
			<ListItemText primary={t("Calendar")} />
		</ListItem>
	)
};