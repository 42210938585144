import * as React from 'react';
import { useTranslation } from 'react-i18next';

// Search Pagination for Sites
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';

import { useReactSelectAsyncPaginate } from '../../../hooks/useReactSelectAsyncPaginate';
import ClearIndicator from './ClearIndicator';

/**
 * Describe options type for the React Select Async Paginate component.
 */
export type ReactSelectSiteOptionType<T = number> = {
	value: T;
	label: string;
};

export type AsyncPaginateSiteDropdownProps<OptionValueType, AdditionalType> = {
	loadSites: LoadOptions<ReactSelectSiteOptionType<OptionValueType>, AdditionalType>;
	siteFilter?: ReactSelectSiteOptionType<OptionValueType> | null;
	siteInputValue?: string;
	handleSiteFilter: (option: ReactSelectSiteOptionType<OptionValueType> | null) => void;
	additional?: AdditionalType;
	isClearable?: boolean;
	onClear?: () => void;
};

/**
 * A dropdown, used by AssetDetails and CreateNewForm modal, to select a site
 * @template OptionValueType The type of the value of the option, normally a number or a string. Should contains a site id or a site fbid
 * @template AdditionalType The type of the additional data passed to loadOptions, such as a pagination cursor
 */
export function AsyncPaginateSiteDropdown<OptionValueType, AdditionalType>(
	props: AsyncPaginateSiteDropdownProps<OptionValueType, AdditionalType>,
): JSX.Element {
	const [t, _i18n] = useTranslation();
	const [{ isMenuOpen, selectRef, showKeyboard }, { openMenu, closeMenu }] = useReactSelectAsyncPaginate();

	return (
		<AsyncPaginate
			id="site-helper"
			value={props.siteFilter}
			selectRef={selectRef}
			debounceTimeout={300}
			onFocus={openMenu}
			onBlur={closeMenu}
			menuIsOpen={isMenuOpen}
			onChange={(e, meta) => {
				if (meta.action === 'clear' && props.onClear) {
					props.onClear();
				}
				props.handleSiteFilter(e);
				closeMenu();
			}}
			blurInputOnSelect={true}
			loadOptions={props.loadSites}
			loadingMessage={() => t('Loading Sites...')}
			cacheUniqs={[props.siteFilter, props.siteInputValue]}
			noOptionsMessage={() => t('No Sites Found')}
			isSearchable={!showKeyboard}
			additional={props.additional}
			isClearable
			components={{
				ClearIndicator,
			}}
		/>
	);
}

export default function SiteDropdown<OptionValueType = number, AdditionalType = undefined>(
	props: AsyncPaginateSiteDropdownProps<OptionValueType, AdditionalType>,
) {
	const [t, i18n] = useTranslation();
	return (
		<div style={{ flex: '1' }}>
			<AsyncPaginateSiteDropdown<OptionValueType, AdditionalType>
				siteFilter={props.siteFilter}
				handleSiteFilter={props.handleSiteFilter}
				loadSites={props.loadSites}
				siteInputValue={props.siteInputValue}
				additional={props.additional}
			/>
			<br />
			<p className="field-label-asset">{t('Site')}</p>
			<br />
		</div>
	);
}
