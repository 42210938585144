import { LoadOptions } from 'react-select-async-paginate';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fire } from '../index';

const useSearchSelect = (
	collectionName: string,
	nameProperty: string,
	setSelectedOption: (selectedOption: any) => void,
	dependsOn?: any,
	dependsOnField?: string,
) => {
	const [t, i18n] = useTranslation();
	const ref = useRef<HTMLInputElement>();
	const [showKeyboardForMobiles, setShowKeyboardForMobiles] = useState(false);
	useEffect(() => {
		setShowKeyboardForMobiles(window.innerWidth < 600);
	}, [window.innerWidth]);

	const [isSelectOpen, setIsSelectOpen] = useState(false);
	const openSelect = () => {
		setIsSelectOpen(true);
		if (ref.current) {
			ref.current.focus();
		}
	};
	const closeSelect = () => {
		setIsSelectOpen(false);
	};
	const handleSelectedOptionChange = e => {
		setSelectedOption(e);
		closeSelect();
	};
	const clearSelectedOption = () => {
		setSelectedOption(null);
		closeSelect();
	};

	const getSelectOptions = ((input: string) => {
		return new Promise(resolve => {
			fire
				.getSelectOptions(input, collectionName, nameProperty, 'asc', dependsOn, dependsOnField)
				.then(document => handleQueryResults(document, resolve));
		});
	}) as LoadOptions;

	const handleQueryResults = (document, resolve) => {
		const options = document.docs.map(doc => {
			const option = {
				value: doc.id,
				label: doc.data()[nameProperty],
				fields: doc.data(),
			};
			return option;
		});
		return resolve({
			options,
		});
	};

	const getLoadingMessage = () => t('Loading') + ' ' + collectionName + '...';
	const getNoOptionsMessage = () => t('No') + ' ' + collectionName + ' ' + t('Found');

	return {
		ref,
		showKeyboardForMobiles,
		isSelectOpen,
		openSelect,
		closeSelect,
		handleSelectedOptionChange,
		clearSelectedOption,
		getSelectOptions,
		getLoadingMessage,
		getNoOptionsMessage,
	};
};

export default useSearchSelect;
