import {QrReader} from '@blackbox-vision/react-qr-reader';
import {Button, Dialog, DialogActions} from '@material-ui/core';
import {Result} from '@zxing/library';
import {History} from 'history';
import React from 'react';
import {useTranslation} from 'react-i18next';

interface QRCodeDialogProps {
    isOpen : boolean;
    close : () => void;
    history?: History;
    readQRCodeAndNavigate?: (QRCode : Result, history : any, DocumentID?: string, JobTaskID?: string, DocumentType?: AddToAssetDocumentType, QuestionAnswerID?: string | null, translate?: any) => void;
    readAndAddQRCode?: (QRCode : Result) => void;
}

const QRCodeDialog = ({isOpen, close, history, readQRCodeAndNavigate, readAndAddQRCode} : QRCodeDialogProps) => {
    const {t, i18n} = useTranslation();
    return (
        <Dialog fullWidth maxWidth={'xs'} open={isOpen}>
            <QrReader
                onResult={result => {
                if (result) {
                    if (readQRCodeAndNavigate) {
                        readQRCodeAndNavigate(result, history as History);
                    } else {
                        if (readAndAddQRCode) {
                            readAndAddQRCode(result);
                        }
                    }
                    close();
                }
            }}
                constraints={{
                facingMode: 'environment'
            }}/>
            <DialogActions>
                <Button onClick={close} color="primary">
                    {t("Close")}
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default QRCodeDialog;
