/*
As of 11/11/2019

To Disable Auto-fill (autocomplete on input) on chrome you must use "new-password".
Stackoverflow: https://stackoverflow.com/questions/50604671/programmatically-disabling-chrome-auto-fill

Autocomplete no longer works on browsers for some reason. Apparently they are now ignored.
Adding a random string to autocomplete seems to work for now.
*/

export const IsChrome = () => {
	if (navigator.userAgent.indexOf('Chrome') != -1) return 'new-password';
	return 'nope';
};
