import React from 'react';
import { useTranslation } from 'react-i18next';
import Request from '../../definitions/request';
import '../../styles/requests/request-card.css';
import Colors from '../../utils/Colors';
import { unixToDateStringForRequestListOnly } from '../../utils/Times';

interface RequestCardProps {
	request: Request;
}

const RequestCard = ({ request }: RequestCardProps) => {
	const [t, i18n] = useTranslation();
	const statusColor = Colors(request.RequestStatus);
	const requestDetails = request.Details.length > 200 ? request.Details.substring(0, 200) + '...' : request.Details;
	const numberAndStatusStyle = {
		backgroundColor: `${statusColor}`,
		color: statusColor === 'transparent' ? 'black' : 'white',
	};

	return (
		<div
			className="request-card"
			style={{
				border: `2px solid ${statusColor}`,
				borderLeft: `7.5px solid ${statusColor}`,
			}}
		>
			<div className="request-top-row">
				<div className="request-status-container">
					<p className="request-status" style={numberAndStatusStyle}>
						{request.RequestNumber}
					</p>
				</div>
				<div className="request-status-container">
					<p className="request-status" style={numberAndStatusStyle}>
						{t(request.RequestStatus)}
					</p>
				</div>
			</div>
			<hr style={{ width: '100%', border: 'none', borderTop: `1px solid ${statusColor}` }} />
			<div>
				<div className="request-content-container">
					<p className="request-header">{t("Details") + ": "}</p>
					<p className="request-content" style={{ overflow: 'hidden' }}>
						{requestDetails}
					</p>
				</div>
				<div className="request-content-container">
					<p className="request-header">{t("Site") + ": "}</p>
					<p className="request-content">{request.Site}</p>
				</div>
				<div className="request-content-container">
					<p className="request-header">{t("Location") + ": "}  </p>
					<p className="request-content">{request.Location}</p>
				</div>
				<div className="request-content-container">
					<p className="request-header">{t("Raised By") + ": "}</p>
					<p className="request-content">{request.RaisedBy}</p>
				</div>
				<div className="request-content-container">
					<p className="request-header">{t("Created") + ": "}</p>
					<p className="request-content">{typeof request.Created === 'number' ? unixToDateStringForRequestListOnly(request.Created) : request.Created}</p>
				</div>
			</div>
		</div>
	);
};

export default RequestCard;
