import * as React from 'react';
import AddAssetButton from '../asset/AddAssetButton';

interface AddAssetToJobButtonProps {
	documentID: string;
	disabled: boolean;
}

const AddAssetToJobButton = (props: AddAssetToJobButtonProps) => {
	const { documentID, disabled } = props;
	return <AddAssetButton documentID={documentID} documentType="job" disabled={disabled} formTemplateFBID={''} jobID={documentID} />;
};

export default AddAssetToJobButton;
