import { Icon, IconButton, InputLabel } from '@material-ui/core';
import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import useSearchSelect from '../../hooks/useSearchSelect';
import '../../styles/search-select/search-select.css';

interface SearchSelectProps {
	label: string;
	collectionName: string;
	nameProperty: string;
	selectedOption: any;
	setSelectedOption: (selectedOption: any) => void;
	isDisabled: boolean;
	dependsOn?: any;
	dependsOnField?: string;
}

const SearchSelect = (props: SearchSelectProps) => {
	const {
		ref,
		showKeyboardForMobiles,
		isSelectOpen,
		openSelect,
		closeSelect,
		handleSelectedOptionChange,
		clearSelectedOption,
		getSelectOptions,
		getLoadingMessage,
		getNoOptionsMessage,
	} = useSearchSelect(
		props.collectionName,
		props.nameProperty,
		props.setSelectedOption,
		props.dependsOn,
		props.dependsOnField,
	);

	return (
		<div className="select-container">
			<div className="select-sub-container">
				<InputLabel style={{ marginBottom: 5 }}>{props.label}</InputLabel>
				<AsyncPaginate
					blurInputOnSelect
					value={props.selectedOption}
					selectRef={ref}
					onFocus={openSelect}
					onBlur={closeSelect}
					menuIsOpen={isSelectOpen}
					onChange={handleSelectedOptionChange}
					cacheUniqs={[props.selectedOption, props.dependsOn]}
					loadOptions={getSelectOptions}
					loadingMessage={getLoadingMessage}
					noOptionsMessage={getNoOptionsMessage}
					isSearchable={!showKeyboardForMobiles}
					isDisabled={props.isDisabled}
				/>
			</div>
			{props.selectedOption && (
				<div className="clear-button-container">
					<div>
						<IconButton onClick={clearSelectedOption} className="clear-button">
							<Icon color="error">delete_forever</Icon>
						</IconButton>
					</div>
				</div>
			)}
		</div>
	);
};

export default SearchSelect;
