import { Icon, IconButton } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

// Search Pagination for Sites
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';

import { isNullOrUndefined } from 'util';
import { useReactSelectAsyncPaginate } from '../../../hooks/useReactSelectAsyncPaginate';
import ClearIndicator from './ClearIndicator';
import { ReactSelectSiteOptionType } from './SiteDropdown';

/**
 * Describe options type for the React Select Async Paginate component.
 */
export type ReactSelectLocationOptionType<T = number> = {
	value: T;
	label: string;
};

export type AsyncPaginateLocationDropdownProps<OptionValueType, AdditionalType> = {
	locationInputValue?: string;
	siteInputValue?: string;
	locationFilter?: ReactSelectLocationOptionType<OptionValueType> | null;
	siteFilter?: ReactSelectSiteOptionType<OptionValueType> | null;
	locationDisabled?: boolean;
	loadLocations: LoadOptions<ReactSelectLocationOptionType<OptionValueType>, AdditionalType>;
	handleLocationFilter: (option: ReactSelectLocationOptionType<OptionValueType> | null) => void;
	additional?: AdditionalType;
	isClearable?: boolean;
	onClear?: () => void;
};

/**
 * A dropdown, used by AssetDetails and CreateNewForm modal, to select a location
 * @template OptionValueType The type of the value of the option, normally a number or a string. Should contains a location id or a location fbid
 * @template AdditionalType The type of the additional data passed to loadOptions, such as a pagination cursor
 */
export function AsyncPaginateLocationDropdown<OptionValueType, AdditionalType>(
	props: AsyncPaginateLocationDropdownProps<OptionValueType, AdditionalType>,
) {
	const [t, _i18n] = useTranslation();

	// close the dropdown when it is disabled
	const [{ isMenuOpen, selectRef, showKeyboard }, { openMenu, closeMenu }] = useReactSelectAsyncPaginate(
		props.locationDisabled,
	);

	return (
		<AsyncPaginate
			id="location-helper"
			value={props.locationFilter}
			selectRef={selectRef}
			debounceTimeout={300}
			onFocus={openMenu}
			onBlur={closeMenu}
			menuIsOpen={isMenuOpen}
			onChange={(e, meta) => {
				if (meta.action === 'clear' && props.onClear) {
					props.onClear();
				}
				props.handleLocationFilter(e);
				closeMenu();
			}}
			blurInputOnSelect={true}
			loadOptions={props.loadLocations}
			loadingMessage={() => t('Loading Locations...')}
			cacheUniqs={[props.siteFilter, props.siteInputValue, props.locationFilter, props.locationInputValue]}
			isDisabled={props.locationDisabled}
			noOptionsMessage={() => t('No Locations Found')}
			isSearchable={!showKeyboard}
			additional={props.additional}
			isClearable={props.isClearable}
			components={{ ClearIndicator }}
		/>
	);
}

export default function LocationDropdown<OptionValueType = number, AdditionalType = undefined>({
	locationInputValue,
	siteInputValue,
	locationFilter,
	siteFilter,
	locationDisabled = false,
	clearSelectedSitesOrLocations,
	loadLocations,
	handleLocationFilter,
	additional,
}: AsyncPaginateLocationDropdownProps<OptionValueType, AdditionalType> & {
	clearSelectedSitesOrLocations: (typeId: 1 | 2 | 3) => void;
}) {
	const [t, i18n] = useTranslation();

	return (
		<div style={{ flex: '1' }}>
			<AsyncPaginateLocationDropdown<OptionValueType, AdditionalType>
				locationInputValue={locationInputValue}
				siteInputValue={siteInputValue}
				locationFilter={locationFilter}
				siteFilter={siteFilter}
				loadLocations={loadLocations}
				handleLocationFilter={handleLocationFilter}
				additional={additional}
				locationDisabled={locationDisabled}
			/>
			<br />
			<p className="field-label-asset">
				{t('Location')}{' '}
				{!isNullOrUndefined(locationFilter) && (
					<IconButton onClick={() => clearSelectedSitesOrLocations(2)} style={{ cursor: 'pointer' }}>
						<Icon color="error">delete_forever</Icon>
					</IconButton>
				)}{' '}
			</p>
			<br />
		</div>
	);
}
