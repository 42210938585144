// React
import * as React from 'react';
import { Link } from 'react-router-dom';

// Material UI
import { isNullOrUndefined } from 'util';
import { Grid } from '@material-ui/core';

import Colors from '../../utils/Colors';

import {
	Icon,
	IconButton
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';


const AssetJobCard = props => {
	return props.jobs.map((jobs: any, i: number) => {
        const [t, i18n] = useTranslation();
        const statusColor = Colors(jobs.JobStatus);

		return (
			<div
				key={i}
				style={{
					border: `2.5px solid var(--light-grey)`,
					marginBottom: '10px',
					padding: '10px',
				}}
				className="card-shadow-site site-card"
			>
				<Grid container>
					<Grid container xs={4} justify="flex-start" alignItems="flex-end">
						<div>
							<p style={{ overflowWrap: 'break-word' }} className="content-site">
								<strong>{t(jobs.JobStatus)}</strong>
							</p>
							<p className="label-asset">{t("Job Status")}</p>
							<br />
						</div>
					</Grid>

					<Grid container xs={4} justify="flex-start" alignItems="flex-end">
						<div>
							<p className="content-site">{jobs.JobNumber}</p>
							<p className="label-asset">{t("Job Number")}</p>
							<br />
						</div>
					</Grid>

                    <Grid container xs={4} justify="flex-end" alignItems="flex-end">
						<div>
                        <IconButton
                            onClick={() => {
                                props.detailsButton(jobs.JobFBID);
                            }}
                        >
                            <Icon>outbound</Icon>
                        </IconButton> 
						</div>
					</Grid>

					<Grid container xs={12} justify="flex-start" alignItems="flex-end">
						<div>
							<p className="content-site">{jobs.JobDetails}</p>
							<p className="label-asset">{t("Job Details")}</p>
						</div>
					</Grid>

				</Grid>
			</div>
		);
	});
};

export default AssetJobCard;
