// React
import * as React from 'react';

// Firebase
import { fire } from '../../index';

// Components
import QuestionLayout from './QuestionLayout';
import DateAnswer from '../form-answers/DateAnswer';

// Utils
import { stringtoUnixUTC, parseDate } from '../../utils/Times';
import { withTranslation } from 'react-i18next';

interface DateQuestionProps {
	data: Forms.QuestionAnswer;
	formComplete: boolean;
	t: any;
}

interface DateQuestionState {
	answer: string | null;
	error: boolean;
}

 class DateQuestion extends React.PureComponent<DateQuestionProps, DateQuestionState> {
	constructor(props: Readonly<DateQuestionProps>) {
		super(props);
		this.state = {
			answer: parseDate(this.props.data.QuestionType, this.props.data.Answer),
			error: false,
		};
	}

	UNSAFE_componentWillReceiveProps(newProps: DateQuestionProps) {
		if (newProps.data.Answer !== this.props.data.Answer) {
			this.setState({
				answer: parseDate(this.props.data.QuestionType, newProps.data.Answer),
			});
		}
	}

	handleInput = (value: string) => {
		this.setState({ answer: value });
	};

	updateFirebase = () => {
		const isDate = this.props.data.QuestionType === 'Date';
		let Answer = stringtoUnixUTC(this.state.answer || '', isDate).toString();
		if (Answer === 'NaN') Answer = '';
		//update question in firebase
		this.props.data.questionRef.update({ Answer }).catch(err => console.error(this.props.t("Error updating answer - ") + err));

		//add answer to job queue
		fire.answerQuestion({ ...this.props.data, Answer: Answer });

		fire.UpdateFormAfterAnswer(this.props.data.FormFBID);
	};

	render() {
		return (
			<QuestionLayout>
				{this.props.data.QuestionTitle}
				<DateAnswer
					answer={this.state.answer}
					isDateTime={this.props.data.QuestionType === 'DateTime'}
					handleDateInput={this.handleInput}
					updateFirebase={this.updateFirebase}
					formComplete={this.props.formComplete}
				/>
			</QuestionLayout>
		);
	}
}

export default withTranslation()(DateQuestion);
