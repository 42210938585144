import * as React from 'react';
import { useState, useRef, useEffect } from 'react';

// Search Pagination for Sites
import { AsyncPaginate } from 'react-select-async-paginate';

// Import Firebase
import { fire } from '../../index';

import { isNullOrUndefined } from 'util';

// Material UI
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
	Input,
	InputLabel,
	TextField,
	Icon,
	IconButton,
	NativeSelect,
	Checkbox,
	FormControlLabel,
	useMediaQuery,
	useTheme
} from '@material-ui/core';

import AutoCompleteDropDown from '../inputs/AutoCompleteDropdown';
import { useTranslation } from 'react-i18next';
import JobNumberSearch from './JobNumberSearch';

interface SearchJobsModalProps {
	jobNumberValue: string;
	handleJobNumber: (value: string) => void;
	[x: string]: any;
}

function SearchJobsModal(props: SearchJobsModalProps) {

	const [enableTextSearchSite, setTextSearchSite] = useState(false);
	const [enableTextSearchLocation, setTextSearchLocation] = useState(false);
	const [enableTextSearchSubLocation, setTextSearchSubLocation] = useState(false);
	const [siteMenuOpen, setSiteMenuOpen] = useState(false);
	const [locationMenuOpen, setLocationMenuOpen] = useState(false);
	const [sublocationMenuOpen, setSubLocationMenuOpen] = useState(false);
	const [showKeyboard, setShowKeyboard] = useState(false);

	const siteRef = useRef<HTMLInputElement>();
	const locationRef = useRef<HTMLInputElement>();
	const sublocationRef = useRef<HTMLInputElement>();

	useEffect(() => {

		// Update showKeyboard when window.innerWidth changes.
		updatePredicate();

	}, [window.innerWidth]);

	const updatePredicate = () => {
		setShowKeyboard(window.innerWidth < 600);
	}

	const openSiteMenu = () => {
		setSiteMenuOpen(true);
		if(!isNullOrUndefined(siteRef.current) && !isNullOrUndefined(siteRef.current.select)) {
			siteRef.current.focus();
		}
	}

	const closeSiteMenu = () => setSiteMenuOpen(false);

	const openLocationMenu = () => {
		setLocationMenuOpen(true);
		if(!isNullOrUndefined(locationRef.current) && !isNullOrUndefined(locationRef.current.select)) {
			locationRef.current.focus();
		}
	}

	const closeLocationMenu = () => setLocationMenuOpen(false);

	const openSubLocationMenu = () => {
		setSubLocationMenuOpen(true);
		if(!isNullOrUndefined(sublocationRef.current) && !isNullOrUndefined(sublocationRef.current.select)) {
			sublocationRef.current.focus();
		}
	}

	const closeSubLocationMenu = () => setSubLocationMenuOpen(false);
	const {t, i18n} = useTranslation();

	return (
		<Dialog
			open={props.searchModalOpen}
			onClose={() => props.handleSearchModal()}
			aria-labelledby={t("Job Search")}
			fullScreen={useMediaQuery(useTheme().breakpoints.down('xs'))}
		>
			<DialogContent id="content" style={{ height: '570px' }}>
				<Grid container spacing={5}>
					<Grid item xs={6}>
						<InputLabel htmlFor="open-closed-helper">{t("Open/Closed")}</InputLabel>
						<NativeSelect
							style={{ width: '100%' }}
							value={props.openClosedTaskFilter}
							onChange={selection => props.handleOpenClosedFilter(selection)}
							input={<Input name="openClosed" id="open-closed-helper" />}
							name={t("Open/Closed")}
						>
							<option value="None">{t("None")}</option>
							<option value="Open">{t("Open")}</option>
							<option value="Closed">{t("Closed")}</option>
						</NativeSelect>
					</Grid>

					<Grid item xs={6}>
						<InputLabel htmlFor="planned-reactive-helper">{t("Reactive/Planned")}</InputLabel>

						<NativeSelect
							style={{ width: '100%' }}
							value={props.plannedReactiveTaskFilter}
							onChange={selection => props.handlePlannedReactiveFilter(selection)}
							input={<Input name="plannedReactive" id="planned-reactive-helper" />}
							name={t("Reactive/Planned")}
						>
							<option value="None">{t("None")}</option>
							<option value="Reactive">{t("Reactive")}</option>
							<option value="Planned">{t("Planned")}</option>
						</NativeSelect>
					</Grid>

					<Grid item xs={6}>
						<InputLabel htmlFor="job-number">{t("Job Number")}</InputLabel>
						<JobNumberSearch value={props.jobNumberValue} onChange={props.handleJobNumber}/>
					</Grid>

					<Grid item xs={6}>
						<FormControlLabel
							control={
								<Checkbox
									style={{ zoom: 1.25, marginRight: 'auto' }}
									color="primary"
									checked={props.isDueTodayCheckboxInput}
									onChange={(event, value) => props.handleIsDueTodayCheckbox(value)}
								/>
							}
							label={t("Is Due Today")}
							labelPlacement="start"
						/>
					</Grid>

					<div style={{display: 'flex', columnGap: 10 ,width: '100%', margin: '10px 20px'}}>
						<div style={{flex: '1'}}>
							<InputLabel htmlFor="user-helper" style={{ marginBottom: 5 }}>{t("Assigned User")}</InputLabel>
							<AsyncPaginate
								id="user-helper"
								value={props.userFilter}
								onChange={props.handleUserFilter}
								loadOptions={props.loadUsers}
								blurInputOnSelect={true}
								loadingMessage={() => t("Loading Users...")}
								cacheUniqs={[props.userFilter]}
								noOptionsMessage={() => t("No Users Found")}
								isSearchable={false}
							/>
						</div>
						<div style={{flex: '0', marginTop: 28, justifySelf: 'flex-end'}}>
							{!isNullOrUndefined(props.userFilter) && props.userFilter !== '' && (
								<div>
									<IconButton onClick={() => props.clearSelectedUser()} style={{ cursor: 'pointer' }}>
										<Icon color="error">
											delete_forever
										</Icon>
									</IconButton>
								</div>
							)}
						</div>
					</div>

					<div style={{display: 'flex', columnGap: 10 ,width: '100%', margin: '10px 20px'}}>
						<div style={{flex: '1'}}>
							<p style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								<InputLabel htmlFor="site-helper" style={{ marginBottom: 5 }}>
									{t("Site")}
								</InputLabel>
								{
									showKeyboard && (
										<IconButton onClick={() => { setTextSearchSite(!enableTextSearchSite); openSiteMenu(); } }>
											<Icon style={{ color: enableTextSearchSite ? 'green' : '' }} >keyboard</Icon>	
										</IconButton>	
									)
								}	
							</p>
							<AsyncPaginate
								id="site-helper"
								value={props.siteFilter}
								selectRef={siteRef}
								onFocus={openSiteMenu}
								onBlur={closeSiteMenu}
								menuIsOpen={siteMenuOpen}
								onChange={(e) => { props.handleSiteFilter(e); setSiteMenuOpen(false); }}
								blurInputOnSelect={true}
								loadOptions={props.loadOptions}
								loadingMessage={() => t("Loading Sites...")}
								cacheUniqs={[props.siteFilter, props.siteValue]}
								noOptionsMessage={() => t("No Sites Found")}
								isSearchable={ showKeyboard ? enableTextSearchSite : true }
							/>
						</div>
						<div style={{flex: '0', marginTop: 28, justifySelf: 'flex-end'}}>
							{!isNullOrUndefined(props.siteFilter) && props.siteFilter !== '' && (
								<div>
									<IconButton onClick={() => props.clearSelectedSitesOrLocations(1)} style={{ cursor: 'pointer' }}>
										<Icon color="error">
											delete_forever
										</Icon>
									</IconButton>
								</div>
							)}
						</div>
					</div>

					<div style={{display: 'flex', columnGap: 10 ,width: '100%', margin: '10px 20px'}}>
						<div style={{flex: '1'}}>
							<p style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								<InputLabel htmlFor="location-helper" style={{ marginBottom: 5 }}>
									{t("Location")}
								</InputLabel>
								{
									(showKeyboard && !props.locationDisabled) && (
										<IconButton onClick={() => { setTextSearchLocation(!enableTextSearchLocation); openLocationMenu(); } }>
											<Icon style={{ color: enableTextSearchLocation ? 'green' : '' }} >keyboard</Icon>	
										</IconButton>		
									)
								}	
							</p>
							<AsyncPaginate
								id="location-helper"
								value={props.locationFilter}
								selectRef={locationRef}
								onFocus={openLocationMenu}
								onBlur={closeLocationMenu}
								menuIsOpen={locationMenuOpen}
								onChange={(e) => { props.handleLocationFilter(e); setLocationMenuOpen(false); }}
								blurInputOnSelect={true}
								loadOptions={props.loadLocations}
								loadingMessage={() => t("Loading Locations...")}
								cacheUniqs={[props.siteFilter, props.siteValue, props.locationFilter, props.locationValue]}
								isDisabled={props.locationDisabled}
								noOptionsMessage={() => t("No Locations Found")}
								isSearchable={ showKeyboard ? enableTextSearchLocation : true }
							/>
						</div>
						<div style={{flex: '0', marginTop: 28, justifySelf: 'flex-end'}}>
							{!isNullOrUndefined(props.locationFilter) && props.locationFilter !== '' && (
								<div>
									<IconButton onClick={() => props.clearSelectedSitesOrLocations(2)} style={{ cursor: 'pointer' }}>
										<Icon color="error">
											delete_forever
										</Icon>
									</IconButton>
								</div>
							)}
						</div>
					</div>

					<div style={{display: 'flex', columnGap: 10 ,width: '100%', margin: '10px 20px'}}>
						<div style={{flex: '1'}}>
							<p style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								<InputLabel htmlFor="sublocation-helper" style={{ marginBottom: 5 }}>
									{t("Sub Location")}
								</InputLabel>
								{
									(showKeyboard && !props.subLocationDisabled) && (
										<IconButton onClick={() => { setTextSearchSubLocation(!enableTextSearchSubLocation); openSubLocationMenu() } }>
											<Icon style={{ color: enableTextSearchSubLocation ? 'green' : '' }} >keyboard</Icon>	
										</IconButton>		
									)
								}	
							</p>
							<AsyncPaginate
								id="sublocation-helper"
								value={props.subLocationFilter}
								selectRef={sublocationRef}
								onFocus={openSubLocationMenu}
								onBlur={closeSubLocationMenu}
								menuIsOpen={sublocationMenuOpen}
								onChange={(e) => { props.handleSubLocationFilter(e); setSubLocationMenuOpen(false); }}
								blurInputOnSelect={true}
								loadOptions={props.loadSubLocations}
								loadingMessage={() => t("Loading Sub Locations...")}
								cacheUniqs={[props.siteFilter, props.siteValue, props.locationFilter, props.locationValue, props.subLocationValue, props.subLocationFilter]}
								isDisabled={props.subLocationDisabled}
								noOptionsMessage={() => t("No Sub Locations Found")}
								isSearchable={ showKeyboard ? enableTextSearchSubLocation : true }
							/>
						</div>
						<div style={{flex: '0', marginTop: 28, justifySelf: 'flex-end'}}>
							{!isNullOrUndefined(props.subLocationFilter) && props.subLocationFilter !== '' && (
								<div>
									<IconButton onClick={() => props.clearSelectedSitesOrLocations(3)} style={{ cursor: 'pointer' }}>
										<Icon color="error">
											delete_forever
										</Icon>
									</IconButton>
								</div>
							)}
						</div>
					</div>
				</Grid>
			</DialogContent>
			<DialogActions classes={{ root: 'action-buttons-list' }}>
				<Button onClick={props.handleSearchModal} color="primary">
					{t("Cancel")}
				</Button>
				<Button onClick={props.searchJobTasks} color="primary">
					{t("Search")}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default SearchJobsModal;
