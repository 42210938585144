export const CURRENT_JOBS = 'CURRENT_JOBS';
export const CURRENT_JOBS_SCROLLY = 'CURRENT_JOBS_SCROLLY';
export const CLEAR_JOBS_STATE = 'CLEAR_JOBS_STATE';

export function jobsLoaded(totalJobsLoaded : number) {
    return dispatch => {
        dispatch({
            type: CURRENT_JOBS,
            payload: totalJobsLoaded
        });
    };
}

export function currentScrollPosition(yAxisPos : number) {
    return dispatch => {
        dispatch({
            type: CURRENT_JOBS_SCROLLY,
            payload: yAxisPos
        });
    };
}

export function clearCurrentJobsInfo() {
    return dispatch => {
        dispatch({
            type: CLEAR_JOBS_STATE
        })
    }
}