import { SubLocationDropdownField } from './SiteLocSubLocFields';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { Grid, GridProps } from '@material-ui/core';
import firebase from 'firebase';
import { LocationDropdownField, SiteDropdownField } from './SiteLocSubLocFields';

import { ReactSelectSiteOptionType } from '../AsyncPaginateDropdowns/SiteDropdown';
import { ReactSelectLocationOptionType } from '../AsyncPaginateDropdowns/LocationDropdown';
import { ReactSelectSublocationOptionType } from '../AsyncPaginateDropdowns/SublocationDropdown';
import { ReactSelectAssetNameOptionType } from '../AsyncPaginateDropdowns/AssetNameDropdown';
import AssetDropdownField from './AssetField';

/* eslint-disable @typescript-eslint/no-non-null-assertion */

const GridChildren = (props: GridProps & { children: React.ReactNode }): JSX.Element => (
	<Grid item xs={12} {...props}>
		{props.children}
	</Grid>
);

/**
 *  @privacyRemarks Option's "value" type. We need both fbid and id because algolia use id to search items
 */
export type FBIDAndIDType = { fbid: string; id: number };

/**
 * State object container the states of the filters
 */
export type SiteSubLocAssetFilters = {
	site?: ReactSelectSiteOptionType<FBIDAndIDType> | null;
	location?: ReactSelectLocationOptionType<FBIDAndIDType> | null;
	subLocation?: ReactSelectSublocationOptionType<FBIDAndIDType> | null;
	/**
	 * Generic type is here an asset because we need extra infos
	 */
	assetName?: ReactSelectAssetNameOptionType<Asset> | null;
};

/**
 * @privacyRemarks This defines the type of the additional parameters passed to loadOptions
 */
export type ADDITIONAL_LOAD_OPTIONS<T> = {
	lastLoadedDocSnapshot?: firebase.firestore.DocumentSnapshot<T>; // for pagination
};

// we use a context to share the filters between the components
export type SiteSubLocAssetFormContextType = {
	filters: SiteSubLocAssetFilters;
	setFilters: React.Dispatch<React.SetStateAction<SiteSubLocAssetFilters>>;
};

export const SiteSublocAssetFiltersCtx = createContext<SiteSubLocAssetFormContextType>(
	{} as SiteSubLocAssetFormContextType,
);

const SiteSubLocAssetForm = ({
	onChange,
	gridParentProps,
	gridChildProps,
}: {
	/**
	 * Extra props to pass to the parent grid component.
	 */
	gridParentProps?: GridProps;
	/**
	 * Extra props to pass the children grid components (of the parent one).
	 */
	gridChildProps?: GridProps;
	onChange: (filters: SiteSubLocAssetFilters) => void;
}): JSX.Element => {
	const [filters, setFilters] = useState<SiteSubLocAssetFilters>({});

	// fire onChange only when filters change
	useEffect(() => {
		onChange(filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters]);

	// use a memo to prevent the context from re-rendering
	const ctxValue = useMemo(
		() => ({
			filters,
			setFilters,
		}),
		[filters],
	);
	return (
		<SiteSublocAssetFiltersCtx.Provider value={ctxValue}>
			<Grid container spacing={2} justify="center" {...gridParentProps}>
				<GridChildren {...gridChildProps}>
					<SiteDropdownField />
				</GridChildren>
				<GridChildren {...gridChildProps}>
					<LocationDropdownField />
				</GridChildren>
				<GridChildren {...gridChildProps}>
					<SubLocationDropdownField />
				</GridChildren>
				<GridChildren>
					<AssetDropdownField />
				</GridChildren>
			</Grid>
		</SiteSublocAssetFiltersCtx.Provider>
	);
};

export default SiteSubLocAssetForm;
