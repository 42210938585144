import React from 'react';
import { Grid, Icon, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface AssetFormListProps {
	assets: Asset[];
	documentId: string;
	removeAsset: (assetFBID: string) => void;
}

const AssetFormList = (props: AssetFormListProps) => {
	const { assets, documentId, removeAsset } = props;
	const history = useHistory();
	const { t } = useTranslation();

	return (
		<>
			{assets.map((asset: Asset, i: number) => {
				return (
					<div
						key={i}
						style={{
							border: `2.5px solid var(--light-grey)`,
							marginBottom: '10px',
							padding: '10px',
						}}
						className="card-shadow-site site-card"
					>
						<Grid container>
							<Grid container xs={4} justify="flex-start" alignItems="flex-end">
								<div>
									<p style={{ overflowWrap: 'break-word' }} className="content-site">
										<strong>{asset.AssetCode}</strong>
									</p>
									<p className="label-asset" style={{ color: '#f12525' }}>
										{t('Asset Code')}
									</p>
									<br />
								</div>
							</Grid>

							<Grid container xs={4} justify="flex-start" alignItems="flex-end">
								<div>
									<p className="content-site">{asset.AssetClass}</p>
									<p className="label-asset" style={{ color: '#f12525' }}>
										{t('Asset Class')}
									</p>
									<br />
								</div>
							</Grid>

							<Grid container xs={4} justify="flex-end" alignItems="flex-end">
								<div>
									<IconButton
									onClick={() => removeAsset(asset.AssetFBID)}
										>
										<Icon>delete</Icon>
									</IconButton>
									<IconButton
										onClick={() => {
											history.push(`/asset-nmdetails/${asset.AssetFBID}` + '/' + documentId);
										}}
									>
										<Icon>outbound</Icon>
									</IconButton>
								</div>
							</Grid>

							<Grid container xs={12} justify="flex-start" alignItems="flex-end">
								<div>
									<p className="content-site">{asset.AssetName}</p>
									<p className="label-asset" style={{ color: '#f12525' }}>
										{t('Asset Name')}
									</p>
								</div>
							</Grid>
						</Grid>
					</div>
				);
			})}
		</>
	);
};

export default AssetFormList;
