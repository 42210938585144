import { Button, Icon } from '@material-ui/core';
import { History } from 'history';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fire } from '../..';
import Request from '../../definitions/request';
import '../../styles/requests/request-details.css';
import DeclineRequestModal from './DeclineRequestModal';

interface DeclineRequestButtonProps {
	request: Request;
	history: History;
	getRequest: any;
}

const DeclineRequestButton = ({ request, history, getRequest }: DeclineRequestButtonProps) => {
    const [displayModal, setDisplayModal] = useState<boolean>(false);
	const [declineReason, setDeclineReason] = useState<string>();
	const [t, i18n] = useTranslation();

	const handleDeclineRequest = () => {
        fire.DeclineRequest(request.ID);
        fire.postToJobQueue(request.ID, {JobAction: "DeclineRequest", RequestID: request.RequestID, DeclineReason: declineReason});
		getRequest();
        setDisplayModal(false);
	};

	const handleCloseModal = () => {
		setDisplayModal(false);
	};

	const newDeclineReasonChange = e => {
		setDeclineReason(e.target.value);
	};

	return (
        <>
		<Button className="decline-button" variant="contained" fullWidth onClick={() => setDisplayModal(true)}>
			<Icon style={{ fontSize: 20 }}>cancel</Icon>
			&nbsp; {t("Decline Request")}
		</Button>
        <DeclineRequestModal isOpen={displayModal} handleCloseModal={handleCloseModal}  
        declineRequest={handleDeclineRequest} declineReasonChange={newDeclineReasonChange} />
        </>
	);
};

export default DeclineRequestButton;
