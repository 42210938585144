import { Button, Fab, Grid, Icon } from '@material-ui/core';
import { History } from 'history';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fire } from '../..';
import Api from '../../apiConfiguration.json';
import SideDrawer from '../../components/shared/SideDrawer';
import LoadingSpinner from '../../components/Spinners/LoadingSpinner';
import QRCodeSearch from '../../components/shared/QRCodeSearch';
import StorageAreaCard from '../../components/storage-areas/StorageAreaCard';
import StorageAreasSearchModal from '../../components/storage-areas/StorageAreasSearchModal';
import useStorageAreas from '../../hooks/StorageAreas/useStorageAreas';
import '../../styles/storage-areas/storage-areas-list.css';
import ApiKeyObj from '../../utils/ApiObjectKey';
import { getBaseURL } from '../../utils/getBaseURL';
import { SendErrorData } from '../../utils/WindowError';
import { useTranslation } from 'react-i18next';

interface StorageAreasViewProps {
	history: History;
	UserSettings: Store.UserSettings;
}

const StorageAreasView = ({ history, UserSettings }: StorageAreasViewProps) => {
	const [t, i18n] = useTranslation();
	return (
	<>
		<SideDrawer
			history={history}
			title={t("Storage Areas List")}
			rightMenuButton={<QRCodeSearch history={history} />}
			colour="primary"
			handleLogout={() => fire.auth.signOut().then(() => location.reload())}
			User={UserSettings}
			versionApp={Api.VERSION}
			versionDb={Api.INDEXEDDB_VERSION}
			SendErrorData={SendErrorData}
			getBaseURL={getBaseURL}
			ApiKeyObj={ApiKeyObj}
		/>
		<StorageAreasList />
	</>
)};

const StorageAreasList = () => {
	const {
		isLoading,
		isFiltered,
		isEndOfList,
		storageAreas,
		loadMoreStorageAreas,
		isSearchDialogOpen,
		openSearchDialog,
		closeSearchDialog,
		selectedSite,
		handleSiteChange,
		selectedLocation,
		handleLocationChange,
		selectedSubLocation,
		handleSubLocationChange,
		searchStorageAreas,
		clearSearch,
	} = useStorageAreas();
	const [t, i18n] = useTranslation();

	if (isLoading) {
		return <LoadingSpinner height="90vh" text={t("Loading Storage Areas...")} />;
	}

	if (storageAreas.length === 0 && !isFiltered) {
		return (
			<Grid container justify="center" alignItems="center" style={{ height: '80vh' }}>
				<h1 className="no-storage-areas-text">{t("There are no Storage Areas available.")}</h1>
			</Grid>
		);
	}

	return (
		<div className="storage-areas-container">
			<div className="storage-areas-list">
				{storageAreas.length === 0 ? (
					<Grid container justify="center" alignItems="center" style={{ height: '70vh' }}>
						<h1 className="no-storage-areas-text">{t("There are no Storage Areas matching your search criterias.")}</h1>
					</Grid>
				) : (
					<>
						{storageAreas.map(storageArea => (
							<Link to={`/storage-area-details/${storageArea.ID}`} key={storageArea.ID}>
								<StorageAreaCard storageArea={storageArea} />
							</Link>
						))}
						{isEndOfList ? (
							<Button variant="outlined" size="large" fullWidth disabled>
								{t("All Storage Areas Loaded")}
							</Button>
						) : (
							<Button variant="outlined" color="primary" size="large" fullWidth onClick={loadMoreStorageAreas}>
								{t("Load More Storage Areas")}
							</Button>
						)}
					</>
				)}
				<Fab
					className={isFiltered ? 'not-hidden' : 'hidden'}
					id="clear-search-fab"
					color="inherit"
					style={{ backgroundColor: 'var(--light-red)' }}
					onClick={clearSearch}
				>
					<Icon style={{ color: 'white' }}>clear</Icon>
				</Fab>
				<Fab
					id="search-fab"
					color="inherit"
					style={{ backgroundColor: 'var(--light-blue)' }}
					onClick={openSearchDialog}
				>
					<Icon style={{ color: 'white' }}>search</Icon>
				</Fab>
				<StorageAreasSearchModal
					isOpen={isSearchDialogOpen}
					close={closeSearchDialog}
					searchStorageAreas={searchStorageAreas}
					selectedSite={selectedSite}
					handleSiteChange={handleSiteChange}
					selectedLocation={selectedLocation}
					handleLocationChange={handleLocationChange}
					selectedSubLocation={selectedSubLocation}
					handleSubLocationChange={handleSubLocationChange}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings as Store.UserSettings,
});

export default connect(mapStateToProps)(StorageAreasView);
