import { Button, Icon } from '@material-ui/core';
import { History } from 'history';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Request from '../../definitions/request';
import '../../styles/requests/request-details.css';

interface ApproveRequestButtonProps {
	request: Request;
	history: History;
}

const ApproveRequestButton = ({ request, history }: ApproveRequestButtonProps) => {
	const [t, i18n] = useTranslation();
	const handleApproveRequest = () => {
		history.push({
			pathname: `/new-job-from-Request/${request.RequestID}`,
			state: {
				ExpectedByDate: request.ExpectedByDate,
				RequestDetails: request.Details,
				SelectedSiteName: request.Site,
				SelectedSiteID: request.SiteID,
				SelectedLocationName: request.Location,
				SelectedLocationID: request.LocationID,
				SelectedSubLocationName: request.SubLocation,
				SelectedSubLocationID: request.SubLocationID,
				SelectedJobTypeName: request.JobType,
				SelectedJobTypeID: request.JobTypeID,
				SelectedJobSubTypeName: request.JobSubType,
				SelectedJobSubTypeID: request.JobSubTypeID,
				RequestFBID: request.ID
			},
		});
	};
	return (
		<Button className="approve-button" variant="contained" fullWidth onClick={handleApproveRequest}>
			<Icon style={{ fontSize: 20 }}>done_outline</Icon>
			&nbsp; {t("Approve Request")}
		</Button>
	);
};

export default ApproveRequestButton;
