import * as React from 'react';
import { useTranslation } from 'react-i18next';

// Search Pagination for Sites
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';
import { useReactSelectAsyncPaginate } from '../../../hooks/useReactSelectAsyncPaginate';
import ClearIndicator from './ClearIndicator';
import { ReactSelectSiteOptionType } from './SiteDropdown';

/**
 * Describe options type for the React Select Async Paginate component.
 */
export type ReactSelectAssetNameOptionType<T = number> = {
	value: T;
	label: string;
};

/**
 * Dropdown component to select an asset from a list of asset names.
 */
function AssetNameDropdown<OptionValueType = number, OptionValueTypeSite = number, AdditionalType = undefined>(props: {
	assetNameFilter?: ReactSelectAssetNameOptionType<OptionValueType> | null;
	siteFilter?: ReactSelectSiteOptionType<OptionValueTypeSite> | null;
	loadAssetOptions: LoadOptions<ReactSelectAssetNameOptionType<OptionValueType>, AdditionalType>;
	handleAssetNameFilter: (option: ReactSelectAssetNameOptionType<OptionValueType> | null) => void;
	additional?: AdditionalType;
	onClear?: () => void;
}) {
	const [t, _i18n] = useTranslation();
	const [{ isMenuOpen, selectRef, showKeyboard }, { openMenu, closeMenu }] = useReactSelectAsyncPaginate();
	return (
		<AsyncPaginate
			id="site-helper"
			value={props.assetNameFilter}
			selectRef={selectRef}
			debounceTimeout={300}
			onFocus={openMenu}
			onBlur={closeMenu}
			menuIsOpen={isMenuOpen}
			onChange={(e, meta) => {
				if (meta.action === 'clear' && props.onClear) {
					props.onClear();
				}
				props.handleAssetNameFilter(e);
				closeMenu();
			}}
			blurInputOnSelect
			loadOptions={props.loadAssetOptions}
			loadingMessage={() => t('Loading Assets...')}
			cacheUniqs={[props.assetNameFilter, props.siteFilter]}
			noOptionsMessage={() => t('No assets found')}
			isSearchable={!showKeyboard}
			additional={props.additional}
			isClearable
			components={{
				ClearIndicator,
			}}
		/>
	);
}

export default AssetNameDropdown;
