import { Icon, IconButton, InputLabel } from '@material-ui/core';
import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Search Pagination for Sites
import { AsyncPaginate } from 'react-select-async-paginate';

import { isNullOrUndefined } from 'util';

function AssetClassDropdown(props) {
	const [t, i18n] = useTranslation();
	return (
		<div style={{flex: '1'}}>
		<AsyncPaginate
			id="assetClass-helper"
			value={props.assetClassFilter}
			onChange={props.handleAssetClassFilter}
			loadOptions={props.loadAssetClasses}
			blurInputOnSelect={true}
			loadingMessage={() => t("Loading Asset Classes...")}
			cacheUniqs={[props.assetClassFilter]}
			noOptionsMessage={() => t("No Asset Classes Found")}
			isSearchable={false}
		/>
		<br/>
		<p className="field-label-asset">{t("Asset Class")}</p>
		<br/>
	</div>
	)
}

export default AssetClassDropdown;
