export enum RequestStatuses {
    Pending = "1",
    Approved = "2",
    Declined = "3",
    ApprovedOnHold = "4",
    RequiresAuthorisation = "5",
    Authorised = "6",
    WorkConfirmed = "7",
    WorkRejected = "8",
    FormNotSubmitted = "9",
    FormSubmitted = "10"
}
