// React
import * as React from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { isNullOrUndefined } from 'util';
import firebase from 'firebase';

// Config File
import Api from '../../apiConfiguration.json';

// Firebase
import { fire } from '../../index';

// Material UI
import { Fab, Grid, Icon, IconButton } from '@material-ui/core';

// Components
import LoadingSpinner from '../../components/Spinners/LoadingSpinner';
import SideDrawer from '../../components/shared/SideDrawer';
import FormCard from '../../components/form-library/FormCard';
import TextSearchModal from '../../components/shared/TextSearchModal';
import CreateFormModal, { NewFormMetadata } from '../../components/form-library/CreateFormModal';

// Utils
import ApiKeyObj from '../../utils/ApiObjectKey';
import { LocalstorageRemoveItem, LocalstorageGetItem, LocalstorageSetItem } from '../../utils/LocalStorage';
import { windowError, SendErrorData } from '../../utils/WindowError';
import { getBaseURL } from '../../utils/getBaseURL';
import { searchFreeTextInput } from '../../utils/forms/FormLibrarySearch';
import { generateFirebaseId } from '../../utils/Guids';
import { stringtoUnixUTC, parseDate } from '../../utils/Times';
import { orderAlphabetically } from '../../utils/forms/FormHelpers';

//CSS
import '../../styles/form-library/form-library.css';
import { useTranslation, withTranslation } from 'react-i18next';
import { fireFormConverter } from '../../utils/FirebaseConverters';

interface FormLibraryListScreenProps {
	history: History;
	UserSettings: Store.UserSettings;
	t: any;
}

interface FormLibraryListScreenState {
	formLibraryList: FormTemplates.FormTemplate[];
	paginationFormLibraryList: FormTemplates.FormTemplate[];
	loading: boolean;
	filtersActive: boolean;
	searchInput: string;
	searchModalOpen: boolean;
	createFormModalOpen: boolean;
	selectedTemplate: FormTemplates.FormTemplate | null;
	newFormMetadata: NewFormMetadata;
}

class FormLibraryListScreen extends React.Component<FormLibraryListScreenProps, FormLibraryListScreenState> {
	constructor(props) {
		super(props);

		this.state = {
			formLibraryList: [],
			paginationFormLibraryList: [],
			loading: true,
			filtersActive: false,
			searchInput: '',
			searchModalOpen: false,
			createFormModalOpen: false,
			selectedTemplate: null,
			newFormMetadata: {},
		};
	}

	componentDidMount() {
		windowError(
			this.props.UserSettings.email,
			this.props.UserSettings.UserUID,
			this.props.UserSettings.ServerName,
			'FormLibraryListScreen',
		);

		const LocalItem = LocalstorageGetItem('FormLibraryFilterOptions');
		if (!isNullOrUndefined(LocalItem) && LocalItem !== '') {
			const JsonObj = JSON.parse(LocalItem);
			this.setState({
				searchInput: JsonObj.searchInput,
				filtersActive: JsonObj.searchInput !== '',
			});
		}

		this.getFormLibraryForms();
	}

	async getFormLibraryForms() {
		this.setState({
			loading: true,
		});

		const snapshot = await fire.getFormTemplatesCollection().get();
		this.handleFormLibrarySnapshot(snapshot);
	}

	handleFormLibrarySnapshot(query: firebase.firestore.QuerySnapshot) {
		if (query.empty || query.docChanges().length < 1) {
			this.setState({
				loading: false,
			});
			return;
		}

		let formLibraryList: FormTemplates.FormTemplate[] = query.docs.map(formLibraryRow => {
			const formLibraryData = formLibraryRow.data() as FormTemplates.FormTemplate;
			formLibraryData.Id = formLibraryRow.id;
			formLibraryData.CurrentFormDate = null;
			formLibraryData.FormFBID = null;

			//if a solo form then only let find an existing open form if it was created by this user.
			if (formLibraryData.FormAccess == 'solo') {
				fire.baseQuery
					.collection('Forms')
					.where('FormTemplateFBID', '==', formLibraryData.Id)
					.where('CompletedDate', '==', null)
					.where('LastUpdatedByUserFBID', '==', this.props.UserSettings.UserUID)
					.limit(1)
					.withConverter(fireFormConverter)
					.get()
					.then(formObjects => {
						formObjects.forEach(formObj => {
							const liveForm = formObj.data();
							formLibraryData.LastUpdatedBy = liveForm.LastUpdatedByUserName;
							formLibraryData.CurrentFormDate = liveForm.CurrentFormDate;
							formLibraryData.FormFBID = formObj.id;
						});
						this.setState({ formLibraryList });
					});
			}
			//otherwise it is a "shared" form so every user has access to this form.
			else {
				//alert("aaa");
				fire.baseQuery
					.collection('Forms')
					.where('FormTemplateFBID', '==', formLibraryData.Id)
					.where('CompletedDate', '==', null)
					.limit(1)
					.withConverter(fireFormConverter)
					.get()
					.then(formObjects => {
						formObjects.forEach(formObj => {
							const liveForm = formObj.data();
							formLibraryData.LastUpdatedBy = liveForm.LastUpdatedByUserName;
							formLibraryData.CurrentFormDate = liveForm.CurrentFormDate;
							formLibraryData.FormFBID = formObj.id;
						});
						this.setState({ formLibraryList });
					});
			}

			return formLibraryData;
		});

		//sort forms
		this.orderAlphabeticallyArray(formLibraryList)

		this.setState({ formLibraryList });
		this.handleSearch(formLibraryList);
	};

	orderAlphabeticallyArray(list: FormTemplates.FormTemplate[]) {
		return list.sort((a, b) => orderAlphabetically(a.FormName.toLowerCase(), b.FormName.toLowerCase()));
	}

	handleSearch(formLibraryList: FormTemplates.FormTemplate[]) {
		let freeTextSearch = '';
		if (this.state.searchInput !== '') {
			freeTextSearch = this.state.searchInput.toLowerCase();
		}

		if (freeTextSearch !== '') {
			formLibraryList = searchFreeTextInput(freeTextSearch, formLibraryList);
		}

		//sort forms
		this.orderAlphabeticallyArray(formLibraryList)

		this.setState({ paginationFormLibraryList: formLibraryList, loading: false });
	}

	handleLogout() {
		LocalstorageRemoveItem('NewJobDetails');
		LocalstorageRemoveItem('JobFilterOptions');
		LocalstorageRemoveItem('AssignedUserFilterOptions');
		LocalstorageRemoveItem('ResourceFilterOptions');
		LocalstorageRemoveItem('FormLibraryFilterOptions');
		fire.auth.signOut().then(() => location.reload());
	}

	handleClearSearch = async () => {
		await this.setState({
			searchInput: '',
			filtersActive: false,
			loading: true,
		});

		const SearchObject = { searchInput: '' };
		await LocalstorageSetItem({ Key: 'FormLibraryFilterOptions', Value: SearchObject });
		this.handleSearch(this.state.formLibraryList);
	};

	searchResources() {
		this.setState({
			filtersActive: true,
			loading: true,
		});
		const SearchObject = {
			searchInput: this.state.searchInput,
		};
		LocalstorageSetItem({ Key: 'FormLibraryFilterOptions', Value: SearchObject });
		this.handleSearch(this.state.formLibraryList);
		this.handleSearchModal();
	}

	handleSearchInput = input => {
		this.setState({ searchInput: input });
	};

	handleSearchModal = () => {
		this.setState({
			searchModalOpen: !this.state.searchModalOpen,
		});
	};

	handleUndoFormModal = () => {
		this.setState({
			createFormModalOpen: !this.state.createFormModalOpen,
		});
	};

	handleNewForm = async (selectedTemplate: FormTemplates.FormTemplate) => {
		if (confirm(this.props.t("Are you sure with to create a new form for") + " " + selectedTemplate.FormName + "?")) {
			this.setState(prevState => ({
				selectedTemplate,
				newFormMetadata: {
					...prevState.newFormMetadata,
					formCreationDate: parseDate('Date', stringtoUnixUTC(new Date().toUTCString() || '', true).toString()),
				},
			}));
			this.handleUndoFormModal();
		}
	};

	createNewForm = async () => {
		try {
			this.setState({ loading: true });
			const Template = this.state.selectedTemplate as FormTemplates.FormTemplate;
			const { formCreationDate, assetFBID, locationFBID, siteFBID, sublocationFBID } = this.state.newFormMetadata;
			const currentFormDate = new Date(formCreationDate || new Date()).getTime();
		const FormFBID = generateFirebaseId();
		const newFormData = {
			CompletedByUser: null,
			CompletedDate: null,
			FormName: Template.FormName,
			FormTemplateFBID: Template.Id,
			FormType: Template.FormType,
			IsCompleted: false,
			IsRequiredForJobCompletion: false,
			JobFBID: null,
			JobTaskFBID: null,
			Status: 'Pending',
			CurrentFormDate: currentFormDate,
			LastUpdatedDate: Date.now(),
			LastUpdatedByUserFBID: this.props.UserSettings.UserUID,
			LastUpdatedByUserName: this.props.UserSettings.Name,
			Sections: {},
			AssetFBID: assetFBID || null, // firestore doesn't support undefined values
			LocationFBID: locationFBID || null,
			SiteFBID: siteFBID || null,
			SublocationFBID: sublocationFBID || null,
			} as Forms.Form;
		//create the new form
			await fire.CreateForm(newFormData, FormFBID);

		//update form template, so resource app form library updates automatically.
			await fire.UpdateFormTemplateFormStart(Template.Id, FormFBID);

		//Post to JobQueue here after create form use FromFBID.
		await fire.postToJobQueue(FormFBID, {
			JobAction: 'FormLibraryNewFormCreated',
			CurrentFormDate: currentFormDate,
			AssetFBID: assetFBID || null, // firestore doesn't support undefined values
			LocationFBID: locationFBID || null,
			SiteFBID: siteFBID || null,
			SublocationFBID: sublocationFBID || null,
			FormTemplateFBID: Template.Id,
		});

		fire.getFormTemplateSections(Template.Id).then(async sectionsTemplate => { 
			let sectionsData = sectionsTemplate.data();
	
			fire.getFormTemplateQuestions(Template.Id).then(async questions => {
				if (questions.docs.length > 0) {
					var parsedQuestions = questions.docs;
					var sections: any = [];
					if(sectionsData && sectionsData.Sections){
						sections = sectionsData.Sections;
					}else{
						alert("firebase could not retrieve the sections information")
						return
					}
					for(const question of parsedQuestions){
						const QuestionAnswerFBID = generateFirebaseId();
						const data = question.data() as Forms.QuestionAnswer;
						const questionFBID = question.id;
	
						if(data.IsRepeatable){
							const section = sections.find(x => x.SectionName === data.Section.Name); /* avoid grouping name in the future */
	
							if (section) {
								if(section.RepeatableSections[0] === undefined){
									section.RepeatableSections.push(QuestionAnswerFBID);
								} else {
									section.RepeatableSections[0] = section.RepeatableSections[0] + "," + QuestionAnswerFBID;
								}
							} else {
								var newSectionTest = await sections.push({SectionName: data.Section.Name, IsRepeatable: true, RepeatableSections: [[QuestionAnswerFBID]], TemplateSectionID: data.Section.TemplateSectionID});
							}
						};
	
						if (data.TemplateSectionID <= 0)
							data.TemplateSectionID = 1;
	
						fire.CreateFormQuestionAnswers(data, FormFBID, QuestionAnswerFBID);
	
						const queueRef = new Date().getTime() + '-' + QuestionAnswerFBID;

						//Post to JobQueue here after create form question use QuestionFBID. It'll be something similar to below
						fire.postToJobQueue(
							QuestionAnswerFBID,
							{
								JobAction: 'FormLibraryNewQuestionAnswer',
								TemplateResponseFBID: FormFBID,
								QuestionFBID: questionFBID,
								DateCreated: new Date().getTime(),
							},
							queueRef,
						)
							.then();
					};
					
					newFormData.Sections = sections;
					await fire.updateForm(FormFBID, newFormData);
				}
			});
		})
			this.props.history.push(`/form-library-details/${Template.Id}/${FormFBID}`);
		} catch (e) {
			console.error(e);
		} finally {
			this.setState({
				loading: false,
			});
		}

		//await fire.UpdateFormLibrary(Template.Id, FormFBID, currentFormDate);
	};
	editCurrentForm = (FormTemplateID: string, FormFBID: string) => {
		this.props.history.push(`/form-library-details/${FormTemplateID}/${FormFBID}`);
	};

	handleChangeNewForm = (value: NewFormMetadata) => {
		this.setState({
			newFormMetadata: value,
		});
	};

	render() {
		if (this.state.loading && this.state.filtersActive) {
			return <LoadingSpinner text={this.props.t("Searching Form Library Templates...")} />;
		}

		if (this.state.loading) {
			return <LoadingSpinner text={this.props.t('Loading Form Library Templates...')} />;
		}

		return (
			<div>
				<SideDrawer
					history={this.props.history}
					title={this.props.t("Form Library")}
					rightMenuButton={<NewJobButton history={this.props.history} />}
					colour="primary"
					handleLogout={this.handleLogout}
					User={this.props.UserSettings}
					versionApp={Api.VERSION}
					versionDb={Api.INDEXEDDB_VERSION}
					SendErrorData={SendErrorData}
					getBaseURL={getBaseURL}
					ApiKeyObj={ApiKeyObj}
				/>
				<Grid container={true} direction="column" justify="center" alignItems="center">
					<div className="main-form-library" style={{ marginTop: '10px' }}>
						{this.state.paginationFormLibraryList.length > 0 ? (
							this.state.paginationFormLibraryList.map((form, i) => (
								<FormCard
									key={i}
									form={form as FormTemplates.FormTemplate}
									startNewForm={this.handleNewForm}
									editCurrentForm={this.editCurrentForm}
								/>
							))
						) : this.state.paginationFormLibraryList === null || this.state.paginationFormLibraryList.length === 0 ? (
							<NoTemplates filtersActive={this.state.filtersActive} />
						) : (
							<LoadingSpinner height="40vh" text={this.props.t("Loading")} />
						)}
					</div>
					<Fab
						className={this.state.filtersActive ? 'not-hidden' : 'hidden'}
						id="clear-search-fab"
						color="inherit"
						aria-label="Add"
						style={{ backgroundColor: 'var(--light-red)' }}
						onClick={() => this.handleClearSearch()}
					>
						<Icon style={{ color: 'white' }}>clear</Icon>
					</Fab>
					<Fab
						id="search-fab"
						color="inherit"
						aria-label="Add"
						style={{ backgroundColor: 'var(--light-blue)' }}
						onClick={() => this.handleSearchModal()}
					>
						<Icon style={{ color: 'white' }}>search</Icon>
					</Fab>
					<TextSearchModal
						searchModalOpen={this.state.searchModalOpen}
						handleSearchModal={this.handleSearchModal}
						searchInput={this.state.searchInput}
						handleSearchInput={this.handleSearchInput}
						searchList={() => this.searchResources()}
					/>

					<CreateFormModal
						createFormModalOpen={this.state.createFormModalOpen}
						handleUndoFormModal={this.handleUndoFormModal}
						value={this.state.newFormMetadata}
						onChange={this.handleChangeNewForm}
						handleCreateForm={this.createNewForm}
					/>
				</Grid>
			</div>
		);
	}
}

const NewJobButton = props => (
	<IconButton onClick={() => props.history.push('/new-job')}>
		<Icon style={{ color: 'white' }}>add_circle_outline</Icon>
	</IconButton>
);

const NoTemplates = props => {
	const [t, i18n] = useTranslation();
	return (
		<div className="no-templates-card">
			<h1 className="hot-pink">
				{props.filtersActive
					? t("There are no Forms in the Form Library matching your search criteria")
					: t("There are no Forms in the Form Library to choose from.")}
			</h1>
		</div>
	);
};

const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
});

export default withTranslation()(connect(mapStateToProps)(FormLibraryListScreen));
