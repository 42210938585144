import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fire } from '../../index';

const useStorageAreaDetails = () => {
	const { id } = useParams<{ id: string }>();
	const mountedRef = useRef(true);
	const [isLoading, setIsLoading] = useState(true);
	const [storageArea, setStorageArea] = useState<StorageArea | null>(null);

	useEffect(() => {
		getStorageArea();
		return () => {
			mountedRef.current = false;
		};
	}, []);

	const getStorageArea = () => {
		setIsLoading(true);
		const document = fire.getDocumentQuery('StorageAreas', id);
		document.onSnapshot(storageAreaObj => {
			const storageArea = storageAreaObj.data() as StorageArea;
			storageArea.ID = id;
			if (mountedRef.current) {
				setStorageArea(storageArea);
				setIsLoading(false);
			}
		});
	};

	return { isLoading, storageArea, getStorageArea };
};

export default useStorageAreaDetails;
