// React
import * as React from 'react';

// Firebase
import { fire } from '../../index';

// Components
import QuestionLayout from './QuestionLayout';

// Utils
import { isNullOrUndefined } from 'util';
import { withTranslation } from 'react-i18next';
import { generateFirebaseId } from '../../utils/Guids';

interface CalculatedFieldProps {
	data: Forms.QuestionAnswer;
	t: any;
}
interface CalculatedFieldState {
	updatedAnswer?: string | null;
	elements: number;
}

 class CalculatedFieldQuestion extends React.Component<CalculatedFieldProps, CalculatedFieldState> {
	myRef: React.RefObject<HTMLInputElement>;
	constructor(props) {
		super(props);
		this.myRef = React.createRef();
		this.state = {
			updatedAnswer: this.props.data.Answer,
			elements: 0,
		};
	}

	UNSAFE_componentWillReceiveProps(newProps: CalculatedFieldProps) {
		if (newProps.data.Answer !== this.props.data.Answer && !isNullOrUndefined(newProps.data.Answer)) {
			this.setState({
				updatedAnswer: newProps.data.Answer,
			});
		}
		//we originally only called the watchParentQuestions method in the componentDidMount
		//function but sometimes the DOM elements weren't ready for the processing 
		//so I added this bit to recall the function until we get them
		if(this.state.elements < this.props.data.CalculatedFieldParents.length){
			const parentQuestions: number[] = this.props.data.CalculatedFieldParents;
			const calculateOperatorNum: number | null = this.props.data.CalculateOperator;
			if (calculateOperatorNum === null) return;	
			this.watchParentQuestions(parentQuestions, calculateOperatorNum);
		}
	}

	componentDidUpdate(prevProps: CalculatedFieldProps, prevState: CalculatedFieldState) {
		if (prevState.updatedAnswer !== this.state.updatedAnswer) {
			this.focusUnfocus();
		}
	}

	componentDidMount() {
		const parentQuestions: number[] = this.props.data.CalculatedFieldParents;
		const calculateOperatorNum: number | null = this.props.data.CalculateOperator;
		if (calculateOperatorNum === null) return;

		this.watchParentQuestions(parentQuestions, calculateOperatorNum);
	}

	watchParentQuestions = (parentQuestions: number[], calculateOperatorNum: number) => {
		parentQuestions.forEach(parent => {
			const el: HTMLInputElement | HTMLSelectElement | null = document.querySelector(`[data-questionid="${parent}"]`);
			if (el == null) return;

			if (el.dataset['type'] === 'calculated-field') {
				el.addEventListener('build', () => {
					this.getParentQuestionValues(parentQuestions, calculateOperatorNum);
				});
			}

			if (el.tagName === 'SELECT') {
				el.addEventListener('change', () => {
					this.getParentQuestionValues(parentQuestions, calculateOperatorNum);
				});
			} else {
				el.addEventListener('blur', () => {
					this.getParentQuestionValues(parentQuestions, calculateOperatorNum);
				});
			}
			this.setState({elements: this.state.elements + 1})
		});
	};

	getParentQuestionValues = (parentQuestions: number[], calculateOperatorNum: number) => {
		let runningTotal = 0;
		let selectNan = false;

		parentQuestions.forEach(parent => {
			let el: HTMLInputElement | HTMLSelectElement | null = document.querySelector(`[data-questionid="${parent}"]`);
			if (el === null) return;

			let newValue = 0;

			if (el.tagName === 'SELECT') {
				el = el as HTMLSelectElement;
				newValue = parseFloat(el.options[el.selectedIndex].text) as any;
				selectNan = isNaN(newValue);
				newValue = selectNan ? 0 : newValue;
			} else {
				el = el as HTMLInputElement;
				newValue = parseFloat(el.value);
			}

			if (runningTotal === 0) {
				runningTotal = newValue;
			} else {
				if (calculateOperatorNum === 2) {
					runningTotal = runningTotal * newValue;
				} else if (calculateOperatorNum === 1) {
					runningTotal = runningTotal + newValue;
				}
			}
		});

		if (selectNan || isNaN(runningTotal)) {
			runningTotal = 0;
		}

		this.setState({ updatedAnswer: runningTotal.toString() });
		this.updateFirebase(runningTotal);
	};

	updateFirebase = (Answer: number) => {
		//update question in firebase
		this.props.data.questionRef
			.update({ Answer: Answer.toString() })
			.catch(err => console.error(this.props.t("Error updating answer - ") + err));

		let MillliTime = new Date().getTime() + generateFirebaseId();
		//add answer to job queue
		fire.answerQuestion({ ...this.props.data, Answer: Answer.toString() }, {}, MillliTime.toString());

		fire.UpdateFormAfterAnswer(this.props.data.FormFBID);
	};

	focusUnfocus = () => {
		if (this.myRef.current !== null) {
			// this.myRef.current.focus();
			let evt: Event = new Event('build');
			this.myRef.current.dispatchEvent(evt);
		}
	};

	render() {
		return (
			<QuestionLayout>
				{this.props.data.QuestionTitle}
				<input
					data-type="calculated-field"
					data-questionid={this.props.data.QuestionID}
					value={this.state.updatedAnswer || '0'}
					readOnly={true}
					style={{ color: 'rgb(84, 84, 84)', cursor: 'default', backgroundColor: 'rgb(235, 235, 228)' }}
					ref={this.myRef}
				/>
			</QuestionLayout>
		);
	}
}

export default withTranslation()(CalculatedFieldQuestion);
