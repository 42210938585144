// React
import * as React from 'react';

// Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon/Icon';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import { Box, Grid, useMediaQuery } from '@material-ui/core';
import SiteSubLocAssetForm, { SiteSubLocAssetFilters } from '../shared/SiteLocSubLocAssetForm/SiteSubLocAssetForm';
import { useEffect } from 'react';

/**
 * Form metadata.
 * Fired data on change
 */
export interface NewFormMetadata {
	formCreationDate?: string;
	assetFBID?: string;
	siteFBID?: string;
	locationFBID?: string;
	sublocationFBID?: string;
}

/**
 * Transform the sublocasset filters into a form metadata object.
 */
function transformFilterToNewFormMetadata({
	assetName,
	site,
	location,
	subLocation,
}: SiteSubLocAssetFilters): NewFormMetadata {
	return {
		assetFBID: assetName ? assetName.value.AssetFBID : undefined,
		siteFBID: site ? site.value.fbid : undefined,
		locationFBID: location ? location.value.fbid : undefined,
		sublocationFBID: subLocation ? subLocation.value.fbid : undefined,
	};
}

/**
 * Form date field
 */
const CreateFormDateField = (props: {
	formCreationDate?: string;
	onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}) => {
	const [t, _i18n] = useTranslation();

	return (
		<Grid xs={12} item>
			<InputLabel className="field-label-asset">{t('Current Form Date')}</InputLabel>
			<Box marginBottom={1}>
				<TextField
					autoFocus
					margin="dense"
					id="create-form-date-input"
					type="date"
					fullWidth
					value={props.formCreationDate || new Date().toISOString().split('T')[0] /* default to today */}
					onChange={props.onChange}
				/>
			</Box>
		</Grid>
	);
};

/**
 * Actions buttons for the create form modal.
 * Undo and create form buttons.
 */
const CreateFormDialogActions = (props: { handleUndoFormModal: () => void; handleCreateForm: () => void }) => {
	const [t, _i18n] = useTranslation();
	return (
		<>
			<Button
				onClick={props.handleUndoFormModal}
				color="primary"
				style={{ backgroundColor: 'var(--light-grey)', color: 'white' }}
			>
				<Icon style={{ fontSize: 20 }}>undo</Icon> &nbsp;
				<p style={{ marginTop: 2 }}>{t('Back')}</p>
			</Button>
			<Button
				onClick={() => props.handleCreateForm()}
				color="primary"
				style={{ backgroundColor: 'var(--light-green)', color: 'white' }}
			>
				<Icon style={{ fontSize: 20 }}>description</Icon>&nbsp;
				<p style={{ marginTop: 2 }}>{t('Create Form')}</p>
			</Button>
		</>
	);
};

/**
 * A modal for creating new form instance from the form library.
 */
const CreateFormModal = (props: {
	createFormModalOpen: boolean;
	handleUndoFormModal: () => void;
	onChange: (e: NewFormMetadata) => void;
	value: NewFormMetadata;
	handleCreateForm: () => void;
}): JSX.Element => {
	const [t, _i18n] = useTranslation();
	const [newFormMetadata, setNewFormMetadata] = React.useState<NewFormMetadata>({});
	const onChangeSubLocAssetForm = (e: SiteSubLocAssetFilters) => {
		setNewFormMetadata(prevState => ({
			...prevState,
			...transformFilterToNewFormMetadata(e),
		}));
	};

	// this will fire "onChange" everytime the form metadata is updated
	useEffect(() => {
		props.onChange(newFormMetadata);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newFormMetadata]);

	const handleCreateFormDate = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setNewFormMetadata(prevState => ({
			...prevState,
			formCreationDate: e.target.value,
		}));
	};

	const shouldDialogBeFullscreen = useMediaQuery(useTheme().breakpoints.down('xl'));
	return (
		<Dialog
			open={props.createFormModalOpen}
			onClose={() => props.handleUndoFormModal()}
			fullScreen={shouldDialogBeFullscreen}
			maxWidth="lg"
			fullWidth // just to make sure the dialog is not too small
		>
			<DialogContent id="content" classes={{ root: 'dialog-content-root' }}>
				<Grid container>
					{/* Form creation date */}
					<CreateFormDateField formCreationDate={newFormMetadata.formCreationDate} onChange={handleCreateFormDate} />
					{/* Site, Location, SubLocation, Asset */}
					<SiteSubLocAssetForm onChange={onChangeSubLocAssetForm} />
				</Grid>
			</DialogContent>
			<DialogActions>
				<CreateFormDialogActions
					handleCreateForm={props.handleCreateForm}
					handleUndoFormModal={props.handleUndoFormModal}
				/>
			</DialogActions>
		</Dialog>
	);
};

export default CreateFormModal;
