/* eslint-disable prettier/prettier */
// Firebase
import Firebase from '../../Firebase';

// IndexedDB
import indexDb from './IndexDb';

// dexie import for IndexedDB
import Dexie from 'dexie';

// Utils
import { generateGuid } from '../../Guids';
import { uploadToFirebaseStorage } from '../../forms/FormQuestionHelpers';
import { isNullOrUndefined } from 'util';

export function AddPhotoExistingJob(
	localQueueObject: indexDb.LocalQueue,
	getPhoto: (documentId: string) => Dexie.Promise<indexDb.Photos | undefined>,
	removeItemFromQueueByItemId: (itemId: string) => Dexie.Promise<void>,
	jobQueueObject: any,
	fire: Firebase,
): Promise<boolean> {
	return new Promise(async (resolve) => {
		if (!isNullOrUndefined(localQueueObject.arrayBuffer)) {
			let uploadSuccess = true;
			const photoGuid = jobQueueObject['PhotoGuid'];
			const blob = new Blob([localQueueObject.arrayBuffer]);
			await fire
				.uploadImageExistingJobPromise(
					blob,
					localQueueObject.documentId,
					photoGuid,
					localQueueObject,
				)
				.then(async (res) => {
					if (res) {
						await removeItemFromQueueByItemId(localQueueObject.id);
					} else {
						uploadSuccess = false;
					}
					resolve(uploadSuccess);
				})
				.catch(err => console.error('Upload Image Existing Job', err));
		}
	})
}

export async function AddPhoto(
	localQueueObject: indexDb.LocalQueue,
	getPhoto: (documentId: string) => Dexie.Promise<indexDb.Photos | undefined>,
	removeItemFromQueueByItemId: (itemId: string) => Dexie.Promise<void>,
	jobQueueObject: any,
	fire: Firebase,
	type: string,
	associatedFBID: string
): Promise<boolean> {
	return new Promise(async (resolve) => {
		if (!isNullOrUndefined(localQueueObject.arrayBuffer)) {
			const photoGuid = jobQueueObject['PhotoGuid'];
			const blob = new Blob([localQueueObject.arrayBuffer]);
			const photoId = localQueueObject.photoId;
			let uploadSuccess = true;
			await fire
				.uploadImage(
					blob,
					localQueueObject.documentId,
					type,
					associatedFBID,
					photoId || "",
					photoGuid,
					true
				).then(async (res) => {
					if (res) {
						await removeItemFromQueueByItemId(localQueueObject.id);
					} else {
						uploadSuccess = false;
					}
				})
				.catch(err => {
					uploadSuccess = false;
					console.error('Upload Image Existing Job', err);
				});
			resolve(uploadSuccess);
		}
	})
}

export async function AddDocumentExistingJob(
	localQueueObject: indexDb.LocalQueue,
	getDocument: (documentId: string) => Dexie.Promise<indexDb.Documents | undefined>,
	removeItemFromQueue: (documentId: string) => Dexie.Promise<void>,
	documentGuid: string,
	documentNewFBID: string,
	fire: Firebase,
) {
	if (!isNullOrUndefined(localQueueObject.arrayBuffer)) {
		const blob = new Blob([localQueueObject.arrayBuffer]);
		fire
			.uploadDocument(
				blob,
				localQueueObject.fileName as string,
				localQueueObject.documentId,
				documentNewFBID,
				documentGuid,
			)
			.then(() => removeItemFromQueue(localQueueObject.documentId))
			.catch(err => console.error('Upload Image Existing Job', err));
	}
}

export async function AddDocument(
	localQueueObject: indexDb.LocalQueue,
	getDocument: (documentId: string) => Dexie.Promise<indexDb.Documents | undefined>,
	removeItemFromQueue: (documentId: string) => Dexie.Promise<void>,
	documentGuid: string,
	documentNewFBID: string,
	fire: Firebase,
	associatedFBID: string,
	type: string
) {
	console.log(localQueueObject);
	if (!isNullOrUndefined(localQueueObject.arrayBuffer)) {
		const blob = new Blob([localQueueObject.arrayBuffer]);
		fire
			.addDocument(
				blob,
				localQueueObject.fileName as string,
				localQueueObject.documentId,
				documentNewFBID,
				documentGuid,
				type,
				associatedFBID
			)
			.then(() => removeItemFromQueue(localQueueObject.documentId))
			.catch(err => console.error('Upload Image Existing Job', err));
	}
}

export async function CreateJob(
	jobQueueObject: any,
	localQueueObject: indexDb.LocalQueue,
	getCreateJobPhotos: (documentId: string) => Dexie.Promise<indexDb.CreateJobPhotos[]>,
	removeItemFromQueue: (documentId: string) => Dexie.Promise<void>,
	fire: Firebase,
	idb: indexDb,
	RemoveJobPhotos: (documentId: string) => Dexie.Promise<void>,
) {
	removeItemFromQueue(localQueueObject.documentId);

	const PhotoGuidInCreateJob = jobQueueObject['guid'];
	const JobDocumentId = jobQueueObject['documentId'];

	const photos = await getCreateJobPhotos(PhotoGuidInCreateJob);

	for (const photo of photos) {
		const blob = new Blob([photo.file]);
		//fire.uploadBlobImage(blob, localQueueObject.userUID, localQueueObject.clientID);
		await fire.uploadImageExistingJob(blob, JobDocumentId, generateGuid(), true);
	}

	idb.removePhotosCreateJobPhotos(PhotoGuidInCreateJob).catch(err => console.error(err));
	RemoveJobPhotos(JobDocumentId);
}

export async function AddFormPhotoAnswer(
	localQueueObject: indexDb.LocalQueue,
	docToUpdateObj: any,
	removeItemFromQueueByItemId: (documentId: string) => Dexie.Promise<void>,
	fire: Firebase,
): Promise<boolean> {
	return new Promise(async (resolve) => {
		let documentUploadScucess = true;
		if (isNullOrUndefined(localQueueObject.docToUpdateRef)) return;
		const jobTaskQueueObj = JSON.parse(localQueueObject.jobQueueObject);
		const blob = new Blob([localQueueObject.arrayBuffer as ArrayBuffer]);

		if (jobTaskQueueObj.QuestionType === 'Signature') {
			docToUpdateObj = null;
		}
		const filename = `photo-${new Date().getTime()}.jpeg`;
		if (jobTaskQueueObj.QuestionType !== 'Signature') {
			await fire.uploadPhotoForQuestionAnswer(
				blob,
				filename,
				localQueueObject.docToUpdateRef,
				localQueueObject.id,
				jobTaskQueueObj,
				localQueueObject.photoId as string,
			).then(async res => {
				if (res) {
					await removeItemFromQueueByItemId(localQueueObject.id);
				} else {
					documentUploadScucess = false;
				}
			}).catch(() => documentUploadScucess = false);
			resolve(documentUploadScucess);
		} else {
			uploadToFirebaseStorage(
				blob,
				filename,
				jobTaskQueueObj,
				localQueueObject.docToUpdateRef,
				docToUpdateObj).then(() => {
					removeItemFromQueueByItemId(localQueueObject.id)
					resolve(true);
				});
		}
	})
}


export async function AddFormDocumentAnswer(
	localQueueObject: indexDb.LocalQueue,
	docToUpdateObj: any,
	removeItemFromQueue: (documentId: string) => Dexie.Promise<void>,
) {
	if (isNullOrUndefined(localQueueObject.docToUpdateRef)) return;

	const jobTaskQueueObj = JSON.parse(localQueueObject.jobQueueObject);
	const blob = new Blob([localQueueObject.arrayBuffer as ArrayBuffer]);

	uploadToFirebaseStorage(
		blob,
		localQueueObject.fileName as string,
		jobTaskQueueObj,
		localQueueObject.docToUpdateRef,
		docToUpdateObj,
	).then(() => removeItemFromQueue(localQueueObject.documentId));
}

// getPhoto(photoGuid)
// 	.then(photo => {
// 		if (photo === undefined) return;
// 		const blob = new Blob([photo.file]);
// 		fire
// 			.uploadImageExistingJob(blob, localQueueObject.documentId, photo.guid, true)
// 			.then(() => removeItemFromQueue(localQueueObject.documentId))
// 			.catch(err => console.error('Upload Image Existing Job', err));
// 	})
// 	.catch(err => console.error(err));
