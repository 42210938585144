import { isNullOrUndefined } from 'util';

export const LandingPage = (landingPage) => {
    if(isNullOrUndefined(landingPage)){
        return '/job-list';
    }

    switch (landingPage) {
        case 'JobList':
            return '/job-list';

        case 'CreateJob':
            return '/new-job';

        case 'SiteList':
            return '/sites-list';

        case 'AssetList':
            return '/asset-list/list';
            }

    return '/job-list';
};
