// React
import * as React from 'react';
import { History } from 'history';
import { match } from 'react-router';

// Material UI
import DialogContent from '@material-ui/core/DialogContent';
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import {
	Button,
	Icon,
	IconButton,
	Grid,
	TextField,
} from '@material-ui/core';
import Fab from '@material-ui/core/Fab/Fab';

//Utilities
import { isNullOrUndefined } from 'util';
import { fire } from '../../index';
import { connect } from 'react-redux';
import SideDrawer from '../../components/shared/SideDrawer';
import BackButton from '../../components/shared/BackButton';
import { SendErrorData } from '../../utils/WindowError';
import { getBaseURL } from '../../utils/getBaseURL';
import Api from '../../apiConfiguration.json';
import ApiKeyObj from '../../utils/ApiObjectKey';
import LoadingSpinner from '../../components/Spinners/LoadingSpinner';
import { Link } from 'react-router-dom';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import { generateFirebaseId } from '../../utils/Guids';

//Dropdowns
import SiteDropdown, { ReactSelectSiteOptionType } from '../../components/shared/AsyncPaginateDropdowns/SiteDropdown';
import LocationDropdown, { ReactSelectLocationOptionType } from '../../components/shared/AsyncPaginateDropdowns/LocationDropdown';
import SublocationDropdown, { ReactSelectSublocationOptionType } from '../../components/shared/AsyncPaginateDropdowns/SublocationDropdown';
import AssetClassDropdown from '../../components/shared/AsyncPaginateDropdowns/AssetClassDropdown';
import AssetSubClassDropdown from '../../components/shared/AsyncPaginateDropdowns/AssetSubClassDropdown';
import StatusDropdown from '../../components/shared/AsyncPaginateDropdowns/StatusDropdown';
import { readAndAddQRCode } from '../../utils/QRCode';
import QRCodeDialog from '../../components/shared/QRCodeDialog';
import QRCode from 'react-qr-code';
import { useTranslation, withTranslation } from 'react-i18next';
import FormsListCards from '../../components/shared/FormsListCards';
import PhotosCard from '../../components/shared/PhotosCard';
import DocumentsCard from '../../components/shared/DocumentsCard';
import { Response } from 'react-select-async-paginate';
import JobListCard from '../../components/shared/JobListCard';

//style
import '../../styles/shared.css'

interface AssetDetailProps {
	UserSettings: Store.UserSettings;
	match: match<{ id: string }>;
	history: History;
	t: any;
}

interface AssetDetailsState {
	JobIds: string[] | null;
	Jobs: Job.Job[];
	assetDetails: Asset | null;
	fromJobFBID: string;
	SearchbyQr: boolean;
	IsEdit: boolean;

	statusFilter?: {
		value: string;
		label: string;
	} | null;
	loadedStatuses: any[];
	statusesPerPage: 50;
	statusValue: '',
	endStatus: boolean;
	lastLoadedStatus: any;

	assetClassFilter?: {
		value: string;
		label: string;
	} | null;
	loadedAssetClasses: any[];
	assetClassesPerPage: 50;
	assetClassValue: '',
	endAssetClass: boolean;
	lastLoadedAssetClass: any;

	assetSubClassFilter?: {
		value: string;
		label: string;
	} | null;
	loadedAssetSubClasses: any[];
	assetSubClassesPerPage: 50;
	assetSubClassValue: '',
	endAssetSubClass: boolean;
	lastLoadedAssetSubClass: any;

	loadedSites: any[];
	siteValue: string;
	endSite: boolean;
	lastLoadedSite: any;

	siteFilter?: {
		value: number;
		label: string;
	} | null;

	locationsPerPage: number;
	lastLoadedLocation: any;
	locationValue: string;
	loadedLocations: [];
	endLocation: boolean;
	locationFilter?: {
		value: number;
		label: string;
	} | null;
	locationDisabled: boolean;

	subLocationsPerPage: 50,
	lastLoadedSubLocation: any,
	subLocationValue: '',
	loadedSubLocations: [],
	endSubLocation: boolean,
	subLocationFilter?: {
		value: number;
		label: string;
	} | null;
	subLocationDisabled: boolean
}

class AssetDetails extends React.Component<AssetDetailProps, AssetDetailsState> {
	constructor(props) {
		super(props);
		this.state = {
			JobIds: [],
			Jobs: [],
			assetDetails: null,
			fromJobFBID: '',
			SearchbyQr: false,
			IsEdit: false,

			statusFilter: null,
			loadedStatuses: [],
			statusesPerPage: 50,
			statusValue: '',
			endStatus: false,
			lastLoadedStatus: null,

			assetClassFilter: null,
			loadedAssetClasses: [],
			assetClassesPerPage: 50,
			assetClassValue: '',
			endAssetClass: false,
			lastLoadedAssetClass: null,

			assetSubClassFilter: null,
			loadedAssetSubClasses: [],
			assetSubClassesPerPage: 50,
			assetSubClassValue: '',
			endAssetSubClass: false,
			lastLoadedAssetSubClass: null,

			loadedSites: [],
			siteValue: '',
			endSite: false,
			lastLoadedSite: null,
			siteFilter: null,

			locationsPerPage: 50,
			lastLoadedLocation: null,
			locationValue: '',
			loadedLocations: [],
			endLocation: false,
			locationFilter: null,
			locationDisabled: true,

			subLocationsPerPage: 50,
			lastLoadedSubLocation: null,
			subLocationValue: '',
			loadedSubLocations: [],
			endSubLocation: false,
			subLocationFilter: null,
			subLocationDisabled: true
		};
	}

	async componentWillReceiveProps(newProps: AssetDetailProps) {
		const { id } = newProps.match.params as any;
		await this.handleComponentLoad();
	};

	async handleComponentLoad() {
		const { id } = this.props.match.params as any;
		const asset = fire.getDocumentQuery('Assets', id);

		asset.get().then(assetObj => {
			const Asset = assetObj.data() as Asset;


			Asset.QRCode = Asset.QRCode || '';

			var locationFilter;
			var subLocationFilter;
			var assetClassFilter;
			var assetSubClassFilter;
			var statusFilter;


			if (!isNullOrUndefined(Asset.LocationID && Asset.LocationName)) {
				locationFilter = { value: Asset.LocationID, label: Asset.LocationName }
			};

			if (!isNullOrUndefined(Asset.SubLocationID && Asset.SubLocationName)) {
				subLocationFilter = { value: Asset.SubLocationID, label: Asset.SubLocationName }
			};

			if (!isNullOrUndefined(Asset.AssetStatusFBID && Asset.AssetStatus)) {
				statusFilter = { value: Asset.AssetStatusFBID, label: Asset.AssetStatus }
			};

			if (!isNullOrUndefined(Asset.AssetClassFBID && Asset.AssetClass)) {
				assetClassFilter = { value: Asset.AssetClassFBID, label: Asset.AssetClass }
			};

			if (!isNullOrUndefined(Asset.AssetSubClassFBID && Asset.AssetSubClass)) {
				assetSubClassFilter = { value: Asset.AssetSubClassFBID, label: Asset.AssetSubClass }
			};

			this.setState({
				assetDetails: Asset,
				siteFilter: {
					value: Asset.SiteID,
					label: Asset.SiteName
				},
				locationFilter,
				subLocationFilter,
				assetClassFilter,
				assetSubClassFilter,
				statusFilter
			}, () => {
				if (!isNullOrUndefined(this.state.siteFilter)) {
					fire.doesLocationsExist(this.state.siteFilter.value).then(async (res) => {
						await this.setState({ locationDisabled: !res });
					})
				};

				if (!isNullOrUndefined(this.state.siteFilter) && !isNullOrUndefined(this.state.locationFilter)) {
					fire.doesSubLocationsExist(this.state.locationFilter.value).then(async (res) => {
						await this.setState({ subLocationDisabled: !res });
					})
				};
			});
		});
		this.getJobsNew(id);
	};

	async componentDidMount() {
		const { id, fromjobfbid } = this.props.match.params as any;

		await this.handleComponentLoad();
		if (!isNullOrUndefined(fromjobfbid)) {
			this.setState({ fromJobFBID: fromjobfbid });
		};
	};

	sortingFunction = (joblist: Job.Job[]) => {
		return joblist.sort((a, b) => {
			if (a.JobNumber === b.JobNumber) return 0;
			else if (a.JobNumber === 0) return -1;
			else if (b.JobNumber === 0) return 1;
			else if (a.JobNumber < b.JobNumber) return 1;
			else if (b.JobNumber < a.JobNumber) return -1;
			else return 0;
		});
	};

	getJobsNew(id) {
		let JobArray: Job.Job[] = [];
		if (!isNullOrUndefined(id)) {
			fire.baseQuery
				.collection('AssetsToJobs')
				.where('AssetFBID', '==', id)
				.get()
				.then(AssetJobs => {
					JobArray = AssetJobs.docs.map(assetJob => {
						let data = assetJob.data() as Job.Job;
						data.JobNumber = isNullOrUndefined(data.JobNumber) ? 0 : data.JobNumber;
						return data;
					});
					JobArray = this.sortingFunction(JobArray);
					this.setState({ Jobs: JobArray });
				});
		}
	};

	backButton = () => {
		window.history.back();
	};

	saveAsset = async () => {
		var newAsset = this.state.assetDetails;

		if (!isNullOrUndefined(newAsset)) {
			if (newAsset.AssetCode == "" || isNullOrUndefined(newAsset.AssetCode)) {
				window.alert(this.props.t("Please enter an asset code."));
				return;
			};

			let isDuplicateAsset = await fire.isAssetCodeDuplicate(newAsset.AssetCode, newAsset.AssetFBID);
			if (isDuplicateAsset) {
				window.alert(this.props.t("The code you have entered is used for another asset on the system. Please change the asset code to a non duplicate value."));
				return;
			};

			let isDuplicateQRCode = await fire.isQRDuplicate(newAsset.QRCode, newAsset.AssetFBID);
			if (isDuplicateQRCode) {
				window.alert(this.props.t("The QR code you have entered is used for another asset on the system. Please change the asset code to a non duplicate value."));
				return;
			};


			if (!isNullOrUndefined(this.state.siteFilter)) {
				newAsset.SiteID = this.state.siteFilter.value;
				newAsset.SiteName = this.state.siteFilter.label;

				fire.getSiteInfoFromID(newAsset.SiteID).then(sites => {
					sites.docs.map(site => {
						if (!isNullOrUndefined(newAsset)) {
							newAsset.SiteFBID = site.id
						};
					})
				});
			};


			if (!isNullOrUndefined(this.state.locationFilter)) {
				newAsset.LocationID = this.state.locationFilter.value;
				newAsset.LocationName = this.state.locationFilter.label;

				await fire.getLocationInfoFromID(newAsset.LocationID).then(locations => {
					locations.docs.map(location => {
						if (!isNullOrUndefined(newAsset)) {
							newAsset.LocationFBID = location.id;
						};
					})
				});

			} else {
				newAsset.LocationID = null;
				newAsset.LocationName = null;
				newAsset.LocationFBID = null;
			}


			if (!isNullOrUndefined(this.state.subLocationFilter)) {
				newAsset.SubLocationID = this.state.subLocationFilter.value;
				newAsset.SubLocationName = this.state.subLocationFilter.label;

				await fire.getSubLocationInfoFromID(newAsset.SubLocationID).then(sublocations => {
					sublocations.docs.map(sublocation => {
						if (!isNullOrUndefined(newAsset)) {
							newAsset.SubLocationFBID = sublocation.id
						};
					})
				});
			} else {
				newAsset.SubLocationID = null;
				newAsset.SubLocationName = null;
				newAsset.SubLocationFBID = null;
			}

			if (!isNullOrUndefined(this.state.statusFilter)) {
				newAsset.AssetStatusFBID = this.state.statusFilter.value;
				newAsset.AssetStatus = this.state.statusFilter.label;
			}

			if (!isNullOrUndefined(this.state.assetClassFilter)) {
				newAsset.AssetClassFBID = this.state.assetClassFilter.value;
				newAsset.AssetClass = this.state.assetClassFilter.label;
			}

			if (!isNullOrUndefined(this.state.assetSubClassFilter)) {
				newAsset.AssetSubClassFBID = this.state.assetSubClassFilter.value;
				newAsset.AssetSubClass = this.state.assetSubClassFilter.label;
			} else {
				newAsset.AssetSubClassFBID = null;
				newAsset.AssetSubClass = null;
				newAsset.AssetSubClassID = null;
			}


			if (!isNullOrUndefined(newAsset.AssetFBID)) {
				fire.CreateAsset(newAsset, newAsset.AssetFBID).catch(err => {
					alert(this.props.t("There was an error creating the asset, if problem persists please email - support@trackplanfm.com"));
					return;
				});
			};

			newAsset["JobAction"] = 'EditAsset';
			fire.postToJobQueue(newAsset.AssetFBID, newAsset).catch(err => {
				alert(this.props.t("There was an error creating the asset, if problem persists please email - support@trackplanfm.com"));
			});
		};

		this.handleComponentLoad();
		this.setState({ IsEdit: false });
	};

	handleLogout() {
		fire.auth.signOut().then(() => location.reload());
	}

	DetailsButton = async id => {
		let doesJobExist = await fire.doesJobExist(id);
		if (doesJobExist) {
			if (!isNullOrUndefined(this.state.assetDetails)) {
				this.props.history.push('/job-details/' + id + '/' + this.state.assetDetails.AssetFBID);
			} else {
				this.props.history.push('/job-details/' + id);
			}
		} else {
			alert(this.props.t("Job cannot be accessed"));
		}
	};

	handleQRCodeRead = async QRCode => {
		if (this.state.IsEdit) {
			this.setState(prevState => {
				let assetDetails = Object.assign({}, prevState.assetDetails);
				assetDetails.QRCode = QRCode.getText();
				return { assetDetails };
			});
		} else {
			readAndAddQRCode(QRCode, 'Assets', 'Asset', this.props.match.params.id)
		}
	}

	cancelEdit = async () => {
		await this.handleComponentLoad();
		await this.setState({ IsEdit: !this.state.IsEdit });
	};

	toggleEdit() {
		this.setState({ IsEdit: !this.state.IsEdit });
	};

	handleNameChange = event => {
		var newValue = event.target.value

		this.setState(prevState => {
			let assetDetails = Object.assign({}, prevState.assetDetails);
			assetDetails.AssetName = newValue;
			return { assetDetails };
		});
	};

	handleCodeChange = event => {
		var newValue = event.target.value

		this.setState(prevState => {
			let assetDetails = Object.assign({}, prevState.assetDetails);
			assetDetails.AssetCode = newValue;
			return { assetDetails };
		});
	};

	handleManufacturerChange = event => {
		var newValue = event.target.value

		this.setState(prevState => {
			let assetDetails = Object.assign({}, prevState.assetDetails);
			assetDetails.Manufacturer = newValue;
			return { assetDetails };
		});
	};

	handleSerialNoChange = event => {
		var newValue = event.target.value

		this.setState(prevState => {
			let assetDetails = Object.assign({}, prevState.assetDetails);
			assetDetails.SerialNumber = newValue;
			return { assetDetails };
		});
	};

	handleModelNoChange = event => {
		var newValue = event.target.value

		this.setState(prevState => {
			let assetDetails = Object.assign({}, prevState.assetDetails);
			assetDetails.ModelNumber = newValue;
			return { assetDetails };
		});
	};

	handleCommentsChange = event => {
		var newValue = event.target.value

		this.setState(prevState => {
			let assetDetails = Object.assign({}, prevState.assetDetails);
			assetDetails.Comments = newValue;
			return { assetDetails };
		});
	};

	handleQRChange = event => {
		var newValue = event.target.value

		this.setState(prevState => {
			let assetDetails = Object.assign({}, prevState.assetDetails);
			assetDetails.QRCode = newValue;
			return { assetDetails };
		});
	};

	handleAssetClassFilter = assetClass => {
		this.setState({
			assetClassFilter: { value: assetClass.value, label: assetClass.label },
			lastLoadedAssetClass: null,
		})
		this.clearSelectedAssetSubClass();
	};

	clearSelectedAssetClass = () => {
		this.setState({
			lastLoadedAssetClass: null,
			loadedAssetClasses: [],
			assetClassFilter: null,
			endAssetClass: false,
		})
		this.clearSelectedAssetSubClass();
	};

	clearSelectedAssetSubClass = () => {
		this.setState({
			assetSubClassFilter: null,
			endAssetSubClass: false,
			lastLoadedAssetSubClass: null,
			loadedAssetSubClasses: []
		})
	};

	clearSelectedStatus = () => {
		this.setState({
			statusFilter: null,
			endStatus: false,
			lastLoadedStatus: null,
			loadedStatuses: []
		})
	};

	clearQRCode = () => {
		this.setState(prevState => {
			let assetDetails = Object.assign({}, prevState.assetDetails);
			assetDetails.QRCode = "";
			return { assetDetails };
		});
	};

	loadStatuses = async (statusName, loadedOptions) => {
		return new Promise((resolve) => {
			fire.getAssetStatusPaginated(this.state.statusesPerPage, this.state.lastLoadedStatus).get().then(docs => {
				if (!docs.empty) {
					let statuses: any = [];
					docs.forEach(function (status) {
						const statusData = {
							value: status.id,
							label: status.data().StatusDescription
						}
						statuses.push(statusData);
					});
					this.setState({ loadedStatuses: statuses, lastLoadedStatus: docs.docs[docs.docs.length - 1] }, () => {
						return resolve({
							options: this.state.loadedStatuses,
							hasMore: !this.state.endStatus
						});
					})
				} else {
					this.setState({ endStatus: true });
					return resolve({
						options: [],
						hasMore: !this.state.endStatus
					});
				}
			})
		})
	};

	loadAssetClasses = async (assetClassName, loadedOptions) => {
		return new Promise((resolve) => {
			fire.getSearchAssetClassesPaginated(this.state.assetClassesPerPage, this.state.lastLoadedAssetClass).get().then(docs => {
				if (!docs.empty) {
					let assetClasses: any = [];
					docs.forEach(function (assetClass) {
						const assetClassData = {
							value: assetClass.id,
							label: assetClass.data().AssetClassName
						}
						assetClasses.push(assetClassData);
					});
					this.setState({ loadedAssetClasses: assetClasses, lastLoadedAssetClass: docs.docs[docs.docs.length - 1] }, () => {
						return resolve({
							options: this.state.loadedAssetClasses,
							hasMore: !this.state.endAssetClass
						});
					})
				} else {
					this.setState({ endAssetClass: true });
					return resolve({
						options: [],
						hasMore: !this.state.endAssetClass
					});
				}
			})
		})
	};

	loadAssetSubClasses = async (loadedOptions) => {
		var assetClassFBID;
		if (!isNullOrUndefined(this.state.assetClassFilter)) {
			assetClassFBID = this.state.assetClassFilter.value;
		};

		return new Promise((resolve) => {
			fire.getSearchAssetSubClassesPaginated(this.state.assetSubClassesPerPage, assetClassFBID, this.state.lastLoadedAssetSubClass).get().then(docs => {
				if (!docs.empty) {
					let assetSubClasses: any = [];
					docs.forEach(function (assetSubClass) {
						const assetSubClassData = {
							value: assetSubClass.id,
							label: assetSubClass.data().AssetSubClassName
						}
						assetSubClasses.push(assetSubClassData);
					});
					this.setState({ loadedAssetSubClasses: assetSubClasses, lastLoadedAssetSubClass: docs.docs[docs.docs.length - 1] }, () => {
						return resolve({
							options: this.state.loadedAssetSubClasses,
							hasMore: !this.state.endAssetSubClass
						});
					})
				} else {
					this.setState({ endAssetSubClass: true });
					return resolve({
						options: [],
						hasMore: !this.state.endAssetSubClass
					});
				}
			})
		})
	};

	handleAssetSubClassFilter = assetSubClass => {
		this.setState({
			assetSubClassFilter: { value: assetSubClass.value, label: assetSubClass.label },
			lastLoadedAssetSubClass: null
		})
	};

	handleStatusFilter = status => {
		this.setState({
			statusFilter: { value: status.value, label: status.label },
			lastLoadedStatus: null
		})
	};

	handleSiteFilter = site => {
		this.setState({
			siteFilter: { value: site.value, label: site.label },
			lastLoadedSite: null,
			locationFilter: null,
			loadedLocations: [],
			loadedSubLocations: [],
			lastLoadedLocation: null,
			lastLoadedSubLocation: null,
			subLocationFilter: null,
			locationValue: '',
			subLocationValue: '',

			locationDisabled: true,
			subLocationDisabled: true,
		}, () => {
			if (!isNullOrUndefined(this.state.siteFilter)) {
				fire.doesLocationsExist(this.state.siteFilter.value).then((res) => {
					this.setState({
						locationDisabled: !res
					})
				})
			}
		});
	};

	handleLocationFilter = location => {
		this.setState({
			locationFilter: { value: location.value, label: location.label },
			lastLoadedLocation: null,
			lastLoadedSubLocation: null,
			subLocationValue: '',
			loadedSubLocations: [],

			subLocationDisabled: true,
		}, () => {
			if (!isNullOrUndefined(this.state.siteFilter) && !isNullOrUndefined(this.state.locationFilter)) {
				fire.doesSubLocationsExist(this.state.locationFilter.value).then((res) => {
					this.setState({
						subLocationDisabled: !res
					})
				})
			}
		});
	}

	loadOptions = async (siteName, loadedOptions) => {
		if (siteName !== this.state.siteValue) {
			await this.setState({ loadedSites: [], lastLoadedSite: null, endSite: false, siteValue: siteName });
			loadedOptions = [];
		}


		return new Promise<Response<ReactSelectSiteOptionType>>((resolve) => {
			fire.getSearchSitesPaginated(50, this.state.siteValue, this.state.lastLoadedSite, this.props.UserSettings.CanAccessAllSites, this.props.UserSettings.ContractFBID).get().then(docs => {
				if (!docs.empty) {
					let sites: any = [];
					docs.forEach(function (site) {
						const siteData = {
							value: site.data().SiteID,
							label: site.data().SiteName
						}
						sites.push(siteData);
					});
					this.setState({ loadedSites: sites, lastLoadedSite: docs.docs[docs.docs.length - 1] }, () => {
						return resolve({
							options: this.state.loadedSites,
							hasMore: !this.state.endSite
						});
					})
				} else {
					this.setState({ endSite: true });
					return resolve({
						options: [],
						hasMore: !this.state.endSite
					});
				}
			})
		})
	};

	loadLocations = async (locationName, loadedOptions) => {
		if (locationName !== this.state.locationValue) {
			await this.setState({ loadedLocations: [], lastLoadedLocation: null, locationValue: locationName });
			loadedOptions = [];
		}

		return new Promise<Response<ReactSelectLocationOptionType>>(resolve => {
			if (!isNullOrUndefined(this.state.siteFilter)) {
				fire.getSearchLocationsPaginated(
					this.state.locationsPerPage,
					this.state.siteFilter.value,
					this.props.UserSettings.CanAccessAllLocations,
					this.state.locationValue,
					this.state.lastLoadedLocation)
					.get()
					.then(docs => {
						if (!docs.empty) {
							let locations: any = [];
							docs.forEach(function (location) {
								const locationData = {
									value: location.data().LocationID,
									label: location.data().LocationName
								}
								locations.push(locationData);
							});
							this.setState({ loadedLocations: locations, lastLoadedLocation: docs.docs[docs.docs.length - 1] }, () => {
								return resolve({
									options: this.state.loadedLocations,
									hasMore: !this.state.endLocation
								});
							})
						} else {
							this.setState({ endLocation: true });
							return resolve({
								options: [],
								hasMore: !this.state.endLocation
							});
						}
					})
			} else {
				return resolve({
					options: [],
					hasMore: false
				})
			}
		})
	};

	handleSubLocationFilter = subLocation => {
		this.setState({
			subLocationFilter: { value: subLocation.value, label: subLocation.label },
			lastLoadedSubLocation: null
		});
	};

	loadSubLocations = async (subLocationName, loadedOptions) => {
		if (subLocationName !== this.state.subLocationValue) {
			await this.setState({ loadedSubLocations: [], lastLoadedSubLocation: null, subLocationValue: subLocationName });
			loadedOptions = [];
		}

		return new Promise<Response<ReactSelectSublocationOptionType>>(resolve => {
			if (!isNullOrUndefined(this.state.locationFilter)) {
				fire
					.getSearchSubLocationsPaginated(
						this.state.subLocationsPerPage,
						this.state.locationFilter.value,
						this.props.UserSettings.CanAccessAllLocations,
						this.state.subLocationValue,
						this.state.lastLoadedSubLocation
					)
					.get()
					.then(docs => {
						if (!docs.empty) {
							let subLocations: any = [];
							docs.forEach(function (subLocation) {
								const subLocationData = {
									value: subLocation.data().SubLocationID,
									label: subLocation.data().SubLocationName
								}
								subLocations.push(subLocationData);
							});
							this.setState({ loadedSubLocations: subLocations, lastLoadedSubLocation: docs.docs[docs.docs.length - 1] }, () => {
								return resolve({
									options: this.state.loadedSubLocations,
									hasMore: !this.state.endSubLocation
								});
							})
						} else {
							this.setState({ endSubLocation: true });
							return resolve({
								options: [],
								hasMore: !this.state.endSubLocation
							});
						}
					})
			} else {
				return resolve({
					options: [],
					hasMore: false
				})
			}
		})
	}

	clearSelectedSitesOrLocations = async (typeId) => {
		if (typeId === 1) {
			await this.setState({
				lastLoadedSite: null,
				loadedSites: [],
				siteFilter: null,
				siteValue: '',
				endSite: false,
				locationDisabled: true,
				subLocationDisabled: true,
			});
		}

		if (typeId === 1 || typeId === 2) {
			await this.setState({
				lastLoadedLocation: null,
				loadedLocations: [],
				locationFilter: null,
				locationValue: '',
				endLocation: false,
				subLocationDisabled: true,
			});
		}

		if (typeId === 1 || typeId === 2 || typeId === 3) {
			await this.setState({
				lastLoadedSubLocation: null,
				loadedSubLocations: [],
				subLocationFilter: null,
				subLocationValue: '',
				endSubLocation: false,
			});
		}
	}

	handleRaiseNewJobfromAsset = (selectedAsset: Asset | null, history: History) => {
		if (!isNullOrUndefined(selectedAsset)) {
			let ids: any;
			ids = selectedAsset.AssetFBID;
			this.props.history.push({
				pathname: '/new-request-from-Asset/' + selectedAsset.AssetFBID,
				state: {
					JobDetails: this.props.t("Requested from Asset:") + " " + selectedAsset.AssetName,
					SelectedSiteName: selectedAsset.SiteName,
					SelectedSiteID: selectedAsset.SiteID,
					SelectedLocationName: selectedAsset.LocationName,
					SelectedLocationID: selectedAsset.LocationID,
					SelectedSubLocationName: selectedAsset.SubLocationName,
					SelectedSubLocationID: selectedAsset.SubLocationID,
					AssetsAttached: ids,
					guid: null,
				},
			});
		}
	};

	render() {
		if (!isNullOrUndefined(this.state.assetDetails)) {
			return (
				<div className="assetDetailsBackground">
					<SideDrawer
						history={this.props.history}
						title={this.state.assetDetails.AssetName.length > 15 ? this.state.assetDetails.AssetName.substr(0, 15) + "..." : this.state.assetDetails.AssetName}
						rightMenuButton={
							<div>
								<BackButton callbackMethod={this.backButton} /></div>
						}
						colour="primary"
						handleLogout={this.handleLogout}
						User={this.props.UserSettings}
						versionApp={Api.VERSION}
						versionDb={Api.INDEXEDDB_VERSION}
						SendErrorData={SendErrorData}
						getBaseURL={getBaseURL}
						ApiKeyObj={ApiKeyObj}
					/>
					{!isNullOrUndefined(this.state.assetDetails) ? (
						<Grid container={true} direction="column" justify="center" alignItems="stretch">
							<DialogContent>
								<div id="details-outer-asset">
									<div className="top-card-alt card-shadow-alt">

										{this.state.IsEdit ? <FieldHolderNameEdit content={this.state.assetDetails.AssetName} label={this.props.t("Asset Name")} handleChange={this.handleNameChange} fullLine="true" />
											: <FieldHolderName content={this.state.assetDetails.AssetName} label={this.props.t("Asset Name")} fullLine="true" />}

										{this.state.IsEdit ? <FieldHolderEdit content={this.state.assetDetails.AssetCode} label={this.props.t("Asset Code")} handleChange={this.handleCodeChange} />
											: <FieldHolder content={this.state.assetDetails.AssetCode} label={this.props.t("Asset Code")} />}


										{this.state.IsEdit ? <StatusDropdown
											statusFilter={this.state.statusFilter}
											handleStatusFilter={this.handleStatusFilter}
											loadStatuses={this.loadStatuses}
											clearSelectedStatuses={this.clearSelectedStatus}
										/>
											: <FieldHolder content={this.state.assetDetails.AssetStatus} label={this.props.t("Asset Status")} />}

										{this.state.IsEdit ? <AssetClassDropdown
											assetClassFilter={this.state.assetClassFilter}
											handleAssetClassFilter={this.handleAssetClassFilter}
											loadAssetClasses={this.loadAssetClasses}
											clearSelectedAssetClass={this.clearSelectedAssetClass}
										/>
											: <FieldHolder content={this.state.assetDetails.AssetClass} label={this.props.t("Asset Class")} />}

										{this.state.IsEdit ? <AssetSubClassDropdown
											clearSelectedAssetClass={this.clearSelectedAssetClass}
											assetSubClassFilter={this.state.assetSubClassFilter}
											handleAssetSubClassFilter={this.handleAssetSubClassFilter}
											loadAssetSubClasses={this.loadAssetSubClasses}
											clearSelectedAssetSubClass={this.clearSelectedAssetSubClass}
										/>
											: <FieldHolder content={this.state.assetDetails.AssetSubClass} label={this.props.t("Asset Sub Class")} />}
									</div>

									<div className="top-card-alt card-shadow-alt">
										{this.state.IsEdit ? <SiteDropdown
											siteFilter={this.state.siteFilter}
											siteInputValue={this.state.siteValue}
											loadSites={this.loadOptions}
											handleSiteFilter={this.handleSiteFilter}
										/>
											: <FieldHolder content={this.state.assetDetails.SiteName} label={this.props.t("Site")} link={this.state.assetDetails.SiteFBID} field="site" />}

										{this.state.IsEdit ? <LocationDropdown
											siteFilter={this.state.siteFilter}
											siteInputValue={this.state.siteValue}
											locationFilter={this.state.locationFilter}
											locationInputValue={this.state.locationValue}
											loadLocations={this.loadLocations}
											handleLocationFilter={this.handleLocationFilter}
											clearSelectedSitesOrLocations={(typeId) => this.clearSelectedSitesOrLocations(typeId)}
											locationDisabled={this.state.locationDisabled}
										/>
											: <FieldHolder content={this.state.assetDetails.LocationName} label={this.props.t("Location")} link={this.state.assetDetails.LocationFBID} field="location" />}

										{this.state.IsEdit ? <SublocationDropdown
											siteFilter={this.state.siteFilter}
											siteInputValue={this.state.siteValue}
											locationFilter={this.state.locationFilter}
											locationLabelValue={this.state.locationValue}
											subLocationFilter={this.state.subLocationFilter}
											subLocationInputValue={this.state.subLocationValue}
											loadSubLocations={this.loadSubLocations}
											handleSubLocationFilter={this.handleSubLocationFilter}
											clearSelectedSitesOrLocations={(typeId) => this.clearSelectedSitesOrLocations(typeId)}
											subLocationDisabled={this.state.subLocationDisabled}
										/>
											: <FieldHolder content={this.state.assetDetails.SubLocationName} label={this.props.t("Sublocation")} link={this.state.assetDetails.SubLocationFBID} field="sublocation" />}

										{this.state.IsEdit ? <FieldHolderEdit content={this.state.assetDetails.Manufacturer} label={this.props.t("Manufacturer")} handleChange={this.handleManufacturerChange} />
											: <FieldHolder content={this.state.assetDetails.Manufacturer} label={this.props.t("Manufacturer")} />}

										{this.state.IsEdit ? <FieldHolderEdit content={this.state.assetDetails.SerialNumber} label={this.props.t("Serial Number")} handleChange={this.handleSerialNoChange} />
											: <FieldHolder content={this.state.assetDetails.SerialNumber} label={this.props.t("Serial Number")} />}

										{this.state.IsEdit ? <FieldHolderEdit content={this.state.assetDetails.ModelNumber} label={this.props.t("Model Number")} handleChange={this.handleModelNoChange} />
											: <FieldHolder content={this.state.assetDetails.ModelNumber} label={this.props.t("Model Number")} />}

										{this.state.IsEdit ? <FieldHolderEdit content={this.state.assetDetails.Comments} label={this.props.t("Comments")} handleChange={this.handleCommentsChange} />
											: <FieldHolder content={this.state.assetDetails.Comments} label={this.props.t("Comments")} />}
									</div>

									<div className="top-card-alt card-shadow-alt">

										{this.state.IsEdit ? <FieldHolderEdit content={this.state.assetDetails.QRCode} label={this.props.t("QRCode")} handleChange={this.handleQRChange}
											showDelete={true} clearQRCode={this.clearQRCode} />
											: <FieldHolder content={this.state.assetDetails.QRCode} label={this.props.t("QRCode")} />}
										<QRCodeDialog
											isOpen={this.state.SearchbyQr}
											close={() => this.setState({ SearchbyQr: false })}
											readAndAddQRCode={this.handleQRCodeRead}
										/>
										{this.state.assetDetails.QRCode && this.state.assetDetails.QRCode !== '' ? (
											<QRCode
												bgColor="#FFFFFF"
												fgColor="#000000"
												level="L"
												size={64}
												value={this.state.assetDetails.QRCode as string}
											/>
										) : (
											<Button
												onClick={() => this.setState({ SearchbyQr: true })}
												className='add-qr-code-button'
												variant="outlined"
												size="large"
											>
												{this.props.t("Add QR Code")} <CameraEnhanceIcon className='padding-left'></CameraEnhanceIcon>
											</Button>
										)}
									</div>
									<FormsListCards
										fromView="Asset"
										associatedFBID={this.state.assetDetails.AssetFBID}
										areTemplatesRestricted={
											this.state.assetDetails.FormTemplatesRestricted != null ?
												this.state.assetDetails.FormTemplatesRestricted : false}
										restrictedTemplates={this.state.assetDetails
											.RestrictedFormTemplates != null ?
											this.state.assetDetails.RestrictedFormTemplates : []}
									/>
									<PhotosCard
										fromView="Asset"
										associatedFBID={this.state.assetDetails.AssetFBID}
									/>
									<DocumentsCard
										fromView="Asset"
										associatedFBID={this.state.assetDetails.AssetFBID}
									/>
									<JobListCard
										assetJobs={this.state.Jobs}
										history={this.props.history}
										detailsButton={this.DetailsButton}
										activeAsset={this.state.assetDetails}
										handleRaiseNewJobfromAsset={this.handleRaiseNewJobfromAsset}
									/>
								</div>
							</DialogContent>
							{this.props.UserSettings.CanAddAssets ? <div className="flex" style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<Fab
									id="search-fab"
									color="inherit"
									aria-label="Add"
									style={{ backgroundColor: this.state.IsEdit ? '#01a901' : '#f59e38' }}
									onClick={() => this.state.IsEdit ? this.saveAsset() : this.toggleEdit()}
								>
									<Icon style={{ color: 'white' }}>{this.state.IsEdit ? 'save' : 'edit'}</Icon>
								</Fab>
								{this.state.IsEdit ? <Fab
									className="not-hidden"
									id="clear-search-fab"
									color="inherit"
									aria-label="Add"
									style={{ backgroundColor: 'var(--light-red)' }}
									onClick={() => { this.cancelEdit() }}
								>
									<Icon style={{ color: 'white' }}>clear</Icon>
								</Fab> : null}
							</div> : null}

						</Grid>
					) : null}
				</div>
			);
		}
		return <LoadingSpinner color="primary" text={this.props.t("Loading asset details")} />;
	}
}
const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
});



const FieldHolder = props => (
	<div className={`${props.pullRight ? 'pull-right' : ''}${props.fullLine ? 'full-line' : ''} field`}>
		<p style={{ overflowWrap: 'break-word' }} className="field-content-asset">
			{!isNullOrUndefined(props.link) ? (<Link to={`/${props.field}-details/${props.link}`} >{props.content}<TouchAppIcon fontSize="small" style={{ color: "#a252d4" }}></TouchAppIcon></Link>) : props.content}
		</p>
		<p className="field-label-asset">{props.label}</p>
	</div>
);

const FieldHolderEdit = props => (
	<div className={`${props.pullRight ? 'pull-right' : ''}${props.fullLine ? 'full-line' : ''} field`}>
		<TextField
			margin="dense"
			name={generateFirebaseId()}
			id="free-text-input"
			type="text"
			fullWidth={true}
			value={props.content || ''}
			onChange={props.handleChange}
		/>
		<p className="field-label-asset">{props.label} {!isNullOrUndefined(props.showDelete) && props.content !== '' && (
			<IconButton onClick={() => props.clearQRCode()} style={{ cursor: 'pointer' }}>
				<Icon color="error">
					delete_forever
				</Icon>
			</IconButton>
		)}</p>
	</div>
);

const FieldHolderName = props => (
	<div className={`${props.pullRight ? 'pull-right' : ''}${props.fullLine ? 'full-line-asset' : ''} field`}>
		<h3 className='field-holder-name'>
			{props.content}
		</h3>
		<p className="field-label-asset">{props.label}</p>
		<hr />
	</div>
);

const FieldHolderNameEdit = props => (
	<div className={`${props.pullRight ? 'pull-right' : ''}${props.fullLine ? 'full-line-asset' : ''} field`}>
		<TextField
			margin="dense"
			name={generateFirebaseId()}
			id="free-text-input"
			type="text"
			fullWidth={true}
			value={props.content || ''}
			onChange={props.handleChange}
		/>
		<p className="field-label-asset">{props.label}</p>
		<hr />
	</div>
);

export default withTranslation()(connect(mapStateToProps)(AssetDetails));
