// React
import * as React from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { match } from 'react-router';

// Config File
import Api from '../../apiConfiguration.json';

// Firebase
import firebase from 'firebase';
import { fire } from '../../index';

// Material UI
import { Button, Grid, Icon, IconButton } from '@material-ui/core';

// Components
import LoadingSpinner from '../../components/Spinners/LoadingSpinner';
import SideDrawer from '../../components/shared/SideDrawer';
import LocationCard from '../../components/locations/LocationCard';
import BackButton from '../../components/shared/BackButton';


// Utils
import { isNullOrUndefined } from 'util';
import { LocalstorageRemoveItem } from '../../utils/LocalStorage';
import { windowError, SendErrorData } from '../../utils/WindowError';
import { getBaseURL } from '../../utils/getBaseURL';
import ApiKeyObj from '../../utils/ApiObjectKey';
import { generateFirebaseId } from '../../utils/Guids';

// CSS
import '../../styles/sites/site-list.css';
import QRCodeDialog from '../../components/shared/QRCodeDialog';
import { readQRCodeAndNavigate } from '../../utils/QRCode';
import { withTranslation } from 'react-i18next';

interface LocationsListScreenProps {
	history: History;
	match: match;
	UserSettings: Store.UserSettings;
	t: any;
}

interface LocationsListScreenState {
	locationArray: Location[];
	SelectedLocation: Location | null;
	lastLoadedLocation: any;
	loading: boolean;
	loadingMore: boolean;
	LoadingPageGrid: boolean;
	endOfList: boolean;
	documentsPerPage: number;
	DocumentID: string;
	yAxisScroll: number;
	siteFBID: string;
	siteName: string;
	SearchbyQr: boolean;
}

class LocationsListScreen extends React.Component<LocationsListScreenProps, LocationsListScreenState> {
	private unsubscribe: any = null;

	constructor(props) {
		super(props);
		this.state = {
			locationArray: [],
			SelectedLocation: null,
			lastLoadedLocation: null,
			loading: false,
			loadingMore: false,
			LoadingPageGrid: true,
			endOfList: false,
			documentsPerPage: 25,
			DocumentID: '',
			yAxisScroll: 0,
			siteFBID: "",
			siteName: "",
			SearchbyQr: false,
		};
	}

	componentWillMount() {
		windowError(
			this.props.UserSettings.email,
			this.props.UserSettings.UserUID,
			this.props.UserSettings.ServerName,
			'LocationList',
		);

		this.setState({ loading: true });
	}

	componentDidMount() {
		const { id } = this.props.match.params as any;
		this.setState({ DocumentID: id, siteFBID: id });
		this.getLocations();

		this.getSite(id);

	}

	getSite(id: string){
		fire.getSiteInfo(id).then(result => {
			const site = result.data();
			if(!isNullOrUndefined(site)){ this.setState({siteName: site.SiteName}); }
		});
	}

	//Run the initial query to get all locations for client.
	getLocations() {
		this.setState({ yAxisScroll: window.scrollY, loading: true }, () => {
			this.unsubscribe = fire
				.getLocationsCollectionPaginated(this.state.documentsPerPage, this.state.siteFBID, this.state.lastLoadedLocation)
				.onSnapshot(this.handleLocationSnapshot);
		});
	}

	//Process the query returned from getLocations()
	handleLocationSnapshot = (query: firebase.firestore.QuerySnapshot) => {
		if (query.empty) {
			this.setState({ loading: false, loadingMore: false, endOfList: true });
			window.scrollTo(0, this.state.yAxisScroll);
			if (isNullOrUndefined(this.state.lastLoadedLocation)) this.setState({ locationArray: [] });
			return;
		}

		let locationArray = isNullOrUndefined(this.state.lastLoadedLocation) ? [] : this.state.locationArray;
		locationArray.push(
			...query.docs.map(locationRow => {
				const row = locationRow.data() as Location;
				row.SiteName = this.state.siteName;
				row.ID = locationRow.id;
				return row;
			}),
		);

		locationArray.sort((a, b) => a.LocationName.toLowerCase().localeCompare(b.LocationName.toLowerCase()));

		this.setState({
			locationArray,
			lastLoadedLocation: query.docs[query.docs.length - 1],
			loading: false,
			loadingMore: false,
		}, () => {
			window.scrollTo(0, this.state.yAxisScroll);
		});

	};

	backButton = () => {
		window.history.back()
	};

	handleLogout() {
		LocalstorageRemoveItem('NewJobDetails');
		fire.auth.signOut().then(() => location.reload());
	}

	render() {
		const title = this.props.t('Location List');

		if (this.state.loading) {
			return <LoadingSpinner text={this.props.t("Loading Locations...")} />;
		}

		if (!isNullOrUndefined(this.state.locationArray) && this.state.locationArray.length < 1) {
			return (
				<div>
					<SideDrawer
						history={this.props.history}
						title={title}
						rightMenuButton={
						
							<div>
							{' '}
							<IconButton onClick={() => this.setState({ SearchbyQr: !this.state.SearchbyQr })} color="primary">
								<Icon style={{ color: 'white', marginRight: 10 }}>image_search</Icon>
							</IconButton>
							<BackButton callbackMethod={this.backButton} />
							</div>
						
						}
						colour="primary"
						handleLogout={this.handleLogout}
						User={this.props.UserSettings}
						versionApp={Api.VERSION}
						versionDb={Api.INDEXEDDB_VERSION}
						SendErrorData={SendErrorData}
						getBaseURL={getBaseURL}
						ApiKeyObj={ApiKeyObj}
					/>
					<Grid container={true} direction="column" justify="center" alignItems="center">
						<div className="main-site" style={{ marginTop: '10px' }}>
							<div className="no-jobtasks-card">
								<h1 className="hot-pink">{this.props.t("There are no locations available.")}</h1>
							</div>
						</div>
					</Grid>
				</div>
			);
		}

		return (
			<div>
				<SideDrawer
					history={this.props.history}
					title={title}
					rightMenuButton={
						
						<div>
						{' '}
						<IconButton onClick={() => this.setState({ SearchbyQr: !this.state.SearchbyQr })} color="primary">
							<Icon style={{ color: 'white', marginRight: 10 }}>image_search</Icon>
						</IconButton>
						<BackButton callbackMethod={this.backButton} />
						</div>
					
					}
					colour="primary"
					handleLogout={this.handleLogout}
					User={this.props.UserSettings}
					versionApp={Api.VERSION}
					versionDb={Api.INDEXEDDB_VERSION}
					SendErrorData={SendErrorData}
					getBaseURL={getBaseURL}
					ApiKeyObj={ApiKeyObj}
				/>
				<br />
				<Grid container={true} direction="column" justify="center" alignItems="center">
					<div className="main-site" style={{ marginTop: '10px' }}>
						 { <LocationCard
							locations={this.state.locationArray}
							DocumentID={this.state.DocumentID}
							history={this.props.history}
						/>  }

						{this.state.endOfList ? (
							<Button variant="outlined" size="large" fullWidth disabled>
								{this.props.t("All Locations Loaded")}
							</Button>
						) : (
							<Button variant="outlined" color="primary" size="large" fullWidth onClick={() => this.getLocations()}>
								{this.props.t("Load More Locations")}
							</Button>
						)}

					</div>
					<div>
						<QRCodeDialog
							isOpen={this.state.SearchbyQr}
							close={() => this.setState({ SearchbyQr: false })}
							history={this.props.history}
							readQRCodeAndNavigate={readQRCodeAndNavigate}
						/>
					</div>
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
});

export default withTranslation()(connect(mapStateToProps)(LocationsListScreen));
