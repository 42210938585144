import { useCallback, useState } from 'react';

const useDialog = () => {
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const openDialog = useCallback(() => {
		setIsDialogOpen(true);
	}, []);

	const closeDialog = useCallback(() => {
		setIsDialogOpen(false);
	}, []);

	return {
		isDialogOpen,
		openDialog,
		closeDialog,
	};
};

export default useDialog;
