import firebase from 'firebase';
/** Firestore data converter to convert an asset to firestore
 * @see {@link https://firebase.google.com/docs/reference/js/firestore_lite.firestoredataconverter}
 */
export const fireAssetConverter: firebase.firestore.FirestoreDataConverter<Asset> = {
	toFirestore(asset: Asset): firebase.firestore.DocumentData {
		return asset;
	},
	fromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot): Asset {
		const data = snapshot.data();
		return { ...(data as Asset), AssetFBID: snapshot.id };
	},
};

/**
 * Firestore data converter to convert a form instance to firestore
 */
export const fireFormConverter: firebase.firestore.FirestoreDataConverter<Forms.Form> = {
	toFirestore(form: Forms.Form): firebase.firestore.DocumentData {
		return form;
	},
	fromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot): Forms.Form {
		const data = snapshot.data();
		return { ...(data as Forms.Form), Id: snapshot.id, FBID: snapshot.id, formRef: snapshot.ref };
	},
};

/**
 * Firestore data converter to convert a site instance to firestore
 */
export const fireSiteConverter: firebase.firestore.FirestoreDataConverter<Site> = {
	toFirestore(form: Site): firebase.firestore.DocumentData {
		return form;
	},
	fromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot): Site {
		const data = snapshot.data();
		return { ...(data as Site), ID: snapshot.id, SiteFBID: snapshot.id };
	},
};

/**
 * Firestore data converter to convert a location instance to firestore
 */
export const fireLocationConverter: firebase.firestore.FirestoreDataConverter<Location> = {
	toFirestore(form: Location): firebase.firestore.DocumentData {
		return form;
	},
	fromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot): Location {
		const data = snapshot.data();
		return { ...(data as Location), ID: snapshot.id };
	},
};

/**
 * Firestore data converter to convert a sublocation instance to firestore
 */
export const fireSubLocationConverter: firebase.firestore.FirestoreDataConverter<SubLocation> = {
	toFirestore(form: SubLocation): firebase.firestore.DocumentData {
		return form;
	},
	fromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot): SubLocation {
		const data = snapshot.data();
		return { ...(data as SubLocation), ID: snapshot.id };
	},
};

/**
 * Firestore data converter to convert a tag instance to firestore
 */
export const fireTagConverter: firebase.firestore.FirestoreDataConverter<DocumentTag> = {
	toFirestore(form: DocumentTag): firebase.firestore.DocumentData {
		return form;
	},
	fromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot): DocumentTag {
		const data = snapshot.data();
		return { ...(data as DocumentTag) };
	},
};
