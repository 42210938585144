/* eslint-disable prettier/prettier */
// React
import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

// Firebase
import firebase from 'firebase';
import { fire } from '../index';

// Logo
import imgLogo from '../assets/images/big-logo.png';
import dimoLogo from '../assets/images/dimo-logo.png';

// Actions
import { loginUser, loginUserError, loginUserSuccess } from '../actions/UserActions';
import { IsChrome } from '../utils/IsChrome';

// Material UI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

// Components
import IOSAddToHomeScreenBubble from '../components/IOS/IOSAddToHomeScreenBubble';
import LoadingSpinner from '../components/Spinners/LoadingSpinner';

// Utils
import { installOfflineWatcher } from '../utils/Network';
import { isNullOrUndefined } from 'util';
import { LandingPage } from '../utils/LandingPage';
import { IsDevelopment } from '../utils/IsDevelopment';

// CSS
import '../styles/login/login.css';

// Third Party
import * as Sentry from '@sentry/browser';

import Api from '../apiConfiguration.json';
import axios, { AxiosError } from 'axios';
import ApiKeyObj from '../utils/ApiObjectKey';
import { generateFirebaseId } from '../utils/Guids';
import { SendErrorData } from '../utils/WindowError';
import LoginForm from './LoginForm';

interface LoginState {
	Email: string;
	Password: string;
	LoginErrorMessage: string;
	LoginSuccessMessage: string;
	User: firebase.User | null;
	showInstallMessage: boolean;
	offline: boolean;
	NewPassword: string;
	NewPasswordCheck: string;
	setPassword: boolean;
	EmailLink: boolean;
	clientUID: string;
	resetPassword: boolean;
	forgotPasswordURL: string | null;
	ActionCode: string;
	ForgotPassword: boolean;
	AppURL: string | null;
	displayCaptcha: boolean;
	displayEmailVerification: boolean;
	loginImage: string;

}

interface LoginProps {
	loginUser: () => void;
	loginUserSuccess: (user: firebase.User) => void;
	loginUserError: (error: firebase.auth.Error | string) => void;
	UID: number;
	IsLoggedIn: boolean;
	Loading: boolean;
	Error: string;
	User: firebase.User | null;
	ClientID: number;
	UserSettings: Store.UserSettings;
}

class LoginScreen extends React.Component<LoginProps, LoginState> {
	constructor(props: any) {
		super(props);
		this.state = {
			Email: '',
			Password: '',
			LoginErrorMessage: '',
			LoginSuccessMessage: '',
			User: null,
			showInstallMessage: false,
			offline: false,
			NewPassword: '',
			NewPasswordCheck: '',
			setPassword: false,
			EmailLink: false,
			clientUID: '',
			resetPassword: false,
			forgotPasswordURL: '',
			ActionCode: '',
			ForgotPassword: false,
			AppURL: '',
			displayCaptcha: false,
			displayEmailVerification: false,
			loginImage: imgLogo,
		};
	}

	componentDidMount() {
		installOfflineWatcher(offline => {
			this.setState({ offline });
		});

		this.processURL();
		this.checkIfDimo();
	}

	checkIfDimo() {
		var url = window.location.href;
		if (url.includes("appges.trackplanfm.com")) {
			this.setState({ loginImage: dimoLogo });
		}
	};

	sendForgotPasswordLink() {
		let actionCodeSettings = {
			url: 'https://manager.trackplanfm.com/'
		};

		fire.auth.sendPasswordResetEmail(this.state.Email, actionCodeSettings)
			.then((result) => {
				this.setState({ LoginErrorMessage: '', LoginSuccessMessage: 'An email has been sent out successfully to the address you have entered. Please check your inbox and follow the instructions.' });
			}).catch((error) => {
				this.setState({ LoginErrorMessage: error.message, LoginSuccessMessage: '' });
			});
	}

	processURL() {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);

		const mode = urlParams.get('mode');
		const actionCode = urlParams.get('oobCode');
		const appName = urlParams.get('continueUrl');
		console.log(appName);

		if (!isNullOrUndefined(actionCode) && mode == "resetPassword") {
			this.setState({ forgotPasswordURL: appName, ActionCode: actionCode });
			this.handleResetPassword(actionCode, appName);
		}

		else if (!isNullOrUndefined(actionCode) && mode == "signIn") {
			this.setState({ displayCaptcha: true, ActionCode: actionCode });
		}
	}

	handleResetPassword(actionCode: string, appType: string | null) {
		fire.auth.verifyPasswordResetCode(actionCode).then((email) => {
			this.setState({ resetPassword: true, Email: email });
		});
	};

	submitNewPassword() {
		fire.auth.confirmPasswordReset(this.state.ActionCode, this.state.NewPassword).then((resp) => {
			if (this.state.forgotPasswordURL == "https://resource.trackplanfm.com/") {
				window.location.replace("https://resource.trackplanfm.com/");
			}

			else {
				this.setState({ resetPassword: false, LoginErrorMessage: '', LoginSuccessMessage: 'Your password has been changed successfully. You can now log in with the new password.' });
			}

		}).catch((error) => {
			this.setState({ LoginErrorMessage: error.message });
		});
	}

	async handleCaptcha() {
		var isValidLink: boolean = await fire.auth.isSignInWithEmailLink(window.location.href);

		if (isValidLink) {
			this.setState({ displayCaptcha: false, displayEmailVerification: true });
		}
		else {
			this.setState({ LoginErrorMessage: "This email has expired or already been accessed. Please contact your administrator to provide a new link or set you up manually." });
		}

	};

	UNSAFE_componentWillReceiveProps(newProps: LoginProps): void {
		if (newProps.Error !== this.props.Error) {
			this.setState({
				LoginErrorMessage: newProps.Error,
			});
		}
		if (newProps.User !== this.props.User) {
			this.setState({ User: newProps.User });
		}
	}

	handleSubmit = () => {

		this.setState({ LoginSuccessMessage: '' });

		if (this.state.Email.length === 0) {
			this.setState({ LoginErrorMessage: 'Email not defined.' });
			return;
		}
		if (!this.state.Email.includes('@') || !this.state.Email.includes('.')) {
			this.setState({ LoginErrorMessage: 'Not a valid email.' });
			return;
		}
		if (this.state.Password.length === 0) {
			this.setState({ LoginErrorMessage: 'Password not defined.' });
			return;
		}
		if (this.state.Password.length === 0 && this.state.Email.length === 0) {
			this.setState({ LoginErrorMessage: 'Email & Password not defined.' });
			return;
		}
		this.loginFirebase();
	};

	setStateHandler = (state: LoginState) => {
		this.setState(state)
	}

	async loginFirebase() {
		this.props.loginUser();
		await fire.auth
			.signInWithEmailAndPassword(this.state.Email, this.state.Password)
			.then(async user => {
				const fbUser = user.user as firebase.User;
				const doesUserExist = await fire.doesUserExist(fbUser.uid);
				if (!doesUserExist) {
					this.setState({
						LoginErrorMessage:
							'There is no user record corresponding to this identifier. The user may have been deleted.',
					});
					this.handleLogout();
				} else {
					var isDeletedOrDisabled = await fire.IsUserDeletedOrDisabled(fbUser.uid);

					var isExpired = await fire.isClientExpired();

					if (isDeletedOrDisabled || isExpired) {
						this.setState({
							LoginErrorMessage:
								'This user has been either deleted or disabled or the client has expired. Please contact your administrator to re-enable your account.',
						});
						this.handleLogout();
					} else {
						this.props.loginUserSuccess(fbUser);
					}

				}
			})
			.catch((err: firebase.auth.Error) => this.props.loginUserError(err.message));
	}

	loginMicrosoft = async () => {
		// Initial configuration
		var microsoftProvider = new firebase.auth.OAuthProvider("microsoft.com");

		await firebase.auth().signInWithPopup(microsoftProvider).then(async (user) => {
			const fbUser = user.user as firebase.User;
			const doesUserExist = await fire.doesUserExist(fbUser.uid);
			if (!doesUserExist) {
				this.setState({
					LoginErrorMessage: 'Resource not found. Please check login details and try again.',
				});
				fbUser.delete();
				this.handleLogout();
			} else {
				this.props.loginUserSuccess(fbUser);
			}
		}).catch(error => {
			if (error.code === 'auth/account-exists-with-different-credential') {
				var pendingCred = error.credential;
				var existingEmail = error.email;

				fire.auth.fetchSignInMethodsForEmail(existingEmail).then(methods => {
					if (methods[0] === 'password') {
						var password = window.prompt('Please Enter the Password for your Manager App Login.  This will only be needed once.') as string;

						fire.auth.signInWithEmailAndPassword(existingEmail, password).then(user => {
							const fbUser = user.user as firebase.User;
							return fbUser.linkWithCredential(pendingCred);
						}).then(async (user) => {
							const fbUser = user.user as firebase.User;
							const doesUserExist = await fire.doesUserExist(fbUser.uid);
							if (!doesUserExist) {
								this.setState({
									LoginErrorMessage: 'Resource not found. Please check login details and try again.',
								});
								this.handleLogout();
							} else {
								this.props.loginUserSuccess(fbUser);
							}
						});
						return;
					}

					// Other Providers will be handled here
				})
			}
		});
	}

	handleLogout() {
		fire.auth.signOut();
	}

	handleReturnKey = e => {
		if (e.key === 'Enter' && this.state.setPassword == false) {
			this.handleSubmit();
		}

		if (e.key === 'Enter') {
			this.setPassword();
		}
	};

	async handleRefresh() {
		if (navigator.onLine) {
			if (confirm('This will refresh the App and the LocalDB. Are you sure you wish to continue? ')) {

				await self.indexedDB.deleteDatabase('TrackplanContractorDB');

				const data = {
					VersionNumber: Api.VERSION,
					DbVersionNumber: Api.INDEXEDDB_VERSION,
					//UserUID: UserSettings.UserUID,
					deviceInfo: navigator.appVersion,
					triggeredBy: 'User Reload',
				};



				await axios({
					data: data,
					method: 'post',
					//url: getBaseURL(UserSettings.ServerName) + '/api/upgradealert/resourceapp',
					headers: ApiKeyObj,
				}).catch((err: AxiosError) => {
				});
				await window.location.reload();
			}
		}
	}

	handleEmailVerification() {
		let email = this.state.Email.toLowerCase();

		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const appName = urlParams.get('continueUrl');
		this.setState({ AppURL: appName });
		if (!isNullOrUndefined(email) && appName == IsDevelopment("Manager")) {
			console.log("Manager")
			fire.auth.signInWithEmailLink(email, window.location.href)
				.then(async result => {
					const newUser = await result.user as firebase.User;
					const clientUID = await fire.InitializeManagerClient(newUser.uid, email);
					this.setState({ setPassword: true, EmailLink: true, LoginErrorMessage: '', clientUID: clientUID });
					return;
				})
				.catch((error) => {
					this.setState({ LoginErrorMessage: 'The e-mail you have entered did not match. Please verify and try again.' });
					SendErrorData(
						email,
						"No UID Set Up",
						"",
						error,
						'EmailVerification Screen',
						'Login'
					);
				});
		}

		else if (!isNullOrUndefined(email) && appName == IsDevelopment("Resource")) {
			console.log("Resource")
			fire.auth.signInWithEmailLink(email, window.location.href)
				.then(async result => {
					const newUser = await result.user as firebase.User;
					const clientUID = await fire.InitializeResourceClient(newUser.uid, email);
					this.setState({ setPassword: true, EmailLink: true, LoginErrorMessage: '', clientUID: clientUID });
					return;
				})
				.catch((error) => {
					this.setState({ LoginErrorMessage: 'The e-mail you have entered did not match. Please verify and try again.' });
				})
		}

		else if (!isNullOrUndefined(email) && appName == IsDevelopment("Request")) {
			console.log("requestor")
			fire.auth.signInWithEmailLink(email, window.location.href)
				.then(async result => {
					console.log(result)
					const newUser = await result.user as firebase.User;
					const clientUID = await fire.InitializeRequestorClient(newUser.uid, email);
					this.setState({ setPassword: true, EmailLink: true, LoginErrorMessage: '', clientUID: clientUID });
					return;
				})
				.catch((error) => {
					console.log(error)
					this.setState({ LoginErrorMessage: 'The e-mail you have entered did not match. Please verify and try again.' });
				})
		}		
		else if (!isNullOrUndefined(email) && appName == IsDevelopment("MergeApp")) {
			fire.auth.signInWithEmailLink(email, window.location.href)
				.then(async result => {
					console.log(result)
					const newUser = await result.user as firebase.User;
					const clientUID = await fire.initializeMergeAppUser(newUser.uid, email);
					this.setState({ setPassword: true, EmailLink: true, LoginErrorMessage: '', clientUID: clientUID });
					return;
				})
				.catch((error) => {
					console.log(error)
					this.setState({ LoginErrorMessage: 'The e-mail you have entered did not match. Please verify and try again.' });
				})
		}

		else {
			this.setState({ LoginErrorMessage: 'The e-mail you have entered did not match. Please verify and try again.' });
		}
	};

	async setPassword() {
		if (this.state.NewPassword.length < 6) {
			this.setState({ LoginErrorMessage: 'Password must be six characters minimum.' });
			return;
		}

		var user = fire.auth.currentUser;
		var newPassword = this.state.NewPassword;
		var serverName = "";

		await fire.getServerName(this.state.clientUID).then(res => {
			const data = res.data();
			if (!isNullOrUndefined(data))
				serverName = data.ServerName;
		})

		if (!isNullOrUndefined(user) && this.state.AppURL == IsDevelopment("Manager")) {
			console.log("manager 2");
			user.updatePassword(newPassword).then(() => {
				if (!isNullOrUndefined(user)) {
					const JobQueueObject = {
						JobAction: 'UserActivatedEmail',
						Email: user.email,
						Password: newPassword,
						UID: user.uid,
						ServerName: serverName
					};
					fire.postToJobQueue(generateFirebaseId(), JobQueueObject);

					var url = document.location.href;
					window.history.pushState({}, "", url.split("?")[0]);
				}
			}).catch(error => { return });
		}

		if (!isNullOrUndefined(user) && this.state.AppURL == IsDevelopment("Resource")) {
			console.log("ressource 2");
			user.updatePassword(newPassword).then(async () => {
				if (!isNullOrUndefined(user)) {
					const JobQueueObject = {
						JobAction: 'ResourceActivatedEmail',
						Email: user.email,
						Password: newPassword,
						UID: user.uid,
						ServerName: serverName
					};
					await fire.postToJobQueueResource(generateFirebaseId(), JobQueueObject).then(res => {
						window.location.replace(IsDevelopment("Resource"));
					});
				}

			}).catch(error => {
				console.log(error);
				return
			});
		}

		if (!isNullOrUndefined(user) && this.state.AppURL == IsDevelopment("Request")) {
			console.log("requestor 2");
			await user.updatePassword(newPassword).then(async () => {
				if (!isNullOrUndefined(user)) {
					const JobQueueObject = {
						JobAction: 'RequestorActivatedEmail',
						Email: user.email,
						Password: newPassword,
						UID: user.uid,
						ServerName: serverName
					};
					console.log(JobQueueObject);
					await fire.postToJobQueueRequest(generateFirebaseId(), JobQueueObject).then(res => {
						console.log(res)
						window.location.replace(IsDevelopment("Request") + "/login/redirected");
					});
				}
			}).catch(error => {
				console.log(error);
				return
			});
		}

		if (!isNullOrUndefined(user) && this.state.AppURL == IsDevelopment("MergeApp")) {
			await user.updatePassword(newPassword).then(async () => {
				if (!isNullOrUndefined(user)) {
					const JobQueueObject = {
						JobAction: 'MobileUserActivatedByEmail',
						Email: user.email,
						Password: newPassword,
						UID: user.uid,
						ServerName: serverName
					};
					await fire.postToJobQueue(generateFirebaseId(), JobQueueObject, undefined, true).then(res => {
						window.location.replace(IsDevelopment("MergeApp"));
					});
				}
			}).catch(error => {
				console.log(error);
				return
			});
		}

		this.setState({ setPassword: false });
	}

	render() {
		console.log(this.state.forgotPasswordURL);
		if (this.state.displayCaptcha) {
			return (
				<div className="outer-container">
					<div className="inputContainer">
						<img src={this.state.loginImage} alt="Logo" width="300" id="logo" />
						<h1 className="title-text">Email Verification</h1>
						<Button
							disabled={this.state.offline}
							variant="contained"
							id="loginBtn"
							className="login-buttons"
							onClick={() => this.handleCaptcha()}
						>
							Click Here to Verify Email
						</Button>

					</div>
				</div>
			)
		}
		if (this.state.resetPassword) {
			return (
				<div className="outer-container">
					<div className="inputContainer">
						<img src={this.state.loginImage} alt="Logo" width="300" id="logo" />
						<h1 className="title-text">{this.state.forgotPasswordURL == "https://resource.trackplanfm.com/" ?
							"Resource App Setup" : this.state.forgotPasswordURL == "https://manager.trackplanfm.com/" ?
								"Manager App Setup" : "Request App Setup"}</h1>

						<TextField
							autoComplete={IsChrome()}
							id="password"
							label="Email"
							className="text-inputs"
							value={this.state.Email}
							margin="normal"
							type="email"
							disabled={true}
						/>
						<TextField
							autoComplete={IsChrome()}
							id="password"
							label="Password"
							className="text-inputs"
							value={this.state.NewPassword}
							onChange={evt => this.setState({ NewPassword: evt.target.value })}
							margin="normal"
							type="password"
							disabled={this.state.offline}
						/>

						<p style={{ color: 'red' }}>{this.state.LoginErrorMessage}</p>
						<Button
							disabled={this.state.offline}
							variant="contained"
							id="loginBtn"
							className="login-buttons"
							onClick={() => this.submitNewPassword()}
						>
							Set Password
						</Button>

					</div>
				</div>
			)
		}
		if (this.state.displayEmailVerification && this.state.EmailLink == false) {
			return (
				<div className="outer-container">
					<div className="inputContainer">
						<img src={this.state.loginImage} alt="Logo" width="300" id="logo" />
						<h1 className="title-text">Please Verify your Email Address.</h1>
						<TextField
							autoComplete={IsChrome()}
							id="email"
							label="Email"
							className="text-inputs"
							value={this.state.Email}
							onChange={evt => this.setState({ Email: evt.target.value })}
							margin="normal"
							disabled={this.state.offline}
						/>
						<p style={{ color: 'red' }}>{this.state.LoginErrorMessage}</p>
						<Button
							disabled={this.state.offline}
							variant="contained"
							id="loginBtn"
							className="login-buttons"
							onClick={() => this.handleEmailVerification()}
						>
							Verify Email
						</Button>
					</div>
				</div>
			)
		}

		if (this.state.setPassword) {
			return (
				<div className="outer-container">
					<div className="inputContainer">
						<img src={this.state.loginImage} alt="Logo" width="300" id="logo" />
						<h1 className="title-text">Please set a password.</h1>
						<TextField
							autoComplete={IsChrome()}
							id="password"
							label="Password"
							className="text-inputs"
							value={this.state.NewPassword}
							onChange={evt => this.setState({ NewPassword: evt.target.value })}
							margin="normal"
							type="password"
							disabled={this.state.offline}
							onKeyDown={e => this.handleReturnKey(e)}
						/>

						<p style={{ color: 'red' }}>{this.state.LoginErrorMessage}</p>
						<Button
							disabled={this.state.offline}
							variant="contained"
							id="loginBtn"
							className="login-buttons"
							onClick={() => this.setPassword()}
						>
							Set Password
						</Button>
					</div>
				</div>
			)
		}

		if (this.state.ForgotPassword) {
			return (
				<div className="outer-container">
					<div className="inputContainer">
						<img src={this.state.loginImage} alt="Logo" width="300" id="logo" />
						<h3 className="title-text">Please enter your email address.</h3>
						<TextField
							autoComplete={IsChrome()}
							id="email"
							label="Email"
							className="text-inputs"
							value={this.state.Email}
							onChange={evt => this.setState({ Email: evt.target.value })}
							margin="normal"
							type="email"
							disabled={this.state.offline}
						/>

						<p style={{ color: 'red' }}>{this.state.LoginErrorMessage}</p>
						<p style={{ color: 'green' }}>{this.state.LoginSuccessMessage}</p>
						<Button
							disabled={this.state.offline}
							variant="contained"
							id="loginBtn"
							className="login-buttons"
							onClick={() => this.sendForgotPasswordLink()}
						>
							Send Email Link
						</Button>
						<Button color="primary" className="login-buttons"
							onClick={() => this.setState({ ForgotPassword: false, LoginSuccessMessage: '', LoginErrorMessage: '' })} >
							Back
						</Button>
					</div>
				</div>
			)
		}

		if (this.props.User !== null && this.props.ClientID !== 0 && this.props.UserSettings !== null) {
			if (process.env.NODE_ENV === 'production') {
				Sentry.setUser({
					id: this.props.User.uid,
					email: this.props.User.email || '',
				});
			}

			/*
			return <Redirect to= {landingPage} />;*/
			let landingPage = LandingPage(this.props.UserSettings.AppUserLandingScreen);
			return (<LoginForm
				loginImage={this.state.loginImage}
				stateEmail={this.state.Email}
				statePassword={this.state.Password}
				stateOffline={this.state.offline}
				setStateHandler={this.setStateHandler}
				handleReturnKey={this.handleReturnKey}
				handleSubmit={this.handleSubmit}
				loginMicrosoft={this.loginMicrosoft}
				loginErrorMessage={this.state.LoginErrorMessage}
				loginSuccessMessage={this.state.LoginSuccessMessage}
				landingPage={landingPage}
			/>);
		}
		if (!this.props.Loading) {
			return (
				<div className="outer-container">
					<div className="inputContainer">
						<img src={this.state.loginImage} alt="Logo" width="300" id="logo" />
						<h1 className="title-text">Manager App</h1>
						<TextField
							id="email"
							label="Email"
							className="text-inputs"
							value={this.state.Email}
							onChange={evt => this.setState({ Email: evt.target.value })}
							margin="normal"
							disabled={this.state.offline}
						/>
						<TextField
							id="password"
							label="Password"
							className="text-inputs"
							value={this.state.Password}
							onChange={evt => this.setState({ Password: evt.target.value })}
							margin="normal"
							type="password"
							disabled={this.state.offline}
							onKeyDown={e => this.handleReturnKey(e)}
						/>
						<p style={{ color: 'red' }}>{this.state.LoginErrorMessage}</p>
						<p style={{ color: 'green' }}>{this.state.LoginSuccessMessage}</p>
						<Button
							disabled={this.state.offline}
							variant="contained"
							id="loginBtn"
							className="login-buttons"
							onClick={() => this.handleSubmit()}
						>
							Login
						</Button>

						<span style={{ margin: '15px 0', textAlign: 'center' }}><strong>OR</strong></span>

						<Button
							disabled={this.state.offline}
							variant="contained"
							id="msLoginBtn"
							onClick={() => this.loginMicrosoft()}
						>
							Login With Microsoft
						</Button>

						<Button color="secondary" className="login-buttons"
							disabled={this.state.offline}
							onClick={() => this.setState({ ForgotPassword: true })} >
							Forgotten Password
						</Button>


						{this.state.offline ? (
							<p style={{ marginTop: '10px', textAlign: 'center', color: 'red' }}>
								Cannot login while offline. Please try again with an internet connection.
							</p>
						) : (
							<IOSAddToHomeScreenBubble messageText="To install this Web App in your iPhone/iPad press the button below and then Add to Home Screen." />
						)}
					</div>
				</div>
			);
		} else {
			return <LoadingSpinner text="Checking your details." loadscreenButton={true} buttonFunction={this.handleRefresh} />;
		}
	}
}

const mapStateToProps = (state: Store.Store) => ({
	UID: state.User.UID,
	IsLoggedIn: state.User.IsLoggedIn,
	Loading: state.User.Loading,
	Error: state.User.Error,
	User: state.User.User,
	ClientID: state.User.ClientID,
	UserSettings: state.User.UserSettings,
});

const mapDispatchToProps = dispatch => ({
	loginUser: () => dispatch(loginUser()),
	loginUserSuccess: user => dispatch(loginUserSuccess(user)),
	loginUserError: error => dispatch(loginUserError(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
