import * as React from 'react';
import { Provider } from 'react-redux';
import { Redirect, Route } from 'react-router';
import { HashRouter as Router } from 'react-router-dom';

// Local imports
import store from './store.js';

// Routes
import LoginScreen from './views/LoginScreen';
import JobListScreen from './views/Job/JobListScreen';
import JobDetailsScreen from './views/Job/JobDetailsScreen';
import NewJobScreen from './views/NewJobScreen';
import TakePhotoScreen from './views/Photos/TakePhotoScreen';
import ResourceListScreen from './views/Resources/ResourceList';
import AssignedUserListScreen from './views/AssignedUsers/AssignedUserList';
import FormDetailsScreen from './views/Forms/FormDetailsScreen';
import FormLibraryListScreen from './views/FormLibrary/FormLibraryListScreen';
import FormLibraryDetailsScreen from './views/FormLibrary/FormLibraryDetailsScreen';
import AssetListScreen from './views/Assets/AssetList';
import AssetCreateScreen from './views/Assets/AssetCreateScreen';
import AssetDetailsModal from './views/Assets/AssetDetailsModal';
import AssetDetails from './views/Assets/AssetDetails';
import SitesList from './views/Sites/SitesList';
import SiteDetails from './views/Sites/SiteDetails';
import LocationsList from './views/Locations/LocationsList';
import LocationDetails from './views/Locations/LocationDetails';
import SubLocationsList from './views/Locations/SublocationsList';
import SubLocationDetails from './views/Locations/SublocationDetails';
import CreatedJobScreen from './views/CreatedJobScreen';
import SettingsScreen from './views/Settings/SettingsScreen';
import PasswordReset from './views/Settings/PasswordReset';
import NotificationsList from './views/Notifications/NotificationsList';
// import ResourceDetailsScreen from './views/Resources/ResourceDetails';
import StorageAreasList from "./views/StorageAreas/StorageAreasList";
import StorageAreaDetails from "./views/StorageAreas/StorageAreaDetails";
import RequestsList from './views/Requests/RequestsList';
import RequestDetails from './views/Requests/RequestDetails';
import JobsCalendar from './views/Calendar/JobsCalendar';

const PrivateRoute = ({ component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={props => {
				return store.getState().User.User !== null ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: '/',
							state: { from: props.location },
						}}
					/>
				);
			}}
		/>
	);
};

const App = () => {
	return (
		<Provider store={store}>
			<Router>
				<div>
					<Route path="/" exact={true} component={LoginScreen} />
					{/* Translated */}
					<PrivateRoute path="/job-list" component={JobListScreen} />
					{/* Translated */}
					<PrivateRoute path="/Job-details/:id/:fromassetfbid?" component={JobDetailsScreen} />
					{/* Translated */}
					<PrivateRoute path="/new-job" component={NewJobScreen} />
					{/* Translated */}
					<PrivateRoute path="/new-job-form-fail/:formid" component={NewJobScreen} />
					{/* Translated */}
					<PrivateRoute path="/jobsuccessful/:id" component={CreatedJobScreen} />
					{/* Translated (Circle back on EditPhoto component? For Aria-Label) */}
					<PrivateRoute path="/take-photo" component={TakePhotoScreen} />

					{/* Translated */}
					<PrivateRoute path="/resource-list/:id" component={ResourceListScreen} />
					{/* Translated */}
					<PrivateRoute path="/assigneduser-list/:id" component={AssignedUserListScreen} />

					{/* Translated */}
					<PrivateRoute path="/form-details/:jobid/:formid" component={FormDetailsScreen} />
					{/* Translated */}
					<PrivateRoute path="/form-library" component={FormLibraryListScreen} />
					{/* Translated */}
					<PrivateRoute path="/form-library-details/:formtemplateid/:formid" component={FormLibraryDetailsScreen} />

					{/* Translated */}
					<PrivateRoute path="/settings" component={SettingsScreen} />
					{/* Translated */}
					<PrivateRoute path="/passwordreset" component={PasswordReset} />
					{/* Translated */}
					<PrivateRoute path="/notifications" component={NotificationsList} />

					{/* Translated */}
					<PrivateRoute path="/asset-list/:id" component={AssetListScreen} />
					{/* Translated */}
					<PrivateRoute path="/asset-details/:id" component={AssetDetailsModal} />
					{/* Translated */}
					<PrivateRoute path="/asset-nmdetails/:id/:fromjobfbid?" component={AssetDetails} />
					{/* Translated */}
					<PrivateRoute path="/new-asset" component={AssetCreateScreen} />
					{/* Translated */}
					<PrivateRoute path="/new-request-from-Asset/:AssetID" component={NewJobScreen} />

					{/* Translated */}
					<PrivateRoute path="/sites-list" component={SitesList} />
					{/* Translated */}
					<PrivateRoute path="/site-details/:id" component={SiteDetails} />
					{/* Translated */}
					<PrivateRoute path="/new-request-from-Site/:SiteID" component={NewJobScreen} />
					{/* Translated */}
					<PrivateRoute path="/site-assets" component={AssetListScreen} />
					{/* Translated */}
					<PrivateRoute path="/locations-list/:id" component={LocationsList} />
					{/* Translated */}
					<PrivateRoute path="/location-details/:id" component={LocationDetails} />
					{/* Translated */}
					<PrivateRoute path="/new-request-from-Location/:LocationID" component={NewJobScreen} />
					{/* Translated */}
					<PrivateRoute path="/sublocations-list/:id" component={SubLocationsList} />
					{/* Translated */}
					<PrivateRoute path="/sublocation-details/:id" component={SubLocationDetails} />
					{/* Translated */}
					<PrivateRoute path="/new-request-from-SubLocation/:SubLocationID" component={NewJobScreen} />

					{/* Translated */}
					<PrivateRoute path="/associated-jobs" component={JobListScreen} />
					{/* Translated */}
					<PrivateRoute path="/associated-assets" component={AssetListScreen} />

					{/* Translated */}
					<PrivateRoute path="/storage-areas-list" component={StorageAreasList} />
					{/* Translated */}
					<PrivateRoute path="/storage-area-details/:id" component={StorageAreaDetails} />
					{/* Translated */}
					<PrivateRoute path="/requests-list" component={RequestsList} />
					<PrivateRoute path="/request-details/:id" component={RequestDetails} />
					{/* Translated */}
					<PrivateRoute path="/new-job-from-Request/:RequestID" component={NewJobScreen} />
					<PrivateRoute path="/jobs-calendar" component={JobsCalendar} />
				</div>
			</Router>
		</Provider>
	);
};

export default App;
