/* eslint-disable prettier/prettier */
// Firebase
import Firebase from '../../Firebase';

// IndexedDB
import IndexDb from './IndexDb';

// Trackplan Manager DB
import TrackplanDatabase from './TrackplanDb';

// dexie import for IndexedDB
import Dexie from 'dexie';

// Local Queue Actions
import {
	AddPhotoExistingJob,
	CreateJob,
	AddFormDocumentAnswer,
	AddFormPhotoAnswer,
	AddDocumentExistingJob,
	AddDocument,
	AddPhoto,
} from './LocalQueueActions';

// Utils
import { installOfflineWatcher } from '../../Network';
import { generateGuid } from '../../Guids';

export default class LocalQueue {
	fire: Firebase;
	indexDb: IndexDb;
	db: TrackplanDatabase;

	constructor(firebase: Firebase, indexDb: IndexDb) {
		this.fire = firebase;
		this.indexDb = indexDb;
		this.db = this.indexDb.db;

		//creates a listener that watches queue table and calls handleNewEntryToLocalQueue when fired
		this.queueListener();

		//watches when back online and gets items in queue
		installOfflineWatcher(offline => {
			if (!offline) {
				this.getDocumentsInLocalQueue();
			}
		});
	}

	queueListener() {
		this.db.localQueue.hook('creating', (key: string, object: indexDb.LocalQueue, transaction: Dexie.Transaction) =>
			this.handleNewEntryToLocalQueue(object),
		);
	}

	// gets all the objects in the queue and sends to handle method which process records, this method is called on init of app
	getDocumentsInLocalQueue() {
		this.db.localQueue.toArray().then(async (records) => {
			for (const localQueueObj of records) {
				if (!navigator.onLine) break;
				await this.handleNewEntryToLocalQueue(localQueueObj);
			}
		});
	}

	//this will watch the local queue and fire when a record has been added
	async handleNewEntryToLocalQueue(object: indexDb.LocalQueue) {
		//if offline or no one is logged in, exit
		if (!navigator.onLine || this.fire.currentUser === null) {
			return;
		}

		const docToUpdateObject = object.docToUpdateObject !== undefined ? JSON.parse(object.docToUpdateObject) : undefined;
		const jobQueueObject = JSON.parse(object.jobQueueObject);
		const jobQueueAction = jobQueueObject['JobAction'];

		switch (jobQueueAction) {
			case 'AddPhotoExistingJob':
				return await AddPhotoExistingJob(
					object,
					this.getPhoto,
					this.removeItemFromQueueByItemId,
					jobQueueObject,
					this.fire
				);

			case 'AddDocumentExistingJob':
				const documentGuid = jobQueueObject['DocumentGuid'];
				const documentNewFBID = jobQueueObject['DocumentNewFBID'];
				return AddDocumentExistingJob(
					object,
					this.getDocument,
					this.removeItemFromQueue,
					documentGuid,
					documentNewFBID,
					this.fire,
				);

			case 'AddDocument':
				const isPhoto = jobQueueObject['IsPhoto'];
				const associatedFBID = jobQueueObject['AssociatedFBID'];
				const type = jobQueueObject['Type'];

				if (isPhoto) {
					return await AddPhoto(
						object,
						this.getPhoto,
						this.removeItemFromQueueByItemId,
						jobQueueObject,
						this.fire,
						type,
						associatedFBID
					);
				}
				const documentID = jobQueueObject['DocumentGuid'];
				const docNewFBID = jobQueueObject['DocumentNewFBID'];

				console.log(associatedFBID);
				return AddDocument(
					object,
					this.getDocument,
					this.removeItemFromQueue,
					documentID,
					docNewFBID,
					this.fire,
					associatedFBID,
					type
				);

			case 'NewJob':
				return CreateJob(
					jobQueueObject,
					object,
					this.getCreateJobPhotos,
					this.removeItemFromQueue,
					this.fire,
					this.indexDb,
					this.RemoveJobPhotos,
				);

			case 'FormAnswer':
				if (
					jobQueueObject.QuestionType === 'Photo' ||
					jobQueueObject.QuestionType === 'Pass/Fail' ||
					jobQueueObject.QuestionType === 'Yes/No' ||
					jobQueueObject.QuestionType === 'Signature'
				) {
					return await AddFormPhotoAnswer(
						object,
						docToUpdateObject,
						this.removeItemFromQueueByItemId,
						this.fire
					);
				} else {
					return AddFormDocumentAnswer(
						object,
						docToUpdateObject,
						this.removeItemFromQueue
					);
				}

			default:
				break;
		}
	}

	async saveToLocalJobQueue(
		documentId: string,
		jobQueueObject: object,
		docToUpdateObject?: object,
		docToUpdateRef?: string,
		guid?: string,
		arrayBuffer?: ArrayBuffer,
		fileName?: string,
		PhotoFBID?: string,
	) {
		//Converts objects to strings as db will not accept objects as is
		const docObjectSerialized = JSON.stringify(docToUpdateObject);
		const jobObjectSerialized = JSON.stringify(jobQueueObject);

		//Writes transaction to the db
		this.db
			.transaction('rw!', this.db.localQueue, () => {
				const newQueueObject: indexDb.LocalQueue = {
					id: guid === undefined ? generateGuid() : guid,
					documentId,
					jobQueueObject: jobObjectSerialized,
					docToUpdateObject: docObjectSerialized,
					docToUpdateRef,
					clientID: this.fire.ClientID as string,
					userUID: this.fire.currentUser.uid,
					arrayBuffer,
					fileName,
					photoId: PhotoFBID,
				};

				this.db.localQueue.add(newQueueObject).catch(err => console.error(err));
			})
			.catch(err => console.error(`Error when adding object to localQueue - error : ${err}`));
	}

	// remove job item from local queue
	removeItemFromQueue = (firebaseId: string) => {
		return this.db.transaction('rw!', this.db.localQueue, async () => {
			await this.db.localQueue
				.where('documentId')
				.equals(firebaseId)
				.delete();
		});
	};

	removeItemFromQueueByItemId = (itemId: string) => {
		return this.db.transaction('rw!', this.db.localQueue, async () => {
			await this.db.localQueue
				.where('id')
				.equals(itemId)
				.delete();
		});
	};

	// get photo from db
	getPhoto = (guid: string): Dexie.Promise<indexDb.Photos | undefined> => {
		return this.db.transaction('r!', this.db.jobphotos, async () => {
			return await this.db.jobphotos
				.where('guid')
				.equals(guid)
				.first();
		});
	};

	getDocument = (guid: string): Dexie.Promise<indexDb.Documents | undefined> => {
		return this.db.transaction('r!', this.db.documents, async () => {
			return await this.db.documents
				.where('guid')
				.equals(guid)
				.first();
		});
	};

	// get Job photo from db
	getJobPhotos = (documentId: string): Dexie.Promise<indexDb.Photos[]> => {
		return this.db.transaction('r!', this.db.jobphotos, async () => {
			return await this.db.jobphotos
				.where('documentId')
				.equals(documentId)
				.toArray();
		});
	};

	// get photo from db
	getCreateJobPhotos = (guid: string): Dexie.Promise<indexDb.CreateJobPhotos[]> => {
		return this.db.transaction('r!', this.db.createjobPhotos, async () => {
			return await this.db.createjobPhotos
				.where('guid')
				.equals(guid)
				.toArray();
		});
	};

	RemoveJobPhotos = (documentId: string) => {
		return this.db.transaction('rw!', this.db.jobphotos, async () => {
			await this.db.jobphotos
				.where('documentId')
				.equals(documentId)
				.delete();
		});
	};

	getDocumentByDocId = (documentId: string): Dexie.Promise<indexDb.Documents | undefined> => {
		return this.db.transaction('r!', this.db.documents, async () => {
			return await this.db.documents
				.where('documentId')
				.equals(documentId)
				.first();
		});
	};
}

// 	// Saves event to local queue, this event will be processed by another function that watches queue
// 	async saveToLocalJobQueue(
// 		documentId: string,
// 		jobTaskQueueObject: object,
// 		docToUpdateObject?: object,
// 		docToUpdateRef?: string,
// 		guid?: string,
// 	) {
// 		//Converts objects to strings as db will not accept objects as is
// 		const docObjectSerialized = JSON.stringify(docToUpdateObject);
// 		const jobTaskObjectSerialized = JSON.stringify(jobTaskQueueObject);

// 		//Writes transaction to the db
// 		this.db
// 			.transaction('rw!', this.db.localQueue, () => {
// 				const newQueueObject: indexDb.LocalQueue = {
// 					id: guid === undefined ? generateGuid() : guid,
// 					documentId,
// 					jobTaskQueueObject: jobTaskObjectSerialized,
// 					docToUpdateObject: docObjectSerialized,
// 					docToUpdateRef,
// 					clientID: this.fire.ClientID as string,
// 					userUID: this.fire.currentUser.uid,
// 				};

// 				this.db.localQueue.add(newQueueObject).catch(err => console.error(err));
// 			})
// 			.catch(err => console.error(`Error when adding object to localJobQueue - error : ${err}`));
// 	}

// 	// gets all the objects in the queue and sends to handle method which process records, this method is called on init of app
// 	getDocumentsInLocalQueue() {
// 		this.db.localQueue.toArray().then(records => {
// 			records.forEach(localQueueObj => {
// 				this.handleNewEntryToLocalQueue(localQueueObj);
// 			});
// 		});
// 	}

// 	//this will watch the local queue and fire when a record has been added
// 	handleNewEntryToLocalQueue(object: indexDb.LocalQueue) {
// 		//if offline or no one is logged in, exit
// 		if (!navigator.onLine || this.fire.currentUser === null) return;

// 		//parses the strings back to objects
// 		const docToUpdateObject = object.docToUpdateObject !== undefined ? JSON.parse(object.docToUpdateObject) : undefined;
// 		const jobTaskQueueObject = JSON.parse(object.jobTaskQueueObject);

// 		//gets the job queue action
// 		const jobQueueAction = jobTaskQueueObject['JobAction'];

// 		switch (jobQueueAction) {
// 			case 'TaskLeaveSite':
// 				return TaskLeaveSite(
// 					jobTaskQueueObject,
// 					docToUpdateObject,
// 					object,
// 					this.removeItemFromQueue,
// 					this.deletePhoto,
// 					this.getPhoto,
// 					this.fire,
// 				);

// 			case 'NewJobRequest':
// 				return NewJobRequest(
// 					jobTaskQueueObject,
// 					object,
// 					this.removeItemFromQueue,
// 					this.removeJobRequestPhotos,
// 					this.getJobRequestPhotos,
// 					this.fire,
// 				);

// 			case 'AddPhotoExistingJobTask':
// 				const photoGuid = jobTaskQueueObject['PhotoGuid'];
// 				return AddPhotoExistingJobTask(object, this.getPhoto, this.removeItemFromQueue, photoGuid, this.fire);

// 			case 'AddDocumentExistingJobTask':
// 				const documentGuid = jobTaskQueueObject['DocumentGuid'];
// 				return AddDocumentExistingJobTask(object, this.getDocument, this.removeItemFromQueue, documentGuid, this.fire);

// 			case 'AddExpense':
// 				return AddExpense(object, this.getDocumentsForExpense, this.removeItemFromQueue, this.fire);

// 			case 'AddDocumentExistingExpense':
// 				return AddDocumentToExpense(object, this.getDocument, this.removeItemFromQueue, this.fire);

// 			default:
// 				break;
// 		}
// 	}

// 	// remove job item from local queue
// 	removeItemFromQueue = (firebaseId: string) => {
// 		return this.db.transaction('rw!', this.db.localQueue, async () => {
// 			await this.db.localQueue
// 				.where('documentId')
// 				.equals(firebaseId)
// 				.delete();
// 		});
// 	};

// 	// remove job request photos from local table
// 	removeJobRequestPhotos = (guid: string) => {
// 		return this.db.transaction('rw!', this.db.requestPhotos, async () => {
// 			await this.db.requestPhotos
// 				.where('guid')
// 				.equals(guid)
// 				.delete();
// 		});
// 	};

// 	deletePhoto = (id: string) => {
// 		return this.db.transaction('rw!', this.db.photos, async () => {
// 			await this.db.photos
// 				.where('id')
// 				.equals(id)
// 				.delete();
// 		});
// 	};

// 	// get photo from db
// 	getPhoto = (guid: string): Dexie.Promise<indexDb.Photos | undefined> => {
// 		return this.db.transaction('r!', this.db.photos, async () => {
// 			return await this.db.photos
// 				.where('guid')
// 				.equals(guid)
// 				.first();
// 		});
// 	};

// 	getJobRequestPhotos = (guid: string): Dexie.Promise<indexDb.RequestPhotos[]> => {
// 		return this.db.transaction('r!', this.db.requestPhotos, async () => {
// 			return await this.db.requestPhotos
// 				.where('guid')
// 				.equals(guid)
// 				.toArray();
// 		});
// 	};

// 	// get photo from db
// 	getPhotos = (guid: string): Dexie.Promise<indexDb.Photos[]> => {
// 		return this.db.transaction('r!', this.db.photos, async () => {
// 			return await this.db.photos
// 				.where('guid')
// 				.equals(guid)
// 				.toArray();
// 		});
// 	};

// 	// get documents from db
// 	getDocument = (guid: string): Dexie.Promise<indexDb.Documents | undefined> => {
// 		return this.db.transaction('r!', this.db.documents, async () => {
// 			return await this.db.documents
// 				.where('guid')
// 				.equals(guid)
// 				.first();
// 		});
// 	};

// 	// get documents from db for expense
// 	getDocumentsForExpense = (documentId: string): Dexie.Promise<indexDb.Documents[] | undefined> => {
// 		return this.db.transaction('r!', this.db.documents, async () => {
// 			return await this.db.documents
// 				.where('documentId')
// 				.equals(documentId)
// 				.toArray();
// 		});
// 	};

// 	// get documents from db for expense
// 	removeDocuments = (documentId: string): Dexie.Promise<number> => {
// 		return this.db.transaction('rw!', this.db.documents, async () => {
// 			return await this.db.documents
// 				.where('documentId')
// 				.equals(documentId)
// 				.delete();
// 		});
// 	};
//
