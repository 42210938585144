// React
import * as React from 'react';
import { History } from 'history';

// Firebase + IndexedDB
import { fire, idb } from '../../index';

import QuestionLayout from './QuestionLayout';

// Material UI
import { Button, Icon } from '@material-ui/core';

// Components
import DateAnswer from '../form-answers/DateAnswer';
import { parseDate, stringtoUnixUTC } from '../../utils/Times';
import TextBoxAnswer from '../form-answers/TextBoxAnswer';
import PhotoAnswer from '../form-answers/PhotoAnswer';
import InlineLoadingSpinner from '../Spinners/InlineLoadingSpinner';

// Utils
import { fileChangeListener } from '../../utils/forms/FormQuestionHelpers';
import { removePhoto } from '../../utils/forms/FormQuestionHelpers';
import { generateGuid } from '../../utils/Guids';
import imgNotAvailable from '../../assets/images/not-available.jpg';
import { isNullOrUndefined } from 'util';
import { useTranslation, withTranslation } from 'react-i18next';

interface YesNoQuestionProps {
	data: Forms.QuestionAnswer;
	formComplete: boolean;
	formId: string;
	jobId: string;
	history: History;
	formName: string;
	questionId: string;
	canCreateRequest: boolean;
	formTemplateFBID: string;
	t: any;
}

interface YesNoQuestionState {
	booleanAnswer: boolean | null;
	photoAnswer: Forms.Document | null;
	dateTimeAnswer: string | null;
	textAnswer: string | null;
	uploadingFile: boolean;
	deletingFile: boolean;
	photosArray: Job.JobPhoto[];
	size: number;
	loading: boolean;
	firstLoad: boolean;
	lastGuidEntered: string;
	showRaiseButton: boolean;
	expand: boolean;
}

class YesNoQuestion extends React.PureComponent<YesNoQuestionProps, YesNoQuestionState> {
	inputRef: React.RefObject<HTMLInputElement>;
	constructor(props: Readonly<YesNoQuestionProps>) {
		super(props);
		this.inputRef = React.createRef();
		this.state = {
			booleanAnswer: this.answerState(this.props.data.Answer),
			dateTimeAnswer: parseDate('DateTime', this.props.data.PassFailDateTimeStamp),
			photoAnswer: this.props.data.DocumentAnswer,
			textAnswer: this.props.data.PassFailTextBox,
			uploadingFile: false,
			deletingFile: false,
			photosArray: [],
			size: 0,
			loading: true,
			firstLoad: true,
			lastGuidEntered: '',
			showRaiseButton: false,
			expand: false
		};
	}

	async componentDidMount() {
		if (this.props.data.IncludePhoto && !isNullOrUndefined(this.props.data.Id)) {
			await idb.getPhotos(this.props.data.Id).then(photos => {
				if (!isNullOrUndefined(photos)) {
					photos.forEach(photo => {
						if (photo.file.byteLength === new ArrayBuffer(0).byteLength) {
							idb.removePhotoByGuid(photo.guid);
						}
					});
				}
			});

			const isComplete = this.props.formComplete.toString();
			// this.watchPhotos();
			// this.watchDeletePhotos();

			fire.getQuestionAnswerPhotoCollection(this.props.data.questionRef.path).onSnapshot(async docs => {
				let count = 1;
				if (docs.size > 0) {
					if (this.state.firstLoad) await this.setState({ size: docs.size });
					for (const doc of docs.docChanges()) {
						const change = doc;
						const Photo = doc.doc.data() as Job.JobPhoto;
						if (change.type === 'added') {
							const Exists = await idb.photoExistsByGuid2(Photo.Guid as string);
							if (!Exists && this.state.lastGuidEntered !== Photo.Guid) {
								await this.setState({ lastGuidEntered: Photo.Guid as string });
								await idb.downloadQuestionAnswerPhotoFromFirebase(
									!isNullOrUndefined(Photo.FirebaseStoragePath)
										? Photo.FirebaseStoragePath
										: (Photo.AzureUrl as string),
									this.props.data.Id,
									!isNullOrUndefined(Photo.Guid) ? (Photo.Guid as string) : generateGuid(),
									change.doc.id,
									Photo.Filename as string,
									isComplete,
									this.props.formId,
								);

								if (!this.state.firstLoad) {
									await this.setState({ size: this.state.size + 1 });
								}
							}

							if (!this.state.firstLoad) {
								this.getPhotos(this.props.data.Id);
							}
						} else if (change.type === 'removed') {
							await idb.removePhotobyGuid(Photo.Guid as string);

							if (!this.state.firstLoad) {
								await this.setState({ size: this.state.size - 1 });
								this.getPhotos(this.props.data.Id);
							}
						}

						if (count >= this.state.size && this.state.firstLoad) {
							this.getPhotos(this.props.data.Id);
						} else {
							count = count + 1;
						}
					}
				} else if (!this.state.firstLoad) {
					for (const doc of docs.docChanges()) {
						const change = doc;
						const Photo = doc.doc.data() as Job.JobPhoto;
						if (change.type === 'removed') {
							await idb.removePhotobyGuid(Photo.Guid as string);

							if (!this.state.firstLoad) {
								await this.setState({ size: this.state.size - 1 });
								this.getPhotos(this.props.data.Id);
							}
						}
					}
				} else {
					this.setState({ loading: false });
					this.props.data.questionRef.update({ HasPhotosAttached: false });
				}

				this.setState({ firstLoad: false });
			});
		}
	}

	private handleExpand = (value) => {
		this.setState({ expand: value })
	}

	getPhotos(documentId: string) {
		idb
			.getPhotos(documentId)
			.then(array => {
				if (array.length > 0 && array.length >= this.state.size) {
					const photosArray = array.map(photoObj => {
						if (photoObj.file.byteLength !== new ArrayBuffer(0).byteLength) {
							const blob = new Blob([photoObj.file]);
							const blobUrl = URL.createObjectURL(blob);
							return {
								Guid: photoObj.guid,
								FirebaseStoragePath: blobUrl,
								fileName: photoObj.fileName,
							};
						} else {
							return { Guid: photoObj.guid, FirebaseStoragePath: imgNotAvailable, fileName: photoObj.fileName };
						}
					});
					this.setState({ photosArray, loading: false });
				} else if (this.state.size > 0) {
					this.setState({ photosArray: [] });
				} else {
					this.setState({ photosArray: [], loading: false });
				}
			})
			.catch(err => console.error(err));
	}

	UNSAFE_componentWillReceiveProps(newProps: YesNoQuestionProps) {
		if (newProps.data.Answer !== this.props.data.Answer) {
			this.setState({
				booleanAnswer: this.answerState(newProps.data.Answer),
			});
		}

		if (newProps.data.PassFailDateTimeStamp !== this.props.data.PassFailDateTimeStamp) {
			this.setState({
				dateTimeAnswer: parseDate('DateTime', newProps.data.PassFailDateTimeStamp),
			});
		}

		if (newProps.data.PassFailTextBox !== this.props.data.PassFailTextBox) {
			this.setState({
				textAnswer: newProps.data.PassFailTextBox,
			});
		}
		this.showRaiseJobFromFailButton();
	}

	showRaiseJobFromFailButton() {
		if (!isNullOrUndefined(this.props.data.RecordFailType) && isNullOrUndefined(this.props.data.FailJobNumber)) {
			const recordfailanswer = this.state.booleanAnswer === true ? 'PASS' : 'FAIL';
			if (this.state.booleanAnswer === null) {
				this.setState({ showRaiseButton: false });
				return;
			} else if (
				this.props.data.RecordFailType !== null &&
				recordfailanswer !== null &&
				this.props.data.RecordFailType.toUpperCase() === recordfailanswer.toUpperCase() &&
				isNullOrUndefined(this.props.data.FailJobNumber)
			) {
				this.setState({ showRaiseButton: true });
				return;
			} else {
				this.setState({ showRaiseButton: false });
				return;
			}
		}
	}

	covertToBlobAndSave = (doc: ArrayBuffer, fileName?: string) => {
		const blob = new Blob([doc]);
		const blobUrl = URL.createObjectURL(blob);
		this.setState({
			...this.state,
			photoAnswer: { FileName: fileName || '', DownloadUrl: blobUrl },
		});
	};

	handleInput = (value: string, field: string) => {
		const obj = {};
		obj[field] = value;
		this.setState(obj);
	};

	answerState(answer: string | null | undefined): boolean | null {
		if (answer === 'True') return true;
		if (answer === 'False') return false;

		return null;
	}

	handleAnswerClick = (booleanAnswer: boolean | null) => {
		if (this.props.formComplete) {
			return;
		}

		let dateTime = Date.now();
		let dateTimeAnswer = parseDate('DateTime', dateTime.toString());

		this.setState({ booleanAnswer, dateTimeAnswer });

		let ansString: string | null = null;
		if (booleanAnswer !== null) {
			ansString = booleanAnswer ? 'True' : 'False';
		}

		this.props.data.questionRef
			.update({ Answer: ansString, PassFailDateTimeStamp: dateTime })
			.catch(err => console.error(this.props.t("Error updating answer - ") + err));

		fire.UpdateFormAfterAnswer(this.props.data.FormFBID);

		fire.answerQuestion(
			{ ...this.props.data, Answer: ansString },
			{
				PassFailDateTimeStamp: dateTime.toString(),
				PassFailTextBox: this.state.textAnswer,
				UploadPhoto: false,
			},
		);
	};

	handleDateTimeAnswer = () => {
		let dateTimeAnswer = stringtoUnixUTC(this.state.dateTimeAnswer || '').toString();
		if (dateTimeAnswer === 'NaN') dateTimeAnswer = '';

		this.props.data.questionRef
			.update({ PassFailDateTimeStamp: dateTimeAnswer })
			.catch(err => console.error(this.props.t("Error updating answer - ") + err));

		fire.answerQuestion(
			{ ...this.props.data },
			{ PassFailDateTimeStamp: dateTimeAnswer, PassFailTextBox: this.state.textAnswer, UploadPhoto: false },
		);
	};

	handleTextBoxAnswer = () => {
		let dateTimeAnswer = stringtoUnixUTC(this.state.dateTimeAnswer || '').toString();
		if (dateTimeAnswer === 'NaN') dateTimeAnswer = '';

		const answer = {
			PassFailTextBox: this.state.textAnswer,
			PassFailDateTimeStamp: dateTimeAnswer,
			UploadPhoto: false,
		};
		this.props.data.questionRef.update(answer).catch(err => console.error(this.props.t("Error updating answer - ") + err));

		fire.answerQuestion({ ...this.props.data }, answer);
	};

	setStateCallback = (
		uploadingFile: boolean,
		deletingFile: boolean,
		fileName: string | null,
		fileUrl: string | null,
		guid?: string,
	) => {
		if (this.state.deletingFile !== deletingFile) {
			this.setState({ deletingFile });
		}
		if (this.state.uploadingFile !== uploadingFile) {
			this.setState({ uploadingFile });
		}
	};

	handlefileChangelistener = (event: React.ChangeEvent<HTMLInputElement>) => {
		const data = { ...this.props.data };
		fileChangeListener(event, this.setStateCallback, true, data, this.state);
	};

	handleRemovePhoto = async (guid: string, reference: any) => {
		removePhoto(guid, reference, this.setStateCallback);
	};

	handleRaiseNewJobfromFail() {
		//only run if called from form on job page
		if (!(isNullOrUndefined(this.props.jobId) || this.props.jobId == '') && this.props.formTemplateFBID !== this.props.jobId) {
			fire
				.getDocumentQuery('Jobs', this.props.jobId)
				.get()
				.then(jobObj => {
					const Job = jobObj.data() as Job.Job;
					this.props.history.push({
						pathname: '/new-job-form-fail/' + this.props.formId,
						state: {
							fromFormFail: true,
							JobDetails:
								this.props.t("Fail on form:") + " " +
								this.props.formName + " . " +
								this.props.t("Question:") + " " +
								this.props.data.QuestionTitle + ". " +
								this.props.t('Fail Comment:'),
							SelectedSiteName: Job.Site,
							SelectedSiteID: Job.SiteID,
							SelectedLocationName: Job.Location,
							SelectedLocationID: Job.LocationID,
							SelectedSubLocationName: Job.SubLocation,
							SelectedSubLocationID: Job.SubLocationID,
							jobID: this.props.jobId,
							formID: this.props.formId,
							questionID: this.props.questionId,
							guid: null,
						},
					});
				});
		} else {
			//probably being called from Form Library
			this.props.history.push({
				pathname: '/new-job-form-fail/' + this.props.formId,
				state: {
					fromFormFail: true,
					formTemplateFBID: this.props.formTemplateFBID,
					JobDetails:
						this.props.t("Fail on form:") + " " +
						this.props.formName + " . " +
						this.props.t("Question:") + " " +
						this.props.data.QuestionTitle + ". " +
						this.props.t('Fail Comment:'),
					SelectedSiteName: '',
					SelectedSiteID: null,
					SelectedLocationName: null,
					SelectedLocationID: null,
					SelectedSubLocationName: null,
					SelectedSubLocationID: null,
					jobID: null,
					formID: this.props.formId,
					questionID: this.props.questionId,
					guid: null,
				},
			});
		}
	}

	render() {
		return (
			<QuestionLayout>
				{this.props.data.QuestionTitle}
				<div>
					<Answer
						answer={this.state.booleanAnswer}
						isYesNo={this.props.data.QuestionType === 'Yes/No'}
						handleClick={this.handleAnswerClick}
						isRequired={this.props.data.IsRequired}
					/>

					{/* Create Form if job records fail */}
					{this.state.showRaiseButton === true && this.props.canCreateRequest === true ? (
						<div>
							<Button onClick={() => this.handleRaiseNewJobfromFail()} className="raise-job-button" size="small">
								<Icon className="button-icon" fontSize="small">
									sms_failed
								</Icon>
								{this.props.t("Raise Job From Fail")}
							</Button>
						</div>
					) : null}

					{!isNullOrUndefined(this.props.data.FailJobNumber) ? (
						<div className="fail-job-number">
							<p>
								{this.props.t("Job Created:") + " " + (this.props.data.FailJobNumber === 0 ? this.props.t("Not Yet Synced") : this.props.data.FailJobNumber)}
							</p>
						</div>
					) : null}

					{/* TextBox Included with Question */}
					{this.props.data.IncludeTextBox ? (
						<div className="spacer">
							<TextBoxAnswer
								answer={this.state.textAnswer}
								onChange={val => this.handleInput(val.target.value, 'textAnswer')}
								onBlur={this.handleTextBoxAnswer}
								error={false}
								formComplete={this.props.formComplete}
							/>
						</div>
					) : null}

					{/* Timestamp included with question */}
					{this.props.data.IncludeDateTimeStamp ? (
						<div className="spacer">
							<DateAnswer
								isDateTime={true}
								answer={this.state.dateTimeAnswer}
								handleDateInput={val => this.handleInput(val, 'dateTimeAnswer')}
								updateFirebase={this.handleDateTimeAnswer}
								formComplete={this.props.formComplete}
							/>
						</div>
					) : null}

					{/* Photo included with question */}
					{this.props.data.IncludePhoto ? (
						<div className="spacer">
							{this.state.uploadingFile ? (
								<InlineLoadingSpinner text={this.props.t("Saving File...")} size={20} />
							) : (
								<PhotoAnswer
									fileName={this.state.photoAnswer !== null ? this.state.photoAnswer.FileName : null}
									photoUrl={this.state.photoAnswer !== null ? this.state.photoAnswer.DownloadUrl : null}
									fileChangeListener={evt =>
										fileChangeListener(
											evt,
											this.setStateCallback,
											true,
											this.props.data,
											this.state,
											this.props.formComplete.toString(),
											this.props.formId,
										)
									}
									inputRef={this.inputRef}
									formComplete={this.props.formComplete}
									photosArray={this.state.photosArray}
									documentReference={this.props.data}
									handleRemovePhoto={this.handleRemovePhoto}
									loading={this.state.loading}
									size={this.state.size}
									handleExpand={this.handleExpand}
									photosExpand={this.state.expand}
								/>
							)}
						</div>
					) : null}
				</div>
			</QuestionLayout>
		);
	}
}

interface AnswerProps {
	answer: boolean | null;
	isYesNo: boolean;
	isRequired: boolean;
	handleClick: (answer: boolean | null) => void;
}

const Answer = (props: AnswerProps) => {
	const [t, i18n] = useTranslation();
	const postiveAnswer = props.isYesNo ? t("Yes") : t("Pass");
	const negativeAnswer = props.isYesNo ? t("No") : t("Fail");
	const postiveCssClass = props.answer !== null && props.answer ? 'green' : '';
	const negativeCssClass = props.answer !== null && !props.answer ? 'red' : '';
	const naCssClass = props.answer === null ? 'grey' : '';

	return (
		<div className="boolean-question-container">
			<div onClick={() => props.handleClick(true)} className={`boolean-answer-box ${postiveCssClass}`}>
				{postiveAnswer}
			</div>
			<div onClick={() => props.handleClick(false)} className={`boolean-answer-box ${negativeCssClass}`}>
				{negativeAnswer}
			</div>
			{!props.isRequired ? (
				<div onClick={() => props.handleClick(null)} className={`boolean-answer-box ${naCssClass}`}>
					{t("N/A")}
				</div>
			) : null}
		</div>
	);
};

export default withTranslation()(YesNoQuestion);
