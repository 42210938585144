import * as React from 'react';
import { TextField, InputAdornment, IconButton, Icon } from '@material-ui/core';
import '../../styles/AutoCompleteDropdown.css';
import { isNullOrUndefined } from 'util';
import { IsChrome } from '../../utils/IsChrome';
import { generateFirebaseId } from '../../utils/Guids';

interface ListItems {
	name: string;
	value: string;
}

interface AutoCompleteProps {
	listToFilter: ListItems[];
	selectInputCallback: (object: { name: string; value: string }) => void;
	labelName: string;
	selectedName: string;
	showListUnderNumber: number;
	clearChildDropdowns?: () => void;
}

interface AutoCompleteState {
	filteredList: ListItems[];
	searchTerm: string;
	showSuggestions: boolean;
	inFocus: boolean;
}

export default class AutoCompleteDropDown extends React.Component<AutoCompleteProps, AutoCompleteState> {
	constructor(props) {
		super(props);

		this.state = {
			filteredList: this.props.listToFilter,
			searchTerm: this.props.selectedName,
			showSuggestions: false,
			inFocus: false,
		};
	}

	componentDidUpdate(prevProps: AutoCompleteProps, prevState: AutoCompleteState) {
		if (this.state.searchTerm !== prevState.searchTerm) {
			this.inputChange(this.state.searchTerm);
		}

		if (
			!isNullOrUndefined(this.props.listToFilter) && this.props.listToFilter.length > 0 &&
			!isNullOrUndefined(this.state.filteredList) && this.state.filteredList.length === 0 &&
			(this.state.searchTerm === '' || isNullOrUndefined(this.state.searchTerm))
		) {
			this.setState({
				filteredList: this.props.listToFilter,
				showSuggestions: false,
			});
		}
	}

	//when a item from the dropdown has been selected
	handleSelection = (value: string, name: string) => {
		this.setState({
			showSuggestions: false,
		});
		this.props.selectInputCallback({ name, value });
		this.setState({
			searchTerm: name,
		});
	};

	//when the input field has changed
	inputChange = (searchInputtext: string) => {
		if (searchInputtext === '')
			return this.setState({
				searchTerm: searchInputtext,
				filteredList: this.props.listToFilter,
				showSuggestions: this.state.inFocus && this.props.listToFilter.length <= this.props.showListUnderNumber,
			});

		this.setState({
			searchTerm: searchInputtext,
			filteredList: this.props.listToFilter.filter(suggestion =>
				suggestion.name.toLowerCase().startsWith(searchInputtext.toLowerCase()),
			),
			showSuggestions: true,
		});
	};

	//clears the input
	clear = () => {
		this.setState({
			searchTerm: '',
			filteredList: this.props.listToFilter,
			showSuggestions: false,
			inFocus: false,
		});

		if (this.props.clearChildDropdowns !== undefined) {
			this.props.clearChildDropdowns();
		}
	};

	render() {
		//this is used to create obscure name for input box, so chrome does it come up with its suggested names previously entered. 
		//although I am not using it yet.  It seems to work with the name="{l}"
		let randString = generateFirebaseId();
		return (
			<div id="autocomplete-container">
				<TextField
					autoComplete={IsChrome()}
					onChange={(evt: React.ChangeEvent<HTMLInputElement>) => this.setState({ searchTerm: evt.target.value })}
					type="text"
					//name="SiteSelectorAutocomplete"
					name={randString}
					value={this.state.searchTerm}
					id="autocomplete-input"
					fullWidth={true}
					placeholder={this.props.labelName}
					onFocus={() => {
						setTimeout(() => {
							this.setState({
								showSuggestions: this.props.listToFilter.length <= this.props.showListUnderNumber,
								inFocus: true,
							});
						}, 50);
					}}
					onBlur={() => {
						setTimeout(() => {
							this.setState({ showSuggestions: false, inFocus: false });
						}, 150);
					}}
					disabled={!isNullOrUndefined(this.props.listToFilter) && this.props.listToFilter.length < 1}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									disabled={!isNullOrUndefined(this.props.listToFilter) && this.props.listToFilter.length < 1}
									style={{ padding: '0' }}
									onClick={() => this.clear()}
								>
									<Icon>clear</Icon>
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
				<div id="autocomplete-filter-list" style={{ display: this.state.showSuggestions === false ? 'none' : '' }}>
					<ul className="autocomplete-item-container">
						{!isNullOrUndefined(this.state.filteredList) && this.state.filteredList.length > 0 ? (
							this.state.filteredList.map((listItem, i) => (
								<li
									onClick={() => {
										this.handleSelection(listItem.value, listItem.name);
									}}
									key={i}
									value={listItem.value}
									className="autocomplete-item"
								>
									{listItem.name}
								</li>
							))
						) : (
							<li key={0} value="" className="autocomplete-item">
								{`No ${this.props.labelName} found`}
							</li>
						)}
					</ul>
				</div>
			</div>
		);
	}
}
