import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
// import logger from 'redux-logger';

import rootReducer from './reducers/index.tsx';

// const store = createStore(rootReducer, {}, applyMiddleware(thunk, logger));
const store = createStore(rootReducer, {}, applyMiddleware(thunk));

export default store;
