// React
import * as React from 'react';

// Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon/Icon';

// Utils
import { parseDateForDateTimeFields } from '../../utils/Times';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel, Radio } from '@material-ui/core';

interface InstructJobModalProps {
	open: boolean;
	ExpectedByDate: number | null;
	handleInstructJob: () => void;
	handleCloseInstructJobModal: () => void;
	EditExpectedDate: (e) => void;
	handleDialogClose?: any | null;
	isSeparateTask?: boolean;
	isSharedTask?: boolean;
	toggleIsSeparate: (e) => void;
	toggleIsShared: (e) => void;
}

const InstructJobModal = (props: InstructJobModalProps) => {
	const {t, i18n} = useTranslation();
	return (
	<Dialog open={props.open} onClose={props.handleDialogClose} fullScreen={true} maxWidth="lg">
		<DialogTitle id="form-dialog-title">{t("Instruct Job")}</DialogTitle>
		<DialogContent>
			<div style={{ marginTop: 30, marginBottom: 30 }}>
				<p style={{ fontWeight: 600, fontStyle: 'italic', marginBottom: 5 }}> {t("Expected By Date")} </p>
				<TextField
					id="expected-date"
					type="datetime-local"
					defaultValue={parseDateForDateTimeFields(props.ExpectedByDate)}
					onChange={event => props.EditExpectedDate(event.target.value)}
					fullWidth={true}
				/>
			</div>
			<div style={{ marginTop: 30, marginBottom: 30 }}>
			<p style={{ fontWeight: 600, fontStyle: 'italic', marginBottom: 5 }}> {t("One Shared Task for All Resources")}</p>
				<Radio
				style={{ zoom: 1.25, marginRight: 'auto' }}
				color="primary"
				checked={props.isSeparateTask}
				onChange={(event) => props.toggleIsSeparate(event)}
			/>
			</div>
			<div style={{ marginTop: 30, marginBottom: 30 }}>
			<p style={{ fontWeight: 600, fontStyle: 'italic', marginBottom: 5 }}> {t("Different Task for Each Resource")}</p>
				<Radio
				style={{ zoom: 1.25, marginRight: 'auto' }}
				color="primary"
				checked={props.isSharedTask}
				onChange={(event) => props.toggleIsShared(event)}
			/>
			</div>
		</DialogContent>
		<DialogActions>
			<Button
				onClick={props.handleCloseInstructJobModal}
				color="primary"
				style={{ backgroundColor: 'var(--light-grey)', color: 'white' }}
			>
				<Icon style={{ fontSize: 20 }}>undo</Icon> &nbsp;
				<p style={{ marginTop: 2 }}>{t("Back")}</p>
			</Button>
			<Button
				onClick={props.handleInstructJob}
				color="primary"
				style={{ backgroundColor: 'var(--light-green)', color: 'white' }}
			>
				<Icon style={{ fontSize: 20 }}>person</Icon>&nbsp;
				<p style={{ marginTop: 2 }}>{t("Instruct")}</p>
			</Button>
		</DialogActions>
	</Dialog>
)};

export default InstructJobModal;
