/* eslint-disable prettier/prettier */
// React
import * as React from 'react';
import { History, Location } from 'history';
import { connect } from 'react-redux';
import { match } from 'react-router';

// Actions
import { clearCurrentAssetsInfo } from '../../actions/AssetActions';

// Config File
import Api from '../../apiConfiguration.json';

// Firebase
import { fire, idb } from '../../index';


// Material UI
import { Button, Grid, Icon, IconButton, Fab, Select, MenuItem } from '@material-ui/core';

// Components
import LoadingSpinner from '../../components/Spinners/LoadingSpinner';
import SideDrawer from '../../components/shared/SideDrawer';
import BackButton from '../../components/shared/BackButton';
import AssetSearchModal from '../../components/asset/AssetSearchModal';
import AssetCard from '../../components/asset/AssetCard';

// Utils
import { isNullOrUndefined } from 'util';
import { assetQrSearch } from '../../utils/assets/AssetQrSearch';
import { LocalstorageRemoveItem, LocalstorageGetItem, LocalstorageSetItem } from '../../utils/LocalStorage';
import { windowError, SendErrorData } from '../../utils/WindowError';
import { getBaseURL } from '../../utils/getBaseURL';
import ApiKeyObj from '../../utils/ApiObjectKey';

// CSS
import '../../styles/asset/asset-list.css';
import firebase from 'firebase';


//Free-Text
import Algolia from '../../utils/Algolia';
import QRCodeDialog from '../../components/shared/QRCodeDialog';
import { readQRCodeAndNavigate } from '../../utils/QRCode';
import { generateFirebaseId } from '../../utils/Guids';
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { Result } from '@zxing/library';
import { handleAddAsset } from '../../components/shared/AddAssetFromQRCode';
import { getArrayChunks } from '../../utils/Converters';
import { BusinessTypes } from '../../utils/shared';
import SiteSelect from './SiteSelect';

interface AssetListScreenProps {
	history: History<any>;
	location: Location;
	match: match;
	UserSettings: Store.UserSettings;
	clearCurrentAssetsInfo: () => void;
	AssetsInfo: Store.AssetsInfoState;
	t: any;
}

interface AssetListScreenState {
	assetArray: Asset[];
	SelectedResource: Asset | null;
	lastLoadedResource: any;
	loading: boolean;
	loadingMore: boolean;
	endOfList: boolean;
	documentsPerPage: number;
	DocumentID: string;
	DocumentType: AddToAssetDocumentType;
	QuestionAnswerID: string | null;
	formTemplateFBID: string,
	jobID: string;
	resourceDetailsModalOpen: boolean;
	searchInput: string;
	searchModalOpen: boolean;
	SiteID: number;
	SiteName: string;
	SearchbyQr: boolean;
	yAxisScroll: number;

	assetClassFilter?: {
		value: number;
		label: string;
	} | null;
	loadedAssetClasses: any[];
	assetClassesPerPage: 50;
	assetClassValue: '',
	endAssetClass: boolean;
	lastLoadedAssetClass: any;

	assetSubClassFilter?: {
		value: string;
		label: string;
	} | null;
	loadedAssetSubClasses: any[];
	assetSubClassesPerPage: 50;
	assetSubClassValue: '',
	endAssetSubClass: boolean;
	lastLoadedAssetSubClass: any;

	// Sites, Locations and Sub Locations
	siteFilter?: {
		value: number;
		label: string;
	} | null;
	loadedSites: any[],
	sitesPerPage: number;
	siteValue: string;
	endSite: boolean;
	lastLoadedSite: any;

	locationsPerPage: number;
	lastLoadedLocation: any;
	locationValue: string;
	loadedLocations: [];
	endLocation: boolean;
	locationFilter: {
		value: number;
		label: string;
	} | null;
	locationDisabled: boolean;

	subLocationsPerPage: number;
	lastLoadedSubLocation: any;
	subLocationValue: string;
	loadedSubLocations: [];
	endSubLocation: boolean;
	subLocationFilter: {
		value: number;
		label: string;
	} | null;
	subLocationDisabled: boolean;
	LinkedFromComponent: boolean;
	displayBackButton: boolean;
	algoliaRanOnce: boolean;
	algoliaActive: boolean;
	canAccessAllSites: boolean;
	sitesPermittedToUser: Site[];
	showDownloadAlert: boolean;
	downloadItemText: string;
	dataDownloadLoading: boolean;
	userLocations: any[];
	activeUserLocations: any[];
	userSubLocations: SubLocation[];
	activeUserSubLocations: SubLocation[];
}

class AssetListScreen extends React.Component<AssetListScreenProps, AssetListScreenState> {
	private unsubscribe: any = null;

	constructor(props) {
		super(props);
		this.state = {
			assetArray: [],
			SelectedResource: null,
			lastLoadedResource: null,
			loading: false,
			loadingMore: false,
			endOfList: false,
			documentsPerPage: 20,
			DocumentID: '',
			DocumentType: '',
			QuestionAnswerID: '',
			formTemplateFBID: '',
			jobID: '',
			resourceDetailsModalOpen: false,
			searchInput: '',
			searchModalOpen: false,
			SiteID: 0,
			SiteName: "",
			SearchbyQr: false,
			yAxisScroll: 0,

			assetClassFilter: null,
			loadedAssetClasses: [],
			assetClassesPerPage: 50,
			assetClassValue: '',
			endAssetClass: false,
			lastLoadedAssetClass: null,

			assetSubClassFilter: null,
			loadedAssetSubClasses: [],
			assetSubClassesPerPage: 50,
			assetSubClassValue: '',
			endAssetSubClass: false,
			lastLoadedAssetSubClass: null,

			siteFilter: null,
			loadedSites: [],
			sitesPerPage: 50,
			siteValue: '',
			endSite: false,
			lastLoadedSite: null,

			locationsPerPage: 50,
			lastLoadedLocation: null,
			locationValue: '',
			loadedLocations: [],
			endLocation: false,
			locationFilter: null,
			locationDisabled: true,

			subLocationsPerPage: 50,
			lastLoadedSubLocation: null,
			subLocationValue: '',
			loadedSubLocations: [],
			endSubLocation: false,
			subLocationFilter: null,
			subLocationDisabled: true,

			LinkedFromComponent: false,
			displayBackButton: false,

			algoliaRanOnce: false,
			algoliaActive: false,
			canAccessAllSites: isNullOrUndefined(this.props.UserSettings.CanAccessAllSites) ||
				this.props.UserSettings.CanAccessAllSites
				? true
				: false,
			sitesPermittedToUser: [],
			dataDownloadLoading: false,
			downloadItemText: "",
			showDownloadAlert: false,
			userLocations: [],
			userSubLocations: [],
			activeUserLocations: [],
			activeUserSubLocations: []
		};
		this._onOfflineDataDownloadClick = this._onOfflineDataDownloadClick.bind(this);
	}

	componentWillMount() {
		windowError(
			this.props.UserSettings.email,
			this.props.UserSettings.UserUID,
			this.props.UserSettings.ServerName,
			'AssetList',
		);

		//Handle site, location and sub-location filters
		let locationState = this.props.history.location.state;
		if (!isNullOrUndefined(locationState)) {
			this.setState({ displayBackButton: true })
			const {
				SelectedSiteID,
				SelectedSiteName,
				SelectedLocationID,
				SelectedLocationName,
				SelectedSubLocationID,
				SelectedSubLocationName,
				addAssetDocumnetType,
				questionAnswerID,
				formTemplateFBID,
				jobID,
			} = this.props.history.location.state;

			const siteFilter = {
				value: SelectedSiteID,
				label: SelectedSiteName
			};


			const locationFilter = {
				value: SelectedLocationID,
				label: SelectedLocationName
			}

			const subLocationFilter = {
				value: SelectedSubLocationID,
				label: SelectedSubLocationName
			}

			if (!isNullOrUndefined(SelectedSubLocationID)) {
				this.setState({
					LinkedFromComponent: true,
					siteFilter,
					locationFilter,
					subLocationFilter,
					siteValue: SelectedSiteName,
					locationValue: SelectedLocationName,
					subLocationValue: SelectedSubLocationName,
					displayBackButton: true,
					loading: true,
					assetArray: [],
				}, () => { this.getAssets('list') });
				this.checkLocationExists();
				this.checkSubLocationExists();
			}

			else if (!isNullOrUndefined(SelectedLocationID)) {
				this.setState({
					LinkedFromComponent: true,
					siteFilter,
					locationFilter,
					siteValue: SelectedSiteName,
					locationValue: SelectedLocationName,
					displayBackButton: true,
					loading: true,
					assetArray: [],
				}, () => { this.getAssets('list') });
				this.checkLocationExists();
				this.checkSubLocationExists();
			}

			else if (!isNullOrUndefined(SelectedSiteID)) {
				this.setState({
					LinkedFromComponent: true,
					siteFilter,
					siteValue: SelectedSiteName,
					displayBackButton: true,
					assetArray: [],
				}, () => {
					this.getAssets('list')
				});
				this.checkLocationExists();
				this.checkSubLocationExists();
			}

			else if (!isNullOrUndefined(addAssetDocumnetType)) {
				this.setState({
					LinkedFromComponent: true,
					displayBackButton: true,
					assetArray: [],
					DocumentType: addAssetDocumnetType === "job" || addAssetDocumnetType === "form" ? addAssetDocumnetType : "job",
					QuestionAnswerID: questionAnswerID,
					formTemplateFBID: formTemplateFBID,
					jobID: jobID,
				}, () => {
					this.getAssets('list', this.state.DocumentType)
				});
			}

		}

		this.setState({ loading: true });
	}


	handleTextSearch = async (textInput: string) => {
		this.setState({ loading: true });
		const algolia = new Algolia();
		let textFilters = this.generateFilterOptions();
		const searchResults = await algolia
			.assetsSearch(textInput, this.props.UserSettings.ClientUID, textFilters);
		await this.setState({ assetArray: searchResults, algoliaActive: true, loading: false });
	}

	async componentDidMount() {
		if (!this.state.LinkedFromComponent) {
			if (!this.state.canAccessAllSites) {
				await this.getSitesPermittedToUser();
				this.initilizeData();
			} else {
				this.initilizeData();
			}
		} else {
			await this._fetchUserSites();
			this.initilizeData();
		}
	}

	private initilizeData() {
		const { id } = this.props.match.params as any;
		this.setState({ DocumentID: id });
		// if (this.props.UserSettings.CanAccessAllLocations === false) {
		// 	await this.getLocationsPermittedToUser();
		// 	await this.getSubLocationsPermittedToUser();
		// }
		//load previously set filters from last search from local storage
		//but do not load in if the asset list has been accessed from the job/task page (jobid != "list")
		const LocalItem = LocalstorageGetItem('AssetFilterOptions') || '{}';
		const JsonObj = JSON.parse(LocalItem);

		if (!isNullOrUndefined(this.props.UserSettings.Language))
			i18n.changeLanguage(this.props.UserSettings.Language)
		else if (!isNullOrUndefined(this.props.UserSettings.ClientLanguage))
			i18n.changeLanguage(this.props.UserSettings.ClientLanguage)
		else
			i18n.changeLanguage("English");

		if (!this.state.LinkedFromComponent) {
			this.setState({
				searchInput: JsonObj.searchInput,
				siteFilter: JsonObj.siteFilter || this.state.siteFilter,
				locationFilter: JsonObj.locationFilter || this.state.locationFilter,
				subLocationFilter: JsonObj.subLocationFilter || this.state.subLocationFilter,
				assetClassFilter: JsonObj.assetClassFilter || null,
				assetSubClassFilter: JsonObj.assetSubClassFilter || null
			}, () => {
				this.checkSubLocationExists();
				if (id != 'list') {
					if (this.state.DocumentType === 'job') {
						this.setState({
							siteFilter: null,
							locationFilter: null,
							subLocationFilter: null,
							assetClassFilter: null,
							displayBackButton: true,
							searchInput: ''
						})
						this.getLocation(id);
					} else {
						this.getAssets('list');
					}
				} else {
					this.getAssets(id);
				}
			})
		}

		if (this.state.searchInput) {
			this.setState({ algoliaActive: true });
		}
	}




	loadAssetClasses = async (assetClassName, loadedOptions) => {
		return new Promise((resolve) => {
			fire.getSearchAssetClassesPaginated(this.state.assetClassesPerPage, this.state.lastLoadedAssetClass).get().then(docs => {
				if (!docs.empty) {
					let assetClasses: any = [];
					docs.forEach(function (assetClass) {
						const assetClassData = {
							value: assetClass.id,
							label: assetClass.data().AssetClassName
						}
						assetClasses.push(assetClassData);
					});
					this.setState({ loadedAssetClasses: assetClasses, lastLoadedAssetClass: docs.docs[docs.docs.length - 1] }, () => {
						return resolve({
							options: this.state.loadedAssetClasses,
							hasMore: !this.state.endAssetClass
						});
					})
				} else {
					this.setState({ endAssetClass: true });
					return resolve({
						options: [],
						hasMore: !this.state.endAssetClass
					});
				}
			})
		})
	}

	loadAssetSubClasses = async (loadedOptions) => {

		var assetClassFBID;
		if (!isNullOrUndefined(this.state.assetClassFilter)) {
			assetClassFBID = this.state.assetClassFilter.value;
		}
		return new Promise((resolve) => {
			fire.getSearchAssetSubClassesPaginated(this.state.assetSubClassesPerPage, assetClassFBID, this.state.lastLoadedAssetSubClass).get().then(docs => {
				if (!docs.empty) {
					let assetSubClasses: any = [];
					docs.forEach(function (assetSubClass) {
						const assetSubClassData = {
							value: assetSubClass.id,
							label: assetSubClass.data().AssetSubClassName
						}
						assetSubClasses.push(assetSubClassData);
					});
					this.setState({ loadedAssetSubClasses: assetSubClasses, lastLoadedAssetSubClass: docs.docs[docs.docs.length - 1] }, () => {
						return resolve({
							options: this.state.loadedAssetSubClasses,
							hasMore: !this.state.endAssetSubClass
						});
					})
				} else {
					this.setState({ endAssetSubClass: true });
					return resolve({
						options: [],
						hasMore: !this.state.endAssetSubClass
					});
				}
			})
		})
	}

	loadOptions = async (siteName, loadedOptions) => {
		if (siteName !== this.state.siteValue) {
			await this.setState({ loadedSites: [], lastLoadedSite: null, endSite: false, siteValue: siteName });
			loadedOptions = [];
		}

		return new Promise((resolve) => {
			fire.getSearchSitesPaginated(this.state.sitesPerPage, this.state.siteValue, this.state.lastLoadedSite, this.props.UserSettings.CanAccessAllSites, this.props.UserSettings.ContractFBID).get().then(docs => {
				if (!docs.empty) {
					let sites: any = [];
					docs.forEach(function (site) {
						const siteData = {
							value: site.data().SiteID,
							label: site.data().SiteName
						}
						sites.push(siteData);
					});
					this.setState({ loadedSites: sites, lastLoadedSite: docs.docs[docs.docs.length - 1] }, () => {
						return resolve({
							options: this.state.loadedSites,
							hasMore: !this.state.endSite
						});
					})
				} else {
					this.setState({ endSite: true });
					return resolve({
						options: [],
						hasMore: !this.state.endSite
					});
				}
			})
		})
	}

	loadLocations = async (locationName, loadedOptions) => {
		if (locationName !== this.state.locationValue) {
			await this.setState({ loadedLocations: [], lastLoadedLocation: null, locationValue: locationName });
			loadedOptions = [];
		}

		return new Promise((resolve) => {

			if (!isNullOrUndefined(this.state.siteFilter)) {
				fire.getSearchLocationsPaginated(
					this.state.locationsPerPage,
					this.state.siteFilter.value,
					this.props.UserSettings.CanAccessAllLocations,
					this.state.locationValue,
					this.state.lastLoadedLocation)
					.get()
					.then(docs => {
						if (!docs.empty) {
							let locations: any = [];
							docs.forEach(function (location) {
								const locationData = {
									value: location.data().LocationID,
									label: location.data().LocationName
								}
								locations.push(locationData);
							});
							this.setState({ loadedLocations: locations, lastLoadedLocation: docs.docs[docs.docs.length - 1] }, () => {
								return resolve({
									options: this.state.loadedLocations,
									hasMore: !this.state.endLocation
								});
							})
						} else {
							this.setState({ endLocation: true });
							return resolve({
								options: [],
								hasMore: !this.state.endLocation
							});
						}
					})
			} else {
				return resolve({
					options: [],
					hasMore: false
				})
			}
		})
	}

	loadSubLocations = async (subLocationName, loadedOptions) => {
		if (subLocationName !== this.state.subLocationValue) {
			await this.setState({ loadedSubLocations: [], lastLoadedSubLocation: null, subLocationValue: subLocationName });
			loadedOptions = [];
		}

		return new Promise((resolve) => {

			if (!isNullOrUndefined(this.state.locationFilter)) {
				fire
					.getSearchSubLocationsPaginated(
						this.state.subLocationsPerPage,
						this.state.locationFilter.value,
						this.props.UserSettings.CanAccessAllLocations,
						this.state.subLocationValue,
						this.state.lastLoadedSubLocation
					)
					.get()
					.then(docs => {
						if (!docs.empty) {
							let subLocations: any = [];
							docs.forEach(function (subLocation) {
								const subLocationData = {
									value: subLocation.data().SubLocationID,
									label: subLocation.data().SubLocationName
								}
								subLocations.push(subLocationData);
							});
							this.setState({ loadedSubLocations: subLocations, lastLoadedSubLocation: docs.docs[docs.docs.length - 1] }, () => {
								return resolve({
									options: this.state.loadedSubLocations,
									hasMore: !this.state.endSubLocation
								});
							})
						} else {
							this.setState({ endSubLocation: true });
							return resolve({
								options: [],
								hasMore: !this.state.endSubLocation
							});
						}
					})
			} else {
				return resolve({
					options: [],
					hasMore: false
				})
			}
		})
	}

	generateFilterOptions() {
		let assetFilterOptions = {
			site: !isNullOrUndefined(this.state.siteFilter) ? this.state.siteFilter.value : null,
			location: !isNullOrUndefined(this.state.locationFilter) ? this.state.locationFilter.value : null,
			subLocation: !isNullOrUndefined(this.state.subLocationFilter) ? this.state.subLocationFilter.value : null,
			assetClass: !isNullOrUndefined(this.state.assetClassFilter) ? this.state.assetClassFilter.value : null,
			assetSubClass: !isNullOrUndefined(this.state.assetSubClassFilter) ? this.state.assetSubClassFilter.value : null
		};

		//!isNullOrUndefined(this.state.assetClassFilter) ? console.log(this.state.assetClassFilter.value) : null;

		return assetFilterOptions;
	}

	getAssets(fromView: string, documentType?: string) {
		if (this.state.LinkedFromComponent) {
			const docType = documentType || this.state.DocumentType;
			if (docType === "job") {
				this.getAssetsBasedOnJob(fromView)
			} else if (docType === "form") {
				this.getAssetsBasedOnFormTemplate(fromView)
			}
			else {
				this.getAssetAPIcall(fromView);
			}
		} else {
			if (this.props.UserSettings.CanAccessAllLocations === false) {
				if (this.state.siteFilter && this.state.siteFilter.value &&
					this.state.locationFilter && this.state.locationFilter.value
				) {
					this.getAssetAPIcall(fromView);
				} else {
					this.setState({
						locationFilter: null,
						subLocationFilter: null,
						assetArray: [],
						loading: false
					})
				}
			} else {
				this.getAssetAPIcall(fromView);
			}
		}
	}

	getAssetAPIcall(fromView: string) {
		const assetFilterOptions = this.generateFilterOptions();
		if (this.state.searchInput) {
			this.handleTextSearch(this.state.searchInput);
			return;
		}

		if (fromView == 'list') {
			if (this.props.AssetsInfo.totalAssetsLoaded > 0) {
				const { totalAssetsLoaded } = this.props.AssetsInfo;
				this.setState({ yAxisScroll: window.scrollY, loading: true }, () => {
					fire
						.getAssetsPaginated(totalAssetsLoaded, this.state.lastLoadedResource, assetFilterOptions)
						.get()
						.then(query => {
							this.getAssetsOnce(query);
						})
				})
			} else {
				this.setState({ yAxisScroll: window.scrollY }, async () => {
					await fire
						.getAssetsPaginated(this.state.documentsPerPage, this.state.lastLoadedResource, assetFilterOptions)
						.onSnapshot(query => {
							this.getAssetsOnce(query);
						})
				})
			}
		}
		else {
			this.unsubscribe = fire
				.getAssetsForSitePaginated(
					this.state.SiteID,
					this.state.documentsPerPage,
					this.state.lastLoadedResource,
					assetFilterOptions,
				)
				.get()
				.then(query => {
					this.getAssetsOnce(query);
				})
		}
	}

	private async getAssetsBasedOnJob(fromView: string) {
		const currentJob = (await fire.getJob(this.state.jobID)).data();
		let siteFilter = this.state.siteFilter;
		let locationFilter = this.state.locationFilter;
		let subLocationFilter = this.state.subLocationFilter;

		if (currentJob) {
			if (currentJob.SiteID) {
				siteFilter = { value: currentJob.SiteID, label: currentJob.Site }
			}

			if (currentJob.LocationID) {
				locationFilter = { value: currentJob.LocationID, label: currentJob.Location }
			}

			if (currentJob.SubLocationID) {
				subLocationFilter = { value: currentJob.SubLocationID, label: currentJob.SubLocation }
			}
		}

		this.setState({
			siteFilter,
			locationFilter,
			subLocationFilter,
		}, () => {
			this.getAssetAPIcall("list");
		})

	}

	getAssetsBasedOnFormTemplate = async (fromView: string) => {
		const { id } = this.props.match.params as any;
		const currentForm = (await fire.getFormTemplateFBID(id)).data()
		let siteFilter = this.state.siteFilter;
		let locationFilter = this.state.locationFilter;
		let subLocationFilter = this.state.subLocationFilter;

		if (currentForm) {
			if (currentForm.SiteFBID) {
				const sitedata = (await fire.getSiteInfo(currentForm.SiteFBID)).data();
				if (sitedata && sitedata.SiteID)
					siteFilter = { value: sitedata.SiteID, label: sitedata.SiteName }
			}

			if (currentForm.LocationFBID) {
				const locationData = (await fire.getLocationInfo(currentForm.LocationFBID)).data();
				if (locationData && locationData.LocationID)
					locationFilter = { value: locationData.LocationID, label: locationData.LocationName }
			}


			if (currentForm.SublocationFBID) {
				const subLocationData = (await fire.getSubLocationInfo(currentForm.SublocationFBID)).data();
				if (subLocationData && subLocationData.SubLocationID)
					subLocationFilter = { value: subLocationData.SubLocationID, label: subLocationData.SubLocationName }
			}
		}
		this.setState({
			siteFilter,
			locationFilter,
			subLocationFilter,
		}, () => {
			this.getAssetAPIcall("list");
		})

	}



	getAssetsOnce(query: any) {
		if (query.empty) {
			this.setState({ loading: false, loadingMore: false, endOfList: true });
			if (isNullOrUndefined(this.state.lastLoadedResource)) this.setState({ assetArray: [] });
			return;
		}

		let assetArray = isNullOrUndefined(this.state.lastLoadedResource) ? [] : this.state.assetArray;


		query.docChanges().forEach(change => {
			//console.log(change);
			//console.log(change.doc.data()as Asset);
			if (change.type == "added") {
				const row = change.doc.data() as Asset;
				row.AssetFBID = change.doc.id;
				assetArray.push(row);
			};
		})

		assetArray.sort((a, b) => a.AssetName.toLowerCase().localeCompare(b.AssetName.toLowerCase()));

		this.setState({
			assetArray,
			lastLoadedResource: query.docs[query.docs.length - 1],
			loading: false,
			loadingMore: false,
			endOfList: false,
		},
			() => {
				//this.handleTextSearch(this.state.searchInput);
				if (this.props.AssetsInfo.totalAssetsLoaded != 0) {
					const { yAxisPos } = this.props.AssetsInfo;
					window.scrollTo(0, yAxisPos);

					this.props.clearCurrentAssetsInfo();
				} else {
					window.scrollTo(0, this.state.yAxisScroll);
				}
			});

	}



	handleAssetSnapshot = async (query: firebase.firestore.QuerySnapshot) => {
		//console.log(query);
		if (query.empty) {
			this.setState({ loading: false, loadingMore: false, endOfList: true });
			if (isNullOrUndefined(this.state.lastLoadedResource)) this.setState({ assetArray: [] });
			return;
		}

		let assetArray = isNullOrUndefined(this.state.lastLoadedResource) ? [] : this.state.assetArray;


		query.docChanges().forEach(change => {
			//console.log(change);
			//console.log(change.doc.data()as Asset);
			if (change.type == "added") {
				const row = change.doc.data() as Asset;
				row.AssetFBID = change.doc.id;
				assetArray.push(row);
			};
		})


		// assetArray.push(
		// 	...query.docs.map(assetRow => {
		// 		const row = assetRow.data() as Asset;
		// 		row.AssetFBID = assetRow.id;
		// 		return row;
		// 	}),
		// );
		assetArray.sort((a, b) => a.AssetName.toLowerCase().localeCompare(b.AssetName.toLowerCase()));

		for (let i = 0; i < assetArray.length; i++) {

		}

		this.setState({
			assetArray,
			lastLoadedResource: query.docs[query.docs.length - 1],
			loading: false,
			loadingMore: false,
			endOfList: false,
		},
			() => {
				//this.handleTextSearch(this.state.searchInput);
				if (this.props.AssetsInfo.totalAssetsLoaded != 0) {
					const { yAxisPos } = this.props.AssetsInfo;
					window.scrollTo(0, yAxisPos);

					this.props.clearCurrentAssetsInfo();
				} else {
					window.scrollTo(0, this.state.yAxisScroll);
				}
			});
	};

	addAssetFromQRCode = async (
		DocumentID: string,
		DocumentType: AddToAssetDocumentType,
		QuestionAnswerID: string | null,
		QRCode: Result,
		history: History,
		translate: any,
		formTemplateFBID: string,
		jobID: string
	) => {
		let QRCodeText = QRCode.getText();

		await fire.QRSearch(QRCodeText).then(result => {
			console.log(result);
			if (result.docs.length > 0) {
				const data = result.docs[0].data();
				if (
					data.AssetFBID !== null &&
					data.AssetFBID !== undefined &&
					data.AssetName !== null &&
					data.AssetName !== undefined
				) {
					handleAddAsset(
						result.docs[0].data().AssetFBID,
						result.docs[0].data().AssetName,
						DocumentID,
						DocumentType,
						QuestionAnswerID,
						history,
						translate,
						formTemplateFBID,
						jobID
					);
				} else {
					alert(translate('The scanned QRCode is not associated to an asset'));
				}
			}
		});
	}

	backButton = () => {
		window.history.back();
	};

	handleLogout() {
		LocalstorageRemoveItem('NewJobDetails');
		LocalstorageRemoveItem('JobFilterOptions');
		LocalstorageRemoveItem('ResourceFilterOptions');
		fire.auth.signOut().then(() => location.reload());
	}

	handleCloseChooseAssetDetailModal = (SelectedResource: Asset | null) => {
		this.setState({
			resourceDetailsModalOpen: !this.state.resourceDetailsModalOpen,
			SelectedResource,
		});
	};

	handleSearchModal = () => {
		this.setState({
			searchModalOpen: !this.state.searchModalOpen,
			lastLoadedSite: null,
			lastLoadedAssetClass: null,
			lastLoadedAssetSubClass: null,
			lastLoadedLocation: null,
			lastLoadedSubLocation: null
		});
	};

	handleSearchInput = input => {
		this.setState({ searchInput: input });
	};

	handleAssetClassFilter = assetClass => {
		this.setState({
			assetClassFilter: { value: assetClass.value, label: assetClass.label },
			lastLoadedAssetClass: null,
		})
		this.clearSelectedAssetSubClass();
	}

	handleAssetSubClassFilter = assetSubClass => {
		this.setState({
			assetSubClassFilter: { value: assetSubClass.value, label: assetSubClass.label },
			lastLoadedAssetSubClass: null
		})
	}

	private checkLocationExists = () => {
		if (!isNullOrUndefined(this.state.siteFilter)) {
			fire.doesLocationsExist(this.state.siteFilter.value).then((res) => {
				this.setState({
					locationDisabled: !res
				})
			})
		}
	}

	private checkSubLocationExists = () => {
		if (!isNullOrUndefined(this.state.siteFilter) && !isNullOrUndefined(this.state.locationFilter)) {
			fire.doesSubLocationsExist(this.state.locationFilter.value).then((res) => {
				this.setState({
					subLocationDisabled: !res
				})
			})
		}
	}

	handleSiteFilter = site => {
		this.setState({
			siteFilter: { value: site.value, label: site.label },
			lastLoadedSite: null,
			locationFilter: null,
			loadedLocations: [],
			lastLoadedSubLocation: null,
			subLocationFilter: null,
			loadedSubLocations: [],
			locationValue: '',
			subLocationValue: '',

			locationDisabled: true,
			subLocationDisabled: true,
		}, () => this.checkLocationExists());
	}

	handleLocationFilter = location => {
		this.setState({
			locationFilter: { value: location.value, label: location.label },
			lastLoadedLocation: null,
			lastLoadedSubLocation: null,
			subLocationValue: '',
			loadedSubLocations: [],
			subLocationFilter: null,
			subLocationDisabled: true,
		}, () => this.checkSubLocationExists());
	}

	handleSubLocationFilter = subLocation => {
		this.setState({
			subLocationFilter: { value: subLocation.value, label: subLocation.label },
			lastLoadedSubLocation: null
		});
	}

	areFiltersActive = () => {
		return (
			this.state.searchInput !== '' ||
			!isNullOrUndefined(this.state.siteFilter) ||
			!isNullOrUndefined(this.state.locationFilter) ||
			!isNullOrUndefined(this.state.subLocationFilter) ||
			!isNullOrUndefined(this.state.assetClassFilter)
		);
	};



	handleClearSearch = () => {
		this.setState(
			{
				searchInput: '',
				loading: true,
				lastLoadedResource: null,
				endOfList: false,
				siteFilter: this.state.canAccessAllSites ? null : this.state.siteFilter,
				loadedSites: [],
				siteValue: '',
				lastLoadedSite: null,
				locationFilter: this.props.UserSettings.CanAccessAllLocations === false ?
					this.state.locationFilter : null,
				locationValue: '',
				loadedLocations: [],
				loadedSubLocations: [],
				lastLoadedLocation: null,
				subLocationFilter: this.props.UserSettings.CanAccessAllLocations === false ?
					this.state.subLocationFilter : null,
				subLocationValue: '',
				lastLoadedSubLocation: null,
				locationDisabled: true,
				subLocationDisabled: true,
				assetClassFilter: null,
				loadedAssetClasses: [],
				lastLoadedAssetClass: null,
				assetSubClassFilter: null,
				loadedAssetSubClasses: [],
				lastLoadedAssetSubClass: null,
				algoliaActive: false
			},
			() => {

				this.handleLocalStorageFilters();
				this.getAssets(this.state.DocumentID);
			},
		);
	};

	handleLocalStorageFilters() {
		const SearchArray = {
			searchInput: this.state.searchInput,
			siteFilter: this.state.siteFilter,
			locationFilter: this.state.locationFilter,
			subLocationFilter: this.state.subLocationFilter,
			assetClassFilter: this.state.assetClassFilter,
			assetSubClassFilter: this.state.assetSubClassFilter
		}

		LocalstorageSetItem({ Key: 'AssetFilterOptions', Value: SearchArray });
	}

	clearSelectedAssetClass = () => {
		this.setState({
			lastLoadedAssetClass: null,
			loadedAssetClasses: [],
			assetClassFilter: null,
			endAssetClass: false,
		})
		this.clearSelectedAssetSubClass();
	}

	clearSelectedAssetSubClass = () => {
		this.setState({
			assetSubClassFilter: null,
			endAssetSubClass: false,
			lastLoadedAssetSubClass: null,
			loadedAssetSubClasses: []
		})
	}

	clearSelectedSitesOrLocations = async (typeId) => {
		// typeId = 1 (Site), typeId = 2 (Location), typeId = 3 (Sub Location)
		// If site is cleared, then location and sub location is also cleared as well since they depend on selected site id.
		// If location is cleared, then sub location is also cleared since it depends on selected location id.

		if (typeId === 1) {
			await this.setState({
				lastLoadedSite: null,
				loadedSites: [],
				siteFilter: null,
				siteValue: '',
				endSite: false,
				locationDisabled: true,
				subLocationDisabled: true,
			});
		}

		if (typeId === 1 || typeId === 2) {
			await this.setState({
				lastLoadedLocation: null,
				loadedLocations: [],
				locationFilter: null,
				locationValue: '',
				endLocation: false,
				subLocationDisabled: true,
			});
		}

		if (typeId === 1 || typeId === 2 || typeId === 3) {
			await this.setState({
				lastLoadedSubLocation: null,
				loadedSubLocations: [],
				subLocationFilter: null,
				subLocationValue: '',
				endSubLocation: false,
			});
		}

		this.handleLocalStorageFilters();
	}

	getLocation = async location => {
		if (location !== 'list') {
			const siteID = await fire.getJobSiteID(location);
			const siteName = await fire.getJobSiteName(location);
			this.setState({
				SiteID: siteID,
				SiteName: siteName,
				siteFilter: {
					value: siteID,
					label: siteName
				}
			});
			this.checkLocationExists();
			this.checkSubLocationExists();
			this.getAssets(location);
		}
	};

	async searchAssets() {
		if (this.state.SiteName == "") {
			this.handleLocalStorageFilters();
		}

		await this.setState(
			{
				searchModalOpen: false,
				//loading: true,
				lastLoadedResource: null,
				//loadedSites: [],
				lastLoadedSite: null,
				//siteValue: '',
				endOfList: false,
				loadedLocations: [],
				loadedSubLocations: [],
				locationValue: '',
				subLocationValue: '',
				lastLoadedLocation: null,
				lastLoadedSubLocation: null,
				loadedAssetClasses: [],
				lastLoadedAssetClass: null,
				loadedAssetSubClasses: [],
				lastLoadedAssetSubClass: null
			},
			async () => {
				if (this.state.searchInput) {
					await this.handleTextSearch(this.state.searchInput);
				} else {
					this.setState({ algoliaActive: false });
					this.getAssets(this.state.DocumentID);
				}
			},
		);
	}

	getSitesPermittedToUser = async () => {
		let siteFilter;
		let locationFilter;
		let subLocationFilter;
		let userLocations: any[] = [];
		let activeUserLocations: any[] = [];
		let userSubLocations: SubLocation[] = [];
		let activeUserSubLocations: SubLocation[] = [];

		if (!this.state.LinkedFromComponent) {
			const LocalItem = LocalstorageGetItem('AssetFilterOptions') || '{}';
			const JsonObj = JSON.parse(LocalItem);
			siteFilter = JsonObj.siteFilter || null
			locationFilter = JsonObj.locationFilter || null
			subLocationFilter = JsonObj.subLocationFilter || null
		}
		const sites = await this._fetchUserSites();
	
		if (this.props.UserSettings.CanAccessAllLocations === false ||
			!this.props.UserSettings.CanAccessAllSites
		) {
			siteFilter = siteFilter ? siteFilter :
				{ value: sites[0].SiteID ? +sites[0].SiteID : 0, label: sites[0].SiteName };
			if (this.props.UserSettings.CanAccessAllLocations === false &&
				!this.state.LinkedFromComponent) {
				const locQueryResult = await fire.getLocationsForUser().get();
				if (!locQueryResult.empty) {
					userLocations = locQueryResult
						.docs.map(doc => { return { ID: doc.id, ...doc.data() } as any });

					activeUserLocations = userLocations
						.filter(_loc => _loc.SiteID === siteFilter.value);

					if (activeUserLocations && activeUserLocations.length) {
						locationFilter = locationFilter ? locationFilter :
							{
								value: activeUserLocations[0].LocationID ? activeUserLocations[0].LocationID
									: 0, label: activeUserLocations[0].LocationName
							};
					}

					const subLocationQueryResult = await fire.getSubLocationsForUser().get();

					if (!subLocationQueryResult.empty) {
						userSubLocations = subLocationQueryResult
							.docs
							.map(doc => { return { ID: doc.id, ...doc.data() } as SubLocation });

						activeUserSubLocations = userSubLocations
							.filter(_subLoc => _subLoc.LocationID === locationFilter.value);

						if (activeUserSubLocations && activeUserSubLocations.length) {
							subLocationFilter = subLocationFilter ? subLocationFilter :
								{
									value: activeUserSubLocations[0].SubLocationID ?
										activeUserSubLocations[0].SubLocationID
										: 0, label: activeUserSubLocations[0].SubLocationName
								};
						}
					}
				}
			}
		}

		this.setState({
			sitesPermittedToUser: sites,
			siteFilter,
			userLocations,
			activeUserLocations,
			locationFilter,
			userSubLocations,
			activeUserSubLocations,
			subLocationFilter,
		}, () => {
			this.checkLocationExists();
			this.checkSubLocationExists();
		});
	}

	private async _fetchUserSites() {
		const queryResult = await fire.getSitesCollectionPaginated(Number.MAX_VALUE, false).get();
		const sites = queryResult.docs.map(doc => { return { ID: doc.id, ...doc.data() } as Site });
		this.setState({
			sitesPermittedToUser: sites
		})

		return sites;
	}

	getLocationsPermittedToUser = async () => {
		const queryResult = await fire.getLocationsForUser().get();
		const locations = queryResult
			.docs
			.map(doc => { return { ID: doc.id, ...doc.data() } });
		this.setState({ userLocations: locations })
	}

	getSubLocationsPermittedToUser = async () => {
		const queryResult = await fire.getSubLocationsForUser().get();
		const subLocations = queryResult
			.docs
			.map(doc => { return { ID: doc.id, ...doc.data() } });
		this.setState({ userSubLocations: subLocations as SubLocation[] });
	}

	handleSiteChange = (event) => {
		const filter = (event.target.value as string).split(",");
		this.handleSiteFilter({ value: +filter[0], label: filter[1] });
		const activeSiteId = filter[0] && parseInt(filter[0]);
		const activeUserLocations = this.state.userLocations
			.filter(_loc => _loc.SiteID === activeSiteId);
		this.setState({ activeUserLocations, activeUserSubLocations: [] })
		this.searchAssets();
	}

	handleLocationChange = (event) => {
		const filter = (event.target.value as string).split(",");
		this.handleLocationFilter({ value: +filter[0], label: filter[1] });

		const activeLocationId = filter[0] && parseInt(filter[0]);
		const activeUserSubLocations = this.state.userSubLocations
			.filter(_subLoc => _subLoc.LocationID === activeLocationId);

		this.setState({ activeUserSubLocations })
		this.searchAssets();
	}

	handleSubLocationChange = (event) => {
		const filter = (event.target.value as string).split(",");
		this.handleSubLocationFilter({ value: +filter[0], label: filter[1] });
		this.searchAssets();
	}

	/* Download data for offline start */

	private async _onOfflineDataDownloadClick() {
		if (navigator.onLine) {
			this.setState({ dataDownloadLoading: true, downloadItemText: "Asset Forms" });
			const loadedAssetIds = this.state.assetArray
				&& this.state.assetArray.map(_asset => _asset.AssetFBID);

			if (loadedAssetIds && loadedAssetIds.length) {
				let assetArrayChunks: string[] = getArrayChunks(loadedAssetIds, 10);

				// Clear inactive Asset docs from indexdb
				await idb.removeInactiveDocumentsFromIDB(
					BusinessTypes.Asset,
					loadedAssetIds
				);

				// get asset forms
				for (let chunk of assetArrayChunks) {
					await fire.getFormsForLoadedData(chunk, "AssetFBID", "Asset")
						.then(() => console.info("Asset Forms downloaded"))
						.catch((err) => console.warn("Error with downloading asset Forms"));
				}
				this.setState({ downloadItemText: "Asset Form Templates" });
				await fire.getFormTemplates()
					.then(() => console.info("Asset Form Templates downloaded"))
					.catch((err) => console.warn("Asset with downloading task Form Templates"));

				// get asset photos
				if (loadedAssetIds && loadedAssetIds.length) {
					this.setState({ downloadItemText: "Asset Photos" });
					for (let assetId of loadedAssetIds) {
						await idb.syncPhotosOrDocsFromFireBase(
							`Assets/${assetId}/Documents`,
							`${assetId}`,
							BusinessTypes.Asset,
							true
						);
					}
				}
			}

			this.setState({ dataDownloadLoading: false });
		} else {
			this.setState({ showDownloadAlert: true }, () => {
				setTimeout(() => {
					this.setState({ showDownloadAlert: false });
				}, 2000);
			})
		}
	}

	/* Download data for offline end */

	render() {
		const title = this.props.t('Asset List');
		if (this.state.loading) {
			return <LoadingSpinner text={this.props.t("Loading Assets...")} />;
		}

		if (this.state.dataDownloadLoading) {
			return <LoadingSpinner
				text={this.props.t(`Downloading ${this.state.downloadItemText}...`)}
			/>;
		}

		if (this.state.showDownloadAlert) {
			return <LoadingSpinner
				text={this.props.t("Download not available as you are offline")}
				showAlert
			/>;
		}

		let showSiteSelect: boolean = false;
		let disableFilterLocOrSubLoc = false;

		if (!this.state.canAccessAllSites && this.state.sitesPermittedToUser.length !== 0) {
			showSiteSelect = true
		}
		if (!this.state.canAccessAllSites && this.state.LinkedFromComponent) {
			showSiteSelect = false
		}
		if (this.state.LinkedFromComponent ||
			this.props.UserSettings.CanAccessAllLocations === false) {
			disableFilterLocOrSubLoc = true
		}

		if (
			(!isNullOrUndefined(this.state.assetArray) && this.state.assetArray.length < 1)
			|| !this.state.canAccessAllSites && this.state.sitesPermittedToUser.length === 0
		) {
			return (
				<div>
					<SideDrawer
						history={this.props.history}
						title={title}
						colour="primary"
						handleLogout={this.handleLogout}
						User={this.props.UserSettings}
						rightMenuButton={
							<div>
								{navigator.onLine && (
									<IconButton
										onClick={this._onOfflineDataDownloadClick} color="primary">
										<Icon
											style={{ color: 'white', marginRight: "0.8em" }}
											title='Download data for offline use'
										>
											download_for_offline
										</Icon>
									</IconButton>
								)}
								<IconButton onClick={() => this.setState({ SearchbyQr: !this.state.SearchbyQr })} color="primary">
									<Icon style={{ color: 'white', marginRight: 20 }}>image_search</Icon>
								</IconButton>

								{this.state.DocumentID === 'list' && this.props.UserSettings.CanAddAssets === true ? (
									<IconButton onClick={() => this.props.history.push('/new-asset')}>
										<Icon style={{ color: 'white' }}>add_circle_outline</Icon>
									</IconButton>
								) : null}
								{this.state.displayBackButton ? <BackButton callbackMethod={this.backButton} /> : null}
							</div>
						}
						versionApp={Api.VERSION}
						versionDb={Api.INDEXEDDB_VERSION}
						SendErrorData={SendErrorData}
						getBaseURL={getBaseURL}
						ApiKeyObj={ApiKeyObj}
					/>
					<Grid container={true} direction="column" justify="center" alignItems="center">
						<div className="main-asset" style={{ marginTop: '10px' }}>
							{showSiteSelect &&
								(<SiteSelect
									handleSiteChange={this.handleSiteChange}
									sitesPermittedToUser={this.state.sitesPermittedToUser}
									siteFilter={this.state.siteFilter}
									activeUserLocations={this.state.activeUserLocations}
									handleLocationChange={this.handleLocationChange}
									activeUserSubLocations={this.state.activeUserSubLocations}
									handleSubLocationChange={this.handleSubLocationChange}
									locationFilter={this.state.locationFilter}
									subLocationFilter={this.state.subLocationFilter}
									canAccessAllLocations={this.props.UserSettings
										.CanAccessAllLocations}
									t={this.props.t}
								/>)
							}
							<div className="no-jobtasks-card">
								<h1 className="hot-pink">
									{
										!this.state.canAccessAllSites && this.state.sitesPermittedToUser.length === 0
											? this.props.t("You are not permitted on any sites and therefore any assets.")
											: this.props.t("There are no assets.")
									}
								</h1>
							</div>
							<Fab
								className={this.areFiltersActive() ? 'not-hidden' : 'hidden'}
								id="clear-search-fab"
								color="inherit"
								aria-label="Add"
								style={{ backgroundColor: 'var(--light-red)' }}
								onClick={() => this.handleClearSearch()}
							>
								<Icon style={{ color: 'white' }}>clear</Icon>
							</Fab>

							<Fab
								id="search-fab"
								color="inherit"
								aria-label="Add"
								style={{ backgroundColor: 'var(--light-blue)' }}
								onClick={() => this.handleSearchModal()}
							>
								<Icon style={{ color: 'white' }}>search</Icon>
							</Fab>

							<AssetSearchModal
								searchModalOpen={this.state.searchModalOpen}
								handleSearchModal={this.handleSearchModal}
								searchInput={this.state.searchInput}
								handleSearchInput={this.handleSearchInput}
								searchAssets={() => this.searchAssets()}
								siteFilterDisabled={!this.state.canAccessAllSites}
								siteFilter={this.state.siteFilter}
								siteValue={this.state.siteValue}
								loadOptions={this.loadOptions}
								handleSiteFilter={this.handleSiteFilter}
								subLocationFilter={this.state.subLocationFilter}
								locationFilter={this.state.locationFilter}
								locationValue={this.state.locationValue}
								subLocationValue={this.state.subLocationValue}
								loadLocations={this.loadLocations}
								loadSubLocations={this.loadSubLocations}
								handleLocationFilter={this.handleLocationFilter}
								handleSubLocationFilter={this.handleSubLocationFilter}
								clearSelectedSitesOrLocations={(typeId) => this.clearSelectedSitesOrLocations(typeId)}
								locationDisabled={this.state.locationDisabled ||
									disableFilterLocOrSubLoc}
								subLocationDisabled={this.state.subLocationDisabled ||
									disableFilterLocOrSubLoc}
								assetClassFilter={this.state.assetClassFilter}
								handleAssetClassFilter={this.handleAssetClassFilter}
								loadAssetClasses={this.loadAssetClasses}
								clearSelectedAssetClass={this.clearSelectedAssetClass}
								assetSubClassFilter={this.state.assetSubClassFilter}
								handleAssetSubClassFilter={this.handleAssetSubClassFilter}
								loadAssetSubClasses={this.loadAssetSubClasses}
								clearSelectedAssetSubClass={this.clearSelectedAssetSubClass}
							/>
						</div>
						<div>
							<QRCodeDialog
								isOpen={this.state.SearchbyQr}
								close={() => this.setState({ SearchbyQr: false })}
								history={this.props.history}
								readQRCodeAndNavigate={readQRCodeAndNavigate}
							/>
						</div>
					</Grid>
				</div>
			);
		}

		return (
			<div>
				<SideDrawer
					history={this.props.history}
					title={title}
					colour="primary"
					handleLogout={this.handleLogout}
					User={this.props.UserSettings}
					rightMenuButton={
						<div>
							{navigator.onLine && (
								<IconButton
									onClick={this._onOfflineDataDownloadClick} color="primary">
									<Icon
										style={{ color: 'white', marginRight: "0.8em" }}
										title='Download data for offline use'
									>
										download_for_offline
									</Icon>
								</IconButton>
							)}
							<IconButton onClick={() => this.setState({ SearchbyQr: !this.state.SearchbyQr })} color="primary">
								<Icon style={{ color: 'white', marginRight: 10 }}>image_search</Icon>
							</IconButton>
							{this.state.DocumentID === 'list' && this.props.UserSettings.CanAddAssets === true ? (
								<IconButton onClick={() => this.props.history.push('/new-asset')}>
									<Icon style={{ color: 'white' }}>add_circle_outline</Icon>
								</IconButton>
							) : null}
							{this.state.displayBackButton ? <BackButton callbackMethod={this.backButton} /> : null}
						</div>
					}
					versionApp={Api.VERSION}
					versionDb={Api.INDEXEDDB_VERSION}
					SendErrorData={SendErrorData}
					getBaseURL={getBaseURL}
					ApiKeyObj={ApiKeyObj}
				/>
				<Grid container={true} direction="column" justify="center" alignItems="center">
					<div className="main-asset" style={{ marginTop: '10px' }}>
						{showSiteSelect &&
							(<SiteSelect
								handleSiteChange={this.handleSiteChange}
								sitesPermittedToUser={this.state.sitesPermittedToUser}
								siteFilter={this.state.siteFilter}
								activeUserLocations={this.state.activeUserLocations}
								handleLocationChange={this.handleLocationChange}
								activeUserSubLocations={this.state.activeUserSubLocations}
								handleSubLocationChange={this.handleSubLocationChange}
								locationFilter={this.state.locationFilter}
								subLocationFilter={this.state.subLocationFilter}
								canAccessAllLocations={this.props.UserSettings.CanAccessAllLocations}
								t={this.props.t}
							/>)
						}
						<AssetCard
							numAssets={this.state.assetArray.length}
							assets={this.state.assetArray}
							DocumentID={this.state.DocumentID}
							DocumentType={this.state.DocumentType}
							QuestionAnswerID={this.state.QuestionAnswerID}
							handleAddAsset={handleAddAsset}
							history={this.props.history}
							formTemplateFBID={this.state.formTemplateFBID}
							jobID={this.state.jobID}
						/>

						{this.state.endOfList && this.state.algoliaActive ? (
							<Button variant="outlined" size="large" fullWidth disabled>
								{this.props.t("All Assets Loaded")}
							</Button>
						) : !this.state.algoliaActive ? (
							<Button
								variant="outlined"
								color="primary"
								size="large"
								fullWidth
								onClick={() => this.getAssets(this.state.DocumentID)}
							>
								{this.props.t("Load More Assets")}
							</Button>
						) : null}

						<Fab
							className={this.areFiltersActive() ? 'not-hidden' : 'hidden'}
							id="clear-search-fab"
							color="inherit"
							aria-label="Add"
							style={{ backgroundColor: 'var(--light-red)' }}
							onClick={() => this.handleClearSearch()}
						>
							<Icon style={{ color: 'white' }}>clear</Icon>
						</Fab>

						<Fab
							id="search-fab"
							color="inherit"
							aria-label="Add"
							style={{ backgroundColor: 'var(--light-blue)' }}
							onClick={() => this.handleSearchModal()}
						>
							<Icon style={{ color: 'white' }}>search</Icon>
						</Fab>

						<AssetSearchModal
							searchModalOpen={this.state.searchModalOpen}
							handleSearchModal={this.handleSearchModal}
							searchInput={this.state.searchInput}
							handleSearchInput={this.handleSearchInput}
							searchAssets={() => this.searchAssets()}
							siteFilterDisabled={!this.state.canAccessAllSites}
							siteFilter={this.state.siteFilter}
							siteValue={this.state.siteValue}
							loadOptions={this.loadOptions}
							handleSiteFilter={this.handleSiteFilter}
							locationFilter={this.state.locationFilter}
							subLocationFilter={this.state.subLocationFilter}
							locationValue={this.state.locationValue}
							subLocationValue={this.state.subLocationValue}
							loadLocations={this.loadLocations}
							loadSubLocations={this.loadSubLocations}
							handleLocationFilter={this.handleLocationFilter}
							handleSubLocationFilter={this.handleSubLocationFilter}
							clearSelectedSitesOrLocations={(typeId) => this.clearSelectedSitesOrLocations(typeId)}
							locationDisabled={this.state.locationDisabled ||
								disableFilterLocOrSubLoc}
							subLocationDisabled={this.state.subLocationDisabled ||
								disableFilterLocOrSubLoc}
							assetClassFilter={this.state.assetClassFilter}
							handleAssetClassFilter={this.handleAssetClassFilter}
							loadAssetClasses={this.loadAssetClasses}
							clearSelectedAssetClass={this.clearSelectedAssetClass}
							assetSubClassFilter={this.state.assetSubClassFilter}
							handleAssetSubClassFilter={this.handleAssetSubClassFilter}
							loadAssetSubClasses={this.loadAssetSubClasses}
							clearSelectedAssetSubClass={this.clearSelectedAssetSubClass}
						/>
					</div>
					<div>
						<QRCodeDialog
							isOpen={this.state.SearchbyQr}
							close={() => this.setState({ SearchbyQr: false })}
							history={this.props.history}
							readQRCodeAndNavigate={(QRCode: Result, history: History) => {
								console.log("QRCode", QRCode);
								if (this.state.DocumentID !== 'list') {
									this.addAssetFromQRCode(
										this.state.DocumentID,
										this.state.DocumentType,
										this.state.QuestionAnswerID,
										QRCode,
										history,
										this.props.t,
										this.state.formTemplateFBID ? this.state.formTemplateFBID : '',
										this.state.jobID ? this.state.jobID : '')
								} else {
									readQRCodeAndNavigate(QRCode, history, this.state.DocumentID, '', this.state.DocumentType, this.state.QuestionAnswerID, this.props.t)
								}
							}}
						/>
					</div>
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
	AssetsInfo: state.AssetsInfo
});

const mapDispatchToProps = dispatch => ({
	clearCurrentAssetsInfo: () => dispatch(clearCurrentAssetsInfo())
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AssetListScreen));
