import * as React from 'react';
import { connect } from 'react-redux';
import { History } from 'history';

// IndexedDB + LocalQueue
import { idb, localQueue, fire } from '../../index';

// Load Image for Saving files and Compressing them
import * as loadImage from 'blueimp-load-image';

// Signature Pad Import
import SignaturePad from 'signature_pad';

// Material UI
import { Icon, IconButton } from '@material-ui/core';

// Utils
import { isNullOrUndefined } from 'util';
import { fileToArrayBuffer } from '../../utils/Converters';
import { windowError } from '../../utils/WindowError';
import { GetFileCompressionRatio } from '../../utils/FileCompressionRatio';
import { JOB_DOCUMENT_SOURCE_FROM_APP } from '../../utils/database/indexdb/IndexDb';

// CSS
import '../../styles/photos/edit-photo.css';
import { generateFirebaseId } from '../../utils/Guids';

interface EditPhotoScreenProps {
	photoUri: string;
	clearPhoto: () => void;
	DocumentID: string;
	newJobRequest: boolean;
	newJob: boolean;
	history: History;
	guid: string | null;
	Collection: string;
	UserSettings?: Store.UserSettings;
	isComplete: boolean;
	shouldRedirect?: boolean | true;
	noRedirectCallback?: () => void;
}

class EditPhotoScreen extends React.PureComponent<EditPhotoScreenProps> {
	private signaturePad: SignaturePad | null;
	private photoCanvas: HTMLCanvasElement | null;
	private drawingCanvas: HTMLCanvasElement | null;

	constructor(props) {
		super(props);
		this.signaturePad = null;
		this.photoCanvas = null;
		this.drawingCanvas = null;
	}

	componentWillMount() {
		if (!isNullOrUndefined(this.props.UserSettings)) {
			windowError(
				this.props.UserSettings.email,
				this.props.UserSettings.UserUID,
				this.props.UserSettings.ServerName,
				'EditPhotoScreen',
			);
		}
	}

	componentDidMount() {
		const canvasParent = document.getElementById('rendered-canvas');
		this.photoCanvas = document.getElementById('photo-canvas') as HTMLCanvasElement;
		this.drawingCanvas = document.getElementById('drawing-canvas') as HTMLCanvasElement;
		this.signaturePad = new SignaturePad(this.drawingCanvas);

		if (
			this.photoCanvas !== null &&
			this.drawingCanvas.parentElement !== null &&
			this.photoCanvas.parentElement !== null
		) {
			const photoContext = this.photoCanvas.getContext('2d') as CanvasRenderingContext2D;
			const drawingContext = this.photoCanvas.getContext('2d') as CanvasRenderingContext2D;

			const width = canvasParent !== null ? canvasParent.clientWidth : window.innerWidth;
			const height = window.innerHeight;

			drawingContext.clearRect(0, 0, this.drawingCanvas.width, this.drawingCanvas.height);

			const imageObj = new Image();
			imageObj.onload = () => {
				let imageWidth = imageObj.width;
				let imageHeight = imageObj.height;
				let ratio = Math.min(width / imageWidth, height / imageHeight);
				imageWidth = imageWidth * ratio;
				imageHeight = imageHeight * ratio;

				if (this.drawingCanvas !== null && this.photoCanvas !== null) {
					this.drawingCanvas.width = imageWidth;
					this.drawingCanvas.height = imageHeight;

					this.photoCanvas.width = imageWidth;
					this.photoCanvas.height = imageHeight;

					if (canvasParent !== null) {
						canvasParent.style.height = imageHeight + 50 + 'px';
					}

					drawingContext.clearRect(0, 0, this.drawingCanvas.width, this.drawingCanvas.height);
					photoContext.drawImage(
						imageObj,
						0,
						0,
						imageObj.width,
						imageObj.height,
						0,
						0,
						this.photoCanvas.width,
						this.photoCanvas.height,
					);
				}
			};
			imageObj.src = this.props.photoUri;
		}
	}

	saveImage = () => {
		const mergedCanvas = document.createElement('canvas');
		const context = mergedCanvas.getContext('2d');

		mergedCanvas.height = this.drawingCanvas !== null ? this.drawingCanvas.height : 0;
		mergedCanvas.width = this.drawingCanvas !== null ? this.drawingCanvas.width : 0;

		if (context !== null && this.photoCanvas !== null && this.drawingCanvas !== null) {
			context.drawImage(this.photoCanvas, 0, 0);
			context.drawImage(this.drawingCanvas, 0, 0);

			mergedCanvas.toBlob(async blob => {
				if (blob === null) return;

				const CompressionRatio = GetFileCompressionRatio(blob);

				loadImage(
					blob,
					(canvas: HTMLCanvasElement) => {
						canvas.toBlob(async blob => {
							//converts blob to array buffer
							const arrayBuffer = await fileToArrayBuffer(blob as Blob);



							if (this.props.newJob) {
								idb.setPhotoForCreateJob(arrayBuffer, this.props.guid).then(guid => {
									// console.log(this.props);
									this.props.history.push({
										pathname: `/new-job`,
										state: { guid },
									});
								});
							} else {
								var documentFBID = generateFirebaseId();
								// if its for a photo on an existing job
								idb
									.savePhotoToLocal(
										arrayBuffer,
										this.props.DocumentID,
										this.props.isComplete.toString(),
										documentFBID,
										`photo-${new Date().getTime()}.jpeg`,
										undefined,
										undefined,
										undefined,
										fire.currentUser.email || undefined,
										JOB_DOCUMENT_SOURCE_FROM_APP,
										new Date().getTime(),
									)
									.then(guid => {
										const jobQueueObject = { JobAction: 'AddPhotoExistingJob', PhotoGuid: guid };
										localQueue
											.saveToLocalJobQueue(
												this.props.DocumentID,
												jobQueueObject,
												undefined,
												undefined,
												undefined,
												arrayBuffer,
											)
											.then(() => {
												if (this.props.shouldRedirect) {
													this.setState({ disableSaveButton: false });
													this.props.history.replace('/job-task-list');
													this.props.history.push(`/job-task-details/${this.props.DocumentID}`);
												} else {
													// @ts-ignore
													this.props.noRedirectCallback();
												}
											});
										this.props.history.push(`/job-details/${this.props.DocumentID}`);
									});
							}
						});
					},
					{
						maxWidth: 400,
						maxHeight: 400,
						orientation: true,
						downsamplingRatio: CompressionRatio,
						canvas: true
					},
				);
			});
		}
	};

	render() {
		return (
			<div id="edit-photo-container">
				<div id="rendered-canvas" style={{ position: 'relative' }}>
					<canvas id="photo-canvas" />
					<canvas id="drawing-canvas" />
				</div>
				<div className="photo-screen-actions">
					<IconButton color="inherit" aria-label="Back" onClick={() => this.props.clearPhoto()}>
						<Icon className="white" fontSize="large">
							delete
						</Icon>
					</IconButton>
					<IconButton
						color="inherit"
						aria-label="Take Photo"
						onClick={() => (this.signaturePad !== null ? this.signaturePad.clear() : null)}
					>
						<Icon className="white" fontSize="large">
							layers_clear
						</Icon>
					</IconButton>
					<IconButton color="inherit" aria-label="Flash" onClick={() => this.saveImage()}>
						<Icon className="white" fontSize="large" color="primary">
							save
						</Icon>
					</IconButton>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
});

export default connect(mapStateToProps, null)(EditPhotoScreen);
