/* eslint-disable prettier/prettier */
import * as React from 'react';
import { Select, MenuItem, InputLabel } from '@material-ui/core';

const SiteSelect: React.FC<IProps> = ({
    siteFilter,
    handleSiteChange,
    sitesPermittedToUser,
    activeUserLocations,
    handleLocationChange,
    activeUserSubLocations,
    locationFilter,
    subLocationFilter,
    handleSubLocationChange,
    canAccessAllLocations
}) => {

    return (
        <>
            <InputLabel>{"Sites"}</InputLabel>
            <Select
                id="sublocation-helper"
                name='userSites'
                fullWidth
                variant="outlined"
                value={siteFilter ? [siteFilter.value.toString(), siteFilter.label] : undefined}
                style={{ marginTop: 15, marginBottom: 25 }}
                onChange={handleSiteChange}
                placeholder='Sites'
            >
                {sitesPermittedToUser &&
                    sitesPermittedToUser.map(site => (
                        <MenuItem
                            key={site.ID}
                            value={[site.SiteID as number, site.SiteName].toString()}
                        >
                            {site.SiteName}
                        </MenuItem>
                    ))}
            </Select>
            {canAccessAllLocations === false && (
                <>
                    {activeUserLocations && activeUserLocations.length > 0 && (
                        <>
                            <InputLabel>{"Locations"}</InputLabel>
                            <Select
                                name='Locations'
                                fullWidth
                                variant="outlined"
                                value={locationFilter ? [locationFilter.value.toString(), locationFilter.label] : undefined}
                                style={{ marginTop: 15, marginBottom: 25 }}
                                onChange={handleLocationChange}
                                placeholder='Locations'
                            >
                                {activeUserLocations &&
                                    activeUserLocations.map(_loc => (
                                        <MenuItem
                                            key={_loc.ID}
                                            value={[_loc.LocationID as number, _loc.LocationName].toString()}
                                        >
                                            {_loc.LocationName}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </>
                    )}
                    {activeUserSubLocations && activeUserSubLocations.length > 0 && (
                        <>
                            <InputLabel>{"Sub Locations"}</InputLabel>
                            <Select
                                name='userSubLocations'
                                fullWidth
                                variant="outlined"
                                value={subLocationFilter ?
                                    [subLocationFilter.value.toString(), subLocationFilter.label]
                                    : undefined}
                                style={{ marginTop: 15, marginBottom: 25 }}
                                onChange={handleSubLocationChange}
                                placeholder='Sub Locations'
                            >
                                {activeUserSubLocations.map((_subLoc, i) => (
                                    <MenuItem
                                        key={`${_subLoc.ID}`}
                                        value={[_subLoc.SubLocationID as number, _subLoc.SubLocationName]
                                            .toString()}
                                    >
                                        {_subLoc.SubLocationName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default SiteSelect;

interface IProps {
    siteFilter?: {
        value: number;
        label: string;
    } | null;

    handleSiteChange: (e: any) => void;
    sitesPermittedToUser: Site[];
    activeUserLocations: any[];
    handleLocationChange: (e: any) => void;
    activeUserSubLocations: SubLocation[];
    handleSubLocationChange: (e: any) => void;
    locationFilter: { value: number; label: string; } | null;
    subLocationFilter: { value: number; label: string; } | null;
    canAccessAllLocations?: boolean;
    t: any;
}
