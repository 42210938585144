const JobNoteTypes = {
    4: ['Job Task {0} recalled.  New job task {1} created', 'RecallJob_JobNote'],
    5: ['Resource {0} instructed on task: {1}.  Expected Date set to {2}', 'CreateInstruction_JobNote'],
    6: ['Expected Date set to {0} for task  {1}', 'TaskUpdate_JobNote'],
    7: ['Alert Raised, alert type: {0}, alert action: {1}, alert date: {2}. Assigned to: {3}', 'Createalert_JobNote'],
    8: ['Alert Actioned, Comment: {0}', 'Actionalert_JobNote'],
    9: ['Escalation Actioned, Comment: {0}', 'Actionescalation_JobNote'],
    10: ['Escalation Raised, reason: {0}, assigned to:{1}', 'Createescalation_JobNote'],
    11: ['Budget changed on task number: {0} from {1} to {2}.  Reason for change: {3}', 'ChangeAccrual_JobNote'],
    12: ['Uplift Request Approved on task number: {0}  - accrual raised from {1} to {2}', 'UpliftRequestApproved_JobNote'],
    13: ['Budget changed on Task number: {0} from {1} to {2} by {3}', 'Resourcechangesaccrual_JobNote'],
    14: ['Uplift Requested of {0} on task number: {1} by {2}', 'Resourcerequestsuplift_JobNote'],
    15: ['Job signed off complete. Note: {0}', 'SignoffJobascomplete_JobNote'],
    16: ['Task signed off complete by {0} on {1}', 'SignoffTaskascomplete_JobNote'],
    17: ['Job cancelled', 'CancelJob_JobNote'],
    18: ['Task {0} cancelled', 'CancelWorkInstruction_JobNote'],
    19: ['Job reopened as client added note and required action to be taken by helpdesk', 'CreateJobNote_JobNote'],
    22: ['Quote created.  Quote Number: {0}', 'CreateQuote_JobNote'],
    23: ['Quote approved.  Quote Number: {0}, value: {1}', 'ApproveQuote_JobNote'],
    24: ['Quote rejected.  Quote Number: {0}', 'RejectQuote_JobNote'],
    25: ['Job reopened as resource added note and required action to be taken by helpdesk', 'Resourceaddsnote_JobNote'],
    26: ['Task {0} signed off complete by {1}', 'Resourcecompletestask_JobNote'],
    27: ['Expected Date set to {0}  for task number {1} by {2}', 'ResourcesetsETA_JobNote'],
    28: ['Task reopened.  Task: {0}, reason for reopening : {1}', 'ReopenWorkInstruction_JobNote'],
    29: ['Uplift Request Rejected on task number: {0}', 'UpliftRequestRejected_JobNote'],
    30: ['Resource {1} confirms complete by completing task no {0}, completion date: {2}', 'ResourceSignsoffjobascomplete_JobNote'],
    31: ['Job signed off complete by {0}', 'Resourcecompletesjobtask_JobNote'],
    32: ['Resource {0} accepts task {1}', 'Resourceacceptstask_JobNote'],
    33: ['Resource {0} starts task for task {1}', 'Resourcestartstask_JobNote'],
    34: ['Resource {0} leaves task, task {1} incomplete. Work done so far: {2}', 'Resourceleavestask_taskincomplete_JobNote'],
    35: ['Task {0} created', 'NewTask_JobNote'],
    36: ['Job Reopened From New Task {0}', 'JobReopenedFromNewTask_JobNote'],
    37: ['Job Task {0} reallocated from resource {1} to resource {2}', 'JobTaskReallocated_JobNote'],
    38: ['Job {0} reallocated from resource {1} to resource {2}', 'JobReallocation_JobNote'],
    39: ['Job {0} requires authorisation', 'JobRequiresAuthorisation_JobNote'],
    40: ['Job {0} authorised', 'JobAuthorised_JobNote'],
    41: ['Quote requested from resource {0} instructed on task: {1}.  Expected Date set to {2}', 'QuoteRequested_JobNote'],
    42: ['User Confirms Work Done from resource {0}.  Reason: {1}', 'UserConfirmsWorkDone_JobNote'],
    43: ['User Rejects Work Done from Resource {0}.  Reason: {1}', 'UserRejectsWorkDone_JobNote'],
    44: ['Job Approved. Note: {0}', 'JobApproved_JobNote'],
    45: ['Job Placed On Hold.  Note: {0}', 'JobOnHold_JobNote'],
    46: ['Job Taken Off Hold. Note: {0}', 'JobOffHold_JobNote'],
    47: ['Invoice Sent For Task Number  {0}.  Contractor Invoice No: {1}', 'InvoiceSent_JobNote'],
    48: ['Invoice Cancelled For Task Number  {0}.  Contractor Invoice No: {1}', 'InvoiceCancelled_JobNote'],
    49: ['Invoice Posted For Task Number  {0}.  Contractor Invoice No: {1}', 'InvoicePosted_JobNote'],
    50: ['Job {0} allocated to multiple resources', 'JobAllocatedtoMultipleResources_JobNote'],
    51: ['Resources removed from job', 'Resourcesremovedfromjob_JobNote'],
    52: ['Job: {0} priority has been changed from {1} to {2}', 'JobPriorityChanged_JobNote'],
    53: ['Job expected date changed from {0} to {1}', 'JobExpectedDateChanged_JobNote'],
    54: ['Job duration changed from {0} to {1}', 'JobDurationChanged_JobNote'],
    55: ['Task duration changed to {0} for {1}', 'JobTaskDurationChanged_JobNote'],
    56: ['Job Type changed to {0}', 'JobTypeChanged_JobNote'],
    57: ['Contract / PO Changed to {0}', 'Contract_POChanged_JobNote'],
    58: ['Job SubStatus changed to {0}', 'JobSubStatusChanged_JobNote'],
    59: ['Job Quote / Estimate Submitted on Task no: {0}', 'QuoteSubmitted_JobNote'],
    60: ['Job Quote / Estimate Submit Undone on Task no: {0}', 'QuoteSubmitUndone_JobNote'],
    61: ['Job Re-Opened. Note: {0}', 'JobRe_Opened_JobNote'],
    62: ['Resource {0} starts travel for task {1}', 'ResourceTravelStart_JobNote'],
    63: ['Resource {0} leaves task, task {1} more work required. Reason: {2}', 'LeaveSiteMoreWorkRequiredReason_JobNote'],
    64: ['Job due date changed from {0} to {1}', 'JobDueDateChanged_JobNote'],
    65: ['Site Changed From {0} to {1}', 'SiteChangedForJob_JobNote'],
    66: ['Job {0} Assigned User Changed To {1}', 'JobAssignedUserChanged_JobNote'],
    67: ['Job Task {0} - Resource {1} Removed. Reason {2}', 'Resourcesremovedfrominstructedtask_JobNote'],
    68: ['/////PLACEHOLDER', 'EscalationNote_JobNote'],
    69: ['Risk Assessment Form has been submitted.', 'RiskAssesmentFormsubmitted_JobNote'],
    70: ['Job {0} Team Changed from {1} to {2}', 'JobTeamChanged_JobNote'],
    71: ['Job {0} Has Further Work Required: New Job {1} Created.', 'FurtherWorkRequired_JobNote'],
    72: ['This Job Has Been Linked To Job {0}', 'LinkedToJob_JobNote'],
    73: ['Contractor Scanned In For Task {0}', 'ContractorScannedIn_JobNote'],
    74: ['Contractor Scanned Out For Task {0}', 'ContractorScannedOut_JobNote'],
    75: ['Costs Finalised on Task {0}', 'CostsFinalisedonTask_JobNote'],
    76: ['Booking Request sent to {0} for Job {1}', 'BookingRequestSent_JobNote'],
    77: ['Booking Request has been confirmed for {0} on Job {1}', 'BookingConfirmed_JobNote'],
    78: ['Booking Request has been cancelled on Job {1}', 'BookingRequestCancelled_JobNote'],
    79: ['Booking Request has been declined on Job {1}', 'BookingRequestDeclined_JobNote'],
    80: ['Job Rejected. Note: {0}', 'RejectJob_JobNote'],
    81: ['Contractor {0} accepted task {1}. Note: {2}', 'ContractorAcceptsJobTask_JobNote'],
    82: ['Contractor {0} declined task {1}. Note: {2}', 'ContractorDeclinesJobTask_JobNote'],
    83: ['Contractor {0} rescheduled task {1}. New requested start date: {2}. Note: {3}', 'JobTaskNewStartDateRequested_JobNote'],
    84: ['New Requested Start Date on Task {0} has been approved. Expected Date set to {1}', 'JobTaskNewRequestedStartDateHasBeenApproved_JobNote'],
    85: ['New Requested Start Date on Task {0} has been rejected. Note: {1}', 'JobTaskNewRequestedStartDateHasBeenRejected_JobNote'],
    86: ['Job Project Changed to {0}', 'JobProjectChanged_JobNote'],
    87: ['Alert Updated, alert type: {0}, alert action: {1}, alert date: {2}. Assigned to: {3}', 'EditAlert_JobNote'],
    88: ['Job: {0} has been created.', 'JobCreated_JobNote'],
    89: ['Job Sub Status changed to PDF Report Sent', 'JobSubStatusChangedToPdfSend_JobNote'],
    90: ['Job PDF Report Sent to Job Contact', 'PdfSentToJobContact_JobNote']
};

export default JobNoteTypes;
