// React
import React, { useState } from 'react';

// Material UI
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon/Icon';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import QRCodeDialog from '../shared/QRCodeDialog';
import { Result } from '@zxing/library';
import { AddAssetFromQRCode } from '../shared/AddAssetFromQRCode';

//style
import '../../styles/asset/add-asset-button.css';

interface AddAssetButtonProps {
	documentID: string;
	documentType: AddToAssetDocumentType;
	otherStateParams?: {
		questionAnswerID?: string;
		[key: string]: string | undefined;
	};
	disabled: boolean;
	formTemplateFBID: string;
	jobID: string;
}

const AddAssetButton = (props: AddAssetButtonProps) => {
	const { documentID, documentType, disabled, otherStateParams, formTemplateFBID, jobID } = props;
	const { t } = useTranslation();
	const history = useHistory();
	const [qrDialogIsOpen, setQrDialogIsOpen] = useState(false);

	return (
	<>
		<div className='buttons-grid'>
			<Button
				onClick={() =>
					history.replace({
						pathname: `/asset-list/${documentID}`,
						state: {
							...otherStateParams,
							addAssetDocumnetType: documentType,
							formTemplateFBID,
							jobID
						},
					})
				}
				className={formTemplateFBID !== '' ?  'old-select-asset-button' : 'select-asset-button'}
				variant="contained"
				disabled={disabled}
			>
				<Icon className='icon'>add_to_queue</Icon>&nbsp;
				<p className='label-margin'> {t('Select Asset')}</p>
			</Button>
			<Button
					onClick={() => setQrDialogIsOpen(true)}
					className={formTemplateFBID !== '' ?  'old-scan-asset-button' : 'scan-asset-button'}
					variant="contained"
					disabled={disabled}
				>
					<Icon className='icon'>add_to_queue</Icon>&nbsp;
					<p className='label-margin'> {t('Scan Asset')}</p>
				</Button>
		</div>
		<QRCodeDialog
		isOpen={qrDialogIsOpen}
		close={() => setQrDialogIsOpen(false)}
		history={history}
		readQRCodeAndNavigate={(QRCode: Result, history: History, DocumentID?: string, JobTaskID?: string, DocumentType?: AddToAssetDocumentType, QuestionAnswerID?: string | null, translate?: any) => {
			console.log('QRCode', QRCode);
			AddAssetFromQRCode(
				documentID,
				documentType,
				(otherStateParams && otherStateParams.questionAnswerID) ? otherStateParams.questionAnswerID : null,
				QRCode,
				history,
				t,
				formTemplateFBID,
				jobID
			);
		}}
	/>
	</>
	);
};

export default AddAssetButton;
