import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { History } from 'history';
import dayjs from 'dayjs';
import Fab from '@material-ui/core/Fab/Fab';
import Icon from '@material-ui/core/Icon/Icon';
import SideDrawer from '../../components/shared/SideDrawer';
import ApiKeyObj from '../../utils/ApiObjectKey';
import { getBaseURL } from '../../utils/getBaseURL';
import { SendErrorData } from '../../utils/WindowError';
import Api from '../../apiConfiguration.json';
import JobsCalendarView from './JobsCalendarView';
import BackButton from '../../components/shared/BackButton';
import { fire } from '../../index';
import { LocalstorageGetItem, LocalstorageSetItem } from '../../utils/LocalStorage';
import { hoursAfter } from '../../utils/Times';
import SearchJobsModal from '../../components/job/SearchJobsModal';
import LoadingSpinner from '../../components/Spinners/LoadingSpinner';

const JobsCalendar: React.FC<IProps> = ({
    history,
    UserSettings
}) => {
    const [t, i18n] = useTranslation();
    const [calendarData, setCalendarData] = useState<any[]>([]);
    const [searchModalOpen, setSearchModalOpen] = useState<boolean>(false);
    const [openClosedJobFilter, setOpenClosedJobFilter] = useState<string>("Open");
    const [plannedReactiveJobFilter, setPlannedReactiveJobFilter] = useState<string>("");
    const [isDueToday, setIsDueToday] = useState<boolean>(false);
    const [siteFilter, setSiteFilter] = useState<any>();
    const [siteValue, setSiteValue] = useState<string>("");
    const [locationFilter, setLocationFilter] = useState<any>();
    const [locationValue, setLocationValue] = useState<string>("");
    const [subLocationFilter, setSubLocationFilter] = useState<any>(null);
    const [subLocationValue, setSubLocationValue] = useState<string>("");
    const [locationDisabled, setLocationDisabled] = useState<boolean>(true);
    const [subLocationDisabled, setSubLocationDisabled] = useState<boolean>(true);
    const [userFilter, setUserFilter] = useState<any>();
    const [jobNumber, setJobNumber] = useState<string>("");
    const [lastLoadedUser, setLastLoadedUser] = useState<any>();
    const [lastLoadedSite, setLastLoadedSite] = useState<any>(null);
    const [lastLoadedLocation, setLastLoadedLocation] = useState<any>(null);
    const [lastLoadedSubLocation, setLastLoadedSubLocation] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const initializeData = async () => {
            const localItem = (await LocalstorageGetItem('JobFilterOptions')) || '{}';
            const JsonObj = JSON.parse(localItem);
            const jobFilterOptions = {
                openClosed: JsonObj.openClosedTaskFilter ? JsonObj.openClosedTaskFilter : 'Open',
                plannedReactiveJobFilter: JsonObj.plannedReactiveJobFilter,
                isDueToday: JsonObj.IsDueToday,
                site: JsonObj.siteFilter && JsonObj.siteFilter.value,
                location: JsonObj.locationFilter && JsonObj.locationFilter.value,
                subLocation: JsonObj.subLocationFilter && JsonObj.subLocationFilter.value,
                assignedUser: JsonObj.userFilter && JsonObj.userFilter.value,
                order: JsonObj.order && JsonObj.order.toLowerCase() || 'desc',
                jobNumber: JsonObj.jobNumber,
            };
            setOpenClosedJobFilter(JsonObj.openClosedTaskFilter ? JsonObj.openClosedTaskFilter : "Open");
            setPlannedReactiveJobFilter(JsonObj.plannedReactiveJobFilter);
            setIsDueToday(JsonObj.IsDueToday);
            setSiteFilter(JsonObj.siteFilter || null);
            setLocationFilter(JsonObj.locationFilter || null);
            setSubLocationFilter(JsonObj.subLocationFilter || null);
            setUserFilter(JsonObj.userFilter || null);
            setJobNumber(JsonObj.jobNumber || '');
            getallJobs(jobFilterOptions);
            setSubLocationDisabled(JsonObj.subLocationFilter ? false : true);
            setLocationDisabled(JsonObj.siteFilter ? false : true);
        }
        initializeData();
    }, []);

    const backButtonClick = useCallback(() => {
        history.push('/job-list');
    }, []);

    const getallJobs = useCallback((jobFilter: Job.IJobFilter) => {
        setLoading(true);
        fire.getAllJobs(UserSettings.UserUID,
            UserSettings.CanAccessAllSites,
            UserSettings.CanOnlyViewCreatedJobs,
            jobFilter
        ).onSnapshot(handleJobSnapshot);
    }, [UserSettings]);

    const handleJobSnapshot = async (query: firebase.default.firestore.QuerySnapshot) => {
        const jobDocChanges = query.docChanges();
        let formattedData: any[] = [];
        for (const jobChange of jobDocChanges) {
            const jobData = jobChange.doc.data() as Job.Job;
            const durationString = jobData.ExpectedDuration && jobData.ExpectedDuration
                .slice(0, jobData.ExpectedDuration.indexOf("hours") - 1);
            const durationNum = durationString && parseInt(durationString) || 2;

            if (jobData.ExpectedByDate) {
                formattedData.push({
                    id: jobChange.doc.id,
                    Jobid: jobChange.doc.id,
                    Location: jobData.Location,
                    title: `${jobData.JobNumber} ${jobData.Site}`,
                    start: jobData.ExpectedByDate && new Date(dayjs(jobData.ExpectedByDate).toDate()),
                    end: new Date(hoursAfter(jobData.ExpectedByDate, durationNum)),
                    completedDate: jobData.CompletedDate,
                    jobStatus: jobData.JobStatus
                })
            }
        }
        setLoading(false);
        setCalendarData(formattedData);
    }

    const onSearchClick = useCallback(() => {
        setSearchModalOpen(!searchModalOpen);
        setLastLoadedUser(null);
        setLastLoadedSite(null);
    }, [searchModalOpen]);

    const handleClearSearch = useCallback(() => {
        setOpenClosedJobFilter("Open");
        setPlannedReactiveJobFilter("");
        setIsDueToday(false);
        setSiteFilter(null);
        setLastLoadedSite(null);
        setLocationFilter(null);
        setLastLoadedLocation(null);
        setSubLocationFilter(null);
        setLastLoadedSubLocation(null);
        setSiteValue("");
        setLocationValue("");
        setSubLocationValue("");
        setLocationDisabled(true);
        setSubLocationDisabled(true);
        setUserFilter(null);
        setLastLoadedUser(null);
        setJobNumber("");
        handleLocalStorageFilters();
        getallJobs({});
    }, []);

    const handleOpenClosedFilter = useCallback((selection) => {
        setOpenClosedJobFilter(selection.target.value)
    }, []);

    const handlePlannedReactiveFilter = useCallback((selection: any) => {
        setPlannedReactiveJobFilter(selection.target.value);
    }, []);

    const handleIsDueTodayCheckbox = useCallback((isChecked: boolean) => {
        setIsDueToday(isChecked);
    }, []);

    const handleJobSearch = useCallback(() => {
        handleLocalStorageFilters();
        getallJobs({
            assignedUser: userFilter ? userFilter.value : null,
            openClosed: openClosedJobFilter,
            plannedReactive: plannedReactiveJobFilter,
            isDueToday: isDueToday,
            site: siteFilter ? siteFilter.value : null,
            location: locationFilter ? locationFilter.value : null,
            subLocation: subLocationFilter ? subLocationFilter.value : null,
            jobNumber: jobNumber
        });
        setSearchModalOpen(false);
    }, [
        openClosedJobFilter,
        plannedReactiveJobFilter,
        jobNumber,
        isDueToday,
        userFilter,
        siteFilter,
        locationFilter,
        subLocationFilter
    ]);

    const loadOptions = useCallback(async (siteName, loadedOptions) => {
        const siteperPage = 50;
        if (siteName !== siteValue) {
            lastLoadedSite(null);
            setSiteValue(siteName);
            loadedOptions = [];
        }

        return new Promise((resolve) => {
            fire.getSearchSitesPaginated(
                siteperPage,
                siteValue,
                lastLoadedSite,
                UserSettings.CanAccessAllSites,
                UserSettings.ContractFBID).get().then(docs => {
                    if (!docs.empty) {
                        let sites: any = [];
                        docs.forEach((site) => {
                            const siteData = {
                                value: site.data().SiteID,
                                label: site.data().SiteName
                            }
                            sites.push(siteData);
                        });
                        setLastLoadedSite(docs.docs[docs.docs.length - 1]);
                        return resolve({
                            options: sites,
                            hasMore: true
                        });
                    } else {
                        return resolve({
                            options: [],
                            hasMore: false
                        });
                    }
                })
        })
    }, [siteValue, lastLoadedSite, UserSettings]);

    const handleSiteFilter = useCallback((site) => {
        setSiteFilter({ value: site.value, label: site.label });
        setLastLoadedSite(null);
        setLocationFilter(null);
        setLastLoadedLocation(null);
        setLastLoadedSubLocation(null);
        setSubLocationFilter(null);
        setLocationValue("");
        setSubLocationValue("");
        setLocationDisabled(true);
        setSubLocationDisabled(true);
        if (site.value) {
            fire.doesLocationsExist(site.value).then((res) => {
                setLocationDisabled(!res);
            })
        }
    }, []);

    const loadLocations = useCallback(async (locationName, loadedOptions) => {
        if (locationName !== locationValue) {
            setLastLoadedLocation(null);
            setLocationValue(locationName);
            loadedOptions = [];
        }
        return new Promise((resolve) => {
            const locationsPerPage = 50;
            if (siteFilter && siteFilter.value) {
                fire.getSearchLocationsPaginated(
                    locationsPerPage,
                    siteFilter.value,
                    UserSettings.CanAccessAllLocations,
                    locationValue,
                    lastLoadedLocation)
                    .get()
                    .then(docs => {
                        if (!docs.empty) {
                            let locations: any = [];
                            docs.forEach((location) => {
                                const locationData = {
                                    value: location.data().LocationID,
                                    label: location.data().LocationName
                                }
                                locations.push(locationData);
                            });
                            setLastLoadedLocation(docs.docs[docs.docs.length - 1]);

                            return resolve({
                                options: locations,
                                hasMore: true
                            });
                        } else {
                            return resolve({
                                options: [],
                                hasMore: false
                            });
                        }
                    })
            } else {
                return resolve({
                    options: [],
                    hasMore: false
                })
            }
        })
    }, [locationValue, siteFilter, lastLoadedLocation]);

    const handleLocationFilter = useCallback((location) => {
        setLocationFilter({ value: location.value, label: location.label });
        setLastLoadedLocation(null);
        setLastLoadedSubLocation(null);
        setSubLocationValue("");
        setSubLocationDisabled(true);
        if (location.value) {
            fire.doesSubLocationsExist(location.value).then((res) => {
                setSubLocationDisabled(!res);
            })
        }
    }, []);

    const handleSubLocationFilter = useCallback((subLocation) => {
        setSubLocationFilter({ value: subLocation.value, label: subLocation.label });
        setLastLoadedSubLocation(null);
    }, []);

    const loadSubLocations = useCallback(async (subLocationName, loadedOptions) => {
        const subLocationsPerPage = 50;
        if (subLocationName !== subLocationValue) {
            setLastLoadedSubLocation(null);
            setSubLocationValue(subLocationName);
            loadedOptions = [];
        }

        return new Promise((resolve) => {
            if (locationFilter && locationFilter.value) {
                fire.getSearchSubLocationsPaginated(
                    subLocationsPerPage,
                    locationFilter.value,
                    UserSettings.CanAccessAllLocations,
                    subLocationName,
                    lastLoadedSubLocation
                )
                    .get()
                    .then(docs => {
                        if (!docs.empty) {
                            let subLocations: any = [];
                            docs.forEach((subLocation) => {
                                const subLocationData = {
                                    value: subLocation.data().SubLocationID,
                                    label: subLocation.data().SubLocationName
                                }
                                subLocations.push(subLocationData);
                            });
                            setLastLoadedSubLocation(docs.docs[docs.docs.length - 1]);
                            return resolve({
                                options: subLocations,
                                hasMore: true
                            });
                        } else {
                            return resolve({
                                options: [],
                                hasMore: false
                            });
                        }
                    })
            } else {
                return resolve({
                    options: [],
                    hasMore: false
                })
            }
        })
    }, [subLocationValue, locationFilter, lastLoadedSubLocation]);

    const clearSelectedSitesOrLocations = useCallback(async (typeId) => {
        if (typeId === 1) {
            setLastLoadedSite(null);
            setSiteFilter(null);
            setSiteValue("");
            setLocationDisabled(true);
            setSubLocationDisabled(true);
        }

        if (typeId === 1 || typeId === 2) {
            setLastLoadedLocation(null);
            setLocationFilter(null);
            setLocationValue("");
            setSubLocationDisabled(true);
        }

        if (typeId === 1 || typeId === 2 || typeId === 3) {
            setLastLoadedSubLocation(null);
            setSubLocationFilter(null);
            setSubLocationValue("");
        }

    }, []);

    const loadUsers = useCallback(async () => {
        const usersPerPage = 50;
        return new Promise((resolve) => {
            fire.getAssignedUsersPaginated(usersPerPage, lastLoadedUser)
                .get().then(docs => {
                    if (!docs.empty) {
                        let users: any = [];
                        docs.forEach((user) => {
                            const userData = {
                                value: user.id,
                                label: user.data().Name
                            }
                            users.push(userData);
                        });
                        setLastLoadedUser(docs.docs[docs.docs.length - 1]);

                        return resolve({
                            options: users,
                            hasMore: true
                        })
                    } else {
                        return resolve({
                            options: [],
                            hasMore: false
                        });
                    }
                })
        })
    }, [lastLoadedUser]);

    const clearSelectedUser = useCallback(() => {
        setUserFilter(null);
        setLastLoadedUser(null);
    }, []);

    const handleUserFilter = useCallback((user) => {
        setUserFilter({ value: user.value, label: user.label });
        setLastLoadedUser(null);
    }, []);

    const handleJobNumber = useCallback((jobNumber: string) => {
        setJobNumber(jobNumber);
    }, []);

    const handleLocalStorageFilters = useCallback(() => {
        const filterData = {
            openClosedTaskFilter: openClosedJobFilter,
            plannedReactiveJobFilter: plannedReactiveJobFilter,
            IsDueToday: isDueToday,
            siteFilter: siteFilter,
            locationFilter: locationFilter,
            subLocationFilter: subLocationFilter,
            userFilter: userFilter,
            jobNumber: jobNumber,
        }
        LocalstorageSetItem({ Key: 'JobFilterOptions', Value: filterData });
    }, [openClosedJobFilter,
        plannedReactiveJobFilter,
        jobNumber,
        isDueToday,
        userFilter,
        siteFilter,
        locationFilter,
        subLocationFilter]);

    const isFilterActive = useCallback((): boolean => {
        let isFilterSet = false;
        if (openClosedJobFilter !== "Open" || jobNumber || plannedReactiveJobFilter || jobNumber || isDueToday || userFilter
            || siteFilter || locationFilter || subLocationFilter) {
            isFilterSet = true;
        }

        return isFilterSet;
    }, [openClosedJobFilter,
        plannedReactiveJobFilter,
        jobNumber,
        isDueToday,
        userFilter,
        siteFilter,
        locationFilter,
        subLocationFilter]);

    if (loading) {
        return <LoadingSpinner text={t("Loading jobs...")} />;
    }

    return (
        <>
            <SideDrawer
                history={history}
                title={t("Calendar")}
                colour="primary"
                User={UserSettings}
                ApiKeyObj={ApiKeyObj}
                getBaseURL={getBaseURL}
                SendErrorData={SendErrorData}
                versionApp={Api.VERSION}
                versionDb={Api.INDEXEDDB_VERSION}
                rightMenuButton={<BackButton callbackMethod={backButtonClick} />}

            />
            <JobsCalendarView
                userSettings={UserSettings}
                calendarData={calendarData}
                history={history}
                translate={t}
            />
            <Fab
                className={isFilterActive() ? 'not-hidden' : 'hidden'}
                id="clear-search-fab"
                color="inherit"
                aria-label="Add"
                style={{ backgroundColor: 'var(--light-red)' }}
                onClick={handleClearSearch}
            >
                <Icon style={{ color: 'white' }}>clear</Icon>
            </Fab>
            <Fab
                id="search-fab"
                color="inherit"
                aria-label="Add"
                style={{ backgroundColor: 'var(--light-blue)' }}
                onClick={onSearchClick}
            >
                <Icon style={{ color: 'white' }}>search</Icon>
            </Fab>
            <SearchJobsModal
                searchModalOpen={searchModalOpen}
                handleSearchModal={onSearchClick}
                openClosedTaskFilter={openClosedJobFilter}
                handleOpenClosedFilter={handleOpenClosedFilter}
                plannedReactiveTaskFilter={plannedReactiveJobFilter}
                handlePlannedReactiveFilter={handlePlannedReactiveFilter}
                isDueTodayCheckboxInput={isDueToday}
                handleIsDueTodayCheckbox={handleIsDueTodayCheckbox}
                searchJobTasks={handleJobSearch}
                siteFilter={siteFilter}
                siteValue={siteValue}
                loadOptions={loadOptions}
                handleSiteFilter={handleSiteFilter}
                locationFilter={locationFilter}
                locationValue={locationValue}
                loadLocations={loadLocations}
                handleLocationFilter={handleLocationFilter}
                subLocationFilter={subLocationFilter}
                subLocationValue={subLocationValue}
                loadSubLocations={loadSubLocations}
                handleSubLocationFilter={handleSubLocationFilter}
                clearSelectedSitesOrLocations={clearSelectedSitesOrLocations}
                locationDisabled={locationDisabled}
                subLocationDisabled={subLocationDisabled}
                userFilter={userFilter}
                loadUsers={loadUsers}
                clearSelectedUser={clearSelectedUser}
                handleUserFilter={handleUserFilter}
                jobNumberValue={jobNumber}
                handleJobNumber={handleJobNumber}
            />
        </>
    )
}

const mapStateToProps = (state: Store.Store) => ({
    UserSettings: state.User.UserSettings as Store.UserSettings,
});

export default withTranslation()(connect(mapStateToProps)(JobsCalendar));

interface IProps {
    history: History;
    UserSettings: Store.UserSettings;
}

