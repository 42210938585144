/* eslint-disable prettier/prettier */
import {
	fireAssetConverter,
	fireTagConverter,
	fireLocationConverter,
	fireSiteConverter,
	fireSubLocationConverter,
} from './FirebaseConverters';
// firebase Imports
import firebase from 'firebase/app';
import 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/firebase-messaging';
import { generateFirebaseId, generateGuid } from './Guids';

// Config JSON File (Staging or Production)
import config from '../configuration.json';

// Store for Reducers
import store from '../store';

// Actions
import { updateUser, setUserSettings, loginUserError } from '../actions/UserActions';

import { isNullOrUndefined } from 'util';

// Axios For httpRequests
import axios, { AxiosError } from 'axios';

// Config File
import Api from '../apiConfiguration.json';

// Utils
import { getBaseURL } from './getBaseURL';
import { SendErrorData } from './WindowError';
import ApiKeyObj from './ApiObjectKey';
import { JOB_DOCUMENT_SOURCE_FROM_APP } from './database/indexdb/IndexDb';
import { unixToDateString } from './Times';
import { IRequestFilter } from '../definitions/request';
import { idb } from '../index';

export default class Firebase {
	db: firebase.firestore.Firestore;
	auth: firebase.auth.Auth;
	currentUser: firebase.User;
	resourceFBID: string | undefined;
	ClientID: string | undefined;
	ref: firebase.storage.Reference;
	baseQuery: firebase.firestore.DocumentReference;
	settings: Store.UserSettings | undefined;
	// messaging: firebase.messaging.Messaging;
	// messagingToken: string | null = null;
	private userToSettingsDocId: string | undefined;

	constructor() {
		if (!firebase.apps.length) {
			firebase.initializeApp(config);
		}
		this.db = firebase.firestore();
		this.db.enablePersistence({ synchronizeTabs: true }).catch(err => {
			if (err.code === 'failed-precondition') {
				console.error('Enable Persistence: failed-precondition. Error: ' + err);
			} else if (err.code === 'unimplemented') {
				console.error('Enable Persistence: unimplemented');
			}
		});
		this.auth = firebase.auth();
		this.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
		this.currentUser = this.auth.currentUser as firebase.User;
		this.ref = firebase.storage().ref();
		this.updateCurrentUser();
		this.baseQuery = this.db.collection('Clients').doc('a');
		// this.messaging = firebase.messaging();
	}

	getPhotos(Document: string, Collection: string) {
		return this.baseQuery
			.collection(Collection)
			.doc(Document)
			.collection('Photos');
	}

	getDocuments(Document: string, Collection: string) {
		return this.baseQuery
			.collection(Collection)
			.doc(Document)
			.collection('Documents');
	}

	updateDocumentGuid(itemId: string, collection: string, documentId: string, updateObj: any) {
		return this.baseQuery
			.collection(collection)
			.doc(itemId)
			.collection("Documents")
			.doc(documentId)
			.update(updateObj)
	}

	getDocumentTags() {
		return this.baseQuery
			.collection("DocumentTags")
			.withConverter(fireTagConverter)
	}

	getDocumentTagsFiltered() {
		return this.getDocumentTags().where("TagAvailability", "in", ["All", "UsersOnly"])
	}

	getDocumentTag(tagFBID: string) {
		return this.baseQuery
			.collection('DocumentTags')
			.doc(tagFBID)
			.withConverter(fireTagConverter)
			.get();
	}

	getJobTypes() {
		return this.baseQuery
			.collection('JobTypes')
			.where('IsPlanned', '==', false)
			.orderBy('JobTypeName', 'asc')
			.get();
	}

	getJobTypeGroupById(groupId: string) {
		return this.baseQuery
			.collection('JobTypeGroups')
			.where(firebase.firestore.FieldPath.documentId(), '==', `${groupId}`)
			.get();
	}

	getNotes(Document: string, Collection: string, CanViewAllNotes?: boolean | null) {
		if (!CanViewAllNotes) {
			return this.baseQuery
				.collection(Collection)
				.doc(Document)
				.collection('Notes')
				.where("IsPrivate", "==", false);
		}

		else {
			return this.baseQuery
				.collection(Collection)
				.doc(Document)
				.collection('Notes');
		}
	}

	getNotesForLoadedJobs = (jobIds, CanViewAllNotes): Promise<any> => {
		return new Promise((resolve, reject) => {
			this.baseQuery
				.collection('Jobs')
				.where(firebase.firestore.FieldPath.documentId(), 'in', jobIds)
				.get()
				.then((querySnapshot) => {
					if (querySnapshot.empty) {
						resolve([]);
					} else {
						if (CanViewAllNotes) {
							querySnapshot.forEach(document => {
								document.ref.collection('Notes').get()
							})
						} else {
							querySnapshot.forEach(document => {
								document.ref.collection('Notes')
									.where("IsPrivate", "==", false)
									.get()
							})
						}
						resolve(querySnapshot);
					}
				})
		})
	}

	async getCollection(Collection: string, OrderBy: string, OrderDirection?: 'desc' | 'asc') {
		return this.baseQuery
			.collection(Collection)
			.orderBy(OrderBy, OrderDirection)
			.get();
	}

	getDocumentQuery(Collection: string, DocumentId: string) {
		return this.baseQuery.collection(Collection).doc(DocumentId);
	}
	async updateDocument(documentRef: firebase.firestore.DocumentReference, updateObj: object) {
		return documentRef.update(updateObj);
	}
	async deleteDocument(documentRef: firebase.firestore.DocumentReference) {
		return documentRef.delete();
	}
	async postToJobQueue(documentId: string, QueueData: any, dateTimeNow?: string, isAwait?: boolean) {
		QueueData.ClientUID = this.ClientID;
		QueueData.Timestamp = new Date().toUTCString();
		QueueData.UID = this.currentUser.uid;
		QueueData.DocumentId = documentId;
		QueueData.ServerName = this.settings === undefined ? 'Ireland' : this.settings.ServerName;

		let user = this.getUserSettings(QueueData.UID);

		let collection = "JobQueue".concat(QueueData.ServerName);

		const TimeMilli = dateTimeNow === undefined || dateTimeNow === null ? new Date().getTime().toString() : dateTimeNow;
		if (isAwait) {
			return this.db
				.collection(collection)
				.doc(TimeMilli)
				.set(QueueData);
		} else {
			this.db
				.collection(collection)
				.doc(TimeMilli)
				.set(QueueData);
		}
	}

	async postToJobQueueLogin(documentId: string, jobTaskQueueData: any) {
		jobTaskQueueData.Timestamp = new Date().toUTCString();
		jobTaskQueueData.DocumentId = documentId;

		const TimeMilli = new Date().getTime().toString();

		this.db
			.collection('JobQueue')
			.doc(TimeMilli)
			.set(jobTaskQueueData);
	}

	async postToJobQueueResource(documentId: string, jobTaskQueueData: any) {
		jobTaskQueueData.Timestamp = new Date().toUTCString();
		jobTaskQueueData.DocumentId = documentId;

		const TimeMilli = new Date().getTime().toString();

		let collection = "JobTaskQueue".concat(jobTaskQueueData.ServerName);

		return this.db
			.collection(collection)
			.doc(TimeMilli)
			.set(jobTaskQueueData);
	}

	async postToJobQueueRequest(documentId: string, jobTaskQueueData: any) {
		jobTaskQueueData.Timestamp = new Date().toUTCString();
		jobTaskQueueData.DocumentId = documentId;

		const TimeMilli = new Date().getTime().toString();

		let collection = "RequestQueue".concat(jobTaskQueueData.ServerName);

		return this.db
			.collection(collection)
			.doc(TimeMilli)
			.set(jobTaskQueueData);
	}

	async uploadImageExistingJob(
		imageBlob: Blob,
		DocumentId: string,
		newGuid?: string,
		SyncToQueue?: boolean,
	): Promise<{ FirebaseStoragePath: string; FBID: string }> {
		const unixTime = Date.now();
		const fileName = `${unixTime}.jpg`;
		const imgPath = `${this.ClientID}/${this.currentUser.uid}/images/${fileName}`;
		const photosCollection = `/Clients/${this.ClientID}/Jobs/${DocumentId}/Documents`;
		return this.ref
			.child(imgPath)
			.put(imageBlob)
			.then(firebaseRef => {
				return this.syncFirebase(firebaseRef, photosCollection, DocumentId, newGuid, SyncToQueue);
			})
			.catch(error => {
				this.logException(error, 'Firebase', 'uploadImage');
				console.error(error);
			});
	}

	async uploadImageExistingJobPromise(
		imageBlob: Blob,
		DocumentId: string,
		newGuid: string,
		localQueueObject: indexDb.LocalQueue
	): Promise<boolean> {
		return new Promise(async (resolve) => {
			if (localQueueObject.photoId) {
				const unixTime = Date.now();
				const fileName = `${unixTime}.jpg`;
				const photosCollection = `/Clients/${this.ClientID}/Jobs/${DocumentId}/Documents`;
				const imgPath = `${this.ClientID}/${this.currentUser.uid}/images/${fileName}`;
				const photoFBID = localQueueObject.photoId;
				await this.ref
					.child(imgPath)
					.put(imageBlob)
					.then(async firebaseRef => {
						let success = true;
						success = await this.syncFirebasePromise(
							firebaseRef,
							photosCollection,
							DocumentId,
							newGuid,
							photoFBID
						);
						resolve(success);
					})
					.catch(error => {
						resolve(false);
						this.logException(error, 'Firebase', 'uploadImage');
						console.error(error);
					});
			}
		})
	}

	syncFirebasePromise(
		firebaseRef: firebase.storage.UploadTaskSnapshot,
		photosCollection: string,
		DocumentId: string,
		newGuid: string,
		photoFBId: string
	): Promise<boolean> {
		return new Promise(async (resolve) => {
			let sucess = true;
			firebaseRef.ref
				.getDownloadURL()
				.then(async (url) => {
					const photoObj = {
						FirebaseStoragePath: url,
						FirebaseRef: firebaseRef.ref.fullPath,
						Guid: newGuid,
						Filename: `Photo-${new Date().getTime()}.jpeg`,
						UploadedBy: this.currentUser.email,
						Source: JOB_DOCUMENT_SOURCE_FROM_APP,
						DateCreated: new Date().getTime(),
					};
					await this.db
						.collection(photosCollection)
						.doc(photoFBId)
						.set(photoObj)
						.then(() => {
							const data = {
								FirebaseStoragePath: url,
								JobAction: 'AddPhotoExistingJob',
								FBID: photoFBId,
							}
							this.postToJobQueue(DocumentId, data);
						})
						.catch(() => {
							sucess = false
						})
					resolve(sucess);
				})
				.catch(error => {
					resolve(false);
					this.logException(error, 'Firebase', 'syncFirebase');
					console.error(error);
				});
		})
	}

	async uploadImage(
		imageBlob: Blob,
		DocumentId: string,
		type: string,
		associatedFBID: string,
		photoFBID: string,
		newGuid?: string,
		SyncToQueue?: boolean,
	): Promise<boolean> {
		return new Promise((resolve) => {
			const unixTime = Date.now();
			const fileName = `${unixTime}.jpg`;
			const imgPath = `${this.ClientID}/${this.currentUser.uid}/images/${fileName}`;
			const photosCollection = `/Clients/${this.ClientID}/${type + "s"}/${DocumentId}/Documents`;
			let uploadSuccess = true;
			this.ref
				.child(imgPath)
				.put(imageBlob)
				.then(async (firebaseRef) => {
					uploadSuccess = await this.syncFirebasePhoto(
						firebaseRef,
						photosCollection,
						DocumentId,
						type,
						associatedFBID,
						photoFBID,
						newGuid,
						SyncToQueue
					);
					resolve(uploadSuccess);
				})
				.catch(error => {
					this.logException(error, 'Firebase', 'uploadImage');
					console.error(error);
					resolve(false);
				});
		})
	}

	async syncFirebase(
		firebaseRef: firebase.storage.UploadTaskSnapshot,
		photosCollection: string,
		DocumentId: string,
		newGuid?: string,
		SyncToQueue?: boolean,
	): Promise<{ FirebaseStoragePath: string; FBID: string }> {
		const url: string | null = await firebaseRef.ref.getDownloadURL();

		if (url === null) return Promise.reject();
		const photoObj = {
			FirebaseStoragePath: url,
			FirebaseRef: firebaseRef.ref.fullPath,
			Guid: newGuid,
			Filename: `Photo-${new Date().getTime()}.jpeg`,
			UploadedBy: this.currentUser.email,
			Source: JOB_DOCUMENT_SOURCE_FROM_APP,
			DateCreated: new Date().getTime(),
		};

		return this.db
			.collection(photosCollection)
			.add(photoObj)
			.then(photo => {
				if (SyncToQueue) {
					const data = {
						FirebaseStoragePath: url,
						JobAction: 'AddPhotoExistingJob',
						FBID: photo.id,
					};

					this.postToJobQueue(DocumentId, data);
					return { FirebaseStoragePath: url, FBID: photo.id };
				}
				return { FirebaseStoragePath: url, FBID: photo.id };
			});
	}

	async uploadBlobImage(imageBlob: Blob, userUID: string, clientID: string) {
		const unixTime = Date.now();
		const fileName = `${unixTime}.jpg`;
		const imgPath = `${clientID}/${userUID}/images/${fileName}`;

		return this.ref
			.child(imgPath)
			.put(imageBlob)
			.then(firebaseRef => {
				return firebaseRef.ref.getDownloadURL();
			})
			.catch(error => console.error(error));
	}

	uploadDocument(
		document: Blob,
		documentName: string,
		documentID: string,
		documentNewFBID: string,
		documentGuid: string
	) {
		const uniqueFolderName = 'doc' + generateFirebaseId();
		const docPath = `${this.ClientID}/${this.currentUser.uid}/uploads/${uniqueFolderName}/${documentName}`;
		return this.ref
			.child(docPath)
			.put(document)
			.then(firebaseRef => {
				return firebaseRef.ref.getDownloadURL();
			})
			.then(downloadUrl => {
				this.baseQuery
					.collection('Jobs')
					.doc(documentID)
					.collection('Documents')
					.doc(documentNewFBID)
					.set({
						FirebaseStoragePath: downloadUrl,
						Filename: documentName,
						Description: 'User Upload',
						Guid: documentGuid,
						UploadedBy: this.currentUser.email,
						Source: JOB_DOCUMENT_SOURCE_FROM_APP,
						DateCreated: new Date().getTime(),
						DocumentFBID: documentNewFBID,
					});

				return downloadUrl;
			})
			.then(url => {
				const jobTaskQueueData = {
					FirebaseStoragePath: url,
					JobAction: 'AddDocumentExistingJob',
					DocumentFileName: documentName,
					DocumentFBID: documentNewFBID,
				};
				this.postToJobQueue(documentID, jobTaskQueueData);
			})
			.catch(error => console.error(error));
	}

	async syncFirebasePhoto(
		firebaseRef: firebase.storage.UploadTaskSnapshot,
		photosCollection: string,
		DocumentId: string,
		type: string,
		associatedFBID: string,
		photoFBID: string,
		newGuid?: string,
		SyncToQueue?: boolean,
	): Promise<boolean> {
		return new Promise(async (resolve) => {
			const url: string | null = await firebaseRef.ref.getDownloadURL();
			const photoObj = {
				FirebaseStoragePath: url,
				FirebaseRef: firebaseRef.ref.fullPath,
				Guid: newGuid,
				Filename: `Photo-${new Date().getTime()}.jpeg`,
				UploadedBy: this.currentUser.email,
				Source: JOB_DOCUMENT_SOURCE_FROM_APP,
				DateCreated: new Date().getTime(),
			};
			await this.db
				.collection(photosCollection)
				.doc(photoFBID)
				.set(photoObj)
				.then(() => {
					if (SyncToQueue) {
						const data = {
							FirebaseStoragePath: url,
							JobAction: 'AddDocument',
							FBID: photoFBID,
							Type: type,
							AssociatedFBID: associatedFBID,
							DocumentFileName: photoObj.Filename,
							DocumentFBID: photoFBID
						};
						this.postToJobQueue(DocumentId, data);
					}
					resolve(true)
				}).catch(() => resolve(false))
		})
	}

	addDocument(
		document: Blob,
		documentName: string,
		documentID: string,
		documentNewFBID: string,
		documentGuid: string,
		collection: string,
		associatedFBID: string
	) {
		const uniqueFolderName = 'doc' + generateFirebaseId();
		const docPath = `${this.ClientID}/${this.currentUser.uid}/uploads/${uniqueFolderName}/${documentName}`;
		return this.ref
			.child(docPath)
			.put(document)
			.then(firebaseRef => {
				return firebaseRef.ref.getDownloadURL();
			})
			.then(downloadUrl => {
				this.baseQuery
					.collection(collection + "s")
					.doc(associatedFBID)
					.collection('Documents')
					.doc(documentNewFBID)
					.set({
						FirebaseStoragePath: downloadUrl,
						Filename: documentName,
						Description: 'User Upload',
						Guid: documentGuid,
						UploadedBy: this.currentUser.email,
						Source: JOB_DOCUMENT_SOURCE_FROM_APP,
						DateCreated: new Date().getTime(),
					});

				return downloadUrl;
			})
			.then(url => {
				const jobTaskQueueData = {
					FirebaseStoragePath: url,
					JobAction: 'AddDocument',
					DocumentFileName: documentName,
					DocumentFBID: documentNewFBID,
					AssociatedFBID: associatedFBID,
					Type: collection
				};
				console.log(jobTaskQueueData);
				this.postToJobQueue(documentID, jobTaskQueueData);
			})
			.catch(error => console.error(error));
	}

	addTagToDocument(tagFBID: string, collection: string, associatedFBID: string, selectedDocumentGuid: string): Promise<void> {
		return this.getDocumentTag(tagFBID).then(data => {
			let tag = data.data()
			if (tag !== undefined) {
				return this.baseQuery
					.collection(collection + 's')
					.doc(associatedFBID)
					.collection('Documents')
					.withConverter(fireTagConverter)
					.where('Guid', '==', selectedDocumentGuid)
					.get()
					.then(querySnapshot => {
						if (querySnapshot.docs.length == 1) {
							console.log(querySnapshot.docs[0].data());
							this.baseQuery
								.collection(collection + 's')
								.doc(associatedFBID)
								.collection('Documents')
								.withConverter(fireTagConverter)
								.doc(querySnapshot.docs[0].id)
								.update({
									DocumentTags: firebase.firestore.FieldValue.arrayUnion(tag),
								})
								.then(() => {
									if (tag !== undefined) {
										const jobQueueData = {
											JobAction: 'AddDocumentTag',
											DocumentFBID: querySnapshot.docs[0].id,
											TagFBID: tagFBID,
											AssociatedFBID: associatedFBID,
										};

										this.postToJobQueue(tag.TagFBID, jobQueueData);
									}
								});
						} else if (querySnapshot.docs.length == 0) {
							console.error('Document not found');
						} else {
							console.error('Multiple documents found');
						}
					});
			}
		});
	}

	async addJobNote(DocumentId: string, addObj: any, guid: string) {
		const NoteObj = {
			CreatedBy: this.currentUser.email,
			Note: addObj.note,
			DateAdded: Date.now(),
			IsPrivate: addObj.isPrivate
		};

		return this.baseQuery
			.collection('Jobs')
			.doc(DocumentId)
			.collection('Notes')
			.doc(guid)
			.set(NoteObj);
	}

	loadSiteAndLocations() {
		this.baseQuery
			.collection('Sites')
			.get()
			.then(() => {
				this.db
					.collection('Clients')
					.doc(this.ClientID)
					.collection('Locations')
					.get()
					.then(() => {
						this.db
							.collection('Clients')
							.doc(this.ClientID)
							.collection('SubLocations')
							.get();
					});
			});
	}

	loadResources() {
		this.baseQuery.collection('Resources').get();
	}

	logException(ErrorMessage: string, FileName: string, MethodName: string) {
		this.baseQuery.collection('Exceptions').add({
			ErrorMessage,
			User: this.currentUser.email,
			DateTime: new Date().toUTCString(),
			FileName,
			MethodName,
		});
	}

	private updateCurrentUser() {
		this.auth.onAuthStateChanged((user: firebase.User | null) => {
			if (user !== null) {
				store.dispatch(updateUser(user));
				this.currentUser = user;
				this.resourceFBID = user.uid;
				this.getUserSettings(user.uid);
			} else {
				store.dispatch(loginUserError(''));
			}
		});
	}

	private async getClientSettings(clientUID: string) {
		return this.db.doc(`Clients/${clientUID}`).get();
	}

	private getUserSettings(UserId: string) {
		this.db
			.collection('UsersToClient')
			.where('UserUID', '==', UserId)
			.limit(1)
			.onSnapshot(userSettings => {
				if (userSettings.empty) return;
				const settings = userSettings.docs[0].data() as Store.UserSettings;
				this.ClientID = settings.ClientUID;
				this.baseQuery = this.db.collection('Clients').doc(this.ClientID);
				this.userToSettingsDocId = userSettings.docs[0].id;

				if (isNullOrUndefined(settings.NewJobDatePriorityTreatment)) {
					settings.NewJobDatePriorityTreatment = 'Date';
				}

				this.getClientSettings(settings.ClientUID).then(clientSettings => {
					const { ServerName, ClientLanguage, ClientCulture } = clientSettings.data() as any;
					console.log(ServerName, ClientLanguage, ClientCulture);
					settings.ServerName = isNullOrUndefined(ServerName) ? 'Ireland' : ServerName;
					settings.ClientLanguage = isNullOrUndefined(ClientLanguage) ? 'English' : ClientLanguage;
					settings.ClientCulture = isNullOrUndefined(ClientCulture) ? 'en' : ClientCulture;

					this.settings = settings;
					store.dispatch(setUserSettings(settings));
				});

				this.loadSiteAndLocations();
				//this.loadResources();
				//this.loadAssets();
			});
	}

	getJobsCollectQuery() {
		return this.baseQuery.collection('Jobs').orderBy('JobNumber', 'desc');
	}

	getJobPaginated(itemsPerPage: number, userID: string, canAccessAllSites: boolean, lastJob?: any, filters?: any, canOnlyViewCreatedJobs?: boolean) {
		let query: any;

		query = this.baseQuery.collection('Jobs');

		if (!isNullOrUndefined(filters['jobNumber']) && filters['jobNumber'] != '') {
			// don't consider other filters when searching by job number
			return query.where('JobNumber', '==', +filters['jobNumber']);
		}

		if (!isNullOrUndefined(filters['isDueToday']) && filters['isDueToday']) {
			query = query
				.orderBy('DueDate', filters['order'])
				.where('DueDate', '>=', new Date().setHours(0, 0, 0, 0))
				.where('DueDate', '<=', new Date().setHours(23, 59, 59, 999))
		} else if (isNullOrUndefined(filters['jobNumber']) || filters['jobNumber'] == '') {
			query = query.orderBy('JobNumber', filters['order']);
		}

		if (!canAccessAllSites || canOnlyViewCreatedJobs) {
			query = query.where('UsersPermittedToJob', 'array-contains', userID);
		}

		if (!isNullOrUndefined(filters['openClosed']) && filters['openClosed'] != '') {
			switch (filters['openClosed']) {
				case 'Open':
					query = query.where('IsClosed', '==', false);
					break;
				case 'Closed':
					query = query.where('IsClosed', '==', true);
					break;
			}
		}

		if (!isNullOrUndefined(filters['plannedReactive']) && filters['plannedReactive'] != '') {
			switch (filters['plannedReactive']) {
				case 'Planned':
					query = query.where('IsPlanned', '==', true);
					break;
				case 'Reactive':
					query = query.where('IsPlanned', '==', false);
					break;
			}
		}

		if (!isNullOrUndefined(filters['site']) && isNullOrUndefined(filters['location']) && isNullOrUndefined(filters['subLocation'])) {
			query = query.where('SiteID', '==', filters['site']);
		}

		if (!isNullOrUndefined(filters['site']) && !isNullOrUndefined(filters['location']) && isNullOrUndefined(filters['subLocation'])) {
			query = query.where('SiteID', '==', filters['site']);
			query = query.where('LocationID', '==', filters['location']);
		}

		if (!isNullOrUndefined(filters['site']) && !isNullOrUndefined(filters['location']) && !isNullOrUndefined(filters['subLocation'])) {
			query = query.where('SiteID', '==', filters['site']);
			query = query.where('LocationID', '==', filters['location']);
			query = query.where('SubLocationID', '==', filters['subLocation']);
		}

		if (!isNullOrUndefined(filters['assignedUser']) && filters['assignedUser'] != '') {
			query = query.where('AssignedUserObjFBID', '==', filters['assignedUser']);
		}

		if (lastJob) query = query.startAfter(lastJob);

		query = query.limit(itemsPerPage);

		return query;
	}

	getAllJobs(
		userID: string,
		canAccessAllSites: boolean,
		canOnlyViewCreatedJobs: any,
		filters: Job.IJobFilter
	) {
		let query;
		query = this.baseQuery.collection('Jobs');

		// don't consider other filters when searching by job number
		if (filters.jobNumber) {
			return query.where('JobNumber', '==', parseInt(filters.jobNumber));
		}

		if (filters.isDueToday) {
			query = query
				.where('DueDate', '>=', new Date().setHours(0, 0, 0, 0))
				.where('DueDate', '<=', new Date().setHours(23, 59, 59, 999))
		}

		if (filters.openClosed) {
			switch (filters.openClosed) {
				case 'Open':
					query = query.where('IsClosed', '==', false);
					break;
				case 'Closed':
					query = query.where('IsClosed', '==', true);
					break;
			}
		}

		if (filters.plannedReactive) {
			switch (filters.plannedReactive) {
				case 'Planned':
					query = query.where('IsPlanned', '==', true);
					break;
				case 'Reactive':
					query = query.where('IsPlanned', '==', false);
					break;
			}
		}

		if (filters.site && !filters.location && !filters.subLocation) {
			query = query.where('SiteID', '==', filters.site);
		}

		if (filters.site && filters.location && !filters.subLocation) {
			query = query.where('SiteID', '==', filters.site);
			query = query.where('LocationID', '==', filters.location);
		}

		if (filters.site && filters.location && filters.subLocation) {
			query = query.where('SiteID', '==', filters.site);
			query = query.where('LocationID', '==', filters.location);
			query = query.where('SubLocationID', '==', filters.subLocation);
		}

		if (filters.assignedUser) {
			query = query.where('AssignedUserObjFBID', '==', filters.assignedUser);
		}

		if (!canAccessAllSites || canOnlyViewCreatedJobs) {
			query = query.where('UsersPermittedToJob', 'array-contains', userID);
		}

		return query;
	}

	getAssignedUsersPaginated = (items: number, lastUser?: any) => {

		let query = this.baseQuery.collection('AssignedUsers').orderBy('Name');

		if (lastUser) query = query.startAfter(lastUser);
		query = query.limit(items);
		return query;
	}

	getSearchAssetClassesPaginated = (items: number, lastAssetClass?: any) => {
		let query = this.baseQuery.collection('AssetClass').orderBy('AssetClassName')
			.where("Type", "==", "AssetClass");

		if (lastAssetClass) query = query.startAfter(lastAssetClass);
		query = query.limit(items);
		return query;
	}

	getSiteTypesPaginated = (items: number, lastSiteType?: any) => {
		let query = this.baseQuery.collection('SiteTypes').orderBy('SiteTypeName')

		if (lastSiteType) query = query.startAfter(lastSiteType);
		query = query.limit(items);
		return query;
	}

	getRegionsPaginated = (items: number, lastRegion?: any) => {
		let query = this.baseQuery.collection('Regions').orderBy('RegionName')

		if (lastRegion) query = query.startAfter(lastRegion);
		query = query.limit(items);
		return query;
	}

	getContractsPaginated = (items: number, lastContract?: any) => {
		let query = this.baseQuery.collection('Contracts').orderBy('ContractName')

		if (lastContract) query = query.startAfter(lastContract);
		query = query.limit(items);
		return query;
	}

	getAssetStatusPaginated = (items: number, lastStatus?: any) => {
		let query = this.baseQuery.collection('Statuses').orderBy('StatusDescription')

		if (lastStatus) query = query.startAfter(lastStatus);
		query = query.limit(items);
		return query;
	}

	getSearchAssetSubClassesPaginated = (items: number, assetClassFBID, lastAssetSubClass?: any) => {
		let query = this.baseQuery.collection('AssetClass').orderBy('AssetSubClassName')
			.where("AssetClassFBID", "==", assetClassFBID)
			.where("Type", "==", "AssetSubClass");

		if (lastAssetSubClass) query = query.startAfter(lastAssetSubClass);
		query = query.limit(items);
		return query;
	}

	getSearchSitesPaginated = (items: number, input?: string, lastSite?: any, canAccessAllSites?: boolean, ContractFBID?: string | null) => {
		let query = this.baseQuery
			.collection('Sites')
			.withConverter(fireSiteConverter)
			.orderBy('SiteName');

		if (!isNullOrUndefined(canAccessAllSites) && !canAccessAllSites) {
			query = this.baseQuery
				.collection('Sites')
				.withConverter(fireSiteConverter)
				.where('UsersPermittedToSite', 'array-contains', this.currentUser.uid);
		}

		if (!isNullOrUndefined(ContractFBID)) {
			query = query.where('ContractFBID', '==', ContractFBID);
		}

		if (!isNullOrUndefined(input) && input !== '') {
			query = query.where('SiteNameArray', 'array-contains', input.toLowerCase());
		}

		if (lastSite) query = query.startAfter(lastSite);
		query = query.limit(items);
		return query;
	}

	getSearchLocationsPaginated = (
		items: number,
		siteId: number | string | null,
		canAccessAllLocations: boolean | undefined,
		input?: string,
		lastLocation?: any,
	) => {
		let query = this.baseQuery
			.collection('Locations')
			.withConverter(fireLocationConverter)
			.orderBy('LocationName');

		if (canAccessAllLocations === false) {
			query = query.where('UsersPermittedToLocation', 'array-contains', this.currentUser.uid);
		}

		if (typeof siteId === 'number') query = query.where('SiteID', '==', siteId);
		else if (typeof siteId === 'string') query = query.where('SiteFBID', '==', siteId);

		if (!isNullOrUndefined(input) && input !== '') {
			query = query.where('LocationNameArray', 'array-contains', input.toLowerCase());
		}

		if (lastLocation) query = query.startAfter(lastLocation);
		query = query.limit(items);
		return query;
	}

	getSearchSubLocationsPaginated = (
		items: number,
		locationId: number | null,
		canAccessAllLocations: boolean | undefined,
		input?: string,
		lastSubLocation?: any,
	) => {
		let query = this.baseQuery
			.collection('SubLocations')
			.withConverter(fireSubLocationConverter)
			.orderBy('SubLocationName');

		if (canAccessAllLocations === false) {
			query = query.where('UsersPermittedToSubLocation', 'array-contains', this.currentUser.uid);
		}

		if (typeof locationId === 'number') query = query.where('LocationID', '==', locationId);

		if (!isNullOrUndefined(input) && input !== '') {
			query = query.where('SubLocationNameArray', 'array-contains', input.toLowerCase());
		}

		if (lastSubLocation) query = query.startAfter(lastSubLocation);
		query = query.limit(items);
		return query;
	}

	async CompleteJob(DocumentID: string, JobObj: any) {
		return this.baseQuery
			.collection('Jobs')
			.doc(DocumentID)
			.update({
				JobStatus: 'Complete',
				JobSubStatus: '',
				IsClosed: true,
				CompletedDate: JobObj.CompletedDate,
				Latitude: JobObj.Latitude,
				Longitude: JobObj.Longitude

			});
	}

	async updateIsOpened(DocumentID: string) {

		return this.baseQuery
			.collection('Jobs')
			.doc(DocumentID)
			.update({
				IsOpened: true
			});
	}

	async UpdateFormAfterAnswer(DocumentID: string) {
		return this.baseQuery
			.collection('Forms')
			.doc(DocumentID)
			.update({
				LastUpdatedByUserName: this.currentUser.displayName,
				LastUpdatedByUserFBID: this.currentUser.uid,
				LastUpdatedDate: Date.now(),
			});
	}

	async InstructJob(DocumentID: string, InstructedDate: number, ExpectedByDate: number) {
		return this.baseQuery
			.collection('Jobs')
			.doc(DocumentID)
			.update({
				JobStatus: 'Instructed',
				InstructedDate: InstructedDate,
				ExpectedByDate: ExpectedByDate,
			});
	}

	async CreateJob(JobObj: any, Guid: string) {
		return this.baseQuery
			.collection('Jobs')
			.doc(Guid)
			.set(JobObj);
	}

	async CreateAsset(AssetObj: any, Guid: string) {
		return this.baseQuery
			.collection('Assets')
			.doc(Guid)
			.set(AssetObj);
	}

	async createAssetToJob(AssetJobobj: any, Guid: string) {
		let AssetName = '';
		let AssetClass = '';
		let AssetCode = '';
		let JobNumber = '';
		let JobDetails = '';
		let JobStatus = '';

		await this.baseQuery
			.collection('Assets')
			.doc(AssetJobobj.AssetFBID)
			.get()
			.then(async data => {
				AssetName = data.get('AssetName');
				AssetClass = data.get('AssetClass');
				AssetCode = data.get('AssetCode');
			});

		await this.baseQuery
			.collection('Jobs')
			.doc(AssetJobobj.JobFBID)
			.get()
			.then(data2 => {
				JobNumber = data2.get('JobNumber');
				JobDetails = data2.get('JobDetails');
				JobStatus = data2.get('JobStatus');
			});

		this.baseQuery
			.collection('AssetsToJobs')
			.doc(Guid) //.set(AssetJobobj);
			.set({
				JobFBID: AssetJobobj.JobFBID,
				AssetFBID: AssetJobobj.AssetFBID,
				DateCreated: Date.now(),
				AssetName: AssetName,
				AssetClass: AssetClass,
				AssetCode: AssetCode,
				JobNumber: JobNumber,
				JobDetails: JobDetails,
				JobStatus: JobStatus,
			});

		return JobNumber;
	}

	async createAssetToForm(AssetFormobj: { AssetFBID: string, FormFBID: string, QuestionAnswerFBID: string }) {
		let AssetName = '';
		let AssetClass = '';
		let AssetCode = '';
		let FormName = '';
		let FormStatus = '';

		await this.baseQuery
			.collection('Assets')
			.doc(AssetFormobj.AssetFBID)
			.get()
			.then(async data => {
				AssetName = data.get('AssetName');
				AssetClass = data.get('AssetClass');
				AssetCode = data.get('AssetCode');
			});

		await this.baseQuery
			.collection('Forms')
			.doc(AssetFormobj.FormFBID)
			.get()
			.then(data => {
				FormName = data.get('FormName');
				FormStatus = data.get('Status');
			});

		await this.baseQuery
			.collection('Forms')
			.doc(AssetFormobj.FormFBID)
			.collection('QuestionAnswers')
			.doc(AssetFormobj.QuestionAnswerFBID)
			.update(
				{
					[`Assets.${AssetFormobj.AssetFBID}`]: {
						FormFBID: AssetFormobj.FormFBID,
						AssetFBID: AssetFormobj.AssetFBID,
						QuestionAnswerFBID: AssetFormobj.QuestionAnswerFBID,
						DateCreated: Date.now(),
						AssetName,
						AssetClass,
						AssetCode,
						FormName,
						FormStatus,
					}
				}
			);
	}

	async doesJobExist(JobFBID: string) {
		let doc = await this.baseQuery
			.collection('Jobs')
			.doc(JobFBID)
			.get()
			.then(Record => {
				const job = Record.data() as Job.Job;
				if (isNullOrUndefined(job)) {
					return false;
				} else {
					if (job.JobNumber > 0) return true;
					else return false;
				}
			});
		return doc;
	}

	async doesLocationsExist(SiteID: number) {
		return this.baseQuery
			.collection('Locations')
			.where('SiteID', '==', SiteID)
			.limit(1)
			.get()
			.then(Record => Record.docs.length > 0);
	}

	async doesSubLocationsExist(LocationID: number) {
		return this.baseQuery
			.collection('SubLocations')
			.where('LocationID', '==', LocationID)
			.limit(1)
			.get()
			.then(Record => Record.docs.length > 0);
	}

	async doesAssetExist(AssetFBID: string) {
		return this.baseQuery
			.collection('Assets')
			.where('AssetFBID', '==', AssetFBID)
			.limit(1)
			.get()
			.then(Record => Record.docs.length > 0);
	}

	async doesAssetToJobAlreadyExist(AssetFBID: string, JobFBID: string) {
		return this.baseQuery
			.collection('AssetsToJobs')
			.where('AssetFBID', '==', AssetFBID)
			.where('JobFBID', '==', JobFBID)
			.limit(1)
			.get()
			.then(Record => Record.docs.length > 0);
	}

	async getQuestionAnswer(FormFBID: string, QuestionAnswerFBID: string) {
		return this.baseQuery
			.collection('Forms')
			.doc(FormFBID)
			.collection('QuestionAnswers')
			.doc(QuestionAnswerFBID)
			.get();
	}

	async doesAssetToFormAlreadyExist(AssetFBID: string, FormFBID: string, QuestionAnswerFBID: string) {
		return this.getQuestionAnswer(FormFBID, QuestionAnswerFBID)
			.then(questionAnswer => {
				if (!questionAnswer.exists) {
					return false;
				}

				const questionAnswerData = questionAnswer.data();
				if (questionAnswerData === undefined) {
					return false;
				}

				// check if the asset fbid exists in the already attached assets on the question
				var assetExists = false;
				if (questionAnswerData.Assets && questionAnswerData.Assets.length > 0) {
					questionAnswerData.Assets.map(asset => {
						if (asset.AssetFBID == AssetFBID) {
							assetExists = true;
							return assetExists;
						}
					})
				}

				return assetExists;
			})
	}

	async doesUserExist(uid: string) {
		return this.db
			.collection('UsersToClient')
			.where('UserUID', '==', uid)
			.limit(1)
			.get()
			.then(userRecord => userRecord.docs.length > 0);
	}

	async IsUserDeletedOrDisabled(UID: string) {
		return this.db
			.collection('UsersToClient')
			.where('UserUID', '==', UID)
			.limit(1)
			.get()
			.then(user => {
				var userDetails = user.docs[0].data();
				if (userDetails.IsDeleted === true || userDetails.IsDisabled === true) {
					return true;
				}
				else
					return false;
			});

	}

	async isClientExpired() {
		try {
			return this.db
				.collection('Clients')
				.where('FBID', '==', this.ClientID)
				.limit(1)
				.get()
				.then(client => {

					var clientDetails = client.docs[0].data();

					if (clientDetails.AccountExpiryDate === null || clientDetails.AccountExpiryDate === undefined) {
						console.log("no client expiry date found")
						//we decided to let pass users without an expired date set
						return false;
					}

					//this date needs to be in ms
					var expiryDate = clientDetails.AccountExpiryDate;
					var currentDate = Date.now();

					var objExpiryDate = new Date(expiryDate);
					var objCurrentDate = new Date(currentDate);

					return objExpiryDate < objCurrentDate;
				});
		} catch (error) {
			console.log(error)
			return false;
		}
	}

	getResourceCollection() {
		return this.baseQuery.collection('Resources').orderBy('Name', 'asc');
	}

	getResourceCollectionPaginated(itemsPerPage: number, canOnlyViewResourcesInTheirTeam: boolean, lastResource?: any, filters?: any) {
		let query = this.baseQuery.collection('Resources').orderBy('Name', 'asc');

		if (canOnlyViewResourcesInTheirTeam) {
			if (!isNullOrUndefined(filters['selectedTeam']) && filters['selectedTeam'] != '')
				query = query.where('Teams', 'array-contains', filters['selectedTeam']);
			else if (!isNullOrUndefined(filters['selectedTrade']) && filters['selectedTrade'] != '')
				query = query.where('Trades', 'array-contains', filters['selectedTrade']);
			else
				query = query.where('UsersPermittedToResource', 'array-contains', this.currentUser.uid);
		} else {
			if (!isNullOrUndefined(filters['selectedTeam']) && filters['selectedTeam'] != '')
				query = query.where('Teams', 'array-contains', filters['selectedTeam']);
			else if (!isNullOrUndefined(filters['selectedTrade']) && filters['selectedTrade'] != '')
				query = query.where('Trades', 'array-contains', filters['selectedTrade']);
		}

		if (lastResource) query = query.startAfter(lastResource);
		query = query.limit(itemsPerPage);

		return query;
	}

	getUnopenedJobs() {
		return this.baseQuery.collection('Jobs').where('IsOpened', '==', true);
	}

	getTeamCollection(canOnlyViewResourcesInTheirTeam: boolean) {
		let query = this.baseQuery.collection('Teams').orderBy('Name', 'asc');

		if (canOnlyViewResourcesInTheirTeam) {
			query = query.where('UsersInTeam', 'array-contains', this.currentUser.uid);
		}

		return query;
	}

	getTradesCollection() {
		return this.baseQuery.collection('Trades').orderBy('Name', 'asc');
	}

	getAssignedUserCollection() {
		return this.baseQuery.collection('AssignedUsers').orderBy('Name', 'asc');
	}

	getResourceDetails(resourceID) {
		return this.baseQuery.collection('Resources').doc(resourceID);
	}

	async assignResourceToJob(DocumentID: string, ResourceName: string, ResourceID: string) {
		let job;
		await this.getJob(DocumentID).then(data => {
			console.log(data.data());
			job = data.data();
		});

		let baseQuery = this.baseQuery
			.collection('Jobs')
			.doc(DocumentID);

		//If job has no resources, add selected resource to assignedToResource object.
		if (job.AssignedToResource != null) {
			if (job.AssignedToResource.ResourceName == null && job.AssignedToResourceMultiple == null) {
				baseQuery.update({
					AssignedToResource: { ResourceName: ResourceName, ResourceObjFBID: ResourceID },
					AssignedToResourceMultiple: null,
					IsMultipleResource: false,
				});
				return;
			};
		}

		//If job has one resource, set assignedtoresource null, assignedtoresource multiple the old one plus new one,#
		if (job.AssignedToResource != null) {
			if (job.AssignedToResource.ResourceName != null && job.AssignedToResourceMultiple == null) {
				baseQuery.update({
					AssignedToResource: null,
					AssignedToResourceMultiple: [{ ResourceName: ResourceName, ResourceObjFBID: ResourceID },
					{ ResourceName: job.AssignedToResource.ResourceName, ResourceObjFBID: job.AssignedToResource.ResourceObjFBID }],
					IsMultipleResource: true
				});
				return;
			}
		}

		let jobResourceArray = job.AssignedToResourceMultiple;
		jobResourceArray.push({ ResourceName: ResourceName, ResourceObjFBID: ResourceID });
		baseQuery.update({
			AssignedToResourceMultiple: jobResourceArray
		});
		return;
	}

	async resetResourceToJob(DocumentID: string) {
		return this.baseQuery
			.collection('Jobs')
			.doc(DocumentID)
			.update({
				AssignedToResource: { ResourceName: null, ResourceObjFBID: null },
				AssignedToResourceMultiple: null,
				IsMultipleResource: false,
				JobStatus: "Pending"
			});
	}

	async resetAssignedUserForJob(DocumentID: string) {
		return this.baseQuery
			.collection('Jobs')
			.doc(DocumentID)
			.update({
				AssignedToUser: null,
				AssignedUserID: null,
				AssignedUserObjFBID: null,
			});
	}

	async removeAttachedAsset(DocumentID: string) {
		return this.baseQuery
			.collection('AssetsToJobs')
			.doc(DocumentID)
			.delete();
	}

	async removeAttachedAssetFromForm(FormId: string, QuestionAnswerId: string, AssetFBID: string) {
		var parsedAsset;
		var asset = await this.baseQuery
			.collection('Forms')
			.doc(FormId)
			.collection('QuestionAnswers')
			.doc(QuestionAnswerId).get().then(asset => {
				parsedAsset = asset.data();
				if (parsedAsset && parsedAsset.Assets) {
					parsedAsset.Assets = parsedAsset.Assets.filter(asset => {
						if (asset.AssetFBID == AssetFBID)
							return false;
						else
							return true;
					})
				}
			});

		console.log(parsedAsset);

		return this.baseQuery
			.collection('Forms')
			.doc(FormId)
			.collection('QuestionAnswers')
			.doc(QuestionAnswerId)
			.update({
				Assets: parsedAsset.Assets
			}).then(res => {
				let fbid = generateFirebaseId();
				this.postToJobQueue(fbid, {
					AssetFBID: AssetFBID,
					FormFBID: FormId,
					QuestionFBID: QuestionAnswerId,
					DateCreated: Date.now(),
					JobAction: 'FormAnswer',
					QuestionType: 'Asset',
					RemoveAsset: true
				});
			})
	}

	async assignUserToJob(DocumentID: string, AssignedUserName: string, AssignedUserID: string) {
		return this.baseQuery
			.collection('Jobs')
			.doc(DocumentID)
			.update({
				AssignedToUser: AssignedUserName,
				AssignedUserObjFBID: AssignedUserID,
			});
	}

	async GetAppUpdateFlags() {
		const userData = this.db
			.collection('UsersToClient')
			.where('UserUID', '==', this.resourceFBID)
			.limit(1)
			.get();

		await userData.then(async userSettings => {
			if (userSettings.docs.length > 0 && userSettings.docs[0].exists) {
				const settings = userSettings.docs[0].data() as Store.UserSettings;
				if (settings.AppNeedsUpgrade === true) {
					await this.db.doc(`UsersToClient/${userSettings.docs[0].id}`).update({ AppNeedsUpgrade: false });
					console.log('New version is available. The update will begin once the ok button is clicked.');
					await window.location.reload();
					return;
				} else if (settings.SendAppVersion === true) {
					await this.db.doc(`UsersToClient/${userSettings.docs[0].id}`).update({ SendAppVersion: false });

					const data = {
						VersionNumber: Api.VERSION,
						DbVersionNumber: Api.INDEXEDDB_VERSION,
						UserUID: this.resourceFBID,
						deviceInfo: navigator.appVersion,
						triggeredBy: 'Auto',
					};

					let server = '';
					if (!isNullOrUndefined(this.settings) && !isNullOrUndefined(this.settings.ServerName)) {
						server = this.settings.ServerName;
					} else {
						if (!isNullOrUndefined(this.settings)) {
							await SendErrorData(
								this.settings.email,
								this.settings.UserUID,
								this.settings.ServerName,
								'settings or Servername for this user was undefined.',
								'',
								'',
							);
						}
					}

					await axios({
						data: data,
						method: 'post',
						url: getBaseURL(server) + '/api/upgradealert/managerapp',
						headers: ApiKeyObj,
					}).catch((err: AxiosError) => {
						if (!isNullOrUndefined(this.settings)) {
							SendErrorData(
								this.settings.email,
								this.settings.UserUID,
								this.settings.ServerName,
								err.response + ' ' + err.stack + ' ' + err.message,
								'',
								'',
							);
						}
					});

					return;
				}
			}
		});
	}

	async DeleteFromQuestionAnswer(PhotoFBID: string, Path: any) {
		return this.db.doc(Path + '/Photos/' + PhotoFBID).delete();
	}

	public answerQuestion(data: Forms.QuestionAnswer, customArgs?: object, DateTimeNow?: string) {
		const dataObj = {
			...customArgs,
			Answer: data.Answer,
			DocumentAnswer: data.DocumentAnswer,
			QuestionType: data.QuestionType,
			JobAction: 'FormAnswer',
			QuestionFBID: data.Id,
		};
		this.postToJobQueue(data.FormFBID, dataObj, DateTimeNow);
	}

	uploadDocumentForFormAnswer(document: File | Blob, documentName: string): firebase.storage.UploadTask {
		const uniqueFolderName = 'doc' + generateFirebaseId();
		const docPath = `${this.ClientID}/${this.currentUser.uid}/uploads/${uniqueFolderName}/${documentName}`;
		return this.ref.child(docPath).put(document);
	}

	public CreatePhotoRecordForQuestionAnswer(
		ref: string, photoObj: any, photoFBID: string
	): Promise<boolean> {
		return new Promise((resolve, reject) => {
			this.db.doc(ref + '/Photos/' + photoFBID)
				.set(photoObj)
				.then(res => resolve(true))
				.catch(() => resolve(false));
		})
	}

	getQuestionAnswerPhotoCollection(Path: any) {
		return this.db.doc(Path).collection('Photos');
	}

	public submitForm(
		formFBID: string,
		formRef: firebase.firestore.DocumentReference | null,
		Latitude: number | null,
		Longitude: number | null,
		CompletedDate?: number,
	) {
		const dataObj = {
			JobAction: 'SubmitForm',
			Latitude: Latitude,
			Longitude: Longitude,
			SubmittedDate: CompletedDate,
		};
		if (formRef !== null) {
			formRef.update({ Status: 'Submitted', CompletedDate: CompletedDate || Date.now(), IsCompleted: true });
		}
		this.postToJobQueue(formFBID, dataObj);
	}


	getFormsForJob(JobFBID: string) {
		return this.baseQuery.collection('Forms').where('JobFBID', '==', JobFBID);
	}

	getFormsForSite(SiteFBID: string) {
		return this.baseQuery.collection('Forms')
			.where('SiteFBID', '==', SiteFBID)
			.where('JobTaskFBID', '==', null)
			.where('JobFBID', '==', null)
			.where('AssetFBID', '==', null)
			.where('LocationFBID', '==', null)
			.where('SublocationFBID', '==', null)
	}


	getFormsForLocation(LocationFBID: string) {
		return this.baseQuery.collection('Forms').where('LocationFBID', '==', LocationFBID);
	}

	getFormsForSublocation(SublocationFBID: string) {
		return this.baseQuery.collection('Forms').where('SublocationFBID', '==', SublocationFBID);
	}

	getFormsForAsset(AssetFBID: string) {
		return this.baseQuery.collection('Forms')
			.where('AssetFBID', '==', AssetFBID)
			.where('JobTaskFBID', '==', null)
			.where('JobFBID', '==', null)
	}

	async getJobNumber(JobFBID: string) {
		let data = '';

		await this.baseQuery
			.collection('Jobs')
			.doc(JobFBID)
			.get()
			.then(data => {
				data = data.get('JobNumber');
			});

		return data;
	}

	async getFormSections(formTemplateFBID: string) {
		return this.baseQuery
			.collection('Forms')
			.doc(formTemplateFBID)
			.get();
	}

	async getFormTemplate(formTemplateFBID: string) {
		return this.baseQuery
			.collection('FormTemplates')
			.doc(formTemplateFBID)
			.get();
	}

	async getTasksforLoadedJobs(jobIds) {
		return this.baseQuery
			.collection('JobTasks')
			.where(firebase.firestore.FieldPath.documentId(), 'in', jobIds)
			.get();
	}

	async getJobTaskNumber(JobTaskFBID: string) {
		let taskdata = '';

		await this.baseQuery
			.collection('JobTasks')
			.doc(JobTaskFBID)
			.get()
			.then(data => {
				taskdata = data.get('JobTaskNumber');
			});

		return taskdata;
	}

	async addQRLookup(QRCode, FBID, Type) {

		let check = true

		await this.baseQuery
			.collection('QRLookup')
			.where('QRCode', '==', QRCode)
			.get()
			.then(qrCodes => {
				if (qrCodes.size > 0) {
					check = false;
				}
				// qrCodes.docs.map(qrCode => {
				// 	//
				// });
			});

		if (check) {
			this.baseQuery
				.collection('QRLookup')
				.add({
					QRCode: QRCode,
					Type: Type,
					LinkedFBID: FBID
				});
		}

		return check;
	}

	async getJob(JobFBID: string) {
		return this.baseQuery
			.collection('Jobs')
			.doc(JobFBID)
			.get();
	};

	async getJobSiteID(JobFBID: string) {
		let siteID = 0;

		await this.baseQuery
			.collection('Jobs')
			.doc(JobFBID)
			.get()
			.then(data => {
				siteID = data.get('SiteID');
			});

		return siteID;
	}

	async getJobSiteName(JobFBID: string) {
		let siteName = "";

		await this.baseQuery
			.collection('Jobs')
			.doc(JobFBID)
			.get()
			.then(data => {
				siteName = data.get('Site');
			});

		return siteName;
	}

	async getFormTemplateFBID(FormFBID: string) {
		return await this.baseQuery
			.collection('Forms')
			.doc(FormFBID)
			.get()
	}




	// async getFormTemplateFBID(FormTemplateFBID: string) {
	// 	let taskdata = '';

	// 	await this.baseQuery
	// 		.collection('JobTasks')
	// 		.doc(JobTaskFBID)
	// 		.get()
	// 		.then(data => {
	// 			taskdata = data.get('JobTaskNumber');
	// 		});

	// 	return taskdata;
	// }	

	public uploadPhotoForQuestionAnswer(
		imageBlob: Blob,
		documentName: string,
		reference: string,
		Guid: string,
		questionData: any,
		PhotoFBID: string,
	): Promise<boolean> {
		return new Promise((resolve, reject) => {
			const docPath = `${this.ClientID}/${this.currentUser.uid}/uploads/${documentName}`;
			this.ref
				.child(docPath)
				.put(imageBlob)
				.then(async firebaseRef => {
					await firebaseRef.ref.getDownloadURL()
						.then(async imgUrl => {
							let scuccess = true;
							const photoObj = {
								Guid: Guid,
								Filename: documentName,
								FirebaseRef: docPath,
								FirebaseStoragePath: imgUrl,
							};
							scuccess = await this.CreatePhotoRecordForQuestionAnswer(
								reference,
								photoObj,
								PhotoFBID
							);
							if (scuccess) {
								const DocumentAnswer = {
									FileName: documentName,
									DownloadUrl: imgUrl,
									PhotoFBID
								};
								this.answerQuestion(
									{
										...questionData,
										DocumentAnswer,
									},
									{
										PassFailTextBox: questionData.PassFailTextBox,
										PassFailDateTimeStamp: questionData.PassFailDateTimeStamp,
										UploadPhoto: true,
									},
								);
							}
							resolve(scuccess);
						})
				}).catch(() => resolve(false));
		})
	}

	getFormTemplatesCollection() {
		return this.baseQuery.collection('FormTemplates').where('Scope', '==', "FormLibrary").orderBy('FormName', 'asc');
	}

	getFormTemplateQuestions(TemplateFBID: string) {
		return this.baseQuery
			.collection('FormTemplates')
			.doc(TemplateFBID)
			.collection('Questions')
			.get();
	}

	async getQuestion(TemplateResponseFBID: string, QuestionFBID: string) {
		var get = await this.baseQuery
			.collection('Forms')
			.doc(TemplateResponseFBID)
			.collection('QuestionAnswers')
			.doc(QuestionFBID)
			.get();

		return get;
	}

	getFormTemplateQuestionsRepeatable(TemplateFBID: string, sectionName: string) {
		console.log(sectionName);
		return this.baseQuery
			.collection('FormTemplates')
			.doc(TemplateFBID)
			.collection('Questions')
			.where('Section', 'array-contains', { Name: sectionName })
			.get();
	}



	CreateForm(FormObj: any, FirebaseID: string) {
		this.baseQuery
			.collection('Forms')
			.doc(FirebaseID)
			.set(FormObj);
	}

	CreateFormQuestionAnswers(QuestionObj: any, FormFBID: string, QuestionAnswerFBID) {
		this.baseQuery
			.collection('Forms')
			.doc(FormFBID)
			.collection('QuestionAnswers')
			.doc(QuestionAnswerFBID)
			.set(QuestionObj);
	}

	UpdateFormTemplateFormStart(FormTemplateFBID: string, LastFormStartedOnManagerAppFBID: string | null) {
		// This is needed so that if form started on the manager app, then in the form library on the resource app it will update the form library straight away
		// This is possible as the "onstream" is triggered by a change on the FormTemplates document
		return this.baseQuery
			.collection('FormTemplates')
			.doc(FormTemplateFBID)
			.update({ LastFormStartedOnManagerAppFBID });
	}

	UpdateFormTemplateFormSubmit(FormTemplateFBID: string, LastFormSubmittedOnManagerAppFBID: string | null) {
		// This is needed so that if form started on the manager app, then in the form library on the resource app it will update the form library straight away
		// This is possible as the "onstream" is triggered by a change on the FormTemplates document
		this.baseQuery
			.collection('FormTemplates')
			.doc(FormTemplateFBID)
			.update({ LastFormSubmittedOnManagerAppFBID });
	}

	updateForm(formFBID: string, form: any) {
		this.baseQuery
			.collection('Forms')
			.doc(formFBID)
			.update(form);
	}

	UpdateFormLibrary(FormTemplateFBID: string, FormFBID: string | null, CurrentFormDate: number | null) {
		// NOT NEEDED NOW AS WE GET THE CURRENT FORM FROM THE COLLECTION OF FORMS, NOT FROM THE FORMFBID FOR
		// THE FORM TEMPLATE
		// this.baseQuery
		// 	.collection('FormTemplates')
		// 	.doc(FormTemplateFBID)
		// 	.update({ FormFBID, CurrentFormDate });
	}

	// private getFormHeader(formFBID: string) {
	// 	// return this.db.doc(`Forms/${formFBID}`).get();
	// 	return this.baseQuery.collection('Forms').doc(formFBID);
	// }

	loadAssets() {
		this.baseQuery
			.collection('Assets')
			.where('ClientFBID', '==', this.ClientID)
			.get();
	}

	getAssets() {
		//return this.baseQuery.collection('Assets').where('ClientFBID', '==', this.ClientID).orderBy('AssetName', 'asc');
		return this.baseQuery.collection('Assets').orderBy('AssetName', 'asc');
	}

	getAssetsPaginated(itemsPerPage: number, lastAsset: any = null, filters: any) {
		let query = this.baseQuery.collection('Assets').orderBy('AssetName');

		if (!isNullOrUndefined(filters['searchInput']) && filters['searchInput'] != '') {
			query = query.where('AssetCode', '==', filters['searchInput']);
		}

		if (!isNullOrUndefined(filters['assetClass']) && filters['assetClass'] != '') {
			query = query.where('AssetClassFBID', '==', filters['assetClass']);
		}

		if (!isNullOrUndefined(filters['assetSubClass']) && filters['assetSubClass'] != '') {
			query = query.where('AssetSubClassFBID', '==', filters['assetSubClass']);
		}

		if (!isNullOrUndefined(filters['site'])) {
			query = query.where('SiteID', '==', filters['site']);
		}

		if (!isNullOrUndefined(filters['location'])) {
			query = query.where('LocationID', '==', filters['location']);
		}

		if (!isNullOrUndefined(filters['subLocation'])) {
			query = query.where('SubLocationID', '==', filters['subLocation']);
		}

		if (lastAsset) {
			query = query.startAfter(lastAsset);
		}
		query = query.limit(itemsPerPage);

		return query;
	}

	getAssetsForSite(SiteID: any) {
		//return this.baseQuery.collection('Assets').where('ClientFBID', '==', this.ClientID).where('SiteID','==',SiteID).orderBy('AssetName', 'asc');
		return this.baseQuery
			.collection('Assets')
			.where('SiteID', '==', SiteID)
			.orderBy('AssetName', 'asc');
	}

	getAssetsForSitePaginated(SiteID: any, itemsPerPage: number, lastAsset?: any, filters?: any) {
		//console.log("getAssetsForSitePaginated ran.");
		let query = this.baseQuery
			.collection('Assets')
			.where('SiteID', '==', SiteID)
			.orderBy('AssetName');
		if (!isNullOrUndefined(filters['searchInput']) && filters['searchInput'] != '')
			query = query.where('AssetCode', '==', filters['searchInput']);
		if (!isNullOrUndefined(filters['assetClass']) && filters['assetClass'] != '')
			query = query.where('AssetClassFBID', '==', filters['assetClass']);
		if (!isNullOrUndefined(filters['assetSubClass']) && filters['assetSubClass'] != '')
			query = query.where('AssetSubClassFBID', '==', filters['assetSubClass']);
		if (!isNullOrUndefined(filters['site'])) query = query.where('SiteID', '==', filters['site']);
		if (!isNullOrUndefined(filters['location'])) query = query.where('LocationID', '==', filters['location']);
		if (!isNullOrUndefined(filters['subLocation'])) query = query.where('SubLocationID', '==', filters['subLocation']);
		if (lastAsset) query = query.startAfter(lastAsset);
		query = query.limit(itemsPerPage);

		return query;
	}

	async InitializeManagerClient(UserID: string, UserEmail: string, fromMergeApp?: boolean) {
		let userID = "";
		let clientID = "";
		await this.db
			.collection(`UsersToClient`)
			.where("email", "==", UserEmail)
			.limit(1)
			.get()
			.then(users => {
				users.docs.forEach(user => {
					userID = user.id;
					const data = user.data();
					clientID = data.ClientUID;
				});
			});

		const updateObject = {
			UserUID: UserID
		}
		await this.db
			.collection('UsersToClient')
			.doc(userID)
			.update(updateObject);
		if (!fromMergeApp) {
			await this.updateMergeAppCollection(UserEmail, updateObject);
		}

		return clientID;
	}

	async updateMergeAppCollection(userEmail: string, updateObj: any) {
		await this.db
			.collection(`AppUserToClient`)
			.where("Email", "==", userEmail)
			.limit(1)
			.get()
			.then(async (userResponse) => {
				if (!userResponse.empty) {
					const activeUserId = userResponse.docs[0].id;
					await this.db
						.collection(`AppUserToClient`)
						.doc(activeUserId)
						.update(updateObj)
				}
			});
		return "success";
	}

	async initializeMergeAppUser(userID: string, userEmail: string) {
		let clientID;
		await this.db
			.collection(`AppUserToClient`)
			.where("Email", "==", userEmail)
			.limit(1)
			.get()
			.then(async (userResponse) => {
				if (!userResponse.empty) {
					const activeUserId = userResponse.docs[0].id;
					const activeUserData = userResponse.docs[0].data();
					await this.db
						.collection(`AppUserToClient`)
						.doc(activeUserId)
						.update({
							UserUID: userID
						})

					if (activeUserData.CanAccessManagerApp) {
						clientID = await this.InitializeManagerClient(userID, userEmail, true);
					}
					if (activeUserData.CanAccessResourceApp) {
						clientID = await this.InitializeResourceClient(userID, userEmail, true);
					}
					if (activeUserData.CanAccessRequestApp) {
						clientID = await this.InitializeRequestorClient(userID, userEmail, true);
					}
				}
			})

		return clientID;
	}

	getSitesCollectionPaginated(
		itemsPerPage: number,
		canAccessAllSites: boolean,
		lastSite?: any, ContractFBID?: string | null
	) {
		let query = this.baseQuery.collection('Sites').orderBy('SiteName', 'asc');

		if (canAccessAllSites == false) {
			query = this.baseQuery.collection('Sites')
				.where('UsersPermittedToSite', 'array-contains', this.currentUser.uid);
		}

		if (!isNullOrUndefined(ContractFBID)) {
			query = query.where("ContractFBID", "==", ContractFBID);
		}

		if (lastSite) query = query.startAfter(lastSite);
		query = query.limit(itemsPerPage);

		return query;
	}

	getLocationsCollectionPaginated(itemsPerPage: number, siteFBID: string, lastResource?: any) {
		let query = this.baseQuery.collection('Locations').where("SiteFBID", "==", siteFBID).orderBy('LocationName', 'asc');
		if (lastResource) query = query.startAfter(lastResource);
		query = query.limit(itemsPerPage);

		return query;
	}

	getLocationsForUser() {
		const query = this.baseQuery
			.collection('Locations')
			.orderBy('LocationName')
			.where('UsersPermittedToLocation', 'array-contains', this.currentUser.uid)

		return query;
	}

	getSubLocationsCollectionPaginated(itemsPerPage: number, siteCode: number, lastResource?: any) {
		let query = this.baseQuery.collection('SubLocations').where("LocationID", "==", siteCode).orderBy('SubLocationName', 'asc');
		if (lastResource) query = query.startAfter(lastResource);
		query = query.limit(itemsPerPage);

		return query;
	}

	getSubLocationsForUser() {
		let query = this.baseQuery
			.collection('SubLocations')
			.orderBy('SubLocationName')
			.where('UsersPermittedToSubLocation', 'array-contains', this.currentUser.uid)

		return query;
	}


	async getSiteInfo(siteFBID) {
		return this.baseQuery
			.collection('Sites')
			.doc(siteFBID)
			.get();
	}

	async getSiteInfoFromID(siteID) {
		return this.baseQuery
			.collection('Sites')
			.where("SiteID", "==", siteID)
			.limit(1)
			.get()
	}

	async getLocationInfoFromID(locationCode) {
		return this.baseQuery
			.collection('Locations')
			.where("LocationID", "==", locationCode)
			.limit(1)
			.get()
	}

	async getLocationInfo(locationFbid: string) {
		return this.baseQuery
			.collection('Locations')
			.withConverter(fireLocationConverter)
			.doc(locationFbid)
			.get();
	}

	async getSubLocationInfo(subLocationFbid: string) {
		return this.baseQuery
			.collection('SubLocations')
			.doc(subLocationFbid)
			.get();
	}
	async getSubLocationInfoFromID(sublocationID) {
		return this.baseQuery
			.collection('SubLocations')
			.where("SubLocationID", "==", sublocationID)
			.limit(1)
			.get()
	}

	async QRSearch(input) {
		return this.baseQuery
			.collection('QRLookup')
			.where('QRCode', '==', input)
			.get();
	}

	async isQRDuplicate(QRCode: any, LinkedFBID: string) {
		if (QRCode == null || QRCode == "") {
			return false;
		};

		var isDuplicate = false;

		await this.baseQuery
			.collection('QRLookup')
			.where('QRCode', '==', QRCode)
			.get()
			.then(async qrcodes => {
				if (await qrcodes.docs.length == 1) {
					await qrcodes.docs.forEach(async qrcode => {
						if (await qrcode.data().LinkedFBID == LinkedFBID) {
							isDuplicate = false;
						}
						else
							isDuplicate = true;
					});
				};
				if (qrcodes.docs.length > 1)
					isDuplicate = true;
			});
		return isDuplicate;
	};



	async getServerName(ClientUID: string) {
		return this.db
			.collection('Clients')
			.doc(ClientUID)
			.get();
	}

	async clearNotifications(Jobs: any, UserUID: string) {

		for (let i = 0; i < Jobs.length; i++) {

			this.baseQuery
				.collection('Jobs')
				.doc(Jobs[i].Id)
				.update({
					UsersNotified: firebase.firestore.FieldValue.arrayRemove(UserUID)
				});
		}
	}

	async clearNotificationsForJob(Job: string, UserUID: string) {
		this.baseQuery
			.collection('Jobs')
			.doc(Job)
			.update({
				UsersNotified: firebase.firestore.FieldValue.arrayRemove(UserUID)
			});
	}

	getNotificationsPaginated(itemsPerPage: number) {
		let query = this.baseQuery.collection('Jobs').where('AssignedResource', '==', this.currentUser.uid);

		query = query.limit(itemsPerPage);

		query = this.baseQuery.collection('Jobs').where('UsersNotified', 'array-contains', this.currentUser.uid);

		return query;
	}

	async InitializeResourceClient(UserID: string, UserEmail: string, fromMergeApp?: boolean) {
		let userID = "";
		let clientID = "";

		await this.db
			.collection(`ResourcesToClient`)
			.where("Email", "==", UserEmail)
			.limit(1)
			.get()
			.then(users => {
				users.docs.forEach(user => {
					userID = user.id;
					const data = user.data();
					clientID = data.ClientUID;
				});
			});

		const updateObject = {
			UserUID: UserID
		}
		await this.db
			.collection('ResourcesToClient')
			.doc(userID)
			.update(updateObject);

		if (!fromMergeApp) {
			await this.updateMergeAppCollection(UserEmail, updateObject);
		}

		return clientID;
	}

	async isAssetCodeDuplicate(assetCode: any, assetFBID: any) {
		if (assetCode == null) {
			return false;
		};

		var isDuplicate = false;

		await this.baseQuery
			.collection('Assets')
			.where('AssetCode', '==', assetCode)
			.get()
			.then(async assets => {
				if (await assets.docs.length == 1) {
					await assets.docs.forEach(async asset => {
						if (await asset.data().AssetFBID == assetFBID) {
							isDuplicate = false;
						}
						else
							isDuplicate = true;
					});
				};
				if (assets.docs.length > 1)
					isDuplicate = true;
			});
		return isDuplicate;
	};

	getStorageAreasCollectionPaginated(itemsPerPage: number, lastStorageArea?: any, filters?: any) {
		let query = this.baseQuery.collection('StorageAreas').limit(itemsPerPage);
		if (filters) {
			// if (filters.search !== '') query = query.where('?', '==', filters.search);
			if (filters.SiteFBID) query = query.where('SiteFBID', '==', filters.SiteFBID);
			if (filters.LocationFBID) query = query.where('LocationFBID', '==', filters.LocationFBID);
			if (filters.SubLocationFBID) query = query.where('SubLocationFBID', '==', filters.SubLocationFBID);
		}
		query = query.orderBy('StorageName', 'asc')
		if (lastStorageArea) query = query.startAfter(lastStorageArea);
		return query;
	}

	getRequestsCollectionPaginated(
		canViewAllRequests: boolean,
		userID: string,
		itemsPerPage: number,
		lastRequest?: any,
		filters?: IRequestFilter,
	) {
		let query = this.baseQuery.collection('Requests').limit(itemsPerPage);
		if (filters) {
			if (filters.SiteFBID) query = query.where('SiteFBID', '==', filters.SiteFBID);
			if (filters.LocationFBID) query = query.where('LocationFBID', '==', filters.LocationFBID);
			if (filters.SubLocationFBID) query = query.where('SubLocationFBID', '==', filters.SubLocationFBID);
			if (filters.RequestStatusID) query = query.where('RequestStatusID', '==', parseInt(filters.RequestStatusID))
			if (filters.RequestNumber) query = query.where('RequestNumber', '==', filters.RequestNumber)
		}

		if (!canViewAllRequests) {
			query = query.where('UsersPermittedToRequest', 'array-contains', userID);
		}
		query = query.orderBy('RequestID', 'desc')
		if (lastRequest) query = query.startAfter(lastRequest);

		return query;
	}

	async UpdateQRCode(Collection: string, DocumentID: string, QR: string) {
		return this.baseQuery
			.collection(Collection)
			.doc(DocumentID)
			.update({
				QRCode: QR
			});
	}

	async getSelectOptions(
		Input: string,
		Collection: string,
		NameProperty: string,
		OrderDirection: 'desc' | 'asc',
		DependsOn: any,
		DependsOnField: string | undefined,
	) {
		if (DependsOn && DependsOnField) {
			return this.baseQuery
				.collection(Collection)
				.where(DependsOnField, '==', DependsOn.fields[DependsOnField])
				.where(NameProperty, '>=', Input)
				.where(NameProperty, '<=', Input + '\uf8ff')
				.orderBy(NameProperty, OrderDirection)
				.get();
		} else {
			return this.baseQuery
				.collection(Collection)
				.where(NameProperty, '>=', Input)
				.where(NameProperty, '<=', Input + '\uf8ff')
				.orderBy(NameProperty, OrderDirection)
				.get();
		}
	}

	async approveRequest(DocumentID: string) {
		console.log(DocumentID);

		return this.baseQuery
			.collection('Requests')
			.doc(DocumentID)
			.update({
				RequestStatus: "Approved"
			});


	}

	async InitializeRequestorClient(UserID: string, UserEmail: string, fromMergeApp?: boolean) {
		let userID = "";
		let clientID = "";
		console.log(UserID, UserEmail);
		await this.db
			.collection(`RequestorsToClient`)
			.where("email", "==", UserEmail)
			.limit(1)
			.get()
			.then(users => {
				users.docs.forEach(user => {
					userID = user.id;
					const data = user.data();
					console.log(data);
					clientID = data.ClientUID;
				});
			});
		const updateObject = {
			UserUID: UserID
		}
		await this.db
			.collection('RequestorsToClient')
			.doc(userID)
			.update(updateObject);
		if (!fromMergeApp) {
			await this.updateMergeAppCollection(UserEmail, updateObject);
		}

		return clientID;
	};

	getLanguages = (lastLanguage?: any) => {
		let query = this.db.collection('Languages').orderBy('Location', 'asc');
		if (lastLanguage)
			query = query.startAfter(lastLanguage);

		query = query.limit(20);
		return query;
	};

	async updateUserLanguage(userUID: string | undefined, newLanguage: string) {
		return this.db
			.collection("UsersToClient")
			.where("UserUID", "==", this.currentUser.uid)
			.limit(1)
			.get().then(userRecord => {
				var userDocument = userRecord.docs[0].id;
				this.db.collection("UsersToClient").doc(userDocument).update({ "Language": newLanguage });
			})
	};

	async getUserLanguage(userUID: string | undefined) {
		return this.db
			.collection("UsersToClient")
			.where("UserUID", "==", userUID)
			.limit(1)
			.get();
	};

	async getLanguage(languageLabel: string | undefined) {
		return this.db
			.collection("Languages")
			.where("LanguageLabel", "==", languageLabel)
			.limit(1)
			.get();
	};

	async getQRLookup(QRLookupFBID: string) {
		return this.db
			.collection('QRLookup')
			.doc(QRLookupFBID)
			.get();
	}

	async getAvailableForms() {
		return this.baseQuery
			.collection('FormTemplates')
			.where('Scope', '==', 'JobForm')
			.orderBy('FormName')
			.get()
	}

	async DeclineRequest(DocumentID: string) {
		return this.baseQuery
			.collection('Requests')
			.doc(DocumentID)
			.update({
				RequestStatus: 'Declined'
			});
	}

	async getContractorsForJob(DocumentID: string) {
		return this.baseQuery
			.collection('Jobs')
			.doc(DocumentID)
			.get();
	}

	async getExtraFormDetails(form: Forms.Form): Promise<Forms.ExtraFormDetails> {
		let assetName: string | null = null;
		let siteName: string | null = null;
		let subLocationName: string | null = null;
		let locationName: string | null = null;
		if (form.AssetFBID) {
			const assetDoc = (
				await this.baseQuery
					.collection('Assets')
					.doc(form.AssetFBID)
					.withConverter(fireAssetConverter)
					.get()
			).data();
			assetName = assetDoc ? assetDoc.AssetName : '';
		}
		if (form.SiteFBID) {
			const siteDoc = (
				await this.baseQuery
					.collection('Sites')
					.doc(form.SiteFBID)
					.withConverter(fireAssetConverter)
					.get()
			).data();
			siteName = siteDoc ? siteDoc.SiteName : '';
		}
		if (form.LocationFBID) {
			const locationDoc = (
				await this.baseQuery

					.collection('Locations')
					.doc(form.LocationFBID)
					.withConverter(fireAssetConverter)
					.get()
			).data();
			locationName = locationDoc ? locationDoc.LocationName : '';
		}
		if (form.SublocationFBID) {
			const subLocationDoc = (
				await this.baseQuery
					.collection('SubLocations')
					.doc(form.SublocationFBID)
					.withConverter(fireAssetConverter)
					.get()
			).data();
			subLocationName = subLocationDoc ? subLocationDoc.SubLocationName : '';
		}
		return {
			...form,
			AssetName: assetName,
			SiteName: siteName,
			LocationName: locationName,
			SubLocationName: subLocationName,
		};
	}

	getFormTemplateSections(TemplateFBID: string) {
		return this.baseQuery
			.collection('FormTemplates')
			.doc(TemplateFBID)
			.get();
	}

	getFormTemplates = (): Promise<any> => {
		return new Promise((resolve, reject) => {
			this.baseQuery
				.collection('FormTemplates')
				.where('Scope', '==', 'JobForm')
				.orderBy("FormName")
				.get().then(async res => {
					if (res.empty) {
						resolve([])
					} else {
						for (let doc of res.docs) {
							await doc.ref.collection('Questions').get()
							await doc.ref.collection('QuestionAnswers').get()
						}
					}
					resolve(res)
				}).catch(err => reject(err))
		})
	}


	getFormsForLoadedData = (itemIDs, formFieldRef, fbCollection: string): Promise<any> => {
		return new Promise((resolve, reject) => {
			let query = this.baseQuery.collection('Forms')
				.where(`${formFieldRef}`, 'in', itemIDs);

			if (fbCollection === "Site") {
				query = query.where('JobTaskFBID', '==', null)
					.where('JobFBID', '==', null)
					.where('AssetFBID', '==', null)
					.where('LocationFBID', '==', null)
					.where('SublocationFBID', '==', null);
			}

			if (fbCollection === "Asset") {
				query = query
					.where('JobTaskFBID', '==', null)
					.where('JobFBID', '==', null);
			}

			query.get()
				.then((responseData) => {
					if (responseData.empty) {
						resolve([]);
					} else {
						responseData
							.forEach(documentRes => {
								documentRes.ref
									.collection('QuestionAnswers')
									.get()
									.then(async doc => {
										for (const docItem of doc.docs) {
											let formatedData;
											formatedData = docItem.data() as Forms.QuestionAnswer;
											formatedData.Id = docItem.id;
											formatedData.questionRef = docItem.ref;
											if (formatedData.QuestionType === 'Photo') {
												await idb.getPhotos(formatedData.Id);
												await this.getQuestionAnswerPhotoCollection(
													formatedData.questionRef.path)
													.get()
													.then(async res => {
														for (const formDoc of res.docs) {
															const photo = formDoc.data() as Job.JobPhoto;
															await idb
																.downloadQuestionAnswerPhotoFromFirebase(
																	photo.FirebaseStoragePath
																		? photo.FirebaseStoragePath
																		: (photo.AzureUrl as string),
																	formatedData.Id,
																	photo.Guid
																		? (photo.Guid as string) :
																		generateGuid(),
																	formDoc.id,
																	photo.Filename as string,
																	"true",
																	documentRes.id,
																	fbCollection
																);
														}
													})
											}
										};
									})
							})
						resolve(responseData)
					}
				}).catch(err => reject(err))
		})
	}

	getLocationsForLoadedSite = (siteFBIDs): Promise<any> => {
		return new Promise((resolve, reject) => {
			this.baseQuery.collection('Locations')
				.where('SiteFBID', 'in', siteFBIDs)
				.get()
				.then((res) => {
					const locations = res && res.docs.map(_loc => ({
						..._loc.data(),
						Id: _loc.id
					}))
					resolve(locations);
				})
		})
	}

	getSubLocationsForLoadedSite = (locationFBIds): Promise<any> => {
		return new Promise((resolve, reject) => {
			this.baseQuery.collection('SubLocations')
				.where('LocationID', 'in', locationFBIds)
				.get()
				.then((res) => {
					const subLocations = res && res.docs.map(_loc => ({
						..._loc.data(),
						Id: _loc.id
					}))
					resolve(subLocations);
				})
		})
	}
}
