export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_ERROR = 'LOGOUT_USER_ERROR';

export const UPDATE_USER = 'UPDATE_USER';

export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';

export const UPDATE_AVAILABLE = 'UPDATE_AVAILABLE';

export function loginUser() {
	return dispatch => {
		dispatch({
			type: LOGIN_USER,
		});
	};
}

export function loginUserError(error: string) {
	return dispatch => {
		dispatch({
			type: LOGIN_USER_ERROR,
			payload: error,
		});
	};
}
export function loginUserSuccess(user: firebase.default.User) {
	return dispatch => {
		dispatch({
			type: LOGIN_USER_SUCCESS,
			payload: user.uid,
		});
	};
}

export function updateUser(user: firebase.default.User) {
	return dispatch => {
		dispatch({
			type: UPDATE_USER,
			payload: user,
		});
	};
}

export function setUserSettings(userSettings: Store.UserSettings) {
	return dispatch => {
		dispatch({
			type: SET_USER_SETTINGS,
			payload: userSettings,
		});
	};
}

export function updateAvailable(available: boolean) {
	return dispatch => {
		dispatch({
			type: UPDATE_AVAILABLE,
			payload: available,
		});
	};
}
