import { useEffect, useState } from 'react';
import { fire, idb } from '..';

const useDocumentTags = () => {
	const [tags, setTags] = useState<indexDb.DocumentTag[]>([]);

	useEffect(() => {
		fire
			.getDocumentTagsFiltered()
			.get()
			.then(tags => {
				if (tags.empty) setTags([]);
				else
					setTags(
						tags.docs.map(doc => {
							let data = doc.data();
							let documentTag: indexDb.DocumentTag = {
								tagFBID: data.TagFBID,
								tagName: data.TagName,
								tagAvailability: data.TagAvailability,
							};
							return documentTag;
						}),
					);
			});
	}, []);

	const addTagToDocument = async (
		tag: indexDb.DocumentTag,
		collection: string,
		associatedFBID: string,
		selectedDocumentGuid: string,
		callback?: () => void,
	) => {
		await fire.addTagToDocument(tag.tagFBID, collection, associatedFBID, selectedDocumentGuid);
		await idb.addPhotoTags(selectedDocumentGuid, tag);
		callback ? callback() : null;
	};

	return {
		tags,
		addTagToDocument,
	};
};

export default useDocumentTags;
