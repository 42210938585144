// React
import * as React from 'react';

// Firebase
import { fire } from '../../index';

// Signature Pad
import SignaturePad from 'signature_pad';

// Material UI
import { Button, Icon } from '@material-ui/core';

// Components
import QuestionLayout from './QuestionLayout';

// Utils
import { downloadFile, savePhoto, getPhoto } from '../../utils/forms/FormQuestionHelpers';
import { isIos, isIOS13 } from '../../utils/IsIos';
import { isNullOrUndefined } from 'util';
import { withTranslation } from 'react-i18next';

interface SignatureQuestionProps {
	data: Forms.QuestionAnswer;
	formComplete: boolean;
	formId: string;
	t: any;
}

interface SignatureQuestionState {
	viewMode: boolean;
	answer: {
		DownloadUrl: string | null;
		FileName: string | null;
	};
}

 class SignatureQuestion extends React.PureComponent<SignatureQuestionProps, SignatureQuestionState> {
	private signaturePad: SignaturePad | null = null;
	private signatureCanvas: HTMLCanvasElement | null = null;
	private isIos: boolean;

	constructor(props: Readonly<SignatureQuestionProps>) {
		super(props);
		this.isIos = isIos() && !isIOS13();
		this.state = {
			answer: this.props.data.DocumentAnswer,
			viewMode: true,
		};
	}

	UNSAFE_componentWillReceiveProps(newProps: SignatureQuestionProps) {
		if (newProps.data.DocumentAnswer !== this.props.data.DocumentAnswer && newProps.data.DocumentAnswer !== null) {
			// const viewMode = newProps.data.DocumentAnswer.DownloadUrl !== null;
			// this.setState({
			// 	// answer: newProps.data.DocumentAnswer,
			// 	viewMode,
			// });
			// alert("UNSAFE_componentWillReceiveProps " + viewMode);
			getPhoto(
				this.props.data.Id,
				this.covertToBlobAndSave,
				newProps.data.DocumentAnswer.DownloadUrl,
				newProps.data.DocumentAnswer.FileName,
				this.props.formComplete.toString(),
				this.props.formId,
			);
		}
	}

	componentDidMount() {
		//this.initializeSignaturePad();

		const downloadUrl = isNullOrUndefined(this.props.data.DocumentAnswer)
			? null
			: this.props.data.DocumentAnswer.DownloadUrl;
		const fileName = isNullOrUndefined(this.props.data.DocumentAnswer) ? null : this.props.data.DocumentAnswer.FileName;

		getPhoto(
			this.props.data.Id,
			this.covertToBlobAndSave,
			downloadUrl,
			fileName,
			this.props.formComplete.toString(),
			this.props.formId,
		);
	}

	initializeSignaturePad = () => {
		this.signatureCanvas = document.getElementById(`sig-question-${this.props.data.QuestionID}`) as HTMLCanvasElement;

		if (this.signatureCanvas !== null && this.signatureCanvas.parentElement !== null) {
			this.signatureCanvas.width = this.signatureCanvas.parentElement.clientWidth;
			this.signatureCanvas.height = this.signatureCanvas.parentElement.clientHeight;


			if (this.signaturePad == null)
			{
				 this.signaturePad = new SignaturePad(this.signatureCanvas);
				 //alert("spad on");
			}

			if (this.state.viewMode) {
				this.signaturePad.off();
			}
		}
	};

	covertToBlobAndSave = (doc: ArrayBuffer, fileName?: string) => {
		if (doc.byteLength !== new ArrayBuffer(0).byteLength) {
			const blob = new Blob([doc]);
			const blobUrl = URL.createObjectURL(blob);
			this.setState({
				...this.state,
				answer: { FileName: fileName || '', DownloadUrl: blobUrl },
			});
		} else {
			this.setState({
				...this.state,
				answer: { FileName: fileName || '', DownloadUrl: null },
			});
		}
	};

	saveSignature = async () => {
		
		//first initialise the signature page in case it has not been done already
		if (this.signaturePad == null)
		{			
			this.signatureCanvas = document.getElementById(`sig-question-${this.props.data.QuestionID}`) as HTMLCanvasElement;
			if (this.signatureCanvas !== null && this.signatureCanvas.parentElement !== null) {
				this.initializeSignaturePad();
			}
		}

		//this means the edit button was clicked
		if (this.state.viewMode && this.signaturePad !== null) {
			this.setState({ viewMode: !this.state.viewMode });
			this.signaturePad.on();
			this.signaturePad.clear();
			return;
		}

		if (this.signaturePad !== null) {
			this.setState({ viewMode: !this.state.viewMode });
			const signatureUrl = this.signaturePad.toDataURL('base64');

			this.signaturePad.off();
			const FileName = `signature-${Date.now()}.jpg`;

			const blob = await fetch(signatureUrl).then(resp => resp.blob());
			const blobUrl = URL.createObjectURL(blob);

			const DocumentAnswer = {
				FileName: FileName,
				DownloadUrl: blobUrl,
			};

			this.setState({
				answer: DocumentAnswer,
			});

			savePhoto(
				blob,
				this.props.data,
				FileName,
				undefined,
				this.state,
				this.props.formComplete.toString(),
				this.props.formId,
			);

			fire.UpdateFormAfterAnswer(this.props.data.FormFBID);

			this.props.data.questionRef.update({ HasPhotosAttached: true });
		}
	};

	updateFirebase = (DocumentAnswer: { FileName: string; DownloadUrl: string }) => {
		//update question in firebase
		this.props.data.questionRef
			.update({ DocumentAnswer })
			.catch(err => console.error(this.props.t("Error updating answer - ") + err));

		//add answer to job queue
		fire.answerQuestion({ ...this.props.data, DocumentAnswer });

		//alert(this.props.data.FormFBID);

		fire.UpdateFormAfterAnswer(this.props.data.FormFBID); 
	};

	downloadFile = () => {
		if (this.state.answer)
		{
		if (this.state.answer.DownloadUrl === null || this.state.answer.FileName === null) return;
		downloadFile(this.state.answer.DownloadUrl, this.state.answer.FileName);
		}
	};

	render() {
		const imageUrl =
			this.state.answer !== null && this.state.answer.DownloadUrl !== null ? this.state.answer.DownloadUrl : '';

		let isOffline = false;

		if (this.state.answer != null)
		{
		   if (this.props.formComplete && this.state.answer.FileName === 'unavailable')
			 	isOffline = true;
		}

		if (isOffline) {
			return (
				<QuestionLayout>
					{this.props.data.QuestionTitle}
					<div className="signature-question-container">
						<p style={{ color: 'var(--hot-pink)' }}>{this.props.t("Signature not available when offline")}</p>
					</div>
				</QuestionLayout>
			);
		} else {
			return (
				<QuestionLayout>
					{this.props.data.QuestionTitle}
					<div id="signature-question-container">
						<div className="image-container" id={'pholder'}> 
							<img src={imageUrl} hidden={!this.state.viewMode} />
							<canvas id={`sig-question-${this.props.data.QuestionID}`} hidden={this.state.viewMode} />

						</div>
						<Button
							className="upload-Btn"
							variant="outlined"
							color="inherit"
							size="small"
							onClick={() => this.saveSignature()}
							style={{ display: `${this.props.formComplete ? 'none' : 'inline-flex'}` }}
						>
							<Icon className="button-icon" fontSize="small">
								{this.state.viewMode ? 'edit' : 'save'}
							</Icon>
							{this.state.viewMode ? this.props.t("Edit") : this.props.t("Save")}
						</Button>
						<Button
							className={`upload-Btn ${this.isIos ? 'hidden' : ''}`}
							variant="outlined"
							color="inherit"
							size="small"
							onClick={() => {
								if (this.state.viewMode) {
									this.downloadFile();
								} else {
									this.signaturePad !== null ? this.signaturePad.clear() : null;
									this.props.data.questionRef.update({ HasPhotosAttached: true });
								}
							}}
						>
							<Icon className="button-icon" fontSize="small">
								{this.state.viewMode ? 'cloud_download' : 'clear'}
							</Icon>
							{this.state.viewMode ? this.props.t("Download") : this.props.t("Clear")}
						</Button>
					</div>
				</QuestionLayout>
			);
		}
	}
}

export default withTranslation()(SignatureQuestion)
