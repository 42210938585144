// React
import * as React from 'react';

interface FieldHolderProps {
	pullRight?: boolean;
	fullLine?: boolean;
	label: string;
	content: string;
}

const FieldHolder = (props: FieldHolderProps) => (
	<div className={`${props.pullRight ? 'pull-right' : ''}${props.fullLine ? 'full-line' : ''} field`}>
		<p className="field-content">{props.content}</p>
		<p className="field-label">{props.label}</p>
	</div>
	
);


export default FieldHolder;


