export enum CalendarViews {
    Day = "day",
    Week = "week",
    Month = "month",
    Agenda = "agenda"
}

export enum LocalStrorageVariables {
    CalendarView = "calendarView",
    CalendarDate = "calendarDate"
}