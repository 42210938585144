// React
import * as React from 'react';
import { History } from 'history';
import { match } from 'react-router';
import { connect } from 'react-redux';
import { isNullOrUndefined } from 'util';
import firebase from 'firebase';
// Load Image for Saving files and Compressing them
import * as loadImage from 'blueimp-load-image';
// Config File
// @ts-ignore
import Api from '../../apiConfiguration.json';
// Firebase + indexedDB + localQueue
import { fire, idb, localQueue } from '../../index';
// Offline Not Availble Image
import imgNotAvailable from '../../assets/images/not-available.jpg';
// Material UI
import { Button, Grid, Icon, IconButton } from '@material-ui/core';
import TouchAppIcon from '@material-ui/icons/TouchApp';

// Components
import CompleteJobModal from '../../components/job/CompleteJobModal';
import InstructResourceButtons from '../../components/job/InstructResourceButtons';
import AssignedUserButtons from '../../components/job/AssignedUserButtons';
import InstructJobModal from '../../components/job/InstructJobModal';
import SideDrawer from '../../components/shared/SideDrawer';
import AddNoteDialogPopup from '../../components/job/AddNoteDialogPopup';
import LoadingSpinner from '../../components/Spinners/LoadingSpinner';
import BackButton from '../../components/shared/BackButton';
import NotesCard from '../../components/notes/NotesCard';
import JobPhotos from '../../components/job/JobPhotos';
import FormsList from '../../components/forms/FormsList';
import GoogleMapsModal from '../../components/shared/GoogleMapsModal';
import AssetJobListNew from '../../components/asset/AssetJobListNew';
import JobTaskPhotosCard from '../../components/job/JobTaskPhotosCard';

// Utils
import { parseDateForDateTimeFields, stringtoUnixUTC, unixToDateString } from '../../utils/Times';
import { CanAssignResource, CanInstructResource } from '../../utils/JobDetails';
import { SendErrorData, windowError } from '../../utils/WindowError';
import { GetFileCompressionRatio } from '../../utils/FileCompressionRatio';
import { getBaseURL } from '../../utils/getBaseURL';
import { getLocation } from '../../utils/Geolocation';
import ApiKeyObj from '../../utils/ApiObjectKey';
import { dynamicSort } from '../../utils/Sorting';
import { generateFirebaseId } from '../../utils/Guids';
import { fileToArrayBuffer } from '../../utils/Converters';
import { LocalstorageRemoveItem } from '../../utils/LocalStorage';
import { areAllFormsSubmitted, orderAlphabetically } from '../../utils/forms/FormHelpers';
import { JOB_DOCUMENT_SOURCE_FROM_APP } from '../../utils/database/indexdb/IndexDb';
import { BusinessTypes } from '../../utils/shared';

import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { fireLocationConverter, fireSiteConverter } from '../../utils/FirebaseConverters';
import DocumentsCard from '../../components/shared/DocumentsCard';

// CSS
import '../../styles/job/job-details.css';
import '../../styles/job/job-details-alt.css';

interface Notes {
	Id: string;
	CreatedBy: string;
	DateAdded: number;
	DateAddedString: string;
	Note: string;
	AddedByContractor: boolean | null;
	TimeStamp: number | null;
	statusColor: string;
}

interface JobDetailsScreenProps {
	history: History;
	match: match;
	UserSettings: Store.UserSettings;
	t: any;
}

interface JobDetailsScreenState {
	jobDetails: Job.Job | null;
	DocumentID: string;
	noteDialogOpen: boolean;
	completeJobDialogOpen: boolean;
	instructJobDialogOpen: boolean;
	newNote: string;
	completeJobNote: string;
	notes: Notes[];
	photos: Job.JobPhoto[];
	categorisedPhotos: { [id: string]: Job.JobPhoto[] };
	ExpectedDate: string;
	isComplete: string;
	googleMapDialogOpen: boolean;
	Forms: Forms.Form[];
	AssetsAttached: string | null;
	AssetsIDs: string[] | null;
	AssetsArr: Asset[];
	fromAssetFBID: string;
	AvailableForms: FormTemplates.FormTemplate[];
	SiteFBID: string;
	LocationFBID: string;
	disableCompleteJob: boolean;
	isSeparateTask: boolean;
	isSharedTask: boolean;
	expand: boolean;
	photosExpand: boolean;
	notesExpand: boolean;
	syncDocsLoading: boolean;
}

class JobDetailsScreen extends React.Component<JobDetailsScreenProps, JobDetailsScreenState> {
	constructor(props) {
		super(props);

		this.state = {
			jobDetails: null,
			DocumentID: '',
			noteDialogOpen: false,
			completeJobDialogOpen: false,
			instructJobDialogOpen: false,
			newNote: '',
			completeJobNote: '',
			notes: [],
			photos: [],
			categorisedPhotos: {},
			ExpectedDate: '',
			isComplete: '',
			googleMapDialogOpen: false,
			Forms: [],
			AssetsAttached: '',
			AssetsIDs: [],
			AssetsArr: [],
			fromAssetFBID: '',
			// @ts-ignore
			AvailableForms: [],
			SiteFBID: '',
			LocationFBID: '',
			disableCompleteJob: false,
			isSeparateTask: true,
			isSharedTask: false,
			expand: false,
			photosExpand: false,
			notesExpand: false,
			syncDocsLoading: false
		};
		this.getPhotos = this.getPhotos.bind(this);
	}

	componentWillMount() {
		windowError(
			this.props.UserSettings.email,
			this.props.UserSettings.UserUID,
			this.props.UserSettings.ServerName,
			'JobDetailsScreen',
		);
	}

	async componentDidMount() {
		const { id, fromassetfbid } = this.props.match.params as any;
		await this.handleComponentLoad(id);
		this.getAvailableForms();

		if (this.props.UserSettings.CanSeeNotificationBell) {
			fire.clearNotificationsForJob(this.state.DocumentID, this.props.UserSettings.UserUID);
		};

		if (!isNullOrUndefined(fromassetfbid)) {
			this.setState({ fromAssetFBID: fromassetfbid });
		} else {
		}

		this.updateIsOpened();

		// fetch documents and photos
		this._syncFromFireBase(id);
		this.getPhotos();
		this.watchPhotos();
	}

	updateIsOpened() {
		const { id } = this.props.match.params as any;
		fire.updateIsOpened(id);
	}

	toggleIsSeparateTask = value => {
		console.log(value);
		if (this.state.isSharedTask == true)
			this.setState({ isSharedTask: false })

		this.setState({ isSeparateTask: !this.state.isSeparateTask })
	}

	toggleIsSharedTask = value => {
		console.log(value);
		if (this.state.isSeparateTask == true)
			this.setState({ isSeparateTask: false })

		this.setState({ isSharedTask: !this.state.isSharedTask })
	}

	async componentWillReceiveProps(newProps: JobDetailsScreenProps) {
		const { id } = newProps.match.params as any;
		await this.handleComponentLoad(id);
	}

	async componentWillUnmount() {
		const { id } = this.props.match.params as any;
	}

	async handleComponentLoad(id: string) {
		this.setState({ DocumentID: id });
		const job = fire.getDocumentQuery('Jobs', id);

		job.onSnapshot(jobObj => {
			const Job = jobObj.data() as Job.Job;
			this.setState({
				jobDetails: Job,
				ExpectedDate: parseDateForDateTimeFields(Job.ExpectedByDate),
			});
		});

		let iscomplete = '';
		await job.get().then(data => {
			const Job = data.data() as Job.Job;
			if (!isNullOrUndefined(Job)) {
				iscomplete = !isNullOrUndefined(Job.CompletedDate) ? 'true' : 'false';
				this.setState({
					isComplete: iscomplete,
				});
			}
		});

		this.getSiteLocationSubLocationLinks();
		this.getJobNotes(id);
		this.getAssetIDsNew(id);
		fire.getFormsForJob(id).get().then(this.handleForms);

	}


	getSiteLocationSubLocationLinks() {
		let siteArray: Site[] = [];
		if (!isNullOrUndefined(this.state.jobDetails)) {
			fire.baseQuery
				.collection('Sites')
				.where('SiteID', '==', this.state.jobDetails.SiteID)
				.withConverter(fireSiteConverter)
				.get()
				.then(site => {
					siteArray = site.docs.map(siteJob => {
						const data = siteJob.data();
						if (data.SiteFBID != null) {
							this.setState({ SiteFBID: data.SiteFBID });
						}
						return data;
					});
				});
		}

		if (!isNullOrUndefined(this.state.jobDetails)) {
			let locationArray: Location[] = [];
			fire.baseQuery
				.collection('Locations')
				.where('LocationID', '==', this.state.jobDetails.LocationID)
				.withConverter(fireLocationConverter)
				.get()
				.then(location => {
					locationArray = location.docs.map(locationJob => {
						const data = locationJob.data();
						if (data.ID != null) {
							this.setState({ LocationFBID: data.ID });
						}
						return data;
					});
				});
		}
	}

	handleForms = async (querySnapshot: firebase.firestore.QuerySnapshot) => {
		if (querySnapshot.empty) return this.setState({ Forms: [] });

		const formsArray = await querySnapshot.docs.map(async doc => {
			const data = doc.data() as Forms.Form;
			data.Id = doc.id;

			data.JobTaskNumber = '';
			// if (!isNullOrUndefined(data.JobTaskFBID)) {
			// 	data.JobTaskNumber = await fire.getJobTaskNumber(data.JobTaskFBID);
			// } else {
			// 	data.JobTaskNumber = '';
			// }			
			return data;
		});

		Promise.all(formsArray).then(forms => {
			//preload question answers for forms
			querySnapshot.docs.forEach(doc => doc.ref.collection('QuestionAnswers').get());

			this.setState({ Forms: this.sortingFunction(forms) });
		});
	};

	sortingFunction = (formsList: any) => {
		return formsList.sort((a, b) => {
			if (a.CreatedDate && b.CreatedDate) {
				if (a.CreatedDate === b.CreatedDate) return 0;
				else if (a.CreatedDate === 0) return -1;
				else if (b.CreatedDate === 0) return 1;
				else if (a.CreatedDate < b.CreatedDate) return 1;
				else if (b.CreatedDate < a.CreatedDate) return -1;
				else return 0;
			}
			else { return -1 }
		});
	};

	getAvailableForms = () => {
		fire.baseQuery
			.collection('FormTemplates')
			.where('Scope', '==', 'JobForm')
			.orderBy('FormName')
			.get()
			.then((querySnapshot: firebase.firestore.QuerySnapshot) => {
				// @ts-ignore
				if (querySnapshot.empty) return this.setState({ AvailableForms: [] });

				// @ts-ignore
				let formsArray: FormTemplates.FormTemplate[] = [];
				querySnapshot.docs.forEach(doc => {
					const data = doc.data() as FormTemplates.FormTemplate;
					data.Id = doc.id;
					formsArray.push(data);
				});

				//preload question answers for forms
				querySnapshot.docs.forEach(doc => doc.ref.collection('QuestionAnswers').get());

				//sort forms
				this.orderAlphabeticallyArray(formsArray)

				this.setState({ AvailableForms: formsArray });
			});
	};

	orderAlphabeticallyArray(list: FormTemplates.FormTemplate[]) {
		return list.sort((a, b) => orderAlphabetically(a.FormName.toLowerCase(), b.FormName.toLowerCase()));
	}

	watchPhotos() {
		idb.db.jobphotos.hook('creating', (_primaryKey, photo) => {
			return this.addPhoto(photo);
		});

		idb.db.documents.hook('creating', (_primaryKey, document) => {
			let photo = idb.parseDocumentToPhoto(document);
			if (!isNullOrUndefined(photo)) this.addPhoto(photo);
		});
	}

	getPhotos() {
		const { id } = this.props.match.params as any;
		idb.getPhotos(id).then(photos => {
			this.setState({
				photos: [],
				categorisedPhotos: {}
			}, () => {
				if (photos.length > 0) {
					photos.forEach(photo => {
						this.addPhoto(photo);
					});
				}
			})
		});
	}

	private async _syncFromFireBase(documentId) {
		if (navigator.onLine) {
			this.setState({ syncDocsLoading: true });
			await idb.syncDoumentsAndPhotosFromFb(documentId, `Jobs`, "", this.getPhotos);
			this.setState({ syncDocsLoading: false });
		}
	}

	addPhoto(photo: indexDb.Photos) {
		let photos = this.state.photos;
		let photoURL = imgNotAvailable;
		let categorisedPhotos = this.state.categorisedPhotos;
		categorisedPhotos[photo.jobTaskNumber || ''] = categorisedPhotos[photo.jobTaskNumber || ''] || [];
		if (photo.file.byteLength !== new ArrayBuffer(0).byteLength) {
			photoURL = URL.createObjectURL(new Blob([photo.file]));
		}

		let jobPhoto = {
			Guid: photo.guid,
			FirebaseStoragePath: photoURL,
			Filename: photo.fileName,
			UploadedBy: photo.uploadedBy,
			Source: photo.source,
			DateCreated: photo.dateCreated,
		};

		if (!photos.some(existingPhoto => existingPhoto.Guid === photo.guid)) {
			photos.push(jobPhoto);
		}
		if (!categorisedPhotos[photo.jobTaskNumber || '']
			.some(existingPhoto => existingPhoto.Guid === photo.guid)) {
			categorisedPhotos[photo.jobTaskNumber || ''].push(jobPhoto);
		}

		this.setState({ photos: photos, categorisedPhotos: categorisedPhotos });
	}

	photoExists = Id => {
		if (this.state.photos === undefined) return false;
		return this.state.photos.some(el => el.Guid === Id);
	};

	saveImage = async (selectorFiles: FileList | null) => {
		if (selectorFiles !== null) {
			for (let i = 0; i < selectorFiles.length; i++) {
				const photo: Blob = selectorFiles[i];
				const CompressionRatio = GetFileCompressionRatio(photo);
				const documentFBID = generateFirebaseId();
				loadImage(
					photo,
					(canvas: HTMLCanvasElement) => {
						canvas.toBlob(async blob => {
							const arrayBuffer = await fileToArrayBuffer(blob as Blob);
							await idb
								.savePhotoToLocal(
									arrayBuffer,
									this.state.DocumentID,
									this.state.isComplete,
									documentFBID,
									`photo-${new Date().getTime()}.jpeg`,
									undefined,
									undefined,
									undefined,
									fire.currentUser.email || undefined,
									JOB_DOCUMENT_SOURCE_FROM_APP,
									new Date().getTime(),
									undefined,
									undefined,
									undefined,
									BusinessTypes.Job
								)
								.then(async guid => {
									const jobQueueObject = {
										JobAction: 'AddPhotoExistingJob',
										PhotoGuid: guid,
									};
									await localQueue.saveToLocalJobQueue(
										this.state.DocumentID,
										jobQueueObject,
										undefined,
										undefined,
										undefined,
										arrayBuffer,
										undefined,
										documentFBID,
									);
								})
								.then(() => {
									this.forceUpdate();
								});
						});
					},
					{
						maxWidth: 400,
						maxHeight: 400,
						orientation: true,
						downsamplingRatio: CompressionRatio,
						canvas: true
					},
				);
			}
			this.handlePhotosExpand(true)
		}
	};

	backButton = () => {
		window.history.back();
	};

	handleGooglemapDialogVisibility = () => {
		this.setState({ googleMapDialogOpen: !this.state.googleMapDialogOpen });
	};

	handleCompleteJobDialogVisibility = async () => {
		const areAllFormsComplete = await areAllFormsSubmitted(this.state.DocumentID);
		if (!areAllFormsComplete) {
			return alert(this.props.t('Form Not Submitted - Cannot Complete Job'));
		} else {
			this.setState({ completeJobDialogOpen: !this.state.completeJobDialogOpen });
		}
	};

	handleinstructJobDialogVisibility = () => {
		this.setState({ instructJobDialogOpen: !this.state.instructJobDialogOpen });
	};

	newCompleteJobNoteChange = e => {
		this.setState({ completeJobNote: e.target.value });
	};

	handleCompleteJob = async () => {
		this.setState({ disableCompleteJob: true });

		this.handleCompleteJobDialogVisibility();

		const geoLocation = await getLocation();

		const JobObj = {
			CompletedDate: Date.now(),
			Latitude: geoLocation.Latitude,
			Longitude: geoLocation.Longitude,
			JobAction: 'CompleteJob',
			JobNote: this.state.completeJobNote,
		};

		await fire.CompleteJob(this.state.DocumentID, JobObj);

		await fire.postToJobQueue(this.state.DocumentID, JobObj);

		await this.setState({
			isComplete: 'true',
		});

		await idb.updatePhotosToComplete(this.state.DocumentID);
		idb.updateDocumentsToComplete(this.state.DocumentID);

		if (this.state.Forms.length > 0) {
			this.state.Forms.forEach(form => {
				idb.updateFormPhotosToComplete(form.Id);
				idb.updateFormDocumentsToComplete(form.Id);
			});
		}
	};

	newNoteChange = e => {
		this.setState({ newNote: e.target.value });
	};

	handleNoteDialogVisibility = () => {
		this.setState({ noteDialogOpen: !this.state.noteDialogOpen });
	};

	handleAddNewNote = () => {
		this.setState({ noteDialogOpen: !this.state.noteDialogOpen });

		const firebaseId = generateFirebaseId();
		const addObject = {
			note: this.state.newNote,
			isPrivate: false
		}

		fire.addJobNote(this.state.DocumentID, addObject, firebaseId);

		const NewNoteQueueObj = {
			Note: this.state.newNote,
			NoteFBID: firebaseId,
			JobAction: 'NewNote',
		};

		fire.postToJobQueue(this.state.DocumentID, NewNoteQueueObj).catch(err => {
			alert(this.props.t('There was an error adding job note, if problem persists please email - support@trackplanfm.com'));
			console.error(err);
		});

		this.handleNotesExpand(true)
	};

	handleCameraDialog = () => {
		this.props.history.push({
			pathname: '/take-photo',
			state: {
				DocumentId: this.state.DocumentID,
				newJobRequest: false,
				newJob: false,
				Collection: 'Jobs',
				isComplete: this.state.isComplete,
			},
		});
	};

	openImageDialog = () => {
		const imageUploadButton = document.getElementById('image-upload');
		if (imageUploadButton !== null) {
			imageUploadButton.click();
		}
	};

	resetResourceForJob = () => {
		if (this.state.jobDetails != null) {
			const confirmResourceText = this.state.jobDetails.IsMultipleResource ? 'resources' : 'resource';
			if (confirm(this.props.t('Are you sure you wish to reset the {{l}} for this job?', { l: confirmResourceText }))) {
				fire.resetResourceToJob(this.state.DocumentID);

				const ClearResourceObj = {
					JobAction: 'ClearResourcesFromJob',
					IsMultipleResource:
						this.state.jobDetails.IsMultipleResource !== undefined && this.state.jobDetails.IsMultipleResource !== null
							? this.state.jobDetails.IsMultipleResource
							: false,
				};

				fire.postToJobQueue(this.state.DocumentID, ClearResourceObj);
			}
		}
	};

	resetAssignedUserForJob = () => {
		if (this.state.jobDetails != null) {
			if (confirm(this.props.t('Are you sure you wish to reset the assigned user for this job?'))) {
				fire.resetAssignedUserForJob(this.state.DocumentID);

				const ClearAssignedUserObj = {
					JobAction: 'ClearAssignedUserFromJob',
				};

				fire.postToJobQueue(this.state.DocumentID, ClearAssignedUserObj);
			}
		}
	};

	removeAttachedAsset = assetID => {
		if (confirm(this.props.t('Are you sure you want to remove this asset from the job?'))) {
			fire.getCollection('AssetsToJobs', 'JobFBID').then(AssetJobs => {
				AssetJobs.docs.forEach(AssetJob => {
					const data = AssetJob.data();
					if (data.AssetFBID === assetID && data.JobFBID === this.state.DocumentID) {
						fire.removeAttachedAsset(AssetJob.id);

						const RemoveAsset = {
							AssetFBID: assetID,
							JobFBID: this.state.DocumentID,
							JobAction: 'RemoveAssetFromJob',
						};

						fire.postToJobQueue(AssetJob.id, RemoveAsset);
						this.getAssetIDsNew(this.state.DocumentID);
					}
				});
			});
		}
	};

	handleInstructJob = () => {
		fire.InstructJob(this.state.DocumentID, Date.now(), stringtoUnixUTC(this.state.ExpectedDate));

		if (!isNullOrUndefined(this.state.jobDetails)) {
			let ResourceObjFBIDs: string[] = [];
			if (
				this.state.jobDetails.IsMultipleResource === true &&
				!isNullOrUndefined(this.state.jobDetails.AssignedToResourceMultiple)
			) {
				this.state.jobDetails.AssignedToResourceMultiple.forEach(Resource => {
					if (!isNullOrUndefined(Resource.ResourceObjFBID)) {
						ResourceObjFBIDs.push(Resource.ResourceObjFBID);
					}
				});
			}

			const ResourceFBIDSingle =
				!isNullOrUndefined(this.state.jobDetails) && !isNullOrUndefined(this.state.jobDetails.AssignedToResource)
					? this.state.jobDetails.AssignedToResource.ResourceObjFBID
					: null;

			const InstructObj = {
				ResourceObjFBIDs: ResourceObjFBIDs,
				ResourceObjFBID: ResourceFBIDSingle,
				IsMultipleResource: this.state.jobDetails.IsMultipleResource,
				ExpectedDate: stringtoUnixUTC(this.state.ExpectedDate),
				IsIndividualTask: this.state.isSeparateTask,
				JobAction: 'InstructJob',
			};

			fire.postToJobQueue(this.state.DocumentID, InstructObj);
			this.handleinstructJobDialogVisibility();
		}
	};

	EditExpectedDate = value => {
		this.setState({ ExpectedDate: value });
	};

	getJobNotes = id => {
		fire.getNotes(id, 'Jobs', this.props.UserSettings.CanViewAllNotes).onSnapshot(notes => {
			let Notes: Notes[] = [];
			notes.docs.map(note => {
				const noteObj = note.data() as Notes;
				noteObj.Id = note.id;
				noteObj.DateAddedString = unixToDateString(noteObj.DateAdded);
				if (noteObj.DateAddedString === 'Invalid Date' && noteObj.TimeStamp) {
					noteObj.DateAddedString = unixToDateString(noteObj.TimeStamp * 1000);
				}

				if (noteObj.DateAddedString !== 'Invalid Date') Notes.push(noteObj);
			});

			Notes.sort(dynamicSort('DateAdded')).reverse();
			this.setState({ notes: Notes });
		});
	};

	getAssetIDsNew = id => {
		let AssetArray: Asset[] = [];

		fire.baseQuery
			.collection('AssetsToJobs')
			.where('JobFBID', '==', id)
			.get()
			.then(AssetJobs => {
				AssetArray = AssetJobs.docs.map(assetJob => {
					let data = assetJob.data() as Asset;
					data.AssetName = isNullOrUndefined(data.AssetName) ? '' : data.AssetName;
					return data;
				});
				AssetArray.sort((a, b) => a.AssetName.toLowerCase().localeCompare(b.AssetName.toLowerCase()));
				this.setState({ AssetsArr: AssetArray });
			});
	};

	handleLogout() {
		LocalstorageRemoveItem('NewJobDetails');
		LocalstorageRemoveItem('JobFilterOptions');
		LocalstorageRemoveItem('AssignedUserFilterOptions');
		LocalstorageRemoveItem('ResourceFilterOptions');
		LocalstorageRemoveItem('FormLibraryFilterOptions');
		fire.auth.signOut().then(() => location.reload());
	}

	handleDownloadPhoto = (curPhoto, filename) => {
		const downloadLink = document.createElement('a');
		downloadLink.href = curPhoto;
		downloadLink.setAttribute('download', filename);
		downloadLink.click();
	}

	handleDownloadAllPhotos = () => {
		this.state.photos.forEach((photo) => {
			this.handleDownloadPhoto(photo.FirebaseStoragePath, photo.Filename);
		})
	}

	handleRemovePhoto = (guid, docReference) => {
		const photoQuery = fire.baseQuery.collection('Jobs')
			.doc(docReference).collection('Documents').where('Guid', '==', guid);

		photoQuery.get().then(async (snapshot) => {
			const docId = snapshot.docs[0].id;

			await fire.baseQuery.collection('Jobs')
				.doc(docReference).collection('Documents').doc(docId).delete();

			await idb.removePhotoByGuid(guid);

			if (navigator.onLine) {
				const fileSorageLocation = snapshot.docs[0].data()["FirebaseRef"];
				if (fileSorageLocation) {
					const storage = firebase.storage();
					const storageRef = storage.ref();
					storageRef.child(fileSorageLocation).delete();
				}
			}

			const deleteDocumentObj = {
				JobAction: 'DeleteDocument',
				FBID: docId
			}
			fire.postToJobQueue(docReference, deleteDocumentObj);

		}).then(() => {
			this.setState({ photos: [], categorisedPhotos: {} })
			this.getPhotos();
		});
	}

	handleExpand = (value) => {
		this.setState({ expand: value })
	}

	handlePhotosExpand = (value) => {
		this.setState({ photosExpand: value })
	}

	handleNotesExpand = (value) => {
		this.setState({ notesExpand: value })
	}

	render() {
		if (this.state.jobDetails !== null) {
			const title =
				this.state.jobDetails.JobNumber === 0 ? this.props.t("Job Not Synced") : this.props.t("Job No.") + " " + this.state.jobDetails.JobNumber;
			const showJobType = this.state.jobDetails.JobType !== '' && !isNullOrUndefined(this.state.jobDetails.JobType);
			const showLocation = this.state.jobDetails.Location !== '' && !isNullOrUndefined(this.state.jobDetails.Location);
			const showExpectedBy = !isNullOrUndefined(this.state.jobDetails.ExpectedByDate);
			const showCompletedBy = !isNullOrUndefined(this.state.jobDetails.CompletedDate);
			const showInstructed = !isNullOrUndefined(this.state.jobDetails.InstructedDate);
			const showCategory = !isNullOrUndefined(this.state.jobDetails.JobCategory);
			const showPriority = this.state.jobDetails.Priority !== '' && !isNullOrUndefined(this.state.jobDetails.Priority);

			const showSubLocation =
				this.state.jobDetails.SubLocation !== '' && !isNullOrUndefined(this.state.jobDetails.SubLocation);

			const showJobSubType =
				this.state.jobDetails.JobSubType !== '' && !isNullOrUndefined(this.state.jobDetails.JobSubType);
			const job = this.state.jobDetails;

			const settingCanAssignResource =
				isNullOrUndefined(this.props.UserSettings.CanAssignResource) || this.props.UserSettings.CanAssignResource;

			const settingCanInstructResource =
				isNullOrUndefined(this.props.UserSettings.CanInstructResource) || this.props.UserSettings.CanInstructResource;

			const settingCanAssignUser =
				isNullOrUndefined(this.props.UserSettings.CanAssignUser) || this.props.UserSettings.CanAssignUser;

			const settingCanUseAssets =
				isNullOrUndefined(this.props.UserSettings.AssetsEnabled) || this.props.UserSettings.AssetsEnabled;

			const settingCanCompleteJob =
				isNullOrUndefined(this.props.UserSettings.CanCompleteJob) || this.props.UserSettings.CanCompleteJob;

			let canAssignResource = false;
			if (settingCanAssignResource) {
				canAssignResource = CanAssignResource(
					job.AssignedToResource,
					job.AssignedToResourceMultiple,
					job.IsMultipleResource,
					job.JobStatus,
				);
			}

			let canAssignUser = false;
			if (settingCanAssignUser) {
				canAssignUser = true;
			}

			let isUserAssigned = false;
			if (!(isNullOrUndefined(job.AssignedToUser) || job.AssignedToUser.trim() === '')) {
				isUserAssigned = true;
			}

			let canInstructResource = false;
			if (settingCanInstructResource) {
				canInstructResource = CanInstructResource(
					job.AssignedToResource,
					job.AssignedToResourceMultiple,
					job.IsMultipleResource,
					job.JobStatus,
				);
			}

			let AssignedResource =
				!isNullOrUndefined(this.state.jobDetails.AssignedToResource) &&
					this.state.jobDetails.AssignedToResource.ResourceName !== null
					? this.state.jobDetails.AssignedToResource.ResourceName
					: '';

			let ResourceString = '';
			if (this.state.jobDetails.IsMultipleResource === true) {
				if (this.state.jobDetails.AssignedToResourceMultiple) {
					for (let i = 0; i < this.state.jobDetails.AssignedToResourceMultiple.length; i++) {
						if (i + 1 < this.state.jobDetails.AssignedToResourceMultiple.length) {
							ResourceString = ResourceString + this.state.jobDetails.AssignedToResourceMultiple[i].ResourceName + ', ';
						}
						if (i + 1 === this.state.jobDetails.AssignedToResourceMultiple.length) {
							ResourceString = ResourceString + this.state.jobDetails.AssignedToResourceMultiple[i].ResourceName;
						}
					}
				}
			}

			let jobPhotos = this.state.categorisedPhotos[''] || [];
			let jobTasks = [...Object.keys(this.state.categorisedPhotos)].filter(jobTask => jobTask !== '');

			return (
				<div>
					<SideDrawer
						history={this.props.history}
						title={title}
						rightMenuButton={<BackButton callbackMethod={this.backButton} />}
						colour="primary"
						handleLogout={this.handleLogout}
						User={this.props.UserSettings}
						versionApp={Api.VERSION}
						versionDb={Api.INDEXEDDB_VERSION}
						SendErrorData={SendErrorData}
						getBaseURL={getBaseURL}
						ApiKeyObj={ApiKeyObj}
					/>
					<Grid container={true} direction="column" justify="center" alignItems="center">
						<div className="main">
							<div id="details-outer">
								<div className="top-card-alt card-shadow-alt">
									<div>
										<FieldHolderLink content={job.Site} label={this.props.t("Site")} link={job.SiteFBID} field="site" />
										{showLocation ? <FieldHolderLink content={job.Location} label={this.props.t("Location")} link={job.LocationFBID} field="location" /> : null}
										<FieldHolder fullLine={true} content={job.JobDetails} label={this.props.t("Details")} />
										<FieldHolder content={unixToDateString(job.DateCreated)} label={this.props.t("Created Date")} />

										{showExpectedBy && !isNullOrUndefined(job.ExpectedByDate) ? (
											<FieldHolder content={unixToDateString(job.ExpectedByDate)} label={this.props.t("Expected Date")} />
										) : null}

										{showCompletedBy && !isNullOrUndefined(job.CompletedDate) ? (
											<FieldHolder content={unixToDateString(job.CompletedDate)} label={this.props.t("Completed Date")} />
										) : null}
									</div>
									<div className='main-card-left'>
										<FieldHolder content={this.props.t(job.JobStatus)} content2={this.props.t(job.JobSubStatus)} label={this.props.t("Status")} />
										{showLocation && showSubLocation ? (
											<FieldHolderLink content={job.SubLocation} label={this.props.t("Sub Location")} link={job.SubLocationFBID} field="sublocation" />
										) : null}
										{!isNullOrUndefined(job.CreatedByUser) ? (
											<FieldHolder content={job.CreatedByUser} label={this.props.t("Created By")} />) : null
										}
										{showInstructed && !isNullOrUndefined(job.InstructedDate) ? (
											<FieldHolder content={unixToDateString(job.InstructedDate)} label={this.props.t("Instructed Date")} />
										) : null}
									</div>
								</div>

								{showJobType || showPriority || showCategory ? (
									<div className="top-card-alt card-shadow-alt">
										{showJobType ? <FieldHolder content={job.JobType} label={this.props.t("Job Type")} /> : null}
										{showJobType && showJobSubType ? (
											<FieldHolder content={job.JobSubType} label={this.props.t("Job Sub Type")} />
										) : null}
										{showPriority ? <FieldHolder content={this.props.t(job.Priority)} label={this.props.t("Priority")} /> : null}
										{job.IsPlanned && job.ScheduleName !== '' ? (
											<FieldHolder content={job.ScheduleName} label={this.props.t("Schedule Name")} />
										) : null}
										{showCategory ? <FieldHolder content={job.JobCategory} label={this.props.t("Job Category")} /> : null}
									</div>
								) : null}

								<div className="top-card-alt card-shadow-alt">
									<span className='information-card-title'>{this.props.t('Information')}</span>
									<div className={'full-line'}>
										<span className="almost-bold-text">
											{this.props.t("Address")}
											{isNullOrUndefined(job.Latitude) || isNullOrUndefined(job.Longitude) ? null : (
												<IconButton
													className='icon-button-holder'
													onClick={() => this.handleGooglemapDialogVisibility()}
												>
													<Icon className='pin-icon-holder'>pin_drop</Icon>
												</IconButton>
											)}
										</span> : <span className="field-content-alt">{job.Address}</span>
										<GoogleMapsModal
											open={this.state.googleMapDialogOpen}
											handleGoogleMapsDialogVisibility={this.handleGooglemapDialogVisibility}
											latitude={job.Latitude}
											longitude={job.Longitude}
										/>
										<br></br>
									</div>
									<div className={'full-line'}>&nbsp;</div>
									<>
										<div><span className='almost-bold-text'>{this.props.t("Contact")}</span>: <span
											className='field-content-alt'>{job.Contact}</span></div>
										<div><span className='almost-bold-text'>{this.props.t("Telephone")}</span>: <span
											className='field-content-alt'>{job.Telephone && job.Telephone.toString()}</span></div>
									</>

								</div>

								<div className="assigned-card">
									<div className="assigned-card-column assigned-card-shadow-alt">
										{this.state.jobDetails.IsMultipleResource !== true ? (
											<FieldHolder content={AssignedResource} label={this.props.t("Resource")} />
										) : (
											<FieldHolder content={ResourceString} label={this.props.t("Resources")} />
										)}

										<InstructResourceButtons
											canAssignResource={canAssignResource}
											canInstructResource={canInstructResource}
											isInstructed={showInstructed}
											isComplete={showCompletedBy}
											documentID={this.state.DocumentID}
											history={this.props.history}
											resetResourceForJob={this.resetResourceForJob}
											handleinstructJobDialogVisibility={this.handleinstructJobDialogVisibility}
											resourceAssigned={
												!canAssignResource &&
												settingCanAssignResource &&
												job.JobStatus !== 'Complete' &&
												job.JobStatus !== 'Cancelled'
											}
										/>
									</div>

									<div className="assigned-card-column assigned-card-shadow-alt">
										<FieldHolder content={job.AssignedToUser} label={this.props.t("Assigned To")} />
										<AssignedUserButtons
											canAssignUser={canAssignUser}
											isComplete={showCompletedBy}
											documentID={this.state.DocumentID}
											history={this.props.history}
											resetAssignedUserForJob={this.resetAssignedUserForJob}
											userAssigned={isUserAssigned}
										/>
									</div>
								</div>

								<>
									<CompleteJobModal
										open={this.state.completeJobDialogOpen}
										handleCloseCompleteJobModal={this.handleCompleteJobDialogVisibility}
										newCompleteJobNote={this.newCompleteJobNoteChange}
										handleCompleteJob={this.handleCompleteJob}
										isDisabled={this.state.disableCompleteJob}
									/>
									<InstructJobModal
										open={this.state.instructJobDialogOpen}
										handleCloseInstructJobModal={this.handleinstructJobDialogVisibility}
										handleInstructJob={this.handleInstructJob}
										EditExpectedDate={this.EditExpectedDate}
										ExpectedByDate={this.state.jobDetails.ExpectedByDate}
										toggleIsSeparate={this.toggleIsSeparateTask}
										isSeparateTask={this.state.isSeparateTask}
										toggleIsShared={this.toggleIsSharedTask}
										isSharedTask={this.state.isSharedTask}
									/>

									{job.JobStatus !== 'Complete' && job.JobStatus !== 'Cancelled' && settingCanCompleteJob ? (
										<Button
											onClick={() => this.handleCompleteJobDialogVisibility()}
											className='complete-job-button'
											variant="contained"
										>
											<Icon className='icon'>done_outline</Icon>&nbsp;
											<p className='label-margin'>{this.props.t("Complete Job")}</p>
										</Button>
									) : null}
								</>

								{/* Job Forms */}
								<FormsList
									Forms={this.state.Forms}
									TaskId={this.state.DocumentID}
									history={this.props.history}
									AvailableForms={this.state.AvailableForms}
									showAttachFormButton={true}
									UserSettings={this.props.UserSettings}
								/>

								{/* Photos */}
								<JobPhotos
									// @ts-ignore
									history={this.props.history}
									documentId={this.state.DocumentID}
									isComplete={!isNullOrUndefined(job.CompletedDate)}
									color=""
									photos={jobPhotos}
									titleText={this.props.t("Job Photos")}
									noPhotosText={this.props.t("No Job Photos")}
									isForRequest={false}
									canRemovePhoto={true}
									canDownloadPhoto={true}
									handleDownloadPhoto={this.handleDownloadPhoto}
									handleRemovePhoto={this.handleRemovePhoto}
									numberOfPhotos={0}
									documentReference={this.state.DocumentID}
									handleDownloadAllPhotos={this.handleDownloadAllPhotos}
									displayDownloadAll={true}
									canEditPhoto={true}
									expand={this.state.photosExpand}
									handleExpand={this.handlePhotosExpand}
									saveImage={this.saveImage}
									showActionButton={true}
									openImageDialog={this.openImageDialog}
									photosLoading={this.state.syncDocsLoading}
								/>

								{/* Job Task Photos */}
								{jobTasks && (
									<JobTaskPhotosCard
										documentId={this.state.DocumentID}
										completedDate={job.CompletedDate}
										jobTasks={jobTasks}
										categorisedPhotos={this.state.categorisedPhotos}
										t={this.props.t}
										// @ts-ignore
										history={this.props.history}
										photosLoading={this.state.syncDocsLoading}
									/>
								)}

								{/* Job Documents */}
								<DocumentsCard fromView="Job" associatedFBID={this.state.DocumentID} />

								{/* Job Assets */}
								{settingCanUseAssets && (
									<AssetJobListNew
										assets={this.state.AssetsArr}
										TaskId={this.state.DocumentID}
										history={this.props.history}
										documentID={this.state.DocumentID}
									/>
								)}

								{/* Job Notes */}
								<AddNoteDialogPopup
									open={this.state.noteDialogOpen}
									handleNoteDialogVisibility={this.handleNoteDialogVisibility}
									newNoteChange={this.newNoteChange}
									handleAddNew={this.handleAddNewNote}
									Title={this.props.t("Add Job Note")}
								/>
								<NotesCard notes={this.state.notes as Notes[]} noNotes={this.props.t("No Job Notes")} title={this.props.t("Job Notes")}
									expand={this.state.notesExpand} handleExpand={this.handleNotesExpand} handleNoteDialogVisibility={this.handleNoteDialogVisibility} />
							</div>
						</div>
					</Grid>
				</div>
			);
		}

		return <LoadingSpinner color="primary" text={this.props.t("Loading job details")} />;
	}
}

const FieldHolder = props => (
	<div className={`${props.pullRight ? 'pull-right' : ''}${props.fullLine ? 'full-line' : ''} field`}>
		<p className={props.bold ? 'field-content-alt break-word-wrap almost-bold-text' : 'field-content-alt break-word-wrap'}>
			{props.content}{' '}
			{isNullOrUndefined(props.content2) ? (
				''
			) : (
				<p>
					<span className="field-content2">{props.content2}</span>
				</p>
			)}
		</p>
		<p className="field-label-alt">{props.label}</p>
	</div>
);

//Checks to see if being passed in link URL and if so sets a link tag around the field content.
const FieldHolderLink = props => (
	<div className={`${props.pullRight ? 'pull-right' : ''}${props.fullLine ? 'full-line' : ''} field`}>
		<p className={props.bold ? 'field-content-link break-word-wrap almost-bold-text' : 'field-content-link break-word-wrap'}>
			{!isNullOrUndefined(props.link) ? (<Link to={`/${props.field}-details/${props.link}`} >{props.content}<TouchAppIcon fontSize="small"
				className='touch-app-icon-color'></TouchAppIcon></Link>) : props.content}
		</p>
		<p className="field-label-alt">{props.label}</p>
	</div>
);

const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
});

export default withTranslation()(connect(mapStateToProps)(JobDetailsScreen));