export default function GetMimeType(filename: string): string {
	const fileNameArray = filename.split('.');
	if (fileNameArray.length < 1) return '';

	const fileExtension = fileNameArray[fileNameArray.length - 1];

	switch (fileExtension) {
		case 'txt':
			return 'text/plain';

		case 'rtx':
			return 'text/richtext';

		case 'jpeg':
		case 'jpg':
		case 'png':
		case 'tiff':
		case 'ico':
		case 'jfif':
			return 'image/jpeg';

		case 'mp2':
		case 'mpa':
		case 'mpe':
		case 'mpeg':
			return 'video/mpeg';

		case 'zip':
			return 'application/zip';

		case 'xls':
		case 'xlsx':
			return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

		case 'doc':
			return 'application/msword';

		default:
			return '';
	}
}
