import { CURRENT_JOBS, CURRENT_JOBS_SCROLLY, CLEAR_JOBS_STATE } from '../actions/JobActions';

const initialState = {
    totalJobsLoaded: 0,
    yAxisPos: 0
};

const JobsReducer = (state = initialState, action) => {

    switch (action.type) {
        case CURRENT_JOBS:
            state = {
                ...state, totalJobsLoaded: action.payload
            }
            return state;
        case CURRENT_JOBS_SCROLLY:
            state = {
                ...state, yAxisPos: action.payload
            };
            return state;
        case CLEAR_JOBS_STATE:
            state = initialState;
            return state;
        default:
            return state;
    }
};

export default JobsReducer;