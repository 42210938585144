import { useCallback, useEffect, useState } from 'react';
import { fire, idb, localQueue } from '../../index';
import { GetFileCompressionRatio } from '../../utils/FileCompressionRatio';
import { generateFirebaseId } from '../../utils/Guids';
import * as loadImage from 'blueimp-load-image';
import { fileToArrayBuffer } from '../../utils/Converters';

import { JOB_DOCUMENT_SOURCE_FROM_APP } from '../../utils/database/indexdb/IndexDb';
import imgNotAvailable from '../../assets/images/not-available.jpg';
import { BusinessTypes } from '../../utils/shared';

const usePhotosCards = (associatedFBID: string, fromView: string) => {
	const [photos, setPhotos] = useState<Photo[]>([]);
	const [syncDocsLoading, setSyncDocsLoading] = useState<boolean>(false);
	const [showPhotosLoadMore, setShowPhotosLoadMore] = useState<boolean>(false);
	const [disableLoadMoreBtn, setDisableLoadMoreBtn] = useState<boolean>(false);


	useEffect(() => {
		const initializeData = async () => {
			getPhotos();
			if (navigator.onLine) {
				setSyncDocsLoading(true);
				await idb.syncDoumentsAndPhotosFromFb(
					associatedFBID,
					fromView + "s",
					"",
					getPhotos,
					setAllPhotosFromFB,
					true
				);
				setSyncDocsLoading(false);
			}
		};

		initializeData();
	}, []);

	const setAllPhotosFromFB = useCallback(async (allPhotos: any[]) => {
		if (allPhotos && allPhotos.length > 5) {
			const idbPhotos = await idb.getPhotosCard(associatedFBID);
			if (idbPhotos.length !== allPhotos.length) {
				setShowPhotosLoadMore(true);
			}
		} else {
			setShowPhotosLoadMore(false);
		}
	}, []);

	const loadMorePhotos = useCallback(async () => {
		setDisableLoadMoreBtn(true);
		const hasMorePhotos = await idb.loadMorePhotosFromFB(
			associatedFBID,
			`${fromView}s`,
			"",
			getPhotos
		);
		if (hasMorePhotos) {
			setDisableLoadMoreBtn(false);
		} else {
			setShowPhotosLoadMore(false);
		}
	}, [])

	const getPhotos = () => {
		idb.getPhotosCard(associatedFBID).then(_photos => {
			let allPhotos: any[] = [];
			if (_photos.length > 0) {
				_photos.forEach(photo => {
					const formattedPhoto = getPhotoObject(photo);
					if (formattedPhoto) {
						allPhotos.push(formattedPhoto);
					}
				});
			}
			setPhotos(allPhotos);
		});
	};


	const openImageDialog = () => {
		const imageUploadButton = document.getElementById('image-upload');
		if (imageUploadButton !== null) {
			imageUploadButton.click();
		}
	};

	const getPhotoObject = (photo: indexDb.Photos) => {
		let photoURL = imgNotAvailable;

		if (photo.file.byteLength !== new ArrayBuffer(0).byteLength)
			photoURL = URL.createObjectURL(new Blob([photo.file]));

		let photoObj = {
			Guid: photo.guid,
			FirebaseStoragePath: photoURL,
			Filename: photo.fileName,
			UploadedBy: "Other",
			Source: "Other",
			DateCreated: 1663768061818.3882
		};

		return photoObj;
	}

	const handleDownloadPhoto = (curPhoto, filename) => {
		const downloadLink = document.createElement('a');
		downloadLink.href = curPhoto;
		downloadLink.setAttribute('download', filename);
		downloadLink.click();
	}

	const saveImage = async (selectorFiles: FileList | null) => {
		if (selectorFiles !== null) {
			for (let i = 0; i < selectorFiles.length; i++) {
				const photo: Blob = selectorFiles[i];
				const CompressionRatio = GetFileCompressionRatio(photo);
				const photoFBID = generateFirebaseId();

				loadImage(photo,
					(canvas: HTMLCanvasElement) => {
						canvas.toBlob(async blob => {
							const arrayBuffer = await fileToArrayBuffer(blob as Blob);
							var documentFBID = generateFirebaseId();
							let businessType;
							if (fromView === "Asset") {
								businessType = BusinessTypes.Asset
							} else if (fromView === "Site") {
								businessType = BusinessTypes.Site
							} else if (fromView === "Location") {
								businessType = BusinessTypes.Locations
							} else if (fromView === "SubLocation") {
								businessType = BusinessTypes.SubLocations
							}

							await idb.savePhotoToLocal(
								arrayBuffer,
								associatedFBID,
								"",
								documentFBID,
								`photo-${new Date().getTime()}.jpeg`,
								undefined,
								undefined,
								undefined,
								fire.currentUser.email || undefined, JOB_DOCUMENT_SOURCE_FROM_APP,
								new Date().getTime(),
								undefined,
								undefined,
								undefined,
								businessType
							)
								.then(guid => {
									const jobQueueObject = {
										JobAction: 'AddDocument',
										PhotoGuid: guid,
										Type: fromView,
										IsPhoto: true,
										AssociatedFBID: associatedFBID,
										DocumentFBID: documentFBID
									};
									localQueue.saveToLocalJobQueue(
										associatedFBID,
										jobQueueObject,
										undefined,
										undefined,
										undefined,
										arrayBuffer,
										undefined,										
										photoFBID
									);
								})
								.then(() => {
									getPhotos();
								});
						});
					},
					{
						maxWidth: 400,
						maxHeight: 400,
						orientation: true,
						downsamplingRatio: CompressionRatio,
						canvas: true
					},
				);
			}
		}
	};

	return {
		photos,
		saveImage,
		openImageDialog,
		handleDownloadPhoto,
		syncDocsLoading,
		showPhotosLoadMore,
		loadMorePhotos,
		disableLoadMoreBtn
	};
}

export default usePhotosCards;
