// react
import * as React from 'react';
import { CircularProgress, Dialog } from '@material-ui/core';

import '../../styles/forms/forms.css';

const TransparentLoadingSpinner = (props) => {

    return (<><Dialog id="dialogLoading" open={true} color="secondary" aria-labelledby="form-dialog-title" fullWidth maxWidth="sm" style={{zIndex: 999999999 }} >
			<h1>{props.text ? props.text : "Loading..."}</h1>
			&nbsp;
			<CircularProgress size={60} disableShrink />
		</Dialog></>);
};

export default TransparentLoadingSpinner;
