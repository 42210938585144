// React
import * as React from 'react';

const DateAnswer = (props: {
	answer: string | null;
	isDateTime: boolean;
	handleDateInput: (value: string) => void;
	updateFirebase: (any) => void;
	formComplete: boolean;
}) => {
	return (
		<div className="date-question-container">
			<input
				type={`${props.isDateTime ? 'datetime-local' : 'date'}`}
				name="date-question"
				className="date-question"
				value={props.answer || ''}
				onChange={val => props.handleDateInput(val.target.value)}
				onBlur={props.updateFirebase}
				disabled={props.formComplete}
			/>
		</div>
	);
};

export default DateAnswer;
