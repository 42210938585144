// React
import * as React from 'react';

// Material UI
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const InlineLoadingSpinner = props => {
	const [t, i18n] = useTranslation();
	return (
	<div style={{ width: '100%', marginTop: '10px' }}>
		<CircularProgress size={props.size || 50} color={props.color || 'primary'} />
		<span style={{ marginLeft: '10px', fontSize: `${props.size}px`, color: '#e91e63' }}>
			{props.text || t("Loading...")}
		</span>
	</div>
	)};

export default InlineLoadingSpinner;
