/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box, InputLabel } from '@material-ui/core';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LoadOptions, Response } from 'react-select-async-paginate';
import firebase from 'firebase';
import { fire } from '../../..';
import {
	AsyncPaginateLocationDropdown,
	ReactSelectLocationOptionType,
} from '../AsyncPaginateDropdowns/LocationDropdown';
import { AsyncPaginateSiteDropdown, ReactSelectSiteOptionType } from '../AsyncPaginateDropdowns/SiteDropdown';
import {
	AsyncPaginateSubLocationsDropdown,
	ReactSelectSublocationOptionType,
} from '../AsyncPaginateDropdowns/SublocationDropdown';

import { ADDITIONAL_LOAD_OPTIONS, FBIDAndIDType, SiteSublocAssetFiltersCtx } from './SiteSubLocAssetForm';

const NUMBER_OF_SITES_TO_LOAD = 50;
const NUMBER_OF_LOCATIONS_TO_LOAD = 50;
const NUMBER_OF_SUBLOCATIONS_TO_LOAD = 50;

/**
 * Factory function that creates a loadOptions function for the site dropdown
 */
const loadSiteOptionsFactory = (
	userSettings: Store.UserSettings,
): LoadOptions<ReactSelectSiteOptionType<FBIDAndIDType>, ADDITIONAL_LOAD_OPTIONS<Site>> => {
	// NOTE: Be careful, an error in this function is not logged! That's an issue with the async paginate component.
	return async (
		inputValue, // user search value
		_options, // already loaded options
		additional, // passed to the component as props
	) => {
		const { docs } = await fire
			.getSearchSitesPaginated(
				NUMBER_OF_SITES_TO_LOAD,
				inputValue,
				additional ? additional!.lastLoadedDocSnapshot : null,
				userSettings.CanAccessAllSites,
				userSettings.ContractFBID,
			)
			.get()
			// we need to handle errors here, because the async paginate component will just loop on loadOptions if there is an error
			// without even loggin it!
			.catch(err => {
				console.error(err);
				throw err;
			});
		return buildLoadOptionsResponseObject(docs, docs.length === NUMBER_OF_SITES_TO_LOAD, doc => ({
			label: doc.data().SiteName,
			value: { fbid: doc.data().SiteFBID!, id: doc.data().SiteID! },
		}));
	};
};

function useSiteDropdown() {
	const { filters, setFilters } = useContext(SiteSublocAssetFiltersCtx);

	// user settings cannot be null here, it explains the bang op.
	const userSettings = useSelector((state: Store.Store) => state.User.UserSettings!);
	const loadSiteOptions = useMemo(() => loadSiteOptionsFactory(userSettings), [userSettings]);
	// see https://react-select.com/typescript#onchange for the type of this function
	const handleSiteFilter = useCallback(
		(option: ReactSelectSiteOptionType<FBIDAndIDType> | null) =>
			setFilters({ site: option, location: null, subLocation: null, assetName: null }),
		[setFilters],
	); // we clear every filter when the site changes

	const clearAllFields = useCallback(
		() =>
			setFilters({
				assetName: null,
				site: null,
				location: null,
				subLocation: null,
			}),
		[setFilters],
	);

	return [filters, { loadSiteOptions, handleSiteFilter, clearAllFields }] as const;
}

/**
 * A site dropdown that is used in the SiteSubLocAssetForm, fetch sites and paginate them
 */
export function SiteDropdownField() {
	const [t, _i18n] = useTranslation();
	const [filters, { loadSiteOptions, handleSiteFilter, clearAllFields }] = useSiteDropdown();
	return (
		<>
			<Box marginBottom={1}>
				<InputLabel>{t('Site')}</InputLabel>
			</Box>

			{/* the first generic argument define the type of Option's value, the second the type is for additional options to loadOptions function */}
			<AsyncPaginateSiteDropdown<FBIDAndIDType, ADDITIONAL_LOAD_OPTIONS<Site>>
				handleSiteFilter={handleSiteFilter}
				loadSites={loadSiteOptions}
				siteFilter={filters.site}
				isClearable
				onClear={clearAllFields}
			/>
		</>
	);
}

function buildLoadOptionsResponseObject<T, U>(
	docs: firebase.firestore.QueryDocumentSnapshot<T>[],
	hasMore: boolean,
	mapFun: (value: firebase.firestore.QueryDocumentSnapshot<T>) => U,
): Response<U, ADDITIONAL_LOAD_OPTIONS<T>> {
	return {
		additional: {
			lastLoadedDocSnapshot: docs.slice(-1)[0], // last doc is the last loaded one, used as a cursor by firebase for pagination
		},
		hasMore,
		options: docs.map(mapFun),
	};
}

/**
 * Factory function that creates a loadOptions function for the location dropdown
 */
const loadLocationOptionsFactory = (
	siteFilter: ReactSelectSiteOptionType<FBIDAndIDType> | null,
): LoadOptions<ReactSelectLocationOptionType<FBIDAndIDType>, ADDITIONAL_LOAD_OPTIONS<Location>> => {
	// NOTE: Be careful, an error in this function is not logged! That's an issue with the async paginate component.
	return async (
		inputValue, // user search value
		_options, // already loaded options
		additional, // passed to the component as props
	) => {
		const { docs } = await fire
			.getSearchLocationsPaginated(
				NUMBER_OF_LOCATIONS_TO_LOAD,
				siteFilter ? siteFilter.value.fbid : null,
				true,
				inputValue,
				additional ? additional.lastLoadedDocSnapshot : null,
			)
			.get()
			// we need to handle errors here, because the async paginate component will just loop on loadOptions if there is an error
			// without even loggin it!
			.catch(err => {
				console.error(err);
				throw err;
			});
		return buildLoadOptionsResponseObject(docs, docs.length === NUMBER_OF_LOCATIONS_TO_LOAD, doc => ({
			label: doc.data().LocationName,
			value: { fbid: doc.data().ID!, id: doc.data().LocationID! },
		}));
	};
};

function useLocationDropdown() {
	const { filters, setFilters } = useContext(SiteSublocAssetFiltersCtx);

	const loadLocationOptions = useMemo(() => loadLocationOptionsFactory(filters.site || null), [filters]);
	const clearSelectedLocation = useCallback(
		() =>
			setFilters(filters => ({
				...filters,
				location: null,
				subLocation: null,
				assetName: null, // don't forget to clear the asset name!
			})),
		[setFilters],
	);
	// see https://react-select.com/typescript#onchange for the type of this function
	const handleLocationFilter = useCallback(
		(option: ReactSelectLocationOptionType<FBIDAndIDType> | null) =>
			setFilters(filters => ({ ...filters, location: option })),
		[setFilters],
	);

	return [filters, { loadLocationOptions, clearSelectedLocation, handleLocationFilter }] as const;
}

/**
 * A location dropdown field, fetches the locations and paginates them, it is used in the SiteSubLocAssetForm
 */
export function LocationDropdownField() {
	const [t, _i18n] = useTranslation();
	const [filters, { loadLocationOptions, handleLocationFilter, clearSelectedLocation }] = useLocationDropdown();
	return (
		<>
			<Box marginBottom={1}>
				<InputLabel>{t('Location')}</InputLabel>
			</Box>

			<AsyncPaginateLocationDropdown<FBIDAndIDType, ADDITIONAL_LOAD_OPTIONS<Location>>
				siteFilter={filters.site}
				locationFilter={filters.location}
				loadLocations={loadLocationOptions}
				handleLocationFilter={handleLocationFilter}
				locationDisabled={!filters.site} // disable the location dropdown if the site is not selected
				isClearable
				onClear={clearSelectedLocation}
			/>
		</>
	);
}

const loadSublocationOptionsFactory = (
	locationFilter: ReactSelectLocationOptionType<FBIDAndIDType> | null,
): LoadOptions<ReactSelectSublocationOptionType<FBIDAndIDType>, ADDITIONAL_LOAD_OPTIONS<SubLocation>> => {
	// NOTE: Be careful, an error in this function is not logged! That's an issue with the async paginate component.
	return async (
		inputValue, // user search value
		_options, // already loaded options
		additional, // passed to the component as props
	) => {
		const { docs } = await fire
			.getSearchSubLocationsPaginated(
				NUMBER_OF_SUBLOCATIONS_TO_LOAD,
				locationFilter ? locationFilter.value.id : null,
				true,
				inputValue,
				additional ? additional.lastLoadedDocSnapshot : null,
			)
			.get()
			// we need to handle errors here, because the async paginate component will just loop on loadOptions if there is an error
			// without even loggin it!
			.catch(err => {
				console.error(err);
				throw err;
			});
		return buildLoadOptionsResponseObject(docs, docs.length === NUMBER_OF_SUBLOCATIONS_TO_LOAD, doc => ({
			label: doc.data().SubLocationName,
			value: { fbid: doc.data().ID!, id: doc.data().SubLocationID! },
		}));
	};
};

function useSubLocationDropdown() {
	const { filters, setFilters } = useContext(SiteSublocAssetFiltersCtx);

	const clearSelectedSubLocation = useCallback(
		() => setFilters(filters => ({ ...filters, subLocation: null, assetName: null })),
		[setFilters],
	);
	// see https://react-select.com/typescript#onchange for the type of this function
	const handleSubLocationFilter = useCallback(
		(option: ReactSelectSublocationOptionType<FBIDAndIDType> | null) =>
			setFilters(filters => ({ ...filters, subLocation: option, assetName: null })),
		[setFilters],
	);
	// we use a memo here to avoid re-rendering the sublocation dropdown
	const loadSubLocationOptions = useMemo(() => loadSublocationOptionsFactory(filters.location || null), [filters]);
	return [filters, { handleSubLocationFilter, clearSelectedSubLocation, loadSubLocationOptions }] as const;
}

/**
 * A sublocation dropdown field, fetch the sublocations and paginate them, it is used in the SiteSubLocAssetForm
 */
export function SubLocationDropdownField() {
	const [t, _i18n] = useTranslation();
	const [
		filters,
		{ handleSubLocationFilter, clearSelectedSubLocation, loadSubLocationOptions },
	] = useSubLocationDropdown();
	return (
		<>
			<Box marginBottom={1}>
				<InputLabel>{t('Sublocation')}</InputLabel>
			</Box>
			<AsyncPaginateSubLocationsDropdown<FBIDAndIDType, ADDITIONAL_LOAD_OPTIONS<SubLocation>>
				siteFilter={filters.site}
				locationFilter={filters.location}
				subLocationFilter={filters.subLocation}
				loadSubLocations={loadSubLocationOptions}
				handleSubLocationFilter={handleSubLocationFilter}
				subLocationDisabled={!filters.location} // disable the sublocation dropdown if the location is not selected
				isClearable
				onClear={clearSelectedSubLocation}
			/>
		</>
	);
}
