import React from 'react';
import AddAssetButton from '../asset/AddAssetButton';

interface AddAssetToFormButtonProps {
	formID: string;
	questionAnswerID: string;
	disabled: boolean;
	formTemplateFBID: string;
	jobID: string;
}

const AddAssetToFormButton = (props: AddAssetToFormButtonProps) => {
	const { formID, questionAnswerID, disabled, formTemplateFBID, jobID } = props;
	return (
		<AddAssetButton
			documentID={formID}
			documentType="form"
			disabled={disabled}
			otherStateParams={{ questionAnswerID }}
			formTemplateFBID={formTemplateFBID} 
			jobID={jobID}
		/>
	);
};

export default AddAssetToFormButton;
