// React
import * as React from 'react';
import ReactPaginate from 'react-paginate';

// Material UI
import Icon from '@material-ui/core/Icon/Icon';
import '../../styles/pagination.css';
import { useTranslation } from 'react-i18next';

interface PaginationGridProps {
	documentsPerPage: number;
	pageCount: number;
	onPageChange: (e) => void;
	initialPage: number;
	fullArray: any[];
	collectionName: string | null;
}

const changePage = (e: any, fullArray: any[] | null, documentsPerPage: number) => {
	if (fullArray !== null) {
		const offset = e.selected === 0 ? 0 : e.selected * documentsPerPage;
		const NumberOfRows = offset + documentsPerPage;
		const array = fullArray;
		const NewTen = array !== null ? array.slice(offset, NumberOfRows) : null;
		return { paginationArray: NewTen, selected: e.selected };
	}
	return null;
};

const PaginationGrid = (props: PaginationGridProps) => {
	const [t, i18n] = useTranslation();
	return (
	<div>
		{props.fullArray.length === 0 ? null : props.fullArray.length === 1 ? (
			<h4 style={{ textAlign: 'center', color: 'var(--dark-green)', marginBottom: '-10px' }}>
				{props.fullArray.length} {props.collectionName} {t("Record Available")}
			</h4>
		) : (
			<div>
				<h4 style={{ textAlign: 'center', color: 'var(--dark-green)', marginBottom: '5px' }}>
					{props.fullArray.length} {props.collectionName} {t("Records Available")}
				</h4>
				{props.pageCount > 1 ? (
					<h4 style={{ textAlign: 'center', color: 'var(--dark-green)', marginBottom: '-10px' }}>
						{t("Showing Page") + " " + (props.initialPage + 1) + " " + t("Of") + " " + props.pageCount}
					</h4>
				) : null}
			</div>
		)}
		<ReactPaginate
			previousLabel={<Icon>chevron_left</Icon>}
			nextLabel={<Icon>chevron_right</Icon>}
			pageCount={props.pageCount}
			initialPage={props.initialPage}
			forcepage={props.initialPage}
			marginPagesDisplayed={2}
			pageRangeDisplayed={1}
			onPageChange={e => {
				const data = changePage(e, props.fullArray, props.documentsPerPage);
				if (data !== null) {
					props.onPageChange(data);
				}
			}}
			containerClassName={'pagination'}
		/>
	</div>
)};

export default PaginationGrid;
