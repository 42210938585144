import { Button, Fab, Grid, Icon } from '@material-ui/core';
import { History } from 'history';
import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fire } from '../..';
import Api from '../../apiConfiguration.json';
import RequestCard from '../../components/requests/RequestCard';
import RequestsSearchModal from '../../components/requests/RequestsSearchModal';
import QRCodeSearch from '../../components/shared/QRCodeSearch';
import SideDrawer from '../../components/shared/SideDrawer';
import LoadingSpinner from '../../components/Spinners/LoadingSpinner';
import useRequests from '../../hooks/Requests/useRequests';
import '../../styles/requests/requests-list.css';
import ApiKeyObj from '../../utils/ApiObjectKey';
import { getBaseURL } from '../../utils/getBaseURL';
import { SendErrorData } from '../../utils/WindowError';

interface RequestsViewProps {
	history: History;
	UserSettings: Store.UserSettings;
	t: any;
}

const RequestsView = ({ history, UserSettings }: RequestsViewProps) => {
	const [t, i18n] = useTranslation();
	return (
		<>
			<SideDrawer
				history={history}
				title={t("Requests List")}
				rightMenuButton={<QRCodeSearch history={history} />}
				colour="primary"
				handleLogout={() => fire.auth.signOut().then(() => location.reload())}
				User={UserSettings}
				versionApp={Api.VERSION}
				versionDb={Api.INDEXEDDB_VERSION}
				SendErrorData={SendErrorData}
				getBaseURL={getBaseURL}
				ApiKeyObj={ApiKeyObj}
			/>
			<RequestsList />
		</>
	)
};
const RequestsList = () => {
	const [t, i18n] = useTranslation();
	const {
		isLoading,
		isFiltered,
		isEndOfList,
		requests,
		loadMoreRequests,
		isSearchDialogOpen,
		openSearchDialog,
		closeSearchDialog,
		selectedSite,
		handleSiteChange,
		selectedLocation,
		handleLocationChange,
		selectedSubLocation,
		handleSubLocationChange,
		searchRequests,
		clearSearch,
		setSelectedStatus,
		selectedStatus,
		requestNumber,
		setRequestNumber
	} = useRequests();

	if (isLoading) {
		return <LoadingSpinner height="90vh" text={t("Loading Requests...")} />;
	}

	if (requests.length === 0 && !isFiltered) {
		return (
			<Grid container justify="center" alignItems="center" style={{ height: '80vh' }}>
				<h1 className="no-requests-text">{t("There are no Requests available.")}</h1>
			</Grid>
		);
	}

	return (
		<div className="requests-container">
			<div className="requests-list">
				{requests.length === 0 ? (
					<Grid container justify="center" alignItems="center" style={{ height: '70vh' }}>
						<h1 className="no-requests-text">{t("There are no Requests matching your search criterias.")}</h1>
					</Grid>
				) : (
					<>
						{requests.map(request => (
							<Link to={`/request-details/${request.ID}`} key={request.ID}>
								<RequestCard request={request} />
							</Link>
						))}
						{isEndOfList ? (
							<Button variant="outlined" size="large" fullWidth disabled>
								{t("All Requests Loaded")}
							</Button>
						) : (
							<Button variant="outlined" color="primary" size="large" fullWidth onClick={loadMoreRequests}>
								{t("Load More Requests")}
							</Button>
						)}
					</>
				)}
				<Fab
					className={isFiltered ? 'not-hidden' : 'hidden'}
					id="clear-search-fab"
					color="inherit"
					style={{ backgroundColor: 'var(--light-red)' }}
					onClick={clearSearch}
				>
					<Icon style={{ color: 'white' }}>clear</Icon>
				</Fab>
				<Fab
					id="search-fab"
					color="inherit"
					style={{ backgroundColor: 'var(--light-blue)' }}
					onClick={openSearchDialog}
				>
					<Icon style={{ color: 'white' }}>search</Icon>
				</Fab>
				<RequestsSearchModal
					isOpen={isSearchDialogOpen}
					close={closeSearchDialog}
					searchRequests={searchRequests}
					selectedSite={selectedSite}
					handleSiteChange={handleSiteChange}
					selectedLocation={selectedLocation}
					handleLocationChange={handleLocationChange}
					selectedSubLocation={selectedSubLocation}
					handleSubLocationChange={handleSubLocationChange}
					setSelectedStatus={setSelectedStatus}
					selectedStatus={selectedStatus}
					requestNumber={requestNumber}
					setRequestNumber={setRequestNumber}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings as Store.UserSettings,
});

export default withTranslation()(connect(mapStateToProps)(RequestsView));
