
const frMonths = require('cldr-dates-full/main/fr/ca-gregorian.json');
const deMonths = require('cldr-dates-full/main/de/ca-gregorian.json');
const brMonths = require('cldr-dates-full/main/br/ca-gregorian.json');
const esMonths = require('cldr-dates-full/main/es/ca-gregorian.json')
const zzMonths = require('cldr-dates-full/main/ar-SA/ca-gregorian.json')
const heMonths = require('cldr-dates-full/main/he/ca-gregorian.json')

// return json import based on the passed in culture
export function getCalendarLocalizationMonths(culture) {
    switch(culture){
        case "fr": 
            return frMonths;
        case "de":
            return deMonths;
        case "br":
            return brMonths;
        case "es":
            return esMonths;
        case "zz":
            return zzMonths;
        case "he":
            return heMonths;
        default:
            return null;
    }
}
