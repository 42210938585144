export function dynamicSort(property) {
	let sortOrder = 1;
	if (property[0] === '-') {
		sortOrder = -1;
		property = property.substr(1);
	}
	return (a, b) => {
		const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
		return result * sortOrder;
	};
}

export function sortJobNumber(a: { JobNumber: number }, b: { JobNumber: number }) {
	if (a.JobNumber > b.JobNumber) {
		return 1;
	} else {
		return -1;
	}
}
