// React
import * as React from 'react';
import { History } from 'history';
import { connect } from 'react-redux';

// Config File
import Api from '../../apiConfiguration.json';

// Firebase + indexedDB + localQueue
import firebase from 'firebase';
import { fire, localQueue, idb } from '../../index';

// dexie import for IndexedDB
import Dexie from 'dexie';

// Material UI
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon/Icon';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Fab from '@material-ui/core/Fab/Fab';

// Actions
import { clearCurrentJobsInfo } from '../../actions/JobActions';

// Components
import JobCard from '../../components/job/JobCard';
import SearchJobsModal from '../../components/job/SearchJobsModal';
import SideDrawer from '../../components/shared/SideDrawer';
import LoadingSpinner from '../../components/Spinners/LoadingSpinner';
import BackButton from '../../components/shared/BackButton';

// Utils
import { LocalstorageRemoveItem, LocalstorageGetItem, LocalstorageSetItem } from '../../utils/LocalStorage';
import { isNullOrUndefined } from 'util';
import { windowError, SendErrorData } from '../../utils/WindowError';
import { getBaseURL } from '../../utils/getBaseURL';
import ApiKeyObj from '../../utils/ApiObjectKey';
import { isTodaysDate } from '../../utils/Times';

// CSS
import '../../styles/job/job-list.css';
import QRCodeDialog from '../../components/shared/QRCodeDialog';
import { readQRCodeAndNavigate } from '../../utils/QRCode';
import { useTranslation, withTranslation } from "react-i18next";
import i18n from '../../i18n';
import { getArrayChunks } from '../../utils/Converters';
import { BusinessTypes } from '../../utils/shared';

interface JobListScreenProps {
	history: History<any>;
	UserSettings: Store.UserSettings;
	clearCurrentJobsInfo: () => void;
	JobsInfo: Store.JobsInfoState;
	t: any;
}

interface JobListScreenState {
	jobs: Job.Job[];
	lastLoadedJob: any;
	loading: boolean;
	loadingMore: boolean;
	endOfList: boolean;
	searchModalOpen: boolean;
	searchInput: string;
	openClosedJobFilter: string;
	plannedReactiveJobFilter: string;
	isDueToday: boolean;
	documentsPerPage: number;
	yAxisScroll: number;
	order: string;
	notificationColor: string;

	// AssignedUser Filter
	userFilter?: {
		value: number;
		label: string;
	} | null;
	loadedUsers: any[];
	usersPerPage: number;
	userValue: string;
	endUser: boolean;
	lastLoadedUser: any;

	// Sites, Locations and Sub Locations for Search Filter
	siteFilter?: {
		value: number;
		label: string;
	} | null;
	loadedSites: any[];
	sitesPerPage: number;
	siteValue: string;
	endSite: boolean;
	lastLoadedSite: any;

	locationsPerPage: number;
	lastLoadedLocation: any;
	locationValue: string;
	loadedLocations: [];
	endLocation: boolean;
	locationFilter?: {
		value: number;
		label: string;
	} | null;
	locationDisabled: boolean;

	subLocationsPerPage: number;
	lastLoadedSubLocation: any;
	subLocationValue: string;
	loadedSubLocations: [];
	endSubLocation: boolean;
	subLocationFilter?: {
		value: number;
		label: string;
	} | null;
	subLocationDisabled: boolean;

	SearchbyQr: boolean;
	displayBackButton: boolean;

	notificationCount: number;
	activeNotifications: boolean;
	jobNumber: string;
	dataDownloadLoading: boolean;
	downloadItemText: string;
	showDownloadAlert: boolean;
}

class JobListScreen extends React.Component<JobListScreenProps, JobListScreenState> {
	private unsubscribe: any = null;

	constructor(props) {
		super(props);
		this.state = {
			jobs: [],
			lastLoadedJob: null,
			loading: false,
			loadingMore: false,
			endOfList: false,
			searchModalOpen: false,
			searchInput: '',
			openClosedJobFilter: this.props.t("Open"),
			plannedReactiveJobFilter: '',
			isDueToday: false,
			documentsPerPage: 20,
			yAxisScroll: 0,
			order: 'DESC',
			notificationColor: 'white',

			loadedUsers: [],
			userFilter: null,
			usersPerPage: 50,
			userValue: '',
			endUser: false,
			lastLoadedUser: null,

			loadedSites: [],
			siteFilter: null,
			sitesPerPage: 50,
			siteValue: '',
			endSite: false,
			lastLoadedSite: null,

			locationsPerPage: 50,
			lastLoadedLocation: null,
			locationValue: '',
			loadedLocations: [],
			endLocation: false,
			locationFilter: null,
			locationDisabled: true,

			subLocationsPerPage: 50,
			lastLoadedSubLocation: null,
			subLocationValue: '',
			loadedSubLocations: [],
			endSubLocation: false,
			subLocationFilter: null,
			subLocationDisabled: true,

			SearchbyQr: false,
			displayBackButton: false,
			notificationCount: 0,
			activeNotifications: false,

			jobNumber: '',
			dataDownloadLoading: false,
			downloadItemText: "",
			showDownloadAlert: false
		};
		this._onOfflineDataDownloadClick = this._onOfflineDataDownloadClick.bind(this);
	}

	componentWillMount() {
		windowError(
			this.props.UserSettings.email,
			this.props.UserSettings.UserUID,
			this.props.UserSettings.ServerName,
			'JobListScreen',
		);

		//Checks for documents in local queue and processes them if needed
		localQueue.getDocumentsInLocalQueue();

		if (
			!isNullOrUndefined(this.props.history.location.state) &&
			window.location.href.includes('associated-jobs')
		) {

			this.setState({ displayBackButton: true });

			const {
				SelectedSiteID,
				SelectedSiteName,
				SelectedLocationID,
				SelectedLocationName,
				SelectedSubLocationID,
				SelectedSubLocationName
			} = this.props.history.location.state;

			const siteFilter = {
				value: SelectedSiteID,
				label: SelectedSiteName
			};

			const locationFilter = {
				value: SelectedLocationID,
				label: SelectedLocationName
			};

			const subLocationFilter = {
				value: SelectedSubLocationID,
				label: SelectedSubLocationName
			};

			if (this.props.history.location.state.SelectedSubLocationID != null) {
				this.setState({
					siteFilter,
					locationFilter,
					subLocationFilter
				}, () => this.handleLocalStorageFilters());
				return;
			}

			else if (this.props.history.location.state.SelectedLocationID != null) {
				this.setState({
					siteFilter,
					locationFilter,
				}, () => this.handleLocalStorageFilters());
				return;
			}

			this.setState({
				siteFilter,
			}, () => this.handleLocalStorageFilters());
		}
	}

	async componentDidMount() {
		// Clear Scroll Y Redux State if coming from sidebar

		const routerState = this.props.history.location.state;
		this.getNotifications();
		if (!isNullOrUndefined(routerState) && routerState.clearScrollState) {
			this.props.clearCurrentJobsInfo();
		}

		if (navigator.onLine) {
			await fire.GetAppUpdateFlags();
			await this.checkIndexedDBVersion();
		}


		if (!isNullOrUndefined(this.props.UserSettings.Language))
			i18n.changeLanguage(this.props.UserSettings.Language)
		else if (!isNullOrUndefined(this.props.UserSettings.ClientLanguage))
			i18n.changeLanguage(this.props.UserSettings.ClientLanguage)
		else
			i18n.changeLanguage("English");

		LocalstorageRemoveItem('NewJobDetails');
		const localItem = (await LocalstorageGetItem('JobFilterOptions')) || '{}';
		const JsonObj = JSON.parse(localItem);
		await this.setState(
			{
				searchInput: JsonObj.searchInput,
				plannedReactiveJobFilter: JsonObj.plannedReactiveJobFilter,
				openClosedJobFilter: !isNullOrUndefined(JsonObj.openClosedTaskFilter) ? JsonObj.openClosedTaskFilter : 'Open',
				isDueToday: JsonObj.IsDueToday,
				siteFilter: JsonObj.siteFilter || null,
				locationFilter: JsonObj.locationFilter || null,
				subLocationFilter: JsonObj.subLocationFilter || null,
				userFilter: JsonObj.userFilter || null,
				yAxisScroll: JsonObj.YAxisScroll,
				order: JsonObj.order || 'DESC',
				jobNumber: JsonObj.jobNumber || ''
			},
			() => {

				if (!isNullOrUndefined(this.state.siteFilter)) {
					fire.doesLocationsExist(this.state.siteFilter.value).then(async (res) => {
						await this.setState({ locationDisabled: !res });
					})
				}

				if (!isNullOrUndefined(this.state.siteFilter) && !isNullOrUndefined(this.state.locationFilter)) {
					fire.doesSubLocationsExist(this.state.locationFilter.value).then(async (res) => {
						await this.setState({ subLocationDisabled: !res });
					})
				}

				this.searchJobs();
				idb.removeCompletedPhotosFromTable();

				if ('storage' in navigator && 'estimate' in navigator.storage) {
					navigator.storage.estimate().then(estimate => {
						if (!isNullOrUndefined(estimate.usage) && !isNullOrUndefined(estimate.quota)) {
							console.log(
								`Using ${Math.round((estimate.usage / 1024 / 1024) * 100) / 100}MB out of ${(Math.round(
									estimate.quota / 1024 / 1024,
								) *
									100) /
								100}MB.`,
							);
						}
					});
				}
			},
		);
	}

	/* Download data for offline start */

	private async _onOfflineDataDownloadClick() {

		if (navigator.onLine) {
			this.setState({ dataDownloadLoading: true, downloadItemText: "Jobs" });
			let jobArrayChunks: string[] = [];

			const loadedJobIds = this.state.jobs &&
				this.state.jobs.map(_job => _job.FirebaseId) || [];

			const completedJobIds = this.state.jobs &&
				this.state.jobs.filter(jobItem => jobItem.CompletedDate)
					.map(_jobItem => _jobItem.FirebaseId) || [];

			if (loadedJobIds && loadedJobIds.length) {
				jobArrayChunks = getArrayChunks(loadedJobIds, 10);

				// Clear inactive job docs from indexdb
				if (loadedJobIds && loadedJobIds.length) {
					await idb.removeInactiveDocumentsFromIDB(
						BusinessTypes.Job,
						loadedJobIds,
						completedJobIds
					);
				}

				// get Job Notes				
				this.setState({ downloadItemText: "Job Notes" });
				const CanViewAllNotes = this.props.UserSettings &&
					this.props.UserSettings.CanViewAllNotes;

				for (let chunk of jobArrayChunks) {
					await fire.getNotesForLoadedJobs(chunk, CanViewAllNotes)
						.then(() => console.info("Job Notes downloaded"))
						.catch((err) => console.warn("Error with downloading Job Notes"));
				}

				// get Job forms
				this.setState({ downloadItemText: "Job Forms" });
				for (let chunk of jobArrayChunks) {
					await fire.getFormsForLoadedData(chunk, "JobFBID", "Job")
						.then(() => console.info("Site Forms downloaded"))
						.catch((err) => console.warn("Error with downloading site Forms"));
				}

				// get Job form Templates
				this.setState({ downloadItemText: "Job Form Templates" });
				await fire.getFormTemplates()
					.then(() => console.info("Job Form Templates downloaded"))
					.catch((err) => console.warn(`Error with downloading Job Form Templates ${err}`));

				// get job photos
				this.setState({ downloadItemText: "Job Photos" });
				for (let jobId of loadedJobIds) {
					await idb.syncPhotosOrDocsFromFireBase(
						`Jobs/${jobId}/Documents`,
						`${jobId}`,
						BusinessTypes.Job,
						true
					);
				}

				this.setState({ dataDownloadLoading: false, downloadItemText: "" });
			}
		} else {
			this.setState({ showDownloadAlert: true }, () => {
				setTimeout(() => {
					this.setState({ showDownloadAlert: false });
				}, 2000);
			})
		}
	}

	/* Download data for offline end */

	async checkIndexedDBVersion() {
		await new Dexie('TrackplanManagerDB').open().then(async db => {
			const DbVersion = parseInt(Api.INDEXEDDB_VERSION);
			if (db.verno !== DbVersion) {
				let URL = '';
				if (Api.ENVIRONMENT === 'development') {
					URL = 'https://manager-staging.trackplanfm.com/#/job-task-list';
				} else {
					URL = 'https://manager.trackplanfm.com/#/job-task-list';
				}

				await SendErrorData(
					this.props.UserSettings.email,
					this.props.UserSettings.UserUID,
					this.props.UserSettings.ServerName,
					this.props.t("IndexedDB Version was not the latest version upgrade was required"),
					URL,
					'JobListScreen',
				);
				self.indexedDB.deleteDatabase('TrackplanManagerDB');
				window.location.reload();
			}
		});
	}

	componentWillUnmount() {
		this.unsubscribe = null;
		window.removeEventListener('scroll', this.handleScroll);
	}

	loadUsers = async () => {
		return new Promise((resolve) => {
			console.log("last loaded user", this.state.lastLoadedUser);
			fire.getAssignedUsersPaginated(this.state.usersPerPage, this.state.lastLoadedUser)
				.get().then(docs => {
					if (!docs.empty) {
						let users: any = [];
						docs.forEach(function (user) {
							const userData = {
								value: user.id,
								label: user.data().Name
							}
							users.push(userData);
						});
						this.setState({ loadedUsers: users, lastLoadedUser: docs.docs[docs.docs.length - 1] }, () => {
							return resolve({
								options: this.state.loadedUsers,
								hasMore: !this.state.endUser
							});
						})
					} else {
						this.setState({ endUser: true });
						return resolve({
							options: [],
							hasMore: !this.state.endUser
						});
					}
				})
		})
	}

	loadOptions = async (siteName, loadedOptions) => {
		if (siteName !== this.state.siteValue) {
			await this.setState({
				loadedSites: [],
				lastLoadedSite: null,
				endSite: false,
				siteValue: siteName
			});
			loadedOptions = [];
		}

		return new Promise((resolve) => {
			fire.getSearchSitesPaginated(
				this.state.sitesPerPage,
				this.state.siteValue,
				this.state.lastLoadedSite,
				this.props.UserSettings.CanAccessAllSites,
				this.props.UserSettings.ContractFBID
			)
				.get()
				.then(docs => {
					if (!docs.empty) {
						let sites: any = [];
						docs.forEach(function (site) {
							const siteData = {
								value: site.data().SiteID,
								label: site.data().SiteName
							}
							sites.push(siteData);
						});
						this.setState({ loadedSites: sites, lastLoadedSite: docs.docs[docs.docs.length - 1] }, () => {
							return resolve({
								options: this.state.loadedSites,
								hasMore: !this.state.endSite
							});
						})
					} else {
						this.setState({ endSite: true });
						return resolve({
							options: [],
							hasMore: !this.state.endSite
						});
					}
				})
		})
	}

	loadLocations = async (locationName, loadedOptions) => {

		if (locationName !== this.state.locationValue) {
			await this.setState({
				loadedLocations: [],
				lastLoadedLocation: null,
				locationValue: locationName
			});
			loadedOptions = [];
		}

		return new Promise((resolve) => {

			if (!isNullOrUndefined(this.state.siteFilter)) {
				fire.getSearchLocationsPaginated(
					this.state.locationsPerPage,
					this.state.siteFilter.value,
					this.props.UserSettings.CanAccessAllLocations,
					this.state.locationValue,
					this.state.lastLoadedLocation)
					.get()
					.then(docs => {
						if (!docs.empty) {
							let locations: any = [];
							docs.forEach(function (location) {
								const locationData = {
									value: location.data().LocationID,
									label: location.data().LocationName
								}
								locations.push(locationData);
							});
							this.setState({ loadedLocations: locations, lastLoadedLocation: docs.docs[docs.docs.length - 1] }, () => {
								return resolve({
									options: this.state.loadedLocations,
									hasMore: !this.state.endLocation
								});
							})
						} else {
							this.setState({ endLocation: true });
							return resolve({
								options: [],
								hasMore: !this.state.endLocation
							});
						}
					})
			} else {
				return resolve({
					options: [],
					hasMore: false
				})
			}
		})
	}

	loadSubLocations = async (subLocationName, loadedOptions) => {
		if (subLocationName !== this.state.subLocationValue) {
			await this.setState({
				loadedSubLocations: [],
				lastLoadedSubLocation: null,
				subLocationValue: subLocationName,
			});
			loadedOptions = [];
		}

		return new Promise((resolve) => {

			if (!isNullOrUndefined(this.state.locationFilter)) {
				fire
					.getSearchSubLocationsPaginated(
						this.state.subLocationsPerPage,
						this.state.locationFilter.value,
						this.props.UserSettings.CanAccessAllLocations,
						this.state.subLocationValue,
						this.state.lastLoadedSubLocation,
					)
					.get()
					.then(docs => {
						if (!docs.empty) {
							let subLocations: any = [];
							docs.forEach(function (subLocation) {
								const subLocationData = {
									value: subLocation.data().SubLocationID,
									label: subLocation.data().SubLocationName
								}
								subLocations.push(subLocationData);
							});
							this.setState({ loadedSubLocations: subLocations, lastLoadedSubLocation: docs.docs[docs.docs.length - 1] }, () => {
								return resolve({
									options: this.state.loadedSubLocations,
									hasMore: !this.state.endSubLocation
								});
							})
						} else {
							this.setState({ endSubLocation: true });
							return resolve({
								options: [],
								hasMore: !this.state.endSubLocation
							});
						}
					})
			} else {
				return resolve({
					options: [],
					hasMore: false
				})
			}
		})
	}

	getNotifications = () => {
		let query = fire.baseQuery.collection('Jobs').where('UsersNotified', 'array-contains', this.props.UserSettings.UserUID);
		query.onSnapshot(this.handleNotifications);
	};

	handleNotifications = async (query: firebase.firestore.QuerySnapshot) => {
		if (query.empty) {
			return;
		}

		let notificationTaskList = isNullOrUndefined(this.state.lastLoadedJob) ? [] : this.state.jobs;

		const docChanges = query.docChanges();
		for (const job of docChanges) {
			const notificationTask = job.doc.data() as Job.Job;
			notificationTaskList.push(notificationTask);
		}

		if (query.docs.length > this.state.notificationCount) {
			//Check if service worker is online and if the manager has approved the user to receive notifications.
			//this.props.userSettings.enableFirebaseNotifications (replace true with this for live deployment).
			this.setState({ activeNotifications: true, notificationColor: 'red', notificationCount: query.docs.length });
		}
	};

	handleScroll = () => {
		this.setState({ yAxisScroll: window.scrollY }, () => {
			this.handleLocalStorageFilters(window.scrollY);
		});
	};

	handleSearchModal = () => {
		this.setState({
			searchModalOpen: !this.state.searchModalOpen,
			lastLoadedSite: null,
			lastLoadedUser: null,
			lastLoadedLocation: null,
			lastLoadedSubLocation: null
		});
	};

	handleOpenClosedFilter = selection => {
		this.setState({ openClosedJobFilter: selection.target.value });
	};

	handlePlannedReactiveFilter = selection => {
		this.setState({ plannedReactiveJobFilter: selection.target.value });
	};

	handleSearchInput = input => {
		this.setState({ searchInput: input });
	};

	handleIsDueTodayCheckbox = isChecked => {
		this.setState({ isDueToday: isChecked });
	};

	handleUserFilter = user => {
		this.setState({
			userFilter: { value: user.value, label: user.label },
			lastLoadedUser: null,
		});
	}

	handleJobNumber = (jobNumber: string) => {
		this.setState({
			jobNumber: jobNumber,
		})
	}

	handleSiteFilter = site => {
		this.setState({
			siteFilter: { value: site.value, label: site.label },
			lastLoadedSite: null,
			locationFilter: null,
			loadedLocations: [],
			loadedSubLocations: [],
			lastLoadedLocation: null,
			lastLoadedSubLocation: null,
			subLocationFilter: null,
			locationValue: '',
			subLocationValue: '',

			locationDisabled: true,
			subLocationDisabled: true,
		}, () => {
			if (!isNullOrUndefined(this.state.siteFilter)) {
				fire.doesLocationsExist(this.state.siteFilter.value).then((res) => {
					this.setState({
						locationDisabled: !res
					})
				})
			}
		});
	}

	handleLocationFilter = location => {
		this.setState({
			locationFilter: { value: location.value, label: location.label },
			lastLoadedLocation: null,
			lastLoadedSubLocation: null,
			subLocationValue: '',
			loadedSubLocations: [],

			subLocationDisabled: true,
		}, () => {
			if (!isNullOrUndefined(this.state.siteFilter) && !isNullOrUndefined(this.state.locationFilter)) {
				fire.doesSubLocationsExist(this.state.locationFilter.value).then((res) => {
					this.setState({
						subLocationDisabled: !res
					})
				})
			}
		});

	}

	handleSubLocationFilter = subLocation => {
		this.setState({
			subLocationFilter: { value: subLocation.value, label: subLocation.label },
			lastLoadedSubLocation: null
		});
	};

	backButton = () => {
		window.history.back()
	};

	searchJobs = () => {
		const jobFilterOptions = {
			// searchInput: this.state.searchInput,
			openClosed: this.state.openClosedJobFilter,
			plannedReactive: this.state.plannedReactiveJobFilter,
			isDueToday: this.state.isDueToday,
			site: !isNullOrUndefined(this.state.siteFilter) ? this.state.siteFilter.value : null,
			location: !isNullOrUndefined(this.state.locationFilter) ? this.state.locationFilter.value : null,
			subLocation: !isNullOrUndefined(this.state.subLocationFilter) ? this.state.subLocationFilter.value : null,
			assignedUser: !isNullOrUndefined(this.state.userFilter) ? this.state.userFilter.value : null,
			order: this.state.order.toLowerCase(),
			jobNumber: this.state.jobNumber,
		};

		let canOnlyViewCreatedJobs = !isNullOrUndefined(this.props.UserSettings.CanOnlyViewCreatedJobs) ? this.props.UserSettings.CanOnlyViewCreatedJobs : false;

		if (this.props.JobsInfo.totalJobsLoaded > 0) {
			const { totalJobsLoaded } = this.props.JobsInfo;
			this.setState({ yAxisScroll: window.scrollY, loading: true }, () => {
				this.unsubscribe = fire
					.getJobPaginated(totalJobsLoaded, this.props.UserSettings.UserUID, this.props.UserSettings.CanAccessAllJobs, this.state.lastLoadedJob, jobFilterOptions, canOnlyViewCreatedJobs)
					.get()
					.then(query => {
						this.getJobsOnce(query);
					})
			});
		} else {
			this.setState({ yAxisScroll: window.scrollY, loading: true }, () => {
				this.unsubscribe = fire
					.getJobPaginated(this.state.documentsPerPage, this.props.UserSettings.UserUID, this.props.UserSettings.CanAccessAllJobs, this.state.lastLoadedJob, jobFilterOptions, canOnlyViewCreatedJobs)
					.get()
					.then(query => {
						this.getJobsOnce(query);
					})
			});
		}
	};

	getJobsOnce = (query: any) => {
		if (query.empty || query.docChanges().length < 1) {
			this.setState({ loading: false, loadingMore: false, endOfList: true });
			window.scrollTo(0, this.state.yAxisScroll);
			if (isNullOrUndefined(this.state.lastLoadedJob)) this.setState({ jobs: [] });
			return;
		}

		let JobList: Job.Job[] = isNullOrUndefined(this.state.lastLoadedJob) ? [] : this.state.jobs;

		query.docChanges().forEach(docChange => {
			const change = docChange;
			const jobObj = docChange.doc.data() as Job.Job;
			jobObj.Id = docChange.doc.id;

			const index = JobList.findIndex(x => x.Id === jobObj.Id);

			if (this.props.UserSettings.CanAccessAllJobs) {
				if (index === -1) {
					JobList.push(jobObj);
				} else {
					JobList[index] = jobObj;
					this.getForms(jobObj.Id, jobObj.DateCreated);
				}
			} else {
				let isPresent = false;
				const usersPermittedArray = jobObj.UsersPermittedToJob;

				if (usersPermittedArray === undefined && usersPermittedArray === null) {
					isPresent = true;
				} else if (usersPermittedArray !== undefined && usersPermittedArray !== null) {
					isPresent = usersPermittedArray.includes(this.props.UserSettings.UserUID);
				}

				if (isPresent) {
					if (index === -1) {
						JobList.push(jobObj);
					} else {
						JobList[index] = jobObj;
						this.getForms(jobObj.Id, jobObj.DateCreated);
					}
				} else {
					if (index >= 0) {
						JobList.splice(index, 1);
					}
				}
			}

			if (change.type === 'removed') {
				JobList.splice(index, 1);
			}
		});

		JobList = this.sortingFunction(JobList);

		this.setState(
			{
				jobs: JobList,
				lastLoadedJob: query.docs[query.docs.length - 1],
				loading: false,
				loadingMore: false,
			},
			() => {
				if (this.props.JobsInfo.totalJobsLoaded != 0) {
					const { yAxisPos } = this.props.JobsInfo;
					window.scrollTo(0, yAxisPos);

					this.props.clearCurrentJobsInfo();
				} else {
					window.scrollTo(0, this.state.yAxisScroll);
				}
			},
		);
	};

	handleJobSnapshot = (query: firebase.firestore.QuerySnapshot) => {
		if (query.empty || query.docChanges().length < 1) {
			this.setState({ loading: false, loadingMore: false, endOfList: true });
			window.scrollTo(0, this.state.yAxisScroll);
			if (isNullOrUndefined(this.state.lastLoadedJob)) this.setState({ jobs: [] });
			return;
		}

		let JobList: Job.Job[] = isNullOrUndefined(this.state.lastLoadedJob) ? [] : this.state.jobs;

		query.docChanges().forEach(docChange => {
			const change = docChange;
			const jobObj = docChange.doc.data() as Job.Job;
			jobObj.Id = docChange.doc.id;

			const index = JobList.findIndex(x => x.Id === jobObj.Id);

			if (this.props.UserSettings.CanAccessAllJobs) {
				if (index === -1) {
					JobList.push(jobObj);
				} else {
					JobList[index] = jobObj;
					this.getForms(jobObj.Id, jobObj.DateCreated);
				}
			} else {
				let isPresent = false;
				const usersPermittedArray = jobObj.UsersPermittedToJob;

				if (usersPermittedArray === undefined && usersPermittedArray === null) {
					isPresent = true;
				} else if (usersPermittedArray !== undefined && usersPermittedArray !== null) {
					isPresent = usersPermittedArray.includes(this.props.UserSettings.UserUID);
				}

				if (isPresent) {
					if (index === -1) {
						JobList.push(jobObj);
					} else {
						JobList[index] = jobObj;
						this.getForms(jobObj.Id, jobObj.DateCreated);
					}
				} else {
					if (index >= 0) {
						JobList.splice(index, 1);
					}
				}
			}

			if (change.type === 'removed') {
				JobList.splice(index, 1);
			}
		});

		JobList = this.sortingFunction(JobList);

		this.setState(
			{
				jobs: JobList,
				lastLoadedJob: query.docs[query.docs.length - 1],
				loading: false,
				loadingMore: false,
			},
			() => {
				if (this.props.JobsInfo.totalJobsLoaded != 0) {
					const { yAxisPos } = this.props.JobsInfo;
					window.scrollTo(0, yAxisPos);

					this.props.clearCurrentJobsInfo();
				} else {
					window.scrollTo(0, this.state.yAxisScroll);
				}
			},
		);
	};

	getForms(JobFBID: string, CreatedDate: number) {
		if (isTodaysDate(CreatedDate)) {
			fire.getFormsForJob(JobFBID).onSnapshot(formDocs => {
				if (formDocs.empty) return;
				formDocs.docs.forEach(form => {
					form.ref.collection('QuestionAnswers').get();
				});
			});
		}
	}

	areFiltersActive() {
		// const freeTextSearch = (this.state.searchInput || '').toLowerCase();
		const openClosedSearch = this.state.openClosedJobFilter || 'Open';
		const reactivePlannedSearch = this.state.plannedReactiveJobFilter || '';
		const isDueTodaySearch = this.state.isDueToday;
		const siteSearch = this.state.siteFilter;
		const locationSearch = this.state.locationFilter;
		const subLocationSearch = this.state.subLocationFilter;
		const userSearch = this.state.userFilter;
		const jobNumber = this.state.jobNumber;
		let filtersActive = false;
		if (
			// freeTextSearch !== '' ||
			openClosedSearch !== 'Open' ||
			reactivePlannedSearch !== '' ||
			!isNullOrUndefined(siteSearch) ||
			!isNullOrUndefined(locationSearch) ||
			!isNullOrUndefined(subLocationSearch) ||
			!isNullOrUndefined(userSearch) ||
			isDueTodaySearch ||
			(!isNullOrUndefined(jobNumber) && jobNumber !== '')
		) {
			filtersActive = true;
		}

		return filtersActive;
	}

	sortingFunction = (joblist: Job.Job[]) => {
		if (this.state.order === 'DESC') {
			return joblist.sort((a, b) => {
				if (a.JobNumber === b.JobNumber) return 0;
				else if (a.JobNumber === 0) return -1;
				else if (b.JobNumber === 0) return 1;
				else if (a.JobNumber < b.JobNumber) return 1;
				else if (b.JobNumber < a.JobNumber) return -1;
				else return 0;
			});
		} else {
			return joblist.sort((a, b) => {
				if (a.JobNumber === b.JobNumber) return 0;
				else if (a.JobNumber === 0) return -1;
				else if (b.JobNumber === 0) return 1;
				else if (a.JobNumber > b.JobNumber) return 1;
				else if (b.JobNumber > a.JobNumber) return -1;
				else return 0;
			});
		}
	};

	async handleClearSearch() {
		this.setState(
			{
				loading: true,
				searchInput: '',
				openClosedJobFilter: '',
				plannedReactiveJobFilter: '',
				isDueToday: false,
				siteFilter: null,
				lastLoadedJob: null,
				endOfList: false,
				loadedSites: [],
				lastLoadedSite: null,
				locationFilter: null,
				lastLoadedLocation: null,
				loadedLocations: [],
				subLocationFilter: null,
				lastLoadedSubLocation: null,
				loadedSubLocations: [],
				siteValue: '',
				locationValue: '',
				subLocationValue: '',
				locationDisabled: true,
				subLocationDisabled: true,
				userFilter: null,
				loadedUsers: [],
				lastLoadedUser: null,
				jobNumber: '',
			},
			() => {
				this.handleLocalStorageFilters();
				this.searchJobs();
				// this.filterJobs(this.state.jobs);
			}
		);
	}

	async handleJobSearch() {
		this.handleLocalStorageFilters();

		await this.setState(
			{
				loading: true,
				searchModalOpen: false,
				yAxisScroll: 0,
				lastLoadedJob: null,
				endOfList: false,
				loadedSites: [],
				lastLoadedSite: null,
				siteValue: '',
				loadedLocations: [],
				lastLoadedLocation: null,
				locationValue: '',
				loadedSubLocations: [],
				lastLoadedSubLocation: null,
				subLocationValue: '',
			},
			() => {
				this.setState({ yAxisScroll: 0 })
				this.searchJobs();
				// this.filterJobs(this.state.jobs);
			},
		);
	}

	async handleorderfilter() {
		const newOrder = this.state.order === 'DESC' ? 'ASC' : 'DESC';
		await this.setState({ order: newOrder, loading: true, lastLoadedJob: null }, async () => {
			// const jobList = await this.sortingFunction(this.state.jobs);
			// await this.filterJobs(jobList);
			this.searchJobs();
		});
	}

	handleLogout() {
		LocalstorageRemoveItem('NewJobDetails');
		LocalstorageRemoveItem('JobFilterOptions');
		LocalstorageRemoveItem('AssignedUserFilterOptions');
		LocalstorageRemoveItem('ResourceFilterOptions');
		LocalstorageRemoveItem('FormLibraryFilterOptions');
		fire.auth.signOut().then(() => location.reload());
	}

	handleLocalStorageFilters(yAxisScroll?: number) {
		const SearchArray = {
			searchInput: this.state.searchInput,
			openClosedTaskFilter: this.state.openClosedJobFilter,
			plannedReactiveJobFilter: this.state.plannedReactiveJobFilter,
			IsDueToday: this.state.isDueToday,
			siteFilter: this.state.siteFilter,
			locationFilter: this.state.locationFilter,
			subLocationFilter: this.state.subLocationFilter,
			userFilter: this.state.userFilter,
			YAxisScroll: yAxisScroll || this.state.yAxisScroll,
			order: this.state.order,
			jobNumber: this.state.jobNumber,
		};

		LocalstorageSetItem({ Key: 'JobFilterOptions', Value: SearchArray });
	}

	clearSelectedUser = () => {
		this.setState({
			userFilter: null,
			lastLoadedUser: null,
			loadedUsers: [],
			endUser: false,
		})
	}

	clearSelectedSitesOrLocations = async (typeId) => {
		// typeId = 1 (Site), typeId = 2 (Location), typeId = 3 (Sub Location)
		// If site is cleared, then location and sub location is also cleared as well since they depend on selected site id.
		// If location is cleared, then sub location is also cleared since it depends on selected location id.

		if (typeId === 1) {
			await this.setState({
				lastLoadedSite: null,
				loadedSites: [],
				siteFilter: null,
				siteValue: '',
				endSite: false,
				locationDisabled: true,
				subLocationDisabled: true,
			});
		}

		if (typeId === 1 || typeId === 2) {
			await this.setState({
				lastLoadedLocation: null,
				loadedLocations: [],
				locationFilter: null,
				locationValue: '',
				endLocation: false,
				subLocationDisabled: true,
			});
		}

		if (typeId === 1 || typeId === 2 || typeId === 3) {
			await this.setState({
				lastLoadedSubLocation: null,
				loadedSubLocations: [],
				subLocationFilter: null,
				subLocationValue: '',
				endSubLocation: false,
			});
		}

		this.handleLocalStorageFilters();
	}

	render() {
		if (this.state.loading && !this.areFiltersActive()) {
			return <LoadingSpinner text={this.props.t("Loading jobs...")} />;
		}
		if (this.state.loading && this.areFiltersActive()) {
			return <LoadingSpinner text={this.props.t("Searching jobs...")} />;
		}
		if (this.state.dataDownloadLoading && this.state.dataDownloadLoading) {
			return <LoadingSpinner
				text={this.props.t(`Downloading ${this.state.downloadItemText}...`)}
			/>;
		}
		if (this.state.showDownloadAlert) {
			return <LoadingSpinner
				text={this.props.t("Download not available as you are offline")}
				showAlert
			/>;
		}

		return (
			<div>
				<SideDrawer
					history={this.props.history}
					title={this.props.t("Job List")}
					rightMenuButton={
						<div>
							{navigator.onLine && (
								<IconButton
									onClick={this._onOfflineDataDownloadClick} color="primary">
									<Icon
										style={{ color: 'white', marginRight: "0.8em" }}
										title='Download data for offline use'
									>
										download_for_offline
									</Icon>
								</IconButton>
							)}
							<IconButton
								onClick={() => this.setState({ SearchbyQr: !this.state.SearchbyQr })}
								color="primary">
								<Icon style={{ color: 'white', marginRight: "1em" }}>
									image_search
								</Icon>
							</IconButton>
							<IconButton onClick={() => this.handleorderfilter()} style={{ marginRight: "0.8em" }}>
								<Icon style={{ color: 'white' }}>autorenew</Icon>
							</IconButton>
							<NewJobButton history={this.props.history} />
							{this.props.UserSettings.CanSeeNotificationBell ?
								<IconButton onClick={() => this.props.history.push('/notifications')} >
									<Icon style={{ color: this.state.notificationColor }}>notifications</Icon>
									<p style={{ color: 'white' }}>{this.state.notificationCount}</p>
								</IconButton> : null}
							{this.state.displayBackButton ? <BackButton callbackMethod={this.backButton} /> : ''}
						</div>
					}
					colour="primary"
					handleLogout={this.handleLogout}
					User={this.props.UserSettings}
					versionApp={Api.VERSION}
					versionDb={Api.INDEXEDDB_VERSION}
					SendErrorData={SendErrorData}
					getBaseURL={getBaseURL}
					ApiKeyObj={ApiKeyObj}
				/>
				<Grid container={true} direction="column" justify="center" alignItems="center">
					<div className="main">
						{this.state.jobs.length > 0 ? (
							<div>
								{this.state.jobs.map((job, i) => (

									<JobCard numJobs={this.state.jobs.length} key={i} job={job as Job.Job} />
								))}

								{this.state.endOfList ? (
									<Button id="outer-card-button" variant="outlined" size="large" fullWidth disabled>
										{this.props.t("All Jobs Loaded")}
									</Button>
								) : (
									<Button
										id="outer-card-button"
										variant="outlined"
										color="primary"
										size="large"
										fullWidth
										onClick={() => this.searchJobs()}
									>
										{this.props.t("Load More Jobs")}
									</Button>
								)}
							</div>
						) : (
							<NoJobs filtersActive={this.areFiltersActive()} />
						)}

						<Fab
							className={this.areFiltersActive() ? 'not-hidden' : 'hidden'}
							id="clear-search-fab"
							color="inherit"
							aria-label="Add"
							style={{ backgroundColor: 'var(--light-red)' }}
							onClick={() => this.handleClearSearch()}
						>
							<Icon style={{ color: 'white' }}>clear</Icon>
						</Fab>

						<Fab
							id="search-fab"
							color="inherit"
							aria-label="Add"
							style={{ backgroundColor: 'var(--light-blue)' }}
							onClick={() => this.handleSearchModal()}
						>
							<Icon style={{ color: 'white' }}>search</Icon>
						</Fab>

						<SearchJobsModal
							searchModalOpen={this.state.searchModalOpen}
							handleSearchModal={this.handleSearchModal}
							openClosedTaskFilter={this.state.openClosedJobFilter}
							handleOpenClosedFilter={this.handleOpenClosedFilter}
							plannedReactiveTaskFilter={this.state.plannedReactiveJobFilter}
							handlePlannedReactiveFilter={this.handlePlannedReactiveFilter}
							searchInput={this.state.searchInput}
							handleSearchInput={this.handleSearchInput}
							isDueTodayCheckboxInput={this.state.isDueToday}
							handleIsDueTodayCheckbox={this.handleIsDueTodayCheckbox}
							searchJobTasks={() => this.handleJobSearch()}
							siteFilter={this.state.siteFilter}
							siteValue={this.state.siteValue}
							loadOptions={this.loadOptions}
							handleSiteFilter={this.handleSiteFilter}
							locationFilter={this.state.locationFilter}
							locationValue={this.state.locationValue}
							loadLocations={this.loadLocations}
							handleLocationFilter={this.handleLocationFilter}
							subLocationFilter={this.state.subLocationFilter}
							subLocationValue={this.state.subLocationValue}
							loadSubLocations={this.loadSubLocations}
							handleSubLocationFilter={this.handleSubLocationFilter}
							clearSelectedSitesOrLocations={(typeId) => this.clearSelectedSitesOrLocations(typeId)}
							locationDisabled={this.state.locationDisabled}
							subLocationDisabled={this.state.subLocationDisabled}
							userFilter={this.state.userFilter}
							loadUsers={this.loadUsers}
							clearSelectedUser={this.clearSelectedUser}
							handleUserFilter={this.handleUserFilter}
							jobNumberValue={this.state.jobNumber}
							handleJobNumber={this.handleJobNumber}
						/>
					</div>
					<div>
						<QRCodeDialog
							isOpen={this.state.SearchbyQr}
							close={() => this.setState({ SearchbyQr: false })}
							history={this.props.history}
							readQRCodeAndNavigate={readQRCodeAndNavigate}
						/>
					</div>
				</Grid>
			</div>
		);
	}
}

const NoJobs = props => {
	const { t, i18n } = useTranslation();
	return (
		<div className="no-jobtasks-card">
			<h1 className="hot-pink">
				{props.filtersActive ? t('There are no jobs matching your search criteria') : t('There are no jobs.')}
			</h1>
		</div>
	);
};

const NewJobButton = props => (
	<IconButton onClick={() => props.history.push('/new-job')} style={{ marginRight: "0.8em" }}>
		<Icon style={{ color: 'white' }}>add_circle_outline</Icon>
	</IconButton>
);

const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
	JobsInfo: state.JobsInfo
});

const mapDispatchToProps = dispatch => ({
	clearCurrentJobsInfo: () => dispatch(clearCurrentJobsInfo())
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(JobListScreen));
