export const CURRENT_ASSETS = 'CURRENT_ASSETS';
export const CURRENT_ASSETS_SCROLLY = 'CURRENT_ASSETS_SCROLLY';
export const CLEAR_ASSETS_STATE = 'CLEAR_ASSETS_STATE';

export function assetsLoaded(totalAssetsLoaded : number) {
    return dispatch => {
        dispatch({
            type: CURRENT_ASSETS,
            payload: totalAssetsLoaded
        });
    };
}

export function currentScrollPosition(yAxisPos : number) {
    return dispatch => {
        dispatch({
            type: CURRENT_ASSETS_SCROLLY,
            payload: yAxisPos
        });
    };
}

export function clearCurrentAssetsInfo() {
    return dispatch => {
        dispatch({
            type: CLEAR_ASSETS_STATE
        })
    }
}