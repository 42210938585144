import { Button, Grid } from '@material-ui/core';
import { CameraEnhance } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import useDialog from '../../hooks/useDialog';
import '../../styles/storage-areas/storage-area-details.css';
import { readAndAddQRCode } from '../../utils/QRCode';
import QRCodeDialog from '../shared/QRCodeDialog';

interface QRCodeCardProps {
	storageArea: StorageArea;
}

const QRCodeCard = ({ storageArea }: QRCodeCardProps) => {
	const [t, i18n] = useTranslation();
	const { isDialogOpen, openDialog, closeDialog } = useDialog();
	return (
		<Grid container direction="row">
			<Grid container item xs={6} justify="flex-start" alignItems="flex-end">
				<div>
					<p className={`content-storage-area`}>{storageArea.QRCode}</p>
					<p className="label-storage-area">{t("QRCode")}</p>
					<br />
				</div>
			</Grid>
			<QRCodeDialog
				isOpen={isDialogOpen}
				close={closeDialog}
				readAndAddQRCode={QRCode => readAndAddQRCode(QRCode, 'StorageAreas', 'StorageArea', storageArea.ID)}
			/>
			{!storageArea.QRCode || storageArea.QRCode === '' ? (
				<Button onClick={openDialog} style={{ color: '#3f51b5', width: '50%' }} variant="outlined" size="large">
					{t("Add QR Code")} <CameraEnhance style={{ paddingLeft: '5px' }}></CameraEnhance>
				</Button>
			) : (
				<QRCode bgColor="#FFFFFF" fgColor="#000000" level="L" size={64} value={storageArea.QRCode} />
			)}
		</Grid>
	);
};

export default QRCodeCard;
