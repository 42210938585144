// React
import * as React from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { match } from 'react-router';
import firebase from 'firebase';
// Load Image for Saving files and Compressing them
import * as loadImage from 'blueimp-load-image';
import { isNullOrUndefined } from 'util';
import { AsyncPaginate, Response } from 'react-select-async-paginate';

// Config File
import Api from '../apiConfiguration.json';

// Firebase + IndexedDB + localQueue
import { fire, idb, localQueue } from '../index';

// Material UI
import { Button, FormControl, Grid, Icon, NativeSelect, TextField, IconButton } from '@material-ui/core';

// Components
import SideDrawer from '../components/shared/SideDrawer';
import JobPhotos from '../components/job/JobPhotos';
import BackButton from '../components/shared/BackButton';
import LoadingSpinner from '../components/Spinners/LoadingSpinner';

// Utils
import { generateFirebaseId, generateGuid } from '../utils/Guids';
import { fileToArrayBuffer } from '../utils/Converters';
import { stringtoUnixUTC } from '../utils/Times';
import { LocalstorageRemoveItem, LocalstorageGetItem, LocalstorageSetItem } from '../utils/LocalStorage';
import { windowError, SendErrorData } from '../utils/WindowError';
import { parseDateForDateTimeFields } from '../utils/Times';
import { GetFileCompressionRatio } from '../utils/FileCompressionRatio';
import { getBaseURL } from '../utils/getBaseURL';
import ApiKeyObj from '../utils/ApiObjectKey';
import DateOrPriority from '../components/inputs/DateOrPriority';

// CSS
import '../styles/new-job/new-job.css';
import { useTranslation, withTranslation } from 'react-i18next';
import { IJobTypeGroup } from '../definitions/jobType';

interface NewJobScreenProps {
	history: History<any>;
	match: match<{ RequestID: string }>;
	UserSettings: Store.UserSettings;
	t: any;
}

interface NewJobScreenState {
	JobDetails: string;
	JobDetailsRequired: boolean;
	SelectedSiteName: string;
	SelectedSiteID: number | null;
	SiteIDRequired: boolean;
	SelectedLocationID: number | null;
	SelectedLocationName: string;
	SelectedSubLocationID: number | null;
	SelectedSubLocationName: string;
	JobTypesList: any[];
	SelectedJobTypeName: string;
	SelectedJobTypeID: number | null;
	JobSubTypesList: any[];
	SelectedSubJobTypeID: number | null;
	SelectedSubJobTypeName: string;
	JobSubTypeDisabled: boolean;
	JobSubTypeListSource: any[];
	ExpectedByDate: string;
	JobPhotos: Job.JobPhoto[];
	guid: string;
	DocumentID: string | null;
	CreatingJob: boolean;
	GeneratedGuid: string;
	FieldsDisabled: boolean;
	fromAssetData: any;
	NewDate: number;
	selectedPriority: {
		Name: string;
		ID: string;
	} | null;
	prioritiesSource: any[];
	jobID: string;
	formID: string;
	questionID: string;
	fromFormFail: boolean;
	fromFormLibrary: boolean;
	formTemplateFBID: string;
	questionRef: firebase.firestore.DocumentReference | null;
	AssetsAttached: string | null | undefined;

	// Sites, Locations, Sub Locations
	sitesPerPage: number;
	lastLoadedSite: any;
	siteValue: string;
	loadedSites: [];
	endSite: boolean;
	sitesLocationCanBeSelected: boolean;
	siteFilter?: {
		value: number;
		label: string;
	} | null;

	locationsPerPage: number;
	lastLoadedLocation: any;
	locationValue: string;
	loadedLocations: [];
	endLocation: boolean;
	locationFilter?: {
		value: number;
		label: string;
	} | null;
	locationDisabled: boolean;

	subLocationsPerPage: number;
	lastLoadedSubLocation: any;
	subLocationValue: string;
	loadedSubLocations: [];
	endSubLocation: boolean;
	subLocationFilter?: {
		value: number;
		label: string;
	} | null;
	subLocationDisabled: boolean;

	enableTextSearchSite: boolean;
	enableTextSearchLocation: boolean;
	enableTextSearchSubLocation: boolean;
	siteMenuOpen: boolean;
	locationMenuOpen: boolean;
	sublocationMenuOpen: boolean;

	showKeyboard: boolean;
	siteFreeze: boolean;
	locationFreeze: boolean;
	sublocationFreeze: boolean;
	requestID: number | null;
	RequestFBID: string | null;
	expand: boolean;
}

class NewJobScreen extends React.Component<NewJobScreenProps, NewJobScreenState> {
	private siteRef: React.RefObject<HTMLInputElement>;
	private locationRef: React.RefObject<HTMLInputElement>;
	private sublocationRef: React.RefObject<HTMLInputElement>;

	constructor(props) {
		super(props);

		this.state = {
			JobDetails: '',
			JobDetailsRequired: false,
			SelectedSiteName: '',
			SelectedSiteID: null,
			SiteIDRequired: false,
			SelectedLocationID: null,
			SelectedLocationName: '',
			SelectedSubLocationID: null,
			SelectedSubLocationName: '',
			JobTypesList: [],
			SelectedJobTypeName: '',
			SelectedJobTypeID: null,
			JobSubTypesList: [],
			SelectedSubJobTypeName: '',
			JobSubTypeDisabled: true,
			JobSubTypeListSource: [],
			SelectedSubJobTypeID: null,
			ExpectedByDate: '',
			JobPhotos: [],
			guid: '',
			fromAssetData: '',
			DocumentID: null,
			CreatingJob: false,
			GeneratedGuid: generateFirebaseId(),
			FieldsDisabled: false,
			NewDate: new Date().getTime(),
			selectedPriority: null,
			prioritiesSource: [],
			jobID: '',
			formID: '',
			questionID: '',
			fromFormFail: false,
			fromFormLibrary: false,
			formTemplateFBID: '',
			questionRef: null,
			AssetsAttached: '',

			sitesPerPage: 50,
			lastLoadedSite: null,
			siteValue: '',
			loadedSites: [],
			endSite: false,
			sitesLocationCanBeSelected: true,
			siteFilter: null,

			locationsPerPage: 50,
			lastLoadedLocation: null,
			locationValue: '',
			loadedLocations: [],
			endLocation: false,
			locationFilter: null,
			locationDisabled: true,

			subLocationsPerPage: 50,
			lastLoadedSubLocation: null,
			subLocationValue: '',
			loadedSubLocations: [],
			endSubLocation: false,
			subLocationFilter: null,
			subLocationDisabled: true,

			enableTextSearchSite: false,
			enableTextSearchLocation: false,
			enableTextSearchSubLocation: false,
			siteMenuOpen: false,
			locationMenuOpen: false,
			sublocationMenuOpen: false,

			showKeyboard: false,
			siteFreeze: false,
			locationFreeze: false,
			sublocationFreeze: false,
			requestID: null,
			RequestFBID: '',
			expand: false
		};
		this.siteRef = React.createRef();
		this.locationRef = React.createRef();
		this.sublocationRef = React.createRef();
	}

	async componentWillMount() {
		windowError(
			this.props.UserSettings.email,
			this.props.UserSettings.UserUID,
			this.props.UserSettings.ServerName,
			'NewJobScreen',
		);

		if (this.props.UserSettings.NewJobDatePriorityTreatment === 'Date') {
			if (isNullOrUndefined(this.state.ExpectedByDate) || this.state.ExpectedByDate === '') {
				this.setState({
					ExpectedByDate: parseDateForDateTimeFields(this.state.NewDate),
				});
			}
		}

		const localItem = LocalstorageGetItem('NewJobDetails');
		if (!isNullOrUndefined(localItem) && localItem !== '') {
			const LocalJSONObject = JSON.parse(localItem);
			await this.setState({
				JobDetails: LocalJSONObject.JobDetails,
				JobDetailsRequired: LocalJSONObject.JobDetailsRequired,
				SelectedSiteName: LocalJSONObject.SelectedSiteName,
				SelectedSiteID: LocalJSONObject.SelectedSiteID,
				SiteIDRequired: LocalJSONObject.SiteIDRequired,
				SelectedLocationID: LocalJSONObject.SelectedLocationID,
				SelectedLocationName: LocalJSONObject.SelectedLocationName,
				SelectedSubLocationID: LocalJSONObject.SelectedSubLocationID === undefined ? null : LocalJSONObject.SelectedSubLocationID,
				SelectedSubLocationName: LocalJSONObject.SelectedSubLocationName,
				JobTypesList: LocalJSONObject.JobTypesList,
				SelectedJobTypeName: LocalJSONObject.SelectedJobTypeName,
				SelectedJobTypeID: LocalJSONObject.SelectedJobTypeID,
				JobSubTypesList: LocalJSONObject.JobSubTypesList,
				SelectedSubJobTypeName: LocalJSONObject.SelectedSubJobTypeName,
				JobSubTypeDisabled: LocalJSONObject.JobSubTypeDisabled,
				JobSubTypeListSource: LocalJSONObject.JobSubTypeListSource,
				SelectedSubJobTypeID: LocalJSONObject.SelectedSubJobTypeID,
				ExpectedByDate: LocalJSONObject.ExpectedByDate,
				NewDate: LocalJSONObject.NewDate,
				selectedPriority: LocalJSONObject.selectedPriority,
				prioritiesSource: LocalJSONObject.prioritiesSource,
				jobID: LocalJSONObject.jobID,
				formID: LocalJSONObject.formID,
				questionID: LocalJSONObject.questionID,
				fromFormFail: LocalJSONObject.fromFormFail,
				formTemplateFBID: LocalJSONObject.formTemplateFBID,
				AssetsAttached: LocalJSONObject.AssetsAttached,
				RequestFBID: LocalJSONObject.RequestFBID
			});
		}

		if (
			!isNullOrUndefined(this.props.history.location.state) &&
			window.location.href.includes('new-request-from-Asset')
		) {
			const {
				JobDetails,
				SelectedSiteID,
				SelectedSiteName,
				SelectedLocationID,
				SelectedLocationName,
				SelectedSubLocationID,
				SelectedSubLocationName,
				AssetsAttached,
			} = this.props.history.location.state;

			const fromAssetData = {
				JobDetails,
				SelectedSiteID,
				SelectedSiteName,
				SelectedLocationID,
				SelectedLocationName,
				SelectedSubLocationID,
				SelectedSubLocationName,
				AssetsAttached,
			};

			this.setState({
				JobDetails,
				SelectedSiteID,
				SelectedSiteName,
				SelectedLocationID,
				SelectedLocationName,
				SelectedSubLocationID,
				SelectedSubLocationName,
				AssetsAttached,
				fromAssetData,
				siteFreeze: true,
				locationFreeze: true,
				sublocationFreeze: true,
			}, () => this.loadDropdowns());

		}

		if (
			!isNullOrUndefined(this.props.history.location.state) &&
			window.location.href.includes('new-request-from-Site')
		) {
			const {
				JobDetails,
				SelectedSiteID,
				SelectedSiteName
			} = this.props.history.location.state;

			const fromAssetData = {
				JobDetails,
				SelectedSiteID,
				SelectedSiteName
			};

			this.setState({
				JobDetails,
				SelectedSiteID,
				SelectedSiteName,
				fromAssetData,
				siteFreeze: true
			}, () => this.loadDropdowns());
		}

		if (
			!isNullOrUndefined(this.props.history.location.state) &&
			window.location.href.includes('new-request-from-Location')
		) {
			const {
				JobDetails,
				SelectedSiteID,
				SelectedSiteName,
				SelectedLocationID,
				SelectedLocationName,

			} = this.props.history.location.state;

			const fromAssetData = {
				JobDetails,
				SelectedSiteID,
				SelectedSiteName,
				SelectedLocationID,
				SelectedLocationName
			};

			this.setState({
				JobDetails,
				SelectedSiteID,
				SelectedSiteName,
				SelectedLocationID,
				SelectedLocationName,
				fromAssetData,
				siteFreeze: true,
				locationFreeze: true
			}, () => this.loadDropdowns());
		}

		if (
			!isNullOrUndefined(this.props.history.location.state) &&
			window.location.href.includes('new-request-from-Sublocation')
		) {
			const {
				JobDetails,
				SelectedSiteID,
				SelectedSiteName,
				SelectedLocationID,
				SelectedLocationName,
				SelectedSubLocationID,
				SelectedSubLocationName,

			} = this.props.history.location.state;

			const fromAssetData = {
				JobDetails,
				SelectedSiteID,
				SelectedSiteName,
				SelectedLocationID,
				SelectedLocationName,
				SelectedSubLocationID,
				SelectedSubLocationName
			};

			this.setState({
				JobDetails,
				SelectedSiteID,
				SelectedSiteName,
				SelectedLocationID,
				SelectedLocationName,
				SelectedSubLocationID,
				SelectedSubLocationName,
				fromAssetData,
				siteFreeze: true,
				locationFreeze: true,
				sublocationFreeze: true,
			}, () => this.loadDropdowns());
		}

		if (!isNullOrUndefined(this.props.history.location.state) && window.location.href.includes('new-job-form-fail')) {
			const {
				fromFormFail,
				JobDetails,
				SelectedSiteID,
				SelectedSiteName,
				SelectedLocationID,
				SelectedLocationName,
				SelectedSubLocationID,
				SelectedSubLocationName,
				jobID,
				formID,
				questionID,
				formTemplateFBID,
			} = this.props.history.location.state;

			this.setState({
				fromFormFail,
				JobDetails,
				SelectedSiteID,
				SelectedSiteName,
				SelectedLocationID,
				SelectedLocationName,
				SelectedSubLocationID: SelectedSubLocationID === undefined ? null : SelectedSubLocationID,
				SelectedSubLocationName,
				jobID,
				formID,
				questionID,
				formTemplateFBID,
			}, () => this.loadDropdowns());
		}

		if (window.location.href.includes('new-job-from-Request')) {
			const {
				ExpectedByDate,
				RequestDetails,
				SelectedSiteName,
				SelectedSiteID,
				SelectedLocationName,
				SelectedLocationID,
				SelectedSubLocationName,
				SelectedSubLocationID,
				SelectedJobTypeName,
				SelectedJobTypeID,
				SelectedJobSubTypeName,
				SelectedJobSubTypeID,
				RequestFBID
			} = this.props.history.location.state;
			this.setState({
				ExpectedByDate: ExpectedByDate ? parseDateForDateTimeFields(ExpectedByDate) : this.state.ExpectedByDate,
				requestID: +this.props.match.params.RequestID,
				JobDetails: RequestDetails,
				SelectedSiteName,
				SelectedSiteID,
				SelectedLocationName,
				SelectedLocationID,
				SelectedSubLocationName,
				SelectedSubLocationID,
				SelectedJobTypeName,
				SelectedJobTypeID,
				SelectedSubJobTypeName: SelectedJobSubTypeName,
				SelectedSubJobTypeID: SelectedJobSubTypeID,
				JobSubTypeDisabled: SelectedJobSubTypeID ? false : true,
				RequestFBID: RequestFBID
			}, () => this.loadDropdowns());
		}
	}

	loadDropdowns = () => {
		if (!isNullOrUndefined(this.state.SelectedSiteID) && !isNullOrUndefined(this.state.SelectedSiteName)) {
			this.setState({
				siteFilter: {
					value: this.state.SelectedSiteID,
					label: this.state.SelectedSiteName
				}
			}, () => this.checkLocations());
		}

		if (!isNullOrUndefined(this.state.SelectedLocationID) && !isNullOrUndefined(this.state.SelectedLocationName)) {
			this.setState({
				locationFilter: {
					value: this.state.SelectedLocationID,
					label: this.state.SelectedLocationName
				}
			}, () => this.checkSubLocations());
		}

		if (!isNullOrUndefined(this.state.SelectedSubLocationID) && !isNullOrUndefined(this.state.SelectedSubLocationName)) {
			this.setState({
				subLocationFilter: {
					value: this.state.SelectedSubLocationID,
					label: this.state.SelectedSubLocationName
				}
			})
		}

	}

	async componentDidMount() {
		this.updatePredicate();
		window.addEventListener("resize", this.updatePredicate);

		if (this.state.fromFormFail && !isNullOrUndefined(this.state.questionID) && !isNullOrUndefined(this.state.formID)) {
			fire.baseQuery
				.collection('Forms')
				.doc(this.state.formID)
				.collection('QuestionAnswers')
				.doc(this.state.questionID)
				.onSnapshot(data => {
					this.setState({ questionRef: data.ref });
				});
		}

		let sitesLocationCanBeSelected = true;
		if (this.state.fromFormFail) {
			if (!isNullOrUndefined(this.state.SelectedSiteID)) {
				let selSiteID = 0;
				selSiteID = this.state.SelectedSiteID;
				if (selSiteID > 0) {
					await this.setState({ sitesLocationCanBeSelected: false });
				}
			}
		}

		const guid = this.props.history.location.state !== undefined ? this.props.history.location.state.guid : null;
		this.setState({ guid });
		this.getPhotos(guid);
		this.getJobTypes();
		this.getJobSubTypes();
		this.getPriorities();
		window.scrollTo(0, 0);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updatePredicate);
	}

	updatePredicate = () => {
		this.setState({ showKeyboard: window.innerWidth < 600 })
	}

	loadOptions = async (siteName, loadedOptions) => {
		if (siteName !== this.state.siteValue) {
			await this.setState({ loadedSites: [], lastLoadedSite: null, siteValue: siteName });
			loadedOptions = [];
		}

		return new Promise<Response>(resolve => {
			fire.getSearchSitesPaginated(this.state.sitesPerPage, this.state.siteValue, this.state.lastLoadedSite, this.props.UserSettings.CanAccessAllSites, this.props.UserSettings.ContractFBID).get().then(docs => {
				if (!docs.empty) {
					let sites: any = [];
					docs.forEach(function (site) {
						const siteData = {
							value: site.data().SiteID,
							label: site.data().SiteName
						}
						sites.push(siteData);
					});
					this.setState({ loadedSites: sites, lastLoadedSite: docs.docs[docs.docs.length - 1] }, () => {
						return resolve({
							options: this.state.loadedSites,
							hasMore: !this.state.endSite
						});
					})
				} else {
					this.setState({ endSite: true });
					return resolve({
						options: [],
						hasMore: !this.state.endSite
					});
				}
			})
		})
	};

	loadLocations = async (locationName, loadedOptions) => {

		if (locationName !== this.state.locationValue) {
			await this.setState({ loadedLocations: [], lastLoadedLocation: null, locationValue: locationName });
			loadedOptions = [];
		}

		return new Promise<Response>(resolve => {
			if (!isNullOrUndefined(this.state.SelectedSiteID)) {
				fire.getSearchLocationsPaginated(
					this.state.locationsPerPage,
					this.state.SelectedSiteID,
					this.props.UserSettings.CanAccessAllLocations,
					this.state.locationValue,
					this.state.lastLoadedLocation)
					.get()
					.then(docs => {
						if (!docs.empty) {
							let locations: any = [];
							docs.forEach(function (location) {
								const locationData = {
									value: location.data().LocationID,
									label: location.data().LocationName
								}
								locations.push(locationData);
							});
							this.setState({ loadedLocations: locations, lastLoadedLocation: docs.docs[docs.docs.length - 1] }, () => {
								return resolve({
									options: this.state.loadedLocations,
									hasMore: !this.state.endLocation
								});
							})
						} else {
							this.setState({ endLocation: true });
							return resolve({
								options: [],
								hasMore: !this.state.endLocation
							});
						}
					})
			} else {
				return resolve({
					options: [],
					hasMore: false
				})
			}
		})
	}

	loadSubLocations = async (subLocationName, loadedOptions) => {
		if (subLocationName !== this.state.subLocationValue) {
			await this.setState({ loadedSubLocations: [], lastLoadedSubLocation: null, subLocationValue: subLocationName });
			loadedOptions = [];
		}

		return new Promise<Response>(resolve => {

			if (!isNullOrUndefined(this.state.SelectedLocationID)) {
				fire
					.getSearchSubLocationsPaginated(
						this.state.subLocationsPerPage,
						this.state.SelectedLocationID,
						this.props.UserSettings.CanAccessAllLocations,
						this.state.subLocationValue,
						this.state.lastLoadedSubLocation
					)
					.get()
					.then(docs => {
						if (!docs.empty) {
							let subLocations: any = [];
							docs.forEach(function (subLocation) {
								const subLocationData = {
									value: subLocation.data().SubLocationID,
									label: subLocation.data().SubLocationName
								}
								subLocations.push(subLocationData);
							});
							this.setState({ loadedSubLocations: subLocations, lastLoadedSubLocation: docs.docs[docs.docs.length - 1] }, () => {
								return resolve({
									options: this.state.loadedSubLocations,
									hasMore: !this.state.endSubLocation
								});
							})
						} else {
							this.setState({ endSubLocation: true });
							return resolve({
								options: [],
								hasMore: !this.state.endSubLocation
							});
						}
					})
			} else {
				return resolve({
					options: [],
					hasMore: false
				})
			}
		})
	}

	getPhotos(guid: string) {
		if (isNullOrUndefined(guid)) return;
		idb.getPhotosForCreateJob(guid).then(photoArray => {
			if (photoArray.length > 0) {
				const arrayMap = photoArray.map(photo => {
					const blob = new Blob([photo.file]);
					const blobUrl = URL.createObjectURL(blob);
					return { FirebaseStoragePath: blobUrl };
				});
				this.setState({ JobPhotos: arrayMap });
			}
		});
	}

	handleJobFilterOptions = () => {
		const localItem = JSON.parse(LocalstorageGetItem('JobFilterOptions'));
		if (!isNullOrUndefined(localItem)) {
			const SearchArray = {
				searchInput: '',
				openClosedJobFilter: 'Open',
				plannedReactiveJobFilter: '',
				IsDueToday: false,
				SelectedPageNumber: 0,
				YAxisScroll: 0,
				IsNotSynced: false,
				order: localItem.order,
			};

			LocalstorageRemoveItem('JobFilterOptions');
			LocalstorageSetItem({ Key: 'JobFilterOptions', Value: SearchArray });
		}
	};

	handleDateTimeChange = (value: string): void => {
		this.setState({
			ExpectedByDate: value,
			NewDate: stringtoUnixUTC(value),
		});
	};

	handlePriorityChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
		const {
			value,
			dataset: { priorityid },
		} = event.target.options[event.target.selectedIndex];

		if (!isNullOrUndefined(priorityid)) {
			this.setState({
				selectedPriority: { ID: priorityid, Name: value },
			});
		} else {
			this.setState({
				selectedPriority: null,
			});
		}
	};

	backButton = async () => {
		LocalstorageRemoveItem('NewJobDetails');

		if (this.state.fromFormFail) {
			if (!(isNullOrUndefined(this.state.formTemplateFBID) || this.state.formTemplateFBID.length === 0)) {
				this.props.history.replace('/form-library-details/' + this.state.formTemplateFBID + '/' + this.state.formID);
			} else {
				this.props.history.replace('/form-details/' + this.state.jobID + '/' + this.state.formID);
			}
		} else {
			window.history.back();
		}

		if (!isNullOrUndefined(this.state.guid)) {
			idb.removePhotosCreateJobPhotos(this.state.guid);
		}
	};

	saveJob = async () => {
		const errorsPresent = this.checkForNullFields(this.state.SelectedSiteID, this.state.JobDetails);

		if (errorsPresent) {
			return;
		}

		this.setState({
			FieldsDisabled: true,
			JobSubTypeDisabled: true,
			CreatingJob: true,
		});

		LocalstorageRemoveItem('NewJobDetails');
		//Causing issues with Creating a new job.
		//this.handleJobFilterOptions();
		this.setState({ CreatingJob: false });

		if (this.state.fromFormFail) {
			if (!(isNullOrUndefined(this.state.formTemplateFBID) || this.state.formTemplateFBID.length === 0)) {
				this.props.history.replace('/form-library-details/' + this.state.formTemplateFBID + '/' + this.state.formID);
			} else {
				this.props.history.replace('/form-details/' + this.state.jobID + '/' + this.state.formID);
			}
		} else {
			this.props.history.replace('/jobsuccessful/' + this.state.GeneratedGuid);
		}

		let ExpectedDate: string | null = this.state.ExpectedByDate;

		if (isNullOrUndefined(this.state.ExpectedByDate) || this.state.ExpectedByDate === '') {
			ExpectedDate = null;
		}

		const JobDetails = {
			Site: this.state.SelectedSiteName,
			Location: this.state.SelectedLocationName,
			SubLocationName: this.state.SelectedSubLocationName,
			JobType: this.state.SelectedJobTypeName,
			JobSubType: this.state.SelectedSubJobTypeName,
			SiteID: this.state.SelectedSiteID,
			LocationID: this.state.SelectedLocationID,
			SubLocationID: this.state.SelectedSubLocationID,
			JobTypeID: this.state.SelectedJobTypeID,
			JobSubTypeID: this.state.SelectedSubJobTypeID,
			JobDetails: this.state.JobDetails,
			FirebaseId: this.state.GeneratedGuid,
			ExpectedByDate: ExpectedDate,
			DateCreated: Date.now(),
			JobStatus: 'Pending',
			JobNumber: 0,
			AssignedToResource: { ResourceName: null, ResourceObjFBID: null },
			AssignedToResourceMultiple: null,
			IsMultipleResource: false,
			AssetsAttached: this.state.AssetsAttached,
			IsOpened: false,
			Priority:
				this.props.UserSettings.NewJobDatePriorityTreatment === 'Priority' && this.state.selectedPriority !== null
					? this.state.selectedPriority.Name
					: '',
		};

		if (!isNullOrUndefined(this.state.questionRef) && this.state.fromFormFail) {
			await this.state.questionRef.update({ FailJobNumber: 0 });
		}

		if (this.state.RequestFBID != null && this.state.RequestFBID != '') {
			await fire.approveRequest(this.state.RequestFBID as string);
		}

		await fire.CreateJob(JobDetails, this.state.GeneratedGuid);

		const Details = {
			Site: this.state.SelectedSiteName,
			Location: this.state.SelectedLocationName,
			SubLocationName: this.state.SelectedSubLocationName,
			JobType: this.state.SelectedJobTypeName,
			JobSubType: this.state.SelectedSubJobTypeName,
			SiteID: this.state.SelectedSiteID,
			LocationID: this.state.SelectedLocationID,
			SubLocationID: this.state.SelectedSubLocationID,
			AssetFBID: this.state.AssetsAttached,
			JobTypeID: this.state.SelectedJobTypeID,
			JobSubTypeID: this.state.SelectedSubJobTypeID,
			JobDetails: this.state.JobDetails,
			DateCreated: Date.now(),
			JobStatus: 'Pending',
			JobNumber: 0,
			JobAction: 'NewJob',
			FirebasePhotos: [],
			AssetsAttached: this.state.AssetsAttached,
			PriorityID:
				this.props.UserSettings.NewJobDatePriorityTreatment === 'Priority' && this.state.selectedPriority !== null
					? this.state.selectedPriority.ID
					: '',
			NewJobDatePriorityTreatment: this.props.UserSettings.NewJobDatePriorityTreatment,
			TemplateResponseFBID: this.state.formID,
			TemplateQuestionAnswerFBID: this.state.questionID,
			RequestID: this.state.requestID,
		};

		if (this.state.JobPhotos.length > 0) {
			const LocalQueueDetails = { guid: this.state.guid, documentId: this.state.GeneratedGuid, JobAction: 'NewJob' };
			await localQueue.saveToLocalJobQueue(this.state.guid, LocalQueueDetails);
		}

		delete Details['ExpectedByDate'];
		delete Details['JobFBID'];

		if (ExpectedDate !== null) {
			const UnixExpectedDate = stringtoUnixUTC(ExpectedDate);
			Details['ExpectedDate'] = Number.isNaN(UnixExpectedDate) ? null : UnixExpectedDate;
		}


		fire.postToJobQueue(this.state.GeneratedGuid, Details)
			.catch(err => {
				alert(this.props.t('There was an error adding the job, if problem persists please email - support@trackplanfm.com'));
				console.error(err);
			});

		if (!isNullOrUndefined(this.state.AssetsAttached) && this.state.AssetsAttached !== '') {
			const AssetJob = {
				AssetFBID: this.state.AssetsAttached,
				JobFBID: this.state.GeneratedGuid,
				DateCreated: Date.now(),
			};
			let assetJobID = generateFirebaseId();
			await fire.createAssetToJob(AssetJob, assetJobID);

			const Details = {
				AssetFBID: this.state.AssetsAttached,
				JobFBID: this.state.GeneratedGuid,
				DateCreated: Date.now(),
				JobAction: 'AddAssetToJob',
			};
			fire.postToJobQueue(assetJobID, Details).catch(err => {
				alert(this.props.t('There was an error adding job from asset, if problem persists please email - support@trackplanfm.com'));
				console.error(err);
			});
		}
	};

	checkForNullFields = (SiteID, jobDetails) => {
		let errors = 0;
		if (isNullOrUndefined(SiteID)) {
			this.setState({ SiteIDRequired: true });
			errors += 1;
		} else {
			this.setState({ SiteIDRequired: false });
		}

		if (jobDetails === undefined || jobDetails === null || jobDetails === '') {
			this.setState({ JobDetailsRequired: true });
			errors += 1;
		} else {
			this.setState({ JobDetailsRequired: false });
		}
		return errors > 0;
	};

	handleCameraButton = () => {
		const data = {
			JobDetails: this.state.JobDetails,
			JobDetailsRequired: this.state.JobDetailsRequired,
			SelectedSiteName: this.state.SelectedSiteName,
			SelectedSiteID: this.state.SelectedSiteID,
			SiteIDRequired: this.state.SiteIDRequired,
			SelectedLocationID: this.state.SelectedLocationID,
			SelectedLocationName: this.state.SelectedLocationName,
			SelectedSubLocationID: this.state.SelectedSubLocationID,
			SelectedSubLocationName: this.state.SelectedSubLocationName,
			JobTypesList: this.state.JobTypesList,
			SelectedJobTypeName: this.state.SelectedJobTypeName,
			SelectedJobTypeID: this.state.SelectedJobTypeID,
			JobSubTypesList: this.state.JobSubTypesList,
			SelectedSubJobTypeName: this.state.SelectedSubJobTypeName,
			JobSubTypeDisabled: this.state.JobSubTypeDisabled,
			JobSubTypeListSource: this.state.JobSubTypeListSource,
			SelectedSubJobTypeID: this.state.SelectedSubJobTypeID,
			ExpectedByDate: this.state.ExpectedByDate,
			NewDate: this.state.NewDate,
			selectedPriority: this.state.selectedPriority,
			prioritiesSource: this.state.prioritiesSource,
			jobID: this.state.jobID,
			formID: this.state.formID,
			questionID: this.state.questionID,
			fromFormFail: this.state.fromFormFail,
			formTemplateFBID: this.state.formTemplateFBID,
			AssetsAttached: this.state.AssetsAttached,
		};

		LocalstorageSetItem({ Key: 'NewJobDetails', Value: data });
		this.props.history.push({
			pathname: '/take-photo',
			state: {
				newJobRequest: false,
				newJob: true,
				guid: this.state.guid,
				DocumentId: '',
			},
		});
	};

	openImageDialog = () => {
		const imageUploadButton = document.getElementById('image-upload');
		if (imageUploadButton !== null) {
			imageUploadButton.click();
		}
	};

	saveImage = async (selectorFiles: FileList | null) => {
		if (selectorFiles !== null) {
			for (let i = 0; i < selectorFiles.length; i++) {
				//console.log(selectorFiles[i]);
				const photo: Blob = selectorFiles[i];
				const CompressionRatio = GetFileCompressionRatio(photo);

				loadImage(
					photo,
					(canvas: HTMLCanvasElement) => {
						canvas.toBlob(async blob => {
							const arrayBuffer = await fileToArrayBuffer(blob as Blob);
							await idb
								.setPhotoForCreateJob(arrayBuffer, this.state.GeneratedGuid)
								.then(guid => {
									//console.log("Photo " + i + " was added to the index.db with guid " + guid);
									this.getPhotos(this.state.GeneratedGuid);
									this.setState({ guid: this.state.GeneratedGuid });
									//console.log(this.state.guid);

								})
								.catch(err => console.error(err));
						});
					},
					{
						maxWidth: 400,
						maxHeight: 400,
						orientation: true,
						downsamplingRatio: CompressionRatio,
						canvas: true
					},
				);
			}
		}
	};

	handleLogout() {
		LocalstorageRemoveItem('NewJobDetails');
		LocalstorageRemoveItem('JobFilterOptions');
		LocalstorageRemoveItem('AssignedUserFilterOptions');
		LocalstorageRemoveItem('ResourceFilterOptions');
		LocalstorageRemoveItem('FormLibraryFilterOptions');
		fire.auth.signOut().then(() => location.reload());
	}

	openSiteMenu = () => {
		this.setState({ siteMenuOpen: true }, () => { if (!isNullOrUndefined(this.siteRef.current)) this.siteRef.current.focus() });
	}

	closeSiteMenu = () => {
		this.setState({ siteMenuOpen: false });
	}

	openLocationMenu = () => {
		this.setState({ locationMenuOpen: true }, () => { if (!isNullOrUndefined(this.locationRef.current)) this.locationRef.current.focus() });
	}

	closeLocationMenu = () => {
		this.setState({ locationMenuOpen: false });
	}

	openSubLocationMenu = () => {
		this.setState({ sublocationMenuOpen: true }, () => { if (!isNullOrUndefined(this.sublocationRef.current)) this.sublocationRef.current.focus() });
	}

	closeSubLocationMenu = () => {
		this.setState({ sublocationMenuOpen: false });
	}

	handleExpand = (value) => {
		this.setState({ expand: value });
	}

	render() {
		const { showKeyboard } = this.state;
		const iOS =
			!!navigator.platform &&
			(/iPad|iPhone|iPod/.test(navigator.platform) ||
				(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1));

		if (this.state.CreatingJob) {
			return <LoadingSpinner text={this.props.t("Creating job...")} />;
		}

		return (
			<div>
				<SideDrawer
					history={this.props.history}
					title={this.props.t("Create New Job")}
					rightMenuButton={<BackButton callbackMethod={this.backButton} />}
					title2={window.location.href.includes('new-request-from-Asset') ? 'Asset Job' : ''}
					colour="primary"
					handleLogout={this.handleLogout}
					User={this.props.UserSettings}
					versionApp={Api.VERSION}
					versionDb={Api.INDEXEDDB_VERSION}
					SendErrorData={SendErrorData}
					getBaseURL={getBaseURL}
					ApiKeyObj={ApiKeyObj}
				/>
				<Grid container={true} direction="column" justify="center" alignItems="center">
					<div className="main">
						<div className="padding-25-15">
							<div className="container-div" style={{ marginTop: 20 }}>
								<TextField
									id="job-details"
									type="text"
									fullWidth={true}
									multiline={true}
									value={this.state.JobDetails}
									//onChange={event => this.setState({ JobDetails: event.target.value })}
									onChange={event => {
										this.setState({ JobDetails: event.target.value });
										this.checkForNullFields(this.state.SelectedSiteID, event.target.value);
									}}
									label={this.props.t("Job Details")}
									variant="standard"
									disabled={this.state.FieldsDisabled}
								/>
								<FieldEmptyWarning showField={this.state.JobDetailsRequired} />
							</div>

							<div style={{ marginTop: 10 }}>
								<p style={{ fontWeight: 600, fontStyle: 'italic', marginBottom: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
									<span>{this.props.t("Site")}</span>
									{
										(showKeyboard && !this.state.siteFreeze) && (
											<IconButton onClick={() => this.setState({ enableTextSearchSite: !this.state.enableTextSearchSite }, () => this.openSiteMenu())}>
												<Icon style={{ color: this.state.enableTextSearchSite ? 'green' : '' }} >keyboard</Icon>
											</IconButton>
										)
									}

								</p>
								<AsyncPaginate
									id="site-helper"
									value={this.state.siteFilter}
									selectRef={this.siteRef}
									onFocus={this.openSiteMenu}
									onBlur={this.closeSiteMenu}
									isDisabled={this.state.siteFreeze}
									menuIsOpen={this.state.siteMenuOpen}
									onChange={this.handleSiteChange}
									blurInputOnSelect={true}
									loadOptions={this.loadOptions}
									loadingMessage={() => this.props.t("Loading Sites...")}
									noOptionsMessage={() => this.props.t("No Sites Found")}
									cacheUniqs={[this.state.siteFilter, this.state.siteValue]}
									isSearchable={showKeyboard ? this.state.enableTextSearchSite : true}
								/>
								<br />
								{(!isNullOrUndefined(this.state.siteFilter) && !this.state.siteFreeze) && (
									<div style={{ marginTop: -5 }}>
										<span>{this.props.t("Selected") + ": "}</span>
										<IconButton onClick={() => this.clearSelectedSitesOrLocations(1)} style={{ float: 'right', marginTop: '-5px', cursor: 'pointer' }}>
											<Icon color="error">
												delete_forever
											</Icon>
										</IconButton>
										{this.state.SelectedSiteName}
									</div>
								)}
							</div>
							<FieldEmptyWarning showField={this.state.SiteIDRequired} />

							<div style={{ marginTop: 10 }}>
								<p style={{ fontWeight: 600, fontStyle: 'italic', marginBottom: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
									<span>{this.props.t("Location")}</span>
									{
										(showKeyboard && !this.state.locationDisabled && !this.state.locationFreeze) && (
											<IconButton onClick={() => this.setState({ enableTextSearchLocation: !this.state.enableTextSearchLocation }, () => this.openLocationMenu())}>
												<Icon style={{ color: this.state.enableTextSearchLocation ? 'green' : '' }} >keyboard</Icon>
											</IconButton>
										)
									}
								</p>
								<AsyncPaginate
									id="location-helper"
									value={this.state.locationFilter}
									selectRef={this.locationRef}
									onFocus={this.openLocationMenu}
									onBlur={this.closeLocationMenu}
									menuIsOpen={this.state.locationMenuOpen}
									onChange={this.handleLocationChange}
									blurInputOnSelect={true}
									loadOptions={this.loadLocations}
									loadingMessage={() => this.props.t("Loading Locations...")}
									noOptionsMessage={() => this.props.t("No Locations Found")}
									isDisabled={this.state.locationDisabled || this.state.locationFreeze}
									cacheUniqs={[this.state.locationFilter, this.state.locationValue, this.state.SelectedSiteID]}
									isSearchable={showKeyboard ? this.state.enableTextSearchLocation : true}
								/>
								<br />
								{(!isNullOrUndefined(this.state.locationFilter) && !this.state.locationFreeze) && (
									<div style={{ marginTop: -5, marginBottom: 15 }}>
										<span>{this.props.t("Selected") + ": "}</span>
										<IconButton onClick={() => this.clearSelectedSitesOrLocations(2)} style={{ float: 'right', marginTop: '-5px', cursor: 'pointer' }}>
											<Icon color="error">
												delete_forever
											</Icon>
										</IconButton>
										{this.state.SelectedLocationName}
									</div>
								)}
							</div>

							<div style={{ marginTop: 10 }}>
								<p style={{ fontWeight: 600, fontStyle: 'italic', marginBottom: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
									<span>{this.props.t("Sub Location")}</span>
									{
										(showKeyboard && !this.state.subLocationDisabled && !this.state.sublocationFreeze) && (
											<IconButton onClick={() => this.setState({ enableTextSearchSubLocation: !this.state.enableTextSearchSubLocation }, () => this.openSubLocationMenu())}>
												<Icon style={{ color: this.state.enableTextSearchSubLocation ? 'green' : '' }} >keyboard</Icon>
											</IconButton>
										)
									}
								</p>
								<AsyncPaginate
									id="subLocation-helper"
									value={this.state.subLocationFilter}
									selectRef={this.sublocationRef}
									onFocus={this.openSubLocationMenu}
									onBlur={this.closeSubLocationMenu}
									menuIsOpen={this.state.sublocationMenuOpen}
									blurInputOnSelect={true}
									onChange={this.handleSubLocationChange}
									loadOptions={this.loadSubLocations}
									loadingMessage={() => this.props.t("Loading Sub Locations...")}
									isDisabled={this.state.subLocationDisabled || this.state.sublocationFreeze}
									cacheUniqs={[this.state.subLocationFilter, this.state.subLocationValue, this.state.SelectedSiteID, this.state.SelectedLocationID]}
									isSearchable={showKeyboard ? this.state.enableTextSearchSubLocation : true}
								/>
								<br />
								{(!isNullOrUndefined(this.state.subLocationFilter) && !this.state.sublocationFreeze) && (
									<div style={{ marginTop: -5, marginBottom: 15 }}>
										<span>{this.props.t("Selected" + ": ")}</span>
										<IconButton onClick={() => this.clearSelectedSitesOrLocations(3)} style={{ float: 'right', marginTop: '-5px', cursor: 'pointer' }}>
											<Icon color="error">
												delete_forever
											</Icon>
										</IconButton>
										{this.state.SelectedSubLocationName}
									</div>
								)}
							</div>

							{/* Job Type Select */}
							<div style={{ marginTop: 10 }}>
								<p style={{ fontWeight: 600, fontStyle: 'italic' }}>{this.props.t("Job Type")} </p>
								<FormControl style={{ width: '100%', marginTop: '5px' }}>
									{/* <InputLabel htmlFor="select-multiple">Sub-Location</InputLabel> */}
									<NativeSelect
										// fullWidth={true}
										value={this.state.SelectedJobTypeName}
										onChange={this.handleJobTypeChange}
										name={this.props.t("Job Type")}
										disabled={this.state.FieldsDisabled}
									>
										{this.state.JobTypesList.length > 0 ? (
											this.state.JobTypesList.map((JobType, _i) => (
												<option
													key={JobType.Id}
													data-jobtypeid={JobType.Data.JobTypeID}
													value={JobType.Data.JobTypeName}
												>
													{JobType.Data.JobTypeName}
												</option>
											))
										) : (
											<option key={1} value="empty">
												{this.props.t("No Job Types")}
											</option>
										)}
									</NativeSelect>
								</FormControl>
							</div>

							{/* Job Sub Type Select */}
							<div style={{ marginTop: 30 }}>
								<p style={{ fontWeight: 600, fontStyle: 'italic' }}> {this.props.t("Job Sub Type")} </p>
								<FormControl style={{ width: '100%', marginTop: '5px' }}>
									{/* <InputLabel htmlFor="select-multiple">Sub-Location</InputLabel> */}
									<NativeSelect
										// fullWidth={true}
										value={this.state.SelectedSubJobTypeName}
										onChange={this.handleJobSubTypeChange}
										disabled={this.state.JobSubTypeDisabled}
										name={this.props.t("Job Sub Type")}
									>
										{this.state.JobSubTypesList.length > 0 ? (
											this.state.JobSubTypesList.map((JobSubType, _i) => (
												<option
													key={JobSubType.Id}
													data-jobsubtypeid={JobSubType.Data.JobSubTypeID}
													value={JobSubType.Data.JobSubTypeName}
												>
													{JobSubType.Data.JobSubTypeName}
												</option>
											))
										) : (
											<option key={1} value="empty">
												{this.props.t("No Job Sub Types")}
											</option>
										)}
									</NativeSelect>
								</FormControl>
							</div>

							<DateOrPriority
								NewDate={this.state.NewDate}
								ExpectedByDate={this.state.ExpectedByDate}
								selectedPriority={this.state.selectedPriority}
								prioritiesSource={this.state.prioritiesSource}
								handlePriorityChange={this.handlePriorityChange}
								handleDateTimeChange={this.handleDateTimeChange}
								NewJobDatePriorityTreatment={this.props.UserSettings.NewJobDatePriorityTreatment}
							/>
							<JobPhotos
								color=""
								photos={this.state.JobPhotos}
								titleText={this.props.t("Attached Photos")}
								noPhotosText={this.props.t("No Job Photos")}
								isForRequest={true}
								canRemovePhoto={false}
								canDownloadPhoto={false}
								numberOfPhotos={8}
								displayDownloadAll={false}
								canEditPhoto={false}
								expand={this.state.expand}
								handleExpand={this.handleExpand}
								showActionButton={false}
								saveImage={this.saveImage}
								openImageDialog={this.openImageDialog}
							/>
							<div className="btn-container">
								{
									<div>
										<Button variant="contained" color="default" onClick={() => this.openImageDialog()}>
											<Icon>add_circle</Icon>&nbsp; {this.props.t("Add Photo")}
										</Button>
									</div>
								}
								<Button
									color="primary"
									variant="contained"
									style={{ backgroundColor: 'green' }}
									onClick={() => this.saveJob()}
									disabled={this.state.FieldsDisabled}
								>
									<Icon>save</Icon>&nbsp; {this.props.t("Create")}
								</Button>
							</div>
						</div>
					</div>
				</Grid>
			</div>
		);
	}

	private getJobTypes = async () => {
		const userJobTypeGroupId = this.props.UserSettings &&
			this.props.UserSettings.JobTypeGroupFbId;
		let userJobTypeGroup: IJobTypeGroup;
		if (userJobTypeGroupId) {
			const jobGroupData = await fire.getJobTypeGroupById(userJobTypeGroupId)
			if (!jobGroupData.empty) {
				userJobTypeGroup = { ...jobGroupData.docs[0].data(), Id: jobGroupData.docs[0].id };
			}
		}
		const JobTypeArray: any[] = [];
		fire
			.getJobTypes()
			.then(async JobTypes => {
				JobTypes.docs.forEach(JobType => {
					if (userJobTypeGroupId) {
						if (userJobTypeGroup.JobTypeArray &&
							userJobTypeGroup.JobTypeArray
								.some(_typeId => _typeId === JobType.id)) {
							JobTypeArray.push({
								Data: JobType.data(),
								Id: JobType.id,
							});
						}
					} else {
						JobTypeArray.push({
							Data: JobType.data(),
							Id: JobType.id,
						});
					}
				});
			})
			.then(() => {
				JobTypeArray.sort((a, b) => {
					const jobTypeA = a.Data.JobTypeName.toLowerCase();
					const jobTypeB = b.Data.JobTypeName.toLowerCase();
					if (jobTypeA < jobTypeB)
						return -1;

					if (jobTypeA > jobTypeB)
						return 1;

					return 0;
				});

				JobTypeArray.unshift({
					Data: { JobTypeName: this.props.t('None'), JobTypeID: null },
					Id: 'null',
				});

				this.setState({ JobTypesList: JobTypeArray });
			});
	};

	private getJobSubTypes = () => {
		const JobSubTypesArray: any[] = [];
		fire
			.getCollection('JobSubTypes', 'JobSubTypeName')
			.then(JobSubTypes => {
				JobSubTypes.docs.forEach(JobSubType => {
					JobSubTypesArray.push({
						Data: JobSubType.data(),
						Id: JobSubType.id,
					});
				});
			})
			.then(() => {
				JobSubTypesArray.sort((a, b) => {
					const jobSubTypeA = a.Data.JobSubTypeName.toLowerCase();
					const jobSubTypeB = b.Data.JobSubTypeName.toLowerCase();

					if (jobSubTypeA < jobSubTypeB)
						return -1;

					if (jobSubTypeA > jobSubTypeB)
						return 1;

					return 0;
				});
				this.setState({ JobSubTypeListSource: JobSubTypesArray });

				if (this.props.history &&
					this.props.history.location &&
					this.props.history.location.state &&
					this.props.history.location.state.SelectedJobTypeID) {
					const jobTypeId = this.props.history.location.state.SelectedJobTypeID
					const jobSubTypes = JobSubTypesArray
						.filter(subType => subType.Data.JobTypeID === jobTypeId);
					this.setState({ JobSubTypesList: jobSubTypes })
				}
			});
	};

	private getPriorities = () => {
		fire
			.getCollection('Priorities', 'DisplayOrder', 'asc')
			.then(priorities => {
				const array = priorities.docs.map(priority => ({ Data: priority.data(), Id: priority.id }));
				array.sort((a, b) => {
					if (a.Data.DisplayOrder > b.Data.DisplayOrder) {
						return 1;
					} else if (a.Data.DisplayOrder < b.Data.DisplayOrder) {
						return -1;
					}

					if (a.Data.Name < b.Data.Name) {
						return -1;
					} else if (a.Data.Name > b.Data.Name) {
						return 1;
					} else {
						return 0;
					}
				});

				array.unshift({ Data: { Name: 'Select Priority' }, Id: '' });

				return array;
			})
			.then(array => {
				this.setState({ prioritiesSource: array });
			});
	};

	private checkLocations = () => {
		if (!isNullOrUndefined(this.state.siteFilter)) {
			fire.doesLocationsExist(this.state.siteFilter.value).then((res) => {
				this.setState({
					locationDisabled: !res
				})
			})
		}
	}

	private checkSubLocations = () => {
		if (!isNullOrUndefined(this.state.siteFilter) && !isNullOrUndefined(this.state.locationFilter)) {
			fire.doesSubLocationsExist(this.state.locationFilter.value).then((res) => {
				this.setState({
					subLocationDisabled: !res
				})
			})
		}
	}

	private handleSiteChange = (object: { value: number; label: string }) => {
		this.setState({ siteMenuOpen: false });
		const SiteID = object.value;
		let siteidN: number | null = SiteID;
		if (!isNullOrUndefined(SiteID)) {
			this.setState({
				SelectedSiteName: object.label,
				SelectedSiteID: SiteID,
				SelectedLocationID: null,
				SelectedLocationName: '',
				SelectedSubLocationName: '',
				SelectedSubLocationID: null,
				lastLoadedSite: null,
				siteFilter: object,
				lastLoadedLocation: null,
				locationFilter: null,
				loadedLocations: [],
				locationValue: '',
				subLocationValue: '',
				loadedSubLocations: [],
				subLocationFilter: null,
			}, () => this.checkLocations());
		} else {
			this.setState({
				SelectedSiteID: null,
				SelectedLocationName: '',
				SelectedLocationID: null,
				SelectedSubLocationName: '',
				SelectedSubLocationID: null,
				lastLoadedSite: null,
				siteFilter: null,
				lastLoadedLocation: null,
				locationFilter: null,
				loadedLocations: [],
				locationValue: '',
				lastLoadedSubLocation: null,
				subLocationFilter: null,
				loadedSubLocations: [],
				subLocationValue: '',
			});
		}
		//to remove the validation message
		const errorsPresent = this.checkForNullFields(siteidN, this.state.JobDetails);
	};

	private handleLocationChange = (object: { value: number; label: string }) => {
		this.setState({ locationMenuOpen: false });
		const LocationID = object.value;
		if (!isNullOrUndefined(LocationID)) {
			this.setState({
				SelectedLocationName: object.label,
				SelectedLocationID: LocationID,
				SelectedSubLocationName: '',
				SelectedSubLocationID: null,
				lastLoadedLocation: null,
				locationFilter: object,
				subLocationValue: '',
				loadedSubLocations: [],
				subLocationFilter: null,
			}, () => this.checkSubLocations());
		} else {
			this.setState({
				SelectedLocationName: '',
				SelectedLocationID: null,
				SelectedSubLocationName: '',
				SelectedSubLocationID: null,
				lastLoadedLocation: null,
				locationFilter: null,
				subLocationValue: '',
				loadedSubLocations: [],
				subLocationFilter: null,
			});
		}
	}

	private handleSubLocationChange = (object: { value: number; label: string }) => {
		this.setState({ sublocationMenuOpen: false });
		const SubLocationID = object.value;
		if (!isNullOrUndefined(SubLocationID)) {
			this.setState({
				SelectedSubLocationName: object.label,
				SelectedSubLocationID: SubLocationID,
				lastLoadedSubLocation: null,
				subLocationFilter: object,
			});
		} else {
			this.setState({
				SelectedSubLocationName: '',
				SelectedSubLocationID: null,
				lastLoadedSubLocation: null,
				subLocationFilter: null,
			});
		}
	};

	clearSelectedSitesOrLocations = async (typeId) => {
		// typeId = 1 (Site), typeId = 2 (Location), typeId = 3 (Sub Location)
		// If site is cleared, then location and sub location is also cleared as well since they depend on selected site id.
		// If location is cleared, then sub location is also cleared since it depends on selected location id.

		if (typeId === 1) {
			await this.setState({
				SelectedSiteID: null,
				SelectedSiteName: '',
				lastLoadedSite: null,
				loadedSites: [],
				siteFilter: null,
				siteValue: '',
				endSite: false,
				locationDisabled: true,
				subLocationDisabled: true,
			});
		}

		if (typeId === 1 || typeId === 2) {
			await this.setState({
				SelectedLocationName: '',
				SelectedLocationID: null,
				lastLoadedLocation: null,
				loadedLocations: [],
				locationFilter: null,
				locationValue: '',
				endLocation: false,
				subLocationDisabled: true,
			});
		}

		if (typeId === 1 || typeId === 2 || typeId === 3) {
			await this.setState({
				SelectedSubLocationName: '',
				SelectedSubLocationID: null,
				lastLoadedSubLocation: null,
				loadedSubLocations: [],
				subLocationFilter: null,
				subLocationValue: '',
				endSubLocation: false,
			});
		}
	}

	private handleJobTypeChange = (child: React.ChangeEvent<HTMLSelectElement>) => {
		const {
			value,
			dataset: { jobtypeid },
		} = child.target.options[child.target.selectedIndex];

		if (!isNullOrUndefined(jobtypeid)) {
			const JobTypeID = parseInt(jobtypeid, 10);

			const SubTypeList = this.state.JobSubTypeListSource.filter(SubType => SubType.Data.JobTypeID === JobTypeID);
			const JobSubTypeDisabled = SubTypeList.length < 1;
			if (!JobSubTypeDisabled) {
				SubTypeList.unshift({
					Data: { JobSubTypeName: 'None', JobSubTypeID: null },
					Id: 1,
				});
			}
			this.setState({
				JobSubTypesList: SubTypeList,
				SelectedJobTypeName: value,
				SelectedJobTypeID: JobTypeID,
				JobSubTypeDisabled,
				SelectedSubJobTypeName: '',
				SelectedSubJobTypeID: null,
			});
		} else {
			this.setState({
				SelectedJobTypeName: "",
				SelectedJobTypeID: null,
				SelectedSubJobTypeName: "",
				SelectedSubJobTypeID: null,
				JobSubTypesList: [],
				JobSubTypeDisabled: true,
			});
		}
	};

	private handleJobSubTypeChange = (child: React.ChangeEvent<HTMLSelectElement>) => {
		const {
			value,
			dataset: { jobsubtypeid },
		} = child.target.options[child.target.selectedIndex];

		if (!isNullOrUndefined(jobsubtypeid)) {
			const JobSubTypeID = parseInt(jobsubtypeid, 10);

			this.setState({
				SelectedSubJobTypeName: value,
				SelectedSubJobTypeID: JobSubTypeID,
			});
		} else {
			this.setState({
				SelectedSubJobTypeName: '',
				SelectedSubJobTypeID: null,
			});
		}
	};
}

const FieldEmptyWarning = props => {
	const { t, i18n } = useTranslation();
	return (
		<div style={{ ...props.style, paddingTop: 5 }}>
			<p
				style={{
					color: '#c62828',
					fontStyle: 'italic',
					display: props.showField ? 'flex' : 'none',
				}}
			>
				{t("This field is required.")}
			</p>
		</div>
	)
};

const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
});

export default withTranslation()(connect(mapStateToProps)(NewJobScreen));
