// React
import * as React from 'react';
import { History } from 'history';
import { match } from 'react-router';
import QRCode from 'react-qr-code';

// Material UI
import DialogContent from '@material-ui/core/DialogContent';
import {
	Button,
	Grid
} from '@material-ui/core';
import { isNullOrUndefined } from 'util';
import { fire } from '../../index';
import { connect } from 'react-redux';
import BuildIcon from '@material-ui/icons/Build';
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddBoxIcon from '@material-ui/icons/AddBox';

// Components
import SideDrawer from '../../components/shared/SideDrawer';
import BackButton from '../../components/shared/BackButton';
import FieldHolder from '../../components/styled-components/FieldHolderSiteCard';

// Utils
import { SendErrorData } from '../../utils/WindowError';
import { getBaseURL } from '../../utils/getBaseURL';
import Api from '../../apiConfiguration.json';
import ApiKeyObj from '../../utils/ApiObjectKey';
import LoadingSpinner from '../../components/Spinners/LoadingSpinner';
import { readAndAddQRCode } from '../../utils/QRCode';
import QRCodeDialog from '../../components/shared/QRCodeDialog';
import { useTranslation, withTranslation } from 'react-i18next';
import FormsListCards from '../../components/shared/FormsListCards';
import PhotosCard from '../../components/shared/PhotosCard';
import DocumentsCard from '../../components/shared/DocumentsCard';
import { fireSubLocationConverter } from '../../utils/FirebaseConverters';

//style
import '../../styles/shared.css'

interface SubLocationDetailProps {
	UserSettings: Store.UserSettings;
	match: match<{id: string}>;
	history: History;
	t: any;
}
interface SubLocationDetailsState {
	JobIds: string[] | null;
	Jobs: Job.Job[];
	Assets: Asset[];
	sublocationDetails: SubLocation | null;
	fromJobFBID: string;
	SearchbyQr: boolean;
	SubLocationFBID: string;
}


const routeToAssociatedJobs = (selectedSubLocation: SubLocation | null, history: History) => {
	if (!isNullOrUndefined(selectedSubLocation)) {
		let ids: any;
		history.push({
			pathname: '/associated-jobs',
			state: {
				SelectedSiteID: selectedSubLocation.SiteID,
				SelectedSiteName: selectedSubLocation.SiteName,
				SelectedLocationID: selectedSubLocation.LocationID,
				SelectedLocationName: selectedSubLocation.LocationName,
				SelectedSubLocationID: selectedSubLocation.SubLocationID,
				SelectedSubLocationName: selectedSubLocation.SubLocationName,
			},
		});
	}
};

const routeToAssociatedAssets = (selectedSubLocation: SubLocation | null, history: History) => {
	if (!isNullOrUndefined(selectedSubLocation)) {
		let ids: any;
		ids = selectedSubLocation.SiteFBID;
		history.push({
			pathname: '/asset-list/list',
			state: {
				SelectedSiteID: selectedSubLocation.SiteID,
				SelectedSiteName: selectedSubLocation.SiteName,
				SelectedLocationID: selectedSubLocation.LocationID,
				SelectedLocationName: selectedSubLocation.LocationName,
				SelectedSubLocationID: selectedSubLocation.SubLocationID,
				SelectedSubLocationName: selectedSubLocation.SubLocationName,
			},
		});
	}
};

const FieldHolderName = props => (
	<div className={`${props.pullRight ? 'pull-right' : ''}${props.fullLine ? 'full-line-asset' : ''} assetField`}>
		<h3 className='field-holder-name'>
			{props.content}
		</h3>
		<p className="field-label-asset">{props.label}</p>
		<hr/>
	</div>
);

class SubLocationDetails extends React.Component<SubLocationDetailProps, SubLocationDetailsState> {
	constructor(props) {
		super(props);
		this.state = {
			JobIds: [],
			Jobs: [],
			Assets: [],
			sublocationDetails: null,
			fromJobFBID: '',
			SearchbyQr: false,
			SubLocationFBID: '',
		};
	}

	async componentWillReceiveProps(newProps: SubLocationDetailProps) {
		const { id } = newProps.match.params as any;
		await this.handleComponentLoad(id);
	}

	 handleComponentLoad(id: string) {
		const sublocation = fire.getDocumentQuery('SubLocations', id);		

		sublocation.withConverter(fireSubLocationConverter).onSnapshot(async sublocationObj => {
			let siteName = "";
			let locationName = "";
			let siteCode = 0;
			
			const SubLocation = sublocationObj.data()!;

			await fire.getLocationInfoFromID(SubLocation.LocationID).then(data => {
				data.docs.forEach(location => {
					locationName = location.data().LocationName;
					siteCode = location.data().SiteID;
				})
			});  

			await fire.getSiteInfoFromID(siteCode)
			.then(data => {
				data.docs.forEach(site => {
					siteName = site.data().SiteName;
				})
			});
			
			SubLocation.QRCode = SubLocation.QRCode || '';
			SubLocation.LocationName = locationName;
			SubLocation.SiteName = siteName;
			SubLocation.SiteID = siteCode;
			this.setState({
				sublocationDetails: SubLocation
			});

			if(!isNullOrUndefined(this.state.sublocationDetails)){
				this.getJobs(this.state.sublocationDetails.ID);
				this.getAssets(this.state.sublocationDetails.ID);
			}
		});
		
}

	async componentDidMount() {
		const { id, fromjobfbid } = this.props.match.params as any;
		this.setState({ SubLocationFBID: id });

		await this.handleComponentLoad(id);
		if (!isNullOrUndefined(fromjobfbid)) {
			this.setState({ fromJobFBID: fromjobfbid });
		}

	}

	//Sorting function for jobs.
	sortingFunction = (joblist: Job.Job[]) => {
		return joblist.sort((a, b) => {
			if (a.JobNumber === b.JobNumber) return 0;
			else if (a.JobNumber === 0) return -1;
			else if (b.JobNumber === 0) return 1;
			else if (a.JobNumber < b.JobNumber) return 1;
			else if (b.JobNumber < a.JobNumber) return -1;
			else return 0;
		});
	};

	getJobs(id) {
		let JobArray: Job.Job[] = [];
		if (!isNullOrUndefined(id)) {
			fire.baseQuery
				.collection('Jobs')
				.where('SiteID', '==', id)
				.get()
				.then(siteJobs => {
					JobArray = siteJobs.docs.map(siteJob => {
						let data = siteJob.data() as Job.Job;
						data.JobNumber = isNullOrUndefined(data.JobNumber) ? 0 : data.JobNumber;
						return data;
					});
					JobArray = this.sortingFunction(JobArray);
					this.setState({ Jobs: JobArray });
				});
		}
	}

	getAssets(id) {
		let assetArray: Asset[] = [];
		if (!isNullOrUndefined(id)) {
			fire.baseQuery
				.collection('Assets')
				.where('SubLocationID', '==', id)
				.get()
				.then(locationAssets => {
					assetArray = locationAssets.docs.map(locationAsset => {
						let data = locationAsset.data() as Asset;
						return data;
					});
					this.setState({ Assets: assetArray });
				});
		}
	}


	backButton = () => {
		window.history.back()
	};

	handleLogout() {
		fire.auth.signOut().then(() => location.reload());
	}

	DetailsButton = async id => {
		let doesJobExist = await fire.doesJobExist(id);
		if (doesJobExist) {
			if (!isNullOrUndefined(this.state.sublocationDetails)) {
				this.props.history.push('/job-details/' + id + '/' + this.state.SubLocationFBID);
			} else {
				this.props.history.push('/job-details/' + id);
			}

		} else {
			alert(this.props.t('Job cannot be accessed'));
		}
	};

	AssetDetailsButton = async id => {
		this.props.history.push('/asset-nmdetails/' + id);
	};

	handleRaiseNewJobfromSubLocation = (selectedSubLocation: SubLocation | null, history: History) => {
		if (!isNullOrUndefined(selectedSubLocation)) {
			let ids: any;
			ids = selectedSubLocation.ID;
			history.push({
				pathname: '/new-request-from-Sublocation/' + selectedSubLocation.ID,
				state: {
					JobDetails: this.props.t('Requested at SubLocation:') + " " + selectedSubLocation.SubLocationName,
					SelectedLocationID: selectedSubLocation.LocationID,
					SelectedLocationName: selectedSubLocation.LocationName,
					SelectedSubLocationID: selectedSubLocation.SubLocationID,
					SelectedSubLocationName: selectedSubLocation.SubLocationName,
					SelectedSiteID: selectedSubLocation.SiteID,
					SelectedSiteName: selectedSubLocation.SiteName,
					AssetsAttached: ids,
					guid: null,
				},
			});
		}
	};

	handleCreateNewAssetfromSubLocation = (selectedSubLocation: SubLocation | null, history: History, UserSettings: Store.UserSettings) => {
		if(UserSettings.CanAddAssets === false){
			alert(this.props.t("This user is not authorized to create assets. If this is a mistake, please contact your administrator and ensure permission has been granted to create assets from the main dashboard."));
			return;
		}
		
		if (!isNullOrUndefined(selectedSubLocation)) {
			let ids: any;
			ids = selectedSubLocation.ID;
			history.push({
				pathname: '/new-asset',
				state: {
					SelectedSiteID: selectedSubLocation.SiteID,
					SelectedSiteName: selectedSubLocation.SiteName,
					SelectedLocationID: selectedSubLocation.LocationID,
					SelectedLocationName: selectedSubLocation.LocationName,
					SelectedSubLocationID: selectedSubLocation.SubLocationID,
					SelectedSubLocationName: selectedSubLocation.SubLocationName
				},
			});
		}
	};
	
	render() {
		if (!isNullOrUndefined(this.state.sublocationDetails)) {
			const title = this.state.sublocationDetails.SubLocationName.length > 15 ? this.state.sublocationDetails.SubLocationName.substr(0, 15) + "..." : this.state.sublocationDetails.SubLocationName;

			//Future consideration: google maps implementation with location.address passed in as URL parameter.
			//const gmapLink = "https://maps.google.com/?q=" + this.state.locationDetails.Address;

			return (
				<div>
					<SideDrawer
						history={this.props.history}
						title={title}
						rightMenuButton={<BackButton callbackMethod={this.backButton} />}
						colour="primary"
						handleLogout={this.handleLogout}
						User={this.props.UserSettings}
						versionApp={Api.VERSION}
						versionDb={Api.INDEXEDDB_VERSION}
						SendErrorData={SendErrorData}
						getBaseURL={getBaseURL}
						ApiKeyObj={ApiKeyObj}
					/>
					{!isNullOrUndefined(this.state.sublocationDetails) ? (
						<Grid container={true} direction="column" justify="center" alignItems="stretch">
							<DialogContent>
								<div id="details-outer-asset">
								<div className="top-card-alt card-shadow-alt">
										<FieldHolderName content={this.state.sublocationDetails.SubLocationName} label={this.props.t("Sublocation Name")} fullline="true"/>
										<FieldHolder content={this.state.sublocationDetails.LocationName} label={this.props.t("Location Name")} />
										<FieldHolder content={this.state.sublocationDetails.Code ? this.state.sublocationDetails.Code : "n/a"} label={this.props.t("Location Code")} />
										<FieldHolder content={this.state.sublocationDetails.SiteName} label={this.props.t("Site Name")} />
										</div>
										<div className="top-card-alt card-shadow-alt">
										<FieldHolder content={this.state.sublocationDetails.QRCode} label={this.props.t("QRCode")} />
										<QRCodeDialog
											isOpen={this.state.SearchbyQr}
											close={() => this.setState({ SearchbyQr: false })}
											readAndAddQRCode={QRCode => readAndAddQRCode(QRCode, 'SubLocations', 'SubLocation', this.props.match.params.id)}
										/>
										{this.state.sublocationDetails.QRCode && this.state.sublocationDetails.QRCode !== '' ? (
											<QRCode
												bgColor="#FFFFFF"
												fgColor="#000000"
												level="L"
												size={64}
												value={this.state.sublocationDetails.QRCode as string}
											/>
										) : (
											<Button
												onClick={() => this.setState({ SearchbyQr: true })}
												className='add-qr-code-button'
												variant="outlined"
												size="large"
											>
												{this.props.t("Add QR Code")} <CameraEnhanceIcon className='padding-left'></CameraEnhanceIcon>
											</Button>
										)}
									</div>
									<FormsListCards fromView="Sublocation" associatedFBID={this.state.SubLocationFBID}
									areTemplatesRestricted={this.state.sublocationDetails.FormTemplatesRestricted != null ? this.state.sublocationDetails.FormTemplatesRestricted : false}
									restrictedTemplates={this.state.sublocationDetails.RestrictedFormTemplates != null ? this.state.sublocationDetails.RestrictedFormTemplates : []}></FormsListCards>
									<PhotosCard fromView="SubLocation" associatedFBID={this.state.SubLocationFBID}></PhotosCard>
									<DocumentsCard fromView="SubLocation" associatedFBID={this.state.SubLocationFBID}></DocumentsCard>

									<div className="top-card-alt-with-two-buttons card-shadow-alt">
									<Button onClick={() => routeToAssociatedJobs(this.state.sublocationDetails, this.props.history)}
										className='view-jobs-button'
										variant="contained"
										color="secondary"
										size="large"
										 >
										{this.props.t("View Jobs")} <BuildIcon className='padding-left'></BuildIcon>
									</Button>		
									<Button onClick={() => this.handleRaiseNewJobfromSubLocation(this.state.sublocationDetails, this.props.history)}
										variant="outlined"
										className='raise-job-button-shared'
										size="large"
										 >							 
										{this.props.t("Raise Job")}
										<AddCircleIcon className='padding-left'></AddCircleIcon>
										</Button>
									</div>

									<div className="top-card-alt-with-two-buttons card-shadow-alt">
									<Button onClick={() => routeToAssociatedAssets(this.state.sublocationDetails, this.props.history)}
										className='view-assets-button'
										variant="contained"
										color="secondary"
										size="large"
										 >
										{this.props.t("View Assets")} <DynamicFeedIcon className='padding-left'></DynamicFeedIcon>
										</Button>
										<Button onClick={() => this.handleCreateNewAssetfromSubLocation(this.state.sublocationDetails, this.props.history, this.props.UserSettings)}
											variant="outlined"
											className='create-asset-button'
											size="large"
											>							 
											{this.props.t("Create Asset")}
											<AddBoxIcon className='padding-left'></AddBoxIcon>
											</Button>	
									</div>								
								</div>
							</DialogContent>
						</Grid>
					) : null}
				</div>
			);
		}
		return <LoadingSpinner color="primary" text={this.props.t("Loading SubLocation Details")} />;
	}
}
const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
});

export default withTranslation()(connect(mapStateToProps)(SubLocationDetails));
