import { Result } from '@zxing/library';
import { History } from 'history';
import { fire } from '..';
import { generateFirebaseId } from './Guids';
import { IsDevelopment } from './IsDevelopment';
import { LocalstorageSetItem } from './LocalStorage';

interface Details {
	JobAction: string;
	QRCode: string;
	AssetFBID?: string;
	SiteFBID?: string;
	LocationFBID?: string;
	SubLocationFBID?: string;
	StorageAreaFBID?: string;
}

type CollectionsWithQR = 'Assets' | 'Sites' | 'Locations' | 'SubLocations' | 'StorageAreas';

export const readAndAddQRCode = async (
	QRCode: Result,
	collection: CollectionsWithQR,
	type: QRLookupType,
	id: string,
) => {
	const success = await fire.addQRLookup(QRCode.getText(), id, type);
	if (success) {
		const Details = {
			JobAction: 'QRCodeAssociation',
			QRCode: QRCode.getText(),
			FBID: id,
			Type: type,
		};
		await fire.postToJobQueue(id, Details);
		await fire
			.UpdateQRCode(collection, id, QRCode.getText())
			.catch(() =>
				alert(
					`There was an error associating the ${type} with this QR Code, if problem persists please email - support@trackplanfm.com`,
				),
			);
	} else {
		alert('This QR Code is already in use on the application. Please select a new QR Code.');
	}
};

export const readQRCodeAndNavigate = async (QRCode: Result, history: History, DocumentID?: string, JobTaskID?: string, DocumentType?: AddToAssetDocumentType, QuestionAnswerID?: string | null, translate?: any) => {
	let QRCodeText = QRCode.getText();
	let requestURL = IsDevelopment("Request") + "/guest-request/";
	let QRObject;

	if(QRCodeText.includes(requestURL)){
		let qrFBID = QRCodeText.substring(requestURL.length, QRCodeText.length);
		await fire.getQRLookup(qrFBID).then(result => {
			QRObject = result.data();
			if (QRObject.LinkedFBID) {
				qrCodeRouting(QRObject, history);
			} else {
				alert('There was an error scanning the QR Code, if problem persists please email - support@trackplanfm.com');
			}		
		});	
	} else {
	await fire.QRSearch(QRCodeText).then(result => {
		
		result.docs.map(query => {
			QRObject = query.data();
		});
		if (QRObject) {
			if (QRObject.LinkedFBID) {
				qrCodeRouting(QRObject, history);
			} else {
				alert('There was an error scanning the QR Code, if problem persists please email - support@trackplanfm.com');
			}
		} else {
			if (history.location.pathname.includes('/asset-list')) {
				const confirm = window.confirm(translate('The scanned QRCode is not associated to an asset, do you want to add it as a new asset?'));
				if (confirm) {
					LocalstorageSetItem({
						Key: 'QRCodeTextJson',
						Value: {
							QRCodeText: QRCodeText,
							DocumentID: DocumentID,
							JobTaskID: JobTaskID,
							DocumentType: DocumentType,
							QuestionAnswerID: QuestionAnswerID
						}
					});
					history.push('/new-asset');
				}
			}else{
				alert('This QR does not belong to any entity');
			}
		}
	});
}
};

const qrCodeRouting = (QRObject: QRLookup, history: History) => {
	const firebaseID = generateFirebaseId();
	let route = '';
	const details: Details = {
		JobAction: 'ObjectQRScan',
		QRCode: QRObject.QRCode,
	};
	switch (QRObject.Type) {
		case 'Asset':
			details.AssetFBID = QRObject.LinkedFBID;
			route = 'asset-nmdetails';
			break;
		case 'Site':
			details.SiteFBID = QRObject.LinkedFBID;
			route = 'site-details';
			break;
		case 'Location':
			details.LocationFBID = QRObject.LinkedFBID;
			route = 'location-details';
			break;
		case 'SubLocation':
			details.SubLocationFBID = QRObject.LinkedFBID;
			route = 'sublocation-details';
			break;
		case 'StorageArea':
			details.StorageAreaFBID = QRObject.LinkedFBID;
			route = 'storage-area-details';
			break;
	}
	fire
		.postToJobQueue(firebaseID, details)
		.catch(() =>
			alert('There was an error scanning the QR Code, if problem persists please email - support@trackplanfm.com'),
		);
	history.push(`/${route}/${QRObject.LinkedFBID}`);
};
