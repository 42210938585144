// React
import * as React from 'react';
import { useState, useRef, useEffect } from 'react';

// Search Pagination for Sites
import { AsyncPaginate } from 'react-select-async-paginate';

import { isNullOrUndefined } from 'util';

// Material UI
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
	InputLabel,
	TextField,
	useMediaQuery,
	Icon,
	IconButton
} from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import AutoCompleteDropDown from '../inputs/AutoCompleteDropdown';
import { generateFirebaseId } from '../../utils/Guids';
import { useTranslation } from 'react-i18next';

function AssetSearchModal(props) {
	const [enableTextSearchSite, setTextSearchSite] = useState(false);
	const [enableTextSearchLocation, setTextSearchLocation] = useState(false);
	const [enableTextSearchSubLocation, setTextSearchSubLocation] = useState(false);
	const [siteMenuOpen, setSiteMenuOpen] = useState(false);
	const [locationMenuOpen, setLocationMenuOpen] = useState(false);
	const [sublocationMenuOpen, setSubLocationMenuOpen] = useState(false);
	const [showKeyboard, setShowKeyboard] = useState(false);
	const [t, i18n] = useTranslation();

	const siteRef = useRef<HTMLInputElement>();
	const locationRef = useRef<HTMLInputElement>();
	const sublocationRef = useRef<HTMLInputElement>();

	useEffect(() => {
		updatePredicate();
	}, [window.innerWidth]);

	const updatePredicate = () => {
		setShowKeyboard(window.innerWidth < 600);
	}

	const openSiteMenu = () => {
		setSiteMenuOpen(true);
		if (!isNullOrUndefined(siteRef.current) && !isNullOrUndefined(siteRef.current.select)) {
			siteRef.current.focus();
		}
	}

	const closeSiteMenu = () => setSiteMenuOpen(false);

	const openLocationMenu = () => {
		setLocationMenuOpen(true);
		if (!isNullOrUndefined(locationRef.current) && !isNullOrUndefined(locationRef.current.select)) {
			locationRef.current.focus();
		}
	}

	const closeLocationMenu = () => setLocationMenuOpen(false);

	const openSubLocationMenu = () => {
		setSubLocationMenuOpen(true);
		if (!isNullOrUndefined(sublocationRef.current) && !isNullOrUndefined(sublocationRef.current.select)) {
			sublocationRef.current.focus();
		}
	}

	const closeSubLocationMenu = () => setSubLocationMenuOpen(false);

	return (
		<Dialog
			open={props.searchModalOpen}
			onClose={() => props.handleSearchModal()}
			aria-labelledby={t("Asset Search")}
			fullScreen={useMediaQuery(useTheme().breakpoints.down('xs'))}
		>
			<DialogContent id="content" style={{ height: '580px' }}>
				<Grid container spacing={5}>
					<Grid item xs={12}>
						<InputLabel htmlFor="free-text-input">{t("Search")}</InputLabel>

						<TextField
							margin="dense"
							name={generateFirebaseId()}
							id="free-text-input"
							type="text"
							fullWidth={true}
							value={props.searchInput || ''}
							onChange={e => props.handleSearchInput(e.target.value)}
						/>
					</Grid>

					<div style={{ display: 'flex', columnGap: 10, width: '100%', margin: '10px 20px' }}>
						<div style={{ flex: '1' }}>
							<InputLabel htmlFor="assetClass-helper" style={{ marginBottom: 5 }}>{t("Asset Class")}</InputLabel>
							<AsyncPaginate
								id="assetClass-helper"
								value={props.assetClassFilter}
								onChange={props.handleAssetClassFilter}
								loadOptions={props.loadAssetClasses}
								blurInputOnSelect={true}
								loadingMessage={() => t("Loading Asset Classes...")}
								cacheUniqs={[props.assetClassFilter]}
								noOptionsMessage={() => t("No Asset Classes Found")}
								isSearchable={false}
							/>
						</div>
						<div style={{ flex: '0', marginTop: 28, justifySelf: 'flex-end' }}>
							{!isNullOrUndefined(props.assetClassFilter) && props.assetClassFilter !== '' && (
								<div>
									<IconButton onClick={() => props.clearSelectedAssetClass()} style={{ cursor: 'pointer' }}>
										<Icon color="error">
											delete_forever
										</Icon>
									</IconButton>
								</div>
							)}
						</div>
					</div>

					<div style={{ display: 'flex', columnGap: 10, width: '100%', margin: '10px 20px' }}>
						<div style={{ flex: '1' }}>
							<InputLabel htmlFor="assetSubClass-helper" style={{ marginBottom: 5 }}>
								{t("Asset Sub Class")}
							</InputLabel>
							<AsyncPaginate
								id="assetSubClass-helper"
								value={props.assetSubClassFilter}
								onChange={props.handleAssetSubClassFilter}
								loadOptions={props.loadAssetSubClasses}
								blurInputOnSelect={true}
								loadingMessage={() => t("Loading Asset Sub Classes...")}
								cacheUniqs={[props.assetSubClassFilter, props.assetClassFilter]}
								noOptionsMessage={() => t("No Asset Sub Classes Found")}
								isSearchable={false}
							/>
						</div>
						<div style={{ flex: '0', marginTop: 28, justifySelf: 'flex-end' }}>
							{!isNullOrUndefined(props.assetSubClassFilter) && props.assetSubClassFilter !== '' && (
								<div>
									<IconButton onClick={() => props.clearSelectedAssetSubClass()} style={{ cursor: 'pointer' }}>
										<Icon color="error">
											delete_forever
										</Icon>
									</IconButton>
								</div>
							)}
						</div>
					</div>


					<div style={{ display: 'flex', columnGap: 10, width: '100%', margin: '10px 20px' }}>
						<div style={{ flex: '1' }}>
							<p style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								<InputLabel htmlFor="site-helper" style={{ marginBottom: 5 }}>
									{t("Site")}
								</InputLabel>
								{
									showKeyboard && (
										<IconButton onClick={() => { setTextSearchSite(!enableTextSearchSite); openSiteMenu() }}>
											<Icon style={{ color: enableTextSearchSite ? 'green' : '' }} >keyboard</Icon>
										</IconButton>
									)
								}
							</p>
							<AsyncPaginate
								id="site-helper"
								isDisabled={props.siteFilterDisabled}
								value={props.siteFilter}
								selectRef={siteRef}
								onFocus={openSiteMenu}
								onBlur={closeSiteMenu}
								menuIsOpen={siteMenuOpen}
								onChange={(e) => { props.handleSiteFilter(e); setSiteMenuOpen(false); }}
								blurInputOnSelect={true}
								loadOptions={props.loadOptions}
								loadingMessage={() => t("Loading Sites...")}
								cacheUniqs={[props.siteFilter, props.siteValue]}
								noOptionsMessage={() => t("No Sites Found")}
								isSearchable={showKeyboard ? enableTextSearchSite : true}
							/>
						</div>
						<div style={{ flex: '0', marginTop: 28, justifySelf: 'flex-end' }}>
							{!props.siteFilterDisabled && !isNullOrUndefined(props.siteFilter) && props.siteFilter !== '' && (
								<div>
									<IconButton onClick={() => props.clearSelectedSitesOrLocations(1)} style={{ cursor: 'pointer' }}>
										<Icon color="error">
											delete_forever
										</Icon>
									</IconButton>
								</div>
							)}
						</div>
					</div>

					<div style={{ display: 'flex', columnGap: 10, width: '100%', margin: '10px 20px' }}>
						<div style={{ flex: '1' }}>
							<p style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								<InputLabel htmlFor="location-helper" style={{ marginBottom: 5 }}>
									{t("Location")}
								</InputLabel>
								{
									(showKeyboard && !props.locationDisabled) && (
										<IconButton onClick={() => { setTextSearchLocation(!enableTextSearchLocation); openLocationMenu(); }}>
											<Icon style={{ color: enableTextSearchLocation ? 'green' : '' }}>keyboard</Icon>
										</IconButton>
									)
								}
							</p>
							<AsyncPaginate
								id="location-helper"
								value={props.locationFilter}
								selectRef={locationRef}
								onFocus={openLocationMenu}
								onBlur={closeLocationMenu}
								menuIsOpen={locationMenuOpen}
								onChange={(e) => { props.handleLocationFilter(e); setLocationMenuOpen(false); }}
								blurInputOnSelect={true}
								loadOptions={props.loadLocations}
								loadingMessage={() => t("Loading Locations...")}
								cacheUniqs={[props.siteFilter, props.siteValue, props.locationFilter, props.locationValue]}
								isDisabled={props.locationDisabled}
								noOptionsMessage={() => t("No Locations Found")}
								isSearchable={showKeyboard ? enableTextSearchLocation : true}
							/>
						</div>
						<div style={{ flex: '0', marginTop: 28, justifySelf: 'flex-end' }}>
							{!isNullOrUndefined(props.locationFilter) ||
								props.locationFilter !== '' ||
								props.locationDisabled && (
									<div>
										<IconButton onClick={() => props.clearSelectedSitesOrLocations(2)} style={{ cursor: 'pointer' }}>
											<Icon color="error">
												delete_forever
											</Icon>
										</IconButton>
									</div>
								)}
						</div>
					</div>

					<div style={{ display: 'flex', columnGap: 10, width: '100%', margin: '10px 20px' }}>
						<div style={{ flex: '1' }}>
							<p style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								<InputLabel htmlFor="sublocation-helper" style={{ marginBottom: 5 }}>
									{t("Sub Location")}
								</InputLabel>
								{
									(showKeyboard && !props.subLocationDisabled) && (
										<IconButton onClick={() => { setTextSearchSubLocation(!enableTextSearchSubLocation); openSubLocationMenu(); }}>
											<Icon style={{ color: enableTextSearchSubLocation ? 'green' : '' }} >keyboard</Icon>
										</IconButton>
									)
								}
							</p>
							<AsyncPaginate
								id="sublocation-helper"
								value={props.subLocationFilter}
								selectRef={sublocationRef}
								onFocus={openSubLocationMenu}
								onBlur={closeSubLocationMenu}
								menuIsOpen={sublocationMenuOpen}
								onChange={(e) => { props.handleSubLocationFilter(e); setSubLocationMenuOpen(false); }}
								blurInputOnSelect={true}
								loadOptions={props.loadSubLocations}
								loadingMessage={() => t("Loading Sub Locations...")}
								cacheUniqs={[props.siteFilter, props.siteValue, props.locationFilter, props.locationValue, props.subLocationValue, props.subLocationFilter]}
								isDisabled={props.subLocationDisabled}
								noOptionsMessage={() => t("No Sub Locations Found")}
								isSearchable={showKeyboard ? enableTextSearchSubLocation : true}
							/>
						</div>
						<div style={{ flex: '0', marginTop: 28, justifySelf: 'flex-end' }}>
							{!isNullOrUndefined(props.subLocationFilter) ||
								props.subLocationFilter !== '' ||
								props.subLocationDisabled && (
									<div>
										<IconButton onClick={() => props.clearSelectedSitesOrLocations(3)} style={{ cursor: 'pointer' }}>
											<Icon color="error">
												delete_forever
											</Icon>
										</IconButton>
									</div>
								)}
						</div>
					</div>

				</Grid>
			</DialogContent>

			<DialogActions classes={{ root: 'action-buttons-list' }}>
				<Button onClick={props.handleSearchModal} color="primary">
					{t("Cancel")}
				</Button>
				<Button onClick={props.searchAssets} color="primary">
					{t("Search")}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default AssetSearchModal;
