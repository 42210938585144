// React
import * as React from 'react';

// Firebase
import { fire } from '../../index';

// Components
import QuestionLayout from './QuestionLayout';
import TextBoxAnswer from '../form-answers/TextBoxAnswer';
import { useTranslation, withTranslation } from 'react-i18next';

interface TextInputQuestionProps {
	data: Forms.QuestionAnswer;
	formComplete: boolean;
	t: any;
}

interface TextInputQuestionState {
	answer?: string | null;
	error: boolean;
}

 class TextInputQuestion extends React.PureComponent<TextInputQuestionProps, TextInputQuestionState> {
	constructor(props: Readonly<TextInputQuestionProps>) {
		super(props);
		this.state = {
			answer: this.props.data.Answer,
			error: false,
		};
	}

	UNSAFE_componentWillReceiveProps(newProps: TextInputQuestionProps) {
		if (newProps.data.Answer !== this.props.data.Answer) {
			this.setState({
				answer: newProps.data.Answer,
			});
		}
	}

	handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ answer: event.target.value });
	};

	parseInput = (): void => {
		let answer = this.state.answer;

		//if integer parse and return to string
		if (this.props.data.QuestionType === 'Integer') {
			answer = parseInt(answer || '', 10).toString();
		}

		//if decimal parse and return to string
		if (this.props.data.QuestionType === 'Decimal') {
			answer = parseFloat(answer || '').toString();
		}

		if (answer === 'NaN') {
			this.setState({ error: true });
			setTimeout(() => {
				this.setState({ error: false });
			}, 2000);
			return this.setState({ answer: '' });
		}

		this.setState({ answer });
		this.updateFirebase(answer);
	};

	updateFirebase = (Answer?: string | null) => {
		//update question in firebase
		this.props.data.questionRef.update({ Answer }).catch(err => console.error(this.props.t("Error updating answer - ") + err));

		//add answer to job queue
		fire.answerQuestion({ ...this.props.data, Answer }, {});

		fire.UpdateFormAfterAnswer(this.props.data.FormFBID);
	};

	_pickAnswer(): JSX.Element {
		switch (this.props.data.QuestionType) {
			case 'Integer':
				return (
					<IntegerAnswer
						answer={this.state.answer}
						handleInput={this.handleInput}
						parseInput={this.parseInput}
						error={this.state.error}
						questionID={this.props.data.QuestionID}
						formComplete={this.props.formComplete}
						isAutoIncrement={this.props.data.IsAutoIncrement}
					/>
				);

			case 'Decimal':
				return (
					<DecimalAnswer
						answer={this.state.answer}
						handleInput={this.handleInput}
						parseInput={this.parseInput}
						error={this.state.error}
						questionID={this.props.data.QuestionID}
						formComplete={this.props.formComplete}
					/>
				);

			case 'TextBox':
				return (
					<TextBoxAnswer
						answer={this.state.answer}
						onChange={this.handleInput}
						onBlur={this.parseInput}
						error={this.state.error}
						formComplete={this.props.formComplete}
					/>
				);

			default:
				return <div>{this.props.t("Could not find answer type.")}</div>;
		}
	}

	_answer() {
		if (this.props.data.QuestionType === 'DisplayText') {
			return (
				<QuestionLayout>
					{this.props.data.QuestionTitle}
					{null}
				</QuestionLayout>
			);
		}
		return (
			<QuestionLayout>
				{this.props.data.QuestionTitle}
				{this._pickAnswer()}
			</QuestionLayout>
		);
	}

	render() {
		return this._answer();
	}
}

const IntegerAnswer = (props: {
	answer?: string | null;
	handleInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
	parseInput: () => void;
	error: boolean;
	questionID: number;
	formComplete: boolean;
	isAutoIncrement: boolean;
}) => {
	const [t, i18n] = useTranslation();
	return (
		<div className="number-question-container">
			<input
				data-questionid={props.questionID}
				type="number"
				name="integer-answer"
				className={`number-question-container-input ${props.error ? 'input-error' : ''}`}
				value={props.answer || ''}
				onChange={val => props.handleInput(val)}
				onBlur={props.parseInput}
				disabled={props.isAutoIncrement ? true : props.formComplete}
			/>
			{props.error ? (
				<label className="input-error-text" htmlFor="integer-answer">
					{t("Must be integer")}
				</label>
			) : null}
		</div>
	);
};

const DecimalAnswer = (props: {
	answer?: string | null;
	handleInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
	parseInput: () => void;
	error: boolean;
	questionID: number;
	formComplete: boolean;
}) => {
	const [t, i18n] = useTranslation();
	return (
		<div className="number-question-container">
			<input
				data-questionid={props.questionID}
				type="number"
				name="decimal-answer"
				className={`number-question-container-input ${props.error ? 'input-error' : ''}`}
				value={props.answer || ''}
				onChange={val => props.handleInput(val)}
				onBlur={props.parseInput}
				disabled={props.formComplete}
			/>
			{props.error ? (
				<label className="input-error-text" htmlFor="integer-answer">
					{t("Must be decimal")}
				</label>
			) : null}
		</div>
	);
};

export default withTranslation()(TextInputQuestion);
