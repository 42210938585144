import { isNullOrUndefined } from 'util';

export default class Algolia {
    algoliasearch: any;
    client: any;
    indexAssets: any;
    indexSites: any;

	constructor() {
        this.algoliasearch = require('algoliasearch');
        this.client = this.algoliasearch('RRKLZGHDHA', '663199fb06b3b13f2069ae08bdf89d6a');
        this.indexAssets = this.client.initIndex('Assets');
        this.indexSites = this.client.initIndex('Sites');
    }


	async assetsSearch(
		searchInput: string,
		ClientID: string,
		userFilters?: AssetFilter,
		page?: number,
		hitsPerPage?: number,
	) {
		let assetsResult: Asset[] = [];
		// let attributesForFaceting = await this.prepareFiltersAssets(userFilters);
		const filters = await this.setFiltersAssets(ClientID, userFilters);

		await this.indexAssets
			.setSettings({
				//attributesForFaceting
			})
			.then(async data => {
				await this.indexAssets
					.search(searchInput, {
						filters,
						hitsPerPage: hitsPerPage || 100,
						page,
					})
					.then(data => {
						assetsResult = data.hits;
					});
			});

		return assetsResult;
    };

    async sitesSearch(searchInput: string, ClientID: string, userFilters?: any) {
        let sitesResult = [];
        let filters = await this.setFiltersSites(ClientID, userFilters);

        await this.indexSites.setSettings({
        }).then(async (data) => {
             await this.indexSites.search(searchInput, {
                filters, hitsPerPage: 100
            }).then((data) => {
                sitesResult = data.hits;
                });
        });

        return sitesResult;  
    };

    async prepareFiltersAssets(userFilters?: any){
        let attributesForFaceting = ['filterOnly(ClientFBID)'] as any;

        if(!isNullOrUndefined(userFilters['site'])){
            attributesForFaceting.push('filterOnly(SiteID)');
        };

        if(!isNullOrUndefined(userFilters['location'])){
            attributesForFaceting.push('filterOnly(LocationID)');
        };

        if(!isNullOrUndefined(userFilters['subLocation'])){
            attributesForFaceting.push('filterOnly(SubLocationID)');
        };

        if(!isNullOrUndefined(userFilters['assetClass'])){
            attributesForFaceting.push('filterOnly(AssetClassFBID)');
        };

        if(!isNullOrUndefined(userFilters['assetSubClass'])){
            attributesForFaceting.push('filterOnly(AssetSubClassFBID)');
        };
        
        return attributesForFaceting; 
    };


    async setFiltersAssets(ClientID: string, userFilters?: any){
        let filters = `ClientFBID:"${ClientID}"`;

        if(!isNullOrUndefined(userFilters['site'])){
            filters = filters.concat(` AND SiteID:"${userFilters['site']}"`);
        };

        if(!isNullOrUndefined(userFilters['location'])){
            filters = filters.concat(` AND LocationID:"${userFilters['location']}"`);
        };

        if(!isNullOrUndefined(userFilters['subLocation'])){
            filters = filters.concat(` AND SubLocationID:"${userFilters['subLocation']}"`);
        };

        if(!isNullOrUndefined(userFilters['assetClass'])){
            filters = filters.concat(` AND AssetClassFBID:"${userFilters['assetClass']}"`);
        };

        if(!isNullOrUndefined(userFilters['assetSubClass'])){
            filters = filters.concat(` AND AssetSubClassFBID:"${userFilters['assetSubClass']}"`);
        };
        
        return filters; 
    };

    async setFiltersSites(ClientID: string, userFilters?: any){
        let filters = `ClientFBID:"${ClientID}"`;

        if(!isNullOrUndefined(userFilters['siteType'])){
            filters = filters.concat(` AND SiteTypeFBID:"${userFilters['siteType']}"`);
        };

        if(!isNullOrUndefined(userFilters['region'])){
            filters = filters.concat(` AND RegionFBID:"${userFilters['region']}"`);
        };

        if(!isNullOrUndefined(userFilters['contract'])){
            filters = filters.concat(` AND ContractFBID:"${userFilters['contract']}"`);
        };

        if(!isNullOrUndefined(userFilters['canAccessAllSites']) && userFilters['canAccessAllSites'] == false){
            filters = filters.concat(` AND UsersPermittedToSite:"${userFilters['userUID']}"`);
        }

        if(!isNullOrUndefined(userFilters['contractFBID'] && userFilters['contractFBID'] != "")) {
            filters = filters.concat(` AND ContractFBID:"${userFilters['contractFBID']}"`);
        }
        

        console.log(filters);
        
        return filters; 
    };
}