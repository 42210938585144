// dexie import for IndexedDB
import Dexie from 'dexie';

export default class TrackplanDatabase extends Dexie {
	jobphotos!: Dexie.Table<indexDb.Photos, number>; // id is number in this case
	createjobPhotos!: Dexie.Table<indexDb.CreateJobPhotos, number>; // id is number in this case
	localQueue!: Dexie.Table<indexDb.LocalQueue, string>;
	documents!: Dexie.Table<indexDb.Documents, number>; // id is number in this case

	constructor() {
		super('TrackplanManagerDB');
		this.version(1).stores({
			jobphotos: '++id,documentId,&guid',
			jobdocuments: '++id,documentId,&guid',
			createjobPhotos: '++id,guid',
		});
		this.version(2).stores({
			localQueue: '&documentId',
		});
		this.version(3).stores({
			localQueue: '&id, documentId',
			jobdocuments: '++id,documentId,guid',
		});

		this.version(4).stores({
			jobphotos: '++id,documentId,&guid,isComplete',
			jobdocuments: '++id,documentId,&guid',
			createjobPhotos: '++id,guid',
			localQueue: '&id, documentId',
		});

		this.version(5).stores({
			jobphotos: '++id,documentId,&guid,isComplete',
			jobdocuments: '++id,documentId,&guid, isComplete',
			createjobPhotos: '++id,guid',
			localQueue: '&id, documentId',
		});

		this.version(6).stores({
			jobphotos: '++id,documentId,&guid,isComplete,formId,photoId',
			jobdocuments: '++id,documentId,&guid, isComplete,formId',
			createjobPhotos: '++id,guid',
			localQueue: '&id, documentId, photoId',
		});

		this.version(7).stores({
			jobphotos: '++id, documentId, &guid, isComplete, formId, photoId',
			jobdocuments: null,
			createjobPhotos: '++id, guid',
			localQueue: '&id, documentId, photoId',
			documents: '++id, documentId, &guid, isComplete, formId, documentFBID, *tags',
		});

		this.version(8).stores({
			jobphotos: '++id, documentId, &guid, isComplete, formId, photoId',
			jobdocuments: null,
			createjobPhotos: '++id, guid',
			localQueue: '&id, documentId, photoId',
			documents: '++id, documentId, &guid, isComplete, formId, documentFBID, *tags',
		});

		this.jobphotos = this.table('jobphotos');
		this.createjobPhotos = this.table('createjobPhotos');
		this.localQueue = this.table('localQueue');
		this.documents = this.table('documents');
	}
}
