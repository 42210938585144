import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Request from '../../definitions/request';
import '../../styles/requests/request-details.css';
import { unixToDateStringForRequestListOnly } from '../../utils/Times';

interface Field {
	content: any;
	label: string;
	hasLineBreak: boolean;
}

interface FieldsDisplayerProps {
	fields: Field[];
}

const FieldsDisplayer = ({ fields }: FieldsDisplayerProps) => (
	<div className="request-card">
		<Grid container>
			{fields.map((field, index) => (
				<Grid key={index} container item xs={6} justify="flex-start" alignItems="flex-end">
					<div className='contentHolder'>
						<p className="content-request">{field.content}</p>
						<p className="label-request">{field.label}</p>
						{field.hasLineBreak && <br />}
					</div>
				</Grid>
			))}
		</Grid>
	</div>
);

interface RequestMainDetailsProps {
	request: Request;
}

const RequestMainDetails = ({ request }: RequestMainDetailsProps) => {
	const [t, i18n] = useTranslation();
	const requestMainFieldsToDisplay: Field[] = [
		{ content: request.Details, label: t('Details'), hasLineBreak: true },
		{ content: t(request.RequestStatus), label: t('Status'), hasLineBreak: true },
		{ content: request.RequestNumber, label: t('Request Number'), hasLineBreak: true },
		{ content: request.RaisedBy, label: t('Raised By'), hasLineBreak: true },
		{ content: request.JobType, label: t('Job Type'), hasLineBreak: true },
		{ content: request.JobSubType, label: t('Job Sub Type'), hasLineBreak: true },
		{ content: typeof request.Created === 'number' ? unixToDateStringForRequestListOnly(request.Created) : request.Created, label: t('Created'), hasLineBreak: false },
	];
	const requestSecondaryFieldsToDisplay: Field[] = [
		{ content: request.Site, label: t('Site Name'), hasLineBreak: true },
		{ content: request.Location, label: t('Location Name'), hasLineBreak: true },
		{ content: request.SubLocation, label: t('SubLocation Name'), hasLineBreak: false },
	];
	return (
		<>
			<FieldsDisplayer fields={requestMainFieldsToDisplay} />
			<FieldsDisplayer fields={requestSecondaryFieldsToDisplay} />
		</>
	);
};

export default RequestMainDetails;
