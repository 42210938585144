// Axios For httpRequests
import axios, { AxiosError } from 'axios';

// Utils
import { getBaseURL } from './getBaseURL';
import ApiKeyObj from './ApiObjectKey';

const findBrowser = () => {
	if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) != -1) {
		return 'Opera';
	} else if (navigator.userAgent.indexOf('Chrome') != -1) {
		return 'Chrome';
	} else if (navigator.userAgent.indexOf('Safari') != -1) {
		return 'Safari';
	} else if (navigator.userAgent.indexOf('Firefox') != -1) {
		return 'Firefox';
	} else {
		return 'unknown';
	}
};

export const SendErrorData = async (
	Email: string,
	UID: string,
	ServerName: string | undefined,
	Message: string,
	Source: string,
	AppScreeen: string,
) => {
	try {
		const server = ServerName === undefined ? 'Ireland' : ServerName;
		const baseurl = getBaseURL(server);

		const data = {
			FBID: UID,
			Email: Email,
			ErrorMessage: Message.toString(),
			Device: navigator.appVersion,
			Browser: findBrowser(),
			URL: Source,
			AppScreeen: AppScreeen,
		};

		// await axios({
		// 	data: data,
		// 	method: 'post',
		// 	url: baseurl + '/api/exception/appexception',
		// 	headers: ApiKeyObj,
		// }).catch((err: AxiosError) => {
		// 	console.log(err);
		// });
	} catch (err) {
		console.log(err);
	}
};

export const windowError = (Email: string, UID: string, ServerName: string | undefined, AppScreeen: string) => {
	window.onerror = async function(Message, Source) {
		if (navigator.onLine) {
			const ErrorMessage = Message.toString();
			const URL = Source !== undefined ? Source.toString() : '';

			await SendErrorData(Email, UID, ServerName, ErrorMessage, URL, AppScreeen);
			if (ErrorMessage.includes('An internal error was encountered in the Indexed Database server')) {
				window.location.reload();
			}

			if (ErrorMessage.includes('AsyncQueue is already failed')) {
				window.location.reload();
			}

			if (ErrorMessage.includes('Attempt to delete range from database without an in-progress transaction')) {
				window.location.reload();
			}

			if (ErrorMessage.includes(`Attempt to iterate a cursor that doesn't exist`)) {
				window.location.reload();
			}
		}
	};
};

// request.open('POST', 'https://staging.trackplanfm.com/HeadOffice/RR3695x7r/CreateAppException', true);
// request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
// request.send(JSON.stringify(data));
