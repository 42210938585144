// React
import * as React from 'react';
import { useState } from 'react';
import { CircularProgress } from '@material-ui/core';

//utils
import { isNullOrUndefined } from 'util';

// Components
import CardHeader from '../shared/CardHeader';
import OuterCard from '../shared/OuterCard';
import JobTaskPhotos from './JobTaskPhotos';

//card icon
import PHOTO from '../../assets/images/icons/PHOTO.svg'

interface JobTaskPhotosCardProps {
    documentId: string;
    completedDate: number | undefined | null;
    jobTasks: string[];
    categorisedPhotos: { [id: string]: Job.JobPhoto[] };
    history: History;
    t: any;
    photosLoading: boolean;
}

export default function JobTaskPhotosCard(props: JobTaskPhotosCardProps) {

    const [expand, setExpand] = useState(false);

    const handleExpand = (value: boolean) => {
        setExpand(value)
    }

    return (
        <>
            <OuterCard marginBottom="15px" styles='border-radius'>
                <CardHeader
                    title={props.t("Job Task Photos")}
                    textColor="white"
                    backgroundColor="var(--dark-blue)"
                    expand={expand}
                    handleExpand={handleExpand}
                    showExpand={true}
                    titleIcon={PHOTO}
                />

                {expand ? <div className='margin-top loading-elements-card-container'>
                    {props.photosLoading ? (
                        <>
                            {props.t("Loading")}
                            <CircularProgress
                                size={10}
                                color={'inherit'} style={{ "marginLeft": "5px" }}
                            />
                        </>
                    ) : props.jobTasks.length > 0 ? props.jobTasks.map((jobTask, _i) => (
                        <JobTaskPhotos
                            // @ts-ignore
                            history={props.history}
                            documentId={props.documentId}
                            isComplete={!isNullOrUndefined(props.completedDate)}
                            color=""
                            photos={props.categorisedPhotos[jobTask]}
                            titleText={jobTask}
                            noPhotosText={props.t("No Job Photos")}
                            isForRequest={false}
                            canRemovePhoto={false}
                            canDownloadPhoto={false}
                            numberOfPhotos={0}
                        />
                    )) : <div className='no-elements-card-text'>
                        <p>{props.t("No Photos")}</p>
                    </div>}
                </div> : <></>}
            </OuterCard>
        </>
    );

}
