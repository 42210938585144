import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { fire } from '../..';
import Request, { IRequestFilter } from '../../definitions/request';
import { LocalstorageGetItem, LocalstorageRemoveItem, LocalstorageSetItem } from '../../utils/LocalStorage';
import useDialog from '../useDialog';

const DOCUMENTS_PER_PAGE = 25;
const LOCAL_STORAGE_KEY = 'RequestsFilterOptions';

const useRequests = () => {
	const mountedRef = useRef(true);
	const user = useSelector((state: Store.Store) => {
		let canViewAllRequests = state.User.UserSettings!.CanViewAllRequests;
		if (canViewAllRequests === null || canViewAllRequests === undefined) {
			canViewAllRequests = true;
		}
		return {
			userID: state.User.UserSettings!.UserUID as string,
			canViewAllRequests,
		};
	});
	const [isLoading, setIsLoading] = useState(false);
	const [isEndOfList, setIsEndOfList] = useState(false);
	const [lastLoadedDocument, setLastLoadedDocument] = useState<firebase.default.firestore.DocumentData | null>(null);
	const [requests, setRequests] = useState<Request[]>([]);
	const [isFiltered, setIsFiltered] = useState(false);
	const [selectedSite, setSelectedSite] = useState(null as any);
	const [selectedLocation, setSelectedLocation] = useState(null as any);
	const [selectedSubLocation, setSelectedSubLocation] = useState(null as any);
	const [selectedStatus, setSelectedStatus] = useState<string>("None");
	const [requestNumber, setRequestNumber] = useState(null as any);

	const {
		isDialogOpen: isSearchDialogOpen,
		openDialog: openSearchDialog,
		closeDialog: closeSearchDialog,
	} = useDialog();

	const handleSiteChange = site => {
		setSelectedSite(site);
		setSelectedLocation(null);
		setSelectedSubLocation(null);
	};
	const handleLocationChange = location => {
		setSelectedLocation(location);
		setSelectedSubLocation(null);
	};
	const handleSubLocationChange = subLocation => {
		setSelectedSubLocation(subLocation);
	};

	useEffect(() => {
		let filters: IRequestFilter = {};
		const storedFilters = JSON.parse(LocalstorageGetItem(LOCAL_STORAGE_KEY) || 'null');
		if (storedFilters) {
			setSelectedSite(storedFilters.selectedSite);
			setSelectedLocation(storedFilters.selectedLocation);
			setSelectedSubLocation(storedFilters.selectedSubLocation);
			setRequestNumber(storedFilters.requestNumber);
			setSelectedStatus(storedFilters.selectedStatus);

			filters = {
				SiteFBID: storedFilters.selectedSite && storedFilters.selectedSite.value,
				LocationFBID: storedFilters.selectedLocation && storedFilters.selectedLocation.value,
				SubLocationFBID: storedFilters.selectedSubLocation && storedFilters.selectedSubLocation.value,
				RequestNumber: storedFilters.requestNumber,
				RequestStatusID: storedFilters.selectedStatus
			};
			setIsFiltered(true);
		}
		Object.keys(filters).length ? getRequests(false, filters) : getRequests();
		return () => {
			mountedRef.current = false;
		};
	}, []);

	const requestFilterObject = useMemo((): IRequestFilter => {
		return ({
			SiteFBID: selectedSite && selectedSite.value,
			LocationFBID: selectedLocation && selectedLocation.value,
			SubLocationFBID: selectedSubLocation && selectedSubLocation.value,
			RequestStatusID: selectedStatus === "None" ? "" : selectedStatus,
			RequestNumber: requestNumber
		})

	}, [selectedSite, selectedSubLocation, selectedLocation, requestNumber, selectedStatus]);

	const getRequests = (isReset?: boolean, filters?: any, isFilteredAndPaginated?: boolean) => {
		const isResetOrFiltered = isReset || (filters !== undefined && !isFilteredAndPaginated);
		const scrollY = isResetOrFiltered ? 0 : window.scrollY;
		setIsLoading(true);
		fire
			.getRequestsCollectionPaginated(
				user.canViewAllRequests,
				user.userID,
				DOCUMENTS_PER_PAGE,
				isResetOrFiltered ? null : lastLoadedDocument,
				filters,
			)
			.get()
			.then(queryResult => mountedRef.current && _handleQueryResult(queryResult, isResetOrFiltered))
			.finally(() => {
				mountedRef.current && setIsLoading(false);
				window.scrollTo(0, scrollY);
			});
	};

	const _handleQueryResult = (queryResult: firebase.default.firestore.QuerySnapshot, isResetOrFiltered?: boolean) => {
		if (queryResult.empty) {
			setIsEndOfList(true);
			if (isResetOrFiltered) setRequests([]);
		} else {
			if (isResetOrFiltered) setIsEndOfList(false);
			const rows = queryResult.docs.map(requestRow => {
				const row = requestRow.data() as Request;
				row.ID = requestRow.id;
				return row;
			});
			const data = isResetOrFiltered ? rows : requests.concat(rows);
			setRequests(data.sort((a, b) => b.RequestID - a.RequestID));
			setLastLoadedDocument(queryResult.docs[queryResult.docs.length - 1]);
		}
	};

	const searchRequests = () => {
		if (selectedSite || selectedLocation || selectedSubLocation || selectedStatus) {
			setIsFiltered(true);
			const filters: IRequestFilter = requestFilterObject;
			LocalstorageSetItem({
				Key: LOCAL_STORAGE_KEY,
				Value: {
					selectedSite,
					selectedLocation,
					selectedSubLocation,
					selectedStatus: selectedStatus === "None" ? undefined : selectedStatus,
					requestNumber
				},
			});
			getRequests(false, filters);
		} else {
			if (isFiltered) {
				clearSearch();
			}
		}
		closeSearchDialog();
	};

	const clearSearch = () => {
		setIsFiltered(false);
		setSelectedSite(null);
		setSelectedLocation(null);
		setSelectedSubLocation(null);
		setRequestNumber(null);
		setSelectedStatus("None")
		LocalstorageRemoveItem(LOCAL_STORAGE_KEY);
		getRequests(true);
	};

	const loadMoreRequests = () => {
		if (isFiltered) {
			const filters: IRequestFilter = requestFilterObject;
			getRequests(false, filters, true);
		} else {
			getRequests();
		}
	};

	return {
		isLoading,
		isFiltered,
		isEndOfList,
		requests,
		loadMoreRequests,
		isSearchDialogOpen,
		openSearchDialog,
		closeSearchDialog,
		selectedSite,
		handleSiteChange,
		selectedLocation,
		handleLocationChange,
		selectedSubLocation,
		handleSubLocationChange,
		searchRequests,
		clearSearch,
		_handleQueryResult,
		setSelectedStatus,
		selectedStatus,
		requestNumber,
		setRequestNumber
	};
};

export default useRequests;
