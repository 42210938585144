// React
import * as React from 'react';

// Material UI
import Icon from '@material-ui/core/Icon/Icon';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { isNullOrUndefined } from 'util';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const ResourceCard = props => {
	const [t, i18n] = useTranslation();
	return props.resources.map((resources: Resource, i: number) => {
		let TradeString = '';
		let TeamsString = '';

		for (let i = 0; i < resources.Trades.length; i++) {
			if (i + 1 < resources.Trades.length) {
				TradeString = TradeString + resources.Trades[i] + ', ';
			}
			if (i + 1 === resources.Trades.length) {
				TradeString = TradeString + resources.Trades[i];
			}
		}
		if (!isNullOrUndefined(resources.Teams)) {
			for (let i = 0; i < resources.Teams.length; i++) {
				if (i + 1 < resources.Teams.length) {
					TeamsString = TeamsString + resources.Teams[i] + ', ';
				}
				if (i + 1 === resources.Teams.length) {
					TeamsString = TeamsString + resources.Teams[i];
				}
			}
		}
		return (
			<div
				key={i}
				style={{
					border: `2.5px solid var(--light-grey)`,
					marginBottom: '10px',
					padding: '10px',
				}}
				className="card-shadow resource-card"
			>
				<Grid container>
					<Grid container xs={5} justify="flex-start" alignItems="flex-end">
						<div>
							<p style={{ overflowWrap: 'break-word' }} className="content-resource">
								{resources.Name}
							</p>
							<p className="label-resource">{t("Resource Name")}</p>
							<br />
						</div>
					</Grid>

					<Grid container xs={5} justify="flex-start" alignItems="flex-end">
						<div>
							<p className="content-resource">{TeamsString}</p>
							<p className="label-resource">{t("Teams")}</p>
							<br />
						</div>
					</Grid>

					<Grid container xs={2} justify="flex-end" alignItems="flex-start">
						<IconButton onClick={() => props.handleCloseChooseResourceDetailModal(resources, TradeString, TeamsString)}>
							<Icon style={{ color: 'var(--dark-blue)', fontSize: '32px' }}>info</Icon>
						</IconButton>
					</Grid>

					<Grid container xs={5} justify="flex-start" alignItems="flex-end">
						<div>
							<p className="content-resource">{resources.Town}</p>
							<p className="label-resource">{t("Town")}</p>
						</div>
					</Grid>

					<Grid container xs={5} justify="flex-start" alignItems="flex-end">
						<div>
							<p className="content-resource">{TradeString}</p>
							<p className="label-resource">{t("Trades")}</p>
						</div>
					</Grid>

					<Grid container xs={2} justify="flex-end" alignItems="flex-end">
						{props.DocumentID !== 'list' && (
							<IconButton
								onClick={() =>
									props.handleChooseResource(resources.ID, resources.Name, props.DocumentID, props.history, t)
								}
							>
								<Icon style={{ color: 'var(--dark-green)', fontSize: '32px' }}>person_add</Icon>
							</IconButton>
						)}
					</Grid>
				</Grid>
			</div>
		);
	});
};

export default ResourceCard;
