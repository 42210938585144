// React
import * as React from 'react';

// Components
import PhotosDialog from '../photos/PhotosDialog';

interface Photos {
	FirebaseStoragePath: string;
	LocalGuid?: string;
	fileName?: string;
}

interface PhotosProps {
	history?: History;
	documentId?: string;
	isComplete?: boolean;
	color: string | null;
	photos: Photos[];
	titleText: string;
	noPhotosText: string;
	isForRequest: boolean;
	canRemovePhoto: boolean;
	canDownloadPhoto: boolean;
	handleDownloadPhoto?: (URL: string, FileName: string) => void;
	handleRemovePhoto?: (Guid: string, Reference: any) => void;
	documentReference?: any;
	numberOfPhotos?: number;
	handleDownloadAllPhotos?: () => void;
	displayDownloadAll?: boolean;
	canEditPhoto?: boolean;
	expand: boolean;
	handleExpand: (value: boolean) => void;
	showActionButton: boolean;
	openImageDialog: (() => void) | undefined;
	saveImage?: (selectorFiles: FileList | null) => Promise<void>;
	photosLoading?: boolean;
}

export default class JobPhotos extends React.Component<PhotosProps> {
	render() {
		return (
			<>
				{<input
					id="image-upload"
					type="file"
					multiple
					onChange={e => this.props.saveImage ? this.props.saveImage(e.target.files) : undefined}
					style={{ display: 'none' }}
				/>}
				<PhotosDialog
					history={this.props.history}
					documentId={this.props.documentId}
					isComplete={this.props.isComplete}
					photos={this.props.photos}
					color={this.props.color}
					isForRequest={this.props.isForRequest}
					noPhotosText={this.props.noPhotosText}
					titleText={this.props.titleText}
					canRemovePhoto={this.props.canRemovePhoto}
					canDownloadPhoto={this.props.canDownloadPhoto}
					handleDownloadPhoto={this.props.handleDownloadPhoto}
					handleRemovePhoto={this.props.handleRemovePhoto}
					handleDownloadAllPhotos={this.props.handleDownloadAllPhotos}
					documentReference={this.props.documentReference}
					numberOfPhotos={this.props.numberOfPhotos}
					displayDownloadAll={this.props.displayDownloadAll}
					canEditPhoto={this.props.canEditPhoto}
					expand={this.props.expand}
					handleExpand={this.props.handleExpand}
					showActionButton={this.props.showActionButton}
					openImageDialog={this.props.openImageDialog}
					photosLoading={this.props.photosLoading}
				/>
			</>
		);
	}
}
