// React
import * as React from 'react';

// Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon/Icon';
import { useTranslation } from 'react-i18next';

const CompleteJobModal = props => {
	const {t, i18n} = useTranslation();
	return (
	<Dialog open={props.open} onClose={props.handleDialogClose} fullScreen={true} maxWidth="lg">
		<DialogTitle id="form-dialog-title">{t("Complete Job")}</DialogTitle>
		<DialogContent>
			<TextField
				autoFocus={true}
				margin="none"
				id="add-note"
				label={t("Complete Job Note")}
				type="text"
				fullWidth={true}
				multiline={true}
				onChange={e => props.newCompleteJobNote(e)}
			/>
		</DialogContent>
		<DialogActions>
			<Button
				onClick={props.handleCloseCompleteJobModal}
				color="primary"
				style={{ backgroundColor: 'var(--light-grey)', color: 'white' }}
			>
				<Icon style={{ fontSize: 20 }}>undo</Icon> &nbsp;
				<p style={{ marginTop: 2 }}>{t("Back")}</p>
			</Button>
			<Button
				onClick={props.handleCompleteJob}
				color="primary"
				disabled={props.isDisabled}
				style={{ backgroundColor: props.isDisabled ? 'grey' : 'var(--light-green)', color: 'white' }}
			>
				<Icon style={{ fontSize: 20 }}>done</Icon>&nbsp;
				<p style={{ marginTop: 2 }}>{t("Complete Job")}</p>
			</Button>
		</DialogActions>
	</Dialog>
)};

export default CompleteJobModal;
