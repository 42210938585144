import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import App from './App';
import { register } from './serviceWorker';
import IndexDb from './utils/database/indexdb/IndexDb';
import Firebase from './utils/Firebase';
import Algolia from './utils/Algolia';
import LocalQueue from './utils/database/indexdb/LocalQueue';
// import MessageBroker from './messageBroker';
import packageJson from '../package.json';
import './i18n';

import './index.css';

if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: 'https://62a8632ada3e45c8b919d05cab479cd1@sentry.io/1888487',
		release: 'trackplan-manager-app@' + packageJson.version,
	});
}

const fire = new Firebase();
const algolia = new Algolia();

ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);

register();


const idb = new IndexDb();
const localQueue = new LocalQueue(fire, idb);
// new MessageBroker();

export { fire, idb, localQueue, algolia };
