import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fire } from '../../index';
import useDialog from '../useDialog';

enum Action {
	ADD,
	REMOVE,
}

const useStocksTable = (storageArea: StorageArea, refetchStorageArea: () => void) => {
	const { isDialogOpen, openDialog, closeDialog } = useDialog();
	const [action, setAction] = useState(Action.ADD);
	const [quantity, setQuantity] = useState<number | string>('');
	const [comments, setComments] = useState('');
	const [stockToChange, setStockToChange] = useState(storageArea.StockToStorageArea[0]);
	const [t, i18n] = useTranslation();

	const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
		const posQuantity = event.target.value.replace('-', '');
		setQuantity(Number(posQuantity));
	};

	const handleOpenDialog = (action: Action, stockToChange: StockToStorageArea) => {
		setAction(action);
		setStockToChange(stockToChange);
		setQuantity('');
		setComments('');
		openDialog();
	};

	const _getNewLevel = (selectedQuantity: number) => {
		let newLevel: number;
		if (action === Action.ADD) {
			newLevel = stockToChange.CurrentLevel + selectedQuantity;
		} else {
			newLevel = stockToChange.CurrentLevel - selectedQuantity;
		}
		return newLevel;
	};

	const _getUpdatedStocks = (newLevel: number) => {
		return storageArea.StockToStorageArea.map(stock => {
			const updatedStock = { ...stock };
			if (stock.StockFBID === stockToChange.StockFBID) {
				updatedStock.CurrentLevel = newLevel;
			}
			return updatedStock;
		});
	};

	const handleAction = () => {
		const selectedQuantity = Number(quantity);
		if (selectedQuantity !== 0) {
			const newLevel = _getNewLevel(selectedQuantity);
			const updatedStocks = _getUpdatedStocks(newLevel);

			fire.baseQuery
				.collection('StorageAreas')
				.doc(storageArea.ID)
				.update({ StockToStorageArea: updatedStocks })
				.then(() => refetchStorageArea());

			const storageAreaObj = {
				StorageAreaFBID: storageArea.ID,
				StockFBID: stockToChange.StockFBID,
				StockID: stockToChange.StockID,
				JobAction: 'StorageAreaStockAdjustment',
				Quantity: newLevel,
				Comments: comments,
			};

			fire.postToJobQueue(storageArea.ID, storageAreaObj).catch(err => {
				alert(t('There was an error updating the quantity. If problem persists please email - support@trackplanfm.com'));
			});
		}
		closeDialog();
	};

	return {
		isDialogOpen,
		handleOpenDialog,
		closeDialog,
		action,
		stockToChange,
		quantity,
		handleQuantityChange,
		comments,
		setComments,
		handleAction,
		_getNewLevel,
		_getUpdatedStocks,
	};
};

export default useStocksTable;
