// React
import * as React from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { match } from 'react-router';

// Config File
import Api from '../../apiConfiguration.json';

// Firebase
import firebase from 'firebase';
import { fire } from '../../index';

// Material UI
import { Button, Grid, Icon, IconButton } from '@material-ui/core';

// Components
import LoadingSpinner from '../../components/Spinners/LoadingSpinner';
import SideDrawer from '../../components/shared/SideDrawer';
import SubLocationCard from '../../components/locations/SubLocationCard';
import BackButton from '../../components/shared/BackButton';


// Utils
import { isNullOrUndefined } from 'util';
import { LocalstorageRemoveItem } from '../../utils/LocalStorage';
import { windowError, SendErrorData } from '../../utils/WindowError';
import { getBaseURL } from '../../utils/getBaseURL';
import ApiKeyObj from '../../utils/ApiObjectKey';

// CSS
import '../../styles/sites/site-list.css';
import QRCodeDialog from '../../components/shared/QRCodeDialog';
import { readQRCodeAndNavigate } from '../../utils/QRCode';
import { withTranslation } from 'react-i18next';

interface SubLocationsListScreenProps {
	history: History;
	match: match;
	UserSettings: Store.UserSettings;
	t: any;
}

interface SubLocationsListScreenState {
	sublocationArray: SubLocation[];
	SelectedSubLocation: SubLocation | null;
	lastLoadedSubLocation: any;
	loading: boolean;
	loadingMore: boolean;
	LoadingPageGrid: boolean;
	endOfList: boolean;
	documentsPerPage: number;
	DocumentID: string;
	yAxisScroll: number;
	locationCode: number;
	siteName: string;
	LocationName: string;
	SearchbyQr: boolean;
}

class SubLocationsListScreen extends React.Component<SubLocationsListScreenProps, SubLocationsListScreenState> {
	private unsubscribe: any = null;

	constructor(props) {
		super(props);
		this.state = {
			sublocationArray: [],
			SelectedSubLocation: null,
			lastLoadedSubLocation: null,
			loading: false,
			loadingMore: false,
			LoadingPageGrid: true,
			endOfList: false,
			documentsPerPage: 25,
			DocumentID: '',
			yAxisScroll: 0,
			locationCode: 0,
			siteName: '',
			LocationName: '',
			SearchbyQr: false,
		};
	}

	componentWillMount() {
		windowError(
			this.props.UserSettings.email,
			this.props.UserSettings.UserUID,
			this.props.UserSettings.ServerName,
			'SubLocationList',
		);

		this.setState({ loading: true });
	}

	async componentDidMount() {
		const { id } = this.props.match.params as any;
		const locationID = parseInt(id);
		await this.setState({ locationCode: id });
		this.setState({ DocumentID: id, locationCode: locationID });
		this.getSite(id);
		this.getSiteFromID(locationID);
		this.getSubLocations();
		
	}

	getSiteFromID(id: number) {
		fire.getSiteInfoFromID(id).then(result => {
			result.forEach(doc => {
				const Site = doc.data();
				this.setState({ siteName: Site.SiteName });
			})
		});
	}

	getSite(id: string){
		fire.getSiteInfo(id).then(result => {
			const site = result.data();
			if(!isNullOrUndefined(site)){ this.setState({siteName: site.SiteName}); }
		});
	}

	//Run the initial query to get all sub-locations for location code in URL..
	getSubLocations() {
		this.setState({ yAxisScroll: window.scrollY, loading: true }, () => {
			this.unsubscribe = fire
				.getSubLocationsCollectionPaginated(this.state.documentsPerPage, this.state.locationCode, this.state.lastLoadedSubLocation)
				.onSnapshot(this.handleSubLocationSnapshot);
		});
	}

	//Process the query returned from getLocations()
	handleSubLocationSnapshot = async (query: firebase.firestore.QuerySnapshot) => {
		if (query.empty) {
			this.setState({ loading: false, loadingMore: false, endOfList: true });
			window.scrollTo(0, this.state.yAxisScroll);
			if (isNullOrUndefined(this.state.lastLoadedSubLocation)) this.setState({ sublocationArray: [] });
			return;
		}

		let sublocationArray = isNullOrUndefined(this.state.lastLoadedSubLocation) ? [] : this.state.sublocationArray;
		let locationName;
		let siteName;
		let siteCode;
		
		await fire.getLocationInfoFromID(this.state.locationCode).then(data => {
				data.docs.forEach(location => {
					locationName = location.data().LocationName;
					siteCode = location.data().SiteID;
				})
			});

		await fire.getSiteInfoFromID(siteCode)
			.then(result => {
				result.docs.forEach(site => {
					siteName = site.data().SiteName;
				})
		});
		
		sublocationArray.push(
			...query.docs.map(sublocationRow => {
				const row = sublocationRow.data() as SubLocation;
				row.ID = sublocationRow.id;
				row.LocationName = locationName;
				row.SiteName = siteName;
				return row;
			}),
		);

		sublocationArray.sort((a, b) => a.SubLocationName.toLowerCase().localeCompare(b.SubLocationName.toLowerCase()));

		this.setState({
			sublocationArray,
			lastLoadedSubLocation: query.docs[query.docs.length - 1],
			loading: false,
			loadingMore: false,
		}, () => {
			window.scrollTo(0, this.state.yAxisScroll);
		});
	};


	backButton = () => {
		window.history.back()
	};

	handleLogout() {
		LocalstorageRemoveItem('NewJobDetails');
		fire.auth.signOut().then(() => location.reload());
	}

	render() {
		const title = this.props.t('SubLocation List');

		if (this.state.loading) {
			return <LoadingSpinner text={this.props.t("Loading SubLocations...")} />;
		}

		if (!isNullOrUndefined(this.state.sublocationArray) && this.state.sublocationArray.length < 1) {
			return (
				<div>
					<SideDrawer
						history={this.props.history}
						title={title}
						rightMenuButton={
						
							<div>
							{' '}
							<IconButton onClick={() => this.setState({ SearchbyQr: !this.state.SearchbyQr })} color="primary">
								<Icon style={{ color: 'white', marginRight: 10 }}>image_search</Icon>
							</IconButton>
							<BackButton callbackMethod={this.backButton} />
							</div>
						
						}
						colour="primary"
						handleLogout={this.handleLogout}
						User={this.props.UserSettings}
						versionApp={Api.VERSION}
						versionDb={Api.INDEXEDDB_VERSION}
						SendErrorData={SendErrorData}
						getBaseURL={getBaseURL}
						ApiKeyObj={ApiKeyObj}
					/>
					<Grid container={true} direction="column" justify="center" alignItems="center">
						<div className="main-site" style={{ marginTop: '10px' }}>
							<div className="no-jobtasks-card">
								<h1 className="hot-pink">{this.props.t("There are no sublocations available.")}</h1>
							</div>
						</div>
					</Grid>
					<QRCodeDialog
						isOpen={this.state.SearchbyQr}
						close={() => this.setState({ SearchbyQr: false })}
						history={this.props.history}
						readQRCodeAndNavigate={readQRCodeAndNavigate}
					/>
				</div>
			);
		}

		return (
			<div>
				<SideDrawer
					history={this.props.history}
					title={title}
					rightMenuButton={
						
						<div>
						{' '}
						<IconButton onClick={() => this.setState({ SearchbyQr: !this.state.SearchbyQr })} color="primary">
							<Icon style={{ color: 'white', marginRight: 10 }}>image_search</Icon>
						</IconButton>
						<BackButton callbackMethod={this.backButton} />
						</div>
					
					}
					colour="primary"
					handleLogout={this.handleLogout}
					User={this.props.UserSettings}
					versionApp={Api.VERSION}
					versionDb={Api.INDEXEDDB_VERSION}
					SendErrorData={SendErrorData}
					getBaseURL={getBaseURL}
					ApiKeyObj={ApiKeyObj}
				/>
				<br />
				<Grid container={true} direction="column" justify="center" alignItems="center">
					<div className="main-site" style={{ marginTop: '10px' }}>
						 { <SubLocationCard
							locations={this.state.sublocationArray}
							DocumentID={this.state.DocumentID}
							history={this.props.history}
						/>  }

						{this.state.endOfList ? (
							<Button variant="outlined" size="large" fullWidth disabled>
								{this.props.t("All Sublocations Loaded")}
							</Button>
						) : (
							<Button variant="outlined" color="primary" size="large" fullWidth onClick={() => this.getSubLocations()}>
								{this.props.t("Load More SubLocations")}
							</Button>
						)}

					</div>
					<div>
						<QRCodeDialog
							isOpen={this.state.SearchbyQr}
							close={() => this.setState({ SearchbyQr: false })}
							history={this.props.history}
							readQRCodeAndNavigate={readQRCodeAndNavigate}
						/>
					</div>
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
});

export default withTranslation()(connect(mapStateToProps)(SubLocationsListScreen));
