// Utils
import { isNullOrUndefined } from 'util';

export const searchFreeTextInput = (input: string, userArray) => {
	if (userArray === [] || isNullOrUndefined(userArray)) {
		return null;
	}

	return userArray.filter(object => {
		const matchesTeam = object.Teams ? object.Teams.map(x => x.toLowerCase()).includes(input) : null;

		const matchesName = object.Name ? object.Name.toLowerCase().includes(input) : null;

		const matchesEmail = object.Email ? object.Email.toLowerCase().includes(input) : null;

		return matchesName || matchesEmail || matchesTeam;
	});
};
