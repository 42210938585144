import * as React from 'react';
import { connect } from 'react-redux';
import Dexie from 'dexie';
import { History } from 'history';
import { isNullOrUndefined } from 'util';
import { Button, Grid } from '@material-ui/core';

// Firebase
import firebase from 'firebase';
import { fire, localQueue, idb } from '../../index';

import SideDrawer from '../../components/shared/SideDrawer';
import LoadingSpinner from '../../components/Spinners/LoadingSpinner';
import { LocalstorageGetItem, LocalstorageRemoveItem, LocalstorageSetItem } from '../../utils/LocalStorage';
import { isTodaysDate } from '../../utils/Times';
import { windowError, SendErrorData } from '../../utils/WindowError';
import Api from '../../apiConfiguration.json';

import BackButton from '../../components/shared/BackButton';
import { getBaseURL } from '../../utils/getBaseURL';
import ApiKeyObj from '../../utils/ApiObjectKey';
import JobCard from '../../components/job/JobCard';
import { useTranslation, withTranslation } from 'react-i18next';


interface NotificationsListScreenProps {
	history: History<any>;
	UserSettings: Store.UserSettings;
	t: any;
}

interface NotificationsListScreenState {
	jobs: Job.Job[];
	lastLoadedJob: any;
	loading: boolean;
	loadingMore: boolean;
	endOfList: boolean;
	searchModalOpen: boolean;
	searchInput: string;
	openClosedTaskFilter: string;
	plannedReactiveTaskFilter: string;
	filtersActive: boolean;
	isDueToday: boolean;
	documentsPerPage: number;
	yAxisScroll: number;
	order: string;
	sitesList: any[];
	siteFilter?: {
		name: string;
		value: number;
	} | null;
}

class NotificationsListScreen extends React.Component<NotificationsListScreenProps, NotificationsListScreenState> {
	private unsubscribe: any = null;

	constructor(props) {
		super(props);
		this.state = {
			jobs: [],
			lastLoadedJob: null,
			loading: false,
			loadingMore: false,
			endOfList: false,
			searchModalOpen: false,
			searchInput: '',
			openClosedTaskFilter: 'Open',
			plannedReactiveTaskFilter: '',
			filtersActive: false,
			isDueToday: false,
			documentsPerPage: 20,
			yAxisScroll: 0,
			order: 'DESC',
			sitesList: [],
			siteFilter: null,
		};
	}

	componentWillMount() {
		windowError(
			this.props.UserSettings.email,
			this.props.UserSettings.UserUID,
			this.props.UserSettings.ServerName,
			'NotificationsList',
		);


		localQueue.getDocumentsInLocalQueue();
	}

	backButton = () => {
		this.props.history.push("/job-list");
	};

	async componentDidMount() {
		if (navigator.onLine) {
			await fire.GetAppUpdateFlags();
			await this.checkIndexedDBVersion();
		}

		const localItem = (await LocalstorageGetItem('JobFilterOptions')) || '{}';
		const JsonObj = JSON.parse(localItem);
		await this.setState(
			{
				searchInput: JsonObj.searchInput || '',
				plannedReactiveTaskFilter: JsonObj.plannedReactiveTaskFilter || '',
				openClosedTaskFilter: !isNullOrUndefined(JsonObj.openClosedTaskFilter) ? JsonObj.openClosedTaskFilter : 'Open',
				isDueToday: JsonObj.IsDueToday || false,
				siteFilter: JsonObj.siteFilter || null,
				yAxisScroll: JsonObj.YAxisScroll || 0,
				order: JsonObj.order || 'DESC',
			},
			() => {
					this.fetchJobs();
			},
		);

		
	}

	async checkIndexedDBVersion() {
		await new Dexie('TrackplanManagerDB').open().then(async db => {
			const DbVersion = parseInt(Api.INDEXEDDB_VERSION);
			if (db.verno !== DbVersion) {
				let URL = '';
				if (Api.ENVIRONMENT === 'development') {
					URL = 'https://manager-staging.trackplanfm.com/#/job-task-list';
				} else {
					URL = 'https://manager.trackplanfm.com/#/job-task-list';
				}

				await SendErrorData(
					this.props.UserSettings.email,
					this.props.UserSettings.UserUID,
					this.props.UserSettings.ServerName,
					'IndexedDB Version was not the latest version upgrade was required',
					URL,
					'NotificationScreen',
				);
				self.indexedDB.deleteDatabase('TrackplanManagerDB');
				window.location.reload();
			}
		});
	}

	componentWillUnmount() {
		this.unsubscribe = null;
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll = () => {
		this.setState({ yAxisScroll: window.scrollY }, () => {
			this.handleLocalStorageFilters(window.scrollY);
		});
	};

	handleLocalStorageFilters(yAxisScroll?: number) {
		const SearchArray = {
			searchInput: this.state.searchInput,
			openClosedTaskFilter: this.state.openClosedTaskFilter,
			plannedReactiveTaskFilter: this.state.plannedReactiveTaskFilter,
			IsDueToday: this.state.isDueToday,
			siteFilter: this.state.siteFilter,
			YAxisScroll: yAxisScroll || this.state.yAxisScroll,
			order: this.state.order,
		};

		LocalstorageSetItem({ Key: 'JobFilterOptions', Value: SearchArray });
	}

	fetchJobs = () => {
		this.setState({ yAxisScroll: window.scrollY }, () => {
			this.setState(
				{
					loading: true
				},
				() => {
					this.unsubscribe = fire
						.getNotificationsPaginated(this.state.documentsPerPage)
						.onSnapshot(this.handleJobSnapshot);
				},
			);
		});
	};

	handleJobSnapshot = (query: firebase.firestore.QuerySnapshot) => {
		if (query.empty || query.docChanges().length < 1) {
			this.setState({ loading: false, loadingMore: false, endOfList: true });
			window.scrollTo(0, this.state.yAxisScroll);
			if (isNullOrUndefined(this.state.lastLoadedJob)) this.setState({ jobs: [] });
			return;
		}

		let jobTaskList = isNullOrUndefined(this.state.lastLoadedJob) ? [] : this.state.jobs;

		const docChanges = query.docChanges();
		for (const job of docChanges) {
			const jobTask = job.doc.data() as Job.Job;

			if (isTodaysDate(5)) {
				fire.getFormsForJob(job.doc.id).onSnapshot(formDocs => {
					if (formDocs.empty) return;
					formDocs.docs.forEach(form => {
						form.ref.collection('QuestionAnswers').get();
					});
				});
			}

			const jobTaskIndex = jobTaskList.findIndex(x => x.Id === job.doc.id);
			const jobTaskObj = {
				...jobTask,
				Id: job.doc.id,
			};

			if (jobTaskIndex === -1) {
				jobTaskList.push(jobTaskObj);
			} else {
				jobTaskList[jobTaskIndex] = jobTaskObj;
			}

			if (job.type === 'removed') {
				jobTaskList.splice(jobTaskIndex, 1);
			}
			
		}

		//jobTaskList = this.sortingFunction(jobTaskList);

		this.setState(
			{
				jobs: jobTaskList,
				lastLoadedJob: query.docs[query.docs.length - 1],
				loading: false,
				loadingMore: false,
			},
			() => {
				if(!isNullOrUndefined(this.props.history.location.state)) {
					const  yAxisPos  = this.props.history.location.state.yAxisScroll;
					window.scrollTo(0, yAxisPos);
					this.props.history.location.state = undefined;
				} else {
					window.scrollTo(0, this.state.yAxisScroll);
				}
			},
		);
	};

	clearNotifications(){
		fire.clearNotifications(this.state.jobs, this.props.UserSettings.UserUID);
		this.setState({jobs: []});
	}

    handleLogout() {
		LocalstorageRemoveItem('NewJobDetails');
		LocalstorageRemoveItem('JobFilterOptions');
		LocalstorageRemoveItem('AssignedUserFilterOptions');
		LocalstorageRemoveItem('ResourceFilterOptions');
		LocalstorageRemoveItem('FormLibraryFilterOptions');
		fire.auth.signOut().then(() => location.reload());
	}
	

	render() {
		if (this.state.loading && !this.state.filtersActive) {
			return <LoadingSpinner text={this.props.t("Loading notifications...")} />;
		}
		if (this.state.loading && this.state.filtersActive) {
			return <LoadingSpinner text={this.props.t("Searching notifications...")} />;
		}
		return (
			<div>
                <SideDrawer
					history={this.props.history}
					title={this.props.t("Job List")}
					rightMenuButton={
						<div>
							{' '}
                            <BackButton callbackMethod={this.backButton}/>
						</div>
					}
					colour="primary"
					handleLogout={this.handleLogout}
					User={this.props.UserSettings}
					versionApp={Api.VERSION}
					versionDb={Api.INDEXEDDB_VERSION}
					SendErrorData={SendErrorData}
					getBaseURL={getBaseURL}
					ApiKeyObj={ApiKeyObj}
				/>
				<Grid container={true} direction="column" justify="center" alignItems="center">
					<div className="main">
						{this.state.jobs.length > 0 ? (
							<div>
								<Button
										id="outer-card-button"
										variant="contained"
										color="secondary"
										size="large"
										style={{ marginTop: 10 }}
										fullWidth
										onClick={() => this.clearNotifications()}
									>
										{this.props.t("Clear All Notifications")}
									</Button>
								{this.state.jobs.map((job, i) => (
                                    <JobCard numJobs={this.state.jobs.length} key={i} job={job as Job.Job} />
								))}

								{!this.state.endOfList ? (
									<Button id="outer-card-button" variant="outlined" size="large" fullWidth disabled>
										{this.props.t("All Notifications Loaded")}
									</Button>
								) : (
									<Button
										id="outer-card-button"
										variant="outlined"
										color="primary"
										size="large"
										fullWidth
										onClick={() => this.fetchJobs()}
									>
										{this.props.t("Load More Notifications")}
									</Button>
								)}
							</div>
						) : (
							<NoJobs filtersActive={this.state.filtersActive} />
						)}


					</div>
				</Grid>
			</div>
		);
	}
}

const NoJobs = props => {
	const [t, i18n] = useTranslation();
	return (
		<div className="no-jobtasks-card">
			<h1 className="hot-pink">
				{props.filtersActive ? t("There are no more unopened jobs.") : t("There are no notifications.")}
			</h1>
		</div>
	);
};


const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
});

export default withTranslation()(connect(mapStateToProps)(NotificationsListScreen));

