// React
import * as React from 'react';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Components
import PhotosGrid from '../photos/PhotosGrid';

interface Photos {
	FirebaseStoragePath: string;
	LocalGuid?: string;
	fileName?: string;
}

interface JobTaskPhotosProps {
	history?: History;
	documentId: string;
	isComplete: boolean;
	color: string | null;
	photos: Photos[];
	titleText: string;
	noPhotosText: string;
	isForRequest: boolean;
	canRemovePhoto: boolean;
	canDownloadPhoto: boolean;
	handleDownloadPhoto?: (URL: string, FileName: string) => void;
	handleRemovePhoto?: (Guid: string, Reference: any) => void;
	documentReference?: any;
	numberOfPhotos?: number;
}

export default class JobTaskPhotos extends React.Component<JobTaskPhotosProps> {
	render() {
		return (
			<ExpansionPanel>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>{this.props.titleText}</Typography>
				</ExpansionPanelSummary>

				<ExpansionPanelDetails>
					<PhotosGrid
						history={this.props.history}
						jobId={this.props.documentId}
						isComplete={this.props.isComplete}
						photos={this.props.photos}
						canRemovePhoto={this.props.canRemovePhoto}
						canDownloadPhoto={this.props.canDownloadPhoto}
						handleRemovePhoto={this.props.handleRemovePhoto}
						handleDownloadPhoto={this.props.handleDownloadPhoto}
						documentReference={this.props.documentReference}
						canEditPhoto={true}
					/>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		);
	}
}
