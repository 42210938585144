// React
import * as React from 'react';
import { fire } from '..';

import { Redirect } from 'react-router';
import { Button, TextField } from '@material-ui/core';
import { IsChrome } from '../utils/IsChrome';
import IOSAddToHomeScreenBubble from '../components/IOS/IOSAddToHomeScreenBubble';
import LoadingSpinner from '../components/Spinners/LoadingSpinner';

interface LoginFormProps {
    loginImage: string;
    stateOffline: boolean;
    setStateHandler: any;
    stateEmail: string;
    statePassword: string;
    loginErrorMessage: string;
	loginSuccessMessage: string;
    handleReturnKey: any;
    handleSubmit: any;
    loginMicrosoft: any;
	landingPage: string;
}

const LoginForm = (props: LoginFormProps) => {
	
	console.log("props", props)

	//0 = not determined
	//1 = allowed
	//0 = not allowed
    const [allowed, setAllowed] = React.useState(0)

    React.useEffect(() => {

		const fetchData = async () => {
			var isExpired = await fire.isClientExpired()
			setAllowed(!isExpired ? 1 : 2) 
		}

		fetchData().catch(console.error);

    }, [allowed])
    

	if(allowed === 1){
		return <Redirect to={props.landingPage} />
	}
	if(allowed === 2){
		return <div className="outer-container">
					<div className="inputContainer">
						<img src={props.loginImage} alt="Logo" width="300" id="logo" />
						<h1 className="title-text">Manager App</h1>
						<TextField
							id="email"
							label="Email"
							className="text-inputs"
							value={props.stateEmail}
							onChange={evt => props.setStateHandler({ Email: evt.target.value })}
							margin="normal"
							disabled={props.stateOffline}
						/>
						<TextField
							id="password"
							label="Password"
							className="text-inputs"
							value={props.statePassword}
							onChange={evt => props.setStateHandler({ Password: evt.target.value })}
							margin="normal"
							type="password"
							disabled={props.stateOffline}
							onKeyDown={e => props.handleReturnKey(e)}
						/>
						<p style={{ color: 'red' }}>{props.loginErrorMessage}</p>
						<p style={{ color: 'green' }}>{props.loginSuccessMessage}</p>
						<Button
							disabled={props.stateOffline}
							variant="contained"
							id="loginBtn"
							className="login-buttons"
							onClick={() => props.handleSubmit()}
						>
							Login
						</Button>

						<span style={{ margin: '15px 0', textAlign: 'center' }}><strong>OR</strong></span>

						<Button
							disabled={props.stateOffline}
							variant="contained"
							id="msLoginBtn"
							onClick={() => props.loginMicrosoft()}
						>
							Login With Microsoft
						</Button>

						 <Button color="secondary" className="login-buttons"
						 	disabled={props.stateOffline}
							onClick={() => props.setStateHandler({ForgotPassword: true})} >
							Forgotten Password
						</Button> 


						{props.stateOffline ? (
							<p style={{ marginTop: '10px', textAlign: 'center', color: 'red' }}>
								Cannot login while offline. Please try again with an internet connection.
							</p>
						) : (
							<IOSAddToHomeScreenBubble messageText="To install this Web App in your iPhone/iPad press the button below and then Add to Home Screen." />
						)}
					</div>
				</div>
	} else{
		return <LoadingSpinner/>
	}
}

export default LoginForm;