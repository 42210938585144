import * as React from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { match } from 'react-router';

// Material UI
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon/Icon';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Fab from '@material-ui/core/Fab/Fab';

// Firebase
import firebase from 'firebase';
import { fire } from '../../index';

// Config File
import Api from '../../apiConfiguration.json';

// Components
import LoadingSpinner from '../../components/Spinners/LoadingSpinner';
import SideDrawer from '../../components/shared/SideDrawer';
import BackButton from '../../components/shared/BackButton';
import PaginationGrid from '../../components/shared/PaginationGrid';
import TextSearchModal from '../../components/shared/TextSearchModal';
import AssignedUserCard from '../../components/assigneduser/AssignedUserCard';

// Utils
import { isNullOrUndefined } from 'util';
import { searchFreeTextInput } from '../../utils/assignedusers/AssignedUserSearch';
import { LocalstorageRemoveItem, LocalstorageGetItem, LocalstorageSetItem } from '../../utils/LocalStorage';
import { windowError, SendErrorData } from '../../utils/WindowError';
import { getBaseURL } from '../../utils/getBaseURL';
import ApiKeyObj from '../../utils/ApiObjectKey';

// CSS
import '../../styles/resource/resource-list.css';
import { withTranslation } from 'react-i18next';

interface UserListScreenProps {
	history: History;
	match: match;
	UserSettings: Store.UserSettings;
	t: any;
}

interface UserListScreenState {
	assignedUserArray: AssignedUser[] | null;
	paginationUserArray: AssignedUser[] | null;
	SelectedUser: AssignedUser | null;
	loading: boolean;
	LastPageNumber: number;
	SelectedPageNumber: number;
	LoadingPageGrid: boolean;
	documentsPerPage: number;
	DocumentID: string;
	userDetailsModalOpen: boolean;
	searchInput: string;
	filtersActive: boolean;
	searchModalOpen: boolean;
	TeamString: string;
}

class AssignedUserListScreen extends React.Component<UserListScreenProps, UserListScreenState> {
	private unsubscribe: any = null;

	constructor(props) {
		super(props);
		this.state = {
			assignedUserArray: [],
			paginationUserArray: null,
			SelectedUser: null,
			loading: false,
			LastPageNumber: 0,
			SelectedPageNumber: 0,
			LoadingPageGrid: true,
			documentsPerPage: 10,
			DocumentID: '',
			userDetailsModalOpen: false,
			searchInput: '',
			filtersActive: false,
			searchModalOpen: false,
			TeamString: '',
		};
	}

	componentWillMount() {
		windowError(
			this.props.UserSettings.email,
			this.props.UserSettings.UserUID,
			this.props.UserSettings.ServerName,
			'ResourceList',
		);

		this.setState({ loading: true });
	}

	async componentDidMount() {
		const { id } = this.props.match.params as any;
		this.setState({ DocumentID: id });

		const localItem = await LocalstorageGetItem('AssignedUserFilterOptions');
		if (!isNullOrUndefined(localItem) && localItem !== '') {
			const JsonObj = JSON.parse(localItem);
			this.setState({
				searchInput: JsonObj.searchInput,
				filtersActive: JsonObj.searchInput !== '' ? true : false,
			});
		}

		this.getAssignedUsers();
	}

	getAssignedUsers() {
		this.unsubscribe = fire.getAssignedUserCollection().onSnapshot(this.handleAssignedUserSnapshot);
	}

	handleAssignedUserSnapshot = (query: firebase.firestore.QuerySnapshot) => {
		if (query.empty) {
			this.setState({ assignedUserArray: [], loading: false });
			return;
		}
		let assignedUserArray = query.docs.map(resourceRow => {
			const row = resourceRow.data() as AssignedUser;
			row.ID = resourceRow.id;
			return row;
		});

		const freeTextSearch = this.state.searchInput.toLowerCase();

		if (freeTextSearch !== '') {
			assignedUserArray = searchFreeTextInput(freeTextSearch, assignedUserArray);
		}

		assignedUserArray.sort((a, b) => a.Name.toLowerCase().localeCompare(b.Name.toLowerCase()));

		if (assignedUserArray !== null && assignedUserArray.length < 1) {
			this.setState({
				assignedUserArray: [],
				loading: false,
				LastPageNumber: 0,
				paginationUserArray: assignedUserArray,
			});
		} else {
			const AddPage = assignedUserArray.length % this.state.documentsPerPage === 0 ? 0 : 1;
			const FirstPageArray = assignedUserArray.slice(0, this.state.documentsPerPage);
			this.setState({
				assignedUserArray,
				loading: false,
				paginationUserArray: FirstPageArray,
				LastPageNumber: Math.floor(assignedUserArray.length / this.state.documentsPerPage) + AddPage,
			});
		}
	};

	handleNextPage = e => {
		this.setState({
			paginationUserArray: e.paginationArray,
			LoadingPageGrid: false,
			SelectedPageNumber: e.selected,
		});
	};

	handleAssignUser(AssignedUserID: string, AssignedUserName: string, DocumentID: string, history: History, translate: any) {
		if (confirm(translate("Are you sure you wish to assign") + " " + AssignedUserName + " " +  translate("to this job?"))) {
			fire.assignUserToJob(DocumentID, AssignedUserName, AssignedUserID);

			const JobObj = {
				AssignedUserObjFBID: AssignedUserID,
				JobAction: 'AssignUserToJob',
			};

			fire.postToJobQueue(DocumentID, JobObj);

			history.push('/job-details/' + DocumentID);
		}
	}

	backButton = () => {
		this.props.history.push('/job-details/' + this.state.DocumentID);
	};

	handleLogout() {
		LocalstorageRemoveItem('NewJobDetails');
		LocalstorageRemoveItem('JobFilterOptions');
		LocalstorageRemoveItem('AssignedUserFilterOptions');
		LocalstorageRemoveItem('ResourceFilterOptions');
		LocalstorageRemoveItem('FormLibraryFilterOptions');
		fire.auth.signOut().then(() => location.reload());
	}

	handleCloseChooseResourceDetailModal = (SelectedUser: AssignedUser | null, TeamString: string) => {
		this.setState({
			userDetailsModalOpen: !this.state.userDetailsModalOpen,
			SelectedUser,
			TeamString,
		});
	};

	handleSearchModal = () => {
		this.setState({
			searchModalOpen: !this.state.searchModalOpen,
		});
	};

	handleSearchInput = input => {
		this.setState({ searchInput: input });
	};

	handleClearSearch = () => {
		this.setState({
			searchInput: '',
			filtersActive: false,
			SelectedPageNumber: 0,
			loading: true,
		});

		const SearchObject = { searchInput: '' };
		LocalstorageSetItem({ Key: 'AssignedUserFilterOptions', Value: SearchObject });
		this.getAssignedUsers();
	};

	searchAssignedUsers() {
		this.setState({
			SelectedPageNumber: 0,
			searchModalOpen: false,
			filtersActive: true,
			loading: true,
		});
		const SearchObject = {
			searchInput: this.state.searchInput,
		};
		LocalstorageSetItem({ Key: 'AssignedUserFilterOptions', Value: SearchObject });
		this.getAssignedUsers();
	}

	render() {
		const title = this.state.DocumentID === 'list' ? this.props.t("User List") : this.props.t("Choose User");
		const Button =
			this.state.DocumentID === 'list' ? (
				<IconButton onClick={() => this.props.history.push('/new-job')}>
					<Icon style={{ color: 'white' }}>add_circle_outline</Icon>
				</IconButton>
			) : (
				<BackButton callbackMethod={this.backButton} />
			);

		if (this.state.loading) {
			return <LoadingSpinner text={this.props.t("Loading users...")} />;
		}

		if (!isNullOrUndefined(this.state.assignedUserArray) && this.state.assignedUserArray.length < 1) {
			return (
				<div>
					<SideDrawer
						history={this.props.history}
						title={title}
						colour="primary"
						handleLogout={this.handleLogout}
						User={this.props.UserSettings}
						rightMenuButton={Button}
						versionApp={Api.VERSION}
						versionDb={Api.INDEXEDDB_VERSION}
						SendErrorData={SendErrorData}
						getBaseURL={getBaseURL}
						ApiKeyObj={ApiKeyObj}
					/>
					<Grid container={true} direction="column" justify="center" alignItems="center">
						<div className="main-resource" style={{ marginTop: '10px' }}>
							<div className="no-jobtasks-card">
								<h1 className="hot-pink">{this.props.t("There are no users.")}</h1>
							</div>

							<Fab
								className={this.state.filtersActive ? 'not-hidden' : 'hidden'}
								id="clear-search-fab"
								color="inherit"
								aria-label="Add"
								style={{ backgroundColor: 'var(--light-red)' }}
								onClick={() => this.handleClearSearch()}
							>
								<Icon style={{ color: 'white' }}>clear</Icon>
							</Fab>
							<Fab
								id="search-fab"
								color="inherit"
								aria-label="Add"
								style={{ backgroundColor: 'var(--light-blue)' }}
								onClick={() => this.handleSearchModal()}
							>
								<Icon style={{ color: 'white' }}>search</Icon>
							</Fab>
							<TextSearchModal
								searchModalOpen={this.state.searchModalOpen}
								handleSearchModal={this.handleSearchModal}
								searchInput={this.state.searchInput}
								handleSearchInput={this.handleSearchInput}
								searchList={() => this.searchAssignedUsers()}
							/>
						</div>
					</Grid>
				</div>
			);
		}

		return (
			<div>
				<SideDrawer
					history={this.props.history}
					title={title}
					colour="primary"
					handleLogout={this.handleLogout}
					User={this.props.UserSettings}
					rightMenuButton={Button}
					versionApp={Api.VERSION}
					versionDb={Api.INDEXEDDB_VERSION}
					SendErrorData={SendErrorData}
					getBaseURL={getBaseURL}
					ApiKeyObj={ApiKeyObj}
				/>
				<Grid container={true} direction="column" justify="center" alignItems="center">
					<div className="main-resource" style={{ marginTop: '10px' }}>
						<AssignedUserCard
							resources={this.state.paginationUserArray}
							DocumentID={this.state.DocumentID}
							handleAssignUser={this.handleAssignUser}
							modalOpen={this.state.userDetailsModalOpen}
							history={this.props.history}
						/>
						<Fab
							className={this.state.filtersActive ? 'not-hidden' : 'hidden'}
							id="clear-search-fab"
							color="inherit"
							aria-label="Add"
							style={{ backgroundColor: 'var(--light-red)' }}
							onClick={() => this.handleClearSearch()}
						>
							<Icon style={{ color: 'white' }}>clear</Icon>
						</Fab>
						<Fab
							id="search-fab"
							color="inherit"
							aria-label="Add"
							style={{ backgroundColor: 'var(--light-blue)' }}
							onClick={() => this.handleSearchModal()}
						>
							<Icon style={{ color: 'white' }}>search</Icon>
						</Fab>
						{this.state.paginationUserArray !== null && this.state.paginationUserArray.length > 0 ? (
							<div id="resourcepagdiv">
								<PaginationGrid
									documentsPerPage={this.state.documentsPerPage}
									onPageChange={e => this.handleNextPage(e)}
									pageCount={this.state.LastPageNumber}
									initialPage={this.state.SelectedPageNumber}
									fullArray={this.state.assignedUserArray as any[]}
									collectionName={this.props.t("User")}
								/>
							</div>
						) : null}

						<TextSearchModal
							searchModalOpen={this.state.searchModalOpen}
							handleSearchModal={this.handleSearchModal}
							searchInput={this.state.searchInput}
							handleSearchInput={this.handleSearchInput}
							searchList={() => this.searchAssignedUsers()}
						/>
					</div>
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
});

export default withTranslation()(connect(mapStateToProps)(AssignedUserListScreen));
