export async function fileToBase64(file: File | Blob): Promise<string> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = error => reject(error);
	});
}

export async function fileToArrayBuffer(file: File | Blob): Promise<ArrayBuffer> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsArrayBuffer(file);
		reader.onload = () => resolve(reader.result as ArrayBuffer);
		reader.onerror = error => reject(error);
	});
}

export async function blobToBase64(blob: Blob): Promise<string> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(blob);
		reader.addEventListener('load', () => {
			resolve(reader.result as any);
		});
		reader.addEventListener('error', error => {
			reject(error);
		});
	});
}

export function base64ToArrayBuffer(base64: string): ArrayBuffer {
	const binaryString = window.atob(base64);
	const len = binaryString.length;
	const bytes = new Uint8Array(len);
	for (var i = 0; i < len; i++) {
		bytes[i] = binaryString.charCodeAt(i);
	}
	return bytes.buffer;
}

export const getArrayChunks = (dataArray, chunkSize: number) => {
	let arrayChunks: any[] = [];
	for (let i = 0; i < dataArray.length; i += chunkSize) {
		const chunk = dataArray.slice(i, i + chunkSize);
		arrayChunks.push(chunk)
	}
	return arrayChunks;
}