import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Img from 'react-image';
import useDialog from '../../hooks/useDialog';
import '../../styles/requests/request-photos.css';
import DocumentDetails from '../shared/DocumentDetails';
import ImageLoadingSpinner from '../Spinners/ImageLoadingSpinner';

type RequestPhotosProps = {
	photos: RequestPhoto[];
};

const RequestPhotos = ({ photos }: RequestPhotosProps) => {
	const { isDialogOpen, openDialog, closeDialog } = useDialog();
	const [selectedPhoto, setSelectedPhoto] = useState<RequestPhoto | null>(null);
	const [t, i18n] = useTranslation();

	const handlePhotoClick = (photo: RequestPhoto) => {
		setSelectedPhoto(photo);
		openDialog();
	};

	if (photos.length === 0) {
		return (
			<div className="card-shadow request-photos-container">
				<p className="no-photos-text">{t("No Request Photos")}</p>
			</div>
		);
	}

	return (
		<>
			<div className="card-shadow request-photos-container" style={{ padding: 0 }}>
				<div className="request-photos-header">
					<Typography style={{ color: 'white' }}>{t("Request Photos")}</Typography>
				</div>
				<div className="request-photos-display">
					<Grid container item spacing={1}>
						{photos.map(photo => (
							<Grid item xs="auto" key={photo.ID}>
								<Img
									className="request-photo"
									src={photo.AzureImage || photo.FirebaseStoragePath}
									loader={<ImageLoadingSpinner />}
									onClick={() => handlePhotoClick(photo)}
								/>
							</Grid>
						))}
					</Grid>
				</div>
			</div>
			{isDialogOpen && selectedPhoto && (
				<Dialog open={isDialogOpen} onClose={closeDialog} PaperProps={{ className: 'photo-dialog' }}>
					<DialogContent>
						<Img style={{ objectFit: 'fill', maxWidth: '100%', marginBottom: 25 }} src={selectedPhoto.AzureImage || selectedPhoto.FirebaseStoragePath} />
						<DocumentDetails
							Filename={selectedPhoto.Filename}
							UploadedBy={selectedPhoto.UploadedBy}
							Source={selectedPhoto.Source}
							DateCreated={selectedPhoto.UploadedDate}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={closeDialog} color="primary">
							{t("Close")}
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</>
	);
};

export default RequestPhotos;
