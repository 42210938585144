// react
import * as React from 'react';

// Material UI
import { CircularProgress, Icon } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';

interface LoadingSpinnerProps {
	size?: number;
	text?: string;
	color?: 'inherit' | 'secondary' | 'primary' | null;
	height?: string;
	fontSize?: string;
	loadscreenButton?: boolean;
	buttonFunction?: any;
	t: any;
	showAlert?: boolean;
}

const LoadingSpinner = (props: LoadingSpinnerProps) => (
	<div
		style={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: props.height || '100vh',
			flexDirection: 'column',
		}}
	>
		<div style={{ textAlign: 'center', maxWidth: '60%', width: '100%' }}>
			{props.showAlert ? (
				<div className='alertBar'>
					<Icon className='alertIcon'>warning</Icon> {props.text}
				</div>
			) : (
				<>
					<h1 style={{ marginBottom: '20px', fontSize: props.fontSize || '25px', color: '#e91e63' }}>
						{props.text || props.t('Loading')}
					</h1>
					<CircularProgress size={props.size || 50} color={props.color || 'primary'} />
				</>
			)}

		</div>
		{props.loadscreenButton ?
			<Button
				variant="contained"
				color="secondary"
				id="refreshBtn"
				className="login-buttons"
				size='small'
				onClick={() => props.buttonFunction()}
				style={{ margin: 20 }}
			>
				{props.t("If this screen shows for more than 10 seconds there may be an issue, click here to refresh the cache.")}
			</Button> : null}
	</div>
);

export default withTranslation()(LoadingSpinner);
