// React
import * as React from 'react';

// Firebase + IndexedDB
import { fire, idb } from '../../index';

// Components
import QuestionLayout from './QuestionLayout';
import InlineLoadingSpinner from '../Spinners/InlineLoadingSpinner';
import PhotoAnswer from '../form-answers/PhotoAnswer';
import DocumentAnswer from '../form-answers/DocumentAnswer';

// Utils
import { fileChangeListener, getFromIndexDb, getDocument } from '../../utils/forms/FormQuestionHelpers';
import { removePhoto } from '../../utils/forms/FormQuestionHelpers';
import { generateGuid } from '../../utils/Guids';
import imgNotAvailable from '../../assets/images/not-available.jpg';
import { isNullOrUndefined } from 'util';
import { withTranslation } from 'react-i18next';

interface FileInputQuestionProps {
	data: Forms.QuestionAnswer;
	formComplete: boolean;
	formId: string;
	t: any;
	formBusinessType?:string
}

interface FileInputQuestionState {
	answer: {
		DownloadUrl: string | null;
		FileName: string | null;
	};
	uploadingFile: boolean;
	deletingFile: boolean;
	photosArray: Job.JobPhoto[];
	size: number;
	loading: boolean;
	firstLoad: boolean;
	lastGuidEntered: string;
	expand: boolean;
}

class FileInputQuestion extends React.PureComponent<FileInputQuestionProps, FileInputQuestionState> {
	inputRef: React.RefObject<HTMLInputElement>;
	isPhoto: boolean;

	constructor(props: Readonly<FileInputQuestionProps>) {
		super(props);
		this.inputRef = React.createRef();
		this.isPhoto = this.props.data.QuestionType === 'Photo';
		this.state = {
			answer: this.props.data.DocumentAnswer,
			uploadingFile: false,
			deletingFile: false,
			photosArray: [],
			size: 0,
			loading: true,
			firstLoad: true,
			lastGuidEntered: '',
			expand: false
		};
		this.getPhotos = this.getPhotos.bind(this);
	}

	async componentDidMount() {
		if (this.isPhoto && !isNullOrUndefined(this.props.data.Id)) {
			await idb.getPhotos(this.props.data.Id).then(photos => {
				if (!isNullOrUndefined(photos)) {
					photos.forEach(photo => {
						if (photo.file.byteLength === new ArrayBuffer(0).byteLength) {
							idb.removePhotoByGuid(photo.guid);
						}
					});
				}
			});

			const isComplete = this.props.formComplete.toString();

			if (navigator.onLine) {
				fire.getQuestionAnswerPhotoCollection(this.props.data.questionRef.path)
					.onSnapshot(async docs => {
						let count = 1;
						if (docs.size > 0) {
							if (this.state.firstLoad) await this.setState({ size: docs.size });
							for (const doc of docs.docChanges()) {
								const change = doc;
								const Photo = doc.doc.data() as Job.JobPhoto;
								if (isNullOrUndefined(Photo.Filename)) return;

								if (change.type === 'added' || change.type == 'modified') {
									const Exists =
										(await idb.photoExistsByGuid2(Photo.Guid as string)) ||
										(await idb.documentExistsByGuid(Photo.Guid || ''));
									if (!Exists && this.state.lastGuidEntered !== Photo.Guid) {
										await this.setState({ lastGuidEntered: Photo.Guid as string });
										await idb.downloadQuestionAnswerPhotoFromFirebase(
											!isNullOrUndefined(Photo.FirebaseStoragePath)
												? Photo.FirebaseStoragePath
												: (Photo.AzureUrl as string),
											this.props.data.Id,
											!isNullOrUndefined(Photo.Guid) ? (Photo.Guid as string) : generateGuid(),
											change.doc.id,
											Photo.Filename as string,
											isComplete,
											this.props.formId,
											this.props.formBusinessType
										);

										if (!this.state.firstLoad) {
											await this.setState({ size: this.state.size + 1 });
										}
									}

									if (!this.state.firstLoad) {
										this.getPhotos(this.props.data.Id);
									}
								} else if (change.type === 'removed') {
									await idb.removePhotobyGuid(Photo.Guid as string);

									if (!this.state.firstLoad) {
										await this.setState({ size: this.state.size - 1 });
										this.getPhotos(this.props.data.Id);
									}
								}

								if (count >= this.state.size && this.state.firstLoad) {
									this.getPhotos(this.props.data.Id);
								} else {
									count = count + 1;
								}
							}
						} else if (!this.state.firstLoad) {
							for (const doc of docs.docChanges()) {
								const change = doc;
								const Photo = doc.doc.data() as Job.JobPhoto;
								if (change.type === 'removed') {
									await idb.removePhotobyGuid(Photo.Guid as string);

									if (!this.state.firstLoad) {
										await this.setState({ size: this.state.size - 1 });
										this.getPhotos(this.props.data.Id);
									}
								}
							}
						} else {
							this.setState({ loading: false });
							this.props.data.questionRef.update({ HasPhotosAttached: false });
						}

						this.setState({ firstLoad: false });
					});
			} else {
				this.getPhotos(this.props.data.Id)
			}
		}
	}


	private handleExpand = (value) => {
		this.setState({ expand: value })
	}

	getPhotos(documentId: string) {
		idb
			.getPhotos(documentId)
			.then(array => {
				if (array.length > 0 && array.length >= this.state.size) {
					const photosArray = array.map(photoObj => {
						if (photoObj.file.byteLength !== new ArrayBuffer(0).byteLength) {
							const blob = new Blob([photoObj.file]);
							const blobUrl = URL.createObjectURL(blob);
							return {
								Guid: photoObj.guid,
								FirebaseStoragePath: blobUrl,
								fileName: photoObj.fileName,
							};
						} else {
							return { Guid: photoObj.guid, FirebaseStoragePath: imgNotAvailable, fileName: photoObj.fileName };
						}
					});

					this.setState({ photosArray, loading: false });
					this.props.data.questionRef.update({
						HasPhotosAttached: true,
					});
				} else if (this.state.size > 0) {
					this.setState({ photosArray: [] });
					this.props.data.questionRef.update({ HasPhotosAttached: false });
				} else {
					this.setState({ photosArray: [], loading: false });
					this.props.data.questionRef.update({ HasPhotosAttached: false });
				}
			})
			.catch(err => console.error(err));
	}

	UNSAFE_componentWillReceiveProps(newProps: FileInputQuestionProps) {
		this.setState({ answer: newProps.data.DocumentAnswer });

		// no need to reload the file as it's received in props
		// if (!this.isPhoto) {
		// 	if (newProps.data.DocumentAnswer === null) {
		// 		this.getFile(newProps.data.Id, null, null);
		// 	} else {
		// 		this.getFile(newProps.data.Id, newProps.data.DocumentAnswer.DownloadUrl, newProps.data.DocumentAnswer.FileName);
		// 	}
		// }
	}

	getFile = (documentId: string, downloadUrl?: string | null, fileName?: string | null) => {
		getDocument(
			documentId,
			this.covertToBlobAndSave,
			downloadUrl,
			fileName,
			this.props.formComplete.toString(),
			this.props.formId,
		);
	};

	covertToBlobAndSave = (doc: ArrayBuffer, fileName?: string) => {
		const blob = new Blob([doc]);
		const blobUrl = URL.createObjectURL(blob);
		this.setState({
			...this.state,
			answer: { FileName: fileName || '', DownloadUrl: blobUrl },
		});
	};

	setStateCallback = (
		uploadingFile: boolean,
		deletingFile: boolean,
		fileName: string | null,
		fileUrl: string | null,
		guid?: string,
		docId?: string
	) => {
		if (!this.isPhoto) {
			this.props.data.questionRef.update({ HasPhotosAttached: true });
			getFromIndexDb(this.covertToBlobAndSave, this.isPhoto, this.props.data.Id, guid);
		}

		if (this.state.deletingFile !== deletingFile) {
			this.setState({ deletingFile });
		}
		if (this.state.uploadingFile !== uploadingFile) {
			this.setState({ uploadingFile });
		}
		if (this.state.answer !== null && this.state.answer.FileName !== fileName) {
			this.setState({ answer: { ...this.state.answer, FileName: fileName } });
		}
		if (this.state.answer !== null && this.state.answer.DownloadUrl !== fileUrl) {
			this.setState({ answer: { ...this.state.answer, DownloadUrl: fileUrl } });
		}

		fire.UpdateFormAfterAnswer(this.props.data.FormFBID);
		if (docId && !navigator.onLine) {
			this.getPhotos(docId)
		}
	};	

	handleRemovePhoto = async (guid: string, reference: any) => {
		if (!navigator.onLine) {
			this.setState((prevState) => ({
				photosArray: prevState.photosArray.filter(_item => _item.Guid !== guid)
			}), () => {
				removePhoto(guid, reference, this.setStateCallback);
			})
		} else {
			removePhoto(guid, reference, this.setStateCallback);
		}

	};

	_chooseAnswer = () => {
		if (this.isPhoto) {
			return (
				<PhotoAnswer
					fileName={this.state.answer !== null ? this.state.answer.FileName : null}
					photoUrl={this.state.answer !== null ? this.state.answer.DownloadUrl : null}
					fileChangeListener={evt => {
						fileChangeListener(
							evt,
							this.setStateCallback,
							this.isPhoto,
							this.props.data,
							undefined,
							this.props.formComplete.toString(),
							this.props.formId,
							this.handleExpand,
							this.props.formBusinessType,
							this.getPhotos
						);
					}}
					inputRef={this.inputRef}
					formComplete={this.props.formComplete}
					photosArray={this.state.photosArray}
					documentReference={this.props.data}
					handleRemovePhoto={this.handleRemovePhoto}
					loading={this.state.loading}
					size={this.state.size}
					handleExpand={this.handleExpand}
					photosExpand={this.state.expand}
				/>
			);
		} else {
			return (
				<DocumentAnswer
					fileName={this.state.answer !== null ? this.state.answer.FileName : null}
					fileUrl={this.state.answer !== null ? this.state.answer.DownloadUrl : null}
					fileChangeListener={evt =>
						fileChangeListener(
							evt,
							this.setStateCallback,
							this.isPhoto,
							this.props.data,
							undefined,
							this.props.formComplete.toString(),
							this.props.formId,
							undefined,
							this.props.formBusinessType
						)
					}
					inputRef={this.inputRef}
					formComplete={this.props.formComplete}
				/>
			);
		}
	};

	render() {
		return (
			<QuestionLayout>
				{this.props.data.QuestionTitle}
				{this.state.uploadingFile ? (
					<InlineLoadingSpinner text={this.props.t("Saving File...")} size={20} />
				) : this.state.deletingFile ? (
					<InlineLoadingSpinner text={this.props.t("Deleting File...")} size={20} />
				) : (
					this._chooseAnswer()
				)}
			</QuestionLayout>
		);
	}
}

export default withTranslation()(FileInputQuestion);
