import { Icon, IconButton, InputLabel } from '@material-ui/core';
import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Search Pagination for Sites
import { AsyncPaginate } from 'react-select-async-paginate';
import { isNullOrUndefined } from 'util';

function AssetSubClassDropdown(props) {
  const [t, i18n] = useTranslation();
	return (
		<div style={{flex: '1'}}>
		<AsyncPaginate
        id="assetSubClass-helper"
        value={props.assetSubClassFilter}
        onChange={props.handleAssetSubClassFilter}
        loadOptions={props.loadAssetSubClasses}
        blurInputOnSelect={true}
        loadingMessage={() => t("Loading Asset Sub Classes...")}
        cacheUniqs={[props.assetSubClassFilter, props.assetClassFilter]}
        noOptionsMessage={() => t("No Asset Sub Classes Found")}
        isSearchable={false}
    />
		<br/>
		<p className="field-label-asset">{t("Asset Sub Class")} {!isNullOrUndefined(props.assetSubClassFilter) && props.assetSubClassFilter !== '' && (				
                <IconButton onClick={() => props.clearSelectedAssetSubClass()} style={{ cursor: 'pointer' }}>
                  <Icon color="error">
                    delete_forever
                  </Icon>
                </IconButton>        
            )}</p> 
		<br/>
	</div>
	)
}

export default AssetSubClassDropdown;
