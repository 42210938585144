// React
import * as React from 'react';
import { History } from 'history';

// Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {
	Button,
	Icon,
	Table,
	CardHeader,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	IconButton,
} from '@material-ui/core';
import { isNullOrUndefined } from 'util';
import { fire } from '../../index';
import { connect } from 'react-redux';
import OuterCard from '../../components/shared/OuterCard';
import { useTranslation, withTranslation } from 'react-i18next';

interface AssetDetailProps {
	SelectedAsset: Asset | null;
	assetDetailsModalOpen: boolean;
	handleCloseChooseAssetDetailModal: () => void;
	history: History;
	t: any;
}
interface AssetDetailsState {
	JobIds: string[] | null;
	Jobs: Job.Job[];
	assetDetails: Asset | null;
}

const handleRaiseNewJobfromAsset = (selectedAsset: Asset | null, history: History) => {
	const [t, i18n] = useTranslation();
	if (!isNullOrUndefined(selectedAsset)) {
		let ids: any;
		ids = selectedAsset.AssetFBID;
		history.push({
			pathname: '/new-request-from-Asset/' + selectedAsset.AssetFBID,
			state: {
				JobDetails: t("Requested from Asset:") + " " + selectedAsset.AssetName,
				SelectedSiteName: selectedAsset.SiteName,
				SelectedSiteID: selectedAsset.SiteID,
				SelectedLocationName: selectedAsset.LocationName,
				SelectedLocationID: selectedAsset.LocationID,
				SelectedSubLocationName: selectedAsset.SubLocationName,
				SelectedSubLocationID: selectedAsset.SubLocationID,
				AssetsAttached: ids,
				guid: null,
			},
		});
	}
};

const FieldHolder = props => (
	<div className={`${props.pullRight ? 'pull-right' : ''}${props.fullLine ? 'full-line' : ''} field`}>
		<p style={{ overflowWrap: 'break-word' }} className="field-content">
			{props.content}
		</p>
		<p className="field-label">{props.label}</p>
	</div>
);

class AssetDetailsModal extends React.Component<AssetDetailProps, AssetDetailsState> {
	constructor(props) {
		super(props);
		this.state = {
			JobIds: [],
			Jobs: [],
			assetDetails: this.props.SelectedAsset,
		};
	}

	async handleComponentLoad(id: string) {
		//this.setState({ DocumentID: id });
		const asset = fire.getDocumentQuery('Assets', id);

		asset.onSnapshot(assetObj => {
			const Asset = assetObj.data() as Asset;
			this.setState({
				assetDetails: Asset,
			});
		});

		let iscomplete = '';
		await asset.get().then(data => {
			const Asset = data.data() as Asset;
			// iscomplete = !isNullOrUndefined(Job.CompletedDate) ? 'true' : 'false';
			// this.setState({
			// 	isComplete: iscomplete,
			// });
		});
	}

	async componentDidMount() {
		this.getJobsNew();
		if (!isNullOrUndefined(this.props.SelectedAsset)) {
			this.handleComponentLoad(this.props.SelectedAsset.AssetFBID);
		}
	}

	sortingFunction = (joblist: Job.Job[]) => {
		return joblist.sort((a, b) => {
			if (a.JobNumber === b.JobNumber) return 0;
			else if (a.JobNumber === 0) return -1;
			else if (b.JobNumber === 0) return 1;
			else if (a.JobNumber < b.JobNumber) return 1;
			else if (b.JobNumber < a.JobNumber) return -1;
			else return 0;
		});
	};

	getJobsNew() {
		let JobArray: Job.Job[] = [];
		this.setState({ Jobs: JobArray });
		if (!isNullOrUndefined(this.props.SelectedAsset)) {
			fire.baseQuery
				.collection('AssetsToJobs')
				.where('AssetFBID', '==', this.props.SelectedAsset.AssetFBID)
				.get()
				.then(AssetJobs => {
					JobArray = AssetJobs.docs.map(assetJob => {
						let data = assetJob.data() as Job.Job;
						data.JobNumber = isNullOrUndefined(data.JobNumber) ? 0 : data.JobNumber;
						return data;
					});
					JobArray = this.sortingFunction(JobArray);
					this.setState({ Jobs: JobArray });
				});
		}
	}

	//  getJobsNew2() {
	// 	let Jobarray: Job.Job[] = [];
	// 	this.setState({ Jobs: Jobarray });

	DetailsButton = async id => {
		let doesJobExist = await fire.doesJobExist(id);
		if (doesJobExist) {
			if (!isNullOrUndefined(this.props.SelectedAsset)) {
				this.props.history.push('/job-details/' + id + '/' + this.props.SelectedAsset.AssetFBID);
			} else {
				this.props.history.push('/job-details/' + id);
			}

			this.props.handleCloseChooseAssetDetailModal();
		} else {
			alert(this.props.t('Job cannot be accessed'));
		}
	};

	render() {
		return (
			<div>
				{!isNullOrUndefined(this.props.SelectedAsset) ? (
					<Dialog open={this.props.assetDetailsModalOpen} fullScreen={true} maxWidth="lg">
						<DialogContent>
							<div id="details-outer">
								{isNullOrUndefined(this.state.assetDetails) ? (
									<div className="top-card card-shadow" style={{ border: '1px solid grey' }}>
										<FieldHolder content={this.props.SelectedAsset.AssetName} label={this.props.t("Name")} />
										<FieldHolder content={this.props.SelectedAsset.AssetCode} label={this.props.t("Asset Code")} />
										<FieldHolder content={this.props.SelectedAsset.AssetClass} label={this.props.t("Asset Class")} />
										<FieldHolder content={this.props.SelectedAsset.SiteName} label={this.props.t("Site")} />
										<FieldHolder content={this.props.SelectedAsset.LocationName} label={this.props.t("Location")} />
										<FieldHolder content={this.props.SelectedAsset.SubLocationName} label={this.props.t("Sublocation")} />
										<FieldHolder content={this.props.SelectedAsset.Manufacturer} label={this.props.t("Manufacturer")} />
										<FieldHolder content={this.props.SelectedAsset.SerialNumber} label={this.props.t("Serial Number")} />
										<FieldHolder content={this.props.SelectedAsset.ModelNumber} label={this.props.t("Model Number")} />
										<FieldHolder content={this.props.SelectedAsset.AssetStatus} label={this.props.t("Asset Status")} />
										<FieldHolder content={this.props.SelectedAsset.Comments} label={this.props.t("Comments")} />
									</div>
								) : (
									<div className="top-card card-shadow" style={{ border: '1px solid grey' }}>
										<FieldHolder content={this.state.assetDetails.AssetName} label={this.props.t("Name")} />
										<FieldHolder content={this.state.assetDetails.AssetCode} label={this.props.t("Asset Code")} />
										<FieldHolder content={this.state.assetDetails.AssetClass} label={this.props.t("Asset Class")} />
										<FieldHolder content={this.state.assetDetails.SiteName} label={this.props.t("Site")} />
										<FieldHolder content={this.state.assetDetails.LocationName} label={this.props.t("Location")} />
										<FieldHolder content={this.state.assetDetails.SubLocationName} label={this.props.t("Sublocation")} />
										<FieldHolder content={this.state.assetDetails.Manufacturer} label={this.props.t("Manufacturer")} />
										<FieldHolder content={this.state.assetDetails.SerialNumber} label={this.props.t("Serial Number")} />
										<FieldHolder content={this.state.assetDetails.ModelNumber} label={this.props.t("Model Number")} />
										<FieldHolder content={this.state.assetDetails.AssetStatus} label={this.props.t("Asset Status")} />
										<FieldHolder content={this.state.assetDetails.Comments} label={this.props.t("Comments")} />
									</div>
								)}

								<OuterCard styles="asset-card" padding="0px">
									<CardHeader title={this.props.t("Jobs")} />

									{this.state.Jobs.length === 0 ? (
										<OuterCard>
											<p
												style={{
													color: '#e91e63',
													fontSize: '20px',
													paddingBottom: '5px',
													paddingLeft: '15px',
												}}
											>
												{this.props.t("No Jobs.")}
											</p>
										</OuterCard>
									) : (
										<Table>
											<TableHead>
												<TableRow style={{ padding: '0', paddingBottom: '5px', paddingLeft: '2px' }}>
													<TableCell
														style={{ width: '30%', color: 'black', paddingLeft: '5px' }}
														className="cell"
														padding="none"
													>
														{this.props.t("Job Details")}
													</TableCell>
													<TableCell style={{ width: '30%', color: 'black' }} className="cell" padding="none">
														{this.props.t("Job Number")}
													</TableCell>
													<TableCell style={{ width: '30%', color: 'black' }} className="cell" padding="none">
														{this.props.t("Job Status")}
													</TableCell>
													<TableCell style={{ width: '10%', color: 'black' }} className="cell" padding="none" />
												</TableRow>
											</TableHead>
											<TableBody>
												{this.state.Jobs.map((row, i) => {
													return (
														<TableRow key={i}>
															<TableCell className="cell" style={{ paddingLeft: '5px' }}>
																{!isNullOrUndefined(row.JobDetails) ? row.JobDetails.substring(0, 20) : <span></span>}
															</TableCell>
															<TableCell className="cell" padding="none">
																{row.JobNumber}
															</TableCell>
															<TableCell className="cell" padding="none">
																{row.JobStatus}
															</TableCell>
															<TableCell className="cell" padding="none">
																{/* {!isNullOrUndefined(row.FirebaseId) ? ( */}
																<IconButton
																	onClick={() => {
																		this.DetailsButton(row.JobFBID);
																	}}
																>
																	<Icon>pageview</Icon>
																</IconButton>
																{/* ) : (<span></span>)} */}
															</TableCell>
														</TableRow>
													);
												})}
											</TableBody>
										</Table>
									)}
								</OuterCard>
							</div>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={() => handleRaiseNewJobfromAsset(this.state.assetDetails, this.props.history)}
								//className="add-asset-button"
								style={{ backgroundColor: 'green' }}
								variant="contained"
								color="primary"
								size="medium"
							>
								<Icon className="button-icon" fontSize="small">
									add_circle
								</Icon>
								{this.props.t("Raise Job For Asset")}
							</Button>
							<Button onClick={this.props.handleCloseChooseAssetDetailModal} variant="contained" color="default">
								{this.props.t("Close")}
							</Button>
						</DialogActions>
					</Dialog>
				) : null}
			</div>
		);
	}
}
const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
});

export default withTranslation()(connect(mapStateToProps)(AssetDetailsModal));
