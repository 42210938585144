// React
import * as React from 'react';

// Material UI
import Icon from '@material-ui/core/Icon/Icon';
import IconButton from '@material-ui/core/IconButton/IconButton';

// Utils
import { isNullOrUndefined } from 'util';
import { useTranslation } from 'react-i18next';

const AssignedUserCard = props => {
	const [t, i18n] = useTranslation();
	return props.resources.map((assignedUser: AssignedUser, i: number) => {
		let TeamsString = '';
		if (!isNullOrUndefined(assignedUser.Teams)) {
			for (let i = 0; i < assignedUser.Teams.length; i++) {
				if (i + 1 < assignedUser.Teams.length) {
					TeamsString = TeamsString + assignedUser.Teams[i] + ', ';
				}
				if (i + 1 === assignedUser.Teams.length) {
					TeamsString = TeamsString + assignedUser.Teams[i];
				}
			}
		}
		return (
			<div key={i}>
				<div
					style={{
						border: `2.5px solid var(--light-grey)`,
						marginBottom: '10px',
					}}
					className="card-shadow resource-card"
				>
					{/* <div className="top-row-choose-resource">  */}
					<div className="top-row-choose-user">
						<div className="header-container-resource">
							<p style={{ overflowWrap: 'break-word' }} className="top-content-resource">
								{assignedUser.Name}
							</p>
							<p className="label-resource">{t("User Name")}</p>
						</div>
						<div className="header-container-resource">
							<p style={{ overflowWrap: 'break-word' }} className="top-content-resource">
								{assignedUser.Phone}
							</p>
							<p className="label-resource">{t("Phone")}</p>
						</div>
						<div style={{ float: 'right' }}>
							{props.DocumentID !== 'list' ? (
								<IconButton
									className="icon-assign-user"
									style={{
										display: 'inline',
										float: 'right',
									}}
									onClick={() =>
										props.handleAssignUser(assignedUser.ID, assignedUser.Name, props.DocumentID, props.history, t)
									}
								>
									<Icon style={{ color: 'var(--dark-green)', fontSize: '32px' }}>person_add</Icon>
								</IconButton>
							) : null}
						</div>
					</div>

					<div className="bottom-row-resource">
						<div className="header-container-resource">
							<p style={{ overflowWrap: 'break-word' }} className="top-content-resource">
								{assignedUser.Email}
							</p>
							<p className="label-resource">{t("Email")}</p>
						</div>
						<div className="header-container-resource">
							<p className="content-resource">{TeamsString}</p>
							<p className="label-resource">{t("Teams")}</p>
						</div>
					</div>
				</div>
			</div>
		);
	});
};

export default AssignedUserCard;
