import { Chip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDocumentTags from '../../hooks/useDocumentTags';
import DocumentTagChip from './DocumentTagChip';

interface DocumentDetailsProps {
	selectedDocumentGuid: string;
	collection: string;
	associatedFBID: string;
	documentTags?: indexDb.DocumentTag[];
	realoadDocuments: () => void;
}

const DocumentTags = (props: DocumentDetailsProps) => {
	const { selectedDocumentGuid, collection, associatedFBID, realoadDocuments, documentTags } = props;
	const { tags, addTagToDocument } = useDocumentTags();
	const { t } = useTranslation();
	const [filteredTags, setFilteredTags] = useState<indexDb.DocumentTag[]>([]);

	useEffect(() => {
		if (documentTags) {
			setFilteredTags(tags.filter(tag => !documentTags.some(documentTag => documentTag.tagFBID === tag.tagFBID)));
		} else {
			setFilteredTags(tags);
		}
	}, [documentTags, tags]);

	return (
		<>
			{tags.length > 0 ? (
				filteredTags.length > 0 ? (
					<>
						{filteredTags.map((tag, i) => {
							return (
								<DocumentTagChip
									tagName={tag.tagName}
									key={'tag-' + i}
									onClick={() => {
										addTagToDocument(tag, collection, associatedFBID, selectedDocumentGuid, realoadDocuments);
									}}
								/>
							);
						})}
					</>
				) : (
					<Chip className="document-tag document-tag-placeholder" label={t('No Available Tags')} variant="outlined" />
				)
			) : (
				<Chip className="document-tag document-tag-placeholder" label={t('Loading Tags')} variant="outlined" />
			)}
		</>
	);
};

export default DocumentTags;
