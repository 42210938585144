// React
import * as React from 'react';
import { History } from 'history';

// Material UI
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon/Icon';
import { useTranslation } from 'react-i18next';

//style
import '../../styles/job/instruct-resource-n-assigned-user-buttons.css'

interface AssignedUserButtonsProps {
	canAssignUser: boolean;
	documentID: string;
	history: History;
	resetAssignedUserForJob: () => void;
	isComplete: boolean;
	userAssigned: boolean;
}

const AssignedUserButtons = (props: AssignedUserButtonsProps) => {
	const {t, i18n} = useTranslation();
	return (
	<div className='text-align-center'>
		{props.canAssignUser && !props.isComplete && !props.userAssigned ? (
			<Button
				onClick={() => props.history.push('/assigneduser-list/' + props.documentID)}
				className='assign-button'
				variant="contained"
			>
				<Icon style={{ fontSize: 18 }}>person_add</Icon>&nbsp;
				<p style={{ marginTop: 2, fontSize: 12, marginLeft: 1 }}>{t("Assign")}</p>
			</Button>
		) : null}

		{props.canAssignUser && props.userAssigned && !props.isComplete ? (
			<Button
				onClick={() => props.resetAssignedUserForJob()}
				className='reset-button'
				variant="contained"
			>
				<Icon className='icon-holder'>undo</Icon>&nbsp;
				<p className='button-title-holder'>{t("Reset")}</p>
			</Button>
		) : null}
	</div>
)};

export default AssignedUserButtons;
