// Utils
import { isNullOrUndefined } from 'util';

export const CanAssignResource = (
	SingleResource: Job.AssignedResourceToJob | null | undefined,
	MultipleResource: Job.AssignedResourceToJob[] | null | undefined,
	IsMultipleResource: boolean | null | undefined,
	Status: string,
) => {
	if (Status === 'Complete' || Status === 'Cancelled') return false;
	if (IsMultipleResource && isNullOrUndefined(MultipleResource)) return true;
	if (
		!IsMultipleResource &&
		!isNullOrUndefined(SingleResource) &&
		isNullOrUndefined(SingleResource.ResourceObjFBID) &&
		isNullOrUndefined(SingleResource.ResourceName)
	)
		return true;

	return false;
};

export const CanInstructResource = (
	SingleResource: Job.AssignedResourceToJob | null | undefined,
	MultipleResource: Job.AssignedResourceToJob[] | null | undefined,
	IsMultipleResource: boolean | null | undefined,
	Status: string,
) => {
	if (Status === 'Complete' || Status === 'Cancelled') return false;
	if (IsMultipleResource && MultipleResource && MultipleResource.length > 1) {
		const FBDIDs = MultipleResource.map(Resource => Resource.ResourceObjFBID);
		return !FBDIDs.includes(null);
	}

	if (!IsMultipleResource && SingleResource)
		return !isNullOrUndefined(SingleResource.ResourceObjFBID) && !isNullOrUndefined(SingleResource.ResourceName);

	return false;
};

export const searchIsNotSynced = (IsNotSynced: boolean, filteredList: any[]) => {
	if (!IsNotSynced) return filteredList;
	return filteredList.filter(job => job.JobNumber === 0);
};