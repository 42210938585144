import { Icon, IconButton } from '@material-ui/core';
import React, { CSSProperties } from 'react';
import { IndicatorProps } from 'react-select';

/**
 * ClearIndicator for AsyncPaginateReactSelect dropdowns
 * Inspired from {@link https://react-select.com/components#replaceable-components}
 */
export default function ClearIndicator<OptionType, IsMulti extends boolean>(
	props: IndicatorProps<OptionType, IsMulti>,
): JSX.Element {
	const {
		getStyles,
		innerProps: { ref, ...restInnerProps },
	} = props;
	return (
		<IconButton {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props) as CSSProperties}>
			<Icon color="error">delete_forever</Icon>
		</IconButton>
	);
}
