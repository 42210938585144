import React, { useCallback, useContext, useMemo } from 'react';
import { Box, InputLabel } from '@material-ui/core';
import AssetNameDropdown, { ReactSelectAssetNameOptionType } from '../AsyncPaginateDropdowns/AssetNameDropdown';
import { useTranslation } from 'react-i18next';
import { FBIDAndIDType, SiteSubLocAssetFilters, SiteSublocAssetFiltersCtx } from './SiteSubLocAssetForm';
import { LoadOptions } from 'react-select-async-paginate';
import Algolia from '../../../utils/Algolia';
import { useSelector } from 'react-redux';
const NUMBER_OF_ASSETS_TO_LOAD = 50;

const algolia = new Algolia();
const loadAssetNameOptionsFactory = (
	userSettings: Store.UserSettings,
	{ site, location, subLocation }: SiteSubLocAssetFilters,
): LoadOptions<ReactSelectAssetNameOptionType<Asset>, { page: number }> => {
	return async (
		inputValue, // user search value
		_options, // already loaded options
		additional, // passed to the component as props
	) => {
		const assets = await algolia
			.assetsSearch(
				inputValue,
				userSettings.ClientUID,
				{
					site: site ? site.value.id : null,
					location: location ? location.value.id : null,
					subLocation: subLocation ? subLocation.value.id : null,
					assetClass: null,
				},
				additional ? additional.page : 0,
				NUMBER_OF_ASSETS_TO_LOAD,
			)
			// we need to handle errors here, because the async paginate component will just loop on loadOptions if there is an error
			// without even loggin it!
			.catch(err => {
				console.error(err);
				throw err;
			});
		return {
			options: assets.map(asset => ({
				label: asset.AssetName,
				value: asset,
			})),
			hasMore: assets.length === NUMBER_OF_ASSETS_TO_LOAD,
			additional: {
				page: additional ? additional.page + 1 : 1,
			},
		};
	};
};

function useAssetField() {
	const { filters, setFilters } = useContext(SiteSublocAssetFiltersCtx);

	// user settings cannot be null here, it explains the bang op.
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const userSettings = useSelector((state: Store.Store) => state.User.UserSettings!);

	const loadAssetOptions = useMemo(() => loadAssetNameOptionsFactory(userSettings, filters), [userSettings, filters]);

	// see https://react-select.com/typescript#onchange for the type of this function
	const handleAssetNameFilter = useCallback(
		(option: ReactSelectAssetNameOptionType<Asset> | null) => {
			if (!option) {
				return;
			}
			setFilters({
				assetName: option,
				site: {
					label: option.value.SiteName,
					value: { fbid: option.value.SiteFBID, id: option.value.SiteID },
				},
				location:
					option.value.LocationFBID && option.value.LocationID && option.value.LocationName
						? {
								label: option.value.LocationName,
								value: {
									fbid: option.value.LocationFBID,
									id: option.value.LocationID,
								},
						  }
						: null,
				subLocation:
					option.value.SubLocationFBID && option.value.SubLocationID && option.value.SubLocationName
						? {
								label: option.value.SubLocationName,
								value: { fbid: option.value.SubLocationFBID, id: option.value.SubLocationID },
						  }
						: null,
			});
		},
		[setFilters],
	);

	const clearAllFields = () =>
		setFilters({
			assetName: null,
			site: null,
			location: null,
			subLocation: null,
		});

	return [
		filters,
		{
			loadAssetOptions,
			handleAssetNameFilter,
			clearAllFields,
		},
	] as const;
}

/**
 * a dropdown to select an asset, fetches the asset from algolia, it is used in the SiteSubLocAssetForm
 */
export default function AssetDropdownField() {
	const [t, _i18n] = useTranslation();
	const [filters, { loadAssetOptions, handleAssetNameFilter, clearAllFields }] = useAssetField();
	return (
		<>
			<Box marginBottom={1}>
				<InputLabel>{t('Asset Name')}</InputLabel>
			</Box>
			{/* the first generic argument define the type of Option's value, the second the type is for additional options to loadOptions function */}
			<AssetNameDropdown<Asset, FBIDAndIDType, { page: number }>
				loadAssetOptions={loadAssetOptions}
				assetNameFilter={filters.assetName}
				siteFilter={filters.site}
				handleAssetNameFilter={handleAssetNameFilter}
				onClear={clearAllFields}
			/>
		</>
	);
}
