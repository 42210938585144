// Utils
import { isNullOrUndefined } from 'util';

export const searchFreeTextInput = (input: string, formLibraryTemplatesArray) => {
	if (formLibraryTemplatesArray === [] || isNullOrUndefined(formLibraryTemplatesArray)) {
		return null;
	}

	return formLibraryTemplatesArray.filter(object => {
		const matchesFormName = object.FormName ? object.FormName.toLowerCase().includes(input) : null;

		const matchesFormType = object.FormType ? object.FormType.toLowerCase().includes(input) : null;

		const matchesFormCategory = object.FormCategory ? object.FormCategory.toLowerCase().includes(input) : null;

		return matchesFormName || matchesFormType || matchesFormCategory;
	});
};
