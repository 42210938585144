// React
import * as React from 'react';

// Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon/Icon';
import { useTranslation } from 'react-i18next';
import '../../styles/requests/request-details.css';

const DeclineRequestModal = props => {
	const { t, i18n } = useTranslation();
	return (
		<Dialog open={props.isOpen} fullScreen={true} maxWidth="lg">
			<DialogTitle id="form-dialog-title">{t("Decline Request")}</DialogTitle>
			<DialogContent>
				<TextField
					autoFocus={true}
					margin="none"
					id="add-note"
					label={t("Reason for Declining Request")}
					type="text"
					fullWidth={true}
					multiline={true}
					onChange={e => props.declineReasonChange(e)}
				/>
				<DialogActions className='declineDialogFooter'>
					<Button
						onClick={() => props.handleCloseModal()}
						color="primary"
						style={{ backgroundColor: 'var(--light-grey)', color: 'white' }}
					>
						<Icon style={{ fontSize: 20 }}>undo</Icon> &nbsp;
						<p style={{ marginTop: 2 }}>{t("Back")}</p>
					</Button>
					<Button
						onClick={() => props.declineRequest()}
						color="primary"
						disabled={props.isDisabled}
						style={{ backgroundColor: props.isDisabled ? 'grey' : 'var(--light-green)', color: 'white' }}>
						<Icon style={{ fontSize: 20 }}>cancel</Icon>&nbsp;
						<p style={{ marginTop: 2 }}>{t("Decline Request")}</p>
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	)
};

export default DeclineRequestModal;
