import React, { useCallback, useRef } from 'react';
import { SchedulerItemProps } from '@progress/kendo-react-scheduler';
import { SchedulerItem } from '@progress/kendo-react-scheduler';
import { History } from "history";
import Colors from '../../utils/Colors';

const JobSchedulerItem: React.FC<IProps> = ({ history, scheduleProps }) => {

    const ref = useRef(null);
    const handleClick = useCallback((event) => {
        history.push(`/job-details/${scheduleProps.dataItem.Jobid}`);
        if (scheduleProps.onClick) {
            scheduleProps.onClick(event);
        }
    }, [scheduleProps.onClick]);

    const jobStatusColor = Colors(scheduleProps.dataItem.jobStatus)
    return (
        <SchedulerItem
            {...scheduleProps}
            style={{
                ...scheduleProps.style,
                backgroundColor: jobStatusColor,
            }}
            ref={ref}
            onClick={handleClick}
        />
    )
}

export default JobSchedulerItem;

interface IProps {
    scheduleProps: SchedulerItemProps
    history: History;
}