// React
import * as React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// Store
import store from '../../store';

// Actions
import { jobsLoaded, currentScrollPosition } from '../../actions/JobActions';

// Material UI
import { Icon } from '@material-ui/core';

// CSS
import '../../styles/job/job-card.css';

// utils
import { isTodaysDate, unixToDateString } from '../../utils/Times';
import Colors from '../../utils/Colors';
import { isNullOrUndefined } from 'util';
import { withTranslation } from 'react-i18next';

interface JobCardProps {
	job: Job.Job;
	numJobs: number;
	jobsLoaded: (totalJobs) => void;
	currentScrollPosition: (scrollPosition) => void;
	JobsInfo: Store.JobsInfoState;
	t: any;
}

class JobCard extends React.Component<JobCardProps> {
	constructor(props) {
		super(props);
		this.state = {
		}
	}

	componentDidMount() {
		// console.log(this.props.JobsInfo);
	}

	updateJobsInfo = () => {
		this.props.jobsLoaded(this.props.numJobs);
		this.props.currentScrollPosition(window.scrollY);
		// console.log(this.props.JobsInfo);
	}

	render() {

		const job = this.props.job;
		const statusColor = Colors(job.JobStatus);
		const borderStyle = {
			border: `2px solid ${statusColor}`,
			borderLeft: `7.5px solid ${statusColor}`,
		};
		const jobNumberStyle = {
			backgroundColor: `${statusColor}`,
			color: statusColor === 'transparent' ? 'black' : 'white',
		};
		const destinationUrl = `/job-details/${job.Id}`;
		const isDueToday = (expectedDate: number) => isTodaysDate(expectedDate);
		const showLocation = job.Location !== '' && !isNullOrUndefined(job.Location);
		const showExpectedBy = !isNullOrUndefined(job.ExpectedByDate);
		const Jobdetails = job.JobDetails.length > 200 ? job.JobDetails.substring(0, 200) + '...' : job.JobDetails;
		const JobStatus = job.JobStatus === 'Contractor Confirmed Complete' ? this.props.t('Contractor Complete') : job.JobStatus;
		const JobSubStatus = isNullOrUndefined(job.JobSubStatus) ? "" : job.JobSubStatus;

		return (
			<Link onClick={this.updateJobsInfo} to={destinationUrl}>
				<div id="outer-card" className="card-shadow" style={borderStyle}>
					<div className="top-row">
						<div className="status-container">
							<p className="job-number" style={jobNumberStyle}>
								{job.JobNumber === 0 ? this.props.t('Not Synced') : job.JobNumber}
							</p>

							{job.IsPlanned ? (
								<p className="job-number">
									<Icon style={{ color: statusColor, marginTop: '-5px', position: 'absolute' }}>
										cached
									</Icon>
								</p>
							) : null}
						</div>
						<div className="status-container">
							{!isNullOrUndefined(job.ExpectedByDate) &&
							isDueToday(job.ExpectedByDate) &&
							isNullOrUndefined(job.CompletedDate) ? (
								<p className="due-today-flag">{this.props.t("Due Today")}</p>
							) : null}
							<p className="job-number" style={jobNumberStyle}>
								{this.props.t(JobStatus)} <span className="job-number2">{this.props.t(JobSubStatus)}</span>
							</p>
						</div>
					</div>
					<hr style={{ color: statusColor }} />
					<div className="middle-row">
						<div className="content-container">
						<p className="header">{this.props.t("Details") + ": "} </p>
							<p className="content" style={{ overflow: 'hidden' }}>
								{Jobdetails}
							</p>
						</div>
						<div className="content-container">
						<p className="header">{this.props.t("Site") + ": "}</p>
							<p className="content">{job.Site}</p>
						</div>
						{!isNullOrUndefined(job.AssignedToUser) && job.AssignedToUser.length > 1 ? (
							<div className="content-container">
								<p className="header">{this.props.t("Assigned") + ": "} </p>
								<p className="content">{job.AssignedToUser}</p>
							</div>
						) : null}
						{showLocation ? (
							<div className="content-container">
								<p className="header">{this.props.t("Location") + ": "} </p>
								<p className="content">{job.Location}</p>
							</div>
						) : null}
						{showExpectedBy &&
						!isNullOrUndefined(job.ExpectedByDate) &&
						unixToDateString(job.ExpectedByDate) !== 'Invalid Date' ? (
							<div className="content-container">
								<p className="header">{this.props.t("Expected") + ": "}</p>
								<p className="content">{unixToDateString(job.ExpectedByDate)}</p>
							</div>
						) : null}
					</div>
				</div>
			</Link>
		)
	}
}

const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
	JobsInfo: state.JobsInfo
});

const mapDispatchToProps = dispatch => ({
	jobsLoaded: (totalJobsLoaded) => dispatch(jobsLoaded(totalJobsLoaded)),
	currentScrollPosition: (yPos) => dispatch(currentScrollPosition(yPos))
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(JobCard));
