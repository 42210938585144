import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Request from '../../definitions/request';
import { fire } from '../../index';

const useRequestDetails = () => {
	const { id } = useParams<{ id: string }>();
	const mountedRef = useRef(true);
	const [isLoading, setIsLoading] = useState(true);
	const [request, setRequest] = useState<Request | null>(null);
	const [requestUpdated, setRequestedUpdated] = useState(false);

	useEffect(() => {
		getRequest();
		return () => {
			mountedRef.current = false;
		};
	}, []);

	const getRequest = () => {
		setIsLoading(true);
		const requestQuery = fire.getDocumentQuery('Requests', id).get();
		const photosQuery = fire
			.getDocumentQuery('Requests', id)
			.collection('Documents')
			.get();
		Promise.all([requestQuery, photosQuery]).then(([requestDocument, photosDocuments]) => {
			const request = requestDocument.data() as Request;
			const photos = photosDocuments.docs.map(doc => {
				return { ID: doc.id, ...doc.data() };
			});
			request.ID = id;
			request.Photos = photos as RequestPhoto[];
			if (mountedRef.current) {
				setRequest(request);
				setIsLoading(false);
			}
		});
	};

	return { isLoading, request, getRequest };
};

export default useRequestDetails;
