// React
import * as React from 'react';
import { History } from 'history';

// Material UI
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon/Icon';
import { useTranslation } from 'react-i18next';

//style
import '../../styles/job/instruct-resource-n-assigned-user-buttons.css'

interface InstructResourceButtonsProps {
	canAssignResource: boolean;
	canInstructResource: boolean;
	documentID: string;
	history: History;
	resetResourceForJob: () => void;
	handleinstructJobDialogVisibility: () => void;
	isInstructed: boolean;
	isComplete: boolean;
	resourceAssigned: boolean;
}

const InstructResourceButtons = (props: InstructResourceButtonsProps) => {
	const {t, i18n} = useTranslation();
	return(
	<div className='text-align-center'>
		{props.canAssignResource && !props.isInstructed && !props.isComplete ? (
			<Button
				onClick={() => props.history.replace('/resource-list/' + props.documentID)}
				className='choose-resource-button'
				variant="contained"
			>
				<Icon className='icon-holder'>person_add</Icon>&nbsp;
				<p className='button-title-holder'>{t("Choose")}</p>
			</Button>
		) : null}

		{props.resourceAssigned && !props.isComplete ? (
			<Button
				onClick={() => props.resetResourceForJob()}
				className='reset-button'
				variant="contained"
			>
				<Icon className='icon-holder'>undo</Icon>&nbsp;
				<p className='button-title-holder'>{t("Reset")}</p>
			</Button>
		) : null}


		{/* Buttons to be displayed when single resource is selected. */}
		{props.canInstructResource && !props.isInstructed && !props.isComplete ? (
			<>			
			<Button
				onClick={() => props.handleinstructJobDialogVisibility()}
				className='instruct-resource-button'
				variant="contained"
			>
				<Icon className='icon-holder'>announcement</Icon>&nbsp;
				<p className='button-title-holder'>{t("Instruct")}</p>
			</Button>
		</>
		) : null}
		{props.canInstructResource && !props.isComplete ? (
		<>
		<Button
				onClick={() => props.history.replace('/resource-list/' + props.documentID)}
				className='add-another-resource-button'
				variant="contained"
			>
				<Icon className='icon-holder'>person-plus-fill</Icon>&nbsp;
				<p className='button-title-holder'>{t("Add Another Resource")}</p>
			</Button>
			</>
		) : null}
	</div>
)};

export default InstructResourceButtons;
