// React
import * as React from 'react';

const QuestionLayout = props => {
	return (
		<div className="base-question">
			<div className="question-container">{props.children[0]}</div>
			<div className="answer-container">{props.children[1]}</div>
		</div>
	);
};

export default QuestionLayout;
