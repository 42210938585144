import { TextField } from '@material-ui/core';
import React from 'react';

interface JobNumberSearchProps {
	value: string;
	onChange: (value: string) => void;
}

const JobNumberSearch = (props: JobNumberSearchProps) => {
	const { value, onChange } = props;

	return (
		<div>
			<TextField
				name="job-number"
				id="job-number"
				variant="standard"
				type="number"
				onChange={event => onChange(event.target.value)}
				value={value}
			/>
		</div>
	);
};

export default JobNumberSearch;
