export function GetFileCompressionRatio(photo: Blob) {
	let Size = photo.size / 1024 / 1024;

	if (Size < 0.25) {
		return 0.5;
	}

	if (Size < 0.5) {
		return 0.25;
	}

	if (Size < 1) {
		return 0.125;
	}

	if (Size < 2) {
		return 0.0625;
	}

	if (Size < 4) {
		return 0.03125;
	}

	if (Size < 6) {
		return 0.015625;
	}

	return 0.0078125;
}
