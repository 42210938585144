import * as React from 'react';
import Webcam from 'react-webcam';
import { connect } from 'react-redux';
import { History } from 'history';

// Material UI
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

// Components
import EditPhotoScreen from './EditPhotoScreen';

// Utils
import { windowError } from '../../utils/WindowError';
import { withTranslation } from 'react-i18next';

interface TakePhotoScreenState {
	photoUri: string;
	DocumentId: string;
	newJobRequest: boolean;
	newJob: boolean;
	guid: string | null;
	facingMode: any;
	Collection: string;
	isComplete: boolean;
}
interface TakePhotoScreenProps {
	history: History<any>;
	UserSettings: Store.UserSettings;
	t: any;
}

class TakePhotoScreen extends React.Component<TakePhotoScreenProps, TakePhotoScreenState> {
	private webcam: any = null;

	constructor(props) {
		super(props);
		this.state = {
			photoUri: '',
			DocumentId: '',
			newJobRequest: false,
			newJob: false,
			guid: null,
			facingMode: 'environment',
			Collection: '',
			isComplete: false,
		};
	}

	componentWillMount() {
		windowError(
			this.props.UserSettings.email,
			this.props.UserSettings.UserUID,
			this.props.UserSettings.ServerName,
			'TakePhotoScreen',
		);

		const { DocumentId, newJobRequest, newJob, guid, Collection, isComplete } = this.props.history.location.state;
		this.setState({ DocumentId, newJobRequest, newJob, guid, Collection, isComplete });
	}

	setRef = webcam => {
		this.webcam = webcam;
	};

	capture = () => {
		const imageSrc = this.webcam.getScreenshot();
		this.setState({ photoUri: imageSrc });
	};

	clearPhoto = () => {
		this.setState({ photoUri: '' });
	};

	switchCameras = () => {
		if (this.state.facingMode === 'user') {
			this.setState({ facingMode: 'environment' });
		} else {
			this.setState({ facingMode: 'user' });
		}
	};

	render() {
		const videoConstraints = {
			width: 1280,
			height: 720,
			facingMode: this.state.facingMode,
		};

		if (this.state.photoUri === '') {
			return (
				<div id="photo-screen-container">
					<Webcam
						audio={false}
						ref={this.setRef}
						screenshotFormat="image/jpeg"
						videoConstraints={videoConstraints}
						onUserMediaError={err => console.error("Error getting user media: " + err)}
					/>
					<div className="photo-screen-actions">
						<IconButton color="inherit" aria-label={this.props.t("Back")} onClick={() => this.props.history.goBack()}>
							<Icon className="white" fontSize="large">
								arrow_back
							</Icon>
						</IconButton>
						<IconButton color="inherit" aria-label={this.props.t("Take Photo")} onClick={() => this.capture()}>
							<Icon className="white" fontSize="large">
								photo_camera
							</Icon>
						</IconButton>
						<IconButton color="inherit" aria-label={this.props.t("Change Camera")} onClick={() => this.switchCameras()}>
							<Icon className="white" fontSize="large">
								compare_arrows
							</Icon>
						</IconButton>
					</div>
				</div>
			);
		}
		return (
			<EditPhotoScreen
				photoUri={this.state.photoUri}
				clearPhoto={this.clearPhoto}
				DocumentID={this.state.DocumentId}
				newJobRequest={this.state.newJobRequest}
				newJob={this.state.newJob}
				history={this.props.history}
				guid={this.state.guid}
				Collection={this.state.Collection}
				isComplete={this.state.isComplete}
			/>
		);
	}
}

const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
});

export default withTranslation()(connect(mapStateToProps, null)(TakePhotoScreen));
