import { Chip } from '@material-ui/core';
import React, { useState } from 'react';

interface DocumentTagsChipProps {
	tagName: string;
	onClick: () => void;
}

const DocumentTagChip = (props: DocumentTagsChipProps) => {
	const { tagName, onClick } = props;
	const [clicked, setClicked] = useState(false);

	return (
		<Chip
			className={clicked ? 'document-tag-chip-disabled' : ''}
			label={tagName}
			onClick={
				!clicked
					? () => {
							onClick();
							setClicked(true);
          }
					: undefined
			}
		/>
	);
};

export default DocumentTagChip;
