// Firebase + IndexedDB
import { fire, idb } from '../..';

// Utils
import { isNullOrUndefined } from 'util';

export function orderAlphabetically(nameA: string, nameB: string) {
	if (nameA < nameB) {
		return -1;
	}
	if (nameA > nameB) {
		return 1;
	}
	return 0;
}

export function orderRepeatableQuestions(x: Forms.QuestionAnswer, y: Forms.QuestionAnswer){
	if(x.QuestionID > y.QuestionID && x.TemplateSectionID == y.TemplateSectionID)
		return 1;

	else
		return -1;

}

export function orderAlphabeticallyArray(list: any[], field: string) {
	return list.sort((a, b) => orderAlphabetically(a.Value, b.Value));
}

export function orderSort(orderA: number, orderB: number) {
	return orderA - orderB;
}

export function nameSort(orderA: number, orderB: number, nameA: string, nameB: string) {
	if ((orderA > 0 || orderB > 0) && orderA !== orderB) return 0;
	return orderAlphabetically(nameA.toLowerCase(), nameB.toLowerCase());
}

export function isQuestionComplete(question: Forms.QuestionAnswer): boolean {
	//if question is not required return true
	if (!question.IsRequired) return true;

	if (question.QuestionType === 'DisplayText' || question.QuestionType === 'Asset' || question.QuestionType === 'Calculated Field')
		//if display text or Asset will always be true
		return true;

	if (question.QuestionType === 'Photo' || question.QuestionType === 'Document') {
		if (isNullOrUndefined(question.HasPhotosAttached) || !question.HasPhotosAttached) {
			return false;
		}

		return true;
	}

	if (question.QuestionType === 'Signature') {
		if (isNullOrUndefined(question.HasPhotosAttached) || !question.HasPhotosAttached) {
			return false;
		}
		return true;
	}

	//if multiselect question
	if (question.QuestionType === 'MultiSelect') {
		if (question.MultiSelectAnswers !== undefined && question.MultiSelectAnswers.length > 1) {
			const noneSelected = question.MultiSelectAnswers.every(x => x.Selected === false);
			return !noneSelected;
		}

		return false;
	}

	const answerNull = question.Answer === null || question.Answer === undefined || question.Answer === '';

	if (answerNull) return false;

	return true;
}

export async function groupAndSort(list: Forms.QuestionAnswer[], repeatableSections: any, templateResponseFBID: string, sectionNameGetter, sectionOrderGetter) {
	let objArray: Forms.Section[] = [];

	for(const item of list){
		const sectionName = sectionNameGetter(item);
		const sectionOrder = sectionOrderGetter(item);
		const section = objArray.find(x => x.Name === sectionName); /* avoid grouping name in the future */

		if (section) {
			section.QuestionAnswers.push(item);
		} else {
			objArray.push({
				Name: sectionName,
				Order: sectionOrder,
				QuestionAnswers: [item],
				SectionComplete: false,
				IsRepeatable: item.Section.IsRepeatable,
				RepeatableQuestions: [],
				RepeatableSectionCount: null,
				CurrentSectionNumber: null,
				TemplateSectionID: item.Section.TemplateSectionID,
				HighestTemplateSectionID: 0,
				QuestionsPerSection: 0,
				QuestionFBIDs: []
			});
		}
	};

	let allQuestionsAnswered = true;

	if (repeatableSections && repeatableSections[Symbol.iterator]) {
		for (const section of repeatableSections) {
			if(!section.IsRepeatable || section.RepeatableSections.length == 0)
				continue;

			var questionFBIDs: any = [];
			await objArray.push({
				Name: section.SectionName,
				Order: (section.Order !== null && section.Order !== undefined) ? section.Order : 1, /* if there's not an order in the collection it will use 1 */
				QuestionAnswers: [],
				SectionComplete: false,
				IsRepeatable: true,
				RepeatableQuestions: [],
				RepeatableSectionCount: null,
				CurrentSectionNumber: null,
				TemplateSectionID: section.TemplateSectionID,
				HighestTemplateSectionID: 0,
				QuestionsPerSection: 0,
				QuestionFBIDs: []
			});
			
			for(const entry of section.RepeatableSections){
				var entryQuestions = entry.split(",");
				questionFBIDs.push(entryQuestions);
			};

			var pointer = objArray.find(x => x.Name === section.SectionName); /* avoid grouping name in the future */
			let lastEntry = questionFBIDs[questionFBIDs.length - 1];

			for (const fbid of lastEntry) {
				    var doc = await fire.getQuestion(templateResponseFBID, fbid);
					var data = await doc.data() as Forms.QuestionAnswer;
					data.Id = await doc.id;
					data.questionRef = await doc.ref;
					data.CompletedAnswer = await isQuestionComplete(data);
					if(data.CompletedAnswer != true)
						allQuestionsAnswered = false;
					data.FormFBID = await templateResponseFBID;
					if(pointer != null && pointer != undefined && data != undefined)
						pointer.QuestionAnswers.push(data);	
			};

			if(pointer != null && pointer != undefined){
				pointer.RepeatableSectionCount = questionFBIDs.length;
				pointer.CurrentSectionNumber = questionFBIDs.length;
				pointer.HighestTemplateSectionID = 500;
				pointer.QuestionsPerSection = lastEntry.length;
				pointer.QuestionFBIDs = questionFBIDs;
			};		
		};
    };

	//Checks which sections are completed
	for(const section of objArray){
		section.SectionComplete = section.QuestionAnswers.every(answer => answer.CompletedAnswer);
	};

	//Sorts by section order first, then alphabetically next.
	objArray = objArray.sort((a, b) => orderSort(a.Order, b.Order));
	objArray = objArray.sort((a, b) => nameSort(a.Order, b.Order, a.Name, b.Name));

	//Sort section questions by order number and question title
	for (const section of objArray) {
		let tempQuestionAnswers: Forms.QuestionAnswer[] = [];
		tempQuestionAnswers = section.QuestionAnswers.sort((a, b) => orderSort(a.Order, b.Order));
		tempQuestionAnswers = section.QuestionAnswers.sort((a, b) =>
			nameSort(a.Order, b.Order, a.QuestionTitle, b.QuestionTitle),
		);
		section.QuestionAnswers = tempQuestionAnswers;
	};

	return [objArray, allQuestionsAnswered];
};

export async function areAllFormsSubmitted(jobFBID: string): Promise<boolean> {
	return fire.baseQuery
		.collection('Forms')
		.where('JobFBID', '==', jobFBID)
		.get()
		.then(forms => {
			const docsArray = forms.docs;
			let allFormsSubmitted = true;

			allFormsSubmitted = docsArray.every(form => {
				const formData = form.data() as Forms.Form;
				if (formData.IsRequiredForJobCompletion && isNullOrUndefined(formData.CompletedDate)) {
					return false;
				} else {
					return true;
				}
			});

			return allFormsSubmitted;
		});
}
