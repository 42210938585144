// React
import * as React from 'react';

// Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

const FieldHolder = props => (
	<div className={`${props.pullRight ? 'pull-right' : ''}${props.fullLine ? 'full-line' : ''} field`}>
		<p style={{ overflowWrap: 'break-word' }} className="field-content">
			{props.content}
		</p>
		<p className="field-label">{props.label}</p>
	</div>
);

const ResourceDetailsModal = props => {
	const {t, i18n} = useTranslation();
	return (
	<Dialog open={props.resourceDetailsModalOpen} fullScreen={true} maxWidth="lg">
		<DialogContent>
			<div id="details-outer">
				<div className="top-card card-shadow" style={{ border: '1px solid grey' }}>
					<FieldHolder fullLine={true} content={props.SelectedResource.Name} label={t("Name")} />
					<FieldHolder fullLine={true} content={props.SelectedResource.Email} label={t("Email")} />
					<FieldHolder content={props.SelectedResource.Town} label={t("Town")} />
					<FieldHolder content={props.SelectedResource.Type} label={t("Type")} />
					<FieldHolder content={props.SelectedResource.Phone} label={t("Phone")} />
					<FieldHolder content={props.SelectedResource.Mobile} label={t("Mobile")} />
					<FieldHolder fullLine={true} content={props.TradeString} label={t("Trades")} />
					<FieldHolder fullLine={true} content={props.TeamsString} label={t("Teams")} />
				</div>
			</div>
		</DialogContent>
		<DialogActions>
			<Button onClick={props.handleCloseChooseResourceDetailModal} color="primary">
				{t("Close")}
			</Button>
		</DialogActions>
	</Dialog>
)};

export default ResourceDetailsModal;
